export class Card {

    public title: string;
    public subTitle: string;
    public contentRow1: string;
    public contentRow2?: string;
    public contentRow3?: string;
    public attribute?: any;
    public objectId?: number;

    constructor(title: string, subTitle: string, contentRow1: string, contentRow2?: string, contentRow3?: string, attribute?: any) 
    { 
        this.title = title;
        this.subTitle = subTitle;
        this.contentRow1 = contentRow1;
        this.contentRow2 = contentRow2;
        this.contentRow3 = contentRow3;
        this.attribute = attribute;
 
        if (attribute) {
            this.objectId = attribute.OBJECTID;
        }

    }
  }