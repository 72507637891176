import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { setDefaultOptions, loadModules } from 'esri-loader';
import { Esri, ElevationInfo, FeatureLayerProperties, CodedValue, Domain, FeatureServiceQueryResponse, ApplyEditsResult, ApplyEditsResultMessage, SpatialRelationship, EsriSRUnit, Extent, EsriGeometryType } from '../models/esri';

@Injectable({
  providedIn: 'root'
})

export class FeaturesService {

  public featureLayer: any;
  public whereClause: string;
  public outFields: string[] = [];

  public geometry: any;
  public spatialRelationship: SpatialRelationship;
  public distance?: number;
  public units?: EsriSRUnit;
  public returnGeometry?: boolean;

  //private readonly onDestroy = {}

  constructor() { }

  ngOnInit() {
    console.log('FeaturesService ngOnInit()');
  }

  ngOnDestroy() {
    console.log('FeaturesService ngOnDestroy()');
  }

  //
  // Get Layers
  //

  // Get a single Feature Service Layer
  public async getFeatureLayer(is3D: boolean, properties: FeatureLayerProperties): Promise<any> {

    const options = {
      version: Esri.apiVersion
    };

    setDefaultOptions(options);

    const [
      FeatureLayer,
      SceneLayer,
      TileLayer
    ] = await loadModules([
      'esri/layers/FeatureLayer',
      'esri/layers/SceneLayer',
      'esri/layers/TileLayer'
    ]);

    let featureLayer;

    //console.log('properties.url', properties.url);
    //console.log('properties.url search', properties.url.includes('Scene'));

    // Check if it is a Map/Feature Service Layer or a Tile Layer

    if (properties.url.includes('Scene')) {
      console.log('Scene', properties.id);
      console.log('Scene', properties.url);

      featureLayer = new SceneLayer({
        url: properties.url,
        id: properties.id,
        title: properties.title
      });
    }
    else if (properties.url.includes('tile')) {
      //console.log('Tile', properties.id, properties.url);
      featureLayer = new TileLayer({
        url: properties.url,
        id: properties.id,
        title: properties.title
      });
    }
    else {
      //console.log('Feature', properties.id);
      featureLayer = new FeatureLayer({
        url: properties.url,
        id: properties.id,
        title: properties.title
      });
    }

    // These 3 Attributes are mandatory
    if (is3D) {
      //elevationInfoMode
      if (properties.elevationInfo) {
        featureLayer.elevationInfo = properties.elevationInfo;
      }
      else {
        let elevationInfo = new ElevationInfo();
        featureLayer.elevationInfo = elevationInfo;
      }
    }

    // Optional Attributes
    if (properties.opacity) {
      featureLayer.opacity = properties.opacity;
    }
    else {
      featureLayer.opacity = 1.0;
    }

    if (properties.maxScale) {
      featureLayer.maxScale = properties.maxScale;
    }
    else {
      featureLayer.maxScale = 0;
    }

    if (properties.minScale) {
      featureLayer.minScale = properties.minScale;
    }
    else {
      featureLayer.minScale = 0;
    }

    if (properties.legendEnabled) {
      featureLayer.legendEnabled = properties.legendEnabled;
    }
    else {
      featureLayer.legendEnabled = false;
    }

    if (properties.listMode) {
      // 'show' or 'hide'
      featureLayer.listMode = properties.listMode;
    }
    else {
      featureLayer.listMode = 'hide';
    }

    if (properties.popupEnabled) {
      featureLayer.popupEnabled = properties.popupEnabled;
    }
    else {
      featureLayer.popupEnabled = false;
    }

    if (properties.popupTemplate) {
      featureLayer.popupTemplate = properties.popupTemplate;
    }

    if (properties.outFields) {
      featureLayer.outFields = properties.outFields;
    }
    else {
      featureLayer.outFields = ["*"];
    }

    if (properties.definitionExpression) {
      featureLayer.definitionExpression = properties.definitionExpression;
    }
    else {
      featureLayer.definitionExpression = '1=1';
    }

    if (properties.portalItem) {
      featureLayer.portalItem = properties.portalItem;
    }

    if (properties.renderer) {
      //console.log('getFeatureLayer() renderer', properties.renderer, properties.id);
      featureLayer.renderer = properties.renderer;
    }

    //console.log('properties.visible');
    //console.log(properties.visible);

    if (properties.visible != null) {
      //console.log('not null');
      featureLayer.visible = properties.visible;
    }
    else {
      //console.log('null');
      featureLayer.visible = true;
    }
    //console.log('featureLayer.visible');
    //console.log(featureLayer.visible);

    if (properties.displayField != null) {
      featureLayer.displayField = properties.displayField;
    }

    if (properties.labelsVisible != null) {
      featureLayer.labelsVisible = properties.labelsVisible;
    }
    else {
      featureLayer.labelsVisible = false;
    }

    if (properties.labelingInfo != null) {
      featureLayer.labelingInfo = properties.labelingInfo;
    }

    if (properties.returnM != null) {
      featureLayer.returnM = properties.returnM;
    }
    else {
      featureLayer.returnM = false;
    }

    if (properties.returnZ != null) {
      featureLayer.returnZ = properties.returnZ;
    }
    else {
      featureLayer.returnZ = false;
    }

    if (properties.featureReduction != null) {
      featureLayer.featureReduction = properties.featureReduction;
    }

    if (properties.copyright != null) {
      featureLayer.copyright = properties.copyright;
    }

    return featureLayer;
  }

  // Get multiple feature Service Layers
  public async getFeatureLayers(is3D: boolean, properties: any[]): Promise<any> {

    let featureLayers: any[] = [];

    for (const property of properties) {
      //console.log('FeaturesService getFeatureLayers in this.getFeatureLayer');
      let fl = await this.getFeatureLayer(is3D, property);
      //console.log(fl);
      featureLayers.push(fl);
    }

    //console.log('return');
    return featureLayers;
  }

  //
  // Load Layers
  //

  // Load a single feature layer
  public async loadLayer(is3D: boolean, layerProperties: FeatureLayerProperties): Promise<any> {
    let featureLayer;

    await this.getFeatureLayer(is3D, layerProperties).then(layer => {
      //console.log('Loading ' + layerProperties.id +  ' (' + layerProperties.title + ') Layer', layer);
      featureLayer = layer;
    })
      .catch((error) => {
        console.log(layerProperties.id + ' (' + layerProperties.title + ') loadLayer Promise error:', error);
      });

    return featureLayer;
  }

  // Load multiple feature layers
  public async loadLayers(is3D: boolean, properties: FeatureLayerProperties[]): Promise<any> {
    let featureLayers: any[] = [];

    await this.getFeatureLayers(is3D, properties).then(layers => {
      featureLayers = layers;
    });

    return featureLayers;
  }

  // //
  // // Query Layers
  // //

  // public async filterFeatureLayer(clientSideFeatureLayer: any, whereClause: string, outFields?: string[]): Promise<FeatureServiceQueryResponse> {

  //   console.log('filterFeatureLayer whereClause', whereClause);

  //   // This creates a client side feature layer to avoid a callback to the ArcGIS server for each query
  //   let featureService = new FeatureServiceQueryResponse();

  //   let query = clientSideFeatureLayer.createQuery();
  //   query.where = whereClause;

  //   if (outFields.length > 0) {
  //     query.outFields = outFields;
  //   }
  //   else {
  //     query.outFields = ['*'];
  //   }

  //   await clientSideFeatureLayer.queryFeatures(query).then(response => {
  //     console.log('requery', response);
  //     featureService.featureLayerData = response;
  //   });

  //   return featureService;
  // }

  public async queryObjectIds(featureLayer: any, point: any): Promise<any> {

    let objectIds = [];

    // Query the for the feature ids where a user clicked
    await featureLayer.queryObjectIds({
      geometry: point,
      spatialRelationship: "intersects",
      returnGeometry: false,
      outFields: ["*"]
    }).then(response => {
      console.log('queryObjectIds response', response);
      objectIds = response;
    });

    return objectIds;
  }

  public async getFieldDomains(featureLayer: any, fields: string[]): Promise<Domain[]> {
    let query = featureLayer.createQuery();
    query.where = "1=1";
    query.returnGeometry = false;
    query.outFields = ['OBJECTID'];

    let domains: Domain[] = [];

    await featureLayer.queryFeatures(query).then((response) => {
      fields.forEach(field => {
        const domain = featureLayer.getFieldDomain(field, { feature: response.features[0] });
        if (domain) {
          if (domains.find(x => x.name === domain.name)) {
            //console.log('getFieldDomains() domain exists: ', domain.name);
          }
          else {
            //console.log('getFieldDomains() adding domain: ', domain.name);
            domains.push(domain);
          }
        }
      });
    });
    //console.log('getFieldDomains() domains', domains);
    return domains;
  }

  public async queryRelatedFeatures(featureLayer: any, relationshipId: string, objectIds: any[], outFields?: string[]): Promise<any> {

    let queryResponse = null;

    await featureLayer.queryRelatedFeatures({
      outFields,
      relationshipId,
      objectIds
    }).then(response => {
      //console.log('queryRelatedFeatures response', response);
      queryResponse = response;
    });

    return queryResponse;
  }

  public async queryFeatureLayer(featureLayer: any, whereClause: string, orderByClause: string, outFields?: string[], returnDistinct?: boolean): Promise<FeatureServiceQueryResponse> {
    //console.log('queryFeatureLayer featureLayer.definitionExpression', featureLayer.definitionExpression);
    //console.log('queryFeatureLayer() featureLayer, whereClause', featureLayer, whereClause);

    if (this.outFields.length > 0) {
      outFields = this.outFields;
    }
    else {
      outFields = ['*'];
    }

    let queryResponse = new FeatureServiceQueryResponse();
    let query = featureLayer.createQuery();

    // Need to include any definition expression that may already exist
    query.where = featureLayer.definitionExpression || "1=1";
    query.where = query.where + " AND " + whereClause;

    //query.where = whereClause;
    query.orderByFields = orderByClause;

    if (returnDistinct === true) {
      query.returnDistinctValues = true;
    }

    // if (timeExtent) {
    //   query.timeExtent = timeExtent;
    //   featureLayer.timeExtent = timeExtent;
    // }

    //console.log('queryFeatureLayer outFields', outFields);

    if (outFields.length > 0) {
      query.outFields = outFields;
    }
    else {
      query.outFields = ['*'];
    }

    //console.log('queryFeatureLayer query.outFields', query.outFields);

    await featureLayer.queryFeatures(query).then(response => {
      //let domains: Domain[] = [];


      if (response.fields) {
        // // Loop thru fields and get all the domains
        // for (let field of response.fields) {
        //   //console.log(field);

        //   if (field.domain) {
        //     //console.log(field.domain);

        //     let domain = {} as Domain;
        //     domain.name = field.domain.name;
        //     domain.type = field.domain.type;

        //     let codedValues: CodedValue[] = [];

        //     for (let cv of field.domain.codedValues) {
        //       let codedValue = {} as CodedValue;
        //       codedValue.name = cv.name;
        //       codedValue.code = cv.code;
        //       codedValues.push(codedValue);
        //     }

        //     domain.codedValues = codedValues;
        //     domains.push(domain);
        //   }
        // }


        queryResponse.featureLayerData = response;
        //queryResponse.domains = domains;  
      }

      console.log('QueryFeatureServiceService Number features found: ', queryResponse.featureLayerData.features.length);
    });

    //console.log('featureLayer.isTable', featureLayer.isTable);

    if (featureLayer.isTable === false) {
      // Get the extent of the query
      await featureLayer.queryExtent(query).then(response => {
        queryResponse.extent = response.extent;
      });
    }

    //console.log('QueryFeatureServiceService return');
    return queryResponse;
  }

  // public async reQueryFeatureLayer(featureLayer: any, whereClause: string, orderByClause: string, outFields?: string[]): Promise<FeatureServiceQueryResponse> {
  //   if (this.outFields.length > 0) {
  //     outFields = this.outFields;
  //   }
  //   else {
  //     outFields = ['*'];
  //   }

  //   let queryResponse = new FeatureServiceQueryResponse();

  //   let query = featureLayer.createQuery();

  //   // Need to include any definition expression that may already exist
  //   query.where = featureLayer.definitionExpression || "1=1";
  //   query.where = query.where + " AND " + whereClause;
  //   //query.where = whereClause;

  //   query.orderByFields = orderByClause;

  //   if (outFields.length > 0) {
  //     query.outFields = outFields;
  //   }
  //   else {
  //     query.outFields = ['*'];
  //   }

  //   await featureLayer.queryFeatures(query).then(response => {
  //     if (response.fields) {
  //       queryResponse.featureLayerData = response;
  //     }
  //   });

  //   if (featureLayer.isTable === false) {
  //     // Get the extent of the query
  //     await featureLayer.queryExtent(query).then(response => {
  //       queryResponse.extent = response.extent;
  //     });
  //   }

  //   return queryResponse;
  // }

  public async queryExtent(featureLayer: any, whereClause?: string, objectids?: number[]): Promise<Extent> {

    console.log('queryExtent');
    //console.log('featureLayer', featureLayer);

    let extent: Extent = null;
    let _whereClause: string; // = "1=1";

    if (whereClause) {
      _whereClause = whereClause;
    }
    else {
      _whereClause = this.whereClause;
    }

    let query = featureLayer.createQuery();

    if (objectids) {
      query.objectIds = objectids;
    }

    // Need to include any definition expression that may already exist
    query.where = featureLayer.definitionExpression || "1=1";
    query.where = query.where + " AND " + _whereClause;
    //query.where = _whereClause;

    query.outFields = ['OBJECTID'];
    query.returnGeometry = true;

    await featureLayer.queryExtent(query).then(response => {
      //console.log('response', response);
      //console.log('response.extent', response.extent);
      extent = response.extent;
    });

    return extent;
  }

  public async queryTimeExtent(featureLayer: any, whereClause?: string, objectids?: number[]): Promise<any> {

    console.log('queryTimeExtent');
    //console.log('featureLayer', featureLayer);

    let timeExtent: any = null;
    let _whereClause: string; // = "1=1";

    if (whereClause) {
      _whereClause = whereClause;
    }
    else {
      _whereClause = this.whereClause;
    }

    let query = featureLayer.createQuery();

    if (objectids) {
      query.objectIds = objectids;
    }

    // Need to include any definition expression that may already exist
    query.where = featureLayer.definitionExpression || "1=1";
    query.where = query.where + " AND " + _whereClause;
    //query.where = _whereClause;

    query.outFields = ['OBJECTID'];
    query.returnGeometry = false;

    // Execute a query to load the layer and determine the time extent info
    await featureLayer.queryFeatures(query).then(response => {
      //console.log('response fullTimeExtent', featureLayer.timeInfo);
      //console.log('response.extent', response.extent);
      timeExtent = featureLayer.timeInfo.fullTimeExtent;
    });

    return timeExtent;
  }

  // Spatial Query of the Features of a layer and return the attributes and geometry

  public spatialQueryFeatureLayer$ = new Observable((observer) => {
    let outFields: string[];

    if (this.outFields.length > 0) {
      outFields = this.outFields;
    }
    else {
      outFields = ['*'];
    }

    this.spatialQueryFeatureLayer(this.featureLayer, this.whereClause, this.geometry, this.spatialRelationship, outFields, this.distance, this.units, this.returnGeometry).then((featureService) => {
      observer.next(featureService);
      return {
        unsubscribe() {
          this.querySpatialFilterFeatureLayer$ = null;
        }
      };
    });
  });

  public async spatialQueryFeatureLayer(featureLayer: any, whereClause: string, geometry: any, spatialRelationship: SpatialRelationship, outFields?: string[], distance?: number, units?: EsriSRUnit, returnGeometry?: boolean): Promise<any> {

    console.log('spatialQueryFeatureLayer', featureLayer, geometry);
    //console.log('QueryFeatureServiceService whereClause', whereClause);

    let featureService = new FeatureServiceQueryResponse();

    let query = featureLayer.createQuery();
    query.where = whereClause;
    query.geometry = geometry;
    query.spatialRelationship = spatialRelationship;
    query.geometryPrecision = 3;

    //query.having = ''
    //query.objectIds = []

    /*
      // Represents the data for the month of Jan, 1970
      const timeExtent = new TimeExtent({
        start: new Date(Date.UTC(1970, 0, 1, 6, 30)),
        end: new Date(Date.UTC(1970, 0, 31, 6, 30))
      });

      const timeQuery = new Query({
        timeExtent: timeExtent
      });
    */

    //query.timeExtent

    query.returnM = false;
    query.returnZ = true;

    if (outFields.length > 0) {
      query.outFields = outFields;
    }
    else {
      query.outFields = ['*'];
    }

    if (distance) {
      query.distance = distance;
    }

    if (units) {
      query.units = units;
    }

    if (returnGeometry) {
      query.returnGeometry = returnGeometry;
    }
    else {
      query.returnGeometry = true;
    }

    // returnCentroid?: boolean
    // if (returnCentroid) {
    //   query.returnCentroid = returnCentroid;
    // }

    await featureLayer.queryFeatures(query).then(response => {

      if (response.fields) {
        featureService.featureLayerData = response;
      }

      //console.log('QueryFeatureServiceService Number features found: ', featureService.featureLayerData.features.length);
    });

    //console.log('QueryFeatureServiceService return');
    return featureService;
  }

  //
  // Specific Query Layers
  //

  // Check if given geometry is within a layer

  public async checkWithinLayer(featureLayer: any, geometry: any): Promise<boolean> {
    //console.log('checkWithinLayer');
    let result: boolean;

    await this.spatialQueryFeatureLayer(featureLayer, '1=1', geometry, SpatialRelationship.Within, ['OBJECTID']).then((response) => {
      //console.log('response', response);
      if (response.featureLayerData) {
        if (response.featureLayerData.features.length > 0) {
          result = true;
        }
        else {
          result = false;
        }
      }
      else {
        result = false;
      }
    });

    //console.log('result', result);
    return result;
  }

  // Get the attribute value for the location selected within a feature
  public async getAttributeWithinLayer(featureLayer: any, geometry: any, attribute: string): Promise<any> {
    //console.log('getAttributeWithinLayer');
    let result: string;

    await this.spatialQueryFeatureLayer(featureLayer, '1=1', geometry, SpatialRelationship.Within, [attribute]).then((response) => {
      //console.log('spatialQueryFeatureLayer response', response);
      if (response.featureLayerData) {

        //console.log('response.featureLayerData.features.length', response.featureLayerData.features.length);

        if (response.featureLayerData.features.length === 0) {
          result = 'ERROR-0: No features';
        }
        else if (response.featureLayerData.features.length === 1) {
          result = response.featureLayerData.features[0].attributes[attribute];
        }
        else {
          result = 'ERROR-2: Greater than 1 feature';
        }
      }
      else {
        result = 'ERROR-D: No data';
      }

    });

    //console.log('result', result);
    return result;
  }

  //
  // Edit Layer
  //

  // ApplyEdits
  public async applyEditsFeatureLayer(featureLayer: any, params: any): Promise<any> {
    let result: ApplyEditsResultMessage = new ApplyEditsResultMessage();

    //console.log('applyEditsFeatureLayer() FeaturesService applyEditsFeatureLayer');
    //console.log('applyEditsFeatureLayer()', params);
    //console.log('applyEditsFeatureLayer()', JSON.stringify(params));
    //console.log('applyEditsFeatureLayer()', featureLayer);

    await featureLayer
      .applyEdits(params)
      .then((editsResult: ApplyEditsResult) => {

        console.log('editsResult');
        console.log(editsResult);

        // Get the result and objectId of the newly added feature.
        if (editsResult.addFeatureResults.length > 0) {
          // Add
          if (editsResult.addFeatureResults[0].error != null) {
            // error has occured
            result.result = 'ERROR';
            result.message = editsResult.addFeatureResults[0].error.message;
            //result.objectId = editsResult.addFeatureResults[0].objectId;
            result.globalId = editsResult.addFeatureResults[0].globalId;
            // editsResult.addFeatureResults[0].error
            // editsResult.addFeatureResults[0].error.details
            // editsResult.addFeatureResults[0].error.message
            // editsResult.addFeatureResults[0].error.name
          }
          else {
            // success I guess!
            result.result = 'SUCCESS';
            result.globalId = editsResult.addFeatureResults[0].globalId;
            result.objectId = editsResult.addFeatureResults[0].objectId;
            console.log('objectId added:', editsResult.addFeatureResults[0].objectId);
          }
        }
        else if (editsResult.updateFeatureResults.length > 0) {
          // Update

          if (editsResult.updateFeatureResults[0].error != null) {
            // error has occured
            result.result = 'ERROR';
            result.message = editsResult.updateFeatureResults[0].error.message;
            result.objectId = editsResult.updateFeatureResults[0].objectId;
            result.globalId = editsResult.updateFeatureResults[0].globalId;
          }
          else {
            // success I guess!
            result.result = 'SUCCESS';
            result.globalId = editsResult.updateFeatureResults[0].globalId;
            result.objectId = editsResult.updateFeatureResults[0].objectId;
            console.log('objectId added:', editsResult.updateFeatureResults[0].objectId);
          }
        }
        else if (editsResult.deleteFeatureResults.length > 0) {
          // Delete
        }
      })
      .catch((error) => {
        console.error('[ applyEdits ] FAILURE: ', error.code, error.name, error.message);
        console.log('error = ', error);
        result.result = 'ERROR';
        result.message = error;
      });

    //console.log('FeaturesService applyEditsFeatureLayer result');
    //console.log(result);

    // Return success / error
    return result;
  }

  //
  // Create Local Client Side Layers
  //



  // Create graphics layer

  public async createGraphicsLayer(id: string, title: string): Promise<any> {
    try {
      const options = {
        version: Esri.apiVersion
      };

      setDefaultOptions(options);

      const [
        GraphicsLayer
      ] = await loadModules([
        'esri/layers/GraphicsLayer',
      ]);

      let layer = new GraphicsLayer({
        id,
        title
      });

      return layer;
    }
    catch (error) {
      console.error('Esri-Loader: ', error);
    }
  }

  // Create client side feature layer
  public async createClientFeatureLayer(id: string, title: string): Promise<any> {
    try {
      const options = {
        version: Esri.apiVersion
      };

      setDefaultOptions(options);

      const [
        FeatureLayer
      ] = await loadModules([
        'esri/layers/FeatureLayer',
      ]);

      let layer = new FeatureLayer({
        id,
        title,
        objectIdField: "OBJECTID",
        renderer: Esri.rendererGeocode_Point
      });

      return layer;
    }
    catch (error) {
      console.error('Esri-Loader: ', error);
    }
  }



  public async createClientSideFeatureLayer(id: string, title: string, features: any[], fields: any[], visible: boolean, renderer: any, labelClassExpression: any, popupTemplate: any, featureReduction?: any): Promise<any> {

    let clientSideFeatureLayer;

    const options = {
      version: Esri.apiVersion
    };

    setDefaultOptions(options);

    const [
      FeatureLayer
    ] = await loadModules([
      'esri/layers/FeatureLayer'
    ]);

    const labelClass = {
      labelExpressionInfo: { expression: labelClassExpression },
      symbol: {
        type: "text",
        color: "black",
        haloSize: 1,
        haloColor: "white"
      },
      minScale: 10000,
      maxScale: 0
    };

    let graphics: any[] = [];

    for (let idx = 0; idx < features.length; idx++) {
      let graphic = {
        geometry: features[idx].geometry,
        attributes: features[idx].attributes
      }
      graphics.push(graphic);
    }

    clientSideFeatureLayer = new FeatureLayer({
      id,
      title,
      source: graphics,
      objectIdField: "OBJECTID",
      fields,
      popupTemplate,
      renderer,
      visible
    });


    if (labelClass) {
      clientSideFeatureLayer.labelsVisible = true;
      clientSideFeatureLayer.labelingInfo = [labelClass];
    }
    else {
      clientSideFeatureLayer.labelsVisible = false;
    }

    if (featureReduction) {
      clientSideFeatureLayer.featureReduction = featureReduction;
    }

    return clientSideFeatureLayer;
  }



  // Create client side feature layer and add features to it
  public async createFeatures_ClientFeatureLayer(id: string, title: string, geometryType: string, featureGeometry: any): Promise<any> {
    let clientFeatureLayer;

    try {
      const options = {
        version: Esri.apiVersion
      };

      setDefaultOptions(options);

      const [
        Point,
        Multipoint,
        Polygon,
        Polyline,
        Graphic,
        //SimpleMarkerSymbol
      ] = await loadModules([
        'esri/geometry/Point',
        'esri/geometry/Multipoint',
        'esri/geometry/Polygon',
        'esri/geometry/Polyline',
        'esri/Graphic',
        //'esri/symbols/SimpleMarkerSymbol'
      ]);

      await this.createClientFeatureLayer(id, title).then((layer) => {

        clientFeatureLayer = layer;

        // let symbol = new SimpleMarkerSymbol({
        //   style: 'x',          // circle cross diamond square triangle x
        //   color: [255, 0, 255, 0.7],
        //   size: '8px',
        //   outline: {
        //     color: [255, 0, 255, 1.0],
        //     width: 3
        //   }
        // });

        let graphic;
        let graphics;
        let geometry;

        switch (geometryType) {
          case EsriGeometryType.MultiPoint:
            geometry = new Multipoint();
            break;

          case EsriGeometryType.Polygon:
            geometry = new Polygon();
            break;

          case EsriGeometryType.Polyline:
            geometry = new Polyline();
            break;

          case EsriGeometryType.Point:
          default:
            geometry = new Point();
            break;
        }

        // Assign the feature geometry to the appropriately defined geometry object
        geometry = featureGeometry;

        // Create a graphic       <-- maybe will need to look at this later if there are multiple geometries???????
        graphic = new Graphic({
          geometry,
          attributes: {
            OBJECTID: 1
          }
        });

        // Create the graphics array and assign it to the client feature layer
        graphics = [graphic];
        clientFeatureLayer.source = graphics;
      });

      return clientFeatureLayer;
    }
    catch (error) {
      console.error('Esri-Loader: ', error);
    }
  }

  // Create graphics layer and add features to it
  public async createFeatures_GraphicsLayer(id: string, title: string, geometryType: string, geometry: any): Promise<any> {

    let graphicsLayer;

    try {
      const options = {
        version: Esri.apiVersion
      };

      setDefaultOptions(options);

      const [
        Point,
        Multipoint,
        Polygon,
        Polyline,
        Graphic,
        SimpleMarkerSymbol
      ] = await loadModules([
        'esri/geometry/Point',
        'esri/geometry/Multipoint',
        'esri/geometry/Polygon',
        'esri/geometry/Polyline',
        'esri/Graphic',
        'esri/symbols/SimpleMarkerSymbol'
      ]);

      await this.createGraphicsLayer(id, title).then((layer) => {

        graphicsLayer = layer;

        let symbol = new SimpleMarkerSymbol({
          style: 'x',          // circle cross diamond square triangle x
          color: [255, 0, 255, 0.7],
          size: '8px',
          outline: {
            color: [255, 0, 255, 1.0],
            width: 3
          }
        });

        switch (geometryType) {
          case EsriGeometryType.MultiPoint:
            //

            let geometryMultipoint = new Multipoint();
            geometryMultipoint = geometry;

            let graphic = new Graphic({
              geometry: geometryMultipoint,
              symbol
            });

            graphicsLayer.graphics.add(graphic);
            //graphicsLayer.add(graphic);
            //graphicsLayer.graphics.push(graphic);
            //graphicsLayer.graphics.push(graphic, graphic2);x
            //graphicsLayer.addMany([graphic, graphic2]);

            break;

          case EsriGeometryType.Polygon:
            //
            break;

          case EsriGeometryType.Polyline:
            //
            break;

          case EsriGeometryType.Point:
          default:
            //
            break;
        }
      });

      return graphicsLayer;
    }
    catch (error) {
      console.error('Esri-Loader: ', error);
    }

  }

  // Create sketch model
  public async createSketchViewModel(view: any, layer: any, pointSymbol: any): Promise<any> {
    const options = {
      version: Esri.apiVersion
    };

    setDefaultOptions(options);

    const [
      SketchViewModel
    ] = await loadModules([
      'esri/widgets/Sketch/SketchViewModel'
    ]);

    let sketchViewModel = new SketchViewModel({
      view,
      layer,
      updateOnGraphicClick: true,
      pointSymbol
    });

    return sketchViewModel;
  }















  // public async addPointGraphic(graphicsLayer, latitude, longitude): Promise<any> {
  //   const options = {
  //     version: Esri.apiVersion
  //   };

  //   setDefaultOptions(options);

  //   const [
  //     Point,
  //     Graphic,
  //     SimpleMarkerSymbol
  //   ] = await loadModules([
  //     'esri/geometry/Point',
  //     'esri/Graphic',
  //     'esri/symbols/SimpleMarkerSymbol'
  //   ]);

  //   let point = new Point({
  //     latitude: latitude,
  //     longitude: longitude
  //   });

  //   let symbol = new SimpleMarkerSymbol({
  //     //type: 'simple-marker',
  //     style: 'x',          // circle cross diamond square triangle x
  //     color: [255, 0, 255, 0.7],
  //     size: '8px',
  //     outline: {
  //       color: [255, 0, 255, 1.0],
  //       width: 3
  //     }
  //   });

  //   let graphic = new Graphic({
  //     geometry: point,
  //     symbol: symbol
  //   });

  //   graphicsLayer.add(graphic);

  //   return;
  // }

  // public async createFeatureLayer_Point(id: string, title: string, latitude: number, longitude: number, renderer?: any) {
  //   //console.log('createGraphicsLayer_Point', latitude);
  //   try {
  //     const options = {
  //       version: Esri.apiVersion
  //     };

  //     setDefaultOptions(options);

  //     const [
  //       Point,
  //       Graphic,
  //       FeatureLayer
  //     ] = await loadModules([
  //       'esri/geometry/Point',
  //       'esri/Graphic',
  //       'esri/layers/FeatureLayer'
  //     ]);

  //     let point = new Point({
  //       latitude: latitude,
  //       longitude: longitude
  //     });

  //     let graphic = new Graphic({
  //       geometry: point,
  //       attributes: {
  //         OBJECTID: 1
  //       }
  //     });

  //     let graphics = [graphic];
  //     let layer = new FeatureLayer({
  //       id,
  //       title,
  //       source: graphics,
  //       objectIdField: "OBJECTID",
  //       renderer: Esri.rendererGeocode_Point
  //     });

  //     return layer;
  //   } 
  //   catch (error) {
  //     console.error('Esri-Loader: ', error);
  //   }
  // }

  // Point Graphics layer
  // public async createGraphicsLayer_Point(latitude, longitude): Promise<any> {
  //   //console.log('createGraphicsLayer_Point', latitude, longitude);
  //   try {
  //     const options = {
  //       version: Esri.apiVersion
  //     };

  //     setDefaultOptions(options);

  //     const [
  //       Point,
  //       Graphic,
  //       GraphicsLayer,
  //       SimpleMarkerSymbol
  //     ] = await loadModules([
  //       'esri/geometry/Point',
  //       'esri/Graphic',
  //       'esri/layers/GraphicsLayer',
  //       'esri/symbols/SimpleMarkerSymbol'
  //     ]);

  //     let point = new Point({
  //       latitude: latitude,
  //       longitude: longitude,
  //       // spatialReference: {
  //       //   wkid: 3857
  //       // }
  //     });

  //     let symbol = new SimpleMarkerSymbol({
  //       //type: 'simple-marker',
  //       style: 'x',          // circle cross diamond square triangle x
  //       color: [255, 0, 255, 0.7],
  //       size: '8px',
  //       outline: {
  //         color: [255, 0, 255, 1.0],
  //         width: 3
  //       }
  //     });

  //     let graphic = new Graphic({
  //       geometry: point,
  //       symbol: symbol,
  //       // spatialReference: {
  //       //   wkid: 3857
  //       // }
  //     });

  //     let layer = new GraphicsLayer({
  //       graphics: [graphic]
  //     });

  //     //console.log('layer', layer);

  //     return layer;
  //   } 
  //   catch (error) {
  //     console.error('Esri-Loader: ', error);
  //   }
  // }

}
