import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { Subject } from 'rxjs';
import { format, toDate } from 'date-fns'
//import { SubDomain, Domain } from 'src/app/_esri/models/esri';

//import { DomainLovService } from 'src/app/_esri/services/domain-lov.service';
//import { LovDataService } from 'src/app/_globals/services/lov-data.service';
import { ButtonClickService } from 'src/app/_globals/services/button-click.service';

@Component({
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class BaseFeatureView implements OnInit, OnDestroy {

  // System Environment
  protected ngUnsubscribe = new Subject<void>();
  private _numberOfTicks = 0;

  private _field: any;
  private _attribute: any;
  // private _domains: Domain[] = [];
  // private _domains_lov: Domain[] = [];
  // private _domains_lovParent: SubDomain[] = [];

  public attributes: any = [];
  public fieldAlwaysVisible: boolean = false;

  public showAddHeaderBtn: boolean = true;
  public showReportHeaderBtn: boolean = true;

  public showActionIconsInTable: boolean = false;
  public showInfoActionBtn: boolean = false;
  public showEditActionBtn: boolean = true;
  public showAttachActionBtn: boolean = false;
  public showDeleteActionBtn: boolean = false;

  //protected buttonClickService: ButtonClickService = new ButtonClickService();

  @Input()
  set field(field: any) {
    this._field = field;
  }

  get field(): any {
    return this._field;
  }

  @Input()
  set attribute(attribute: any) {
    this._attribute = attribute;
  }

  get attribute(): any {
    return this._attribute;
  }

  @Output() tableRowSelectedEvent = new EventEmitter<any>();  // Also triggers for cardSelected

  @Output() addHeaderSelectedEvent = new EventEmitter<any>();
  @Output() reportHeaderSelectedEvent = new EventEmitter<any>();

  @Output() infoActionSelectedEvent = new EventEmitter<any>();
  @Output() editActionSelectedEvent = new EventEmitter<any>();
  @Output() attachActionSelectedEvent = new EventEmitter<any>();
  @Output() deleteActionSelectedEvent = new EventEmitter<any>();

  constructor(
    protected ref: ChangeDetectorRef,
    //protected lovDataService: LovDataService,
    //protected domainLovService: DomainLovService,
  ) {
    setInterval(() => {
      this._numberOfTicks++;
      this.ref.markForCheck();  // require view to be updated
    }, 1000);
  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
  }

  //
  // Toolbar header action buttons
  //

  public addHeaderBtnSelected() {
    console.log('addButtonSelectedEvent');
    this.addHeaderSelectedEvent.emit();
  }

  public reportHeaderBtnSelected(event) {
    console.log('reportButtonSelected');
    this.reportHeaderSelectedEvent.emit(event);
  }

  //
  // Row action buttons
  //

  public infoActionBtnSelected(attribute: any) {
    this.infoActionSelectedEvent.emit(attribute);
  }

  public editActionBtnSelected(attribute: any) {



    this.editActionSelectedEvent.emit(attribute);

    //let buttonClickData = {}
    //this.buttonClickService.buttonClickData.next(buttonClickData);
  }

  public attachActionBtnSelected(attribute: any) {
    this.attachActionSelectedEvent.emit(attribute);
  }

  public deleteActionBtnSelected(attribute: any) {
    this.deleteActionSelectedEvent.emit(attribute);
  }

  //
  // Domain Value Functions
  //

  public getDomainYesNoValue(codedValue: string) {

    if (codedValue.toUpperCase() === 'YES') {
      return 'Yes';
    }
    else if (codedValue.toUpperCase() === 'NO') {
      return 'No';
    }
    else {
      return '';
    }

  }

  //
  // Dates
  //

  public epochToDate(epochDate: number) {
    return format(toDate(epochDate), 'dd-MMM-yyyy');
  }

  //
  // HTML
  //

  public toHTML(input): any {
    return new DOMParser().parseFromString(input, "text/html").documentElement.textContent;
  }

}
