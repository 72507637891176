import { Component, OnInit, OnDestroy, Output, EventEmitter, ViewChild, ChangeDetectorRef } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Global } from 'src/app/_globals/models/global';
import { FieldConfig } from 'src/app/_esri/models/esri';
import { BaseFeatureView } from 'src/app/_esri/models/base-feature-view';

@Component({
  selector: 'app-esri-view-table',
  templateUrl: './esri-view-table.component.html',
  styleUrls: ['./esri-view-table.component.scss']
})

export class EsriViewTableComponent extends BaseFeatureView implements OnInit, OnDestroy {

  // Table Data
  public displayedColumns: string[] = []; 
  public tableFields: FieldConfig[] = []; 
  public tableDataSource = new MatTableDataSource<any>();
 
  // Pagination settings
  public page: number = 0;
  public pageSize = Global.pageSize; 
  public selectedRow : number;
  public currentPage: number;

  // @Output() addBtnSelectedEvent = new EventEmitter<any>();
  // @Output() reportBtnSelectedEvent = new EventEmitter<any>();

  @ViewChild(MatSort) _sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) private _paginator: MatPaginator;

  constructor(
    protected ref: ChangeDetectorRef,
  ) { 
    super( ref )
  }



  public trackByIndex = i => i;

  public initilaiseViewTable() {
    //console.log('initilaiseViewTable');

    //console.log('initilaiseViewTable tableFields', this.tableFields);

    if (this.attributes) {
      // Filter out the attributes to avoid dot notation, ie feature.attributes[field.name] 
      // (otherwise sort wont work - the table data needs to be a simple array) 
      // feature.attributes[field.name] --> feature[field.name]
      this.tableDataSource = new MatTableDataSource(this.attributes);
      this.tableDataSource.sort = this._sort;
      this.tableDataSource.paginator = this._paginator;   
    }
    else {
      this.tableDataSource = null;
      this._paginator.pageIndex = 0;
      this._paginator.length = 0;
    }

  }

  // Feature Selected from Table
  public tableRowSelected(attribute:any, origin: string) {
    //console.log(feature);

    // origin "TABLE" or "MAP"
    //console.log('tableRowSelected', origin);

    if (attribute) {
      // Highlight the row in the table
      this.selectedRow = attribute.OBJECTID;
      //console.log('tableRowSelected this._selectedRow', this.selectedRow);
    }
    else {
      this.selectedRow = null;
    }

    //console.log('setting _currentPage', this._page);
    this.currentPage = this.page;

    // Calculate the page number based on the index of the array
    this.page = this.getPageNumber(attribute);

    // Go to the page of the selected row
    this._paginator.pageIndex = this.page; // number of the page you want to jump.
    this.goToPage();

    const rowData = {
      origin,
      feature: attribute
    }

    //console.log('tableRowSelected tableRowSelectedEvent.emit(rowData)', rowData);
    this.tableRowSelectedEvent.emit(rowData);
  }

  // Search / filter the data table
  public applyFilter(event) {
    //console.log('event', event);
    console.log(this.tableDataSource);
    //console.log('searchMapEvent Results are: ', + event);

    const filterValue = (event.target as HTMLInputElement).value;
    //console.log('filterValue', filterValue);

    if (filterValue) {
      this.tableDataSource.filter = filterValue.trim().toLowerCase();
    }
    else {
      this.tableDataSource.filter = '';
    }
    
    //console.log('filter', this._tableDataSource.filter);
  }

  // //
  // // Toolbar header action buttons
  // //

  // public addBtnSelected() {
  //   console.log('addButtonSelectedEvent');
  //   this.addBtnSelectedEvent.emit();
  // }

  // public reportBtnSelected(event) {
  //   console.log('reportButtonSelected');
  //   this.reportBtnSelectedEvent.emit(event);
  // }

  //
  // Page Management
  //

  // Get Page Number in table of selected row
  public getPageNumber(feature: any)  {
    //console.log('selected row:', this._selectedRow);
    let page = 0;
    let index = -1;

    if (feature) {
      // Find the index
      //this._features.find(function(item, i) {
      this.attributes.find(function(item, i) {
        //if (item.attributes.OBJECTID === feature.attributes.OBJECTID) {
          if (item.OBJECTID === feature.OBJECTID) {
          index = i;
          return i;
        }
      });
    }
    else if (this.selectedRow) {
      index = this.selectedRow - 1;
    }
    else {
      index = 0;
    }

    // Calculate the page number based on the index of the array
    //page = (Math.ceil((index + 1) / this.pageSize)) - 1;  // 0 based
    page = this.calculatePageNumber(index);
    //console.log(this._page);

    return page;
  }

  // Get Page Number in table of selected row
  public gotoPageNumberObjectId(objectId: number)  {
    console.log('objectId gotoPageNumberObjectId:', objectId);
    let page = 0;
    let index = -1;

    if (this.attributes) {
      // Find the index
      this.attributes.find(function(item, i) {
        if (item.OBJECTID === objectId) {
          index = i;
          return i;
        }
      });
    }
    else {
      index = 0;
    }

    // Calculate the page number based on the index of the array
    //page = (Math.ceil((index + 1) / this.pageSize)) - 1;  // 0 based
    page = this.calculatePageNumber(index);
    //console.log(this._page);

    this.page = page;
    this._paginator.pageIndex = this.page; // number of the page you want to jump.

    console.log('this._page gotoPageNumberObjectId:', this.page);

    // Go to the page
    this.goToPage();

    return page;
  }

  private calculatePageNumber(index: number) {
    // Calculate the page number based on the index of the array
    return (Math.ceil((index + 1) / this.pageSize)) - 1;  // 0 based
  }

  public goToCurrentPage() {
    console.log('goToCurrentPage()', this.currentPage);
    this._paginator.pageIndex = this.currentPage;
    this.page = this.currentPage;

    this.goToPage();
  }

  private goToPage() {   //page?: number

    //console.log('go to page:', page);
    // let _page: number;

    // if (page) {
    //   _page = page;
    //   this._page = _page;
    // }
    // else {
    //   _page = this._page;
    // }
    
    this._paginator.page.next({      
      pageIndex: this.page,
      pageSize: this._paginator.pageSize,
      length: this._paginator.length
    });
  }

  // Set / resize the pagination ribbon
  public setPagination(height: number) { //, width?: number

    if (height > 0) {
      this.pageSize = Global.pageSize;

      if (height >= 500) {
        this.pageSize = Math.floor((height - 380) / 47);
        //this._pageSize = Math.floor((height - 350) / 47);
        //this._pageSize = Math.floor((height - 280) / 47);
      }
      else {
        this.pageSize = 1;
      }
    }

    this.page = this.getPageNumber(null);      

    // Update current settings
    this._paginator.pageSize = this.pageSize;
    this._paginator.pageIndex = this.page;

    // Go to the page
    this.goToPage();
  }

  //
  // Table Drag and Drop Events
  //

  public drop(event: CdkDragDrop<string[]>) {
    console.log('drop', event);
    moveItemInArray(this.displayedColumns, event.previousIndex, event.currentIndex);
  }

}