import { stringify } from '@angular/compiler/src/util';
import { getTime, toDate, parse, eachMonthOfInterval, min, max, add, differenceInYears, differenceInMonths, differenceInDays } from 'date-fns'

export interface Suburb {
    PlaceName: string;
    Longitude: number;
    Latitude: number;
}

export interface GroupBy {
    From: any;
    Label?: any;
}

export interface GroupByRange {
    From: any;
    To: any;
    Label?: any;
}

export interface GroupArray {
    Group: any[];
    Label?: any;
}

export class Global {
    public static title: string = 'CrispOra Golf Club';
    public static clubId: string = '20126';
    public static riskRegisterName: string = 'DEFAULT';
    public static riskMatrixSize: string = '5x5';
    public static riskMatrixVersion: string = 'C';

    public static assetHostServer: string = 'https://kkh.on-par-technology.com/';

    public static pageSize: number = 18;

    public static responsiveBreakpoint: number = 1280;
    public static responsiveScreenHeight: number = 1400; // window height set in css for width < _responsiveBreakpoint

    public static monthFinYearShortNames = ["All", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec", "Jan", "Feb", "Mar", "Apr", "May", "Jun"];
    public static monthFinYearLongNames = ["All", "July", "August", "September", "October", "November", "December", "January", "February", "March", "April", "May", "June"];
    public static monthFirstLetter = ["0", "J", "F", "M", "A", "M", "J", "J", "A", "S", "O", "N", "D"];
    public static monthShortNames = ["All", "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    public static monthLongNames = ["All", "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

    // Integrations
    public static integrationEnabled_ArcGisWorkForce: boolean = true;

    // // Date functions
    // public static formatDate(date: string) : string {
    //     let dateInput = new Date(date);
    //     const day = dateInput.getDate();
    //     const monthIndex = dateInput.getMonth();
    //     const year = dateInput.getFullYear();

    //     return day + ' ' + Global.monthShortNames[monthIndex + 1] + ' ' + year;
    // }
      
    public static getCurrentFinancialYearStartDate() : Date {
        let result: Date;

        const today: Date = new Date();
        let year: number = today.getFullYear();
        let month: number = today.getMonth();  // Jan = 0

        if (month < 6) {
            year = (year - 1);
            
        }
        else if (month >= 6) {
            // No change to year
        }

        // July = 6
        result = new Date(year, 6, 1);
        return result;
    }

    public static getCurrentFinancialYearEndDate() : Date {
        let result: Date;

        const today: Date = new Date();
        let year: number = today.getFullYear();
        let month: number = today.getMonth();  // Jan = 0

        if (month < 6) {
            // No change to year
        }
        else if (month >= 6) {
            year = (year + 1);
        }

        // June = 5
        result = new Date(year, 5, 30);
        return result;
    }

    public static getCurrentFinancialYear() : string {
        let result: string;
        const today: Date = new Date();
        const year: number = today.getFullYear();
        const month: number = today.getMonth();  // Jan = 0

        // Based on current month
        // Jul - Dec, Year A = this year, Year B = next year
        // Jan - Jun, Year A = last year, Year B = this year
        // Format YYYY-YY

        if (month < 6) {
            result = (year - 1).toString() + '-' + year.toString().substr(2, 2);
        }
        else if (month >= 6) {
            result = year.toString() + '-' + (year + 1).toString().substr(2, 2);
        }
        else {
            result = "ERROR";
        }

        return result;
    }

    public static getCurrentFinancialYearDates() : any {
        let startYear: number;
        let endYear: number;
        let result: any = {};
        const today: Date = new Date();
        const year: number = today.getFullYear();
        const month: number = today.getMonth();  // Jan = 0

        // Based on current month
        // Jul - Dec, Year A = this year, Year B = next year
        // Jan - Jun, Year A = last year, Year B = this year
        // Format YYYY-MM-DD

        if (month < 6) {
            startYear = (year - 1);
            endYear = year;
        }
        else if (month >= 6) {
            startYear = year;
            endYear = (year + 1);
        }

        let start = parse(startYear.toString() + '-07-01', 'yyyy-MM-dd', new Date());
        let end = parse(endYear.toString() + '-06-30', 'yyyy-MM-dd', new Date());

        result = {
            start,
            end
        }

        return result;
    }
    
    public static getCurrentFinancialPeriod() : number {
        let result: number;
        const today: Date = new Date();
        const month: number = today.getMonth();  // Jan = 0
    
        // Return 1=Jul ... 12=Jun
        if (month < 6) {
            result = month + 7;
        }
        else if (month >= 6) {
            result = month - 5;
        }
        else {
            result = 0;
        }
    
        return result;
    }

    public static getFinancialYearDates(date: Date) : GroupByRange {
        let startYear: number;
        let endYear: number;
        let result: GroupByRange;
        let financialYear: string;
        const year: number = date.getFullYear();
        const month: number = date.getMonth();  // Jan = 0

        // Based on current month
        // Jul - Dec, Year A = this year, Year B = next year
        // Jan - Jun, Year A = last year, Year B = this year
        // Format YYYY-YY

        if (month < 6) {
            startYear = (year - 1);
            endYear = year;
            financialYear = (year - 1).toString() + '-' + year.toString().substr(2, 2);
        }
        else if (month >= 6) {
            startYear = year;
            endYear = (year + 1);
            financialYear = year.toString() + '-' + (year + 1).toString().substr(2, 2);
        }
        else {
            financialYear = "ERROR";
        }

        let start = parse(startYear.toString() + '-07-01', 'yyyy-MM-dd', new Date());
        let end = parse(endYear.toString() + '-06-30', 'yyyy-MM-dd', new Date());

        result = {
            Label: financialYear,
            From: start,
            To: end
        }

        return result;
    }

    public static getFinancialYear(date: Date): string {
        //console.log('getFinancialYear', date);
        let startYear: number;
        let endYear: number;
        let financialYear: string;

        const _date = new Date(date);

        const year: number = _date.getFullYear();
        const month: number = _date.getMonth();  // Jan = 0

        // Based on current month
        // Jul - Dec, Year A = this year, Year B = next year
        // Jan - Jun, Year A = last year, Year B = this year
        // Format YYYY-YY

        if (month < 6) {
            startYear = (year - 1);
            endYear = year;
            financialYear = (year - 1).toString() + '-' + year.toString().substr(2, 2);
        }
        else if (month >= 6) {
            startYear = year;
            endYear = (year + 1);
            financialYear = year.toString() + '-' + (year + 1).toString().substr(2, 2);
        }
        else {
            financialYear = "ERROR";
        }

        return financialYear;
    }

    public static getFinancialPeriod(date: Date): number {
        let financialPeriod: number;
        const _date = new Date(date);
        const month: number = _date.getMonth();  // Jan = 0, Jun = 5

        // Format YYYY-YY
        if (month < 6) {
            financialPeriod = month + 7;
        }
        else if (month >= 6) {
            financialPeriod = month - 5;
        }
        else {
            financialPeriod = -1;
        }

        return financialPeriod;
    }

    public static getMonthFirstLetter(monthNumber: number): string {
        // Assume Jan = 1
        return Global.monthFirstLetter[monthNumber];
    }

    public static getMonthShortName(monthNumber: number): string {
        // Assume Jan = 1
        return Global.monthShortNames[monthNumber];
    }
    
    public static getMonthsDateRange(startDate: Date, endDate: Date): any {
        const months: Date[] = eachMonthOfInterval({
            start: startDate,
            end: add(endDate, {months: 1})
        });

        const result = {
            start: startDate,
            end: endDate,
            months
        }

        return result;
    }

    public static getDateRangeWithFY(startDate: Date, endDate: Date): any {
        const financialYear = Global.getCurrentFinancialYearDates();
        const start: Date = min([startDate, financialYear.start]);
        const end: Date = add(max([endDate, financialYear.end]), {months: 1});
        const months: Date[] = eachMonthOfInterval({
            start,
            end
        });

        const result = {
            financialYear,
            start,
            end,
            months
        }

        return result;
    }   
    
    public static calculateYearsToNow(date: Date): number {
        return (date) ? differenceInYears(Date.now(), date) : null;
    }

    public static calculateMonthsToNow(date: Date): number {
        return (date) ? differenceInMonths(Date.now(), date) : null;
    }

    public static calculateDaysToNow(date: Date): number {
        return (date) ? differenceInDays(Date.now(), date) : null;
    }

    public static calculateYears(laterDate: Date, earlierDate: Date): number {
        return differenceInYears(laterDate, earlierDate);
    }
 
    // Address functions
    public static formatFullAddress(addressLine1: string, addressLine2: string, suburb: string, state: string, postcode: string, country?: string) {
        let result: string = "";

        (addressLine1) ? result = addressLine1 : '';
        (addressLine2) ? result += " " + addressLine2 : '';
        (suburb) ? result += " " + suburb : '';
        (state) ? result += " " + state : '';
        (postcode) ? result += " " + postcode : '';
        (country) ? result += " " + country : '';

        return result;
    }

    // YouTube functions
    public static getYouTubeId(url: string) : string {
        return url.split('=')[1];
    }

    // Array Sort Functions
    public static sortArrayAsc(array: any[], field) {
        // Sort by the index
        return array.sort(compare);

        function compare(a, b) {
            //const A = a.includeInTableIndex;
            //const B = b.includeInTableIndex;

            const A = a[field];
            const B = b[field];
            
            let comparison = 0;
            if (A > B) {
                comparison = 1;
            } else if (A < B) {
                comparison = -1;
            }
            return comparison;
        }
    }

    public static sortArrayDesc(array: any[], field) {
        // Sort by the index
        return array.sort(compare);

        function compare(a, b) {
            //const A = a.includeInTableIndex;
            //const B = b.includeInTableIndex;

            const A = a[field];
            const B = b[field];
            
            let comparison = 0;
            if (A < B) {
                comparison = 1;
            } else if (A > B) {
                comparison = -1;
            }
            return comparison;
        }
    }

}
