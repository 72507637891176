import { Component, OnInit, OnDestroy, AfterViewInit, Output, ChangeDetectionStrategy, ChangeDetectorRef, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { combineLatest } from 'rxjs';
import { takeUntil, tap, first } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { BaseFeaturePage } from 'src/app/_esri/models/base-feature-page';
import { FeaturesService } from 'src/app/_esri/services/features.service';
import { CourseService } from 'src/app/_course/services/course.service';
import { SurroundsService } from 'src/app/_course/services/surrounds.service';
import { GeocodeService } from 'src/app/_esri/services/geocode.service';
import { Esri, DashboardChart, Address, GeometryPoint, GeometryMultiPoint } from 'src/app/_esri/models/esri';
//import { IncidentService } from 'src/app/incident/services/incident.service';
import { CurrentModeService, CurrentModeData } from 'src/app/_globals/services/current-mode.service';
import { GeocodeDataService } from 'src/app/_globals/services/geocode-data.service';
import { FormChangeService } from 'src/app/_globals/services/form-change.service';
import { FormFieldChangeService } from 'src/app/_globals/services/form-field-change.service';
import { FormFieldUpdateService } from 'src/app/_globals/services/form-field-update.service';
import { DomainLovService } from 'src/app/_esri/services/domain-lov.service';
import { LovDataService } from 'src/app/_globals/services/lov-data.service';

import { MemberReciprocalClub } from 'src/app/member/models/member';
import { Global } from 'src/app/_globals/models/global';
import { EsriViewTableComponent } from 'src/app/_esri/components/esri-view-table/esri-view-table.component';
import { EditComponent } from 'src/app/_esri/dialogs/edit/edit.component';


@Component({
  selector: 'app-member-reciprocal',
  templateUrl: './member-reciprocal.component.html',
  styleUrls: [
    './member-reciprocal.component.scss',
    '../../../_styles/base-feature-page.scss'
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MemberReciprocalComponent extends BaseFeaturePage implements OnInit, OnDestroy, AfterViewInit {

  title = 'Reciprocal Clubs';

  showDataCards = false;
  showDataTable = true;
  darkMode = false;
  includeNswProperty = false;

  public member_basemapName = 'gray'; // dark-gray
  public centre = Esri.initialCenter_Global;
  public zoom = 3;

  constructor(
    protected ref: ChangeDetectorRef,
    protected dialog: MatDialog,
    protected currentModeService: CurrentModeService,
    protected geocodeDataService: GeocodeDataService,
    protected formChangeService: FormChangeService,
    protected formFieldChangeService: FormFieldChangeService,
    protected formFieldUpdateService: FormFieldUpdateService,
    protected elementRef: ElementRef,
    // --
  ) {
    super(
      ref,
      dialog,
      currentModeService,
      geocodeDataService,
      formChangeService,
      formFieldChangeService,
      formFieldUpdateService,
      elementRef
    );
  }

  ngOnInit(): void {
    this.thinking = true;
    // Get layers, features, lovs, domains, etc
    this.featureLayerObject = new MemberReciprocalClub();
    super.ngOnInit();
  }

  public viewCreatedEvent(view: any) {
    this.esriMapComponent.reportBtnHidden = true;
    this.esriMapComponent.addBtnHidden = true;
    super.viewCreatedEvent(view);
  }

  protected refreshDisplay() {
    this.featureLayerObject.updateVirtualFields();
    super.refreshDisplay();
  }

  //
  // Workflow - Control button and editor behaviour based on current feature edit mode
  //

  protected setCurrentMode(currentModeData: CurrentModeData) {

    console.log('Incident setCurrentMode currentModeData', currentModeData);
    super.setCurrentMode(currentModeData);
    
    let mode = currentModeData.mode.toLowerCase();

    if (currentModeData.formName.toUpperCase() === this.formName.toUpperCase()) { 
      console.log('setCurrentMode() FORM mode', mode);

      switch (mode) {
        case 'edit-attribute':
          this.openEditFeatureDialog(mode);
          break;

        case 'close':
          this.esriMapComponent.addBtnHidden = true;
          this.esriMapComponent.reportBtnHidden = true;
          break;
      }
    }

  }

}

