
<!-- Simple Text Field -->
<span *ngIf='(field.visible || fieldAlwaysVisible) && field.editorType != "date" && field.editorType != "text-area" && !field.domain && !field.domain_lov && !field.domain_lovParent'>
    {{limitDisplayLength(attribute[field.name], 35)}}
</span>

<!-- Textarea Field -->
<span *ngIf='(field.visible || fieldAlwaysVisible) && field.editorType != "date" && field.editorType === "text-area" && !field.domain && !field.domain_lov && !field.domain_lovParent'
    [innerHTML]='limitDisplayLength(toHTML(attribute[field.name]), 50)'>
</span>

<!-- Date Field -->
<span *ngIf='(field.visible || fieldAlwaysVisible) && field.editorType === "date" && field.editorType != "text-area" && !field.domain && !field.domain_lov && !field.domain_lovParent'>
    {{epochToDate(attribute[field.name])}}
</span>

<!-- ArcGIS domain Field -->
<!-- <span *ngIf='(field.visible || fieldAlwaysVisible) && field.editorType != "date" && field.editorType != "text-area" && field.domain && !field.domain_lov && !field.domain_lovParent'>
    {{getDomainValue(field.domain, attribute[field.name])}}
</span> -->
<span *ngIf='(field.visible || fieldAlwaysVisible) && field.editorType != "date" && field.editorType != "text-area" && field.domain && !field.domain_lov && !field.domain_lovParent'>
    <span *ngIf='field.domain != "DomYesNo"'>{{attribute[field.domain_VirtualFieldName]}}</span>
    <span *ngIf='field.domain === "DomYesNo"'>{{getDomainYesNoValue(attribute[field.name])}}</span>
</span>

<!-- ArcGIS LOV table Field -->
<!-- <span *ngIf='(field.visible || fieldAlwaysVisible) && field.editorType != "date" && field.editorType != "text-area" && !field.domain && field.domain_lov && !field.domain_lovParent'>
    {{getDomainLovValue(field.domain_lov, attribute[field.name])}}
</span> -->
<span *ngIf='(field.visible || fieldAlwaysVisible) && field.editorType != "date" && field.editorType != "text-area" && !field.domain && field.domain_lov && !field.domain_lovParent'>
    {{attribute[field.domain_VirtualFieldName]}}
</span>

<!-- ArcGIS LOV (with parent) table Field -->
<!-- <span *ngIf='(field.visible || fieldAlwaysVisible) && field.editorType != "date" && field.editorType != "text-area" && !field.domain && field.domain_lov && field.domain_lovParent'>
    {{getDomainLovParentValue(field.domain_lovParent, field.domain_lov, attribute, attribute[field.name])}}
</span>  -->
<span *ngIf='(field.visible || fieldAlwaysVisible) && field.editorType != "date" && field.editorType != "text-area" && !field.domain && field.domain_lov && field.domain_lovParent'>
    {{attribute[field.domain_VirtualFieldName]}} 
    <!-- {{attribute[field.name]}} -->
</span> 
