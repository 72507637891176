<div style="margin-top: 10px;">
    <!-- Header with search bar and action buttons -->
    <mat-toolbar>
        <!-- Search -->
        <div fxLayout="row" fxLayoutAlign="start center" style='background-color: white; width:400px; border: none; height: 36px; border-radius: 18px; margin-left:20px; padding-left:10px;'> 
            <mat-icon>search</mat-icon>
            <input matInput #search (keyup)='applyFilter($event)' placeholder='Search...' style='background-color: white; width:320px; border: none; line-height: 32px; font-size: 18px;'>
            <button mat-button *ngIf="search.value" matSuffix mat-icon-button aria-label="Clear" (click)="search.value=''; applyFilter($event)">
                <mat-icon class='material-icons-sharp'>close</mat-icon>
            </button>
        </div>

        <span class="toolbar-spacer"></span>

        <!-- Buttons -->
        <button mat-mini-fab *ngIf="showAddHeaderBtn" color="accent" aria-label="" (click)="addHeaderBtnSelected()" style='margin-right:20px;'>
            <mat-icon class='material-icons-sharp'>add</mat-icon>
        </button>

        <button mat-mini-fab *ngIf="showReportHeaderBtn" color="accent" aria-label="" (click)="reportHeaderBtnSelected($event)" style='margin-right:20px;'>
            <mat-icon class='material-icons-sharp'>text_snippet</mat-icon>
        </button>
    </mat-toolbar>

    <!-- Table -->
    <table #viewTable mat-table matSort [dataSource]='tableDataSource' cdkDropList cdkDropListOrientation="horizontal" (cdkDropListDropped)="drop($event)">
        <!-- Row Actions -->
        <ng-container *ngIf='showActionIconsInTable' matColumnDef="actionIcons">
            <th mat-header-cell cdkDrag *matHeaderCellDef>Actions</th>
            <td mat-cell *matCellDef="let feature">
                <div fxLayout='row' fxLayoutAlign='center end'>
                    <button mat-icon-button *ngIf="showInfoActionBtn" aria-label="Button with a information icon" (click)='$event.stopPropagation(); infoActionBtnSelected(feature)'>
                        <mat-icon class='material-icons-sharp row-action-icon'>info</mat-icon>
                    </button>  
                    <button mat-icon-button *ngIf="showEditActionBtn" aria-label="Button with a edit icon" (click)='$event.stopPropagation(); editActionBtnSelected(feature)'>
                        <mat-icon class='material-icons-sharp row-action-icon'>edit</mat-icon>
                    </button>
                    <button mat-icon-button *ngIf="showAttachActionBtn" aria-label="Button with a attach file icon" (click)='$event.stopPropagation(); attachActionBtnSelected(feature)'>
                        <mat-icon class='material-icons-sharp row-action-icon'>attach_file</mat-icon>
                    </button>
                    <button mat-icon-button *ngIf="showDeleteActionBtn" aria-label="Button with a delete icon" (click)='$event.stopPropagation(); deleteActionBtnSelected(feature)' [disabled]='true'>
                        <mat-icon class='material-icons-sharp row-action-icon'>delete_outline</mat-icon>
                    </button>
                </div>
            </td>
        </ng-container>
        <!-- Row Data -->                                                                                             
        <span *ngFor='let field of tableFields; trackBy: trackByIndex'>
            <ng-container matColumnDef='{{field.name}}'>
                <!-- <th mat-header-cell *matHeaderCellDef mat-sort-header cdkDrag>{{field.label}}</th> -->
                <th mat-header-cell *matHeaderCellDef cdkDrag>{{field.label}}</th>
                <td mat-cell *matCellDef='let feature'>
                    <app-esri-view-table-elements 
                        [field]="field" 
                        [attribute]="feature"
                        [domains]="domains"
                        [domains_lov]="domains_lov"
                        [domains_lovParent]="domains_lovParent">
                    </app-esri-view-table-elements>  
                </td>
            </ng-container>     
        </span>
        <tr mat-header-row *matHeaderRowDef='displayedColumns'></tr>
        <tr mat-row *matRowDef='let row; columns: displayedColumns;' [ngClass]='{"mat-row-highlight": selectedRow == row.OBJECTID}' class='table-element-row' (click)='tableRowSelected(row, "TABLE");'></tr> <!-- tableRowClickDisabled ? $event.stopPropagation() : tableRowSelected(row, true);-->
    </table>
    <!-- Paginator -->
    <mat-paginator [pageSize]='pageSize' [pageIndex]='page' showFirstLastButtons></mat-paginator>
</div>