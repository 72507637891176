import { Component, OnInit, OnDestroy, ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';
//import { FieldConfig} from 'src/app/_esri/models/esri';
import { BaseFeatureView } from 'src/app/_esri/models/base-feature-view';
import { CardService } from './card.service';
import { Card } from './card';

@Component({
  selector: 'app-esri-view-cards',
  templateUrl: './esri-view-cards.component.html',
  styleUrls: ['./esri-view-cards.component.scss']
})

export class EsriViewCardsComponent extends BaseFeatureView implements OnInit, OnDestroy {
//export class EsriViewCardsComponent implements OnInit, OnDestroy {

  public selectedRow : number;            // ObjectID
  public selectedRowIndex : number;       // ngFor Index
  //public trackByIndex = i => i;
  private _cardHeightIncludePadding: number = 132;
  public cards: Card[] = [];

  // Card Data Fields
  public cardFieldConfigs: any;

        // cardFieldConfigs = {
        //     All,
        //     Title,
        //     SubTitle,
        //     ContentRow1,
        //     ContentRow2,
        //     ContentRow3
        // }

  @ViewChild("scrollable", { static: true }) protected scrollableEl: any;

  constructor(
    protected ref: ChangeDetectorRef,
    private cardService: CardService
  ) { 
    super( ref );
  }

  ngOnInit() {
    super.ngOnInit();
  }

  public initialiseViewCards() {

    //console.log('initialiseCards cardFieldConfigs', this.cardFieldConfigs);

    // this.cardService.domains = this.domains;
    // this.cardService.domains_lov = this.domains_lov;
    // this.cardService.domains_lovSubDomain = this.domains_lovParent;
    this.cardService.attributes = this.attributes;
    this.cardService.cardFieldConfigs = this.cardFieldConfigs;

    this.cardService.lengthLimit_title = 35;
    this.cardService.lengthLimit_subTitle = 55;
    // this.cardService.lengthLimit_contentRow1 = 60;
    // this.cardService.lengthLimit_contentRow2 = 60;
    this.cardService.lengthLimit_contentRow3 = 60;

    this.cards = this.cardService.getCards();
  }

  // Feature Selected from Card List
  public cardSelected(feature:any, origin: string) {
    //console.log(attribute);
    // origin "TABLE" or "MAP"
    console.log('cardSelected', origin);

    let index: number = 0;
    let numberCardsShownOnScreen: number = 6;

    if (feature) {
      // Find the index of the selected card
      index = this.attributes.findIndex(x => x.OBJECTID === feature.OBJECTID);

      // Highlight the row in the table
      this.selectedRow = feature.OBJECTID;
      console.log('cardSelected this._selectedRow', this.selectedRow, index);
    }
    else {
      index = 0;
      this.selectedRow = null;
    }

    const scrollAmount = index * this._cardHeightIncludePadding;
    //this.scrollableEl.scrollTo({top: scrollAmount, duration: 800});
    this.scrollableEl.scrollTo({top: scrollAmount - (this._cardHeightIncludePadding * (numberCardsShownOnScreen - 1)), duration: 800});

    const rowData = {
      origin,
      feature: feature
    }

    console.log('cardSelected tableRowSelectedEvent.emit(rowData)', rowData);
    this.tableRowSelectedEvent.emit(rowData);
  }

}
