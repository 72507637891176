import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class LovDataService {
  public lovData = new Subject();
  constructor() { }
}
