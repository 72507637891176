//import { FormArray } from '@angular/forms';
import { Injectable, Output, EventEmitter } from '@angular/core';
import { combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Esri, FeatureLayerProperties, ElevationInfo, EsriRenderer_Polygon, EsriRenderer_Polyline, EsriRenderer_Point, FormFieldConfig, FieldConfig, FieldDateFormat, FieldNumberFormat, EsriGeometryType } from 'src/app/_esri/models/esri';
import { LayerIds } from 'src/app/_esri/models/layer-ids';
import { Global } from 'src/app/_globals/models/global';
import { LovDataService } from 'src/app/_globals/services/lov-data.service';
import { DomainLovService } from 'src/app/_esri/services/domain-lov.service';
import { FeatureLayerObjectBaseAttributes, FeatureLayerObjectBase } from 'src/app/_esri/models/base-feature-class';


@Injectable()
export abstract class AssetAttributes extends FeatureLayerObjectBaseAttributes {
  public AssetId: string;
  public AssetClass: string;
  public AssetCategory: string;
  public AssetType: string;
  public AssetParent: string;
  public ParentAssetId: string;
  public Description: string;
  public ConstructionCost: number;
  public ConstructionDate_Start: Date;
  public ConstructionDate_End: Date;
  public ConstructionFinancialYear: string;
  public ConstructionBy: string;
  public Material: string;	
  public Length: number;
  public Width: number;
  public Height: number;
  public Area: number;
  public Condition: string;
  public Status: string;
  public Comments: string;

  constructor(
    protected lovDataService: LovDataService,
    protected domainLovService: DomainLovService
  ) {
    super(
      lovDataService,
      domainLovService
    );
  }

  // Virtual Fields

  // AssetClass
  // AssetCategory
  // AssetType
  // AssetParent
  // ConstructionFinancialYear
  // Material
  // Condition
  // Status

  get AssetClass_DomainDesc(): string {
    if (this.AssetClass) {
      return this.domainLovService.getDomainValue('DomAssetClass', this.AssetClass);
    }
    else {
      return null;
    }
  }

  get AssetParent_DomainDesc(): string {
    if (this.AssetParent) {
      return this.domainLovService.getDomainValue('DomAssetParent', this.AssetParent);
    }
    else {
      return null;
    }
  }

  get FinancialYear(): string {
    if (this.ConstructionFinancialYear) {
      return this.domainLovService.getDomainValue('DomFinancialYear', this.ConstructionFinancialYear);
    }
    else {
      return null;
    }
  }

  get Condition_DomainDesc(): string {
    if (this.Condition) {
      return this.domainLovService.getDomainValue('DomAssetCondition', this.Condition);
    }
    else {
      return null;
    }
  }

  get Status_DomainDesc(): string {
    if (this.Status) {
      return this.domainLovService.getDomainValue('DomAssetStatus', this.Status);
    }
    else {
      return null;
    }
  }

  get AssetCategory_DomainDesc(): string { return }
  get AssetType_DomainDesc(): string { return }
  get Material_DomainDesc(): string { return }

}

@Injectable()
export class Asset extends FeatureLayerObjectBase {
  protected materialDomain: string;
  protected assetCategoryDomain: string;
  protected assetTypeLabel: string;
  protected assetTypeDomain: string;
  //protected existingOnly: boolean

  server = LayerIds.gisServer_Assets;
  folder = LayerIds.folder_Assets;

  minScale = 5000;
  whereClause = "1=1";
  orderByClause = 'OBJECTID DESC'; // (CreationDate doesn't exist yet on the service)
  
  elevationInfoMode = 'on-the-ground';

  constructor(
    // protected lovDataService: LovDataService,
    // protected domainLovService: DomainLovService,
    //protected _existingOnly?: boolean,
    protected _geometryType?: any
  ) {
    super(
      // lovDataService,
      // domainLovService
    );

    // if (_existingOnly) {
    //   this.existingOnly = _existingOnly;
    // }

    if (_geometryType) {
      this.geometryType = _geometryType;
    }

    //console.log('Asset extends FeatureBase constructor() _existingOnly 1', this.existingOnly);

    // if (this.existingOnly === true) {
    //   console.log('Asset extends FeatureBase constructor() _existingOnly 2', this.existingOnly);
    //   this.definitionExpression = "Status = 'EXISTING'";
    // }

    console.log('Asset extends FeatureBase constructor() this.geometryType', this.geometryType);

    switch (this.geometryType) {
      case EsriGeometryType.Point:
      case EsriGeometryType.MultiPoint:
        this.renderer = new EsriRenderer_Point([112, 168, 0, 0.5], [112, 168, 0, 0.5], 0);
        break;

      case EsriGeometryType.Polyline:
        this.renderer = new EsriRenderer_Polyline([112, 168, 0, 0.5], 1);
        break;

      case EsriGeometryType.Polygon:
        this.renderer = new EsriRenderer_Polygon([112, 168, 0, 0.5], [112, 168, 0, 0.5], 0);
        break;
    }

    console.log('Asset extends FeatureBase constructor() this.renderer', this.renderer);
  }

  protected async createFormFieldConfigs(): Promise<FormFieldConfig[]> {
    let formFieldConfig: FormFieldConfig;
    let fieldConfigs: FieldConfig[] = [];
    let fieldConfig: FieldConfig;
    let format: any;

    // Description

    fieldConfig = new FieldConfig('AssetId', 'Asset Id:', 'text');
    fieldConfig.includeInTable = true;
    fieldConfig.includeInTableIndex = 0;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('AssetClass', 'Asset Class', 'text');
    fieldConfig.maxLength = 20;
    fieldConfig.domain = 'DomAssetClass';
    fieldConfig.domain_VirtualFieldName = 'AssetClass_DomainDesc';
    fieldConfig.editable = false;
    fieldConfig.visible = false;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('AssetCategory', 'Asset Category', 'text');
    fieldConfig.maxLength = 20;
    fieldConfig.domain = this.assetCategoryDomain;
    fieldConfig.domain_VirtualFieldName = 'AssetCategory_DomainDesc';
    fieldConfig.editable = false;
    fieldConfig.visible = false;
    fieldConfigs.push(fieldConfig);
    
    fieldConfig = new FieldConfig('AssetType', 'Asset Type:', 'text');
    fieldConfig.includeInTable = true;
    fieldConfig.includeInTableIndex = 1;
    fieldConfig.label = this.assetTypeLabel;
    fieldConfig.domain = this.assetTypeDomain;
    fieldConfig.domain_VirtualFieldName = 'AssetType_DomainDesc';
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('AssetParent', 'Asset Parent:', 'text');
    fieldConfig.domain = 'DomAssetParent';
    fieldConfig.domain_VirtualFieldName = 'AssetParent_DomainDesc';
    fieldConfig.visible = false;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('ParentAssetId', 'Parent Asset Id:', 'text-area');
    fieldConfig.visible = false;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('Description', 'Description:', 'text');
    fieldConfig.includeInTable = true;
    fieldConfig.includeInTableIndex = 2;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('ConstructionCost', 'Construction Cost:', 'number');
    format = new FieldNumberFormat(2);
    fieldConfig.prefix = '$';
    fieldConfig.format = format;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('ConstructionDate_Start', 'Start Construction Date:', 'date');
    format = new FieldDateFormat();
    fieldConfig.format = format;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('ConstructionDate_End', 'End Construction Date:', 'date');
    format = new FieldDateFormat();
    fieldConfig.format = format;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('ConstructionFinancialYear', 'Financial Year Constructed:', 'text');
    fieldConfig.domain = 'DomFinancialYear';
    fieldConfig.domain_VirtualFieldName = 'FinancialYear';
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('ConstructionBy', 'Construction By:', 'text');
    fieldConfigs.push(fieldConfig);

    formFieldConfig = new FormFieldConfig('Description', fieldConfigs);
    this.formFieldConfigs.push(formFieldConfig);

    // Material and Size

    fieldConfigs = [];

    fieldConfig = new FieldConfig('Material', 'Material:', 'text');
    fieldConfig.domain = this.materialDomain;
    fieldConfig.domain_VirtualFieldName = 'Material_DomainDesc';
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('Length', 'Length:', 'number');
    format = new FieldNumberFormat(2);
    fieldConfig.suffix = 'm';
    fieldConfig.format = format
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('Width', 'Width:', 'number');
    format = new FieldNumberFormat(2);
    fieldConfig.suffix = 'm';
    fieldConfig.format = format
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('Height', 'Height:', 'number');
    format = new FieldNumberFormat(2);
    fieldConfig.suffix = 'm';
    fieldConfig.format = format
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('Area', 'Area:', 'number');
    format = new FieldNumberFormat(2);
    fieldConfig.suffix = 'm<sup>2</sup>';
    //fieldConfig.suffix = 'sq.m';
    fieldConfig.format = format
    fieldConfigs.push(fieldConfig);

    formFieldConfig = new FormFieldConfig('Material and Size:', fieldConfigs);
    this.formFieldConfigs.push(formFieldConfig);

    // Status and Construction

    fieldConfigs = [];

    fieldConfig = new FieldConfig('Condition', 'Condition:', 'radio-v');
    fieldConfig.domain = 'DomAssetCondition';
    fieldConfig.domain_VirtualFieldName = 'Condition_DomainDesc';
    fieldConfig.includeInTable = true;
    fieldConfig.includeInTableIndex = 3;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('Status', 'Status:', 'radio-v');
    fieldConfig.domain = 'DomAssetStatus';
    fieldConfig.domain_VirtualFieldName = 'Status_DomainDesc';
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('Comments', 'Comments:', 'text-area');
    fieldConfigs.push(fieldConfig);

    formFieldConfig = new FormFieldConfig('Status and Condition', fieldConfigs);
    this.formFieldConfigs.push(formFieldConfig);

    super.createFormFieldConfigs();
    return this.formFieldConfigs;   
  }

  // protected updateVirtualFields(attr: any) {
  //   // Reset
  //   let attributes = [];

  //   this.features.forEach((f) => {
  //     //console.log('updateVirtualFields', f);
  //     //let attr: AssetAttributes = new AssetAttributes(this.lovDataService, this.domainLovService);
  //     attr.AssetId = f.attributes.AssetId;
  //     attr.AssetClass = f.attributes.AssetClass;
  //     attr.AssetCategory = f.attributes.AssetCategory;
  //     attr.AssetType = f.attributes.AssetType;
  //     attr.AssetParent = f.attributes.AssetParent;
  //     attr.ParentAssetId = f.attributes.ParentAssetId;
  //     attr.Description = f.attributes.Description;
  //     attr.ConstructionCost = f.attributes.ConstructionCost;
  //     attr.ConstructionDate_Start = f.attributes.ConstructionDate_Start;
  //     attr.ConstructionDate_End = f.attributes.ConstructionDate_End;
  //     attr.ConstructionFinancialYear = f.attributes.ConstructionFinancialYear;
  //     attr.ConstructionBy = f.attributes.ConstructionBy;
  //     attr.Material = f.attributes.Material;
  //     attr.Length = f.attributes.Length;
  //     attr.Width = f.attributes.Width;
  //     attr.Height = f.attributes.Height;
  //     attr.Area = f.attributes.Area;
  //     attr.Condition = f.attributes.Condition;
  //     attr.Status = f.attributes.Status;
  //     attr.Comments = f.attributes.Comments;

  //     // Common
  //     attr.OBJECTID = f.attributes.OBJECTID;
  //     attr.ClubId = f.attributes.ClubId;
  //     attr.Active = f.attributes.Active;
  //     attr.GlobalID = f.attributes.GlobalID;
  //     attr.HasLocation = f.attributes.HasLocation;
  //     attr.CreationDate = f.attributes.CreationDate;
  //     attr.Creator = f.attributes.Creator;
  //     attr.EditDate = f.attributes.EditDate;
  //     attr.Editor = f.attributes.Editor;

  //     // Populate virtual / calculated fields
  //     f.attributes.AssetClass_DomainDesc = attr.AssetClass_DomainDesc;
  //     f.attributes.AssetCategory_DomainDesc = attr.AssetCategory_DomainDesc;
  //     f.attributes.AssetType_DomainDesc = attr.AssetType_DomainDesc;
  //     f.attributes.AssetParent_DomainDesc = attr.AssetParent_DomainDesc;
  //     f.attributes.FinancialYear = attr.FinancialYear;
  //     f.attributes.Material_DomainDesc = attr.Material_DomainDesc;
  //     f.attributes.Condition_DomainDesc = attr.Condition_DomainDesc;
  //     f.attributes.Status_DomainDesc = attr.Status_DomainDesc;

  //     //console.log('updateVirtualFields attr f.attributes', attr, f.attributes);

  //     attributes.push(attr);
  //   });

  //   return attributes;
  // }

  // protected getLovs() {
  //   this.lovReadyEvent.emit();
  // }
}

// Asset Attributes

export class BallCleanerAttributes extends AssetAttributes {}
export class BinAttributes extends AssetAttributes {}
export class BridgeAttributes extends AssetAttributes {}

export class BuildingAttributes extends AssetAttributes {
  // Virtual Fields
  get AssetCategory_DomainDesc(): string {
    if (this.AssetCategory) {
      return this.domainLovService.getDomainValue('DomBuildingCategory', this.AssetCategory);
    }
    else {
      return null;
    }
  }

  get AssetType_DomainDesc(): string {
    if (this.AssetType) {
      return this.domainLovService.getDomainValue('DomBuildingType', this.AssetType);
    }
    else {
      return null;
    }
  }

  get Material_DomainDesc(): string {
    if (this.Material) {
      return this.domainLovService.getDomainValue('DomBuildingMaterial', this.Material);
    }
    else {
      return null;
    }
  }
}

export class Electrical_ConduitAttributes extends AssetAttributes {}
export class Electrical_EndPointAttributes extends AssetAttributes {}
export class Electrical_SubstationAttributes extends AssetAttributes {}
export class FenceAttributes extends AssetAttributes {}
export class LineMarkingAttributes extends AssetAttributes {}

export class FootpathAttributes extends AssetAttributes {
  // Virtual Fields
  get AssetCategory_DomainDesc(): string {
    if (this.AssetCategory) {
      return this.domainLovService.getDomainValue('DomRoadCategory', this.AssetCategory);
    }
    else {
      return null;
    }
  }

  get AssetType_DomainDesc(): string {
    if (this.AssetType) {
      return this.domainLovService.getDomainValue('DomFootpathType', this.AssetType);
    }
    else {
      return null;
    }
  }

  get Material_DomainDesc(): string {
    if (this.Material) {
      return this.domainLovService.getDomainValue('DomFootpathMaterial', this.Material);
    }
    else {
      return null;
    }
  }
}

export class PracticeNetAttributes extends AssetAttributes {}
export class SignAttributes extends AssetAttributes {}
export class Stormwater_ConduitAttributes extends AssetAttributes {}
export class Stormwater_PitAttributes extends AssetAttributes {}
export class UnderpassAttributes extends AssetAttributes {}
export class WaterSupply_ConduitAttributes extends AssetAttributes {}
export class WaterSupply_EndPointAttributes extends AssetAttributes {}
export class TelecommunicationAttributes extends AssetAttributes {}
export class WeirGPTAttributes extends AssetAttributes {}


// Asset Feature Classes

export class BallCleaner extends Asset {
  attributes: BallCleanerAttributes[];
  layerId = LayerIds.layerId_BallCleaner;
  id = 'featureLayerBallCleaner';
  title = 'Ball Cleaner';

  geometryType = EsriGeometryType.Point;
}

export class Bin extends Asset {
  attributes: BinAttributes[];
  layerId = LayerIds.layerId_Bin;
  id = 'featureLayerBin';
  title = 'Bin';

  geometryType = EsriGeometryType.Point;
}

export class Bridge extends Asset {
  attributes: BridgeAttributes[];
  layerId = LayerIds.layerId_Bridge;
  id = 'featureLayerBridge';
  title = 'Bridge';

  geometryType = EsriGeometryType.Polygon;
}

export class Building extends Asset {
  attributes: BuildingAttributes[];
  layerId = LayerIds.layerId_Building;
  id = 'featureLayerBuilding';
  title = 'Building';

  //geometryType = EsriGeometryType.Polygon;

  assetCategoryDomain = 'DomBuildingCategory';
  assetTypeLabel = 'Building Type:';
  assetTypeDomain = 'DomBuildingType';
  materialDomain = 'DomBuildingMaterial';

  maxScale = 0;
  minScale = 0;

  // protected updateVirtualFields(): any {
  //   let attr: BuildingAttributes = new BuildingAttributes(this.lovDataService, this.domainLovService);
  //   this.attributes = super.updateVirtualFields(attr);
  //   return;
  // }

  constructor(
  ) {
    super(
    );

    this.geometryType = EsriGeometryType.Polygon;
    //this.renderer = Esri.rendererActiveAsset_Polygon;
    console.log('Building extends Asset extends FeatureBase constructor()');
  }
}

export class Electrical_Conduit extends Asset {
  attributes: Electrical_ConduitAttributes[];
  layerId = LayerIds.layerId_Electrical_Conduit;
  id = 'featureLayerElectrical_Conduit';
  title = 'Electrical Conduit';

  geometryType = EsriGeometryType.Polyline;
}

export class Electrical_EndPoint extends Asset {
  attributes: Electrical_EndPointAttributes[];
  layerId = LayerIds.layerId_Electrical_EndPoint;
  id = 'featureLayerElectrical_EndPoint';
  title = 'Electrical EndPoint';

  geometryType = EsriGeometryType.Point;
}

export class Electrical_Substation extends Asset {
  attributes: Electrical_SubstationAttributes[];
  layerId = LayerIds.layerId_Electrical_Substation;
  id = 'featureLayerElectrical_Substation';
  title = 'Electrical Substation';

  geometryType = EsriGeometryType.Point;
}

export class Fence extends Asset {
  attributes: FenceAttributes[];
  layerId = LayerIds.layerId_Fence;
  id = 'featureLayerFence';
  title = 'Fence';

  geometryType = EsriGeometryType.Polyline;
}

export class LineMarking extends Asset {
  attributes: LineMarkingAttributes[];
  layerId = LayerIds.layerId_LineMarking;
  id = 'featureLayerLineMarking';
  title = 'Line Marking';

  geometryType = EsriGeometryType.Polyline;
}

export class Footpath extends Asset {
  attributes: FootpathAttributes[];
  layerId = LayerIds.layerId_Footpath;
  id = 'featureLayerFootpath';
  title = 'Cart Path';

  assetCategoryDomain = 'DomRoadCategory';
  assetTypeLabel = 'Path Type:';
  assetTypeDomain = 'DomFootpathType';
  materialDomain = 'DomFootpathMaterial';


  //renderer = Esri.rendererActiveAsset_Polygon;

  constructor(
    // protected lovDataService: LovDataService,
    // protected domainLovService: DomainLovService,
    //protected _existingOnly: boolean,
  ) {
    super(
      // lovDataService,
      // domainLovService,
      //_existingOnly
    );

    this.geometryType = EsriGeometryType.Polygon;

    //console.log('Footpath extends Asset extends FeatureBase constructor() _existingOnly 1', this.existingOnly);
    //console.log('Footpath extends Asset extends FeatureBase constructor() this.geometryType', this.geometryType);
  
  }


  // protected updateVirtualFields(): any {
  //   let attr: FootpathAttributes = new FootpathAttributes(this.lovDataService, this.domainLovService);
  //   this.attributes = super.updateVirtualFields(attr);
  //   return;
  // }

}

export class PracticeNet extends Asset {
  attributes: PracticeNetAttributes[];
  layerId = LayerIds.layerId_PracticeNet;
  id = 'featureLayerPracticeNet';
  title = 'Practice Net';

  geometryType = EsriGeometryType.Polygon;
}

export class Sign extends Asset {
  attributes: SignAttributes[];
  layerId = LayerIds.layerId_Sign;
  id = 'featureLayerSign';
  title = 'Sign';

  geometryType = EsriGeometryType.Point;
}

export class Stormwater_Conduit extends Asset {
  attributes: Stormwater_ConduitAttributes[];
  layerId = LayerIds.layerId_Stormwater_Conduit;
  id = 'featureLayerStormwater_Conduit';
  title = 'Stormwater Pipe';

  geometryType = EsriGeometryType.Polyline;
}

export class Stormwater_Pit extends Asset {
  attributes: Stormwater_PitAttributes[];
  layerId = LayerIds.layerId_Stormwater_Pit;
  id = 'featureLayerStormwater_Pit';
  title = 'Stormwater Pit';

  geometryType = EsriGeometryType.Point;
}

export class Underpass extends Asset {
  attributes: UnderpassAttributes[];
  layerId = LayerIds.layerId_Underpass;
  id = 'featureLayerUnderpass';
  title = 'Underpass';

  geometryType = EsriGeometryType.Point;
}

export class WaterSupply_Conduit extends Asset {
  attributes: WaterSupply_ConduitAttributes[];
  layerId = LayerIds.layerId_WaterSupply_Conduit;
  id = 'featureLayerWaterSupply_Conduit';
  title = 'Water Supply Pipe';

  geometryType = EsriGeometryType.Polyline;
}

export class WaterSupply_EndPoint extends Asset {
  attributes: WaterSupply_EndPointAttributes[];
  layerId = LayerIds.layerId_WaterSupply_EndPoint;
  id = 'featureLayerWaterSupply_EndPoint';
  title = 'Water Supply EndPoint';

  geometryType = EsriGeometryType.Point;
}

export class Telecommunication extends Asset {
  attributes: TelecommunicationAttributes[];
  layerId = LayerIds.layerId_Telecommunication;
  id = 'featureLayerSTelecommunication';
  title = 'Telecommunication';

  geometryType = EsriGeometryType.Point;
}

export class WeirGPT extends Asset {
  attributes: WeirGPTAttributes[];
  layerId = LayerIds.layerId_WeirGPT;
  id = 'featureLayerWeirGPT';
  title = 'Weir GPT';

  geometryType = EsriGeometryType.Polygon;
}
