import { Component, OnInit, OnDestroy, Input, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { EsriViewTableComponent } from 'src/app/_esri/components/esri-view-table/esri-view-table.component';

@Component({
  selector: 'app-esri-edit-form-master-detail',
  templateUrl: './esri-edit-form-master-detail.component.html',
  styleUrls: ['./esri-edit-form-master-detail.component.scss']
})

//export class EsriEditFormMasterDetailComponent extends BaseEditForm implements OnInit, OnDestroy {
export class EsriEditFormMasterDetailComponent implements OnInit, OnDestroy {

  // System Environment
  protected ngUnsubscribe = new Subject<void>();
  private _numberOfTicks = 0;
  private _featureSelected: boolean = false;

  public numberOfRecords_Contact: number = 0;
  public numberOfRecords_Asset: number = 0;
  public numberOfRecords_Risk: number = 0;
  public numberOfRecords_Project: number = 0;
  public numberOfRecords_Incident: number = 0;
  public numberOfRecords_Maintenance: number = 0;

  public numberOfAssociatedRecords: number = 0;
  public numberOfAssociatedRecordsMsg: string;

  private _showContacts: boolean = false;
  private _showAssets: boolean = false;
  private _showRisks: boolean = false;
  private _showProjects: boolean = false;
  private _showIncidents: boolean = false;
  private _showMaintenance: boolean = false;

  @Input()
  set showContacts(showContacts: boolean) {
    this._showContacts = showContacts;
  }

  get showContacts(): boolean {
    return this._showContacts;
  }

  @Input()
  set showAssets(showAssets: boolean) {
    this._showAssets = showAssets;
  }

  get showAssets(): boolean {
    return this._showAssets;
  }

  @Input()
  set showRisks(showRisks: boolean) {
    this._showRisks = showRisks;
  }

  get showRisks(): boolean {
    return this._showRisks;
  }

  @Input()
  set showProjects(showProjects: boolean) {
    this._showProjects = showProjects;
  }

  get showProjects(): boolean {
    return this._showProjects;
  }

  @Input()
  set showIncidents(showIncidents: boolean) {
    this._showIncidents = showIncidents;
  }

  get showIncidents(): boolean {
    return this._showIncidents;
  }

  @Input()
  set showMaintenance(showMaintenance: boolean) {
    this._showMaintenance = showMaintenance;
  }

  get showMaintenance(): boolean {
    return this._showMaintenance;
  }

  @Input()
  set featureSelected(featureSelected: boolean) {
    this._featureSelected = featureSelected;
  }

  get featureSelected(): boolean {
    return this._featureSelected;
  }

  
  @ViewChild('expansionPanel', { static: true }) private _expansionPanelEl: any;

  @ViewChild("contactsTable") public esriViewTableComponent_Contact: EsriViewTableComponent;
  @ViewChild("assetsTable") public esriViewTableComponent_Asset: EsriViewTableComponent;
  @ViewChild("risksTable") public esriViewTableComponent_Risk: EsriViewTableComponent;
  @ViewChild("projectsTable") public esriViewTableComponent_Project: EsriViewTableComponent;
  @ViewChild("incidentsTable") public esriViewTableComponent_Incident: EsriViewTableComponent;
  @ViewChild("maintenanceTable") public esriViewTableComponent_Maintenance: EsriViewTableComponent;

  constructor(
    protected ref: ChangeDetectorRef,
  ) {
    setInterval(() => {
      this._numberOfTicks++;
      this.ref.markForCheck();
    }, 1000);
  }

  ngOnInit(): void {
    console.log('BaseEditForm ngOnInit()');
  }

  ngOnDestroy() {
    // Unsubscribe from events / observables
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public createNumberOfAssociatedRecordsMsg() {
    this.numberOfAssociatedRecords = this.numberOfRecords_Contact + this.numberOfRecords_Asset + this.numberOfRecords_Risk + this.numberOfRecords_Project + this.numberOfRecords_Incident + this.numberOfRecords_Maintenance;
    //this.esriViewTableComponent_Contact.attributes.length
    let msg: string;

    //this.numberOfAssociatedRecords = (this.galleryImages) ? this.galleryImages.length : 0;

    if (this.numberOfAssociatedRecords === 0) {
      msg = 'There are no associated records to display';
    }
    else if (this.numberOfAssociatedRecords === 1) {
      msg = '1 associated record to display';
    }
    else {
      msg = this.numberOfAssociatedRecords.toString() + ' associated records to display';
    }

    console.log('createNumberOfAssociatedRecordsMsg()', msg);
    this.numberOfAssociatedRecordsMsg = msg;

    // Open panel if there are records
    if (this.numberOfAssociatedRecords > 0) {
      this._expansionPanelEl.open();

      // **** TO DO: Navigate to first tab with records ??
    }
    else {
      this._expansionPanelEl.close();
    }
    
  }

}
