<app-header>
    <span title>{{title}}</span>
    <span toolbar-extras>
        <!-- <button fxHide fxShow.gt-xs mat-button [matMenuTriggerFor]="statusMenu"><mat-icon>filter_list</mat-icon>&nbsp;Choose status...</button>
        <button fxShow fxHide.gt-xs mat-icon-button [matMenuTriggerFor]="statusMenu" aria-label="Example icon-button with a menu">
            <mat-icon>filter_list</mat-icon>
        </button> -->
        <!-- <mat-menu #statusMenu="matMenu">
            <button mat-menu-item (click)='assetStatusSelectedEvent("Existing")'>Existing</button>
            <button mat-menu-item (click)='assetStatusSelectedEvent("Proposed")'>Proposed</button>
            <button mat-menu-item (click)='assetStatusSelectedEvent("Historic")'>Historic</button>
            <button mat-menu-item (click)='assetStatusSelectedEvent("ALL")'>All</button>
        </mat-menu> -->
    
        <!-- <button fxHide fxShow.gt-xs mat-button [matMenuTriggerFor]="selectedAssetMenu"><mat-icon>list_alt</mat-icon>&nbsp;Select an asset type...</button>
        <button fxShow fxHide.gt-xs mat-icon-button [matMenuTriggerFor]="selectedAssetMenu" aria-label="Example icon-button with a menu">
            <mat-icon>list_alt</mat-icon>
        </button>
        <mat-menu #selectedAssetMenu="matMenu">
            <button mat-menu-item (click)='featureLayerIdSelectedEvent("featureLayerBuilding")'>Buildings</button>
            <button mat-menu-item (click)='featureLayerIdSelectedEvent("featureLayerFootpath")'>Cart Paths</button>
            <button mat-menu-item (click)='featureLayerIdSelectedEvent("featureLayerFence")' [disabled]='true'>Fences</button>
        </mat-menu> -->
    </span>
</app-header>

<div *ngIf='!thinking' style="height: 4px;"></div>
<div *ngIf='thinking'><mat-progress-bar mode="query"></mat-progress-bar></div>

<!-- Slider () Row -->
<!-- <div fxLayout='row' fxLayoutAlign="space-between center" class='date-slader-row'>
    <div class='chart-box' [ngClass]="{'dark-chart-box': darkMode}" style="width: 100%;">
        <div fxFlex="200px" class='chart-title' [ngClass]="{'dark-chart-title': darkMode}">Incident Date Range</div>
        <div #timeSlider [hidden]='!loaded' class='time-slider' [ngClass]="{'dark': darkMode}">
            <ngx-slider *ngIf='timeSliderReady'
                [(value)]="dateFrom"
                [(highValue)]="dateTo"
                [(options)]="timeSliderOptions"
                (userChangeEnd)="timeSliderDateRangeChange($event)"
            ></ngx-slider>
        </div>
    </div>
</div> -->

<!-- Chips Row -->
<!-- <div fxLayout='row' fxLayoutAlign="center center" class='chips-row'>
    <div>
        <mat-chip-list #chipListAll aria-label="">
            <span *ngFor="let chart of dashboardCharts">
                <mat-chip [selectable]="true" [removable]="removableChip" *ngIf="chart.visibleChip" (removed)="removeChip(chart.chartName)">
                    {{chart.label}}
                    <mat-icon matChipRemove *ngIf="removableChip">cancel</mat-icon>
                </mat-chip>
            </span>
        </mat-chip-list>
    </div>
</div> -->

<!-- Dashboard Row 1 -->
<!-- <div fxLayout='row' fxLayoutAlign="space-between center" class='chart-row chart-row-3'>
    <div fxFlex="33" class='chart-box' [ngClass]="{'dark-chart-box': darkMode}">   
        <div fxLayout='column' fxLayoutAlign="space-between center">
            <div [ngClass]="{'dark': darkMode}">
                <div fxLayout='row' fxShow fxHide.lt-md fxLayoutAlign="space-between center">
                </div>
                <div fxLayout='row' fxHide fxShow.lt-md fxLayoutAlign="space-between center">
                </div>   
            </div>
            <div>
                <span class='chart-title' [ngClass]="{'dark-chart-title': darkMode}"></span>
            </div>
        </div>
    </div>
    
    <div fxFlex="33" class='chart-box' [ngClass]="{'dark-chart-box': darkMode}">   
        <div fxLayout='column' fxLayoutAlign="space-between center">
            <div [ngClass]="{'dark': darkMode}">
                <div fxLayout='row' fxShow fxHide.lt-md fxLayoutAlign="space-between center">
                </div>
                <div fxLayout='row' fxHide fxShow.lt-md fxLayoutAlign="space-between center">
                </div>  
            </div>
            <div>
                <span class='chart-title' [ngClass]="{'dark-chart-title': darkMode}"></span>
            </div>
        </div>
    </div>

    <div fxFlex="33" class='chart-box' [ngClass]="{'dark-chart-box': darkMode}">   
        <div fxLayout='column' fxLayoutAlign="space-between center">
            <div fxLayout='row' fxLayoutAlign="space-between center">
            </div>
            <div>
                <span class='chart-title' [ngClass]="{'dark-chart-title': darkMode}"></span>
            </div>
        </div>
    </div>
</div> -->

<!-- Map Row -->
<div fxLayout='row' class='map-row'>
    <app-esri-map fxLayout='row' style='width: 100%;' 
        [sidePanelWidth]='sidePanelWidth'
        [showFullscreenWidget]='true' 
        [showLegendWidget]='true'
        [legendLayerInfos]='legendLayerInfos'
        [hideBasemap]='false' 
        [basemapName]='basemapName'
        (viewCreatedEvent)='viewCreatedEvent($event)'>
        
        <div #popupMenu [hidden]="!loaded">
            <app-esri-popup-menu [popupType]="'MENU'">
                <div class="popup-menu-items">
                    <button mat-menu-item *ngIf="selectedFeature" (click)='currentModeService.currentModeData.next("edit-attribute")'>
                        <mat-icon class='material-icons-sharp'>edit</mat-icon>
                        <span>Edit this item</span>
                    </button>
                    <!-- <button mat-menu-item *ngIf="!selectedFeature" (click)='createFeatureFromPopupMenuEvent()'>
                        <mat-icon class='material-icons-sharp'>add_location</mat-icon>
                        <span>Create a new item here</span>
                    </button> -->
                    <!-- <button mat-menu-item *ngIf="selectedFeature" (click)='moveFeatureFromPopupMenuEvent()'>
                        <mat-icon class='material-icons-sharp'>edit_location</mat-icon>
                        <span>Move the selected item here</span>
                    </button> -->
                </div>
            </app-esri-popup-menu>
        </div>

        <div #popupData [hidden]="!loaded">
            <app-esri-popup-menu [popupType]="'DATA'">
                <div class="popup-data-items">
                    <app-esri-edit-form [readOnly]='true'></app-esri-edit-form>
                </div>
            </app-esri-popup-menu>   
        </div>
    </app-esri-map>
</div>

<!-- Image Gallery Row -->
<div fxLayout='row' class='image-row'>
    <app-esri-attachment style='width: 100%'></app-esri-attachment> 
</div>

<!-- Table Data Row -->
<div fxLayout='row' class='table-data-row'>                  
    <app-esri-view-table (addButtonSelectedEvent)='addBtnEvent()'></app-esri-view-table>
</div>

<!-- Footer -->
<app-footer></app-footer>

