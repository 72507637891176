import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { LocalStorage } from 'ngx-webstorage';
import { Esri, StaticMapResponse , StaticMapResponseSync, FeatureLayerProperties, Extent } from 'src/app/_esri/models/esri';
import { ImageSizeInfo, LabelExpressionInfo, LabelingInfo, DrawingInfo, LayerDefinition, OperationalLayer } from 'src/app/_esri/models/esri';
import { Course } from 'src/app/_course/models/course';
import { Surrounds } from 'src/app/_course/models/surrounds';

@Injectable({
  providedIn: 'root'
})

export class StaticMapService {

  public width: number = 600;
  public height: number = 400;
  public dpi: number = 96;  // 256
  public scale: number = 1999;
  public wkid: number = 3857;
  public outputFormat: string = 'JPG';  // Values: PNG8 | PNG32 | JPG | GIF | PDF | EPS | SVG | SVGZ
  public baseMapUrl: string = "https://services.arcgisonline.com/arcgis/rest/services/Canvas/World_Light_Gray_Base/MapServer";
  //public baseMapUrl: string = "https://services.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer";
  //public baseMapUrl: string = "https://services.arcgisonline.com/arcgis/rest/services/Canvas/World_Dark_Gray_Base/MapServer";

  private _url = "https://utility.arcgisonline.com/arcgis/rest/services/Utilities/PrintingTools/GPServer/Export%20Web%20Map%20Task/execute";

  // Define the base map
  private _baseMap = {
    "title" : "Basemap",
    "baseMapLayers" :  [
      {
        "url" : this.baseMapUrl,  
        "visibility": true,
        "opacity": 0.8
      }
    ]
  }

  @LocalStorage()
  private _token: string;

  constructor(
    private http: HttpClient
  ) { }

  public async generate(extent: Extent, url: string, title: string, objectid: number[]): Promise<any> {
    //console.log('StaticMapService generate');

    // Build up the operational layers from the Course and Surrounds defintions
    let operationalLayers: OperationalLayer[] = [];
    let courseFeatureLayers: FeatureLayerProperties[] = Course.createLayerPropertiesList(false);
    let surroundFeatureLayers: FeatureLayerProperties[] = Surrounds.createLayerPropertiesList(false, false);

    // Add the arrays to the operationalLayers array
    operationalLayers = [...this.createOperationalLayers(surroundFeatureLayers), ...this.createOperationalLayers(courseFeatureLayers)];
    //console.log('operationalLayers length', operationalLayers.length);

    // Add the selcetd feature layer
    const featureLayer = {
      "id": 'feature',
      "url" : url,
      "token" : this._token,
      "title" : title,
      "opacity" : 1,
      "layerDefinition" : {
        "objectIds" : [ ...objectid ]
      }
    };

    operationalLayers.push(featureLayer);
    //console.log('generate() operationalLayers', operationalLayers);

    const webMap = {
      "mapOptions": {
        "extent": {
          "xmin": extent.xmin,
          "ymin": extent.ymin,
          "xmax": extent.xmax,
          "ymax": extent.ymax,
          "spatialReference" : extent.spatialReference
        },
        "scale" : this.scale
      },
      "operationalLayers": operationalLayers,
      "baseMap" : this._baseMap,
      "exportOptions": {
        "dpi" : this.dpi,
        "outputSize" :  [ this.width, this.height ]
      }
    };
    //console.log('generate() webMap', webMap);

    //  Call the ArcGIS REST export map task geoprocessor
    const headers = new HttpHeaders({
      'accept': 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded'
    });

    const body = new HttpParams()
      .set('f', 'json')
      .set('Format', this.outputFormat)
      .set('Layout_Template', 'MAP_ONLY')
      .set('Web_Map_as_JSON', JSON.stringify(webMap)); 

    try {
      return await this.http.post<any>(this._url, body , {headers: headers }).toPromise();
    }
    catch (error) {
      console.error('StaticMapService.generate() error: ', error);
    }
  }

  public calculateExtentFromPoint(centreX: number, centreY: number, deltaX?: number, deltaY?: number): Extent {

    // Calculate the extents of the map from the location of the selected feature
    let _deltaX = 3400;
    let _deltaY = 2400;

    if (deltaX) {
      _deltaX = deltaX;
    }

    if (deltaY) {
      _deltaY = deltaY;
    }
    
    let xMin = centreX - (_deltaX / 2);
    let yMin = centreY - (_deltaY / 2);
    let xMax = centreX + (_deltaX / 2);
    let yMax = centreY + (_deltaY / 2);

    let extent: Extent = {
      "xmin": xMin,
      "ymin": yMin,
      "xmax": xMax,
      "ymax": yMax,
      "spatialReference" : {
        "wkid" : this.wkid
      }
    }

    return extent;
  }

  private createOperationalLayers(featureLayers: FeatureLayerProperties[] ) {

    let operationalLayers: OperationalLayer[] = [];

    // Loop through the feature layer settings and reformat them into the operational settings
    featureLayers.forEach( f => {
      let layer: OperationalLayer = {
        "id": f.id,
        "url" : f.url,
        "token" : this._token,
        "title" : f.title,
      }

      if (f.opacity) {
        layer.opacity = f.opacity;
      }

      if (f.visible) {
        layer.visibility = f.visible;
      }

      if (f.minScale) {
        layer.minScale = f.minScale;
      }

      if (f.maxScale) {
        layer.maxScale = f.maxScale;
      }

      if (f.definitionExpression || f.renderer) { 
        let layerDefinition: LayerDefinition = {};

        if (f.definitionExpression) {
          layerDefinition.definitionExpression = f.definitionExpression;
        }
  
        // Renderer settings don't work
        // if (f.renderer) {
        //   let drawingInfo: drawingInfo = {};
  
        //   if (f.renderer && f.renderer.symbol.type === 'simple-fill') {
        //     drawingInfo.renderer = renderer;
        //   }
        //   else {
        //     drawingInfo.renderer = f.renderer;  
        //   }   
          
        //   layerDefinition.drawingInfo = drawingInfo;
        // }

        layer.layerDefinition = layerDefinition;
      }

      operationalLayers.push(layer);
    });
    //console.log('StaticMapService.createOperationalLayers() operationalLayers length:', operationalLayers.length);

    return operationalLayers;
  }

}



      // const renderer = {
    //   "type": "simple-fill",
    //   "type": "esriSFS",
    //   "symbol": {
    //     "type": "esriSFSSolid",
    //     "color": [255, 255, 0, 1],
    //     "outline": {
    //       "type": "esriSLS",
    //       "style": "esriSLSSolid",
    //       "width": "2px",
    //       "color": [92, 137, 68, 0.65]
    //     }
    //   }
    // }

    // const renderer = {
    //   "type": "simple",
    //   "symbol": {
    //    "type": "esriSMS",
    //    "style": "esriSMSCircle",
    //    "size": 24,
    //    "outline": {
    //     "color": [ 255, 255, 255, 255 ],
    //     "width": 1
    //    }
    //   }
    // }
    //"type" : "esriSFS",
    //"style" : "< esriSFSBackwardDiagonal | esriSFSCross | esriSFSDiagonalCross | esriSFSForwardDiagonal | esriSFSHorizontal | esriSFSNull | esriSFSSolid | esriSFSVertical >",
    //{"type":"simple","symbol":{"type":"simple-fill","color":[0,0,0,0],"outline":{"width":"2px","color":[225,0,0,1]}}}
    //{"type":"simple","symbol":{"type":"simple-fill","color":[92,137,68,0.65],"outline":{"width":"0px","color":[92,137,68,0.65]}}}
    //{"type":"simple","symbol":{"type":"simple-fill","color":[0,77,168,1],"outline":{"width":"0px","color":[0,77,168,1]}}}



    // private takeScreenShot() {
    //   console.log('width', this.view.width);
    //   //console.log('height', this.view.height);
  
    //   let options = {
    //     format: "jpg",
    //     quality: 80,
    //     //width: this._view.width * 0.8,
    //     //height: this._view.height * 0.95
    //     //width: this._view.width,
    //     //height: this._view.width * 0.7116
    //     //width: this._view.width * 0.5,
    //     //height: this._view.height * 0.5
    //     //width: 512,
    //     //height: 256
    //     width: this.view.width / 2,
    //     height: this.view.height /2
    //     // area: {
    //     //   x: 0,
    //     //   y: 0,
    //     //   width: 40,
    //     //   height: 40
    //     // }
    //   };
  
    //   this.view.takeScreenshot(options).then( (screenshot) => {
    //     //console.log('screenshot', screenshot);
  
    //     console.log('screenshot.dataUrl', screenshot.dataUrl);
    //     //console.log(this._screenShotEl.nativeElement);
    //     //this._imgScreenShotEl.nativeElement.src = screenshot.dataUrl;
  
    //     // display a preview of the image
    //     //this.showPreview(screenshot);
  
    //     //return screenshot;
    //   });
    // }




      // const baseMap = {
  //   "title" : "VectorTileLayer as BaseMap",
  //   "baseMapLayers": [
  //     {
  //       "id": "1757d18b970-basemap-20",
  //       "type": "VectorTileLayer",
  //       "layerType": "VectorTileLayer",
  //       "title": "Basemap",
  //       "url" : "https://cdn.arcgis.com/sharing/rest/content/items/317ca0b66843491ca9812e850e935dd5/resources/styles/root.json",
  //       "visibility": true,
  //       "opacity": 1
  //     }
  //   ]
  // };





    // public async XXgenerateXX(centreX: number, centreY: number, url: string, title: string, objectid: number): Promise<any> {
    //   //console.log('StaticMapService generate');
  
    //   // Calculate the extents of the map from the location of the selected feature
    //   const deltaX = 3400;
    //   const deltaY = 2400;
      
    //   let xMin = centreX - (deltaX / 2);
    //   let yMin = centreY - (deltaY / 2);
    //   let xMax = centreX + (deltaX / 2);
    //   let yMax = centreY + (deltaY / 2);
  
    //   // Build up the operational layers from the Course and Surrounds defintions
    //   let operationalLayers: OperationalLayer[] = [];
    //   let courseFeatureLayers: FeatureLayerProperties[] = Course.createLayerPropertiesList(false);
    //   let surroundFeatureLayers: FeatureLayerProperties[] = Surrounds.createLayerPropertiesList(false, false);
  
    //   // Add the arrays to the operationalLayers array
    //   operationalLayers = [...this.createOperationalLayers(surroundFeatureLayers), ...this.createOperationalLayers(courseFeatureLayers)];
    //   //console.log('operationalLayers length', operationalLayers.length);
  
    //   // Add the selcetd feature layer
    //   const featureLayer = {
    //     "id": 'feature',
    //     "url" : url,
    //     "token" : this._token,
    //     "title" : title,
    //     "opacity" : 1,
    //     "layerDefinition" : {
    //       "objectIds" : [ objectid ]
    //     }
    //   };
  
    //   operationalLayers.push(featureLayer);
    //   //console.log('generate() operationalLayers', operationalLayers);
  
    //   // Define the base map
    //   const baseMap = {
    //     "title" : "Basemap",
    //     "baseMapLayers" :  [
    //       {
    //         //"url" : "https://services.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer",
    //         "url" : "https://services.arcgisonline.com/arcgis/rest/services/Canvas/World_Light_Gray_Base/MapServer",
    //         //"url" : "https://services.arcgisonline.com/arcgis/rest/services/Canvas/World_Dark_Gray_Base/MapServer",
    //         "visibility": true,
    //         "opacity": 0.8
    //       }
    //     ]
    //   };
  
    //   // const baseMap = {
    //   //   "title" : "VectorTileLayer as BaseMap",
    //   //   "baseMapLayers": [
    //   //     {
    //   //       "id": "1757d18b970-basemap-20",
    //   //       "type": "VectorTileLayer",
    //   //       "layerType": "VectorTileLayer",
    //   //       "title": "Basemap",
    //   //       "url" : "https://cdn.arcgis.com/sharing/rest/content/items/317ca0b66843491ca9812e850e935dd5/resources/styles/root.json",
    //   //       "visibility": true,
    //   //       "opacity": 1
    //   //     }
    //   //   ]
    //   // };
  
    //   const webMap = {
    //     "mapOptions": {
    //       "extent": {
    //         "xmin": xMin,
    //         "ymin": yMin,
    //         "xmax": xMax,
    //         "ymax": yMax,
    //         "spatialReference" : {
    //           "wkid" : this.wkid
    //         },
    //       },
    //       "scale" : this.scale
    //     },
    //     "operationalLayers": operationalLayers,
    //     "baseMap" : baseMap,
    //     "exportOptions": {
    //       "dpi" : this.dpi,
    //       "outputSize" :  [ this.width, this.height ]
    //     }
    //   };
    //   //console.log('generate() webMap', webMap);
  
    //   //  Call the ArcGIS REST export map task geoprocessor
    //   const headers = new HttpHeaders({
    //     'accept': 'application/json',
    //     'Content-Type': 'application/x-www-form-urlencoded'
    //   });
  
    //   const body = new HttpParams()
    //     .set('f', 'json')
    //     .set('Format', this.outputFormat)
    //     .set('Layout_Template', 'MAP_ONLY')
    //     .set('Web_Map_as_JSON', JSON.stringify(webMap)); 
  
    //   try {
    //     return await this.http.post<any>(this._url, body , {headers: headers }).toPromise();
    //   }
    //   catch (error) {
    //     console.error('StaticMapService.generate() error: ', error);
    //   }
    // }