import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-esri-info-box',
  templateUrl: './esri-info-box.component.html',
  styleUrls: ['./esri-info-box.component.scss']
})

export class EsriInfoBoxComponent implements OnInit {

  private _infoBoxKpi: string;
  private _infoBoxTitle: string;
  private _infoBoxIcon: string = 'info';
  private _infoBoxValue: string;
  private _infoBoxDescription: string;
  private _infoBoxColour: string = 'grey';     // purple red orange yellow green blue grey dark-grey
  private _darkMode: boolean = false;

  private _infoBoxIcon_Total: string = 'functions'; 
  private _infoBoxIcon_Open: string = 'construction'; 
  private _infoBoxIcon_Closed: string = 'done_outline'; 
  private _infoBoxIcon_Overdue: string = 'priority_high'; 
  private _infoBoxIcon_InSla: string = 'hourglass_empty'; 
  private _infoBoxIcon_OutSla: string = 'hourglass_disabled'; 
  private _infoBoxIcon_Performance: string = 'network_check'; 

  @Input()
  set infoBoxKpi(infoBoxKpi: string) {
    this._infoBoxKpi = infoBoxKpi;
  }

  get infoBoxKpi(): string {
    return this._infoBoxKpi;
  }

  @Input()
  set infoBoxTitle(infoBoxTitle: string) {
    this._infoBoxTitle = infoBoxTitle;
  }

  get infoBoxTitle(): string {
    return this._infoBoxTitle;
  }

  @Input()
  set infoBoxIcon(infoBoxIcon: string) {
    this._infoBoxIcon = infoBoxIcon;
  }

  get infoBoxIcon(): string {
    return this._infoBoxIcon;
  }

  @Input()
  set infoBoxValue(infoBoxValue: string) {
    this._infoBoxValue = infoBoxValue;
  }

  get infoBoxValue(): string {
    return this._infoBoxValue;
  }

  @Input()
  set infoBoxDescription(infoBoxDescription: string) {
    this._infoBoxDescription = infoBoxDescription;
  }

  get infoBoxDescription(): string {
    return this._infoBoxDescription;
  }

  @Input()
  set infoBoxColour(infoBoxColour: string) {
    this._infoBoxColour = infoBoxColour;
  }

  get infoBoxColour(): string {
    return this._infoBoxColour;
  }

  @Input()
  set darkMode(darkMode: boolean) {
    this._darkMode = darkMode;
  }

  get darkMode(): boolean {
    return this._darkMode;
  }

  constructor() { }

  ngOnInit(): void {

    if (this.infoBoxKpi) {
      switch (this.infoBoxKpi.toUpperCase()) {

        case 'TOTAL':
          this._infoBoxIcon = this._infoBoxIcon_Total;
          //this._infoBoxColour = 'grey';
          this._infoBoxColour = 'night-blue-1';
          break;

        case 'OPEN':
          this._infoBoxIcon = this._infoBoxIcon_Open;
          //this._infoBoxColour = 'yellow';
          this._infoBoxColour = 'night-blue-1';
          break;
          
        case 'CLOSED':
          this._infoBoxIcon = this._infoBoxIcon_Closed;
          //this._infoBoxColour = 'blue';
          this._infoBoxColour = 'night-blue-1';
          break;
          
        case 'OVERDUE':
          this._infoBoxIcon = this._infoBoxIcon_Overdue;
          //this._infoBoxColour = 'red';
          this._infoBoxColour = 'night-magenta-1';
          break;
          
        case 'INSLA':
          this._infoBoxIcon = this._infoBoxIcon_InSla;
          //this._infoBoxColour = 'green';
          this._infoBoxColour = 'night-blue-1';
          break;
          
        case 'OUTSLA':
          this._infoBoxIcon = this._infoBoxIcon_OutSla;
          //this._infoBoxColour = 'orange';
          this._infoBoxColour = 'night-blue-1';
          break;     
          
        case 'PERFORMANCE':
          this._infoBoxIcon = this._infoBoxIcon_Performance;
          //this._infoBoxColour = 'purple';
          this._infoBoxColour = 'night-blue-1';
          break;
          
        default:
          this._infoBoxColour = 'night-blue-1';
          break;
      }
    }
    else {
      if (!this._infoBoxColour) {
        this._infoBoxColour = 'night-blue-1';
      }
    }
  }
}
