import { Component, OnInit } from '@angular/core';
import { LocalStorage } from 'ngx-webstorage';
import { Esri } from 'src/app/_esri/models/esri';
import { LayerIds } from 'src/app/_esri/models/layer-ids';
import { TokenRegisterService } from 'src/app/_esri/services/token-register.service';

import { setDefaultOptions, loadModules } from 'esri-loader';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit {

  private _oAuthAuthoriseUrl: string = Esri.oAuthAuthoriseUrl;
  private _oAuthClientId: string = LayerIds.oAuthClientId;
  private _tokenExpirationRequest: string = Esri.tokenExpirationRequest;
  public _oAuthRedirectUrl: string = Esri.oAuthRedirectUrl;
  private _loginUrl: string = this._oAuthAuthoriseUrl + '?client_id=' + this._oAuthClientId + '&response_type=token&expiration=' + this._tokenExpirationRequest + '&redirect_uri=' + window.encodeURIComponent(this._oAuthRedirectUrl);
  
  public href: string;
  public loginType: string = 'OAUTH';   // POPUP INLINE OAUTH 

  @LocalStorage()
  public access_token;

  @LocalStorage()
  public token_expires_in

  @LocalStorage()
  public username

  constructor(
    private tokenRegisterService: TokenRegisterService
  ) {  }

  ngOnInit(): void {
  }

  public openPopupLogin() {
    const popupWindowFeatures = 'height=420, width=600, menubar=no, location=no, resizable=no, scrollbars=no, status=no, toolbar=no, directories=no, titlebar=no, fullscreen=no, left=100, top=100';
    //const popupWindowFeatures = 'height=420, width=600, menubar=no, location=no,  resizable=no,  scrollbars=1,   status=no,  toolbar=no, directories=no, titlebar=no, fullscreen=no, left=500, top=100';
    const popupWindowTarget = 'oauth-window';
    //const popupUrl = this._oAuthAuthoriseUrl + '?client_id=' + this._oAuthClientId + '&response_type=token&expiration=' + this._tokenExpirationRequest + '&redirect_uri=' + window.encodeURIComponent(this._oAuthRedirectUrl);

    let windowRef = window.open(this._loginUrl, popupWindowTarget, popupWindowFeatures);
    let timer = setInterval( () => {
        if (windowRef.closed) { 
          clearInterval(timer);
          this.tokenRegisterService.registerToken();
        }
      }  , 500);
  }

  public openInlineLogin() {
    this.href = this._loginUrl;
  }


  public async oAuthLogin() {


    console.log('oAuthLogin');

    const options = {
      version: Esri.apiVersion
    };
    
    setDefaultOptions(options);
    
    const [
      OAuthInfo,
      IdentityManager,
      Portal,
      domStyle, domAttr, on, dom
    ] = await loadModules([
      "esri/identity/OAuthInfo",
      "esri/identity/IdentityManager",
      "esri/portal/Portal",
      "dojo/dom-style",
      "dojo/dom-attr",
      "dojo/on",
      "dojo/dom"
    ]);








        // ArcGIS Enterprise Portals are also supported
        var portalUrl = "https://www.arcgis.com/sharing";

        // subsitute your own client_id to identify who spawned the login and check for a matching redirect URI
        var info = new OAuthInfo({
          appId: this._oAuthClientId,
          popup: false,
        });
      IdentityManager.registerOAuthInfos([info]);

        // send users to arcgis.com to login
        on(dom.byId("sign-in"), "click", function() {
          IdentityManager.getCredential(portalUrl);
        });

        // log out and reload
        on(dom.byId("sign-out"), "click", function() {
          IdentityManager.destroyCredentials();
          window.location.reload();
        });

        // persist logins when the page is refreshed
        IdentityManager.checkSignInStatus(portalUrl).then(
          function() {
            dom.byId('anonymousPanel').style.display = 'none';
            dom.byId('personalizedPanel').style.display = 'block'

            // display the map once the user is logged in
            displayMap();
          }
        );

        function displayMap() {
          var portal = new Portal();

          // Once the portal has loaded, the user is signed in
          portal.load().then(function() {
            // dom.byId('viewDiv').style.display = 'flex';

            // var map = new Map({
            //   basemap: "topo"
            // });

            // var view = new MapView({
            //   container: "viewDiv",
            //   map: map,
            //   zoom: 14,
            //   center: [-118.24,34.05]
            // });

            // var traffic = new MapImageLayer({
            //   url: 'https://traffic.arcgis.com/arcgis/rest/services/World/Traffic/MapServer'
            // })
            // map.add(traffic);
          });
        }






    // let oAuthInfo = new OAuthInfo({
    //   appId: this._oAuthClientId,
    //   popup: false,
    //   //popupCallbackUrl: this._oAuthRedirectUrl,
    //   //preserveUrlHash: false
    // });
    
    // IdentityManager.registerOAuthInfos([oAuthInfo]);

    // // let credentialOptions = {
    // //   oAuthPopupConfirmation: false
    // // }

    // IdentityManager.getCredential(oAuthInfo.portalUrl + "/sharing").then( (credential) => {
    //   console.log('oAuthLogin getCredential()', credential);



    

    //   // let portal = new Portal();
    //   // // Setting authMode to immediate signs the user in once loaded
    //   // portal.authMode = "immediate";
    //   // // Once loaded, user is signed in
    //   // portal.load().then( () => {
    //   //   console.log('oAuthLogin catch portal.load()', portal);
    //   // });

    // });


    // IdentityManager.setOAuthRedirectionHandler( (info) => {
    //   // Execute custom logic then perform redirect
    //   console.log('oAuthLogin setOAuthRedirectionHandler', info.authorizeParams);
    //   //window.location = info.authorizeUrl + "?" + ioquery.objectToQuery(info.authorizeParams);
    // });


    // IdentityManager
    //   .checkSignInStatus(oAuthInfo.portalUrl + "/sharing")
    //   .then( (status) => {

    //     console.log('oAuthLogin then', status);

    //   })
    //   .catch( () => {
    //     // Give user an option to sign in
    //     console.log('oAuthLogin catch');

    //     //let credential = 
    //     //IdentityManager.getCredential(oAuthInfo.portalUrl + "/sharing");

    //     //console.log('oAuthLogin getCredential()', credential);

    //     IdentityManager.getCredential(oAuthInfo.portalUrl + "/sharing").then( (credential) => {
    //       console.log('oAuthLogin getCredential()', credential);
    //     });

    //   });

  }

}

/*


"esri/portal/Portal",
"esri/identity/OAuthInfo",
"esri/identity/IdentityManager",
"esri/portal/PortalQueryParams";


var info = new OAuthInfo({
  // Swap this ID out with registered application ID
  appId: "q244Lb8gDRgWQ8hM",
  // Uncomment the next line and update if using your own portal
  // portalUrl: "https://<host>:<port>/arcgis"
  // Uncomment the next line to prevent the user's signed in state from being shared with other apps on the same domain with the same authNamespace value.
  // authNamespace: "portal_oauth_inline",
  popup: false
});

esriId.registerOAuthInfos([info]);


    require(["esri/identity/IdentityManager"],
    function(esriId)
    {
    esriId.setOAuthRedirectionHandler(function(info)
      {
      // Execute custom logic then perform redirect
      window.location = info.authorizeUrl + "?" + ioquery.objectToQuery(info.authorizeParams);
      });
    });

*/




// import { Injectable } from '@angular/core';
// import { LocalStorage } from 'ngx-webstorage';
// import { Esri } from 'src/app/_esri/models/esri';
// import { LayerIds } from 'src/app/_esri/models/layer-ids';
// import { setDefaultOptions, loadModules } from 'esri-loader';


