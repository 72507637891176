import { Injectable, Output, EventEmitter } from '@angular/core';
import { combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Esri, FeatureLayerProperties, ElevationInfo, EsriRenderer_Point, FormFieldConfig, FieldConfig, FieldDateFormat, FieldNumberFormat, GeometryPoint, Domain, SubDomain } from 'src/app/_esri/models/esri';
import { LayerIds } from 'src/app/_esri/models/layer-ids';
import { Global } from 'src/app/_globals/models/global';
import { LovDataService } from 'src/app/_globals/services/lov-data.service';
import { DomainLovService } from 'src/app/_esri/services/domain-lov.service';
import { ContactBase, ContactBaseAttributes } from 'src/app/_esri/models/common-feature-class';
import { WorkRequestCategoryLOV, WorkRequestSubCategoryLOV } from 'src/app/maintenance/models/work-request';
import { FeatureLayerObjectBaseAttributes, FeatureLayerObjectBase } from 'src/app/_esri/models/base-feature-class';

// Feature Layers

export class IncidentAttributes extends FeatureLayerObjectBaseAttributes {
  public IncidentId: string;
  public IncidentTitle: string;
  public IncidentDate: Date;
  public ReportedDate: Date;
  public IncidentCategoryId: string;
  public IncidentSubCategoryId: string;
  public IncidentDetails: string;
  public OccurredOnPremise: string;
  public FullAddress: string;
  public AddressLine1: string;
  public AddressLine2: string;
  public Suburb: string;
  public State: string;
  public Postcode: number;
  public Country: string;
  public FairwayNumber: number;

  public Insurance: string;
  public Insurance_ClaimReference: string;
  public Insurance_ClaimAmount: number;
  public Insurance_SettlementAmount: number;
  public Insurance_SettlementDate: Date;
  public Outcome: string;
  public Insurance_SettlementPaidBy: string;
  public MedicalActionRequired: string;
  public MedicalActionDetails: string;
  public LegalActionTaken: string;
  public LegalActionDetails: string;
  public Status: string;
  public ClosedDate: Date;
  public Comments: string;

  // Move these next ones to a related table? I think - will allow more than one asset / risk to be attached??

  public AssetClass: string;
  public AssetCategory: string;
  public AssetType: string;
  public AssetId: string;

  public RiskRegisterId: string;
  public RiskCategoryId: string;
  public RiskSubCategoryId: string;
  public RiskGlobalId: string;

  constructor(
    protected lovDataService: LovDataService,
    protected domainLovService: DomainLovService
  ) {
    super(
      lovDataService,
      domainLovService
    );
  }

  // Virtual Fields

  get Category(): string {
    if (this.IncidentCategoryId) {
      return this.domainLovService.getDomainLovValue('LovIncidentCategory', this.IncidentCategoryId);
    }
    else {
      return null;
    }
  }

  get SubCategory(): string {
    if (this.IncidentCategoryId && this.IncidentSubCategoryId) {
      //console.log('DomainLovService.domains_lov', DomainLovService.domains_lov);
      return this.domainLovService.getSubDomainLovValue('LovIncidentSubCategory', this.IncidentCategoryId, this.IncidentSubCategoryId);
    }
    else {
      return null;
    }
  }

  get State_DomainDesc(): string {
    if (this.State) {
      return this.domainLovService.getDomainValue('DomState', this.State);
    }
    else {
      return null;
    }
  }

  get Fairway_DomainDesc() {
    if (this.FairwayNumber) {
      return this.domainLovService.getDomainValue('DomFairway', this.FairwayNumber);
    }
    else {
      return null;
    }
  }

  // AssetCategory_DomainDesc AssetCategory DomBuildingCategory DomRoadCategory
  // AssetType_DomainDesc AssetType DomBuildingType DomFootpathType

  get AssetClass_DomainDesc() {
    if (this.AssetClass) {
      return this.domainLovService.getDomainValue('DomAssetClass', this.AssetClass);
    }
    else {
      return null;
    }
  }

  get Outcome_DomainDesc() {
    if (this.Outcome) {
      return this.domainLovService.getDomainValue('DomIncidentOutcome', this.Outcome);
    }
    else {
      return null;
    }
  }

  get SettlementPaidBy_DomainDesc() {
    if (this.Insurance_SettlementPaidBy) {
      return this.domainLovService.getDomainValue('DomSettlementPaidBy', this.Insurance_SettlementPaidBy);
    }
    else {
      return null;
    }
  }

  get Status_DomainDesc() {
    if (this.Status) {
      return this.domainLovService.getDomainValue('DomIncidentStatus', this.Status);
    }
    else {
      return null;
    }
  }

  get RiskRegister(): string {
    if (this.RiskRegisterId) {
      return this.domainLovService.getDomainLovValue('LovRiskRegister', this.RiskRegisterId);
    }
    else {
      return null;
    }
  }

  get RiskCategory(): string {
    if (this.RiskCategoryId) {
      return this.domainLovService.getDomainLovValue('LovRiskCategory', this.RiskCategoryId);
    }
    else {
      return null;
    }
  }

  get RiskSubCategory(): string {
    if (this.RiskCategoryId && this.RiskSubCategoryId) {
      //console.log('DomainLovService.domains_lov', DomainLovService.domains_lov);
      return this.domainLovService.getSubDomainLovValue('LovRiskSubCategory', this.RiskCategoryId, this.RiskSubCategoryId);
    }
    else {
      return null;
    }
  }
}

@Injectable()
export class Incident extends FeatureLayerObjectBase {
  attributes: IncidentAttributes[];
  server = LayerIds.gisServer_Incident;
  folder = LayerIds.folder_Incident;
  layerId = LayerIds.layerId_IncidentRegister;
  id = 'featureLayerIncidentRegister';
  title = 'Incident Register';

  minScale = 10000;
  whereClause = "1=1";

  //orderByClause = "CreationDate ASC"

  labelInfo = {
    symbol: Esri.text12pxWithHalo,
    labelExpressionInfo: {
      expression: "$feature.IncidentTitle"
    },
    maxScale: 0,
    minScale: 4000,
    where: "1=1"
  };

  labelsVisible = true;
  labelingInfo = this.labelInfo;
  // popupEnabled = true;
  // legendEnabled = true;

  @Output() categoryLovReadyEvent = new EventEmitter<any>();
  @Output() subCategoryLovReadyEvent = new EventEmitter<any>();
  // @Output() riskRegisterLovReadyEvent = new EventEmitter<any>();
  // @Output() riskCategoryLovReadyEvent = new EventEmitter<any>();
  // @Output() riskSubCategoryLovReadyEvent = new EventEmitter<any>();

  // if (includeTables === true) {
  //   // Tables
  //   featureLayerProperties.push(IncidentContact.IncidentContact);
  // }

  protected async createFormFieldConfigs(): Promise<FormFieldConfig[]> {
    let formFieldConfig: FormFieldConfig;
    let fieldConfigs: FieldConfig[] = [];
    let fieldConfig: FieldConfig;
    let format: any;

    // Description

    fieldConfigs = [];

    fieldConfig = new FieldConfig('IncidentId', 'Incident ID:', 'text');
    fieldConfig.required = false;
    fieldConfig.readOnly = true;
    fieldConfig.maxLength = 20;
    fieldConfig.includeInTable = true;
    fieldConfig.includeInTableIndex = 0;
    fieldConfig.includeInCard = true;
    fieldConfig.includeInCard_SubTitle = true;
    fieldConfig.includeInCard_Index = 1;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('IncidentTitle', 'Title:', 'text');
    fieldConfig.required = true;
    fieldConfig.maxLength = 200;
    fieldConfig.includeInTable = true;
    fieldConfig.includeInTableIndex = 1;
    fieldConfig.includeInCard = true;
    fieldConfig.includeInCard_Title = true;
    fieldConfig.includeInCard_Index = 0;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('IncidentDate', 'Incident Date:', 'date');
    fieldConfig.required = true;
    format = new FieldDateFormat();
    fieldConfig.format = format;
    fieldConfig.defaultValue = 'date-now';
    fieldConfig.includeInTable = true;
    fieldConfig.includeInTableIndex = 2;
    fieldConfig.includeInCard = true;
    fieldConfig.includeInCard_SubTitle = true;
    fieldConfig.includeInCard_Index = 2;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('ReportedDate', 'Reported Date:', 'date');
    fieldConfig.required = true;
    format = new FieldDateFormat();
    fieldConfig.format = format
    fieldConfig.defaultValue = 'date-now';
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('IncidentCategoryId', 'Category:', 'text');
    fieldConfig.required = true;
    fieldConfig.maxLength = 20;
    fieldConfig.includeInTable = true;
    fieldConfig.includeInTableIndex = 3;
    fieldConfig.includeInCard = true;
    fieldConfig.includeInCard_Content_Row1 = true;
    fieldConfig.includeInCard_Index = 3;
    fieldConfig.domain_lov = 'LovIncidentCategory';
    fieldConfig.domain_VirtualFieldName = 'Category';
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('IncidentSubCategoryId', 'Sub-category:', 'text');
    fieldConfig.required = true;
    fieldConfig.maxLength = 20;
    fieldConfig.includeInTable = true;
    fieldConfig.includeInTableIndex = 4;
    fieldConfig.includeInCard = true;
    fieldConfig.includeInCard_Content_Row1 = true;
    fieldConfig.includeInCard_Index = 4;
    fieldConfig.domain_lovParent = 'LovIncidentCategory';
    fieldConfig.domain_lovParent_FieldName = 'IncidentCategoryId';
    fieldConfig.domain_lov = 'LovIncidentSubCategory';
    fieldConfig.domain_VirtualFieldName = 'SubCategory';
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('IncidentDetails', 'Details:', 'text-area');
    //fieldConfig.required = true;
    fieldConfig.maxLength = 2000;
    //fieldConfig.includeInCard = true;
    //fieldConfig.includeInCard_Content_Row3 = true;
    //fieldConfig.includeInCard_Index = 6;
    fieldConfigs.push(fieldConfig);

    formFieldConfig = new FormFieldConfig('Description', fieldConfigs);
    this.formFieldConfigs.push(formFieldConfig);

    // Location

    fieldConfigs = [];

    fieldConfig = new FieldConfig('OccurredOnPremise', 'Occurred On Premise:', 'radio-h'); /////
    fieldConfig.required = false;
    fieldConfig.maxLength = 5;
    fieldConfig.domain = 'DomYesNo';
    fieldConfig.defaultValue = 'YES';
    fieldConfig.includeInTable = true;
    fieldConfig.includeInTableIndex = 5;
    fieldConfigs.push(fieldConfig);

    // Off Premise
    fieldConfig = new FieldConfig('FullAddress', 'Full Address', 'text');
    fieldConfig.maxLength = 255;
    fieldConfig.geocode = true;
    fieldConfig.visible = false;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('AddressLine1', 'Address Line 1', 'text');
    fieldConfig.maxLength = 100;
    fieldConfig.visible = false;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('AddressLine2', 'Address Line 2', 'text');
    fieldConfig.maxLength = 100;
    fieldConfig.visible = false;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('Suburb', 'Suburb', 'text');
    fieldConfig.maxLength = 50;
    fieldConfig.visible = false;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('State', 'State', 'text');
    fieldConfig.maxLength = 10;
    fieldConfig.domain = 'DomState';
    fieldConfig.domain_VirtualFieldName = 'State_DomainDesc';
    fieldConfig.defaultValue = 'NSW';
    fieldConfig.visible = false;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('Postcode', 'Postcode', 'number');
    fieldConfig.maxLength = 10;
    fieldConfig.visible = false;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('Country', 'Country', 'text');
    fieldConfig.maxLength = 100;
    fieldConfig.defaultValue = 'Australia';
    fieldConfig.visible = false;
    fieldConfigs.push(fieldConfig);

    // On Premise
    fieldConfig = new FieldConfig('FairwayNumber', 'Fairway Number', 'number');
    fieldConfig.domain = 'DomFairway'
    fieldConfig.domain_VirtualFieldName = 'Fairway_DomainDesc';
    fieldConfig.visible = false;
    fieldConfig.includeInTable = true;
    fieldConfig.includeInTableIndex = 7;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('AssetClass', 'Asset Class', 'text');
    fieldConfig.maxLength = 20;
    fieldConfig.domain = 'DomAssetClass';
    fieldConfig.domain_VirtualFieldName = 'AssetClass_DomainDesc';
    fieldConfig.visible = false;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('AssetCategory', 'Asset Category', 'text');
    fieldConfig.maxLength = 20;
    //fieldConfig.domain = assetCategoryDomain;
    fieldConfig.visible = false;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('AssetType', 'Asset Type:', 'text');
    fieldConfig.maxLength = 20;
    //fieldConfig.label = assetTypeLabel;
    //fieldConfig.domain = assetTypeDomain;
    fieldConfig.visible = false;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('AssetId', 'Asset Id:', 'text');
    fieldConfig.maxLength = 20;
    fieldConfig.visible = false;
    fieldConfigs.push(fieldConfig);

    formFieldConfig = new FormFieldConfig('Location', fieldConfigs);
    this.formFieldConfigs.push(formFieldConfig);



    // // Asset

    // fieldConfigs = [];

    // //assetCategoryDomain = 'DomBuildingCategory';
    // //assetTypeDomain = 'DomBuildingType';

    // //assetCategoryDomain = 'DomRoadCategory';
    // //assetTypeDomain = 'DomFootpathType';

    // fieldConfig = new FieldConfig('AssetClass', 'Asset Class', 'text');
    // fieldConfig.maxLength = 20;
    // //fieldConfig.domain = 'DomAssetClass';
    // fieldConfig.visible = false;
    // fieldConfigs.push(fieldConfig);

    // fieldConfig = new FieldConfig('AssetCategory', 'Asset Category', 'text');
    // fieldConfig.maxLength = 20;
    // //fieldConfig.domain = assetCategoryDomain;
    // fieldConfig.visible = false;    
    // fieldConfigs.push(fieldConfig);

    // fieldConfig = new FieldConfig('AssetType', 'Asset Type:', 'text');
    // fieldConfig.maxLength = 20;
    // //fieldConfig.label = assetTypeLabel;
    // //fieldConfig.domain = assetTypeDomain;
    // fieldConfig.visible = false; 
    // fieldConfigs.push(fieldConfig);

    // fieldConfig = new FieldConfig('AssetId', 'Asset Id:', 'text');
    // fieldConfig.maxLength = 20;
    // fieldConfig.visible = false; 
    // fieldConfigs.push(fieldConfig);

    // formFieldConfig = new FormFieldConfig('Asset', fieldConfigs);
    // formFieldConfig.visible = true;
    // formFieldConfigs.push(formFieldConfig);



    // Insurance Claim

    fieldConfigs = [];

    fieldConfig = new FieldConfig('Insurance', 'Insurance Claim:', 'radio-h');
    fieldConfig.required = true;
    fieldConfig.maxLength = 5;
    fieldConfig.domain = 'DomYesNo';
    fieldConfig.defaultValue = 'NO';
    fieldConfig.includeInCard = true;
    fieldConfig.includeInTableIndex = 6;
    fieldConfig.includeInCard_Content_Row2 = true;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('Insurance_ClaimReference', 'Claim Reference:', 'text');
    fieldConfig.maxLength = 50;
    fieldConfig.visible = false;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('Insurance_ClaimAmount', 'Claim Amount:', 'number');
    format = new FieldNumberFormat(2);
    //fieldConfig.prefix = '$';
    fieldConfig.format = format;
    fieldConfig.visible = false;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('Insurance_SettlementAmount', 'Settlement Amount:', 'number');
    format = new FieldNumberFormat(2);
    //fieldConfig.prefix = '$';
    fieldConfig.format = format;
    fieldConfig.visible = false;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('Insurance_SettlementDate', 'Date Settled:', 'date');
    format = new FieldDateFormat();
    fieldConfig.format = format;
    fieldConfig.visible = false;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('Outcome', 'Outcome:', 'radio-v');
    fieldConfig.maxLength = 20;
    fieldConfig.domain = 'DomIncidentOutcome';
    fieldConfig.domain_VirtualFieldName = 'Outcome_DomainDesc';
    fieldConfig.visible = false;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('Insurance_SettlementPaidBy', 'Paid By:', 'radio-h');
    fieldConfig.maxLength = 20;
    fieldConfig.domain = 'DomSettlementPaidBy';
    fieldConfig.domain_VirtualFieldName = 'SettlementPaidBy_DomainDesc';
    fieldConfig.visible = false;
    fieldConfigs.push(fieldConfig);

    formFieldConfig = new FormFieldConfig('Insurance', fieldConfigs);
    this.formFieldConfigs.push(formFieldConfig);

    // Medical and Legal

    fieldConfigs = [];

    fieldConfig = new FieldConfig('MedicalActionRequired', 'Medical Action Required:', 'radio-h');
    fieldConfig.maxLength = 5;
    fieldConfig.domain = 'DomYesNo';
    fieldConfig.defaultValue = 'NO';
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('MedicalActionDetails', 'Medical Action Details:', 'text-area');
    fieldConfig.maxLength = 2000;
    fieldConfig.visible = false;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('LegalActionTaken', 'Legal Action Taken:', 'radio-h');
    fieldConfig.maxLength = 5;
    fieldConfig.domain = 'DomYesNo';
    fieldConfig.defaultValue = 'NO';
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('LegalActionDetails', 'Legal Action Details:', 'text-area');
    fieldConfig.maxLength = 2000;
    fieldConfig.visible = false;
    fieldConfigs.push(fieldConfig);

    formFieldConfig = new FormFieldConfig('Legal and Medical', fieldConfigs);
    this.formFieldConfigs.push(formFieldConfig);

    // Completion

    fieldConfigs = [];

    fieldConfig = new FieldConfig('Status', 'Status:', 'radio-h');
    fieldConfig.maxLength = 20;
    fieldConfig.domain = 'DomIncidentStatus';
    fieldConfig.domain_VirtualFieldName = 'Status_DomainDesc';
    fieldConfig.includeInCard = true;
    fieldConfig.includeInCard_SubTitle = true;
    fieldConfig.includeInCard_Index = 3;
    fieldConfig.defaultValue = 'OPEN';
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('ClosedDate', 'Closed Date:', 'date');
    fieldConfig.required = false;
    format = new FieldDateFormat();
    fieldConfig.format = format;
    //fieldConfig.editable = false;
    //fieldConfig.defaultValue = 'date-now';
    //fieldConfig.includeInTable = true;
    //fieldConfig.includeInTableIndex = 2;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('Comments', 'Comments:', 'text-area');
    fieldConfigs.push(fieldConfig);

    formFieldConfig = new FormFieldConfig('Completion', fieldConfigs);
    this.formFieldConfigs.push(formFieldConfig);

    // Risk

    fieldConfigs = [];

    fieldConfig = new FieldConfig('RiskRegisterId', 'Risk Register:', 'text');
    fieldConfig.maxLength = 20;
    fieldConfig.visible = false;
    fieldConfig.domain_lov = 'LovRiskRegister';
    fieldConfig.domain_VirtualFieldName = 'RiskRegister';
    //fieldConfig.defaultValue = 'DEFAULT';
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('RiskCategoryId', 'Risk Category:', 'text');
    fieldConfig.maxLength = 20;
    fieldConfig.visible = false;
    fieldConfig.domain_lov = 'LovRiskCategory';
    fieldConfig.domain_VirtualFieldName = 'RiskCategory';
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('RiskSubCategoryId', 'Risk Sub-category:', 'text');
    fieldConfig.maxLength = 20;
    fieldConfig.visible = false;
    fieldConfig.domain_lovParent = 'LovRiskCategory';
    fieldConfig.domain_lovParent_FieldName = 'RiskCategoryId';
    fieldConfig.domain_lov = 'LovRiskSubCategory';
    fieldConfig.domain_VirtualFieldName = 'RiskSubCategory';
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('RiskGlobalId ', 'RiskGlobalId ', 'text');
    fieldConfig.visible = false;
    fieldConfigs.push(fieldConfig);

    formFieldConfig = new FormFieldConfig('Risk', fieldConfigs);
    formFieldConfig.visible = false;
    this.formFieldConfigs.push(formFieldConfig);

    // Virtual (and hidden)

    fieldConfigs = [];

    fieldConfig = new FieldConfig('Category', 'Category:', 'text');
    fieldConfig.readOnly = true;
    fieldConfig.visible = false;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('SubCategory', 'Sub-Category:', 'text');
    fieldConfig.readOnly = true;
    fieldConfig.visible = false;
    fieldConfigs.push(fieldConfig);


    // Need to add the rest here
    // State_DomainDesc
    // Fairway_DomainDesc
    // AssetClass_DomainDesc
    // Outcome_DomainDesc
    // SettlementPaidBy_DomainDesc
    // Status_DomainDesc
    // RiskRegister
    // RiskCategory
    // RiskSubCategory


    formFieldConfig = new FormFieldConfig('Virtual', fieldConfigs);
    this.formFieldConfigs.push(formFieldConfig);

    super.createFormFieldConfigs();

    return;
  }

  protected updateVirtualFields() {
    // Reset
    this.attributes = [];

    this.features.forEach((f) => {
      let attr: IncidentAttributes = new IncidentAttributes(this.lovDataService, this.domainLovService);
      //attr = f.attributes;

      attr.IncidentId = f.attributes.IncidentId;
      attr.IncidentTitle = f.attributes.IncidentTitle;
      attr.IncidentDate = f.attributes.IncidentDate;
      attr.ReportedDate = f.attributes.ReportedDate;
      attr.IncidentCategoryId = f.attributes.IncidentCategoryId;
      attr.IncidentSubCategoryId = f.attributes.IncidentSubCategoryId;
      attr.IncidentDetails = f.attributes.IncidentDetails;
      attr.OccurredOnPremise = f.attributes.OccurredOnPremise;
      attr.FullAddress = f.attributes.FullAddress;
      attr.AddressLine1 = f.attributes.AddressLine1;
      attr.AddressLine2 = f.attributes.AddressLine2;
      attr.Suburb = f.attributes.Suburb;
      attr.State = f.attributes.State;
      attr.Postcode = f.attributes.Postcode;
      attr.Country = f.attributes.Country;
      attr.FairwayNumber = f.attributes.FairwayNumber;
      attr.AssetClass = f.attributes.AssetClass;
      attr.AssetCategory = f.attributes.AssetCategory;
      attr.AssetType = f.attributes.AssetType;
      attr.AssetId = f.attributes.AssetId;
      attr.Insurance = f.attributes.Insurance;
      attr.Insurance_ClaimReference = f.attributes.Insurance_ClaimReference;
      attr.Insurance_ClaimAmount = f.attributes.Insurance_ClaimAmount;
      attr.Insurance_SettlementAmount = f.attributes.Insurance_SettlementAmount;
      attr.Insurance_SettlementDate = f.attributes.Insurance_SettlementDate;
      attr.Outcome = f.attributes.Outcome;
      attr.Insurance_SettlementPaidBy = f.attributes.Insurance_SettlementPaidBy;
      attr.MedicalActionRequired = f.attributes.MedicalActionRequired;
      attr.MedicalActionDetails = f.attributes.MedicalActionDetails;
      attr.LegalActionTaken = f.attributes.LegalActionTaken;
      attr.LegalActionDetails = f.attributes.LegalActionDetails;
      attr.Status = f.attributes.Status;
      attr.ClosedDate = f.attributes.ClosedDate;
      attr.Comments = f.attributes.Comments;
      attr.RiskRegisterId = f.attributes.RiskRegisterId;
      attr.RiskCategoryId = f.attributes.RiskCategoryId;
      attr.RiskSubCategoryId = f.attributes.RiskSubCategoryId;
      attr.RiskGlobalId = f.attributes.RiskGlobalId;

      // Common
      attr.OBJECTID = f.attributes.OBJECTID;
      attr.ClubId = f.attributes.ClubId;
      attr.Active = f.attributes.Active;
      attr.GlobalID = f.attributes.GlobalID;
      attr.HasLocation = f.attributes.HasLocation;
      attr.CreationDate = f.attributes.CreationDate;
      attr.Creator = f.attributes.Creator;
      attr.EditDate = f.attributes.EditDate;
      attr.Editor = f.attributes.Editor;

      // Populate virtual / calculated fields
      f.attributes.Category = attr.Category;
      f.attributes.SubCategory = attr.SubCategory;
      f.attributes.State_DomainDesc = attr.State_DomainDesc;
      f.attributes.Fairway_DomainDesc = attr.Fairway_DomainDesc;
      f.attributes.AssetClass_DomainDesc = attr.AssetClass_DomainDesc;
      f.attributes.Outcome_DomainDesc = attr.Outcome_DomainDesc;
      f.attributes.SettlementPaidBy_DomainDesc = attr.SettlementPaidBy_DomainDesc;
      f.attributes.Status_DomainDesc = attr.Status_DomainDesc;
      f.attributes.RiskRegister = attr.RiskRegister;
      f.attributes.RiskCategory = attr.RiskCategory;
      f.attributes.RiskSubCategory = attr.RiskSubCategory;

      this.attributes.push(attr);
    });
  }

  protected getLovs() {
    // All LOV Domains
    combineLatest([
      this.categoryLovReadyEvent,
      this.subCategoryLovReadyEvent,
      // this.riskRegisterLovReadyEvent,
      // this.riskCategoryLovReadyEvent,
      // this.riskSubCategoryLovReadyEvent
    ]).pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      console.log('Incident/FeatureBase getLovs() lovsReady()');
      this.lovReadyEvent.emit(true);
    });

    this.domains_lov = [];
    this.domains_lovSubDomain = [];

    //let incidentCategoryLOV: IncidentCategoryLOV = new IncidentCategoryLOV(this.lovDataService, this.domainLovService);
    let incidentCategoryLOV: IncidentCategoryLOV = new IncidentCategoryLOV();
    incidentCategoryLOV.getFeatureLayerConfigAndData().then(() => {
      incidentCategoryLOV.getDomain('LovIncidentCategory', 'coded-value').then((domain) => {
        this.domains_lov.push(domain);
        console.log('getLovs() incidentCategoryLOV domain', domain);
        this.categoryLovReadyEvent.emit();
      });
    });

    //let incidentSubCategoryLOV: IncidentSubCategoryLOV = new IncidentSubCategoryLOV(this.lovDataService, this.domainLovService);
    let incidentSubCategoryLOV: IncidentSubCategoryLOV = new IncidentSubCategoryLOV();
    incidentSubCategoryLOV.getFeatureLayerConfigAndData().then(() => {
      incidentSubCategoryLOV.getSubDomain('LovIncidentSubCategory', 'coded-value').then((domain) => {
        this.domains_lovSubDomain.push(domain);
        this.subCategoryLovReadyEvent.emit();
      });
    });

    // LovRiskRegister
    // LovRiskCategory
    // LovRiskSubCategory
  }

  protected async getContacts(objectIds: number[], outFields?: string[]): Promise<any> {
    return await this.queryRelatedFeatureLayer(LayerIds.relationshipId_Incident_Contacts, objectIds, outFields);
  }

  protected async getById(id: string): Promise<any> {
    this.whereClause = "IncidentId = '" + id + "'";
    super.getById(id);
  }
}

// Tables

export class IncidentContactAttributes extends ContactBaseAttributes {
  public ContactType: string;
  public ContactType_other: string;
  public Name: string;      //  ***** TO DO: - eplace this with the "FullName" from base
  public IncidentId: string;
  public IncidentRegister_GlobalID: string;

  constructor(
    protected lovDataService: LovDataService,
    protected domainLovService: DomainLovService
  ) {
    super(
      lovDataService,
      domainLovService
    );
  }

  // Virtual Fields

  get ContactType_DomainDesc() {
    if (this.ContactType) {
      //console.log('get ContactType_DomainDesc this.domainLovService', this.domainLovService)
      return this.domainLovService.getDomainValue('DomIncidentContactType', this.ContactType);
    }
    else {
      return null;
    }
  }

  // get FullAddress_Display(): string {
  //   if (this.FullAddress) {
  //     return this.FullAddress;
  //   }
  //   else {
  //     return Global.formatFullAddress(this.AddressLine1, this.AddressLine2, this.Suburb, this.State, this.Postcode, this.Country);
  //   }
  // }
}

@Injectable()
export class IncidentContact extends ContactBase {
  attributes: IncidentContactAttributes[];
  server = LayerIds.gisServer_Incident;
  folder = LayerIds.folder_Incident;
  layerId = LayerIds.layerId_TblIncidentContacts;
  id = 'tableIncidentContact';
  title = 'Incident Contacts';

  protected async createFormFieldConfigs(): Promise<FormFieldConfig[]> {
    //let formFieldConfigs: FormFieldConfig[] = [];
    let formFieldConfig: FormFieldConfig;
    let fieldConfigs: FieldConfig[] = [];
    let fieldConfig: FieldConfig;
    let format: any;

    // Name

    fieldConfigs = [];

    fieldConfig = new FieldConfig('ContactType', 'Contact Type', 'text');
    fieldConfig.required = true;
    fieldConfig.maxLength = 20;
    fieldConfig.domain = 'DomIncidentContactType';
    fieldConfig.domain_VirtualFieldName = 'ContactType_DomainDesc';
    fieldConfig.defaultValue = 'CLAIMANT';
    fieldConfig.includeInTable = true;
    fieldConfig.includeInTableIndex = 1;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('ContactType_other', 'Contact Type (Other)', 'text');
    fieldConfig.visible = false;
    fieldConfig.maxLength = 255;
    fieldConfigs.push(fieldConfig);


    fieldConfig = new FieldConfig('Name', 'Name', 'text');    // ***** TO DO: - eplace this with the "FullName" from base
    fieldConfig.required = true;
    fieldConfig.maxLength = 150;
    fieldConfig.includeInTable = true;
    fieldConfig.includeInTableIndex = 0;
    fieldConfigs.push(fieldConfig);

    formFieldConfig = new FormFieldConfig('ContactType', fieldConfigs);
    this.formFieldConfigs.push(formFieldConfig);

    // Virtual

    fieldConfigs = [];

    fieldConfig = new FieldConfig('ContactType_DomainDesc', 'Contact Type:', 'text');
    fieldConfig.readOnly = true;
    fieldConfig.visible = false;
    fieldConfigs.push(fieldConfig);

    formFieldConfig = new FormFieldConfig('Virtual', fieldConfigs);
    this.formFieldConfigs.push(formFieldConfig);

    // Hidden

    fieldConfigs = [];

    fieldConfig = new FieldConfig('IncidentId', 'Incident Id', 'text');
    fieldConfig.required = true;
    fieldConfig.editable = false;
    fieldConfig.visible = false;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('IncidentRegister_GlobalID', 'IncidentRegister_GlobalID', 'text');
    fieldConfig.editable = false;
    fieldConfig.maxLength = 38;
    fieldConfig.visible = false;
    fieldConfigs.push(fieldConfig);

    formFieldConfig = new FormFieldConfig('Hidden', fieldConfigs);
    formFieldConfig.visible = false;
    this.formFieldConfigs.push(formFieldConfig);

    super.createFormFieldConfigs();

    console.log('IncidentContact this.formFieldConfigs', this.formFieldConfigs);

    return this.formFieldConfigs;
  }

  protected updateVirtualFields() {
    //console.log("IncidentContact updateVirtualFields()");
    // Reset
    this.attributes = [];

    this.features.forEach((f) => {
      let attr: IncidentContactAttributes = new IncidentContactAttributes(this.lovDataService, this.domainLovService);
      //attr = f.attributes;

      //console.log("IncidentContact updateVirtualFields() f", f);

      attr.ContactType = f.attributes.ContactType;
      attr.ContactType_other = f.attributes.ContactType_other;
      attr.Name = f.attributes.Name;
      attr.IncidentId = f.attributes.IncidentId;
      attr.IncidentRegister_GlobalID = f.attributes.IncidentRegister_GlobalID;

      // Inherited
      attr.FullName = f.attributes.FullName;
      attr.Position = f.attributes.Position;
      attr.Company = f.attributes.Company;
      attr.FullAddress = f.attributes.FullAddress;
      attr.AddressLine1 = f.attributes.AddressLine1;
      attr.AddressLine2 = f.attributes.AddressLine2;
      attr.Suburb = f.attributes.Suburb;
      attr.City = f.attributes.City;
      attr.State = f.attributes.State;
      attr.Postcode = f.attributes.Postcode;
      attr.Country = f.attributes.Country;
      attr.HomeNumber = f.attributes.HomeNumber;
      attr.MobileNumber = f.attributes.MobileNumber;
      attr.WorkNumber = f.attributes.WorkNumber;
      attr.FaxNumber = f.attributes.FaxNumber;
      attr.Email = f.attributes.Email;
      attr.Latitude = f.attributes.Latitude;
      attr.Longitude = f.attributes.Longitude;

      // Common
      attr.OBJECTID = f.attributes.OBJECTID;
      attr.ClubId = f.attributes.ClubId;
      attr.Active = f.attributes.Active;
      attr.GlobalID = f.attributes.GlobalID;
      //attr.HasLocation = f.attributes.HasLocation;
      attr.CreationDate = f.attributes.CreationDate;
      attr.Creator = f.attributes.Creator;
      attr.EditDate = f.attributes.EditDate;
      attr.Editor = f.attributes.Editor;

      // Populate virtual / calculated fields
      f.attributes.ContactType_DomainDesc = attr.ContactType_DomainDesc;
      f.attributes.FullAddress_Display = attr.FullAddress_Display;

      this.attributes.push(attr);
    });
  }
}

export class IncidentCategoryLOV extends FeatureLayerObjectBase {
  server = LayerIds.gisServer_Incident;
  folder = LayerIds.folder_Incident;
  layerId = LayerIds.layerId_TblIncidentCategory_LOV;
  id = 'tableIncidentCategory_LOV';
  title = 'Incident Category LOV';

  whereClause = "ClubId='" + Global.clubId + "' AND Active <> 'NO'";
  outFields = ['IncidentCategoryId', 'Description'];

  protected async createFormFieldConfig(): Promise<FormFieldConfig[]> {
    //let formFieldConfigs: FormFieldConfig[] = [];
    let formFieldConfig: FormFieldConfig;
    let fieldConfigs: FieldConfig[] = [];
    let fieldConfig: FieldConfig;
    //let format: any;

    fieldConfig = new FieldConfig('IncidentCategoryId', 'Incident Category Id', 'text');
    fieldConfig.required = true;
    fieldConfig.maxLength = 20;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('Description', 'Description', 'text');
    fieldConfig.required = true;
    fieldConfig.maxLength = 200;
    fieldConfigs.push(fieldConfig);

    // fieldConfig = new FieldConfig('Active', 'Active', 'text');
    // fieldConfig.domain = 'DomYesNo';
    // fieldConfig.defaultValue = 'YES';
    // fieldConfigs.push(fieldConfig);

    formFieldConfig = new FormFieldConfig('Description', fieldConfigs);
    this.formFieldConfigs.push(formFieldConfig);
    //formFieldConfigs.push(formFieldConfig);

    super.createFormFieldConfigs();

    return this.formFieldConfigs;
    //return formFieldConfigs;
  }
}

export class IncidentSubCategoryAttributes extends FeatureLayerObjectBaseAttributes {
  public IncidentCategoryId: string;
  public IncidentSubCategoryId: string;
  public Description: string;
  public DefaultWorkRequestCategoryId: string;
  public DefaultWorkRequestSubCategoryId: string;
  public DefaultRiskId: string;
  public DefaultRiskRegisterId: string;
  public DefaultRiskCategoryId: string;
  public DefaultRiskSubCategoryId: string;

  // public domains: Domain[] = [];
  // public domains_lov: Domain[] = [];
  // public domains_lovSubDomain: SubDomain[] = [];


  constructor(
    protected lovDataService: LovDataService,
    protected domainLovService: DomainLovService
  ) {
    super(
      lovDataService,
      domainLovService
    );
  }

  // Virtual Fields
  get Category(): string {
    if (this.IncidentCategoryId) {
      return this.domainLovService.getDomainLovValue('LovIncidentCategory', this.IncidentCategoryId);
    }
    else {
      return null;
    }
  }

  get SubCategory(): string {
    if (this.IncidentSubCategoryId) {
      return this.Description;
    }
    else {
      return null;
    }
  }

  // Work Request

  get DefaultWorkRequestCategory(): string {
    if (this.DefaultWorkRequestCategoryId) {
      return this.domainLovService.getDomainLovValue('LovWorkRequestCategory', this.DefaultWorkRequestCategoryId);
    }
    else {
      return null;
    }
  };

  get DefaultWorkRequestSubCategory(): string {
    if (this.DefaultWorkRequestSubCategoryId && this.DefaultWorkRequestSubCategoryId) {
      return this.domainLovService.getSubDomainLovValue('LovWorkRequestSubCategory', this.DefaultWorkRequestCategoryId, this.DefaultWorkRequestSubCategoryId);
    }
    else {
      return null;
    }
  };

  // Risk

  get DefaultRiskRegister(): string {
    if (this.DefaultRiskRegisterId) {
      return this.domainLovService.getDomainLovValue('LovRiskRegister', this.DefaultRiskRegisterId);
    }
    else {
      return null;
    }
  };

  get DefaultRiskCategory(): string {
    if (this.DefaultRiskCategoryId) {
      return this.domainLovService.getDomainLovValue('LovRiskCategory', this.DefaultRiskCategoryId);
    }
    else {
      return null;
    }
  };

  get DefaultRiskSubCategory(): string {
    if (this.DefaultRiskSubCategoryId && this.DefaultWorkRequestSubCategoryId) {
      return this.domainLovService.getSubDomainLovValue('LovRiskSubCategory', this.DefaultRiskCategoryId, this.DefaultRiskSubCategoryId);
    }
    else {
      return null;
    }
  };

  // f.attributes.DefaultRisk = attr.DefaultRisk; ?????

}

@Injectable()
export class IncidentSubCategoryLOV extends FeatureLayerObjectBase {
  attributes: IncidentSubCategoryAttributes[];
  server = LayerIds.gisServer_Incident;
  folder = LayerIds.folder_Incident;
  layerId = LayerIds.layerId_TblIncidentSubCategory_LOV;
  id = 'tableIncidentSubCategory_LOV';
  title = 'Incident Sub-Category LOV';

  whereClause = "ClubId='" + Global.clubId + "' AND Active <> 'NO'";
  outFields = ['IncidentCategoryId', 'IncidentSubCategoryId', 'Description'];

  @Output() categoryLovReadyEvent = new EventEmitter<any>();
  @Output() subCategoryLovReadyEvent = new EventEmitter<any>();
  @Output() workRequestCategoryLovReadyEvent = new EventEmitter<any>();
  @Output() workRequestSubCategoryLovReadyEvent = new EventEmitter<any>();

  protected async createFormFieldConfigs(): Promise<FormFieldConfig[]> {
    let formFieldConfig: FormFieldConfig;
    let fieldConfigs: FieldConfig[] = [];
    let fieldConfig: FieldConfig;
    //let format: any;

    fieldConfig = new FieldConfig('IncidentCategoryId', 'Incident Category Id', 'text');
    fieldConfig.required = true;
    fieldConfig.maxLength = 20;
    fieldConfig.domain_lov = 'LovIncidentCategory';
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('IncidentSubCategoryId', 'Incident Sub-Category Id', 'text');
    fieldConfig.required = true;
    fieldConfig.maxLength = 20;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('Description', 'Description', 'text');
    fieldConfig.required = true;
    fieldConfig.maxLength = 200;
    fieldConfigs.push(fieldConfig);

    formFieldConfig = new FormFieldConfig('Description', fieldConfigs);
    this.formFieldConfigs.push(formFieldConfig);

    // Work Request Defaults

    fieldConfigs = [];

    fieldConfig = new FieldConfig('DefaultWorkRequestCategoryId', 'Default WorkRequest Category', 'text');
    fieldConfig.required = true;
    fieldConfig.maxLength = 20;
    fieldConfig.domain_lov = 'LovWorkRequestCategory';
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('DefaultWorkRequestSubCategoryId', 'Default WorkRequest Sub-Category', 'text');
    fieldConfig.required = true;
    fieldConfig.maxLength = 20;
    fieldConfig.domain_lovParent = 'LovWorkRequestCategory';
    fieldConfig.domain_lov = 'LovWorkRequestSubCategory';
    fieldConfigs.push(fieldConfig);

    formFieldConfig = new FormFieldConfig('WorkRequestDefaults', fieldConfigs);
    this.formFieldConfigs.push(formFieldConfig);

    // Risk Defaults

    fieldConfigs = [];

    fieldConfig = new FieldConfig('DefaulRiskRegisterId', 'Default Risk Register', 'text');
    fieldConfig.maxLength = 20;
    fieldConfig.domain_lov = 'LovRiskRegister';
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('DefaulRiskCategoryId', 'Default Risk Category', 'text');
    fieldConfig.maxLength = 20;
    fieldConfig.domain_lov = 'LovRiskCategory';
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('DefaultRiskSubCategoryId', 'Default Risk Sub-Category', 'text');
    fieldConfig.maxLength = 20;
    fieldConfig.domain_lovParent = 'LovRiskCategory';
    fieldConfig.domain_lov = 'LovRiskSubCategory';
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('DefaulRiskId', 'Default Risk', 'text');
    fieldConfig.maxLength = 20;
    fieldConfigs.push(fieldConfig);

    formFieldConfig = new FormFieldConfig('RiskDefaults', fieldConfigs);
    this.formFieldConfigs.push(formFieldConfig);

    super.createFormFieldConfigs();
    return this.formFieldConfigs;
  }
  
  protected updateVirtualFields() {
    // Reset
    this.attributes = [];

    this.features.forEach((f) => {
      let attr: IncidentSubCategoryAttributes = new IncidentSubCategoryAttributes(this.lovDataService, this.domainLovService);

      attr.IncidentCategoryId = f.attributes.IncidentCategoryId;
      attr.IncidentSubCategoryId = f.attributes.IncidentSubCategoryId;
      attr.Description = f.attributes.Description;
      attr.DefaultWorkRequestCategoryId = f.attributes.DefaultWorkRequestCategoryId;
      attr.DefaultWorkRequestSubCategoryId = f.attributes.DefaultWorkRequestSubCategoryId;
      attr.DefaultRiskId = f.attributes.DefaultRiskId;
      attr.DefaultRiskRegisterId = f.attributes.DefaultRiskRegisterId;
      attr.DefaultRiskCategoryId = f.attributes.DefaultRiskCategoryId;
      attr.DefaultRiskSubCategoryId = f.attributes.DefaultRiskSubCategoryId;

      // Common
      attr.OBJECTID = f.attributes.OBJECTID;
      attr.ClubId = f.attributes.ClubId;
      attr.Active = f.attributes.Active;
      attr.GlobalID = f.attributes.GlobalID;
      attr.HasLocation = f.attributes.HasLocation;
      attr.CreationDate = f.attributes.CreationDate;
      attr.Creator = f.attributes.Creator;
      attr.EditDate = f.attributes.EditDate;
      attr.Editor = f.attributes.Editor;

      // Populate virtual / calculated fields
      f.attributes.Category = attr.Category;
      f.attributes.SubCategory = attr.SubCategory;
      f.attributes.DefaultWorkRequestCategory = attr.DefaultWorkRequestCategory;
      f.attributes.DefaultWorkRequestSubCategory = attr.DefaultWorkRequestSubCategory;
      f.attributes.DefaultRiskRegister = attr.DefaultRiskRegister;
      f.attributes.DefaultRiskCategory = attr.DefaultRiskCategory;
      f.attributes.DefaultRiskSubCategory = attr.DefaultRiskSubCategory;
      //f.attributes.DefaultRisk = attr.DefaultRisk;

      this.attributes.push(attr);
    });
  }

  public getLovs() {
    // All LOV Domains
    combineLatest([
      this.categoryLovReadyEvent,
      this.subCategoryLovReadyEvent,
      this.workRequestCategoryLovReadyEvent,
      this.workRequestSubCategoryLovReadyEvent
    ]).pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      console.log('WorkRequestSubCategoryLOV/FeatureBase getLovs() lovsReady()');
      this.lovReadyEvent.emit(true);
    });

    this.domains_lov = [];
    this.domains_lovSubDomain = [];

    let categoryLOV: IncidentCategoryLOV = new IncidentCategoryLOV();
    categoryLOV.getFeatureLayerConfigAndData().then(() => {
      categoryLOV.getDomain('LovIncidentCategory', 'coded-value').then((domain) => {
        this.domains_lov.push(domain);
        this.categoryLovReadyEvent.emit();
      });
    });

    let subCategoryLOV: IncidentSubCategoryLOV = new IncidentSubCategoryLOV();
    subCategoryLOV.getFeatureLayerConfigAndData().then(() => {
      subCategoryLOV.getSubDomain('LovIncidentSubCategory', 'coded-value').then((domain) => {
        this.domains_lovSubDomain.push(domain);
        //console.log('getLovs() subCategoryLovReadyEvent.emit');
        this.subCategoryLovReadyEvent.emit();
      });
    });

    let workRequestCategoryLOV: WorkRequestCategoryLOV = new WorkRequestCategoryLOV();
    workRequestCategoryLOV.getFeatureLayerConfigAndData().then(() => {
      workRequestCategoryLOV.getDomain('LovWorkRequestCategory', 'coded-value').then((domain) => {
        this.domains_lov.push(domain);
        //console.log('getLovs() categoryLovReadyEvent.emit');
        this.workRequestCategoryLovReadyEvent.emit();
      });
    });

    let workRequestSubCategoryLOV: WorkRequestSubCategoryLOV = new WorkRequestSubCategoryLOV();
    workRequestSubCategoryLOV.getFeatureLayerConfigAndData().then(() => {
      workRequestSubCategoryLOV.getSubDomain('LovWorkRequestSubCategory', 'coded-value').then((domain) => {
        this.domains_lovSubDomain.push(domain);
        //console.log('getLovs() subCategoryLovReadyEvent.emit');
        this.workRequestSubCategoryLovReadyEvent.emit();
      });
    });
  }
}



/*


export class IncidentContactAttributes extends FeatureBaseAttributes {
  public ContactType: string;
  public ContactType_other: string;
  public Name: string;
  public Position: string;
  public Company: string;
  public FullAddress: string;
  public AddressLine1: string;
  public AddressLine2: string;
  public Suburb: string;
  public City: string;
  public State: string;
  public Postcode: string;
  public Country: string;
  public HomeNumber: string;
  public MobileNumber: string;
  public WorkNumber: string;
  public FaxNumber: string;
  public Email: string;
  public IncidentId: string;
  public IncidentRegister_GlobalID: string;

  constructor(
    protected lovDataService: LovDataService,
    protected domainLovService: DomainLovService
  ) {
    super(
      lovDataService,
      domainLovService
    );
  }

  // Virtual Fields

  get ContactType_DomainDesc() {
    if (this.ContactType) {
      //console.log('get ContactType_DomainDesc this.domainLovService', this.domainLovService)
      return this.domainLovService.getDomainValue('DomIncidentContactType', this.ContactType);
    }
    else {
      return null;
    }
  }

  get FullAddress_Display(): string {
    if (this.FullAddress) {
      return this.FullAddress;
    }
    else {
      return Global.formatFullAddress(this.AddressLine1, this.AddressLine2, this.Suburb, this.State, this.Postcode, this.Country);
    }
  }
}

@Injectable()
export class IncidentContact extends FeatureBase {
  attributes: IncidentContactAttributes[];
  server = LayerIds.gisServer_Incident;
  folder = LayerIds.folder_Incident;
  layerId = LayerIds.layerId_TblIncidentContacts;
  id = 'tableIncidentContact';
  title = 'Incident Contacts';

  protected async createFormFieldConfigs(): Promise<FormFieldConfig[]> {
    //let formFieldConfigs: FormFieldConfig[] = [];
    let formFieldConfig: FormFieldConfig;
    let fieldConfigs: FieldConfig[] = [];
    let fieldConfig: FieldConfig;
    let format: any;

    // Name

    fieldConfigs = [];

    fieldConfig = new FieldConfig('ContactType', 'Contact Type', 'text');
    fieldConfig.required = true;
    fieldConfig.maxLength = 20;
    fieldConfig.domain = 'DomIncidentContactType';
    fieldConfig.domain_VirtualFieldName = 'ContactType_DomainDesc';
    fieldConfig.defaultValue = 'CLAIMANT';
    fieldConfig.includeInTable = true;
    fieldConfig.includeInTableIndex = 1;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('ContactType_other', 'Contact Type (Other)', 'text');
    fieldConfig.visible = false;
    fieldConfig.maxLength = 255;
    fieldConfigs.push(fieldConfig);

    // fieldConfig = new FieldConfig('SalutationTitle', 'Title', 'text');
    // fieldConfig.domain = 'DomSalutation';
    // fieldConfig.maxLength = 10;
    // fieldConfigs.push(fieldConfig);

    // fieldConfig = new FieldConfig('FirstName', 'FirstName', 'text');
    // fieldConfig.required = true;
    // fieldConfig.maxLength = 150;
    // fieldConfigs.push(fieldConfig);

    // fieldConfig = new FieldConfig('LastName', 'LastName', 'text');
    // fieldConfig.required = true;
    // fieldConfig.maxLength = 150;
    // fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('Name', 'Name', 'text');    // FullName
    fieldConfig.required = true;
    fieldConfig.maxLength = 150;
    fieldConfig.includeInTable = true;
    fieldConfig.includeInTableIndex = 0;
    fieldConfigs.push(fieldConfig);

    // fieldConfig = new FieldConfig('Position', 'Position', 'text');
    // fieldConfig.maxLength = 150;
    // fieldConfigs.push(fieldConfig);

    // fieldConfig = new FieldConfig('Company', 'Company', 'text');
    // fieldConfig.maxLength = 150;
    // fieldConfigs.push(fieldConfig);

    formFieldConfig = new FormFieldConfig('Name', fieldConfigs);
    this.formFieldConfigs.push(formFieldConfig);

    // Address

    fieldConfigs = [];

    fieldConfig = new FieldConfig('FullAddress', 'Full Address', 'text');   ///// Virtual??
    fieldConfig.maxLength = 255;
    fieldConfig.geocode = true;
    //fieldConfig.domain_VirtualFieldName = 'FullAddress_Display';
    fieldConfig.includeInTable = true;
    fieldConfig.includeInTableIndex = 3;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('AddressLine1', 'Address Line 1', 'text');
    fieldConfig.maxLength = 100;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('AddressLine2', 'Address Line 2', 'text');
    fieldConfig.maxLength = 100;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('Suburb', 'Suburb', 'text');
    fieldConfig.maxLength = 50;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('City', 'City', 'text');
    fieldConfig.visible = false;
    fieldConfig.maxLength = 50;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('State', 'State', 'text');
    fieldConfig.maxLength = 10;
    fieldConfig.domain = 'DomState';
    fieldConfig.defaultValue = 'NSW';
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('Postcode', 'Postcode', 'number');
    fieldConfig.maxLength = 10;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('Country', 'Country', 'text');
    fieldConfig.maxLength = 100;
    fieldConfig.defaultValue = 'Australia';
    fieldConfigs.push(fieldConfig);

    formFieldConfig = new FormFieldConfig('Address', fieldConfigs);
    this.formFieldConfigs.push(formFieldConfig);

    // Contact Details

    fieldConfigs = [];

    fieldConfig = new FieldConfig('HomeNumber', 'Home Number', 'tel');
    fieldConfig.maxLength = 15;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('MobileNumber', 'Mobile Number', 'tel');
    fieldConfig.maxLength = 15;
    fieldConfig.includeInTable = true;
    fieldConfig.includeInTableIndex = 2;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('WorkNumber', 'Work Number', 'tel');
    fieldConfig.maxLength = 15;
    fieldConfigs.push(fieldConfig);

    // fieldConfig = new FieldConfig('PhoneNumber', 'Phone Number', 'tel');
    // fieldConfig.maxLength = 15;
    // fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('FaxNumber', 'Fax Number', 'tel');
    fieldConfig.maxLength = 15;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('Email', 'Email', 'email');
    fieldConfig.maxLength = 150;
    fieldConfigs.push(fieldConfig);

    formFieldConfig = new FormFieldConfig('Contact', fieldConfigs);
    this.formFieldConfigs.push(formFieldConfig);

    // Virtual

    fieldConfigs = [];

    fieldConfig = new FieldConfig('ContactType_DomainDesc', 'Contact Type:', 'text');
    fieldConfig.readOnly = true;
    fieldConfig.visible = false;
    // fieldConfig.includeInTable = true;
    // fieldConfig.includeInTableIndex = 1;
    fieldConfigs.push(fieldConfig);

    formFieldConfig = new FormFieldConfig('Virtual', fieldConfigs);
    this.formFieldConfigs.push(formFieldConfig);

    // Hidden

    fieldConfigs = [];

    // fieldConfig = new FieldConfig('Latitude', 'Latitude:', 'number');
    // format = new FieldNumberFormat(8);
    // fieldConfig.format = format;
    // fieldConfig.visible = false;
    // fieldConfigs.push(fieldConfig);

    // fieldConfig = new FieldConfig('Longitude', 'Longitude:', 'number');
    // format = new FieldNumberFormat(8);
    // fieldConfig.format = format;
    // fieldConfig.visible = false;
    // fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('IncidentId', 'Incident Id', 'text');
    fieldConfig.required = true;
    fieldConfig.editable = false;
    fieldConfig.visible = false;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('IncidentRegister_GlobalID', 'IncidentRegister_GlobalID', 'text');
    fieldConfig.editable = false;
    fieldConfig.maxLength = 38;
    fieldConfig.visible = false;
    fieldConfigs.push(fieldConfig);

    formFieldConfig = new FormFieldConfig('Hidden', fieldConfigs);
    formFieldConfig.visible = false;
    this.formFieldConfigs.push(formFieldConfig);

    super.createFormFieldConfigs();

    console.log('IncidentContact this.formFieldConfigs', this.formFieldConfigs);

    return this.formFieldConfigs;
  }

  protected updateVirtualFields() {
    //console.log("IncidentContact updateVirtualFields()");
    // Reset
    this.attributes = [];

    this.features.forEach((f) => {
      let attr: IncidentContactAttributes = new IncidentContactAttributes(this.lovDataService, this.domainLovService);
      //attr = f.attributes;

      //console.log("IncidentContact updateVirtualFields() f", f);

      attr.ContactType = f.attributes.ContactType;
      attr.ContactType_other = f.attributes.ContactType_other;
      attr.Name = f.attributes.Name;
      attr.IncidentId = f.attributes.IncidentId;
      attr.IncidentRegister_GlobalID = f.attributes.IncidentRegister_GlobalID;

      // Inherited
      //attr.FullName = f.attributes.FullName;
      attr.Position = f.attributes.Position;
      attr.Company = f.attributes.Company;
      attr.FullAddress = f.attributes.FullAddress;
      attr.AddressLine1 = f.attributes.AddressLine1;
      attr.AddressLine2 = f.attributes.AddressLine2;
      attr.Suburb = f.attributes.Suburb;
      attr.City = f.attributes.City;
      attr.State = f.attributes.State;
      attr.Postcode = f.attributes.Postcode;
      attr.Country = f.attributes.Country;
      attr.HomeNumber = f.attributes.HomeNumber;
      attr.MobileNumber = f.attributes.MobileNumber;
      attr.WorkNumber = f.attributes.WorkNumber;
      attr.FaxNumber = f.attributes.FaxNumber;
      attr.Email = f.attributes.Email;
      //attr.Latitude = f.attributes.Latitude;
      //attr.Longitude = f.attributes.Longitude;

      // Common
      attr.OBJECTID = f.attributes.OBJECTID;
      attr.ClubId = f.attributes.ClubId;
      attr.Active = f.attributes.Active;
      attr.GlobalID = f.attributes.GlobalID;
      //attr.HasLocation = f.attributes.HasLocation;
      attr.CreationDate = f.attributes.CreationDate;
      attr.Creator = f.attributes.Creator;
      attr.EditDate = f.attributes.EditDate;
      attr.Editor = f.attributes.Editor;

      // Populate virtual / calculated fields
      f.attributes.ContactType_DomainDesc = attr.ContactType_DomainDesc;
      f.attributes.FullAddress_Display = attr.FullAddress_Display;

      this.attributes.push(attr);
    });
  }
}

*/
