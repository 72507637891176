import { Component, OnInit, OnDestroy, AfterViewInit, Output, ChangeDetectionStrategy, ChangeDetectorRef, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { combineLatest } from 'rxjs';
import { takeUntil, tap, first } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { BaseFeaturePage } from 'src/app/_esri/models/base-feature-page';
import { FeaturesService } from 'src/app/_esri/services/features.service';
import { CourseService } from 'src/app/_course/services/course.service';
import { SurroundsService } from 'src/app/_course/services/surrounds.service';
import { GeocodeService } from 'src/app/_esri/services/geocode.service';
import { DashboardChart, Address, GeometryPoint, GeometryMultiPoint, Esri } from 'src/app/_esri/models/esri';
//import { IncidentService } from 'src/app/incident/services/incident.service';
import { CurrentModeService, CurrentModeData } from 'src/app/_globals/services/current-mode.service';
import { GeocodeDataService } from 'src/app/_globals/services/geocode-data.service';
import { FormChangeService } from 'src/app/_globals/services/form-change.service';
import { FormFieldChangeService } from 'src/app/_globals/services/form-field-change.service';
import { FormFieldUpdateService } from 'src/app/_globals/services/form-field-update.service';
import { DomainLovService } from 'src/app/_esri/services/domain-lov.service';
import { LovDataService } from 'src/app/_globals/services/lov-data.service';

import { Incident, IncidentAttributes, IncidentContact, IncidentSubCategoryLOV } from 'src/app/incident/models/incident';
import { Global } from 'src/app/_globals/models/global';
import { EsriViewTableComponent } from 'src/app/_esri/components/esri-view-table/esri-view-table.component';
import { EditComponent } from 'src/app/_esri/dialogs/edit/edit.component';
import { EsriEditFormMasterDetailComponent } from 'src/app/_esri/components/esri-edit-form-master-detail/esri-edit-form-master-detail.component';


import { WorkRequest, WorkRequestContact } from 'src/app/maintenance/models/work-request';
import { CreateWorkRequestService } from 'src/app/maintenance/services/create-work-request.service';

@Component({
  selector: 'app-incident',
  templateUrl: './incident.component.html',
  styleUrls: [
    './incident.component.scss',
    '../_styles/base-feature-page.scss'
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class IncidentComponent extends BaseFeaturePage implements OnInit, OnDestroy, AfterViewInit {

  // Contacts
  protected featureLayerObject_Contact: any;
  protected formName_Contact: string = 'INCIDENT_CONTACT';

  // Work Requests
  protected idType_WorkRequest: string = 'MAINT';
  protected idTypeFieldName_WorkRequest: string = 'WorkRequestId';
  protected formName_WorkRequest: string = 'INCIDENT_WORKREQUEST';

  // Incidents
  title = 'Incident Register';
  subTitle = 'Insurance claims and incidents';
  idType = 'INCIDENT';
  idTypeFieldName = 'IncidentId';
  showTimeSliderWidget = true;
  dateFilterAttributeName = 'IncidentDate';

  showDataCards = true;
  showDataTable = false;
  darkMode = false;
  includeNswProperty = true;

  // courseLayerOpacity = 0.3;
  // surroundsLayerOpacity = 0.1;
  // boundaryLayerOpacity = 0.3;
  // hillshadeLayerOpacity = 0.05;

  dashboardCharts: DashboardChart[] = [
    { chartName: 'STATUS' },
    { chartName: 'CATEGORY' },
    { chartName: 'SUB-CATEGORY' },
    { chartName: 'OUTCOME' },
    { chartName: 'MED-LGL' },
    { chartName: 'PAIDBY' },
    { chartName: 'INSURANCE' },
    { chartName: 'AMOUNTS' },
    { chartName: 'PREMISE' },
    { chartName: 'FAIRWAY' }
  ];

  parentLovWatchList = [{ parentLovKey: 'IncidentCategoryId', childLovName: 'LovIncidentSubCategory', childLovKey: 'IncidentSubCategoryId' }];  // Add risk?
  fieldChangeWatchList = ['OccurredOnPremise', 'Insurance', 'MedicalActionRequired', 'LegalActionTaken', 'Status', 'ClosedDate'];

  //public insuranceClaim: boolean;
  //public insuranceHidden: boolean = true;
  //public insuranceNextHidden: boolean = true;

  //public linkRisk: boolean;
  //public riskHidden: boolean = true;
  //public riskNextHidden: boolean = true;
  //public riskSelectHidden: boolean = true;
  private _riskRegister: string;
  private _riskCategory: string;
  private _riskSubCategory: string;
  private _riskGlobalId: string;

  public cardColour: string = '#fdfdfd'; // '#FDFDFD';
  public colourScheme: any = { domain: ['#fdfdfd'] };   //   '#f9f9f9' { domain: [ '#5AA454', '#E44D25', '#CFC0BB', '#7aa3e5', '#a8385d', '#aae3f5'] }


  // Current Settings

  // Dialog
  protected fieldChangeWatchList_Contact = ['ContactType', 'FullAddress']; // e.g.: ['AuditCoursePart', 'FairwayNumber', ...]
  protected parentLovWatchList_Contact = [];   // e.g.: [{parentLovKey: 'RiskCategoryId', childLovName: 'LovRiskSubCategory', childLovKey: 'RiskSubCategoryId'}, {..}, {..}]\  
  protected formDialogRef_Contact: any;
  protected defaultFieldValues_Contact: any[] = []; //IncidentId": null, "IncidentRegister_GlobalID ": null
  protected defaultFieldVisibility_Contact: any[] = [];
  //private _reportDialogRef_Contact: any;

  // Current Settings
  public selectedFeature_Contact: any;

  // Client Side Feature Layers
  private _featureLayerHeatmap;

  private _fieldsHeatMap: any[] = [
    {
      name: "OBJECTID",
      type: "oid"
    },
  ];

  private _rendererHeatMap = Esri.rendererHeatMap_PurpleYellow;

  // Events
  @Output() workRequestIntegrationReadyEvent = new EventEmitter<any>();
  @Output() incidentIntegrationReadyEvent = new EventEmitter<any>();

  // AccessChild component attributes and functions
  @ViewChild(EsriEditFormMasterDetailComponent) esriEditFormMasterDetailComponent: EsriEditFormMasterDetailComponent;

  constructor(
    protected ref: ChangeDetectorRef,
    protected dialog: MatDialog,
    protected currentModeService: CurrentModeService,
    protected geocodeDataService: GeocodeDataService,
    protected formChangeService: FormChangeService,
    protected formFieldChangeService: FormFieldChangeService,
    protected formFieldUpdateService: FormFieldUpdateService,
    protected elementRef: ElementRef,
    // --
    private geocodeService: GeocodeService,
    private createWorkRequestService: CreateWorkRequestService
  ) {
    super(
      ref,
      dialog,
      currentModeService,
      geocodeDataService,
      formChangeService,
      formFieldChangeService,
      formFieldUpdateService,
      elementRef
    );
  }

  ngOnInit(): void {
    this.thinking = true;

    // Get layers, features, lovs, domains, etc
    this.featureLayerObject = new Incident();

    //
    // Contacts
    //

    // Get Sub Category Details - this has the default SLA, assignment, responsibility, and work force integration info
    this.featureLayerObject_Contact = new IncidentContact();

    // Wait for child layer to load
    this.featureLayerObject_Contact.featureLayerLoadedEvent.pipe(takeUntil(this.ngUnsubscribe)).subscribe((response: any) => {
      console.log('****** ! all child data (contacts) ready ! ******', this.featureLayerObject_Contact);
      this.displayPageComponents_Contact();
    });

    // Load the Contacts Feature Layer (table, so doesn't need to wait for the view to be ready)
    this.featureLayerObject_Contact.getLovs();
    this.featureLayerObject_Contact.getFeatureLayerConfig().then(() => {
      this.featureLayerObject_Contact.configReadyEvent.emit();
    });

    //
    // Work Request Integrations
    //

    // Initialise the Work Request Object
    this.createWorkRequestService.initialiseWorkRequestIntegration();

    // Initialise the Incident LOV Object
    this.createWorkRequestService.initialiseIncidentIntegration();

    combineLatest([
      this.createWorkRequestService.workRequestReadyEvent,
      this.createWorkRequestService.incidentReadyEvent
    ]).pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      console.log('Integration Ready Event ready');
      this.esriMapComponent.createWorkRequestBtnHidden = false;
      //this.idType_WorkRequest = this.createWorkRequestService.workRequest.idType;
      //this.idTypeFieldName_WorkRequest = this.createWorkRequestService.workRequest.idTypeFieldName;
    });

    super.ngOnInit();
  }

  ngOnDestroy(): void {
    this.createWorkRequestService.destroyer();
    super.ngOnDestroy();
  }

  protected refreshDisplay() {
    this.featureLayerObject.updateVirtualFields();

    // Add a heat map to illustrate potential trends
    if (this._featureLayerHeatmap) {
      this.view.map.remove(this._featureLayerHeatmap);
    }

    this.featuresService.createClientSideFeatureLayer('featureLayerHeatmap', 'Heat map', this.featureLayerObject.features, this._fieldsHeatMap, false, this._rendererHeatMap, null, null).then( layer => {
      this._featureLayerHeatmap = layer;
      this.view.map.add(this._featureLayerHeatmap);
    });

    super.refreshDisplay();
  }

  // Build data for charts
  protected createCharts() {
    super.createCharts();
    this.dashboardCharts.find(x => x.chartName == 'STATUS').summaryData = this.createChartDataSet('DomIncidentStatus', 'Status', true);
    this.dashboardCharts.find(x => x.chartName == 'CATEGORY').summaryData = this.createChartDataSet('LovIncidentCategory', 'IncidentCategoryId', true);
    this.dashboardCharts.find(x => x.chartName == 'SUB-CATEGORY').summaryData = this.createChartDataSet_SubCategory(this.featureLayerObject.attributes, 'IncidentCategoryId', 'Category', 'IncidentSubCategoryId', 'SubCategory');
    this.dashboardCharts.find(x => x.chartName == 'PREMISE').summaryData = this.createChartDataSet('DomYesNo', 'OccurredOnPremise', true);
    this.dashboardCharts.find(x => x.chartName == 'OUTCOME').summaryData = this.createChartDataSet('DomIncidentOutcome', 'Outcome', true);
    this.dashboardCharts.find(x => x.chartName == 'PAIDBY').summaryData = this.createChartDataSet('DomSettlementPaidBy', 'Insurance_SettlementPaidBy', true);
    this.dashboardCharts.find(x => x.chartName == 'INSURANCE').summaryData = this.createChartDataSet('DomYesNo', 'Insurance', true);
    this.dashboardCharts.find(x => x.chartName == 'MED-LGL').summaryData = this.createChartDataSet_CountMany(['MedicalActionRequired', 'LegalActionTaken'], ['YES', 'YES'], ['Medical', 'Legal']);
    this.dashboardCharts.find(x => x.chartName == 'AMOUNTS').summaryData = this.createChartDataSet_SumMany(this.featureLayerObject.attributes, ['Insurance_ClaimAmount', 'Insurance_SettlementAmount'], ['Claim', 'Settlement']);
    this.dashboardCharts.find(x => x.chartName == 'FAIRWAY').summaryData = this.createChartDataSet('DomFairway', 'FairwayNumber', true);



    //console.log(this.dashboardCharts);
  }

  //
  // Workflow - Control button and editor behaviour based on current feature edit mode
  //

  protected setCurrentMode(currentModeData: CurrentModeData) {
    console.log('Incident setCurrentMode currentModeData', currentModeData);
    super.setCurrentMode(currentModeData);
    let mode = currentModeData.mode.toLowerCase();

    if (currentModeData.formName.toUpperCase() === this.formName.toUpperCase()) { 
      console.log('setCurrentMode() FORM mode', mode);

      let fieldVisibility_OnPremises: any[] = [];
      let fieldVisibility_Insurance: any[] = [];
      let fieldVisibility_Medical: any[] = [];
      let fieldVisibility_Legal: any[] = [];

      switch (mode) {
        case 'add':
          this.esriMapComponent.locationHidden = false;
          break;

        case 'add-popup':
          break;

        case 'location-map':
          //this.setFormFields_OnPremises('YES');
          break;

        case 'location-address':
          //this.setFormFields_OnPremises('NO');
          break;

        case 'location-selected':
        case 'location-changed':

          if (mode === 'location-selected') {
            // Set the defaults for a new record 
            this.defaultFieldValues = [
              {
                name: 'Insurance',
                value: 'NO'
              },
              {
                name: 'MedicalActionRequired',
                value: 'NO'
              },
              {
                name: 'LegalActionTaken',
                value: 'NO'
              }
            ];

            fieldVisibility_Insurance = [...this.setFormFields_Insurance('NO'),];
            fieldVisibility_Medical = [...this.setFormFields_Medical('NO')];
            fieldVisibility_Legal = [...this.setFormFields_Legal('NO')];
          }

          this.featuresService.checkWithinLayer(this.boundaryLayer, this.geometry).then(onPremises => {
            if (onPremises === true) {
              console.log('in');

              this.defaultFieldValues.push({
                name: 'OccurredOnPremise',
                value: 'YES'
              });

              fieldVisibility_OnPremises = [...this.setFormFields_OnPremises('YES')];
              this.defaultFieldVisibility = [...fieldVisibility_OnPremises, ...fieldVisibility_Insurance, ...fieldVisibility_Medical, ...fieldVisibility_Legal];

              // Lookup the Fairway Number
              this.featuresService.getAttributeWithinLayer(this.fairwayLayer, this.geometry, 'FairwayNumber').then(fairwayNumber => {
                //console.log('fairwayNumber', fairwayNumber);

                // Create a string representation to allow a substring check for "ERROR"
                let _fairwayNumber: string = fairwayNumber.toString();
                //console.log('_fairwayNumber', _fairwayNumber, _fairwayNumber.substr(0, 5));

                if (_fairwayNumber.substr(0, 5) === 'ERROR') {
                  //console.log('Fairway Number not found');
                  this.defaultFieldValues.push({
                    name: 'FairwayNumber',
                    value: 0
                  });
                }
                else {
                  //console.log('Fairway Number found');
                  this.defaultFieldValues.push({
                    name: 'FairwayNumber',
                    value: fairwayNumber
                  });
                }

                // Show form
                //this.openEditFeatureDialog('add', 'Create a new item');
                if (mode === 'location-changed') {
                  // Mark the form dirty
                  console.log('make dirty location-changed', event);
                  this.openEditFeatureDialog(mode, true);
                }
                else {
                  this.openEditFeatureDialog(mode);
                }
              });
            }
            else {
              console.log('out');

              this.defaultFieldValues.push({
                name: 'OccurredOnPremise',
                value: 'NO'
              });

              fieldVisibility_OnPremises = [...this.setFormFields_OnPremises('NO')];
              this.defaultFieldVisibility = [...fieldVisibility_OnPremises, ...fieldVisibility_Insurance, ...fieldVisibility_Medical, ...fieldVisibility_Legal];

              // Send the address details to the form
              if (this.selectedAddress) {
                let address: any[] = [
                  { name: 'AddressLine1', value: this.selectedAddress.AddressLine1 },
                  { name: 'AddressLine2', value: this.selectedAddress.AddressLine2 },
                  { name: 'Suburb', value: this.selectedAddress.Suburb },
                  { name: 'Postcode', value: this.selectedAddress.Postcode },
                  { name: 'State', value: this.selectedAddress.State },
                  { name: 'Country', value: this.selectedAddress.Country },
                  //{ name: 'City', value: this.selectedAddress.City },
                  { name: 'FullAddress', value: this.selectedAddress.FormattedAddress }
                ];

                this.defaultFieldValues.push(...address);

                // Show form
                this.openEditFeatureDialog(mode);
              }
              else {
                // Get the address of the location if outside boundary by a reverse geocode
                this.geocodeService.searchLongitude = this.geometry.longitude.toString();
                this.geocodeService.searchLatitude = this.geometry.latitude.toString();

                //
                // ESRI reverse geocode is NOT accurate enough for The Lakes Golf Club Area
                //

                // OPTION 1
                // NSW Property based reverse geocode workaround : opens form and address appears in fields a bit later
                this.geocodeService.reverseGeocodeNSW();

                // Show form
                this.openEditFeatureDialog(mode);


                // OPTION 2
                // NSW Property based reverse geocode workaround - Asynchronous  : waits for address and then opens form
                // this.geocodeService.reverseGeocodeNSW_Async().then( (event) => {
                //   console.log('reverseGeocode data received is: ', event);
                //   //this.selectedAddress = new Address(data.candidates[0].address.Address, data.address.Neighborhood, data.address.Region, data.address.Postal, this.country);
                //   this.selectedAddress = new Address(event.candidates[0].attributes.StAddr, event.candidates[0].attributes.Nbrhd, event.candidates[0].attributes.Region, event.candidates[0].attributes.Postal, this.country);
                //   let address: any[] = [
                //     { name: 'AddressLine1', value: this.selectedAddress.AddressLine1 },
                //     { name: 'AddressLine2', value: this.selectedAddress.AddressLine2 },
                //     { name: 'Suburb', value: this.selectedAddress.Suburb },
                //     { name: 'Postcode', value: this.selectedAddress.Postcode },
                //     { name: 'State', value: this.selectedAddress.State },
                //     { name: 'Country', value: this.selectedAddress.Country },
                //     { name: 'FullAddress', value: this.selectedAddress.FormattedAddress }
                //   ];
                //   this.defaultFieldValues.push(...address);
                //   // Show form
                //   this.openEditFeatureDialog('add', 'Create a new item');
                // });

                // OPTION 3
                // ESRI Reverse Geocode - quicker, but NOT accurate
                // this.geocodeService.reverseGeocode().subscribe(
                //   data => { 
                //     console.log('reverseGeocode data received is: ', data);
                //     this.selectedAddress = new Address(data.address.Address, data.address.Neighborhood, data.address.Region, data.address.Postal, this.country);
                //     let address: any[] = [
                //       { name: 'AddressLine1', value: this.selectedAddress.AddressLine1 },
                //       { name: 'AddressLine2', value: this.selectedAddress.AddressLine2 },
                //       { name: 'Suburb', value: this.selectedAddress.Suburb },
                //       { name: 'Postcode', value: this.selectedAddress.Postcode },
                //       { name: 'State', value: this.selectedAddress.State },
                //       { name: 'Country', value: this.selectedAddress.Country },
                //       { name: 'FullAddress', value: this.selectedAddress.FormattedAddress }
                //     ];
                //     this.defaultFieldValues.push(...address);
                //     // Show form
                //     this.openEditFeatureDialog('add', 'Create a new item');
                //   },
                //   error => {
                //     console.error('There was an error!', error);
                //   }
                // );

              }
            }

            // Hide the workflow box
            this.esriMapComponent.locationMap = false;
            this.esriMapComponent.locationAddress = false;
          });

          break;

        case 'edit-attribute':
          fieldVisibility_OnPremises = [];
          fieldVisibility_Insurance = [];
          fieldVisibility_Medical = [];
          fieldVisibility_Legal = [];

          if (this.selectedFeature.attributes.OccurredOnPremise === 'YES') {
            fieldVisibility_OnPremises = [...this.setFormFields_OnPremises('YES')];
          }
          else {
            fieldVisibility_OnPremises = [...this.setFormFields_OnPremises('NO')];
          }

          if (this.selectedFeature.attributes.Insurance === 'YES') {
            fieldVisibility_Insurance = [...this.setFormFields_Insurance('YES')];
          }
          else {
            fieldVisibility_Insurance = [...this.setFormFields_Insurance('NO')];
          }

          if (this.selectedFeature.attributes.MedicalActionRequired === 'YES') {
            fieldVisibility_Medical = [...this.setFormFields_Medical('YES')];
          }
          else {
            fieldVisibility_Medical = [...this.setFormFields_Medical('NO')];
          }

          if (this.selectedFeature.attributes.LegalActionTaken === 'YES') {
            fieldVisibility_Legal = [...this.setFormFields_Legal('YES')];
          }
          else {
            fieldVisibility_Legal = [...this.setFormFields_Legal('NO')];
          }

          this.defaultFieldVisibility = [...fieldVisibility_OnPremises, ...fieldVisibility_Insurance, ...fieldVisibility_Medical, ...fieldVisibility_Legal];


          console.log('this.defaultFieldVisibility', this.defaultFieldVisibility);

          this.openEditFeatureDialog(mode);
          break;

        // case 'form-submitted':
        //   break;
      }
    }
    else if (currentModeData.formName.toUpperCase() === this.formName_Contact.toUpperCase()) { 
      console.log('setCurrentMode() INCIDENT_CONTACT mode', mode);
      
      switch (mode) {
        case 'select':
          break;

        case 'add':
          // this.esriMapComponent.addBtnHidden = true;
          // this.esriMapComponent.reportBtnHidden = true;
          // this.esriEditFormMasterDetailComponent.esriViewTableComponent_Contact.showAddHeaderBtn = false;
          //this.esriEditFormMasterDetailComponent.esriViewTableComponent_Contact.showReportHeaderBtn = false;
          this.unSelectFeature_Contact();

          // Get the Incident Parent key fields
          this.defaultFieldValues_Contact = [
            {
              name: 'IncidentId',
              value: this.selectedFeature.attributes.IncidentId
            },
            {
              name: 'IncidentRegister_GlobalID',
              value: this.selectedFeature.attributes.GlobalID
            },
            {
              name: 'HasLocation',
              value: 'NO'
            },
            {
              name: 'CONTACTTYPE',
              value: 'CLAIMANT'
            }
          ];

          if (this.selectedFeature.attributes.OccurredOnPremise === 'NO') {
            this.defaultFieldValues_Contact.push({
              name: 'FullAddress',
              value: this.selectedFeature.attributes.FullAddress
            });

            this.defaultFieldValues_Contact.push({
              name: 'AddressLine1',
              value: this.selectedFeature.attributes.AddressLine1
            });

            this.defaultFieldValues_Contact.push({
              name: 'AddressLine2',
              value: this.selectedFeature.attributes.AddressLine2
            });

            this.defaultFieldValues_Contact.push({
              name: 'Suburb',
              value: this.selectedFeature.attributes.Suburb
            });

            // this.defaultFieldValues_Contact.push({
            //   name: 'City',
            //   value: this.selectedFeature.attributes.City
            // });

            this.defaultFieldValues_Contact.push({
              name: 'State',
              value: this.selectedFeature.attributes.State
            });

            this.defaultFieldValues_Contact.push({
              name: 'Postcode',
              value: this.selectedFeature.attributes.Postcode
            });

            this.defaultFieldValues_Contact.push({
              name: 'Country',
              value: this.selectedFeature.attributes.Country
            });
          }

          this.openEditFeatureDialog_Contact(mode);
          break;

        // case 'add-cancel':
        //   //this.resetCurrent();
        //   this.esriMapComponent.addBtnHidden = false;
        //   this.esriMapComponent.reportBtnHidden = false;
        //   this.esriEditFormMasterDetailComponent.esriViewTableComponent_Contact.showAddHeaderBtn = true;
        //   //this.esriEditFormMasterDetailComponent.esriViewTableComponent_Contact.showReportHeaderBtn = true;
        //   break;

        case 'edit':
          this.openEditFeatureDialog_Contact(mode);
          break;

        case 'form-submitted':
          this.unSelectFeature_Contact();
          this.esriMapComponent.addBtnHidden = false;
          this.esriMapComponent.reportBtnHidden = false;
          //this.esriEditFormMasterDetailComponent.esriViewTableComponent_Contact.showAddHeaderBtn = true;
          //this.esriEditFormMasterDetailComponent.esriViewTableComponent_Contact.showReportHeaderBtn = true;

          // Requery / refresh
          this.getContacts(this.selectedFeature.attributes);

          break;

        case 'cancel':
        case 'close':
          // this.esriMapComponent.addBtnHidden = false;
          // this.esriMapComponent.reportBtnHidden = false;
          // this.esriEditFormMasterDetailComponent.esriViewTableComponent_Contact.showAddHeaderBtn = true;
          //this.esriEditFormMasterDetailComponent.esriViewTableComponent_Contact.showReportHeaderBtn = false;
          break;
      }

    }
    else if (currentModeData.formName.toUpperCase() === this.formName_WorkRequest.toUpperCase()) { 
      console.log('setCurrentMode() INCIDENT_WORKREQUEST mode', mode);

      switch (mode) {
        case 'form-submitted':
          console.log('setCurrentMode() INCIDENT_WORKREQUEST form-submitted this.lastrecordId', this.lastrecordId);

          if (this.lastrecordId) {
            let fieldValue: any[] = [];
            let fieldUpdateData = {};

            fieldValue.push({
              name: this.idTypeFieldName_WorkRequest,
              value: this.lastrecordId
            });

            fieldUpdateData = {
              updateType: 'value',
              data: fieldValue
            }

            if (fieldUpdateData) {
              this.formFieldUpdateService.formFieldUpdateData.next(fieldUpdateData);
            }
          }

        default:
          console.log('Mode, "' + mode + '" not defined for form "INCIDENT_WORKREQUEST"');
          break;
      }
    }
    else {
      // Should be nothing
    }
  }

  //
  // Form Changes Events
  //

  protected featureFormFieldChangeEvent(event) {
    console.log('featureFormFieldChangeEvent', event);
    super.featureFormFieldChangeEvent(event);

    let fieldVisibility: any[] = [];
    let fieldValue: any[] = [];
    let fieldUpdateData = {};

    // Show / hide fields as required by responses
    switch (event.fieldName.toUpperCase()) {
      case 'INSURANCE':
        //Insurance
        fieldVisibility = this.setFormFields_Insurance(event.newValue);
        fieldUpdateData = {
          updateType: 'visibility',
          data: fieldVisibility
        }
        break;

      case 'OCCURREDONPREMISE':
        //OccurredOnPremise
        fieldVisibility = this.setFormFields_OnPremises(event.newValue);
        fieldUpdateData = {
          updateType: 'visibility',
          data: fieldVisibility
        }
        break;

      case 'MEDICALACTIONREQUIRED':
        //MedicalActionRequired
        fieldVisibility = this.setFormFields_Medical(event.newValue);
        fieldUpdateData = {
          updateType: 'visibility',
          data: fieldVisibility
        }
        break;

      case 'LEGALACTIONTAKEN':
        //LegalActionTaken
        fieldVisibility = this.setFormFields_Legal(event.newValue);
        fieldUpdateData = {
          updateType: 'visibility',
          data: fieldVisibility
        }
        break;

      case 'STATUS':
        //Status 
        if (event.newValue === 'CLOSED') {
          // Set the closed date to the current date
          fieldValue.push({
            name: 'ClosedDate',
            value: Date.now()
          });

          fieldUpdateData = {
            updateType: 'value',
            data: fieldValue
          }
        }
        else if (event.newValue === 'OPEN') {
          // Clear the value in the date closed field
          fieldValue.push({
            name: 'ClosedDate',
            value: null
          });

          fieldUpdateData = {
            updateType: 'value',
            data: fieldValue
          }
        }
        else {
          fieldUpdateData = null;
        }
        break;

      case 'CLOSEDDATE':
        //ClosedDate
        if ((this.selectedFeature && this.selectedFeature.attributes.Status === 'OPEN') || (!this.selectedFeature)) {
          fieldValue.push({
            name: 'Status',
            value: 'CLOSED'
          });

          fieldUpdateData = {
            updateType: 'value',
            data: fieldValue
          }
        }
        else {
          fieldUpdateData = null;
        }
        break;

      // Contact Form

      case 'FULLADDRESS':
        if (event.newValue === '') {
          // Clear all the address fields if the 'FullAddress' field has been cleared
          fieldValue.push({
            name: 'AddressLine1',
            value: ''
          });

          fieldValue.push({
            name: 'AddressLine2',
            value: ''
          });

          fieldValue.push({
            name: 'Suburb',
            value: ''
          });

          // fieldValue.push({
          //   name: 'City',
          //   value: this.selectedFeature.attributes.City
          // });

          fieldValue.push({
            name: 'State',
            value: ''
          });

          fieldValue.push({
            name: 'Postcode',
            value: ''
          });

          fieldValue.push({
            name: 'Country',
            value: ''
          });

          fieldUpdateData = {
            updateType: 'value',
            data: fieldValue
          }

        }
        break;
      // case 'CONTACTTYPE':
      //   if (event.newValue === 'CLAIMANT') {
      //     if (this.selectedFeature.attributes.OccurredOnPremise === 'NO') {
      //       fieldValue.push({
      //         name: 'FullAddress',
      //         value: this.selectedFeature.attributes.FullAddress
      //       });

      //       fieldValue.push({
      //         name: 'AddressLine1',
      //         value: this.selectedFeature.attributes.AddressLine1
      //       });

      //       fieldValue.push({
      //         name: 'AddressLine2',
      //         value: this.selectedFeature.attributes.AddressLine2
      //       });

      //       fieldValue.push({
      //         name: 'Suburb',
      //         value: this.selectedFeature.attributes.Suburb
      //       });

      //       // fieldValue.push({
      //       //   name: 'City',
      //       //   value: this.selectedFeature.attributes.City
      //       // });

      //       fieldValue.push({
      //         name: 'State',
      //         value: this.selectedFeature.attributes.State
      //       });

      //       fieldValue.push({
      //         name: 'Postcode',
      //         value: this.selectedFeature.attributes.Postcode
      //       });

      //       fieldValue.push({
      //         name: 'Country',
      //         value: this.selectedFeature.attributes.Country
      //       });

      //       fieldUpdateData = {
      //         updateType: 'value',
      //         data: fieldValue
      //       }
      //     }
      //   }
      //   break;

      default:
        console.log('Form Field Change not configured for this field', event);
        break;
    }

    // Update the field value / visibility in the current form

    //console.log('Form Field Change', fieldUpdateData);

    if (fieldUpdateData) {
      this.formFieldUpdateService.formFieldUpdateData.next(fieldUpdateData);
    }
  }

  //
  // Field Visibility
  //

  private setFormFields_OnPremises(value: string): any {
    //console.log('setFormFields_OnPremises', value);
    let fieldVisibility: any[] = []
    const onPremiseFields: string[] = ['AddressLine1', 'AddressLine2', 'Suburb', 'State', 'Postcode', 'Country', 'FullAddress'];
    //const offPremiseFields: string[] = ['FairwayNumber', 'AssetClass', 'AssetCategory', 'AssetType', 'AssetId'];
    const offPremiseFields: string[] = ['FairwayNumber'];

    // this.defaultFieldVisibility.push({
    //   name: fieldName,
    //   value: false
    // });

    if (value === 'YES') {
      onPremiseFields.forEach(fieldName => {
        fieldVisibility.push({
          name: fieldName,
          value: false
        });
      });

      offPremiseFields.forEach(fieldName => {
        fieldVisibility.push({
          name: fieldName,
          value: true
        });
      });
    }
    else {
      onPremiseFields.forEach(fieldName => {
        fieldVisibility.push({
          name: fieldName,
          value: true
        });
      });

      offPremiseFields.forEach(fieldName => {
        fieldVisibility.push({
          name: fieldName,
          value: false
        });
      });
    }

    return fieldVisibility;
  }

  private setFormFields_Insurance(value: string): any[] {
    let fieldVisibility: any[] = []
    const fields: string[] = ['Insurance_ClaimAmount', 'Insurance_ClaimReference', 'Insurance_SettlementAmount', 'Insurance_SettlementDate', 'Insurance_SettlementPaidBy', 'Outcome'];

    if (value === 'YES') {
      //this.setFieldVisibility(fields, true);
      fields.forEach(fieldName => {
        fieldVisibility.push({
          name: fieldName,
          value: true
        });
      });
    }
    else {
      //this.setFieldVisibility(fields, false);
      fields.forEach(fieldName => {
        fieldVisibility.push({
          name: fieldName,
          value: false
        });
      });
    }

    return fieldVisibility;
  }

  private setFormFields_Medical(value: string): any[] {
    let fieldVisibility: any[] = []
    const fields: string[] = ['MedicalActionDetails'];

    if (value === 'YES') {
      //this.setFieldVisibility(fields, true);
      fields.forEach(fieldName => {
        fieldVisibility.push({
          name: fieldName,
          value: true
        });
      });
    }
    else {
      //this.setFieldVisibility(fields, false);
      fields.forEach(fieldName => {
        fieldVisibility.push({
          name: fieldName,
          value: false
        });
      });
    }

    return fieldVisibility;
  }

  private setFormFields_Legal(value: string): any[] {
    let fieldVisibility: any[] = []
    const fields: string[] = ['LegalActionDetails'];

    if (value === 'YES') {
      //this.setFieldVisibility(fields, true);
      fields.forEach(fieldName => {
        fieldVisibility.push({
          name: fieldName,
          value: true
        });
      });
    }
    else {
      //this.setFieldVisibility(fields, false);
      fields.forEach(fieldName => {
        fieldVisibility.push({
          name: fieldName,
          value: false
        });
      });
    }

    return fieldVisibility;
  }

  //
  // Enhance Base Functions
  //

  protected selectFeature(feature: any, origin: string) {
    super.selectFeature(feature, origin);
    //console.log('selectFeature this.featureLayerObject_Contact feature', feature);
    this.getContacts(feature);
    this.esriEditFormMasterDetailComponent.featureSelected = true;

    // Enable / Disable Work Request Integration Button
    if (this.selectedFeature.attributes.OccurredOnPremise === 'YES') {
      // Enable Create Work Request Button
      this.esriMapComponent.createWorkRequestBtnDisabled = false;
    }
    else {
      // Disable Create Work Request Button
      this.esriMapComponent.createWorkRequestBtnDisabled = true;
    }
  }

  protected unSelectFeature() {
    this.esriEditFormMasterDetailComponent.featureSelected = false;
    this.esriEditFormMasterDetailComponent.esriViewTableComponent_Contact.showAddHeaderBtn = false;
    this.clearContacts();
    this.unSelectFeature_Contact();

    // Disable Create Work Request Button
    this.esriMapComponent.createWorkRequestBtnDisabled = true;

    super.unSelectFeature();
  }

  //
  // Contacts
  //

  private async getContacts(feature: any) {
    //console.log('getContacts())', feature);

    this.esriEditFormMasterDetailComponent.esriViewTableComponent_Contact.showAddHeaderBtn = true;

    // Get the contacts
    let objectIds = [feature.OBJECTID];
    //console.log('selectFeature this.featureLayerObject_Contact objectIds', feature.OBJECTID, objectIds);

    await this.featureLayerObject.getContacts(objectIds).then((response) => {

      if (Object.keys(response).length > 0) {
        console.log('Number contacts found: ', response[feature.OBJECTID].features.length);
        this.featureLayerObject_Contact.features = response[feature.OBJECTID].features;
        this.featureLayerObject_Contact.updateVirtualFields();
        this.esriEditFormMasterDetailComponent.esriViewTableComponent_Contact.attributes = this.featureLayerObject_Contact.attributes;
        this.esriEditFormMasterDetailComponent.esriViewTableComponent_Contact.initilaiseViewTable();
        this.esriEditFormMasterDetailComponent.numberOfRecords_Contact = response[feature.OBJECTID].features.length;
        this.esriEditFormMasterDetailComponent.createNumberOfAssociatedRecordsMsg();
      }
      else {
        console.log('No contacts found');
        this.clearContacts();
      }
    });


    //this.dataReadyEvent_Contact.emit(true);
  }

  private clearContacts() {
    this.featureLayerObject_Contact.features = null;
    this.esriEditFormMasterDetailComponent.esriViewTableComponent_Contact.attributes = null;
    this.esriEditFormMasterDetailComponent.esriViewTableComponent_Contact.initilaiseViewTable();
    this.esriEditFormMasterDetailComponent.numberOfRecords_Contact = 0;
    this.esriEditFormMasterDetailComponent.createNumberOfAssociatedRecordsMsg();
  }

  protected displayPageComponents_Contact() {

    this.featureLayerObject_Contact.updateVirtualFields();

    // Configure Contacts Data Table
    this.esriEditFormMasterDetailComponent.esriViewTableComponent_Contact.showAddHeaderBtn = false;
    this.esriEditFormMasterDetailComponent.esriViewTableComponent_Contact.showReportHeaderBtn = false;

    // Table row selection
    this.esriEditFormMasterDetailComponent.esriViewTableComponent_Contact.tableRowSelectedEvent.pipe(takeUntil(this.ngUnsubscribe)).subscribe((rowData) => {
      this.selectFeature_Contact(rowData.feature, rowData.origin);
    });

    // Table Row Action Button - Edit
    this.esriEditFormMasterDetailComponent.esriViewTableComponent_Contact.editActionSelectedEvent.pipe(takeUntil(this.ngUnsubscribe)).subscribe((feature) => {
      this.selectFeature_Contact(feature, "EDIT");
      this.editBtnEvent_Contact();
    });

    // Get the list of field names to display in the table
    this.esriEditFormMasterDetailComponent.esriViewTableComponent_Contact.tableFields = this.featureLayerObject_Contact.fieldConfigTypes.tableFieldConfig;

    this.esriEditFormMasterDetailComponent.esriViewTableComponent_Contact.tableFields.forEach(field => {
      this.esriEditFormMasterDetailComponent.esriViewTableComponent_Contact.displayedColumns.push(field.name);
    });

    this.esriEditFormMasterDetailComponent.esriViewTableComponent_Contact.showActionIconsInTable = this.showActionIconsInTable;
    this.esriEditFormMasterDetailComponent.esriViewTableComponent_Contact.fieldAlwaysVisible = this.forceFieldVisibleInTable;

    if (this.showActionIconsInTable === true) {
      this.esriEditFormMasterDetailComponent.esriViewTableComponent_Contact.displayedColumns.push('actionIcons');
    }

    // Table Header Buttons

    // Add Button
    this.esriEditFormMasterDetailComponent.esriViewTableComponent_Contact.addHeaderSelectedEvent.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.addBtnEvent_Contact();
    });

    // Report Button
    // this.esriEditFormMasterDetailComponent.esriViewTableComponent_Contact.reportBtnSelectedEvent.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
    //   this.unSelectFeature_Contact();
    //   //console.log('reportBtnSelectedEvent');
    //   this.openReportDialog_Contact();
    // });

    //this.dataReadyEvent_Contact.emit(true);

    console.log('>>>> featureLayerObject_Contact data ready <<<<', this.featureLayerObject_Contact);
  }

  protected selectFeature_Contact(feature: any, origin: string) {
    console.log('selectFeature_Contact, feature, origin', feature, origin);

    if (origin != 'UNSELECT') {
      // find the feature in this._features
      let index = -1;

      this.featureLayerObject_Contact.features.find(function (item, i) {
        if (item.attributes.OBJECTID === feature.OBJECTID) {
          index = i;
          return i;
        }
      });

      // Store the selected feature
      this.selectedFeature_Contact = this.featureLayerObject_Contact.features[index];
      //console.log('selectFeature_Contact this.selectedFeature_Contact', this.selectedFeature_Contact);
    }

  }

  protected unSelectFeature_Contact() {
    if (this.selectedFeature_Contact) {
      // Remove selected feature from store
      this.selectedFeature_Contact = null;
      this.esriEditFormMasterDetailComponent.esriViewTableComponent_Contact.tableRowSelected(null, "UNSELECT");
    }
  }

  // Add button
  public addBtnEvent_Contact() {
    //this.setCurrentMode_Contact('add');

    let currentModeData: CurrentModeData = {
      formName: 'INCIDENT_CONTACT',
      mode: 'add'
    }

    this.setCurrentMode(currentModeData);

  }

  // Edit button
  private editBtnEvent_Contact() {
    //this.setCurrentMode_Contact('edit');
    let currentModeData: CurrentModeData = {
      formName: 'INCIDENT_CONTACT',
      mode: 'edit'
    }
    this.setCurrentMode(currentModeData);
  }

  protected openEditFeatureDialog_Contact(mode: string, markDirtyWhenOpened?: boolean) {

    let _dialogTitle = '';

    // if (dialogTitle) {
    //   _dialogTitle = dialogTitle;
    // }
    // else {

    if ((mode.toUpperCase().indexOf('ADD') > 0) || (mode.toUpperCase().indexOf('SELECTED') > 0)) {
      _dialogTitle = 'Create a new item';
    }
    else {
      _dialogTitle = 'Edit an item';
    }
    //}

    // Exit fullscreen map mode if in it (otherwise, won't see the dialog)
    //this.esriMapComponent.exitFullscreen();

    // TO DO: **** Set max/min width based on screen size ****
    let minWidth = '50%';
    let maxWidth = '50%';

    this.formDialogRef = this.dialog.open(EditComponent, {
      disableClose: true,
      minWidth: minWidth,
      maxWidth: maxWidth,
      data: {
        title: _dialogTitle,
        //formFieldConfigs: this.formFieldConfigs,
        formFieldConfigs: this.featureLayerObject_Contact.formFieldConfigs,
        featureLayer: this.featureLayerObject_Contact.featureLayer,
        feature: this.selectedFeature_Contact,
        geometry: null,
        domains: this.featureLayerObject_Contact.domains,
        domains_lov: this.featureLayerObject_Contact.domains_lov,
        domains_lovSubDomain: this.featureLayerObject_Contact.domains_lovSubDomain,
        useFormStepper: false,
        // currentMode: mode_Contact,
        fieldChangeWatchList: this.fieldChangeWatchList_Contact,
        parentLovWatchList: this.parentLovWatchList_Contact,
        defaultFieldValues: this.defaultFieldValues_Contact,
        defaultFieldVisibility: this.defaultFieldVisibility_Contact,
        // markDirtyWhenOpened: markDirtyWhenOpened_Contact,
        // idType: this.idType_Contact,
        // idTypeFieldName: this.idTypeFieldName_Contact,
        formName: this.formName_Contact
      }
    });

    this.formDialogRef.componentInstance.createEditForm();

  }

  //
  // Link to Miantenance
  //

  protected async createWorkRequest(): Promise<any> {
    this.workRequestAttributes = await this.createWorkRequestService.createFromIncident(this.selectedFeature);
    let dialogTitle = 'Create a work request from Incident: ' + this.selectedFeature.attributes.IncidentId;
    this.openEditFeatureDialog_WorkRequest(dialogTitle, this.createWorkRequestService, this.formName_WorkRequest);
  }
  
  // private async XXXcreateWorkRequestX(): Promise<any> {
  //   this._workRequestAttributes = await this.createWorkRequestService.createFromIncident(this.selectedFeature);
  //   console.log('****** createWorkRequest() ******');

  //   this.featureLayerObject_WorkRequest = new WorkRequest();
  //   this.featureLayerObject_WorkRequest.getLovs();
  //   this.featureLayerObject_WorkRequest.loadLayer().then(() => {
  //     this.featureLayerObject_WorkRequest.layerReadyEvent.emit();
  //   });

  //   this.featureLayerObject_WorkRequest.loadedEvent.pipe(tap(data => {
  //     this.openEditFeatureDialog_WorkRequest('');
  //   }),
  //     first()
  //   ).toPromise();
  // }

  // protected openEditFeatureDialog_WorkRequest(mode: string, markDirtyWhenOpened?: boolean) {

  //   let _dialogTitle = 'Create a work request from Incident: ' + this.selectedFeature.attributes.IncidentId;

  //   // Exit fullscreen map mode if in it (otherwise, won't see the dialog)
  //   this.esriMapComponent.exitFullscreen();

  //   // TO DO: **** Set max/min width based on screen size ****
  //   let minWidth = '50%';
  //   let maxWidth = '50%';

  //   let fieldChangeWatchList_WorkRequest = ['Status', 'CompletedDate', 'WorkRequestSubCategoryId'];
  //   let parentLovWatchList_WorkRequest = [{ parentLovKey: 'WorkRequestCategoryId', childLovName: 'LovWorkRequestSubCategory', childLovKey: 'WorkRequestSubCategoryId' }];
  //   //let fieldChangeWatchList_WorkRequest = this.createWorkRequestService.workRequest.fieldChangeWatchList;
  //   //let parentLovWatchList_WorkRequest = this.createWorkRequestService.workRequest.parentLovWatchList;
  //   let defaultFieldValues_WorkRequest = [];
  //   let defaultFieldVisibility_WorkRequest = [];

  //   console.log('this.selectedFeature.geometry', this.selectedFeature, this.selectedFeature.geometry)
  //   const longitude = this.selectedFeature.geometry.longitude;
  //   const latitude = this.selectedFeature.geometry.latitude;
  //   let geometry = new GeometryMultiPoint();
  //   geometry.points = [];
  //   geometry.points.push([longitude, latitude]);

  //   // Get the Incident Parent key fields
  //   defaultFieldValues_WorkRequest = [
  //     {
  //       name: 'WorkRequestDate',
  //       value: this.workRequestAttributes.WorkRequestDate
  //     },
  //     {
  //       name: 'IncidentId',
  //       value: this.workRequestAttributes.IncidentId
  //     },
  //     {
  //       name: 'FairwayNumber',
  //       value: this.workRequestAttributes.FairwayNumber
  //     },
  //     {
  //       name: 'Comments',
  //       value: this.workRequestAttributes.Comments
  //     },
  //     {
  //       name: 'WorkRequestCategoryId',
  //       value: this.workRequestAttributes.WorkRequestCategoryId
  //     },
  //     {
  //       name: 'WorkRequestSubCategoryId',
  //       value: this.workRequestAttributes.WorkRequestSubCategoryId
  //     },
  //     {
  //       name: 'Source',
  //       value: this.workRequestAttributes.Source
  //     },
  //     {
  //       name: 'Status',
  //       value: this.workRequestAttributes.Status
  //     },
  //     {
  //       name: 'Priority',
  //       value: this.workRequestAttributes.Priority
  //     },
  //     {
  //       name: 'ReportedBy',
  //       value: this.workRequestAttributes.ReportedBy
  //     },

  //     {
  //       name: 'Responsible',
  //       value: this.workRequestAttributes.Responsible
  //     },
  //     {
  //       name: 'AssignedTo',
  //       value: this.workRequestAttributes.AssignedTo
  //     },
  //     {
  //       name: 'SLADays',
  //       value: this.workRequestAttributes.SLADays
  //     },
  //     {
  //       name: 'SLADueDate',
  //       value: this.workRequestAttributes.SLADueDate
  //     },



  //   ];

  //   this.formDialogRef = this.dialog.open(EditComponent, {
  //     disableClose: true,
  //     minWidth: minWidth,
  //     maxWidth: maxWidth,
  //     data: {
  //       title: _dialogTitle,
  //       //formFieldConfigs: this.featureLayerObject_WorkRequest.formFieldConfigs,
  //       formFieldConfigs: this.createWorkRequestService.workRequest.formFieldConfigs,
  //       featureLayer: this.createWorkRequestService.workRequest.featureLayer,
  //       feature: null,
  //       geometry: geometry,
  //       domains: this.createWorkRequestService.workRequest.domains,
  //       domains_lov: this.createWorkRequestService.workRequest.domains_lov,
  //       domains_lovSubDomain: this.createWorkRequestService.workRequest.domains_lovSubDomain,
  //       useFormStepper: false,
  //       // currentMode: mode_Contact,
  //       fieldChangeWatchList: fieldChangeWatchList_WorkRequest,
  //       parentLovWatchList: parentLovWatchList_WorkRequest,
  //       defaultFieldValues: defaultFieldValues_WorkRequest,
  //       defaultFieldVisibility: defaultFieldVisibility_WorkRequest,
  //       // markDirtyWhenOpened: markDirtyWhenOpened_Contact,
  //       idType: this.idType_WorkRequest,
  //       idTypeFieldName: this.idTypeFieldName_WorkRequest,
  //       formName: this.formName_WorkRequest
  //     }
  //   });

  //   this.formDialogRef.componentInstance.createEditForm();
  // }


  //
  // Link to Risk
  //

  public riskSelectedEvent(event) {
    console.log('riskSelectedEvent_Search returned is ', event);

    // Store the risk
    this._riskRegister = event.Register;
    this._riskCategory = event.Category;
    this._riskSubCategory = event.SubCategory;
    this._riskGlobalId = event.GlobalId;
  }

  public riskClearedEvent() {
    // Clear stored risk
    this._riskRegister = null;
    this._riskCategory = null;
    this._riskSubCategory = null;
    this._riskGlobalId = null;
  }

  public riskAcceptededEvent() {
    //console.log('riskAcceptededEvent');
    //this.setCurrentMode('editAttribute');

    // let currentModeData: CurrentModeData = {
    //   formName: this.formName,
    //   mode: 'edit'
    // }
    // this.setCurrentMode(currentModeData);

  }

  //
  // Control button and editor behaviour based on current feature edit mode
  //
  //   public setCurrentMode(mode: string) {
  //     console.log(mode);
  //
  //     let geometry;
  //
  //     // Set the current mode
  //     switch(mode) {
  //       case 'form-valid':
  //       case 'form-invalid':
  //       case 'cancelAdd':
  //       case 'cancelEditAttribute':
  //       case 'cancelEditAttachment':
  //         // Do nothing
  //         break;
  //       default:
  //         this._currentMode = mode;
  //         break;
  //     }
  //     // Set configuration / workflow as per current mode
  //     switch (mode)  {
  //       case 'select':
  //         //this._currentMode = mode;
  //         this.addBtnDisabled = false;
  //         this.editBtnDisabled = false;
  //         this.saveBtnDisabled = true;  
  //         //this.attachmentButtonDisabled = true;
  //         this.cancelBtnDisabled = false;
  //         this.attachmentTabDisabled = true;
  //         break;
  //       case 'add':   
  //         this.unSelectFeature(false);
  //         // Reset Workflow Trackers
  //         this.withinBoundary = null;
  //         this.insuranceClaim = null;
  //         this.linkAsset = null;
  //         this.linkRisk = null;

  //         // Enable / Disable buttons
  //         this.addBtnDisabled = true;
  //         this.editBtnDisabled = true;  
  //         this.saveBtnDisabled = true;
  //         //this.attachmentBtnDisabled = true;
  //         this.cancelBtnDisabled = false;

  //         //this.addBtnHidden = true;

  //         // Enable the edit tab and set focus to it
  //         this.workflowTabDisabled = false;
  //         this.selectedTab.setValue(1);

  //         // Workflow
  //         this.locationHidden = false;
  //         this.locationNextHidden = true;

  //         this.assetHidden = true;
  //         this.assetSelectHidden = true;
  //         this.assetNextHidden = true;

  //         this.addressHidden = true;
  //         this.addressNextHidden = true;

  //         this.insuranceHidden = true;
  //         this.insuranceNextHidden = true;

  //         this.addPointHidden = true;
  //         this.addPointNextHidden = true;

  //         this.riskHidden = true;
  //         this.riskSelectHidden = true;
  //         this.riskNextHidden = true;

  //         this.addInfoHidden = true;

  //         break;

  //       case 'add-popup':
  //         //this._currentMode = mode;

  //         // Enable / Disable buttons
  //         this.addBtnDisabled = true;
  //         this.editBtnDisabled = true;  
  //         this.saveBtnDisabled = true;
  //         //this.attachmentBtnDisabled = true;
  //         this.cancelBtnDisabled = false;

  //         // this.addBtnHidden = true;   
  //         // this.locationHidden = false;
  //         // this.addPointHidden = true;
  //         // this.addPointNextHidden = true;
  //         // this.addInfoHidden = true;

  //         geometry = new GeometryPoint(this.esriPopupMenuComponent.longitude, this.esriPopupMenuComponent.latitude);
  //         this.addFeatureGraphic(geometry);

  //         // Check if location is within the premises
  //         this.featuresService.checkWithinLayer(this._courseBoundaryFeatureLayer, geometry).then( (result) => {
  //           //console.log('checkWithinLayer result', result);
  //           this.withinBoundary = result;

  //           if (!this.withinBoundary) {

  //             // Get the address of the location if outside boundary
  //             this.geocodeService.searchLongitude = this.esriPopupMenuComponent.longitude.toString();
  //             this.geocodeService.searchLatitude = this.esriPopupMenuComponent.latitude.toString();

  //             // *** TO DO - include option to use ESRI reverse code or get address from NSW property (get address string and then do a geocode to get address in parts)
  //             // ESRI reverse geocode is NOT accurate enough for The Lakes Golf Club Area

  //             this.geocodeService.reverseGeocode().subscribe(
  //               data => { 
  //                 console.log('reverseGeocode data received is: ', data);
  //                 //console.log(data);
  //               },
  //               error => {
  //                 console.error('There was an error!', error);
  //               }
  //             );

  //             // Clear the fairway number
  //             this._fairwayNumber = null;
  //           }
  //           else {
  //             // Find the fairway / asset
  //             this.featuresService.getAttributeWithinLayer(this._generalHoleAreaFeatureLayer, geometry, 'FairwayNumber').then( (result) => {
  //               //console.log('getAttributeWithinLayer result _fairwayNumber', result);

  //               if (typeof result === 'number') {
  //                 this._fairwayNumber = result;
  //               }
  //               else {
  //                 this._fairwayNumber = null;
  //               }

  //               //console.log('this._fairwayNumber', this._fairwayNumber);             
  //             });
  //           }

  //         });

  //         break;

  //       case 'location-within':
  //         //this._currentMode = mode;
  //         this.withinBoundary = true;

  //         // Enable / Disable buttons
  //         this.addBtnDisabled = true;
  //         this.editBtnDisabled = true;  
  //         this.saveBtnDisabled = true;
  //         //this.attachmentBtnDisabled = true;
  //         this.cancelBtnDisabled = false;
  //         //this.addBtnHidden = true;

  //         // Workflow
  //         this.locationHidden = false;
  //         this.locationNextHidden = false;

  //         this.assetHidden = false;
  //         this.assetSelectHidden = true;
  //         this.assetNextHidden = true;

  //         this.addressHidden = true;
  //         this.addressNextHidden = true;

  //         this.addPointHidden = true;
  //         this.addPointNextHidden = true;

  //         this.insuranceHidden = true;
  //         this.insuranceNextHidden = true;

  //         this.riskHidden = true;
  //         this.riskSelectHidden = true;
  //         this.riskNextHidden = true;

  //         this.addInfoHidden = true;

  //         this._addNewFeatureEnabled = true;

  //         // Enable the edit tab and set focus to it
  //         //this.workflowTabDisabled = false;
  //         //this.editTabDisabled = false;
  //         //this.selectedTab.setValue(2);

  //         break;

  //       case 'link-asset':
  //         this.linkAsset = true;
  //         //this._currentMode = mode;

  //         // Enable / Disable buttons
  //         this.addBtnDisabled = true;
  //         this.editBtnDisabled = true;  
  //         this.saveBtnDisabled = true;
  //         //this.attachmentBtnDisabled = true;
  //         this.cancelBtnDisabled = false;
  //         //this.addBtnHidden = true;

  //         // Workflow
  //         this.locationHidden = false;
  //         this.locationNextHidden = false;

  //         this.assetHidden = false;
  //         this.assetSelectHidden = false;
  //         this.assetNextHidden = true;

  //         this.addressHidden = true;
  //         this.addressNextHidden = true;

  //         this.addPointHidden = true;
  //         this.addPointNextHidden = true;

  //         this.insuranceHidden = true;
  //         this.insuranceNextHidden = true;

  //         this.riskHidden = true;
  //         this.riskSelectHidden = true;
  //         this.riskNextHidden = true;

  //         this.addInfoHidden = true;

  //         break;        

  //       case 'location-outside':
  //         //this._currentMode = mode;
  //         this.withinBoundary = false;

  //         // Enable / Disable buttons
  //         this.addBtnDisabled = true;
  //         this.editBtnDisabled = true;  
  //         this.saveBtnDisabled = true;
  //         //this.attachmentBtnDisabled = true;
  //         this.cancelBtnDisabled = false;
  //         //this.addBtnHidden = true;

  //         // Workflow
  //         this.locationHidden = false;
  //         this.locationNextHidden = false;

  //         this.assetHidden = true;
  //         this.assetSelectHidden = true;
  //         this.assetNextHidden = true;

  //         this.addressHidden = false;
  //         this.addressNextHidden = true;

  //         this.addPointHidden = true;
  //         this.addPointNextHidden = true;

  //         this.insuranceHidden = true;
  //         this.insuranceNextHidden = true;

  //         this.riskHidden = true;
  //         this.riskSelectHidden = true;
  //         this.riskNextHidden = true;

  //         this.addInfoHidden = true;

  //         // Define the geocoder field
  //         //this.esriEditFormComponent.setFieldGeocode('FullAddress');

  //         break;

  //       case 'location':
  //         //this._currentMode = mode;

  //         // Select a location on the map
  //         // Enable / Disable buttons
  //         this.addBtnDisabled = true;
  //         this.editBtnDisabled = true;  
  //         this.saveBtnDisabled = true;
  //         //this.attachmentBtnDisabled = true;
  //         this.cancelBtnDisabled = false;
  //         //this.addBtnHidden = true;

  //         // Workflow
  //         this.locationHidden = false;
  //         this.locationNextHidden = false;

  //         this.assetHidden = false;
  //         //this.assetSelectHidden = true;
  //         if (this.withinBoundary) {
  //           if (this._assetId) {
  //             this.assetSelectHidden = false;
  //           }
  //           else {
  //             this.assetSelectHidden = true;
  //           }
  //           this.assetNextHidden = false;
  //         }
  //         else {
  //           this.assetSelectHidden = true;
  //           this.assetNextHidden = true;
  //         }

  //         this.addressHidden = true;
  //         this.addressNextHidden = true;

  //         this.addPointHidden = false;
  //         this.addPointNextHidden = true;

  //         this.insuranceHidden = true;
  //         this.insuranceNextHidden = true;

  //         this.riskHidden = true;
  //         this.riskSelectHidden = true;
  //         this.riskNextHidden = true;

  //         this.addInfoHidden = true;

  //         this._addNewFeatureEnabled = true;
  //         break;

  //       case 'location-added':
  //         //this._currentMode = mode;

  //         // Workflow
  //         this.locationHidden = false;
  //         this.locationNextHidden = false;

  //         this.assetHidden = true;
  //         this.assetSelectHidden = true;
  //         this.assetNextHidden = true;

  //         this.addressHidden = false;
  //         this.addressNextHidden = true;

  //         this.addPointHidden = true;
  //         this.addPointNextHidden = true;

  //         this.insuranceHidden = true;
  //         this.insuranceNextHidden = true;

  //         this.riskHidden = true;
  //         this.riskSelectHidden = true;
  //         this.riskNextHidden = true;

  //         this.addInfoHidden = true;

  //         // Set form fields from results of workflow
  //         this.esriEditFormComponent.setFieldValue('HasLocation', 'YES');

  //         this.esriEditFormComponent.setFieldValue('OccurredOnPremise', this.withinBoundary);
  //         this.esriEditFormComponent.setFieldValue('FairwayNumber', this._fairwayNumber);

  //         this.esriEditFormComponent.setFieldValue('AssetClass', this._assetClass);
  //         this.esriEditFormComponent.setFieldValue('AssetCategory', this._assetCategory);
  //         this.esriEditFormComponent.setFieldValue('AssetType', this._assetType);
  //         this.esriEditFormComponent.setFieldValue('AssetId', this._assetId);

  //         this.esriEditFormComponent.setFieldValue('RiskRegisterId', this._riskRegister);
  //         this.esriEditFormComponent.setFieldValue('RiskCategoryId', this._riskCategory);
  //         this.esriEditFormComponent.setFieldValue('RiskSubCategoryId', this._riskSubCategory);
  //         this.esriEditFormComponent.setFieldValue('RiskGlobalId', this._riskGlobalId);


  //         // Create new feature - attributes
  //         //this.addBtnHidden = true;
  //         this.locationHidden = true;
  //         this.addPointHidden = true;
  //         this.addInfoHidden = false;
  //         this._addNewFeatureEnabled = false;

  //         // Set form field visibility back to defaults

  //         if (this.withinBoundary === false) {
  //           this.setFormFields_OnPremises('NO');
  //         }
  //         else {
  //           this.setFormFields_OnPremises('YES');
  //         }

  //         this.setFormFields_Insurance('YES');
  //         this.setFormFields_Medical('NO');
  //         this.setFormFields_Legal('NO');

  //         this.esriEditFormComponent.initialiseEditForm().then( () => {

  //           // Always show the first step when form opens
  //           if (this.esriEditFormComponent.stepper) {
  //             this.esriEditFormComponent.stepper.selectedIndex = 0;
  //           }

  //           // Enable the edit tab and set focus to it
  //           this.editTabDisabled = false;
  //           this.selectedTab.setValue(2);

  //           // // Add a reference to the point to the defualt location defined for risks that have no location
  //           // if (mode === 'location-none') {
  //           //   this.esriEditFormComponent.geometry = Incident.NoLocationPoint;
  //           //   this.esriEditFormComponent.setFieldValue('HasLocation', 'NO');
  //           // }
  //           // else {
  //           //   this.esriEditFormComponent.setFieldValue('HasLocation', 'YES');

  //           //   // Define the geocoder field
  //           //   this.esriEditFormComponent.setFieldGeocode('FullAddress');
  //           // }


  //         });

  //         break;

  //       case 'location-geocoded':
  //         break;

  //       case 'insurance': 
  //         // Enable / Disable buttons
  //         this.addBtnDisabled = true;
  //         this.editBtnDisabled = true;  
  //         this.saveBtnDisabled = true;
  //         //this.attachmentBtnDisabled = true;
  //         this.cancelBtnDisabled = false;
  //         //this.addBtnHidden = true;

  //         // Workflow
  //         this.locationHidden = false;
  //         this.locationNextHidden = true;

  //         this.assetHidden = !this.withinBoundary;
  //         if (this.withinBoundary) {
  //           if (this._assetId) {
  //             this.assetSelectHidden = false;
  //           }
  //           else {
  //             this.assetSelectHidden = true;
  //           }
  //         }
  //         else {
  //           this.assetSelectHidden = true;
  //         }
  //         //this.assetSelectHidden = (this._withinBoundary && this._assetId.length > 0);

  //         this.assetNextHidden = !this.withinBoundary;
  //         this.addressHidden = this.withinBoundary;
  //         this.addressNextHidden = this.withinBoundary;

  //         this.addPointHidden = true;
  //         this.addPointNextHidden = true;

  //         this.insuranceHidden = false;
  //         this.insuranceNextHidden = true;

  //         this.riskHidden = true;
  //         this.riskSelectHidden = true;
  //         this.riskNextHidden = true;

  //         this.addInfoHidden = true;
  //         break;

  //       case 'insurance-claim': 
  //       case 'insurance-claim-nil': 

  //         if (mode === 'insurance-claim') {
  //           this.insuranceClaim = true;
  //         }
  //         else {
  //           this.insuranceClaim = false;
  //         }

  //         // Enable / Disable buttons
  //         this.addBtnDisabled = true;
  //         this.editBtnDisabled = true;  
  //         this.saveBtnDisabled = true;
  //         //this.attachmentBtnDisabled = true;
  //         this.cancelBtnDisabled = false;
  //         //this.addBtnHidden = true;

  //         // Workflow
  //         this.locationHidden = false;
  //         this.locationNextHidden = true;

  //         this.assetHidden = !this.withinBoundary;
  //         if (this.withinBoundary) {
  //           if (this._assetId) {
  //             this.assetSelectHidden = false;
  //           }
  //           else {
  //             this.assetSelectHidden = true;
  //           }
  //         }
  //         else {
  //           this.assetSelectHidden = true;
  //         }
  //         //this.assetSelectHidden = (this._withinBoundary && this._assetId.length > 0);

  //         this.assetNextHidden = !this.withinBoundary;
  //         this.addressHidden = this.withinBoundary;
  //         this.addressNextHidden = true; //this._withinBoundary;

  //         this.addPointHidden = true;
  //         this.addPointNextHidden = true;

  //         this.insuranceHidden = false;
  //         this.insuranceNextHidden = false;

  //         this.riskHidden = false;
  //         this.riskSelectHidden = true;
  //         this.riskNextHidden = true;

  //         this.addInfoHidden = true;      
  //         break;

  //       case 'risk':
  //         //this._currentMode = mode;


  //         // Enable / Disable buttons
  //         this.addBtnDisabled = true;
  //         this.editBtnDisabled = true;  
  //         this.saveBtnDisabled = true;
  //         //this.attachmentBtnDisabled = true;
  //         this.cancelBtnDisabled = false;
  //         //this.addBtnHidden = true;

  //         // Workflow
  //         this.locationHidden = false;
  //         this.locationNextHidden = true;

  //         this.assetHidden = !this.withinBoundary;
  //         if (this.withinBoundary) {
  //           if (this._assetId) {
  //             this.assetSelectHidden = false;
  //           }
  //           else {
  //             this.assetSelectHidden = true;
  //           }
  //         }
  //         else {
  //           this.assetSelectHidden = true;
  //         }
  //         //this.assetSelectHidden = (this._withinBoundary && this._assetId.length > 0);

  //         this.assetNextHidden = !this.withinBoundary;
  //         this.addressHidden = this.withinBoundary;
  //         this.addressNextHidden = true; //this._withinBoundary;

  //         this.addPointHidden = true;
  //         this.addPointNextHidden = true;

  //         this.insuranceHidden = true;
  //         this.insuranceNextHidden = false;

  //         this.riskHidden = false;
  //         this.riskSelectHidden = true;
  //         this.riskNextHidden = true;

  //         this.addInfoHidden = true;

  //         break;

  //       case 'link-risk':
  //       case 'link-risk-nil':

  //         // Enable / Disable buttons
  //         this.addBtnDisabled = true;
  //         this.editBtnDisabled = true;  
  //         this.saveBtnDisabled = true;
  //         //this.attachmentBtnDisabled = true;
  //         this.cancelBtnDisabled = false;
  //         //this.addBtnHidden = true;

  //         // Workflow
  //         this.locationHidden = false;
  //         this.locationNextHidden = true;
  //         this.assetHidden = !this.withinBoundary;

  //         if (this.withinBoundary) {
  //           if (this._assetId) {
  //             this.assetSelectHidden = false;
  //           }
  //           else {
  //             this.assetSelectHidden = true;
  //           }
  //         }
  //         else {
  //           this.assetSelectHidden = true;
  //         }

  //         this.assetNextHidden = !this.withinBoundary;
  //         this.addressHidden = this.withinBoundary;
  //         this.addressNextHidden = true; //this._withinBoundary;

  //         this.addPointHidden = true;
  //         this.addPointNextHidden = true;

  //         this.insuranceHidden = false;
  //         this.insuranceNextHidden = false;

  //         this.riskHidden = false;
  //         if (mode === 'link-risk') {






  //           this.linkRisk = true;
  //           this.riskSelectHidden = false;
  //         }
  //         else {
  //           this.linkRisk = false;
  //           this.riskSelectHidden = true;
  //         }
  //         this.riskNextHidden = true;

  //         this.addInfoHidden = true;

  //         break;        

  //       case 'cancelAdd':
  //         this._currentMode = 'start';

  //         this.unSelectFeature(false);

  //         if (this._graphicsLayer) {
  //           this.view.map.remove(this._graphicsLayer);
  //           this._graphicsLayer = null;
  //         }

  //         //this.addBtnHidden = false;
  //         this.locationHidden = true;
  //         this.addPointHidden = true;
  //         this.addInfoHidden = true;   
  //         //this._addNewFeatureEnabled = true;

  //         // Disable the edit tab and set focus to it
  //         this.editTabDisabled = true;
  //         this.selectedTab.setValue(0);

  //         // Enable / Disable buttons
  //         this.addBtnDisabled = false;
  //         this.editBtnDisabled = true;  
  //         this.saveBtnDisabled = true;
  //         //this.attachmentBtnDisabled = true;
  //         this.cancelBtnDisabled = true;
  //         break;

  //       case 'editAttribute':
  //       case 'location-changed':
  //         //this._currentMode = mode;

  //         // Enable the edit tab and set focus to it
  //         this.esriEditFormComponent.result.result = '';
  //         this.esriEditFormComponent.result.message = '';

  //         this.esriEditFormComponent.setFieldGeocode('FullAddress');

  //         this.editTabDisabled = false;
  //         this.selectedTab.setValue(2);  

  //         // Always show the first step when form opens
  //         this.esriEditFormComponent.stepper.selectedIndex = 0;

  //         // Enable / Disable buttons
  //         this.addBtnDisabled = true;
  //         this.editBtnDisabled = true;  
  //         this.saveBtnDisabled = false;  
  //         //this.attachmentBtnDisabled = false; // Managed by gallery loaded event
  //         this.attachmentTabDisabled = false;
  //         this.cancelBtnDisabled = false;
  //         break;

  //       case 'edit-popup':
  //         geometry = new GeometryPoint(this.esriPopupMenuComponent.longitude, this.esriPopupMenuComponent.latitude);
  //         this.moveFeatureGraphic(geometry);
  //         break;

  //       case 'cancelEditAttribute':
  //         this._currentMode = 'start';

  //         this.unSelectFeature(false);

  //         if (this._graphicsLayer) {
  //           this.view.map.remove(this._graphicsLayer);
  //           this._graphicsLayer = null;
  //         }

  //         // Disable the edit tab and set focus to it
  //         this.editTabDisabled = true;
  //         this.selectedTab.setValue(0);

  //         // Enable / Disable buttons
  //         this.addBtnDisabled = false;
  //         this.editBtnDisabled = true;  
  //         this.saveBtnDisabled = true;
  //         //this.attachmentBtnDisabled = true;
  //         this.attachmentTabDisabled = true;
  //         this.cancelBtnDisabled = true;
  //         break;

  //       case 'editAttachment':
  //         //this._currentMode = mode;

  //         // if (this._highlight) {
  //         //   // Enable the edit tab and set focus to it
  //         //   this.attachmentTabDisabled = false;
  //         //   this.selectedTab.setValue(3);
  //         // }

  //         // Enable / Disable buttons
  //         this.addBtnDisabled = true;
  //         this.editBtnDisabled = true; 
  //         this.saveBtnDisabled = true; 
  //         //this.attachmentBtnDisabled = true;
  //         this.cancelBtnDisabled = false;
  //         break;

  //       case 'cancelEditAttachment':
  //         this._currentMode = 'editAttribute';

  //         // Disable the edit tab and set focus to it
  //         this.attachmentTabDisabled = true;
  //         this.selectedTab.setValue(2);

  //         // Enable / Disable buttons
  //         this.addBtnDisabled = true;
  //         this.editBtnDisabled = true;  
  //         this.saveBtnDisabled = false;
  //         //this.attachmentBtnDisabled = false;
  //         this.cancelBtnDisabled = false;
  //         break;

  //       case 'save':
  //         //this.assetEdit.submitFeatureForm(true);  // should check if successful here
  //         // this.selectedRow = null;
  //         // this.unHighlightFeature();
  //         this.selectedTab.setValue(0);

  //         // Enable / Disable buttons
  //         this.addBtnDisabled = false;
  //         this.editBtnDisabled = true;  
  //         this.saveBtnDisabled = false;
  //         //this.attachmentBtnDisabled = true;
  //         this.attachmentTabDisabled = true;
  //         this.cancelBtnDisabled = false;

  //         //console.log('saveBtnEvent Parent submitFeatureForm');
  //         this.esriEditFormComponent.submitFeatureForm().then( (result) => {

  //           //console.log('this.esriEditFormComponent.submitFeatureForm', result);
  //           // check for errors
  //           if (result.result === 'SUCCESS') {
  //             this._lastObjectId = result.objectId;
  //             this.setCurrentMode('form-submitted');
  //           }
  //           else {
  //             console.log('Form submit error', result);
  //           }         
  //         });

  //         break;

  //       case 'form-valid':
  //         this.saveBtnDisabled = false;
  //         break;

  //       case 'form-invalid':
  //         this.saveBtnDisabled = true;
  //         break;     

  //       case 'form-submitted':
  //         //this._currentMode = mode;

  //         this.unSelectFeature(false);

  //         if (this._graphicsLayer) {
  //           this.view.map.remove(this._graphicsLayer);
  //         }

  //         // Enable / Disable buttons
  //         this.addBtnDisabled = false;
  //         this.editBtnDisabled = true;  
  //         this.saveBtnDisabled = true;
  //         //this.attachmentBtnDisabled = true;
  //         this.attachmentTabDisabled = true;
  //         this.cancelBtnDisabled = true;

  //         //this.addBtnHidden = false;
  //         this.locationHidden = true;
  //         this.addPointHidden = true;
  //         this.addInfoHidden = true;

  //         // Update the table with the new results
  //         //Re - query
  //         this.queryFeatures(true, this._lastObjectId);

  //         break;

  //       case 'clear':
  //       case 'start':
  //       default:
  //         //this._currentMode = mode;

  //         this.unSelectFeature(false);

  //         // Enable / Disable buttons
  //         this.addBtnDisabled = false;
  //         this.editBtnDisabled = true;  
  //         this.saveBtnDisabled = true;
  //         //this.attachmentBtnDisabled = true;
  //         this.attachmentTabDisabled = true;
  //         this.cancelBtnDisabled = true;
  //         break;
  //     }
  //   }


}
