import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { LocalStorage } from 'ngx-webstorage';
import { Esri, GeocodeResponse, SuggestResponse, ReverseGeocodeResponse, FeatureLayerProperties, GeometryPoint, SpatialRelationship } from 'src/app/_esri/models/esri';
import { LayerIds } from 'src/app/_esri/models/layer-ids';
import { FeaturesService } from 'src/app/_esri/services/features.service';
import { GeocodeDataService } from 'src/app/_globals/services/geocode-data.service';

@Injectable({
  providedIn: 'root'
})

export class GeocodeService {

  public searchLatitude: string;
  public searchLongitude: string;
  public searchAddress: string; // = '21 Addington Avenue Ryde';
  public magicKey: string;
  public forStorage = 'false';   // Dev
  //public forStorage = 'true';  // Prod

  private _server = Esri.worldGeocodeServerUrl;
  private _findUrl = this._server + "/findAddressCandidates";
  private _suggestUrl = this._server + "/suggest";
  private _reverseUrl = this._server + "/reverseGeocode";

  @LocalStorage()
  private _token: string;

  constructor(
    private _http: HttpClient,
    private _featuresService: FeaturesService,
    private _geocodeDataService: GeocodeDataService
    ) { }

  public suggest(): Observable<any> {

    //console.log('suggesting ', this.searchAddress);

    const headers = {
      'accept': 'application/json'
    };

    const formData = new FormData();

    formData.append('f', 'json');
    formData.append('text', this.searchAddress);
    formData.append('category', 'Address,Postal');
    formData.append('maxSuggestions', '10');
    formData.append('countryCode', 'AUS');

    try {
      return this._http.post<SuggestResponse>(this._suggestUrl, formData, { headers })
      .pipe(
        map( (res) => {
          return res;
        })
      );
    }
    catch (error) {
      console.error('error: ', error);
    }
  }

  //return await this.http.post<any>(this._url, body , {headers: headers }).toPromise();

  // NEED TO CHECK THIS OUT (requires API key) -->  https://point.digital.nsw.gov.au/v2/docs/pages/support.html#/
  // Wouldn't then need to use ESRI credits for storage of addresses from ArcGIS's geocode/ reverse geocode services


  private async _geocode_Async(useMagicKey?: boolean): Promise<any> {
    const headers = {
      'accept': 'application/json'
    };

    const formData = new FormData();

    formData.append('f', 'json');

    if (useMagicKey === true) {
      //console.log('geocoding magicKey', this.magicKey);
      formData.append('magicKey', this.magicKey);
    }
    else {
      //console.log('geocoding searchAddress', this.searchAddress);
      formData.append('singleLine', this.searchAddress);
    }
    
    formData.append('sourceCountry', 'AU');
    formData.append('outFields', 'StAddr,Nbrhd,Region,Postal,Match_addr,Addr_type');
    formData.append('locationType', 'street');
    formData.append('forStorage', this.forStorage);
    formData.append('token', this._token);

    try {
      return await this._http.post<GeocodeResponse>(this._findUrl, formData, { headers }).toPromise();
    }
    catch (error) {
      console.error('error: ', error);
    }
  }

  // ESRI version
  public geocode(useMagicKey?: boolean): Observable<any> {
    //console.log('geocoding FromMagicKey', this.searchAddress);

    const headers = {
      'accept': 'application/json'
    };

    const formData = new FormData();

    formData.append('f', 'json');

    if (useMagicKey === true) {
      //console.log('geocoding magicKey', this.magicKey);
      formData.append('magicKey', this.magicKey);
    }
    else {
      //console.log('geocoding searchAddress', this.searchAddress);
      formData.append('singleLine', this.searchAddress);
    }
    
    formData.append('sourceCountry', 'AU');
    formData.append('outFields', 'StAddr,Nbrhd,Region,Postal,Match_addr,Addr_type');
    formData.append('locationType', 'street');
    formData.append('forStorage', this.forStorage);
    formData.append('token', this._token);
    
    try {
      return this._http.post<GeocodeResponse>(this._findUrl, formData, { headers })
      .pipe(
        map( (res) => {
          return res;
        })
      );
    }
    catch (error) {
      console.error('error: ', error);
    }
  }

  // ESRI version
  public reverseGeocode(): Observable<any> {
    console.log('reverseGeocode ', this.searchLongitude + "," + this.searchLatitude);

    const headers = {
      'accept': 'application/json'
    };

    const formData = new FormData();
    const searchCoordinate = this.searchLongitude + "," + this.searchLatitude;

    formData.append('f', 'json');
    formData.append('location', searchCoordinate);
    formData.append('featureTypes','StreetAddress,StreetName');
    formData.append('locationType', 'street');
    formData.append('forStorage', this.forStorage);
    formData.append('token', this._token);

    try {
      return this._http.post<ReverseGeocodeResponse>(this._reverseUrl, formData, { headers })
      // .pipe(
      //   map( (res) => {
      //     return res;
      //   })
      // );
    }
    catch (error) {
      console.error('error: ', error);
    }
  }

  // This reverse geocode is inefficient, but the ESRI ones is NOT accurate for the properties surrounding The Lakes Golf Course
  // So, this a dodgey versionof a reverse geocoder that will achieve the result, but more inefficient

  // This one is REALLY REALLY SLOW
  public async reverseGeocodeNSW_Async(): Promise<any> {
    //console.log('reverseGeocode NSW Property Layer', this.searchLongitude + "," + this.searchLatitude);

    const geometry: GeometryPoint = new GeometryPoint(+this.searchLongitude, +this.searchLatitude);
    let featureLayer;
    let response;

    let properties: FeatureLayerProperties = new FeatureLayerProperties(LayerIds.gisServer_NSW, LayerIds.folder_NSW_Property, LayerIds.layerId_Urban_Property, 'featureLayerNSWProperty', 'NSWProperty', 'MAP');
    //properties.mapService();

    await this._featuresService.getFeatureLayer(false, properties).then ( (layer) => {
      // Get the "address" field from the NSW property layer
      featureLayer = layer;   
    });

    await this._featuresService.getAttributeWithinLayer(featureLayer, geometry, 'address').then( address => { 
      this.searchAddress = address;
      //console.log('reverseGeocode NSW geocode address received is: ', address);
    });

    await this._geocode_Async(false).then(
      data => { 
        //console.log('reverseGeocode NSW geocode data received is: ', data);
        response = data;
      },
      error => {
        console.error('There was an error! [ reverseGeocodeNSW()]', error);
      }
    );
    return response;
  }

  // This one allows the edit form to open, but the address takes a coupek of seconds to show up in the form
  public async reverseGeocodeNSW(): Promise<any> {
    //console.log('reverseGeocode NSW Property Layer', this.searchLongitude + "," + this.searchLatitude);

    const geometry: GeometryPoint = new GeometryPoint(+this.searchLongitude, +this.searchLatitude);
    let properties: FeatureLayerProperties = new FeatureLayerProperties(LayerIds.gisServer_NSW, LayerIds.folder_NSW_Property, LayerIds.layerId_Urban_Property, 'featureLayerNSWProperty', 'NSWProperty', 'MAP');
    //properties.mapService();

    this._featuresService.getFeatureLayer(false, properties).then ( (featureLayer) => {
      // Get the "address" field from the NSW property layer
      this._featuresService.getAttributeWithinLayer(featureLayer, geometry, 'address').then( address => { 
        this.searchAddress = address;
        //console.log('reverseGeocode NSW geocode address received is: ', address);

        // Need now to do a geocode to get the address returned in its parts
        this.geocode(false).subscribe(
          data => { 
            //console.log('reverseGeocode NSW geocode data received is: ', data);
            this._geocodeDataService.geocodeData.next(data);
          },
          error => {
            console.error('There was an error! [ reverseGeocodeNSW()]', error);
            this._geocodeDataService.geocodeData.next(null);
          }
        );
      });
    });
    return;
  }

  // public geocode_GET(): Observable<GeocodeResponse> {

  //   const forStorage = 'false';   // Dev
  //   //const forStorage = 'true';  // Prod

  //   let params = "?f=json&singleLine=" + this.searchAddress + "&sourceCountry=AU&outFields=StAddr,Nbrhd,Region,Postal,Match_addr,Addr_type" + "&locationType=street" + "&forStorage=" + forStorage + "&token=" + this._token;
  //   //console.log('findUrl + params: ', this._findUrl + params);

  //   try {
  //     return this.http.get<GeocodeResponse>(this._findUrl + params, { 
  //       observe: 'response', 
  //       responseType: 'json'
  //     })
  //     .pipe(
  //       map( (res) => {
  //         return res.body;
  //       })
  //     );
  //   }
  //   catch (error) {
  //     console.error('error: ', error);
  //   }
  // }  

}



/*




*/