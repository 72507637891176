<button mat-icon-button [matMenuTriggerFor]='menu' aria-label='Example icon-button with a menu'>
    <mat-icon class='menu-icon' matTooltip='Main menu'>menu</mat-icon>
</button>

<mat-menu #menu='matMenu'>
    <button mat-menu-item (click)='login()'>
        <mat-icon class='material-icons-sharp toolbar-icon'>login</mat-icon>
        <span>Log in</span>
    </button>
    
    <button mat-menu-item routerLink='/home'>
        <mat-icon class='material-icons-sharp'>home</mat-icon>
        <span>Home</span>
    </button>

    <button mat-menu-item routerLink='/game'>
        <mat-icon class='material-icons-sharp'>tour</mat-icon>
        <span>Course</span>
    </button>

    <button mat-menu-item routerLink='/map-gallery'>
        <mat-icon class='material-icons-sharp'>map</mat-icon>
        <span>Map Gallery</span>
    </button>

    <button mat-menu-item [matMenuTriggerFor]="members">
        <mat-icon class='material-icons-sharp'>people</mat-icon>
        <span>Membership</span>
    </button>

    <button mat-menu-item routerLink='/incident'>
        <mat-icon class='material-icons-sharp'>report_problem</mat-icon>
        <span>Incidents</span>
    </button>

    <button mat-menu-item routerLink='/risk'>
        <mat-icon class='material-icons-sharp'>report_problem</mat-icon>
        <span>Risk</span>
    </button>

    <button mat-menu-item routerLink='/maintenance'>
        <mat-icon class='material-icons-sharp'>build</mat-icon>
        <span>Maintenance</span>
    </button>

    <button mat-menu-item routerLink='/' disabled>
        <mat-icon class='material-icons-sharp'>engineering</mat-icon>
        <span>Projects</span>
    </button>

    <!-- <button mat-menu-item routerLink='/asset-register'>
        <mat-icon class='material-icons-sharp'>list</mat-icon>
        <span>Assets</span>
    </button> -->

    <button mat-menu-item [matMenuTriggerFor]="assets">
        <mat-icon class='material-icons-sharp'>list</mat-icon>
        <span>Assets</span>
    </button>

    <button mat-menu-item routerLink='/audit'>
        <mat-icon class='material-icons-sharp'>fact_check</mat-icon>
        <span>Audit</span>
    </button>

    <button mat-menu-item (click)='logout()'>
        <mat-icon class='material-icons-sharp toolbar-icon'>logout</mat-icon>
        <span>Log out</span>
    </button>
</mat-menu>

<!-- Membership Sub Menu -->
<mat-menu #members="matMenu">
    <button mat-menu-item routerLink='/member'>
        <mat-icon class='material-icons-sharp'>people</mat-icon>
        <span>Members</span>
    </button>

    <button mat-menu-item routerLink='/member-rounds'>
        <mat-icon class='material-icons-sharp'>people</mat-icon>
        <span>Rounds - Gender</span>
    </button>

    <button mat-menu-item routerLink='/member-origins'>
        <mat-icon class='material-icons-sharp'>people</mat-icon>
        <span>Rounds - Origin</span>
    </button>

    <button mat-menu-item routerLink='/member-reciprocal-rounds'>
        <mat-icon class='material-icons-sharp'>people</mat-icon>
        <span>Rounds - Reciprocal</span>
    </button>

    <button mat-menu-item routerLink='/member-waiting'>
        <mat-icon class='material-icons-sharp'>people</mat-icon>
        <span>Waiting List</span>
    </button>

    <button mat-menu-item routerLink='/member-reciprocal'>
        <mat-icon class='material-icons-sharp'>people</mat-icon>
        <span>Reciprocal Clubs</span>
    </button>
</mat-menu>

<!-- Asset Sub Menu -->
<mat-menu #assets="matMenu">
    <button mat-menu-item routerLink='/asset-register'>
        <mat-icon class='material-icons-sharp'>home_work</mat-icon>
        <span>Buildings</span>
    </button>

    <button mat-menu-item routerLink='/asset-cartpath'>
        <mat-icon class='material-icons-sharp'>follow_the_signs</mat-icon>
        <span>Cart Paths</span>
    </button>
</mat-menu>

