import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialModule } from 'src/app/_globals/material-module';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { MenuComponent } from './components/menu/menu.component';
import { HomeRoutingModule } from 'src/app/home/home-routing.module';
import { GameRoutingModule } from 'src/app/game/game-routing.module';
import { AssetRegisterRoutingModule } from 'src/app/asset-register/asset-register-routing.module';
import { MapGalleryRoutingModule } from 'src/app/map-gallery/map-gallery-routing.module';
import { AuditRoutingModule } from '../audit/audit-routing.module';
import { MaintenanceRoutingModule } from '../maintenance/maintenance-routing.module';
import { MemberRoutingModule } from '../member/member-routing.module';
import { RiskRoutingModule } from '../risk/risk-routing.module';
import { IncidentRoutingModule } from '../incident/incident-routing.module';
import { LoginComponent } from './components/login/login.component';
//import { LoginCallbackComponent } from './components/login-callback/login-callback.component';
//import { EsriLoginComponent } from 'src/app/_esri/components/esri-login/esri-login.component';

// used to create fake backend
import { fakeBackendProvider } from 'src/app/_nav/helpers/fake-backend';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtInterceptor } from 'src/app/_nav/helpers/jwt-interceptor';
import { ErrorInterceptor } from 'src/app/_nav/helpers/error-interceptor';
import { HeaderComponent } from './components/header/header.component';
import { WorkflowArrowComponent } from './components/workflow-arrow/workflow-arrow.component';
import { FooterComponent } from './components/footer/footer.component';


@NgModule({
  declarations: [
    MenuComponent, 
    LoginComponent, 
    HeaderComponent, 
    FooterComponent,
    WorkflowArrowComponent,  
    //LoginCallbackComponent
  ],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MaterialModule,  
    FormsModule,
    ReactiveFormsModule,
    AssetRegisterRoutingModule,
    AuditRoutingModule,   
    GameRoutingModule, 
    HomeRoutingModule,
    MaintenanceRoutingModule,
    MapGalleryRoutingModule,
    MemberRoutingModule,
    RiskRoutingModule,
    IncidentRoutingModule
  ],
  providers: [
    //{ provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    //{ provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },

    // provider used to create fake backend
    //fakeBackendProvider
  ],
  exports: [
    MenuComponent,
    LoginComponent,
    HeaderComponent,
    FooterComponent,
    WorkflowArrowComponent
    //LoginCallbackComponent
  ]
})
export class NavModule { }
