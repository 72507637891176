import { Injectable } from '@angular/core';
import { format, toDate } from 'date-fns'
import { SubDomain, Domain } from 'src/app/_esri/models/esri'; // FieldConfig, FormFieldConfig, 
import { Card } from './card';

@Injectable({
  providedIn: 'root'
})

export class CardService {

  public attributes: any[];
  public cardFieldConfigs: any;
  // public domains: Domain[];
  // public domains_lov: Domain[];
  // public domains_lovSubDomain: SubDomain[];
  //public formFieldConfigs: FormFieldConfig[];

  public lengthLimit_title: number = 0;
  public lengthLimit_subTitle: number = 0;
  public lengthLimit_contentRow1: number = 0;
  public lengthLimit_contentRow2: number = 0;
  public lengthLimit_contentRow3: number = 0;

  constructor() {}

  public getCards(): Card[] {
    let cards: Card[] = [];
    //this.createCardFields();

    if (this.attributes) {
      for (let attribute of this.attributes) {

        // Create the card title and lines and truncate if required
        let title = this.getCardLine(this.cardFieldConfigs.Title, attribute, this.lengthLimit_title);
        // if (this.lengthLimit_title > 0) {
        //   title = this.truncateField(title, this.lengthLimit_title);
        // }
  
        let subTitle = this.getCardLine(this.cardFieldConfigs.SubTitle, attribute, this.lengthLimit_subTitle);
        // if (this.lengthLimit_subTitle > 0) {
        //   subTitle = this.truncateField(subTitle, this.lengthLimit_subTitle);
        // }
  
        let contentRow1 = this.getCardLine(this.cardFieldConfigs.ContentRow1, attribute, this.lengthLimit_contentRow1, true);
        // if (this.lengthLimit_contentRow1 > 0) {
        //   contentRow1 = this.truncateField(contentRow1, this.lengthLimit_contentRow1);
        // }
  
        let contentRow2 = this.getCardLine(this.cardFieldConfigs.ContentRow2, attribute, this.lengthLimit_contentRow2, true);
        // if (this.lengthLimit_contentRow2 > 0) {
        //   contentRow2 = this.truncateField(contentRow2, this.lengthLimit_contentRow2);
        // }
  
        let contentRow3 = this.getCardLine(this.cardFieldConfigs.ContentRow3, attribute, this.lengthLimit_contentRow3, true);
        // if (this.lengthLimit_contentRow3 > 0) {
        //   contentRow3 = this.truncateField(contentRow3, this.lengthLimit_contentRow3);
        // }
  
        // Create the card
        let card = new Card(title, subTitle, contentRow1, contentRow2, contentRow3, attribute);
        //card.featureAttribute = featureAttribute;
        //card.objectId = featureAttribute.OBJECTID;
        cards.push(card);
      }
    }



    //console.log('getCards() cards', cards);
    return cards;
  }

  private getCardLine(fieldConfigsPart: any, attribute: any, lengthLimit: number, includeLabels?: boolean): string {
    //console.log('getCardLine() fieldConfigsPart, attribute', fieldConfigsPart, attribute);
    let textPart = '';

    for (let i = 0; i < fieldConfigsPart.length; i++) {
      if (fieldConfigsPart[i].visible || fieldConfigsPart[i].AlwaysVisible) {
        const field = fieldConfigsPart[i];

        if (includeLabels === true) {
          textPart += '<div><span class="sidebar-card-label">' + field.label + '&nbsp;</span>';   // 38 + 13
        }
        else {
          if (i > 0) {
            textPart += '&nbsp;';
          }
        }

        switch (field.editorType) {
          case 'date':
            // Date Field
            textPart += this.epochToDate(attribute[field.name]);
            break;

          case 'text-area':
            // Textarea Field
            textPart += this.toHTML(attribute[field.name]);
            break;   
            
          default:
            if (field.domain) {
              // ArcGIS domain Field
              //textPart += this.getDomainValue(field.domain, attribute[field.name]);

              if (field.domain === 'DomYesNo') {
                if (attribute[field.name].toUpperCase() === 'YES') {
                  textPart += 'Yes';
                }
                else if (attribute[field.name].toUpperCase() === 'NO') {
                  textPart += 'No';
                }
                else {
                  textPart += '';
                }
              }
              else {
                textPart += attribute[field.domain_VirtualFieldName];
              }
            }
            else {
              if (field.domain_lov) {
                if (field.domain_lovParent) {
                  // ArcGIS LOV (with parent) table Field
                  //textPart += this.getDomainLovParentValue(field.domain_lovParent, field.domain_lov, attribute, attribute[field.name]);
                  textPart += attribute[field.domain_VirtualFieldName];
                }
                else {
                  // ArcGIS LOV table Field
                  //textPart += this.getDomainLovValue(field.domain_lov, attribute[field.name]);
                  textPart += attribute[field.domain_VirtualFieldName]
                }
              }
              else {
                // Simple Text Field
                textPart += attribute[field.name];
              }
            }
            break;
        }

        if (includeLabels === true) {
          textPart += '</div>';  // + 6
        }
      }
    }

    if (lengthLimit > 0) {
      // Increase the length limit to account for the HTML markup
      if (includeLabels === true) {  
        lengthLimit = lengthLimit + (fieldConfigsPart.length * (38 + 13 + 6));
      }
      textPart = this.truncateField(textPart, lengthLimit);
    }

    return textPart;    
  }

  //
  // Domain Value Functions
  //

  // private getDomainValue(domainName: string, codedValue: any)  {
  //   //console.log('getDomainValue() domainName:', domainName);
  //   //console.log('getDomainValue() codedValue:', codedValue);

  //   if (codedValue) {
  //     if (codedValue === 'other') {
  //       return 'other';
  //     }
  //     else {
  //       const domain = this.domains.find(domain => domain.name === domainName);
  //       return domain.codedValues.find(code => code.code === codedValue).name;
  //     }
  //   }
  //   else {
  //     return;
  //   }
  // }

  // private getDomainLovValue(domainName: string, codedValue: any)  {
  //   //console.log('getDomainLovValue() domainLov: codedValue:', domainName, codedValue);
  //   //console.log('getDomainLovValue() codedValue:', codedValue);
  //   if (codedValue) {
  //     const domain = this.domains_lov.find(domain => domain.name === domainName);
  //     //console.log('getDomainLovValue() domain:', this.domains_lov, domain);
  //     return domain.codedValues.find(code => code.code === codedValue).name;
  //   }
  //   else {
  //     return;
  //   }
  // }

  // private getDomainLovParentValue(domainLovParent: string, domainName: string, attributes: any, codedValue: any) {

  //   // **************************************************************************
  //   // This assumes naming convention is followed:
  //   // "LovFieldName" for the LOV domain name, eg "LovRiskCategory"
  //   // "FieldNameId" is the field name, eg "RiskCategoryId"
  //   // **************************************************************************

  //   // console.log('getDomainParentLovValue');
  //   // console.log('domainLovParent', domainLovParent);
  //   // console.log('domainName', domainName);
  //   // console.log('attributes', attributes);
  //   // console.log('fieldName', fieldName);
  //   // console.log('codedValue', codedValue);

  //   //console.log('this._domains_lovParent', this.domains_lovParent);

  //   if (codedValue) {
  //     // Remove the leading "Lov" and Append "Id" to create the field name of the parent
  //     const parentFieldName = domainLovParent.substr(3) + "Id";
  //     const parentFieldValue = attributes[parentFieldName];
  //     //console.log('parentFieldName: & parentFieldValue:', parentFieldName, parentFieldValue);

  //     const parentDomain = this.domains_lovSubDomain.find(domain => domain.name === domainName);
  //     //console.log('parentDomain', parentDomain);

  //     const domain = parentDomain.domains.find(domain => domain.code === parentFieldValue).domain;
  //     //console.log('domain', domain);

  //     const value = domain.codedValues.find(code => code.code === codedValue).name;
  //     //console.log('value', value);

  //     if (value) {
  //       return value;
  //     }
  //     else {
  //       return codedValue;
  //     }
  //   }
  //   else {
  //     return;
  //   }
  // }

  //
  // Dates
  //

  private epochToDate(epochDate: number) {
    return format(toDate(epochDate), 'dd-MMM-yyyy');
  }

  //
  // HTML
  //

  private toHTML(input) : any {
    return new DOMParser().parseFromString(input, "text/html").documentElement.textContent;
  }

  //
  // Truncate field length
  //
  private truncateField(fieldText: any, maxLength: number) {

    //console.log('truncateField() fieldText, maxLength', fieldText, maxLength);
    const fld: string = fieldText.toString();
    let result: string = '';

    if (fld.length > maxLength) {
      // Check if last char is a space
      if (fld.charAt(maxLength-1) === ' ') {
        // If the last char is a space, ignore it and start the ... one char earlier
        result = fld.substr(0, maxLength-1) + "...";
      }
      else {
        result = fld.substr(0, maxLength) + "...";
      }
    }
    else {
      result = fld;
    }
    //console.log('truncateField() result', result);
    return result;
  }

}



  // private createCardFields() {
  //   //let All: FieldConfig[] = [];
  //   //let fieldConfigs: any;
  //   let Title: FieldConfig[] = [];
  //   let SubTitle: FieldConfig[] = [];
  //   let ContentRow1: FieldConfig[] = [];
  //   let ContentRow2: FieldConfig[] = [];
  //   let ContentRow3: FieldConfig[] = [];

  //   // Filter out the fields that are to appear in the cards
  //   for (let i=0; i < this.formFieldConfigs.length; i++) {
  //     for (let j=0; j < this.formFieldConfigs[i].fieldConfig.length; j++) {
  //       if (this.formFieldConfigs[i].fieldConfig[j].includeInCard === true) {
  //         if (this.formFieldConfigs[i].fieldConfig[j].includeInCard_Title) {
  //           Title.push(this.formFieldConfigs[i].fieldConfig[j]);
  //         }
  //         else if (this.formFieldConfigs[i].fieldConfig[j].includeInCard_SubTitle) {
  //           SubTitle.push(this.formFieldConfigs[i].fieldConfig[j]);
  //         }
  //         else if (this.formFieldConfigs[i].fieldConfig[j].includeInCard_Content_Row1) {
  //           ContentRow1.push(this.formFieldConfigs[i].fieldConfig[j]);
  //         }
  //         else if (this.formFieldConfigs[i].fieldConfig[j].includeInCard_Content_Row2) {
  //           ContentRow2.push(this.formFieldConfigs[i].fieldConfig[j]);
  //         }
  //         else if (this.formFieldConfigs[i].fieldConfig[j].includeInCard_Content_Row3) {
  //           ContentRow3.push(this.formFieldConfigs[i].fieldConfig[j]);
  //         }
  //       }
  //     }
  //   }

  //   Title.sort(compare);
  //   SubTitle.sort(compare);
  //   ContentRow1.sort(compare);
  //   ContentRow2.sort(compare);
  //   ContentRow3.sort(compare);

  //   this.fieldConfigs = {
  //     Title,
  //     SubTitle,
  //     ContentRow1,
  //     ContentRow2,
  //     ContentRow3
  //   }

  //   //return fieldConfigs;
  //   return;

  //   function compare(a, b) {
  //     const A = a.includeInCard_Index;
  //     const B = b.includeInCard_Index;
      
  //     let comparison = 0;
  //     if (A > B) {
  //         comparison = 1;
  //     } else if (A < B) {
  //         comparison = -1;
  //     }
  //     return comparison;
  //   }
  // }