import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FeaturesService } from 'src/app/_esri/services/features.service';
import { Esri, FeatureLayerProperties, Domain, CodedValue, SubDomain, ChildDomain, ElevationInfo, EsriRenderer_Point, FormFieldConfig, FieldConfig, FieldDateFormat, FieldNumberFormat } from 'src/app/_esri/models/esri';
import { LovDataService } from 'src/app/_globals/services/lov-data.service';


@Injectable({
  providedIn: 'root'
})

export class DomainLovService implements OnDestroy {

  // System
  protected ngUnsubscribe = new Subject<void>();

  public domains: Domain[];
  public domains_lov: Domain[];
  public domains_lovSubDomain: SubDomain[];

  constructor (
    private _lovDataService: LovDataService
  ) 
  {
    // Domains and LOV's
    this._lovDataService.lovData.pipe(takeUntil(this.ngUnsubscribe)).subscribe( (lovData: any) => {
      console.log('DomainLovService received lovData', lovData);
      this.domains = lovData.domains;
      this.domains_lov = lovData.domains_lov;
      this.domains_lovSubDomain = lovData.domains_lovSubDomain;
    });
  }

   public ngOnDestroy(): void {
    // Unsubscribe from events / observables
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  // 
  private async loadLovTable(featureLayerProperties: FeatureLayerProperties): Promise<any> {

    let featuresService: FeaturesService = new FeaturesService();
    let featureLayer;

    await featuresService.loadLayer(false, featureLayerProperties).then(layer => {
      featureLayer = layer;
    });

    return featureLayer;
  }

  // Use this if feature layer already queried, ie features exist
  public async createDomainLov(domainName: string, domainType: string, features: any[], outFields: any[]): Promise<Domain> {
    // "outFields" should be defined in this order: Code, Description
    let domain: Domain = new Domain;
    let codedValues: CodedValue[] = [] ;

    domain.name = domainName;
    domain.type = domainType;

    for (let feature of features) {
      let codedValue: CodedValue = {
        code: feature.attributes[outFields[0]],
        name: feature.attributes[outFields[1]]
      };
      codedValues.push(codedValue);     
    }

    domain.codedValues = codedValues;
    //console.log('domain-lov.service getDomainLov', domain);
    return domain;
  }

  // Use this to query feature layer and then create domains
  public async getDomainLov(featureLayerProperties: FeatureLayerProperties, domainName: string, domainType: string, whereClause: string, outFields: string[]): Promise<Domain> {
    // "outFields" should be defined in this order: Code, Description
    let domain: Domain = new Domain;

    //let codedValues: CodedValue[] = [];
    let layer: any;
    //let feature: any;

    // console.log(domainName);
    // console.log(domainType);
    // console.log(featureLayerProperties);
    // console.log(whereClause);
    // console.log(outFields);

    // console.log(outFields[0]);
    // console.log(outFields[1]);
    
    //domain.name = domainName;
    //domain.type = domainType;

    // Get the LOV Feature Layer
    await this.loadLovTable(featureLayerProperties).then(featureLayer => {
      layer = featureLayer;
    });

    // Define the query
    let queryTblLov = layer.createQuery();
    //console.log('queryTblLov', queryTblLov);

    queryTblLov.where = whereClause;
    queryTblLov.outFields = outFields;
    queryTblLov.returnGeometry = false;

    // Execute the query and get all the data
    await layer.queryFeatures(queryTblLov).then((response) => {
      this.createDomainLov(domainName, domainType, response.features, outFields).then( (response) => {
        domain = response;
      })
      // //console.log(response);
      // for (let feature of response.features) {
      //   let codedValue: CodedValue = {
      //     code: feature.attributes[outFields[0]],
      //     name: feature.attributes[outFields[1]]
      //   };
      //   codedValues.push(codedValue);     
      // }
    })
    .catch( (error) => {
      console.log('getDomainLov Promise error:', error);
      console.log('getDomainLov Promise error: featureLayerProperties, domainName, domainType, whereClause, outFields', featureLayerProperties, domainName, domainType, whereClause, outFields);
    });

    //domain.codedValues = codedValues;
    console.log('domain-lov.service getDomainLov', domain);
    return domain;
  }

  // Use this if feature layer already queried, ie features exist
  public async createSubDomainLov(domainName: string, domainType: string, features: any[], outFields: any[]): Promise<SubDomain> {
    // "outFields" should be defined in this order: ParentCode, ChildCode, Description

    //console.log('getParentDomainLov');
    let subDomain: SubDomain = new SubDomain;
    subDomain.name = domainName;
    subDomain.domains = [];

    let childDomains: ChildDomain[] = []; 

    // Find the unique child domains
    const distinctLevel1 = features.filter(
      (feature, i, arr) => arr.findIndex(x => x.attributes[outFields[0]] === feature.attributes[outFields[0]]) === i
    );

    // Level 1 loop
    distinctLevel1.forEach(parent => {
      //console.log(parent.attributes[outFields[0]]);

      let childDomain = new ChildDomain();    
      childDomain.code = parent.attributes[outFields[0]];
      childDomain.domain = null;

      let domain = new Domain();
      domain.type = domainType;
      domain.name = 'LOV_' + parent.attributes[outFields[0]];
      domain.codedValues = [];

      let codedValues: CodedValue[] = [];

      // Level 2 loop
      features.forEach(feature => {

        if (feature.attributes[outFields[0]] === parent.attributes[outFields[0]]) {

          //console.log(feature.attributes[outFields[1]]);

          let codedValue: CodedValue = {
            code: feature.attributes[outFields[1]],
            name: feature.attributes[outFields[2]]
          };

          codedValues.push(codedValue); 
        }

      }); // End Level 2

      domain.codedValues = codedValues;
      childDomain.domain = domain;

      childDomains.push(childDomain);
    });  // End Level 1

    subDomain.domains = childDomains; 

  
    //console.log(parentDomain);
    return subDomain;
  }

  // Use this to query feature layer and then create sub-domains
  public async getSubDomainLov(featureLayerProperties: FeatureLayerProperties, domainName: string, domainType: string, whereClause: string, outFields: string[]): Promise<SubDomain> {
    // "outFields" should be defined in this order: ParentCode, ChildCode, Description

    //console.log('getParentDomainLov');
    let subDomain: SubDomain = new SubDomain;
    //subDomain.name = domainName;
    //subDomain.domains = [];

    //let childDomains: ChildDomain[] = []; 
    let layer: any;

    // Get the LOV Feature Layer
    await this.loadLovTable(featureLayerProperties).then(featureLayer => {
      layer = featureLayer;
    });
  
    // Define the query
    let queryTblLov = layer.createQuery();
  
    //console.log(queryTblLov);
  
    queryTblLov.where = whereClause;
    queryTblLov.outFields = outFields;
    queryTblLov.returnGeometry = false;

    // Execute the query and get all the data
    await layer.queryFeatures(queryTblLov).then((response) => {
  
      this.createSubDomainLov(domainName, domainType, response.features, outFields).then( (response) => {
        subDomain = response;
      })

      // // Find the unique child domains
      // const distinctLevel1 = response.features.filter(
      //   (feature, i, arr) => arr.findIndex(x => x.attributes[outFields[0]] === feature.attributes[outFields[0]]) === i
      // );

      // // Level 1 loop
      // distinctLevel1.forEach(parent => {
      //   //console.log(parent.attributes[outFields[0]]);

      //   let childDomain = new ChildDomain();    
      //   childDomain.code = parent.attributes[outFields[0]];
      //   childDomain.domain = null;

      //   let domain = new Domain();
      //   domain.type = domainType;
      //   domain.name = 'LOV_' + parent.attributes[outFields[0]];
      //   domain.codedValues = [];

      //   let codedValues: CodedValue[] = [];

      //   // Level 2 loop
      //   response.features.forEach(feature => {

      //     if (feature.attributes[outFields[0]] === parent.attributes[outFields[0]]) {

      //       //console.log(feature.attributes[outFields[1]]);

      //       let codedValue: CodedValue = {
      //         code: feature.attributes[outFields[1]],
      //         name: feature.attributes[outFields[2]]
      //       };

      //       codedValues.push(codedValue); 
      //     }

      //   }); // End Level 2

      //   domain.codedValues = codedValues;
      //   childDomain.domain = domain;

      //   childDomains.push(childDomain);
      // });  // End Level 1

      // subDomain.domains = childDomains; 
    });
  
    //console.log(parentDomain);
    return subDomain;
  }


  public getDomainIndex(domainName: string) { 
    //console.log('getDomainIndex() domainName:', domainName);
    if (domainName) {
      return this.domains.findIndex(domain => domain.name === domainName);
    }
    else {
      return null;
    }
  }

  public getDomainValue(domainName: string, codedValue: any)  {
    //console.log('getDomainLovValue() domainLov: codedValue:', domainName, codedValue, this.domains_lov);
    
    if (codedValue) {
      const domain = this.domains.find(domain => domain.name === domainName);
      //console.log('getDomainLovValue() domain:', this.domains_lov, domain);
      return domain.codedValues.find(code => code.code === codedValue).name;
    }
    else {
      return;
    }
  }

  public getDomainLovValue(domainName: string, codedValue: any)  {
    //console.log('getDomainLovValue() domainLov: codedValue: this.domains_lov:', domainName, codedValue, this.domains_lov);
    if (codedValue) {
      const domain = this.domains_lov.find(domain => domain.name === domainName);
      //console.log('getDomainLovValue() domain:', domain);
      //return domain.codedValues.find(code => code.code === codedValue).name;
      if (domain) {
        return domain.codedValues.find(code => code.code === codedValue).name;
      }
      else {
        return;
      }
    }
    else {
      return;
    }
  }

  public getSubDomainLovValue(domainName: string, parentFieldValue: any, codedValue: any) { //parentDomainName: string
    //console.log('getSubDomainLovValue() domainLov: parentFieldValue: codedValue:', domainName, parentFieldValue, codedValue, this.domains_lov);
    if (codedValue) {
      //const parentDomain = DomainLovService.domains_lovParent.find(domain => domain.parentName === parentDomainName && domain.name === domainName);
      const subDomain = this.domains_lovSubDomain.find(domain => domain.name === domainName);
      //console.log('subDomain', subDomain);

      const childDomain = subDomain.domains.find(domain => domain.code === parentFieldValue).domain;
      //console.log('domain', domain);

      const value = childDomain.codedValues.find(code => code.code === codedValue).name;
      //console.log('value', value);

      if (value) {
        return value;
      }
      else {
        return codedValue;
      }
    }
    else {
      return;
    }
  }

}

