<div class='bottom-sheet'>
    <h1 class='bottom-sheet-title'>Risk Matrix</h1>
    <table class='risk-matrix-table' aria-labelledby="tableLabel">  
        <thead>
            <!-- <tr style="text-align: center;" colspan='6'><th>Consequence</th></tr>  {{matrixHeader[0]}} -->
        </thead>  
        <tbody>  
            <tr>  
                <td scope="col" *ngFor="let column of matrixHeader; let i = index">  
                    <span *ngIf='i > 0'>{{column}}</span>  
                    <!-- {{column}} -->
                </td>  
            </tr>
            <tr *ngFor="let row of matrixData">  
                <td *ngFor="let column of matrixHeader" 
                    [ngClass]="{
                        'risk-low':row[column] === 'Low',
                        'risk-moderate':row[column] === 'Moderate',
                        'risk-high':row[column] === 'High',
                        'risk-extreme':row[column] === 'Extreme'
                    }">  
                    {{row[column]}}
                </td>  
            </tr>  
        </tbody>  
    </table>
</div>
