import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PdfService } from 'src/app/_globals/services/pdf.service';

export interface DialogData {
  title: string,
  gameTeeMarkerType: string,
  gameDriveLength: string,
  gameFairwayNumber: string,
  gameGender: string
}

@Component({
  selector: 'app-game-notes',
  templateUrl: './game-notes.component.html',
  styleUrls: ['./game-notes.component.scss']
})

export class GameNotesComponent implements OnInit {

  private _gameNotesForm = new FormGroup({
    gameTeeMarkerType: new FormControl(''),
    gameDriveLength: new FormControl(''),
    gameFairwayNumber: new FormControl(''),
    gameGender: new FormControl(''),
    gameNotes: new FormControl('')
  });

  @Input()
  set gameNotesForm(gameNotesForm: any) {
    this._gameNotesForm = gameNotesForm;
  }

  get gameNotesForm(): any {
    return this._gameNotesForm;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private pdfService: PdfService
  ) { }

  public generatePdf() {
    //const def = { content: this.data.title + ' - ' + this._gameNotesForm.value.gameNotes};

    // https://pdfmake.github.io/docs/0.1/document-definition-object/styling/

    // const fonts = {
    //   Courier: {
    //     normal: 'Courier',
    //     bold: 'Courier-Bold',
    //     italics: 'Courier-Oblique',
    //     bolditalics: 'Courier-BoldOblique'
    //   },
    //   Helvetica: {
    //     normal: 'Helvetica',
    //     bold: 'Helvetica-Bold',
    //     italics: 'Helvetica-Oblique',
    //     bolditalics: 'Helvetica-BoldOblique'
    //   },
    //   Times: {
    //     normal: 'Times-Roman',
    //     bold: 'Times-Bold',
    //     italics: 'Times-Italic',
    //     bolditalics: 'Times-BoldItalic'
    //   }
    // };

    const def = {
      content: [
        { text: this.data.title, style: 'title' },
        { text: this._gameNotesForm.value.gameNotes, style: 'default' },
      ],
      styles: {
        title: {
          //font: 'Helvetica',
          fontSize: 14,
          bold: true,
          decoration: 'underline'
        },
        default: {
          //font: 'Times',
          fontSize: 12,
          bold: false
        }
      },

    }
    
    this.pdfService.generatePdf(def);
  }

   ngOnInit(): void {
    // Set the game values
    this._gameNotesForm.patchValue({
      gameTeeMarkerType: this.data.gameTeeMarkerType,
      gameDriveLength: this.data.gameDriveLength,
      gameFairwayNumber: this.data.gameFairwayNumber,
      gameGender: this.data.gameGender
    });
   }
}



