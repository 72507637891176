import { Component, OnInit } from '@angular/core';
import { BaseFeatureView } from 'src/app/_esri/models/base-feature-view';

@Component({
  selector: 'app-esri-view-table-elements',
  templateUrl: './esri-view-table-elements.component.html',
  styleUrls: ['./esri-view-table-elements.component.scss']
})
export class EsriViewTableElementsComponent extends BaseFeatureView implements OnInit {

  //constructor() { }

  ngOnInit(): void {
  }

  public limitDisplayLength(fieldText: any, maxLength: number) {

    if (fieldText) {
      const fld: string = fieldText.toString();

      if (fld.length > maxLength) {
        return fld.substr(0, maxLength) + "...";
      }
      else {
        return fld;
      }
    }
    else {
      return;
    }


  }

}
