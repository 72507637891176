<app-header>
    <span title>{{title}}</span>
    <span toolbar-extras>
    </span>
</app-header>

<div *ngIf='!thinking' style="height: 4px;"></div>
<div *ngIf='thinking'><mat-progress-bar mode="query"></mat-progress-bar></div>

<!-- Chips Row -->
<div fxLayout='row' fxLayoutAlign="center center" class='chips-row'>
    <div>
        <mat-chip-list #chipListAll aria-label="">
            <span *ngFor="let chart of dashboardCharts">
                <mat-chip [selectable]="true" [removable]="removableChip" *ngIf="chart.visibleChip" (removed)="removeChip(chart.chartName)">
                    {{chart.label}}
                    <mat-icon matChipRemove *ngIf="removableChip">cancel</mat-icon>
                </mat-chip>
            </span>
        </mat-chip-list>
    </div>
</div>

<!-- Dashboard Row 1 -->
<div fxLayout='row' fxLayoutGap="20px" fxLayoutAlign="space-between center" class='chart-row chart-row-3' >
    <!-- Chart 1 -->
    <div fxFlex="33" class='chart-box' [ngClass]="{'dark-chart-box': darkMode}">
        <div fxLayout='column' fxLayoutAlign="space-between center"> 
            <div fxLayout='row' fxShow fxHide.lt-md fxLayoutAlign="space-between center">
                <div [ngClass]="{'dark': darkMode}">
                    <ngx-charts-advanced-pie-chart 
                        [view]="[0.33 * chartWidth, chartHeight]"
                        [results]="getChartData('GENDER')"
                        [label]="'Number of members'"
                        [gradient]="true" 
                        [customColors]='genderColorScheme'>
                    </ngx-charts-advanced-pie-chart>
                </div>
            </div>
            <div fxLayout='row' fxHide fxShow.lt-md fxLayoutAlign="space-between center">
                <div [ngClass]="{'dark': darkMode}">
                    <ngx-charts-pie-chart
                        [view]="[0.33 * chartWidth, chartHeight]"
                        [results]="getChartData('GENDER')"
                        [labels]="true"
                        [gradient]="true" 
                        [customColors]='genderColorScheme'>
                    </ngx-charts-pie-chart>
                </div>
            </div>
            <div>
                <span class='chart-title' [ngClass]="{'dark-chart-title': darkMode}">All Members - Gender Mix</span>
            </div>
        </div>
    </div>

    <!-- Chart 2 -->
    <div fxFlex="33" class='chart-box' [ngClass]="{'dark-chart-box': darkMode}">
        <div fxLayout='column' fxLayoutAlign="space-between center"> 
            <div fxLayout='row' fxShow fxHide.lt-md fxLayoutAlign="space-between center">
                <div [ngClass]="{'dark': darkMode}">
                    <ngx-charts-bar-vertical-2d 
                        [view]="[0.33 * chartWidth, chartHeight]"
                        [results]="getChartData('MEMBER_YEARS')"
                        [xAxisLabel]="''" 
                        [yAxisLabel]="'Number of members'"
                        [showXAxisLabel]='false'
                        [showYAxisLabel]='true'
                        [xAxis]='true'
                        [yAxis]='true'
                        [trimXAxisTicks]='true'
                        [maxXAxisTickLength]='5'
                        [rotateXAxisTicks]='true'    
                        [tooltipDisabled]='false' 
                        [gradient]='true'
                        [noBarWhenZero]='true'
                        [customColors]='genderColorScheme'
                        [roundEdges]='false'
                        [barPadding]='1'
                        [groupPadding]='4'
                        [showDataLabel]='true'
                        >
                    </ngx-charts-bar-vertical-2d>
                </div>
            </div>
            <div fxLayout='row' fxHide fxShow.lt-md fxLayoutAlign="space-between center">
            </div>
            <div>
                <span class='chart-title' [ngClass]="{'dark-chart-title': darkMode}">Membership Tenure (at end of the financial year)</span>
            </div>
        </div>
    </div>

    <!-- Chart 3 -->
    <div fxFlex="33" class='chart-box' [ngClass]="{'dark-chart-box': darkMode}">
        <div fxLayout='column' fxLayoutAlign="space-between center"> 
            <div fxLayout='row' fxShow fxHide.lt-md fxLayoutAlign="space-between center">
                <div [ngClass]="{'dark': darkMode}">
                    <ngx-charts-bar-vertical-stacked 
                        [view]="[0.33 * chartWidth, chartHeight]"
                        [results]="getChartData('AGE')"
                        [xAxisLabel]="''" 
                        [yAxisLabel]="'Number of members'"
                        [showXAxisLabel]='false'
                        [showYAxisLabel]='true'
                        [xAxis]='true'
                        [yAxis]='true'
                        [trimXAxisTicks]='true'
                        [maxXAxisTickLength]='5'
                        [rotateXAxisTicks]='true'    
                        [tooltipDisabled]='false' 
                        [gradient]='true'
                        [noBarWhenZero]='true'
                        [customColors]='genderColorScheme'
                        [barPadding]='1'
                        [showDataLabel]='true'
                        >
                    </ngx-charts-bar-vertical-stacked>
                </div>
            </div>
            <div fxLayout='row' fxHide fxShow.lt-md fxLayoutAlign="space-between center">
            </div>
            <div>
                <span class='chart-title' [ngClass]="{'dark-chart-title': darkMode}">All Members - Age (at end of the financial year)</span>
            </div>
        </div>
    </div>
</div>

<!-- Dashboard Row 2 -->
<div fxLayout='row' fxLayoutGap="20px" fxLayoutAlign="space-between center" class='chart-row chart-row-1'>
    <!-- Chart 1 -->
    <div fxFlex="100" class='chart-box' [ngClass]="{'dark-chart-box': darkMode}">
        <div fxLayout='column' fxLayoutAlign="space-between center"> 
            <div fxLayout='row' fxShow fxHide.lt-md fxLayoutAlign="space-between center">
                <div [ngClass]="{'dark': darkMode}">
                    <ngx-charts-bar-vertical-stacked 
                        [view]="[chartWidth, chartHeight]"
                        [results]="getChartData('MEMBER_TYPE')"
                        [xAxisLabel]="''" 
                        [yAxisLabel]="'Number of members'"
                        [showXAxisLabel]='false'
                        [showYAxisLabel]='true'
                        [xAxis]='true'
                        [yAxis]='true'
                        [trimXAxisTicks]='true'
                        [maxXAxisTickLength]='15'
                        [rotateXAxisTicks]='true'    
                        [tooltipDisabled]='false' 
                        [gradient]='true'
                        [noBarWhenZero]='true'
                        [customColors]='genderColorScheme'
                        [barPadding]='1'
                        [showDataLabel]='true'
                        >
                    </ngx-charts-bar-vertical-stacked>
                </div>
            </div>
            <div fxLayout='row' fxHide fxShow.lt-md fxLayoutAlign="space-between center">
            </div>
            <div>
                <span class='chart-title' [ngClass]="{'dark-chart-title': darkMode}">Membership Category</span>
            </div>
        </div>
    </div>
</div>

<!-- Dashboard Row 3 -->
<div fxLayout='row' fxLayoutGap="20px" fxLayoutAlign="space-between center" class='chart-row chart-row-3'>
    <div fxFlex="33" class='chart-box' [ngClass]="{'dark-chart-box': darkMode}">
        <div fxLayout='column' fxLayoutAlign="space-between center"> 
            <div fxLayout='row' fxShow fxHide.lt-md fxLayoutAlign="space-between center">
                <div [ngClass]="{'dark': darkMode}">
                    <ngx-charts-pie-chart
                        [view]="[0.33 * chartWidth, chartHeight]"
                        [results]="getChartData('NEW_MEMBERS_GENDER')"
                        [labels]="true"
                        [gradient]="true"
                        [doughnut]="true"
                        [arcWidth]="0.5"
                        [customColors]='genderColorScheme'
                        >
                    </ngx-charts-pie-chart>
                </div>
            </div>
            <div fxLayout='row' fxHide fxShow.lt-md fxLayoutAlign="space-between center">
            </div>
            <div>
                <span class='chart-title' [ngClass]="{'dark-chart-title': darkMode}">New Members (this finacial year) - Gender Mix</span>
            </div>
        </div>
    </div>

    <!-- Chart 2 -->
    <div fxFlex="33" class='chart-box' [ngClass]="{'dark-chart-box': darkMode}">
        <div fxLayout='column' fxLayoutAlign="space-between center"> 
            <div fxLayout='row' fxShow fxHide.lt-md fxLayoutAlign="space-between center">
                <div [ngClass]="{'dark': darkMode}">
                    <ngx-charts-bar-vertical-stacked 
                        [view]="[chartWidth_3, chartHeight]"
                        [results]="getChartData('NEW_MEMBERS_MEMBER_TYPE')"
                        [xAxisLabel]="''" 
                        [yAxisLabel]="'Number of members'"
                        [showXAxisLabel]='false'
                        [showYAxisLabel]='true'
                        [xAxis]='true'
                        [yAxis]='true'
                        [trimXAxisTicks]='true'
                        [maxXAxisTickLength]='15'
                        [rotateXAxisTicks]='true'    
                        [tooltipDisabled]='false' 
                        [gradient]='true'
                        [noBarWhenZero]='true'
                        [customColors]='genderColorScheme'
                        [barPadding]='1'
                        [showDataLabel]='true'
                        >
                    </ngx-charts-bar-vertical-stacked>
                </div>
            </div>
            <div fxLayout='row' fxHide fxShow.lt-md fxLayoutAlign="space-between center">
            </div>
            <div>
                <span class='chart-title' [ngClass]="{'dark-chart-title': darkMode}">New Members (this finacial year) - Membership Category</span>
            </div>
        </div>
    </div>

    <!-- Chart 3 -->
    <div fxFlex="33" class='chart-box' [ngClass]="{'dark-chart-box': darkMode}">
        <div fxLayout='column' fxLayoutAlign="space-between center"> 
            <div fxLayout='row' fxShow fxHide.lt-md fxLayoutAlign="space-between center">
                <div [ngClass]="{'dark': darkMode}">
                    <ngx-charts-bar-vertical-stacked 
                        [view]="[chartWidth_3, chartHeight]"
                        [results]="getChartData('NEW_MEMBERS_AGE')"
                        [xAxisLabel]="''" 
                        [yAxisLabel]="'Number of members'"
                        [showXAxisLabel]='false'
                        [showYAxisLabel]='true'
                        [xAxis]='true'
                        [yAxis]='true'
                        [trimXAxisTicks]='true'
                        [maxXAxisTickLength]='15'
                        [rotateXAxisTicks]='true'    
                        [tooltipDisabled]='false' 
                        [gradient]='true'
                        [noBarWhenZero]='true'
                        [customColors]='genderColorScheme'
                        [barPadding]='1'
                        [showDataLabel]='true'
                        >
                    </ngx-charts-bar-vertical-stacked>
                </div>
            </div>
            <div fxLayout='row' fxHide fxShow.lt-md fxLayoutAlign="space-between center">
            </div>
            <div>
                <span class='chart-title' [ngClass]="{'dark-chart-title': darkMode}">New Members - Age (at end of the financial year)</span>
            </div>
        </div>
    </div>
</div>

<!-- Dashboard Row 4 -->
<div fxLayout='row' fxLayoutGap="20px" fxLayoutAlign="space-between center" class='chart-row chart-row-3'>
    <!-- Chart 1 -->
    <div fxFlex="33" class='chart-box' [ngClass]="{'dark-chart-box': darkMode}">
        <div fxLayout='column' fxLayoutAlign="space-between center"> 
            <div fxLayout='row' fxShow fxHide.lt-md fxLayoutAlign="space-between center">
                <div [ngClass]="{'dark': darkMode}">
                    <ngx-charts-area-chart
                        [view]="[chartWidth_3, chartHeight]"
                        [results]="getChartData('NEW_MEMBERS_FEMALE_AGE_10_YEAR')"
                        [xAxisLabel]="''" 
                        [yAxisLabel]="'Number of members'"
                        [showXAxisLabel]='false'
                        [showYAxisLabel]='true'
                        [xAxis]='true'
                        [yAxis]='true'
                        [scheme]="pinkColorScheme"
                        [gradient]='true'
                        >
                    </ngx-charts-area-chart>
                </div>
                <!-- <ngx-charts-bar-vertical 
                    [view]="[0.33 * chartWidth, chartHeight]"
                    [results]="getChartData('NEW_MEMBERS_FEMALE_AGE_10_YEAR')"
                    [xAxisLabel]="''" 
                    [yAxisLabel]="'Number of members'"
                    [showXAxisLabel]='false'
                    [showYAxisLabel]='true'
                    [xAxis]='true'
                    [yAxis]='true'
                    [trimXAxisTicks]='true'
                    [maxXAxisTickLength]='5'
                    [rotateXAxisTicks]='true'    
                    [tooltipDisabled]='false' 
                    [gradient]='true'
                    [noBarWhenZero]='true'
                    [scheme]='pinkColorScheme'
                    [barPadding]='1'
                    [showDataLabel]='true'
                    >
                </ngx-charts-bar-vertical> -->
            </div>
            <div fxLayout='row' fxHide fxShow.lt-md fxLayoutAlign="space-between center">
            </div>
            <div>
                <span class='chart-title' [ngClass]="{'dark-chart-title': darkMode}">10 Year History - New Female Members</span>
            </div>
        </div>
    </div>

    <!-- Chart 2 -->
    <div fxFlex="33" class='chart-box' [ngClass]="{'dark-chart-box': darkMode}">
        <div fxLayout='column' fxLayoutAlign="space-between center"> 
            <div fxLayout='row' fxShow fxHide.lt-md fxLayoutAlign="space-between center">
                <div [ngClass]="{'dark': darkMode}">
                    <ngx-charts-area-chart
                        [view]="[chartWidth_3, chartHeight]"
                        [results]="getChartData('AGE_10_YEAR_AVG')"
                        [xAxisLabel]="''" 
                        [yAxisLabel]="'Average Age'"
                        [showXAxisLabel]='false'
                        [showYAxisLabel]='true'
                        [xAxis]='true'
                        [yAxis]='true'
                        [scheme]="customColorScheme"
                        [gradient]='true'
                        [yScaleMin]='30'
                        [yScaleMax]='65'
                        >
                    </ngx-charts-area-chart>
                </div>
            </div>
            <div fxLayout='row' fxHide fxShow.lt-md fxLayoutAlign="space-between center">
            </div>
            <div>
                <span class='chart-title' [ngClass]="{'dark-chart-title': darkMode}">10 Year History - Existing Members Average Age</span>
            </div>
        </div>
    </div>

    <!-- Chart 3 -->
    <div fxFlex="33" class='chart-box' [ngClass]="{'dark-chart-box': darkMode}">
        <div fxLayout='column' fxLayoutAlign="space-between center"> 
            <div fxLayout='row' fxShow fxHide.lt-md fxLayoutAlign="space-between center">
                <div [ngClass]="{'dark': darkMode}">
                    <ngx-charts-area-chart
                        [view]="[chartWidth_3, chartHeight]"
                        [results]="getChartData('NEW_MEMBERS_AGE_10_YEAR_AVG')"
                        [xAxisLabel]="''" 
                        [yAxisLabel]="'Average Age'"
                        [showXAxisLabel]='false'
                        [showYAxisLabel]='true'
                        [xAxis]='true'
                        [yAxis]='true'
                        [scheme]="customColorScheme"
                        [gradient]='true'
                        [yScaleMin]='30'
                        [yScaleMax]='55'
                        >
                    </ngx-charts-area-chart>
                </div>
            </div>
            <div fxLayout='row' fxHide fxShow.lt-md fxLayoutAlign="space-between center">
            </div>
            <div>
                <span class='chart-title' [ngClass]="{'dark-chart-title': darkMode}">10 Year History - New Members Average Age</span>
            </div>
        </div>
    </div>
</div>




<!-- ---------------------------------------------------------------------------------------------------------------------- -->




<!-- Dashboard Row 3 -->
<!-- <div fxLayout='row' fxLayoutGap="20px" fxLayoutAlign="space-between center" class='chart-row chart-row-3'>     
    <div fxFlex="100" class='chart-box' [ngClass]="{'dark-chart-box': darkMode}">
        <div fxLayout='column' fxLayoutAlign="space-between center">
            <div [ngClass]="{'dark': darkMode}">
                <ngx-charts-bar-vertical 
                    [view]="[chartWidth, chartHeight]"
                    [results]="getChartData('MEMBER_YEARS')"
                    [xAxisLabel]="''" 
                    [yAxisLabel]="'Count'"
                    [showXAxisLabel]='true'
                    [showYAxisLabel]='false'
                    [xAxis]='true'
                    [yAxis]='true'
                    [trimXAxisTicks]='true'
                    [maxXAxisTickLength]='5'
                    [rotateXAxisTicks]='true'    
                    [tooltipDisabled]='false' 
                    [gradient]='true'
                    [roundEdges]='false'
                    [noBarWhenZero]='false'
                    >
                </ngx-charts-bar-vertical>
            </div>
            <div>
                <span class='chart-title' [ngClass]="{'dark-chart-title': darkMode}">Membership Years</span>
            </div>
        </div>
    </div>
</div> -->

<!-- Map Row -->
<div fxLayout='row' class='map-row'>
    <app-esri-map fxLayout='row' style='width: 100%;' 
        [zoom]='zoom'
        [center]='centre'
        [showFullscreenWidget]='false' 
        [showLegendWidget]='false'
        [basemapName]='member_basemapName'
        (viewCreatedEvent)='viewCreatedEvent($event)'>
    </app-esri-map>
</div>

<!-- Footer -->
<app-footer></app-footer>