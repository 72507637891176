import { Injectable } from '@angular/core';
import { FeaturesService } from 'src/app/_esri/services/features.service';
import { Course } from 'src/app/_course/models/course';
//import { FeatureLayerProperties, ElevationInfo, EsriRenderer_Polygon } from 'src/app/_esri/models/esri';
//import { LayerIds } from 'src/app/_esri/models/layer-ids';
//import { CourseService } from 'src/app/_course/services/course.service';

import { Game } from '../models/game';

@Injectable({
  providedIn: 'root'
})
export class GamePlayService {

  constructor(
    private featuresService: FeaturesService,
    //private courseService: CourseService
  ) { }

  public async loadGamePlayLayer(is3D: boolean): Promise<any> {
    let featureLayers = [];

    // The course layers
    let featureLayerProperties = Game.createLayerPropertiesList(is3D);

    await this.featuresService.loadLayers(is3D, featureLayerProperties).then(layers => {
      console.log('load game play Layers');
      featureLayers = layers;
    });

    await this.featuresService.loadLayer(is3D, Course.TeeMarker).then(layer => {
      console.log('load TeeMarker Layer');
      featureLayers.push(layer);
    });

    return featureLayers;
  }
}
