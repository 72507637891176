import { Component, OnInit, OnDestroy, AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, ElementRef } from '@angular/core';
import { BaseFeaturePage } from 'src/app/_esri/models/base-feature-page';
import { MatDialog } from '@angular/material/dialog';
import { getTime, toDate, format, isBefore, parse, addYears } from 'date-fns';
import { FeaturesService } from 'src/app/_esri/services/features.service';
import { Esri, EsriGraphic, ApplyEditsResultMessage, GeometryPoint, DashboardChart } from 'src/app/_esri/models/esri';
import { CurrentModeService } from 'src/app/_globals/services/current-mode.service';
import { GeocodeDataService } from 'src/app/_globals/services/geocode-data.service';
import { FormChangeService } from 'src/app/_globals/services/form-change.service';
import { FormFieldChangeService } from 'src/app/_globals/services/form-field-change.service';
import { FormFieldUpdateService } from 'src/app/_globals/services/form-field-update.service';
import { MemberAppliction } from 'src/app/member/models/member';
import { CsvReadService } from 'src/app/_globals/services/csv-read.service';
import { Global, GroupBy, GroupArray, GroupByRange } from 'src/app/_globals/models/global';

@Component({
  selector: 'app-member-waiting-list',
  templateUrl: './member-waiting-list.component.html',
  styleUrls: [
    './member-waiting-list.component.scss',
    '../../../_styles/base-feature-page.scss'
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class MemberWaitingListComponent extends BaseFeaturePage implements OnInit, OnDestroy, AfterViewInit {

  title = 'Waiting List';
  //subTitle = '';
  showTimeSliderWidget = false;
  //dateFilterAttributeName = '';

  showDataCards = false;
  showDataTable = false;
  showBaseLayers = false;
  showImageGallery = false;
  darkMode = false;

  dashboardCharts: DashboardChart[] = [
    { chartName: 'MEMBERSHIP_TYPE' },
    { chartName: 'GENDER' },
    { chartName: 'WAITING_TIME' },  
  ];

  dashboardCards: DashboardChart[] = [
    { chartName: 'CARD-TOTAL', chartType: 'info-box', label: 'Total', icon: 'functions', colour: 'night-magenta-1', description: 'Total number of applicants on a waiting list', summaryData: [''] },
    { chartName: 'CARD-3DAY', chartType: 'info-box', label: '3 Day', icon: 'sports_golf', colour: 'night-blue-1', description: 'Applicants on waiting list for 3 day membership', summaryData: [''] },
    { chartName: 'CARD-5DAY', chartType: 'info-box', label: '5 Day', icon: 'sports_golf', colour: 'night-blue-1', description: 'Applicants on waiting list for 5 day membership', summaryData: [''] },
    { chartName: 'CARD-7DAY', chartType: 'info-box', label: '7 Day', icon: 'sports_golf', colour: 'night-blue-1', description: 'Applicants on waiting list for 7 day membership', summaryData: [''] },
    { chartName: 'CARD-CORPORATE', chartType: 'info-box', label: 'Corporate', icon: 'work', colour: 'night-blue-1', description: 'Applicants on waiting list for corporate membership', summaryData: [''] },
    { chartName: 'CARD-MALE', chartType: 'info-box', label: 'Men', icon: 'person', colour: 'night-blue-2', description: 'Number of male applicants on a waiting list', summaryData: [''] },
    { chartName: 'CARD-FEMALE', chartType: 'info-box', label: 'Ladies', icon: 'face', colour: 'night-blue-2', description: 'Number of female applicants on a waiting list', summaryData: [''] }
  ]

  private _csvDataArray: any = [];   // used by CSV load 
  //private _dataArray_filter: any = [];

  public alternate: boolean = true;
  //public  toggle: boolean = true;
  public color: boolean = true;
  public dotSize: number = 30;
  public dotSizeMin: number = 15;
  public dotSizeMax: number = 60;
  public expandEnabled: boolean = true;
  public contentAnimation: boolean = true;
  public dotAnimation: boolean = true;
  public side = 'left';

  private _timelineDataResponse: any = {};
  public timelineData: any = [];

  constructor(
    protected ref: ChangeDetectorRef,
    protected dialog: MatDialog,
    protected currentModeService: CurrentModeService,
    protected geocodeDataService: GeocodeDataService,
    protected formChangeService: FormChangeService,
    protected formFieldChangeService: FormFieldChangeService,
    protected formFieldUpdateService: FormFieldUpdateService,
    protected elementRef: ElementRef,
    //
    private csvReadService: CsvReadService,
  ) {
    super(
      ref,
      dialog,
      currentModeService,
      geocodeDataService,
      formChangeService,
      formFieldChangeService,
      formFieldUpdateService,
      elementRef
    );
  }

  ngOnInit(): void {
    this.thinking = true;
    // Get layers, features, lovs, domains, etc
    this.featureLayerObject = new MemberAppliction();
    // Load the Feature Layer - There is no view, so need to call this here
    this.featureLayerObject.getFeatureLayerConfig();
    super.ngOnInit();
  }

  //
  // Get attributes from feature set and assign to class "featureAttributes", (view table, and view cards )- calc virtual fields
  //
  protected refreshDisplay() {
    this.featureLayerObject.updateVirtualFields();
    //this._dataArray_filter = this.featureLayerObject.attributes;
    super.refreshDisplay();

    //console.log('Attempting to load csv data');
    //this.createFeatures(this.featureLayerObject.featureLayer);
  }

  //
  // Build data for charts
  //

  protected createCharts() {
    super.createCharts();
    const dataArray = this.featureLayerObject.attributes;

    let total = dataArray.length;
    // Info-box Cards
    this.dashboardCards.find(x => x.chartName == 'CARD-TOTAL').summaryData = [total];
    this.dashboardCards.find(x => x.chartName == 'CARD-3DAY').summaryData = [this.createCardDataSet_Count(dataArray.filter(x => x.MembershipTypeId === '3DAY'))];
    this.dashboardCards.find(x => x.chartName == 'CARD-5DAY').summaryData = [this.createCardDataSet_Count(dataArray.filter(x => x.MembershipTypeId === '5DAY'))];
    this.dashboardCards.find(x => x.chartName == 'CARD-7DAY').summaryData = [this.createCardDataSet_Count(dataArray.filter(x => x.MembershipTypeId === '7DAY'))];
    this.dashboardCards.find(x => x.chartName == 'CARD-CORPORATE').summaryData = [this.createCardDataSet_Count(dataArray.filter(x => x.MembershipTypeId === 'CORPORATE'))];
    this.dashboardCards.find(x => x.chartName == 'CARD-MALE').summaryData = [this.createCardDataSet_Count(dataArray.filter(x => x.Gender === 'MALE'))];
    this.dashboardCards.find(x => x.chartName == 'CARD-FEMALE').summaryData = [this.createCardDataSet_Count(dataArray.filter(x => x.Gender === 'FEMALE'))];

    const monthsWaitingRange: GroupByRange[] = [
      { From: 0, To: 6, Label: '< 0.5 yr' },
      { From: 7, To: 12, Label: '0.5 - 1 yr' },
      { From: 13, To: 18, Label: '1 - 1.5 yrs' },
      { From: 19, To: 24, Label: '1.5 - 2 yrs' },
      { From: 25, To: 30, Label: '2 - 2.5 yrs' },
      { From: 31, To: 36, Label: '2.5 - 3 yrs' },
      { From: 37, To: 42, Label: '3 - 3.5 yrs' },
      { From: 43, To: 48, Label: '3.5 - 4 yrs' },
      { From: 49, To: 54, Label: '4 - 4.5 yrs' },
      { From: 55, To: 60, Label: '4.5 - 5 yrs' },
      { From: 61, To: 1000, Label: '> 5 yrs' }
    ];

    this.dashboardCharts.find(x => x.chartName == 'MEMBERSHIP_TYPE').summaryData = this.createChartDataSet('LovMembershipType', 'MembershipTypeId', false);
    this.dashboardCharts.find(x => x.chartName == 'GENDER').summaryData = this.createChartDataSet('DomGender', 'Gender', true);
    this.dashboardCharts.find(x => x.chartName == 'WAITING_TIME').summaryData = this.createChartDataSet_Count_GroupedRange(dataArray, 'MonthsWaiting', monthsWaitingRange, false, 'NONE');
    //console.log('createCharts() this.dashboardCharts', this.dashboardCharts);

    // Build the timeline
    this._timelineDataResponse = this.createTimelineDataSet(dataArray, 'PeriodLabel');
    this.timelineData = this._timelineDataResponse.summaryData;
  }

  public setDotSize(count: number) {

    let size;

    if (this._timelineDataResponse.min === this._timelineDataResponse.max) {
      size = this.dotSize;
    }
    else {
      let ratio: number = count / (this._timelineDataResponse.max);
      size = (this.dotSizeMin + (ratio * (this.dotSizeMax - this.dotSizeMin))).toFixed(0);
      // console.log(count, ratio);
      // console.log('size', size);
    }


    return size;
  }

  protected createTimelineDataSet(dataArray: any, groupField: string) {
    let valuesToGroupBy = Array.from(new Set(dataArray.map((item: any) => item[groupField]))).sort();
    //console.log('createTimelineDataSet() valuesToGroupBy', valuesToGroupBy);

    let summaryData = [];
    let timelineData = {};
    let content = [];
    let min = 0;
    let max = 0;
    let idx = 0;
    let year;
    let monthLabel;
    let monthsWaiting;

    valuesToGroupBy.forEach(groupValue => {
      //console.log('createTimelineDataSet() groupValue', groupValue);

      let group = dataArray.filter(x => x[groupField] === groupValue);
      //console.log('createTimelineDataSet() group', group);

      if (idx === 0) {
        min = group.length;
        max = group.length;
      }
      else {
        (group.length < min) ? min = group.length : min = max;
        (group.length > max) ? max = group.length : max = max;
      }

      content = [];

      group.forEach(element => {
        let item = {
          type: element.MembershipType,
          category: element.MembershipCategory,
          name: element.FullName,
          received: format(element.DateReceived, 'dd/MM/yyyy'),
          gender: element.Gender,
          status: element.ApplicationStatus,
          waiting: element.DaysWaiting,
          id: element.ApplicationId
        }
        content.push(item);

        year = element.Year;
        monthLabel = element.MonthLabel;
        monthsWaiting = element.MonthsWaiting;
      });

      //let header = 'Applications received in ' + monthLabel + " " + year;
      let header = group.length + ((group.length === 1) ? ' application' : ' applications') + ' received in ' + monthLabel + " " + year;
      
      let side = groupValue; // + ' : ';
      //let side = ((group.length === 1) ? group.length + ' applicant' : group.length + ' applicants') + ' in the waiting list';
      //let side = monthsWaiting + ( monthsWaiting === 1 ? ' month' : ' months') + ' on waiting list'

      let data = {
        header,
        content,
        side,
        count: group.length
      }

      idx++;
      summaryData.push(data);
    });

    timelineData = {
      summaryData,
      min,
      max
    }

    //console.log('createTimelineDataSet() timelineData', timelineData);
    return timelineData;
  }

  //
  // CSV Data Load
  //

  protected async getData(): Promise<any> {
    let members: any[] = [];
    let fileName: string = 'assets/data/WaitingList.csv';

    await this.csvReadService.readCsvFile(fileName, true).then((parsedData) => {
      members = parsedData;
    });

    return members;
  }

  // This fn creates features from a CSV
  private async XXXcreateFeatures(featureLayer: any) {

    await this.getData().then((data) => {
      this._csvDataArray = data;
    });
    //console.log('this._memberDataArray', this._memberDataArray);

    let result: ApplyEditsResultMessage = new ApplyEditsResultMessage();
    let featuresService: FeaturesService = new FeaturesService();
    let params;
    let resultMessage: string = '';
    //let geometry: GeometryPoint;
    let newFeatures: any[] = [];

    for (let element of this._csvDataArray.data) {
      const attributes = {
        'ApplicationId': element['ApplicantId'],
        'DateReceived': getTime(parse(element['DateReceived'], 'dd/MM/yyyy', new Date())),
        'Gender': element['Gender'],
        'FullName': element['FullName'],
        'DateInterviewed': getTime(parse(element['DateInterviewed'], 'dd/MM/yyyy', new Date())),
        'DateOfBirth': getTime(parse(element['DOB'], 'dd/MM/yyyy', new Date())),
        'KnownToMember': element['KnownToMember'],
        'Comments': element['Comments'],
        'MembershipTypeId': element['MembershipTypeId'],
        'MembershipCategoryId': element['MembershipCategoryId'],
        'StatusId': element['ApplicationStatusId'],
      }
      console.log('this.createFeatures attributes', attributes);

      // Create a new feature
      const newFeature: EsriGraphic = {
        attributes
      };
      //console.log('this.createFeatures newFeature', newFeature);

      newFeatures.push(newFeature);
    }

    // Setup the applyEdits parameter with adds
    const adds = {
      addFeatures: newFeatures
    };
    params = adds;

    console.log('createFeatures() params', params);

    // Send the adds to the GIS server (ESRI feature service)
    await featuresService.applyEditsFeatureLayer(featureLayer, params).then((editResult) => {
      result = editResult;
      console.log('createFeatures() applyEditsFeatureLayer result', result);
      //result.recordId = recordId;
    });
  }

  //
  // Timeline
  //

  public onHeaderClick(event) {

    console.log('onHeaderClick', event);


    if (!this.expandEnabled) {
      event.stopPropagation();
    }
    //mgl-timeline-entry-header-active
  }

  public onDotClick(event) {
    if (!this.expandEnabled) {
      event.stopPropagation();
    }
  }

  public onExpandEntry(expanded, index) {
    //console.log(`Expand status of entry #${index} changed to ${expanded}`)

    // if (expanded === true) {

    // }
  }
}
