<app-header>
    <span title>{{title}}</span>
    <span toolbar-extras>
        <!-- <button fxHide fxShow.gt-xs mat-button [matMenuTriggerFor]="selectedControlStatusMenu"><mat-icon>list_alt</mat-icon>&nbsp;Select control status...</button>
        <button fxShow fxHide.gt-xs mat-icon-button [matMenuTriggerFor]="selectedControlStatusMenu" aria-label="Example icon-button with a menu">
            <mat-icon>list_alt</mat-icon>
        </button>
        <mat-menu #selectedControlStatusMenu="matMenu">
            <button mat-menu-item (click)='controlStatusSelectedEvent("REVIEW")'>Under Review</button>
            <button mat-menu-item (click)='controlStatusSelectedEvent("PROPOSED")'>Proposed</button>
            <button mat-menu-item (click)='controlStatusSelectedEvent("IMPLEMENTED")'>Implemented</button>
            <button mat-menu-item (click)='controlStatusSelectedEvent("REJECTED")'>Rejected</button>
            <button mat-menu-item (click)='controlStatusSelectedEvent("All")'>All</button>
        </mat-menu>

        <button fxHide fxShow.gt-xs mat-button [matMenuTriggerFor]="selectedResidualMenu"><mat-icon>list_alt</mat-icon>&nbsp;Select residual risk...</button>
        <button fxShow fxHide.gt-xs mat-icon-button [matMenuTriggerFor]="selectedResidualMenu" aria-label="Example icon-button with a menu">
            <mat-icon>list_alt</mat-icon>
        </button>
        <mat-menu #selectedResidualMenu="matMenu">
            <button mat-menu-item (click)='residualRiskSelectedEvent("Extreme")'>Extreme</button>
            <button mat-menu-item (click)='residualRiskSelectedEvent("High")'>High</button>
            <button mat-menu-item (click)='residualRiskSelectedEvent("Moderate")'>Moderate</button>
            <button mat-menu-item (click)='residualRiskSelectedEvent("Low")'>Low</button>
            <button mat-menu-item (click)='residualRiskSelectedEvent("All")'>All</button>
        </mat-menu> -->
    </span>
</app-header>

<div *ngIf='!thinking' style="height: 4px;"></div>
<div *ngIf='thinking'><mat-progress-bar mode="query"></mat-progress-bar></div>

<!-- Chips Row -->
<div fxLayout='row' fxLayoutAlign="center center" class='chips-row'>
    <div>
        <mat-chip-list #chipListAll aria-label="">
            <span *ngFor="let chart of dashboardCharts">
                <mat-chip [selectable]="true" [removable]="removableChip" *ngIf="chart.visibleChip" (removed)="removeChip(chart.chartName)">
                    {{chart.label}}
                    <mat-icon matChipRemove *ngIf="removableChip">cancel</mat-icon>
                </mat-chip>
            </span>
        </mat-chip-list>
    </div>
</div>

<!-- Slider (Date) Row -->
<!-- <div fxLayout='row' fxLayoutAlign="space-between center" class='date-slader-row'>
    <div class='chart-box' [ngClass]="{'dark-chart-box': darkMode}" style="width: 100%;">
        <div fxFlex="200px" class='chart-title' [ngClass]="{'dark-chart-title': darkMode}">Incident Date Range</div>
        <div #timeSlider [hidden]='!loaded' class='time-slider' [ngClass]="{'dark': darkMode}">
            <ngx-slider *ngIf='timeSliderReady'
                [(value)]="dateFrom"
                [(highValue)]="dateTo"
                [(options)]="timeSliderOptions"
                (userChangeEnd)="timeSliderDateRangeChange($event)"
            ></ngx-slider>
        </div>
    </div>
</div> -->

<!-- Dashboard Row 1 -->
<div fxLayout='row' fxLayoutGap="20px" fxLayoutAlign="space-between center" class='chart-row chart-row-3'>
    <!-- Chart 1 -->
    <div fxFlex="33" class='chart-box' [ngClass]="{'dark-chart-box': darkMode}">   
        <div fxLayout='column' fxLayoutAlign="space-between center">
            <div fxLayout='row' fxShow fxHide.lt-md fxLayoutAlign="space-between center">
                <div [ngClass]="{'dark': darkMode}">
                    <ngx-charts-advanced-pie-chart 
                        [view]="[0.33 * chartWidth, chartHeight]"
                        [results]="getChartData('RESIDUAL-RISK')"
                        [label]="'Number of risk items'"
                        [gradient]="true" 
                        [customColors]="riskColorScheme"
                        (select)="selectChart($event, 'RESIDUAL-RISK', 'LovRiskRating', 'ResidualRisk_Rating', false)">
                    </ngx-charts-advanced-pie-chart>  
                </div>              
            </div>
            <div fxLayout='row' fxHide fxShow.lt-md fxLayoutAlign="space-between center">
                <div [ngClass]="{'dark': darkMode}">
                    <ngx-charts-pie-chart
                        [view]="[chartWidth, chartHeight]"
                        [results]="getChartData('RESIDUAL-RISK')"
                        [labels]="true"
                        [gradient]="true" 
                        [customColors]='riskColorScheme' 
                        (select)="selectChart($event, 'RESIDUAL-RISK', 'LovRiskRating', 'ResidualRisk_Rating', false)">
                    </ngx-charts-pie-chart>
                </div>
            </div>
            <div>
                <span class='chart-title' [ngClass]="{'dark-chart-title': darkMode}">Residual Risk</span>
            </div>
        </div>
    </div>
    <!-- Chart 2 -->
    <div fxFlex="33" class='chart-box' [ngClass]="{'dark-chart-box': darkMode}">   
        <div fxLayout='column' fxLayoutAlign="space-between center">
            <div fxLayout='row' fxShow fxHide.lt-md fxLayoutAlign="space-between center">
                <div [ngClass]="{'dark': darkMode}">
                    <ngx-charts-advanced-pie-chart 
                        [view]="[0.33 * chartWidth, chartHeight]"
                        [results]="getChartData('INHERENT-RISK')"
                        [label]="'Number of risk items selected'"
                        [gradient]="true" 
                        [customColors]='riskColorScheme'
                        (select)="selectChart($event, 'INHERENT-RISK', 'LovRiskRating', 'InherentRisk_Rating', false)">
                    </ngx-charts-advanced-pie-chart> 
                </div>              
            </div>
            <div fxLayout='row' fxHide fxShow.lt-md fxLayoutAlign="space-between center">
                <div [ngClass]="{'dark': darkMode}">
                    <ngx-charts-pie-chart
                        [view]="[chartWidth, chartHeight]"
                        [results]="getChartData('INHERENT-RISK')"
                        [labels]="true"
                        [gradient]="true" 
                        [customColors]='riskColorScheme'
                        (select)="selectChart($event, 'INHERENT-RISK', 'LovRiskRating', 'InherentRisk_Rating', false)">
                    </ngx-charts-pie-chart>
                </div>
            </div>
            <div>
                <span class='chart-title' [ngClass]="{'dark-chart-title': darkMode}">Inherent Risk</span>
            </div>
        </div>
    </div>
    <!-- Chart 3 -->
    <div fxFlex="33" class='chart-box' [ngClass]="{'dark-chart-box': darkMode}">   
        <div fxLayout='column' fxLayoutAlign="space-between center">
            <div [ngClass]="{'dark': darkMode}">
                <ngx-charts-bar-vertical 
                    [view]="[0.33 * chartWidth, chartHeight]"
                    [results]="getChartData('CATGEORY')"
                    [yAxisLabel]="'Count'"
                    [showXAxisLabel]="true"
                    [showYAxisLabel]="false"
                    [xAxis]="true"
                    [yAxis]="true"
                    [trimXAxisTicks]="true"
                    [maxXAxisTickLength]='5'
                    [rotateXAxisTicks]='true'
                    [gradient]="true" 
                    [roundEdges]='false' 
                    [noBarWhenZero]='true'
                    [showDataLabel]='true'
                    [scheme]="customColorScheme"
                    (select)="selectChart($event, 'CATGEORY', 'LovRiskCategory', 'RiskCategoryId', true)">
                </ngx-charts-bar-vertical>
            </div>
            <div>
                <span class='chart-title' [ngClass]="{'dark-chart-title': darkMode}">Category</span>
            </div>
        </div>
    </div>
</div>

<!-- Dashboard Row 2 -->
<div fxLayout='row' fxLayoutGap="20px" fxLayoutAlign="space-between center" class='chart-row chart-row-4'>
    <!-- Chart 1 -->
    <div fxFlex="25" class='chart-box' [ngClass]="{'dark-chart-box': darkMode}">   
        <div fxLayout='column' fxLayoutAlign="space-between center">
            <div [ngClass]="{'dark': darkMode}">
                <ngx-charts-bar-vertical 
                    [view]="[0.25 * chartWidth, chartHeight]"
                    [results]="getChartData('CONTROL-STATUS')"
                    [yAxisLabel]="'Count'"
                    [showXAxisLabel]="true"
                    [showYAxisLabel]="false"
                    [xAxis]="true"
                    [yAxis]="true"
                    [trimXAxisTicks]="true"
                    [maxXAxisTickLength]='10'
                    [rotateXAxisTicks]='true'
                    [gradient]="true" 
                    [roundEdges]='false' 
                    [noBarWhenZero]='true'
                    [showDataLabel]='true'
                    [scheme]="customColorScheme"
                    (select)="selectChart($event, 'CONTROL-STATUS', 'DomRiskControlStatus', 'ControlStatus', true)">
                </ngx-charts-bar-vertical>
            </div>
            <div>
                <span class='chart-title' [ngClass]="{'dark-chart-title': darkMode}">Status</span>
            </div>
        </div>
    </div>
    <!-- Chart 2 -->
    <div fxFlex="25" class='chart-box' [ngClass]="{'dark-chart-box': darkMode}">   
        <div fxLayout='column' fxLayoutAlign="space-between center">
            <div [ngClass]="{'dark': darkMode}">
                <ngx-charts-pie-chart
                    [view]="[0.25 * chartWidth, chartHeight]"
                    [results]="getChartData('EFFECTIVENESS')"
                    [labels]="true"
                    [gradient]="true" 
                    [scheme]="customColorScheme"
                    (select)="selectChart($event, 'EFFECTIVENESS', 'DomRiskControlEffectiveness', 'ControlEffectiveness', true)">
                </ngx-charts-pie-chart>
            </div>
            <div>
                <span class='chart-title' [ngClass]="{'dark-chart-title': darkMode}">Effectiveness</span>
            </div>
        </div>
    </div>
    <!-- Chart 3 -->
    <div fxFlex="25" class='chart-box' [ngClass]="{'dark-chart-box': darkMode}">   
        <div fxLayout='column' fxLayoutAlign="space-between center">
            <div [ngClass]="{'dark': darkMode}">
                <ngx-charts-pie-chart
                    [view]="[0.25 * chartWidth, chartHeight]"
                    [results]="getChartData('OWNER')"
                    [labels]="true"
                    [gradient]="true" 
                    [scheme]="customColorScheme"
                    (select)="selectChart($event, 'OWNER', 'LovStaff', 'RiskOwnerId', true)">
                </ngx-charts-pie-chart>
            </div>
            <div>
                <span class='chart-title' [ngClass]="{'dark-chart-title': darkMode}">Risk Owner</span>
            </div>
        </div>
    </div>
    <!-- Chart 4 -->
    <div fxFlex="25" class='chart-box' [ngClass]="{'dark-chart-box': darkMode}">   
        <div fxLayout='column' fxLayoutAlign="space-between center">
            <div [ngClass]="{'dark': darkMode}">
                <ngx-charts-pie-chart
                    [view]="[0.25 * chartWidth, chartHeight]"
                    [results]="getChartData('RESPONSIBILITY')"
                    [labels]="true"
                    [gradient]="true" 
                    [scheme]="customColorScheme"
                    (select)="selectChart($event, 'RESPONSIBILITY', 'LovStaff', 'RiskResponsibilityId', true)">
                </ngx-charts-pie-chart>
            </div>
            <div>
                <span class='chart-title' [ngClass]="{'dark-chart-title': darkMode}">Risk Responsibility</span>
            </div>
        </div>
    </div>
</div>

<!-- Map Row -->  
<div fxLayout='row' class='map-row' style='height: unset;'>
    <mat-accordion class="map-accordian-panel" multi>
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>Map</mat-panel-title>
                <mat-panel-description>
                    <span class='map-accordian-title'>Expand to see the map</span>
                    <!-- <mat-icon>account_circle</mat-icon> -->
                </mat-panel-description>
            </mat-expansion-panel-header>
                <app-esri-map fxLayout='row' style='width: 100%; height: 750px;' 
                    sidePanelWidth='sidePanelWidth'
                    [showFullscreenWidget]='true' 
                    [showLegendWidget]='true'
                    [legendLayerInfos]='legendLayerInfos'
                    [hideBasemap]='false' 
                    [basemapName]='basemapName'
                    (viewCreatedEvent)='viewCreatedEvent($event)'>
                    
                    <div #popupMenu [hidden]="!loaded">
                        <app-esri-popup-menu [popupType]="'MENU'">
                            <div class="popup-menu-items">
                                <button mat-menu-item *ngIf="selectedFeature" (click)='currentModeService.currentModeData.next("edit-attribute")'>
                                    <mat-icon class='material-icons-sharp'>edit</mat-icon>
                                    <span>Edit this item</span>
                                </button>
                                <button mat-menu-item *ngIf="!selectedFeature" (click)='createFeatureFromPopupMenuEvent()'>
                                    <mat-icon class='material-icons-sharp'>add_location</mat-icon>
                                    <span>Create a new item here</span>
                                </button>
                                <button mat-menu-item *ngIf="selectedFeature" (click)='moveFeatureFromPopupMenuEvent()'>
                                    <mat-icon class='material-icons-sharp'>edit_location</mat-icon>
                                    <span>Move the selected item here</span>
                                </button>
                            </div>
                        </app-esri-popup-menu>
                    </div>
            
                    <div #popupData [hidden]="!loaded">
                        <app-esri-popup-menu [popupType]="'DATA'">
                            <div class="popup-data-items">
                                <app-esri-edit-form [readOnly]='true'></app-esri-edit-form>
                            </div>
                        </app-esri-popup-menu>   
                    </div>
                </app-esri-map>
        </mat-expansion-panel>
    </mat-accordion>
</div>

<!-- Image Gallery Row -->
<div fxLayout='row' class='image-row' style='height: unset;'>
    <app-esri-attachment style='width: 100%'></app-esri-attachment> 
</div>

<!-- Table Data Row -->
<div fxLayout='row' class='table-data-row'>
    <app-esri-view-table (addButtonSelectedEvent)='addBtnEvent()'></app-esri-view-table>
</div>

<!-- Footer -->
<app-footer></app-footer>

