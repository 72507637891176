import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { YouTubePlayerModule } from '@angular/youtube-player';

export interface DialogData {
  url: string,
  title: string,
  width: string,
  height: string
}

@Component({
  selector: 'app-game-flyover',
  templateUrl: './game-flyover.component.html',
  styleUrls: ['./game-flyover.component.scss']
})

export class GameFlyoverComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  ngOnInit(): void {
    const tag = document.createElement('script');
    tag.src = 'https://www.youtube.com/iframe_api';
    document.body.appendChild(tag);
  }
}
