import { Component, OnInit, OnDestroy, AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, ElementRef } from '@angular/core';
import { combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { BaseFeaturePage } from 'src/app/_esri/models/base-feature-page';
import { FeaturesService } from 'src/app/_esri/services/features.service';
import { CourseService } from 'src/app/_course/services/course.service';
import { SurroundsService } from 'src/app/_course/services/surrounds.service';
import { DashboardChart, EsriGeometryType } from 'src/app/_esri/models/esri';
//import { AuditService } from 'src/app/audit/services/audit.service';
import { CurrentModeService, CurrentModeData } from 'src/app/_globals/services/current-mode.service';
import { GeocodeDataService } from 'src/app/_globals/services/geocode-data.service';
import { FormChangeService } from 'src/app/_globals/services/form-change.service';
import { FormFieldChangeService } from 'src/app/_globals/services/form-field-change.service';
import { FormFieldUpdateService } from 'src/app/_globals/services/form-field-update.service';
import { DomainLovService } from 'src/app/_esri/services/domain-lov.service';
import { LovDataService } from 'src/app/_globals/services/lov-data.service';
import { Audit, AuditAttributes } from 'src/app/audit/models/audit';

import { WorkRequest, WorkRequestContact } from 'src/app/maintenance/models/work-request';
import { CreateWorkRequestService } from 'src/app/maintenance/services/create-work-request.service';



@Component({
  selector: 'app-audit',
  templateUrl: './audit.component.html',
  //templateUrl: './audit.split.component.html',
  styleUrls: [
    './audit.component.scss',
    '../_styles/base-feature-page.scss'
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class AuditComponent extends BaseFeaturePage implements OnInit, OnDestroy, AfterViewInit {

  // Work Requests
  protected idType_WorkRequest: string;
  protected idTypeFieldName_WorkRequest;
  protected formName_WorkRequest: string = 'AUDIT_WORKREQUEST';

  // Assign Object Type
  attributes: AuditAttributes[] = [];

  title = 'Course Audit';
  subTitle = 'Items identified during an audit of the course';
  idType = 'AUDIT';
  idTypeFieldName = 'AuditId';
  showTimeSliderWidget = false;
  //dateFilterAttributeName = 'AuditDate';

  showDataCards = true;
  showDataTable = false;
  //showBaseLayers = true;
  darkMode = false;

  // courseLayerOpacity = 0.3;
  // surroundsLayerOpacity = 0.1;
  // boundaryLayerOpacity = 0.3;
  // hillshadeLayerOpacity = 0.05;

  dashboardCharts: DashboardChart[] = [
    { chartName: 'WORKPROGRAM' },
    { chartName: 'COURSEPART' },
    { chartName: 'FAIRWAY' }
  ];

  //parentLovWatchList = [];
  //fieldChangeWatchList = [];

  constructor(
    protected ref: ChangeDetectorRef,
    protected dialog: MatDialog,
    protected currentModeService: CurrentModeService,
    protected geocodeDataService: GeocodeDataService,
    protected formChangeService: FormChangeService,
    protected formFieldChangeService: FormFieldChangeService,
    protected formFieldUpdateService: FormFieldUpdateService,
    protected elementRef: ElementRef,
    //
    private createWorkRequestService: CreateWorkRequestService
  ) {
    super(
      ref,
      dialog,
      currentModeService,
      geocodeDataService, 
      formChangeService,
      formFieldChangeService,
      formFieldUpdateService,
      elementRef
    );
  }

  ngOnInit(): void {
    this.thinking = true;

    // Get layers, features, lovs, domains, etc
    this.featureLayerObject = new Audit();


    


    //
    // Work Request Integrations
    //

    // Initialise the Work Request Object
    this.createWorkRequestService.initialiseWorkRequestIntegration();

    // Initialise the Audit Object
    this.createWorkRequestService.initialiseAuditIntegration();

    combineLatest([
      this.createWorkRequestService.workRequestReadyEvent,
      this.createWorkRequestService.auditReadyEvent
    ]).pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      console.log('Integration Ready Event ready', this.esriMapComponent.createWorkRequestBtnHidden);
      this.esriMapComponent.createWorkRequestBtnHidden = false;
      this.idType_WorkRequest = this.createWorkRequestService.workRequest.idType;
      this.idTypeFieldName_WorkRequest = this.createWorkRequestService.workRequest.idTypeFieldName;
    });

    super.ngOnInit();
  }

  ngOnDestroy(): void {
    this.createWorkRequestService.destroyer();
    super.ngOnDestroy();
  }

  //
  // Get attributes from feature set and assign to class "featureAttributes", (view table, and view cards )- calc virtual fields
  //
  protected refreshDisplay() {
    this.featureLayerObject.updateVirtualFields();
    super.refreshDisplay();
  }

  // Build data for charts
  protected createCharts() {
    super.createCharts();
    this.dashboardCharts.find(x => x.chartName == 'WORKPROGRAM').summaryData = this.createChartDataSet('DomWorkType', 'WorkTypeId', true);
    this.dashboardCharts.find(x => x.chartName == 'COURSEPART').summaryData = this.createChartDataSet('DomAuditCoursePart', 'AuditCoursePart', true);
    this.dashboardCharts.find(x => x.chartName == 'FAIRWAY').summaryData = this.createChartDataSet('DomFairway', 'FairwayNumber', true);
  }

  //
  // Workflow - Control button and editor behaviour based on current feature edit mode
  //

  protected setCurrentMode(currentModeData: CurrentModeData) {
    console.log('Audit setCurrentMode currentModeData', currentModeData);
    super.setCurrentMode(currentModeData);
    let mode = currentModeData.mode.toLowerCase();

    // Only process parent forms here.  Child forms (with names other than FORM) should be processed in extended class
    if (currentModeData.formName.toUpperCase() === this.formName.toUpperCase()) {

      let mode = currentModeData.mode.toLowerCase();
      let fieldVisibility_OnPremises: any[] = [];
      let fieldVisibility_Insurance: any[] = [];
      let fieldVisibility_Medical: any[] = [];
      let fieldVisibility_Legal: any[] = [];

      switch (mode) {
        case 'add':
          this.esriMapComponent.locationHidden = true;
          this.esriMapComponent.locationMap = true;
          this.esriMapComponent.locationAddress = false;

        case 'add-popup':
          break;

        case 'location-map':
          //this.setFormFields_OnPremises('YES');
          break;

        case 'location-address':
          //this.setFormFields_OnPremises('NO');
          break;

        case 'location-selected':
        case 'location-changed':
          // Lookup the Fairway Number
          this.featuresService.getAttributeWithinLayer(this.fairwayLayer, this.geometry, 'FairwayNumber').then(fairwayNumber => {
            console.log('fairwayNumber', fairwayNumber);

            if (fairwayNumber) {
              this.defaultFieldValues.push({
                name: 'FairwayNumber',
                value: fairwayNumber
              });
            }
            else {
              this.defaultFieldValues.push({
                name: 'FairwayNumber',
                value: null
              });
            }

            // Show form
            //this.openEditFeatureDialog('add', 'Create a new item');
            if (mode === 'location-changed') {
              // Mark the form dirty
              console.log('make dirty location-changed', event);
              this.openEditFeatureDialog(mode, true);
            }
            else {
              this.openEditFeatureDialog(mode);
            }
          });

          // Hide the workflow box
          this.esriMapComponent.locationMap = false;
          this.esriMapComponent.locationAddress = false;
          this.esriMapComponent.locationNoneChoice = false;

          break;

        case 'edit-attribute':
          this.openEditFeatureDialog(mode);
          break;

        case 'form-submitted':
          break;
      }

    }
    else if (currentModeData.formName.toUpperCase() === this.formName_WorkRequest.toUpperCase()) {
      console.log('setCurrentMode() AUDIT_WORKREQUEST mode', mode);

      switch (mode) {
        case 'form-submitted':
          console.log('setCurrentMode() AUDIT_WORKREQUEST form-submitted this.lastrecordId', this.lastrecordId);

          if (this.lastrecordId) {
            let fieldValue: any[] = [];
            let fieldUpdateData = {};

            fieldValue.push({
              name: this.idTypeFieldName_WorkRequest,
              value: this.lastrecordId
            });

            fieldUpdateData = {
              updateType: 'value',
              data: fieldValue
            }

            if (fieldUpdateData) {
              this.formFieldUpdateService.formFieldUpdateData.next(fieldUpdateData);
            }
          }

        default:
          console.log('Mode, "' + mode + '" not defined for form "AUDIT_WORKREQUEST"');
          break;
      }
    }
  }

  //
  // Form Changes Events
  //

  protected featureFormFieldChangeEvent(event) {
    super.featureFormFieldChangeEvent(event);
  }

  //
  // Define report definitions
  //

  public openReportDialog(dialogTitle?: string) {
    //console.log('audit openReportDialog');

    this.reportDefinitions = [
      {
        moduleId: 'AUDIT',
        reportId: 'COURSEAUDIT',
        reportName: 'Course Audit',
        reportTitleLine1: 'The Lakes Golf Course',
        reportTitleLine2: 'Audit Report 2020',
        reportDescription: 'The Lakes Golf Course Audit Report 2020',
        featureLayer: this.featureLayerObject.featureLayer,
        features: this.featureLayerObject.features,
        domains: this.featureLayerObject.domains,
        //domains_lov: this._domains_lov,
        //domains_lovParent: this._domains_lovParent
      }
    ];

    const _dialogTitle = 'Audit Reports';
    super.openReportDialog(_dialogTitle);
  }



  //
  // Enhance Base Functions
  //

  protected selectFeature(feature: any, origin: string) {
    super.selectFeature(feature, origin);
    //console.log('selectFeature this.featureLayerObject feature', feature);

    // Enable / Disable Work Request Integration Button
    if (this.selectedFeature.attributes.WorkTypeId === 'MAINT') {
      // Enable Create Work Request Button
      this.esriMapComponent.createWorkRequestBtnDisabled = false;
    }
    else {
      // Disable Create Work Request Button
      this.esriMapComponent.createWorkRequestBtnDisabled = true;
    }
  }

  protected unSelectFeature() {
    // Disable Create Work Request Button
    this.esriMapComponent.createWorkRequestBtnDisabled = true;

    super.unSelectFeature();
  }

  //
  // Link to Miantenance
  //

  protected async createWorkRequest(): Promise<any> {
    this.workRequestAttributes = await this.createWorkRequestService.createFromAudit(this.selectedFeature);
    let dialogTitle = 'Create a work request from Audit: ' + this.selectedFeature.attributes.AuditItemNumber;
    this.openEditFeatureDialog_WorkRequest(dialogTitle, this.createWorkRequestService, this.formName_WorkRequest);
  }






}
