<h1 mat-dialog-title>{{data.title}}</h1>
<div mat-dialog-content>
  <div>
    <form [formGroup]='gameNotesForm'>

      <mat-form-field class='form-field'> 
        <mat-label>Tee Marker</mat-label>
        <input matInput type='text' formControlName="gameTeeMarkerType" readonly>
      </mat-form-field>

      <mat-form-field class='form-field'> 
        <mat-label>Drive Length</mat-label>
        <input matInput type='text' formControlName="gameDriveLength" readonly>
      </mat-form-field>

      <mat-form-field class='form-field'> 
        <mat-label>Fairway</mat-label>
        <input matInput type='text' formControlName="gameFairwayNumber" readonly>
      </mat-form-field>

      <mat-form-field class='form-field'> 
        <mat-label>Gender</mat-label>
        <input matInput type='text' formControlName="gameGender" readonly>
      </mat-form-field>

      <mat-form-field class='form-field'> 
        <mat-label>Notes</mat-label>
        <input matInput type='textarea' formControlName="gameNotes">
      </mat-form-field>

    </form>

    <!-- <p>Form Status: {{ gameNotesForm.status }} </p> -->
    <p>Form Value: {{ gameNotesForm.value | json }} </p>
    <!-- <p>Form gameNotes: {{ gameNotesForm.value.gameNotes }} </p> -->

  </div>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="generatePdf()">Generate PDF</button>
  <!-- <button mat-button (click)="createPdf()">Create PDF File</button> -->
  <button mat-button mat-dialog-close>Close</button>
</div>

