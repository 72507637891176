import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})

export class MenuComponent implements OnInit {

  @ViewChild('menu', {static: true}) public menu: any;

  @Output() loginEvent = new EventEmitter<any>();
  @Output() logoutEvent = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }

  public login() {
    this.loginEvent.emit();
  }

  public logout() {
    this.logoutEvent.emit();
  }
}
