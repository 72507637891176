import { Component, OnInit } from '@angular/core';
import { Platform, supportsPassiveEventListeners, supportsScrollBehavior, getSupportedInputTypes } from '@angular/cdk/platform';
//import { TokenOauthService } from 'src/app/_esri/services/token-oauth.service';
//import { TokenRegisterService } from 'src/app/_esri/services/token-register.service';
import { LocalStorage } from 'ngx-webstorage';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})

export class HomeComponent implements OnInit {

  public title: string = 'Home';

  supportedInputTypes = Array.from(getSupportedInputTypes()).join(', ');
  supportsPassiveEventListeners = supportsPassiveEventListeners();
  supportsScrollBehavior = supportsScrollBehavior();

  @LocalStorage()
  //public access_token;
  public _token;

  constructor(
    public platform: Platform,
    //private tokenOauthService: TokenOauthService,
    //private registerTokenService: TokenRegisterService,
  ) {  }

  ngOnInit(): void {
    console.log(this.platform);

    if (this.platform.IOS || this.platform.ANDROID) {
      console.log('mobile');
    }
    else {
      console.log('not mobile');
    }

    // Get a OAUTH token to access the secure map layers
    // this.tokenOauthService.getToken().subscribe(
    //   data => { 
    //     this.access_token = data.access_token;
    //     console.log('Token received is: ', this.access_token);
    //     this.registerTokenService.registerToken();
    //   },
    //   error => {
    //     console.error('There was an error!', error);
    //   }
    // );
  }
}