<app-header>
    <span title>{{title}}</span>
    <span toolbar-extras></span>
</app-header>

<!-- <div class='body' [ngClass]="{'dark': darkMode}"> -->
<div *ngIf='!thinking' style="height: 4px;"></div>
<div *ngIf='thinking'><mat-progress-bar mode="query"></mat-progress-bar></div>

<!-- Cards Row -->
<div fxLayout='row' fxLayoutGap="20px" fxLayoutAlign="space-between center" class='cards-row' >
    <app-esri-info-box *ngFor='let card of dashboardCards' fxFlex='cardWidth' [darkMode]='darkMode' [infoBoxKpi]='card.kpi' [infoBoxTitle]='card.label' [infoBoxValue]='card.summaryData[0]' [infoBoxDescription]='card.description'></app-esri-info-box>
</div>

<!-- Chips Row -->
<div fxLayout='row' fxLayoutAlign="center center" class='chips-row'>
    <div>
        <mat-chip-list #chipListAll aria-label="">
            <span *ngFor="let chart of dashboardCharts">
                <mat-chip [selectable]="true" [removable]="removableChip" *ngIf="chart.visibleChip" (removed)="removeChip(chart.chartName)">
                    {{chart.label}}
                    <mat-icon matChipRemove *ngIf="removableChip">cancel</mat-icon>
                </mat-chip>
            </span>
        </mat-chip-list>
    </div>
</div>

<!-- Slider (Date) Row -->
<div fxLayout='row' fxLayoutAlign="space-between center" class='date-slader-row'>
    <!-- Date Range Selector -->
    <div class='chart-box' [ngClass]="{'dark-chart-box': darkMode}" style="width: 100%;">
        <div fxFlex="200px" class='chart-title' [ngClass]="{'dark-chart-title': darkMode}">Request Date Range</div>
        <div #timeSlider [hidden]='!loaded' class='time-slider' [ngClass]="{'dark': darkMode}">
            <ngx-slider *ngIf='timeSliderReady'
                [(value)]="dateFrom"
                [(highValue)]="dateTo"
                [(options)]="timeSliderOptions"
                (userChangeEnd)="timeSliderDateRangeChange($event)"
            ></ngx-slider>
        </div>
    </div>
</div>

<!-- Dashboard Row 1 -->
<div fxLayout='row' fxLayoutGap="20px" fxLayoutAlign="space-between center" class='chart-row chart-row-3'>
    <!-- Chart 1 -->
    <div fxFlex="33" class='chart-box' [ngClass]="{'dark-chart-box': darkMode}">
        <div fxLayout='column' fxLayoutAlign="space-between center">   
            <div fxLayout='row' fxShow fxHide.lt-md fxLayoutAlign="space-between center">
                <ngx-charts-advanced-pie-chart 
                    [view]="[0.33 * chartWidth, chartHeight]"
                    [results]="getChartData('STATUS')"
                    [label]="'Number of work requests'"
                    [gradient]="true" 
                    [scheme]='customColorScheme'     
                    (select)="selectChart($event, 'STATUS', 'DomStatus', 'Status', false)">
                </ngx-charts-advanced-pie-chart>
            </div> <!-- style="pieChartAdvancedStyle" -->
            <div fxLayout='row' fxHide fxShow.lt-md fxLayoutAlign="space-between center">
                <ngx-charts-pie-chart
                    [view]="[chartWidth, chartHeight]"
                    [results]="getChartData('STATUS')"
                    [labels]="true"
                    [gradient]="true" 
                    [scheme]='customColorScheme'
                    (select)="selectChart($event, 'STATUS', 'DomWorkRequestStatus', 'Status', false)">
                </ngx-charts-pie-chart>
            </div> <!-- style="color: rgb(249, 249, 249);" -->
            <div>
                <span class='chart-title' [ngClass]="{'dark-chart-title': darkMode}">Status</span>
            </div>
        </div>
    </div>
    <!-- Chart 2 -->
    <div fxFlex="33" class='chart-box' [ngClass]="{'dark-chart-box': darkMode}">
        <div fxLayout='column' fxLayoutAlign="space-between center"> 
            <div [ngClass]="{'dark': darkMode}">        
                <ngx-charts-bar-vertical 
                    [view]="[0.33 * chartWidth, chartHeight]"
                    [results]="getChartData('FAIRWAY')"
                    [xAxisLabel]="'Hole'" 
                    [yAxisLabel]="'Count'"
                    [showXAxisLabel]="false"
                    [showYAxisLabel]="false"
                    [xAxis]="true"
                    [yAxis]="true"
                    [trimXAxisTicks]="true"
                    [maxXAxisTickLength]='5'
                    [rotateXAxisTicks]='true'  
                    [tooltipDisabled]='true' 
                    [gradient]="true"
                    [roundEdges]='false'
                    [noBarWhenZero]='true'
                    [showDataLabel]='true'
                    [scheme]='customColorScheme'
                    (select)="selectChart($event, 'FAIRWAY', 'DomFairway', 'FairwayNumber', false, true)">
                </ngx-charts-bar-vertical>
            </div>
            <div> 
                <span class='chart-title' [ngClass]="{'dark-chart-title': darkMode}">Hole</span>
            </div>
        </div>
    </div>
    <!-- Chart 3 -->
    <div fxFlex="33" class='chart-box' [ngClass]="{'dark-chart-box': darkMode}">
        <div fxLayout='column' fxLayoutAlign="space-between center"> 
            <div [ngClass]="{'dark': darkMode}">        
                <ngx-charts-gauge 
                    [view]="[0.33 * chartWidth, chartHeight]"
                    [results]="getChartData('PERFORMANCE')"
                    [startAngle]='-120'
                    [angleSpan]='240'
					[min]='0'
                    [max]='100'
                    [bigSegments]='10'
                    [smallSegments]='1'
                    [showText]='true'
                    [units]="'%'"  
                    [scheme]='customColorScheme'
                    style="fill: darkgrey;"
                    >
                </ngx-charts-gauge>
            </div>
            <div> 
                <span class='chart-title' [ngClass]="{'dark-chart-title': darkMode}">Perfomance</span>
            </div>
        </div>
    </div>
</div>    

<!-- Dashboard Row 2 -->
<div fxLayout='row' fxLayoutGap="20px" fxLayoutAlign="space-between center" class='chart-row chart-row-3'>
    <!-- Chart 1 -->
    <div fxFlex="33" class='chart-box' [ngClass]="{'dark-chart-box': darkMode}">
        <div fxLayout='column' fxLayoutAlign="space-between center">
            <div [ngClass]="{'dark': darkMode}">
                <ngx-charts-pie-chart
                    [view]="[0.33 * chartWidth, chartHeight]"
                    [results]="getChartData('REPORTED-BY')"
                    [labels]="true"
                    [gradient]="true" 
                    [scheme]='customColorScheme'
                    (select)="selectChart($event, 'REPORTED-BY', 'LovWorkRequestReportedBy', 'ReportedBy', false)">
                </ngx-charts-pie-chart>
            </div>
            <div> 
                <span class='chart-title' [ngClass]="{'dark-chart-title': darkMode}">Reported By</span>
            </div>
        </div>
    </div>
    <!-- Chart 2 -->
    <div fxFlex="33" class='chart-box' [ngClass]="{'dark-chart-box': darkMode}">
        <div fxLayout='column' fxLayoutAlign="space-between center" >
            <div [ngClass]="{'dark': darkMode}">
                <ngx-charts-pie-chart
                    [view]="[0.33 * chartWidth, chartHeight]"
                    [results]="getChartData('PRIORITY')"
                    [labels]="true"
                    [gradient]="true" 
                    [scheme]='customColorScheme'
                    (select)="selectChart($event, 'PRIORITY', 'DomPriority', 'Priority', false)">
                </ngx-charts-pie-chart>
            </div>
            <div> 
                <span class='chart-title' [ngClass]="{'dark-chart-title': darkMode}">Priority</span>
            </div>
        </div>
    </div>
    <!-- Chart 3 -->
    <div fxFlex="33" class='chart-box' [ngClass]="{'dark-chart-box': darkMode}">
        <div fxLayout='column' fxLayoutAlign="space-between center">
            <div [ngClass]="{'dark': darkMode}">
                <ngx-charts-pie-chart
                    [view]="[0.33 * chartWidth, chartHeight]"
                    [results]="getChartData('SOURCE')"
                    [labels]="true"
                    [gradient]="true" 
                    [scheme]='customColorScheme'
                    (select)="selectChart($event, 'SOURCE', 'DomSource', 'Source', false)">
                </ngx-charts-pie-chart>
            </div>
            <div> 
                <span class='chart-title' [ngClass]="{'dark-chart-title': darkMode}">Source</span>
            </div>
        </div>
    </div>
</div>

<!-- Dashboard Row 3 -->
<div fxLayout='row' fxLayoutGap="20px" fxLayoutAlign="space-between center" class='chart-row chart-row-4'>
    <!-- Chart 1 -->
    <div fxFlex="25" class='chart-box' [ngClass]="{'dark-chart-box': darkMode}">
        <div fxLayout='column' fxLayoutAlign="space-between center">
            <div [ngClass]="{'dark': darkMode}">
                <ngx-charts-bar-vertical 
                    [view]="[0.25 * chartWidth, chartHeight]"
                    [results]="getChartData('CATEGORY')"
                    [xAxisLabel]="'Category'" 
                    [yAxisLabel]="'Count'"
                    [showXAxisLabel]="false"
                    [showYAxisLabel]="false"
                    [xAxis]="true"
                    [yAxis]="true"
                    [trimXAxisTicks]="true"
                    [maxXAxisTickLength]='5'
                    [rotateXAxisTicks]='true'
                    [tooltipDisabled]='false' 
                    [gradient]="true" 
                    [roundEdges]='false' 
                    [noBarWhenZero]='true'
                    [showDataLabel]='true'
                    [scheme]='customColorScheme'
                    (select)="selectChart($event, 'CATEGORY', 'LovWorkRequestCategory', 'WorkRequestCategoryId', true)">
                </ngx-charts-bar-vertical> <!-- [scheme]="customColorScheme" -->
            </div>
            <div> 
                <span class='chart-title' [ngClass]="{'dark-chart-title': darkMode}">Category</span>
            </div>
        </div>
    </div>
    <!-- Chart 2 -->
    <div fxFlex="25" class='chart-box' [ngClass]="{'dark-chart-box': darkMode}">
        <div fxLayout='column' fxLayoutAlign="space-between center">
            <div [ngClass]="{'dark': darkMode}">
                <ngx-charts-bar-vertical-2d 
                    [view]="[0.25 * chartWidth, chartHeight]"
                    [results]="getChartData('SUB-CATEGORY')"
                    [xAxisLabel]="'Category'" 
                    [yAxisLabel]="'Count'"
                    [showXAxisLabel]='false'
                    [showYAxisLabel]='false'
                    [xAxis]='true'
                    [yAxis]='true'
                    [trimXAxisTicks]='true'
                    [maxXAxisTickLength]='5'
                    [rotateXAxisTicks]='true'    
                    [tooltipDisabled]='false' 
                    [gradient]='true'
                    [noBarWhenZero]='true'  
                    [roundEdges]='false'
                    [barPadding]='1'
                    [groupPadding]='4'
                    [showDataLabel]='true'
                    [scheme]='customColorScheme'
                    (select)="selectChart_SubDomain($event, 'SUB-CATEGORY')">
                </ngx-charts-bar-vertical-2d>
            </div>
            <div> 
                <span class='chart-title' [ngClass]="{'dark-chart-title': darkMode}">Sub-Category</span>
            </div>
        </div>
    </div>
    <!-- Chart 3 -->
    <div fxFlex="25" class='chart-box' [ngClass]="{'dark-chart-box': darkMode}">
        <div fxLayout='column' fxLayoutAlign="space-between center">
            <div [ngClass]="{'dark': darkMode}">
                <ngx-charts-bar-vertical 
                    [view]="[0.25 * chartWidth, chartHeight]"
                    [results]="getChartData('RESPONSIBILITY')"
                    [xAxisLabel]="'Responsibility'" 
                    [yAxisLabel]="'Count'"
                    [showXAxisLabel]="false"
                    [showYAxisLabel]="false"
                    [xAxis]="true"
                    [yAxis]="true"
                    [trimXAxisTicks]="true"
                    [maxXAxisTickLength]='5'
                    [rotateXAxisTicks]='true'
                    [tooltipDisabled]='false' 
                    [gradient]="true" 
                    [roundEdges]='false' 
                    [noBarWhenZero]='true'
                    [showDataLabel]='true'
                    [scheme]='customColorScheme'
                    (select)="selectChart($event, 'RESPONSIBILITY', 'LovStaff', 'Responsible', true)">
                </ngx-charts-bar-vertical>
            </div>
            <div> 
                <span class='chart-title' [ngClass]="{'dark-chart-title': darkMode}">Responsibility</span>
            </div>
        </div>
    </div>
    <!-- Chart 4 -->
    <div fxFlex="25" class='chart-box' [ngClass]="{'dark-chart-box': darkMode}">
        <div fxLayout='column' fxLayoutAlign="space-between center">
            <div [ngClass]="{'dark': darkMode}">
                <ngx-charts-bar-vertical 
                    [view]="[0.25 * chartWidth, chartHeight]"
                    [results]="getChartData('ASSIGNED')"
                    [xAxisLabel]="'Assigned To'" 
                    [yAxisLabel]="'Count'"
                    [showXAxisLabel]="false"
                    [showYAxisLabel]="false"
                    [xAxis]="true"
                    [yAxis]="true"
                    [trimXAxisTicks]="true"
                    [maxXAxisTickLength]='5'
                    [rotateXAxisTicks]='true'
                    [tooltipDisabled]='false' 
                    [gradient]="true" 
                    [roundEdges]='false' 
                    [noBarWhenZero]='true'
                    [showDataLabel]='true'
                    [scheme]='customColorScheme'
                    (select)="selectChart($event, 'ASSIGNED', 'LovStaff', 'AssignedTo', true)">
                </ngx-charts-bar-vertical>
            </div>
            <div> 
                <span class='chart-title' [ngClass]="{'dark-chart-title': darkMode}">Assigned To</span>
            </div>
        </div>
    </div>
</div>   

<!-- Dashboard Row 3 -->
<!-- <div fxLayout='row' fxLayoutAlign="space-between center" style='margin-top: 50px; padding-left: 40px; padding-right: 40px; height: 240px; width: 100%;'>
</div> -->

<!-- Image Gallery Row -->
<div fxLayout='row' class='image-row'>
    <app-esri-attachment [ngClass]="{'dark': darkMode}" style='width: 100%'></app-esri-attachment> 
</div>

<!-- Map Row -->
<div fxLayout='row' class='map-row'>
    <app-esri-map fxLayout='row' style='width: 100%;' 
        [sidePanelWidth]='sidePanelWidth' 
        [showFullscreenWidget]='true' 
        [showLegendWidget]='true'
        [legendLayerInfos]='legendLayerInfos'
        [hideBasemap]='false'
        [basemapName]='basemapName'
        (viewCreatedEvent)='viewCreatedEvent($event)'>

        <div #popupMenu [hidden]="!loaded">
            <app-esri-popup-menu [popupType]="'MENU'">  <!--  [latitude]='geometry.latitude' [longitude]='geometry.longitude' *ngIf='menuPopupVisible' -->
                <div class="popup-menu-items">
                    <!-- 'openEditFeatureDialog("EDIT")' -->

                    <button mat-menu-item *ngIf="selectedFeature" (click)='currentModeService.currentModeData.next("edit-attribute")'>
                        <mat-icon class='material-icons-sharp'>edit</mat-icon>
                        <span>Edit this item</span>
                    </button>

                    <button mat-menu-item *ngIf="!selectedFeature" (click)='createFeatureFromPopupMenuEvent()'>
                        <mat-icon class='material-icons-sharp'>add_location</mat-icon>
                        <span>Create a new item here</span>
                    </button>

                    <button mat-menu-item *ngIf="selectedFeature" (click)='moveMultiPointFeatureFromPopupMenuEvent()'>
                        <mat-icon class='material-icons-sharp'>edit_location</mat-icon>
                        <span>Move the location of the selected item here</span>
                    </button>

                    <button mat-menu-item *ngIf="selectedFeature" (click)='addMultiPointFeatureFromPopupMenuEvent()'>
                        <mat-icon class='material-icons-sharp'>edit_location</mat-icon>
                        <span>Add an additional location for the item here</span>
                    </button>

                    <button mat-menu-item *ngIf="selectedFeature" (click)='removeMultiPointFeatureFromPopupMenuEvent()'>
                        <mat-icon class='material-icons-sharp'>edit_location</mat-icon>
                        <span>Remove this location for the item here</span>
                    </button>

                    
                    <!-- <button mat-menu-item (click)='reportBtnEvent()'>
                        <mat-icon class='material-icons-sharp'>text_snippet</mat-icon>
                        <span>Run the audit report</span>
                    </button> -->

                </div>
            </app-esri-popup-menu>
        </div>
        
        <div #popupData [hidden]="!loaded">
            <app-esri-popup-menu [popupType]="'DATA'">
                <div class="popup-data-items">
                    <app-esri-edit-form [readOnly]='true'></app-esri-edit-form>
                </div>
            </app-esri-popup-menu>   
        </div>
    </app-esri-map>
</div>

<!-- Table Data Row -->
<div *ngIf='showDataTable' fxLayout='row' class='table-data-row'>
    <div id='divResults'>                         
        <app-esri-view-table [ngClass]="{'dark': darkMode}" (addButtonSelectedEvent)='addBtnEvent()'></app-esri-view-table>
    </div>
</div>

<!-- Edit Row -->
<!-- <div fxLayout='row' style='margin-top: 20px; padding-left: 20px; padding-right: 20px; width: 100%;'>
    <mat-expansion-panel class="edit-panel">
        <mat-expansion-panel-header>
            <mat-panel-title>Editor</mat-panel-title>
            <mat-panel-description>
                <span class='edit-panel-title'>Expand to edit the record</span>
            </mat-panel-description>
        </mat-expansion-panel-header>
        <div>                         

        </div>
    </mat-expansion-panel>
</div> -->


<!-- <div fxLayout='row' style='margin-top: 20px; padding-left: 20px; padding-right: 20px;'>
    <mat-expansion-panel class="results-panel" expanded>
        <mat-expansion-panel-header>
            <mat-panel-title>Records</mat-panel-title>
            <mat-panel-description>
                <span class='results-panel-title'>Expand to see the records</span>
            </mat-panel-description>
        </mat-expansion-panel-header>
        <div id='divResults'>                         
            <app-esri-view-table (addButtonSelectedEvent)='addBtnEvent()'></app-esri-view-table>
        </div>
    </mat-expansion-panel>
</div> -->


<!-- </div> -->
<!-- Footer -->
<app-footer></app-footer>