<div class='bottom-sheet'>

    <h1 class='bottom-sheet-title'>{{data.title}}</h1>

    <div fxLayout='row' fxLayoutAlign="space-between stretch">

        <mat-card class="bottom-sheet-card">
            <mat-card-header>
                <div mat-card-avatar class="card-header-image">
                    <span class='hole-stats-header-text'>{{data.gameFairwayNumber}}</span>
                </div>
                <mat-card-title>Putting Green Zones</mat-card-title>
                <mat-card-subtitle>For hole {{data.gameFairwayNumber}}</mat-card-subtitle>
            </mat-card-header>
            <img mat-card-image class='gallery-thumbnail' src="{{filenamePuttingGreen_thumbnail}}" alt="Photo of a ">
            <!-- <mat-card-content>
                <p>
                Can write a generic description here
                </p>
            </mat-card-content> -->
            <mat-card-actions>
                <div fxLayout='row' fxLayoutAlign="space-evenly center">
                    <!-- <mat-icon matTooltip='Open a map gallery for this hole in the selected game'>cloud_download</mat-icon>Download formats: -->
                    <a href="{{pathPuttingGreen_jpg}}{{filenamePuttingGreen_jpg}}" download="{{filenamePuttingGreen_jpg}}"><button mat-button><mat-icon>photo</mat-icon>&nbsp;.jpg</button></a>
                    <a href="{{pathPuttingGreen_pdf}}{{filenamePuttingGreen_pdf}}" download="{{filenamePuttingGreen_pdf}}"><button mat-button><mat-icon>picture_as_pdf</mat-icon>&nbsp;.pdf</button></a>
                </div>
            </mat-card-actions>
        </mat-card>

        <mat-card class="bottom-sheet-card">
            <mat-card-header>
                <div mat-card-avatar class="card-header-image" 
                [ngClass]="{
                    'card-header-image-red':data.gameTeeMarkerType === 'RED',
                    'card-header-image-black':data.gameTeeMarkerType === 'BLACK',
                    'card-header-image-blue':data.gameTeeMarkerType === 'BLUE',
                    'card-header-image-white':data.gameTeeMarkerType === 'WHITE',
                    'card-header-image-gold':data.gameTeeMarkerType === 'GOLD'
                }">
                <i *ngIf='data.gameGender === "M"' class="fa fa-male fa-2x" aria-hidden="true"></i>
                <i *ngIf='data.gameGender === "F"' class="fa fa-female fa-2x" aria-hidden="true"></i>
                <!-- <fa-icon *ngIf='gameGender === "M"' [icon]="faMale" size="2x"></fa-icon> -->
                <!-- <fa-icon *ngIf='gameGender === "F"' [icon]="faFemale" size="2x"></fa-icon> -->
            </div>
                <mat-card-title>Game Play</mat-card-title>
                <mat-card-subtitle>{{data.gameTeeMarkerType}}-{{data.gameDriveLength}}-{{data.gameGender}}-{{data.gameFairwayNumber}}</mat-card-subtitle>
            </mat-card-header>
            <img mat-card-image src="{{filenameGamePlay_thumbnail}}" alt="Photo of a ">
            <!-- <mat-card-content>
                <p>
                Can write a generic description here
                </p>
            </mat-card-content> -->
            <mat-card-actions>
                <div fxLayout='row' fxLayoutAlign="space-evenly center">
                    <!-- <mat-icon matTooltip='Open a map gallery for this hole in the selected game'>cloud_download</mat-icon>Download formats: -->
                    <a href="{{pathGamePlay_jpg}}{{filenameGamePlay_jpg}}" download="{{filenameGamePlay_jpg}}"><button mat-button><mat-icon>photo</mat-icon>&nbsp;.jpg</button></a>
                    <a href="{{pathGamePlay_pdf}}{{filenameGamePlay_pdf}}" download="{{filenameGamePlay_pdf}}"><button mat-button><mat-icon>picture_as_pdf</mat-icon>&nbsp;.pdf</button></a>
                </div>
            </mat-card-actions>
        </mat-card>


    </div>




    

    <!-- <div fxLayout='row' fxLayoutAlign="space-between stretch">
        <div fxFlex='50' class='gallery-panel'>
            <img class='gallery-thumbnail' src='assets/images/hole-thumbnails/Hole1Thumbnail.jpg'/>
        </div>
        <div fxFlex='50' class='gallery-panel'>
            <a href="assets/images/putting-green/GREEN_1.jpg" download="GREEN_1.jpg"><img class='gallery-thumbnail' src='assets/images/putting-green/GREEN_1_thumbnail.jpg'/></a>    
        </div>
    </div>

    <a href="assets/downloads/map-gallery/game/black-par/BLACK_PAR_M_1.pdf" download="BLACK_PAR_M_1.pdf">BLACK_PAR_M_1.pdf</a> -->
    
    
    

</div>



