import { Component, OnInit } from '@angular/core';
import { LocalStorage } from 'ngx-webstorage';
import { Esri } from 'src/app/_esri/models/esri';
import { LayerIds } from 'src/app/_esri/models/layer-ids';
import { setDefaultOptions, loadModules } from 'esri-loader';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})

export class HeaderComponent implements OnInit {
  private _identityManager: any = null;
  public signedIn: boolean = false;

  //private _wal;

  @LocalStorage()
  private _token: string;

  @LocalStorage()
  private _token_expires: number;

  @LocalStorage()
  public username: string;

  constructor() { }

  ngOnInit(): void {
    // Config ESRI Login
    this.oAuthInit();
  }

  public oAuthLogin() {
    // send users to arcgis.com to login


    console.log('this._identityManager.dialog', this._identityManager, this._identityManager.dialog );

    //this._wal = this._identityManager.dialog;

    this._identityManager.getCredential(Esri.portalUrl);
  }

  public oAuthLogout() {
    this._identityManager.destroyCredentials();
    //window.location.reload();
    // Navigate to the root url - avoids 404 error as /home or /whatever is not navigable in hosted prod environment
    window.location.replace('/');
  }

  public async oAuthInit() {
    //console.log('oAuthLogin');

    const options = {
      version: Esri.apiVersion
    };
    
    setDefaultOptions(options);
    
    const [
      OAuthInfo,
      IdentityManager
    ] = await loadModules([
      "esri/identity/OAuthInfo",
      "esri/identity/IdentityManager"
    ]);

    this._identityManager = IdentityManager;

    let oAuthInfo = new OAuthInfo({
      appId: LayerIds.oAuthClientId,
      popup: false
    });

    this._identityManager.registerOAuthInfos([oAuthInfo]);

    // persist logins when the page is refreshed
    this._identityManager
      .checkSignInStatus(Esri.portalUrl)
      .then( (status) => {
        //console.log('"', this.username, '" is already signed in...', status);
        this.username = status.userId;
        this._token = status.token;
        this._token_expires = status.expires;
        this.signedIn = true;

        //console.log('Token received is...', this._token);
        //console.log('Token expires...', this._token_expires);
      })
      .catch( (error) => {
        // Give user an option to sign in
        console.log('Not signed in...', error);
        this.signedIn = false;
      });
  }
}