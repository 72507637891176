import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, Injectable, ViewChild, ElementRef } from '@angular/core';
import { Esri, FieldConfig } from '../../models/esri';
import { setDefaultOptions, loadModules } from 'esri-loader';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-esri-edit-table',
  templateUrl: './esri-edit-table.component.html',
  styleUrls: ['./esri-edit-table.component.scss']
})
export class EsriEditTableComponent implements OnInit {

  @Output() featureTableLoadedEvent = new EventEmitter<any>();
  @Output() featureSelectedEvent = new EventEmitter<any>();

  private _featureLayer: any;
  private _fieldConfigs: FieldConfig[] = [];
  private _features = [];
  private _menuConfig: any[] = null;
  private _editingEnabled: boolean = true;

  @ViewChild("featureEditTable", { static: true }) private featureEditTableEl: ElementRef;

  @Input()
  set featureLayer(featureLayer: any) {
    this._featureLayer = featureLayer;
  }

  get featureLayer(): any {
    return this._featureLayer;
  }

  @Input()
  set fieldConfigs(fieldConfigs: FieldConfig[]) {
    this._fieldConfigs = fieldConfigs;
  }

  get fieldConfigs(): FieldConfig[] {
    return this._fieldConfigs;
  }

  @Input()
  set menuConfig(menuConfig: any[]) {
    this._menuConfig = menuConfig;
  }

  get menuConfig(): any[] {
    return this._menuConfig;
  }

  constructor() { }

  ngOnInit(): void {
    // this.initialiseEditTable().then( (featureTable) => {
    //   // The featureTable edit has been initialized   
    //   this.featureTableLoadedEvent.emit(featureTable);
    //   console.log('featureTable edit ready');
    // });
  }

  async initialiseEditTable() {
    console.log('EsriEditTableComponent initialiseEditTable');
    try {
      const options = {
        version: Esri.apiVersion
      };
    
      setDefaultOptions(options);

      const [
        FeatureTable
      ] = await loadModules([
        "esri/widgets/FeatureTable"
      ]);

      const menuConfig = {
        items: [
          {
            label: "Zoom to feature",
            iconClass: "esri-icon-zoom-in-magnifying-glass",
            clickFunction: (event) => {
              this.zoomToFeature();
            }
          }
        ]
      };

      if (!environment.production) {
        console.log('!environment.production _featureLayer is: ');
        console.log(this._featureLayer);

        // console.log('_fieldConfigs is: ');
        // console.log(this._fieldConfigs);
      }

      // Create the feature table
      const featureTable = new FeatureTable({
        layer: this._featureLayer,
        editingEnabled: this._editingEnabled,
        //menuConfig: this._menuConfig,
        menuConfig: menuConfig,
        fieldConfigs: this._fieldConfigs,
        container: this.featureEditTableEl.nativeElement
      });

      // Get the FeatureLayer's layerView and listen for the table's selection-change event
      featureTable.on("selection-change", (changes) => {
        // If the selection is removed, remove the feature from the array
        changes.removed.forEach( (item) => {
          const data = this._features.find( (data) => {
            return data.feature === item.feature;
          });
          if (data) {
            this._features.splice(this._features.indexOf(data), 1);
          }
        });

        // If the selection is added, push all added selections to array
        changes.added.forEach( (item) => {
          const feature = item.feature;
          this._features.push({
            feature: feature
          });
        });
      });

      //console.log('featureTable is: ', featureTable);

      if (!environment.production) {
        console.log('featureTable edit ready');
      }

      this.featureTableLoadedEvent.emit(featureTable);

      return featureTable;
    } 
    catch (error) {
      console.error('Esri-Loader: ', error);
    }
  }

  private zoomToFeature() {
    this.featureSelectedEvent.emit(this._features[0]);
  }

}
