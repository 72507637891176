<app-header>
    <span title>{{title}}</span>
    <span toolbar-extras>
        <button mat-icon-button [matMenuTriggerFor]='genderMenu' aria-label='Example icon-button with a menu'>
            <mat-icon matTooltip='Set the tees for men or women'>people</mat-icon>
        </button>
        <mat-menu #genderMenu="matMenu">
            <button mat-menu-item (click)='setGameGender("M")'>
                <!-- <mat-icon class='material-icons-sharp'>home</mat-icon> -->
                <span class="fa fa-male fa-2x" style='padding-right:30px;' aria-hidden="true"></span>
                <span>Men</span>
            </button>
            <button mat-menu-item (click)='setGameGender("F")'>
                <span class="fa fa-female fa-2x" style='padding-right:30px;' aria-hidden="true"></span>
                <span>Women</span>
            </button>
        </mat-menu>

        <button mat-icon-button [matMenuTriggerFor]='teeMenu' aria-label="Example icon-button with share icon">
            <mat-icon matTooltip='Choose the tee markers for your game'>golf_course</mat-icon>
        </button>

        <mat-menu #teeMenu="matMenu">
            <button mat-menu-item (click)='setTeeMarkerType("BLACK")' [disabled]='gameGender=="F"'>
                <mat-icon class='material-icons-sharp' style='color:black;'>sports_golf</mat-icon>
                <span>Black</span>
            </button>
            <button mat-menu-item (click)='setTeeMarkerType("BLUE")'>
                <mat-icon class='material-icons-sharp' style='color:blue;'>sports_golf</mat-icon>
                <span>Blue</span>
            </button>
            <button mat-menu-item (click)='setTeeMarkerType("WHITE")' [disabled]='gameGender=="F"'>
                <mat-icon class='material-icons-sharp' style='color:gray;'>sports_golf</mat-icon>
                <span>White</span>
            </button>
            <button mat-menu-item (click)='setTeeMarkerType("RED")'>
                <mat-icon class='material-icons-sharp' style='color:red;'>sports_golf</mat-icon>
                <span>Red</span>
            </button>
            <button mat-menu-item (click)='setTeeMarkerType("GOLD")' [disabled]='gameGender=="M"'>
                <mat-icon class='material-icons-sharp' style='color:gold;'>sports_golf</mat-icon>
                <span>Gold</span>
            </button>
        </mat-menu>

        <button mat-icon-button [matMenuTriggerFor]='lengthMenu' aria-label='Example icon-button with a menu'>
            <mat-icon matTooltip='Choose your drive length'>straighten</mat-icon>
        </button>
        <mat-menu #lengthMenu="matMenu">
            <button mat-menu-item (click)='setGameDriveLength("BIRDIE")'>
                <mat-icon class='material-icons-sharp'>straighten</mat-icon>
                <span>Birdie</span>
            </button>
            <button mat-menu-item (click)='setGameDriveLength("PAR")'>
                <mat-icon class='material-icons-sharp'>straighten</mat-icon>
                <span>Par</span>
            </button>
            <button mat-menu-item (click)='setGameDriveLength("137")'>
                <mat-icon class='material-icons-sharp'>straighten</mat-icon>
                <span>137m</span>
            </button>
            <button mat-menu-item (click)='setGameDriveLength("150")'>
                <mat-icon class='material-icons-sharp'>straighten</mat-icon>
                <span>150m</span>
            </button>
            <button mat-menu-item (click)='setGameDriveLength("180")'>
                <mat-icon class='material-icons-sharp'>straighten</mat-icon>
                <span>180m</span>
            </button>
        </mat-menu>
    </span>
</app-header>

<div fxLayout='column' fxLayoutAlign="start stretch" class='container gallery'>

    <!-- fxFlex.lt-lg='380px' -->

    <div fxLayout='row' fxLayoutAlign="space-between stretch">
        <div fxFlex='16' class='gallery-panel'>
            <!-- <img class='gallery-thumbnail' src='{{assetHostServer}}assets/images/hole-thumbnails/Hole1Thumbnail.jpg' (click)='queryGameHole("1");'/> -->
            <div class='gallery-thumbnail-panel' (click)='queryGameHole("1");'>Hole 1</div>
        </div>
        <div fxFlex='16' class='gallery-panel'>
            <!-- <img class='gallery-thumbnail' src='{{assetHostServer}}assets/images/hole-thumbnails/Hole2Thumbnail.jpg' (click)='queryGameHole("2");'/> -->
            <div class='gallery-thumbnail-panel' (click)='queryGameHole("2");'>Hole 2</div>
        </div>
        <div fxFlex='16' class='gallery-panel'>
            <!-- <img class='gallery-thumbnail' src='{{assetHostServer}}assets/images/hole-thumbnails/Hole3Thumbnail.jpg' (click)='queryGameHole("3");'/> -->
            <div class='gallery-thumbnail-panel' (click)='queryGameHole("3");'>Hole 3</div>
        </div>
        <div fxFlex='16' class='gallery-panel'>
            <!-- <img class='gallery-thumbnail' src='{{assetHostServer}}assets/images/hole-thumbnails/Hole4Thumbnail.jpg' (click)='queryGameHole("4");'/> -->
            <div class='gallery-thumbnail-panel' (click)='queryGameHole("4");'>Hole 4</div>
        </div>
        <div fxFlex='16' class='gallery-panel'>
            <!-- <img class='gallery-thumbnail' src='{{assetHostServer}}assets/images/hole-thumbnails/Hole5Thumbnail.jpg' (click)='queryGameHole("5");'/> -->
            <div class='gallery-thumbnail-panel' (click)='queryGameHole("5");'>Hole 5</div>
        </div>
        <div fxFlex='16' class='gallery-panel'>
            <!-- <img class='gallery-thumbnail' src='{{assetHostServer}}assets/images/hole-thumbnails/Hole6Thumbnail.jpg' (click)='queryGameHole("6");'/> -->
            <div class='gallery-thumbnail-panel' (click)='queryGameHole("6");'>Hole 6</div>
        </div>
    </div>

    <div fxLayout='row' fxLayoutAlign="space-between stretch">
        <div fxFlex='16' class='gallery-panel'>
            <!-- <img class='gallery-thumbnail' src='{{assetHostServer}}assets/images/hole-thumbnails/Hole7Thumbnail.jpg' (click)='queryGameHole("7");'/> -->
            <div class='gallery-thumbnail-panel' (click)='queryGameHole("7");'>Hole 7</div>
        </div>
        <div fxFlex='16' class='gallery-panel'>
            <!-- <img class='gallery-thumbnail' src='{{assetHostServer}}assets/images/hole-thumbnails/Hole8Thumbnail.jpg' (click)='queryGameHole("8");'/> -->
            <div class='gallery-thumbnail-panel' (click)='queryGameHole("8");'>Hole 8</div>
        </div>
        <div fxFlex='16' class='gallery-panel'>
            <!-- <img class='gallery-thumbnail' src='{{assetHostServer}}assets/images/hole-thumbnails/Hole9Thumbnail.jpg' (click)='queryGameHole("9");'/> -->
            <div class='gallery-thumbnail-panel' (click)='queryGameHole("9");'>Hole 9</div>
        </div>
        <div fxFlex='16' class='gallery-panel'>
            <!-- <img class='gallery-thumbnail' src='{{assetHostServer}}assets/images/hole-thumbnails/Hole10Thumbnail.jpg' (click)='queryGameHole("10");'/> -->
            <div class='gallery-thumbnail-panel' (click)='queryGameHole("10");'>Hole 10</div>
        </div>
        <div fxFlex='16' class='gallery-panel'>
            <!-- <img class='gallery-thumbnail' src='{{assetHostServer}}assets/images/hole-thumbnails/Hole11Thumbnail.jpg' (click)='queryGameHole("11");'/> -->
            <div class='gallery-thumbnail-panel' (click)='queryGameHole("11");'>Hole 11</div>
        </div>
        <div fxFlex='16' class='gallery-panel'>
            <!-- <img class='gallery-thumbnail' src='{{assetHostServer}}assets/images/hole-thumbnails/Hole12Thumbnail.jpg' (click)='queryGameHole("12");'/> -->
            <div class='gallery-thumbnail-panel' (click)='queryGameHole("12");'>Hole 12</div>
        </div>
    </div>

    <div fxLayout='row' fxLayoutAlign="space-between stretch">
        <div fxFlex='16' class='gallery-panel'>
            <!-- <img class='gallery-thumbnail' src='{{assetHostServer}}assets/images/hole-thumbnails/Hole13Thumbnail.jpg' (click)='queryGameHole("13");'/> -->
            <div class='gallery-thumbnail-panel' (click)='queryGameHole("13");'>Hole 13</div>
        </div>
        <div fxFlex='16' class='gallery-panel'>
            <!-- <img class='gallery-thumbnail' src='{{assetHostServer}}assets/images/hole-thumbnails/Hole14Thumbnail.jpg' (click)='queryGameHole("14");'/> -->
            <div class='gallery-thumbnail-panel' (click)='queryGameHole("14");'>Hole 14</div>
        </div>
        <div fxFlex='16' class='gallery-panel'>
            <!-- <img class='gallery-thumbnail' src='{{assetHostServer}}assets/images/hole-thumbnails/Hole15Thumbnail.jpg' (click)='queryGameHole("15");'/> -->
            <div class='gallery-thumbnail-panel' (click)='queryGameHole("15");'>Hole 15</div>
        </div>
        <div fxFlex='16' class='gallery-panel'>
            <!-- <img class='gallery-thumbnail' src='{{assetHostServer}}assets/images/hole-thumbnails/Hole16Thumbnail.jpg' (click)='queryGameHole("16");'/> -->
            <div class='gallery-thumbnail-panel' (click)='queryGameHole("16");'>Hole 16</div>
        </div>
        <div fxFlex='16' class='gallery-panel'>
            <!-- <img class='gallery-thumbnail' src='{{assetHostServer}}assets/images/hole-thumbnails/Hole17Thumbnail.jpg' (click)='queryGameHole("17");'/> -->
            <div class='gallery-thumbnail-panel' (click)='queryGameHole("17");'>Hole 17</div>
        </div>
        <div fxFlex='16' class='gallery-panel'>
            <!-- <img class='gallery-thumbnail' src='{{assetHostServer}}assets/images/hole-thumbnails/Hole18Thumbnail.jpg' (click)='queryGameHole("18");'/> -->
            <div class='gallery-thumbnail-panel' (click)='queryGameHole("18");'>Hole 18</div>
        </div>
    </div>
</div>