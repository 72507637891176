import { Esri, FeatureLayerProperties, ElevationInfo } from 'src/app/_esri/models/esri';
import { LayerIds } from 'src/app/_esri/models/layer-ids';

export class Game {

  public static GamePlay: FeatureLayerProperties = new FeatureLayerProperties(
    LayerIds.gisServer_Game, LayerIds.folder_Game, LayerIds.layerId_GamePlay, 'featureLayerGamePlay', 'GamePlay'
  );
  
  public static Game: FeatureLayerProperties = new FeatureLayerProperties(
    LayerIds.gisServer_GameTables, LayerIds.folder_Game, LayerIds.layerId_TblCourseGuide, 'tableCourseGuide', 'Course Guide'
  );

  public static GameGuide: FeatureLayerProperties = new FeatureLayerProperties(
    LayerIds.gisServer_GameTables, LayerIds.folder_Game, LayerIds.layerId_TblGameGuide, 'tableGameGuide', 'Game Guide'
  );

  public static createLayerPropertiesList(is3D: boolean): FeatureLayerProperties[] {
   
    let featureLayerProperties: FeatureLayerProperties[] = [];
    //let renderer;
    //renderer = new EsriRenderer_Polygon([0, 0, 0, 0.0], [225, 0, 0, 1.0], 2);

    // Set the elevation info if the layers are required to be shown in a scene
    if (is3D === true) {
        let elevationInfo = new ElevationInfo();
        elevationInfo.mode = 'relative-to-scene';
        Game.GamePlay.elevationInfo = elevationInfo;
        Game.GamePlay.returnM = true;
        Game.GamePlay.returnZ = true;
    }

    // Set the renderer / attributes and add the layers to a list
    Game.GamePlay.visible = true;
    Game.GamePlay.minScale = 10000;

    // Polylines	above-after, above-along, above-before, above-start, above-end, below-after, below-along, below-before, below-start, below-end, center-after, center-along, center-before, center-start, center-end

    let labelInfo = { 
      symbol: Esri.text12pxWithHalo,
      //labelPlacement: 'above-along',
      labelExpressionInfo: {
          expression: "IIf($feature.Distance > 10, Concatenate([Round($feature.Distance, 0), 'm'], ' '), '')"  
      },
      maxScale: 0,
      minScale: 2000,
      where: "1=1"
    };

    Game.GamePlay.labelsVisible = true;
    Game.GamePlay.labelingInfo = labelInfo;

    featureLayerProperties.push(Game.GamePlay);

    // Tables
    featureLayerProperties.push(Game.Game);
    featureLayerProperties.push(Game.GameGuide);

    return featureLayerProperties;
  }
}


