import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export interface ButtonClickData {
  formName: string
  button: string,
  event?: any,
}

@Injectable({
  providedIn: 'root'
})

export class ButtonClickService {
  public buttonClickData = new Subject();
  constructor() { }
}
