<!-- Buttons (show mini-fab buttons on small screens) -->
<div fxLayout='row' fxLayoutAlign='space-around center' class='button-row'>
    <!-- Add -->
    <div fxHide fxShow.gt-sm *ngIf='!addBtnHidden'>
        <button #addBtn mat-fab matTooltip='Add a new item' aria-label="Add icon button" (click)='addBtnSelected()' [disabled]='addBtnDisabled' >
            <mat-icon>add</mat-icon>     
        </button>
        <div fxHide fxShow.gt-md class='button-row-icon-text'>Add</div>
    </div>

    <button fxShow fxHide.gt-sm #addBtn mat-mini-fab matTooltip='Add a new item' aria-label="Add icon button" (click)='addBtnSelected()' [disabled]='addBtnDisabled' *ngIf='!addBtnHidden'>
        <mat-icon>add</mat-icon>     
    </button>

    <!-- Edit -->
    <div fxHide fxShow.gt-sm *ngIf='!editBtnHidden'>
        <button #editBtn mat-fab matTooltip='Edit an item' aria-label="Edit icon button" (click)='editBtnSelected()' [disabled]='editBtnDisabled'>
            <mat-icon>edit</mat-icon>     
        </button>
        <div fxHide fxShow.gt-md class='button-row-icon-text'>Edit</div>
    </div>

    <button fxShow fxHide.gt-sm #editBtn mat-mini-fab matTooltip='Edit an item' aria-label="Edit icon button" (click)='editBtnSelected()' [disabled]='editBtnDisabled' *ngIf='!editBtnHidden'>
        <mat-icon>edit</mat-icon>     
    </button>

    <!-- Save -->
    <div fxHide fxShow.gt-sm *ngIf='!saveBtnHidden'>
        <button #saveBtn mat-fab matTooltip='Save an item' aria-label="Save icon button" (click)='saveBtnSelected()' [disabled]='saveBtnDisabled'>
            <mat-icon>save</mat-icon>     
        </button>
        <div fxHide fxShow.gt-md class='button-row-icon-text'>Save</div>
    </div>

    <button fxShow fxHide.gt-sm #saveBtn mat-mini-fab matTooltip='Save an item' aria-label="Save icon button" (click)='saveBtnSelected()' [disabled]='saveBtnDisabled' *ngIf='!saveBtnHidden'>
        <mat-icon>save</mat-icon>     
    </button>

    <!-- Attachment -->
    <div fxHide fxShow.gt-sm *ngIf='!attachmentBtnHidden'>
        <button #attachmentBtn mat-fab matTooltip='Add an attachment to the item' aria-label="Attachment icon button" (click)='attachmentBtnSelected()' [disabled]='attachmentBtnDisabled'>
            <mat-icon>attach_file</mat-icon>     
        </button>
        <div fxHide fxShow.gt-md class='button-row-icon-text'>Attach</div>
    </div>

    <button fxShow fxHide.gt-sm #attachmentBtn mat-mini-fab matTooltip='Add an attachment to the item' aria-label="Attachment icon button" (click)='attachmentBtnSelected()' [disabled]='attachmentBtnDisabled' [hidden]='attachmentBtnHidden'>
        <mat-icon>attach_file</mat-icon>     
    </button>

    <!-- Delete -->
    <div fxHide fxShow.gt-sm *ngIf='!deleteBtnHidden'>
        <button #deleteBtn mat-fab matTooltip='Delete an item' aria-label="Attachment icon button" (click)='deleteBtnSelected()' [disabled]='deleteBtnDisabled'>
            <mat-icon>delete</mat-icon>     
        </button>
        <div fxHide fxShow.gt-md class='button-row-icon-text'>Delete</div>
    </div>

    <button fxShow fxHide.gt-sm #deleteBtn mat-mini-fab matTooltip='Delete an item' aria-label="Attachment icon button" (click)='deleteBtnSelected()' [disabled]='deleteBtnDisabled' *ngIf='!deleteBtnHidden'>
        <mat-icon>delete</mat-icon>     
    </button>

    <!-- Misc 1 -->
    <div fxHide fxShow.gt-sm *ngIf='!misc1BtnHidden'>
        <button #misc1Btn mat-fab matTooltip={{misc1Tooltip}} aria-label="Icon button" (click)='misc1BtnSelected()' [disabled]='misc1BtnDisabled'>
            <mat-icon>{{misc1Icon}}</mat-icon>     
        </button>
        <div fxHide fxShow.gt-md class='button-row-icon-text'>{{misc1Label}}</div>
    </div>

    <button fxShow fxHide.gt-sm #misc1Btn mat-mini-fab matTooltip={{misc1Tooltip}} aria-label="Icon button" (click)='misc1BtnSelected()' [disabled]='misc1BtnDisabled' *ngIf='!misc1BtnHidden'>
        <mat-icon>{{misc1Icon}}</mat-icon>     
    </button>

    <!-- Misc 2 -->
    <div fxHide fxShow.gt-sm *ngIf='!misc2BtnHidden'>
        <button #misc2Btn mat-fab matTooltip={{misc2Tooltip}} aria-label="Icon button" (click)='misc2BtnSelected()' [disabled]='misc2BtnDisabled'>
            <mat-icon>{{misc2Icon}}</mat-icon>     
        </button>
        <div fxHide fxShow.gt-md class='button-row-icon-text'>{{misc2Label}}</div>
    </div>

    <button fxShow fxHide.gt-sm #misc2Btn mat-mini-fab matTooltip={{misc2Tooltip}} aria-label="Icon button" (click)='misc2BtnSelected()' [disabled]='misc2BtnDisabled' *ngIf='!misc2BtnHidden'>
        <mat-icon>{{misc2Icon}}</mat-icon>     
    </button>

    <!-- Misc 3 -->
    <div fxHide fxShow.gt-sm *ngIf='!misc3BtnHidden'>
        <button #misc3Btn mat-fab matTooltip={{misc3Tooltip}} aria-label="Icon button" (click)='misc3BtnSelected()' [disabled]='misc3BtnDisabled'>
            <mat-icon>{{misc3Icon}}</mat-icon>     
        </button>
        <div fxHide fxShow.gt-md class='button-row-icon-text'>{{misc3Label}}</div>
    </div>

    <button fxShow fxHide.gt-sm #misc1Btn mat-mini-fab matTooltip={{misc3Tooltip}} aria-label="Icon button" (click)='misc3BtnSelected()' [disabled]='misc3BtnDisabled' *ngIf='!misc3BtnHidden'>
        <mat-icon>{{misc3Icon}}</mat-icon>     
    </button>


    <!-- Cancel -->
    <div fxHide fxShow.gt-sm *ngIf='!cancelBtnHidden'>
        <button #cancelBtn mat-fab matTooltip='Cancel the current action' aria-label="Cancel icon button" (click)='cancelBtnSelected()' [disabled]='cancelBtnDisabled'>
            <mat-icon>clear</mat-icon>     
        </button>
        <div fxHide fxShow.gt-md class='button-row-icon-text'>Cancel</div>
    </div>

    <button fxShow fxHide.gt-sm #cancelBtn mat-mini-fab matTooltip='Cancel the current action' aria-label="Cancel icon button" (click)='cancelBtnSelected()' [disabled]='cancelBtnDisabled' *ngIf='!cancelBtnHidden'>
        <mat-icon>clear</mat-icon>     
    </button>        

    <!-- Messages / Info -->
    <div #infoMessage class='status-message-container'>
        <span>{{statusMessage}}</span>
    </div>
</div> 





        <!-- <button #reportBtn mat-button matTooltip='' (click)='reportBtnEvent()' [disabled]=false>
            <mat-icon>clear</mat-icon>
            <div fxHide fxShow.gt-xs class='button-row-icon-text'>Report</div>
        </button> -->


          <!-- <button #addBtn mat-button matTooltip='Add a new item to the {{title}}' (click)='addBtnSelected()' [disabled]='addBtnDisabled' [hidden]='addBtnHidden' >
            <mat-icon>add</mat-icon>
            <div fxHide fxShow.gt-xs class='button-row-icon-text'>Add</div>
        </button> -->



          <!-- <div fxLayout='row' fxLayoutAlign='space-around center' class='add-new-workflow-box' [hidden]='locationHidden' >     
            <span>Does this risk have a location?</span>
            <span>
                <button mat-button (click)='setCurrentMode("location")'>
                    <mat-icon class='add-new-workflow-icon'>thumb_up</mat-icon>
                    <div fxHide fxShow.gt-xs class='add-new-workflow-icon-text'>Yes</div>
                </button>
                <button mat-button (click)='setCurrentMode("location-none")'>
                    <mat-icon class='add-new-workflow-icon'>thumb_down</mat-icon>
                    <div fxHide fxShow.gt-xs class='add-new-workflow-icon-text'>No</div>
                </button>
            </span>
        </div> -->

        <!-- <div fxLayout='row' fxLayoutAlign='space-around center' class='add-new-workflow-box' (click)='setCurrentMode("location-added")' [hidden]='addPointHidden' >
            <span>Select a point on the map...</span>
        </div> -->
  
        <!-- <div fxLayout='row' fxLayoutAlign='space-around center' class='add-new-workflow-box' [hidden]='addInfoHidden'>
            <span>Enter the details into the form above...</span>
        </div> -->
