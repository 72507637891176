import { Component, OnInit, OnDestroy, AfterViewInit, Input, Output, EventEmitter, ViewChild, ElementRef, HostListener, ChangeDetectorRef, ChangeDetectionStrategy } from "@angular/core";
import { combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { BaseFeaturePage } from 'src/app/_esri/models/base-feature-page';
import { FeaturesService } from 'src/app/_esri/services/features.service';
import { CourseService } from 'src/app/_course/services/course.service';
import { SurroundsService } from 'src/app/_course/services/surrounds.service';
import { Esri, DashboardChart, Address } from 'src/app/_esri/models/esri';
//import { AssetRegisterService } from 'src/app/asset-register/services/asset-register.service';
//import { Options, LabelType, ChangeContext } from '@angular-slider/ngx-slider';
import { Global } from 'src/app/_globals/models/global';
import { getTime, toDate, format } from 'date-fns'
import { CurrentModeService, CurrentModeData } from 'src/app/_globals/services/current-mode.service';
import { GeocodeDataService } from 'src/app/_globals/services/geocode-data.service';
import { FormChangeService } from 'src/app/_globals/services/form-change.service';
import { FormFieldChangeService } from 'src/app/_globals/services/form-field-change.service';
import { FormFieldUpdateService } from 'src/app/_globals/services/form-field-update.service';
import { Building, Footpath } from 'src/app/asset-register/models/asset-register';    // need to add the rest
import { DomainLovService } from 'src/app/_esri/services/domain-lov.service';
import { LovDataService } from 'src/app/_globals/services/lov-data.service';
import { ButtonClickService } from 'src/app/_globals/services/button-click.service';


@Component({
  selector: 'app-asset-cartpath',
  templateUrl: './asset-cartpath.component.html',
  styleUrls: [
    './asset-cartpath.component.scss',
    '../../../_styles/base-feature-page.scss'
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class AssetCartpathComponent extends BaseFeaturePage implements OnInit, OnDestroy, AfterViewInit {

  public title: string = 'Asset Register - Cart Paths';
  public subTitle: string = 'A register of assets';

  idType = 'ASSET';
  idTypeFieldName = 'AssetId';
  showTimeSliderWidget = true;
  //forceFieldVisibleInTable = true;

  showDataTable = true;
  showDataCards = false;
  //showBaseLayers = true;
  showBaseAssetLayers = false;
  darkMode = false;

  // courseLayerOpacity = 0.3;
  // surroundsLayerOpacity = 0.1;
  // boundaryLayerOpacity = 0.3;
  // hillshadeLayerOpacity = 0.05;

  popupMenuNumberItems: string = '0';
  popupMenuNumberItems_selectedFeature: string = '1';

  dashboardCharts: DashboardChart[] = [
    //{ chartName: 'CATEGORY' },
  ];

  //dateFilterAttributeName = 'IncidentDate';
  //parentLovWatchList = [{parentLovKey: 'IncidentCategoryId', childLovName: 'LovIncidentSubCategory', childLovKey: 'IncidentSubCategoryId'}];  // Add risk?
  fieldChangeWatchList = [];

  public assetLayerTitle: string = 'Cart Path';
  public assetLayerSubTitle: string = 'Existing';
  public assetLayerId: string = 'featureLayerFootpath';

  private _existingOnly: boolean = true;

  constructor(
    protected ref: ChangeDetectorRef,
    // protected featuresService: FeaturesService,
    // protected courseService: CourseService,
    // protected surroundsService: SurroundsService,
    protected dialog: MatDialog,
    protected currentModeService: CurrentModeService,
    protected geocodeDataService: GeocodeDataService,
    protected formChangeService: FormChangeService,
    protected formFieldChangeService: FormFieldChangeService,
    protected formFieldUpdateService: FormFieldUpdateService,
    // protected lovDataService: LovDataService,
    // protected domainLovService: DomainLovService,
    //protected buttonClickService: ButtonClickService,
    protected elementRef: ElementRef,
    // ----
    //private assetRegisterService: AssetRegisterService
  ) {
    super(
      ref,
      // featuresService, 
      // courseService, 
      // surroundsService,
      dialog,
      currentModeService,
      geocodeDataService, 
      formChangeService,
      formFieldChangeService,
      formFieldUpdateService,
      // lovDataService,
      // domainLovService,
      //buttonClickService,
      elementRef
    );
  }

  ngOnInit(): void {

    this.thinking = true;
    // Get WorkRequest layers, features, lovs, domains, etc
    //this.featureLayerObject = new Footpath();
    this.featureLayerObject = new Footpath();


    this.featureLayerObject.renderer = Esri.rendererActiveAsset_Polygon;

    super.ngOnInit();

  }

  protected refreshDisplay() {
    this.featureLayerObject.updateVirtualFields();
    super.refreshDisplay();
  }

    // Build data for charts
    protected createCharts() {
      //super.createCharts();
      //this.dashboardCharts.find(x => x.chartName == 'FAIRWAY').summaryData = this.createChartDataSet('DomFairway', 'FairwayNumber', true);
    }
  
    //
    // Workflow - Control button and editor behaviour based on current feature edit mode
    //
  
    protected setCurrentMode(currentModeData: CurrentModeData) {
      super.setCurrentMode(currentModeData);
  
      // Only process parent forms here.  Child forms (with names other than FORM) should be processed in extended class
      if (currentModeData.formName.toUpperCase() === 'FORM') {
  
        let mode = currentModeData.mode.toLowerCase();
  
        switch (mode) {
          case 'add':
          //this.esriMapComponent.locationHidden = true;
          //this.esriMapComponent.locationMap = true;
          //this.esriMapComponent.locationAddress = false;
  
          case 'add-popup':
            break;
  
          case 'location-map':
            //this.setFormFields_OnPremises('YES');
            break;
  
          case 'location-address':
            //this.setFormFields_OnPremises('NO');
            break;
  
          case 'location-selected':
          case 'location-changed':
            // Lookup the Fairway Number
            // this.featuresService.getAttributeWithinLayer(this.fairwayLayer, this.geometry, 'FairwayNumber').then( fairwayNumber => {
            //   console.log('fairwayNumber', fairwayNumber);
  
            //   if (fairwayNumber) {
            //     this.defaultFieldValues.push({
            //       name: 'FairwayNumber',
            //       value: fairwayNumber
            //     }); 
            //   }
            //   else {
            //     this.defaultFieldValues.push({
            //       name: 'FairwayNumber',
            //       value: null
            //     }); 
            //   }
  
            //   // Show form
            //   //this.openEditFeatureDialog('add', 'Create a new item');
            //   if (mode === 'location-changed') { 
            //     // Mark the form dirty
            //     console.log('make dirty location-changed', event);
            //     this.openEditFeatureDialog(mode, true);
            //   }
            //   else {
            //     this.openEditFeatureDialog(mode);
            //   }
            // });
  
            // Hide the workflow box
            this.esriMapComponent.locationMap = false;
            this.esriMapComponent.locationAddress = false;
            this.esriMapComponent.locationNoneChoice = false;
  
            break;
  
          case 'edit-attribute':
            this.openEditFeatureDialog(mode);
            break;
  
          case 'form-submitted':
            break;
        }
  
      }
  
  
    }
  
    // Form Changes
    public featureFormFieldChangeEvent(event) {
      super.featureFormFieldChangeEvent(event);
    }
  
    //
    // Query
    //
  
    // Query / Filters
  
    public featureLayerIdSelectedEvent(value) {
      // if (value === 'All') {
      //   this._whereClause = "1=1";
      // }
      // else {
      //   this._whereClause = "ResidualRisk_Rating = '" + value + "'";
      // }
  
      this.assetLayerId = value; //event.value;
      //this.queryFeatureFromLayer();
    }
  
    public assetStatusSelectedEvent(value) {
  
      //   if (value === 'ALL') {
      //     this._whereClause = '1=1';
      //   }
      //   else {
      //     this._whereClause = "Status = '" + value.toUpperCase() + "'";
      //   }
  
  
      //   //this.assetLayerSubTitle = value;
      //   //this.assetStatus = value.toUpperCase(); //event.value;
      //   this.queryFeatureFromLayer();
    }



}
