import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogData {
  url: string,
  title: string //,
  // width: string,
  // height: string
}

@Component({
  selector: 'app-game-info-video',
  templateUrl: './game-info-video.component.html',
  styleUrls: ['./game-info-video.component.scss']
})
export class GameInfoVideoComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  ngOnInit(): void {
  }

}
