import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, Injectable, ViewChild, ElementRef } from '@angular/core';
import { Esri, CameraSettings } from '../../models/esri';
import { LayerIds } from 'src/app/_esri/models/layer-ids';
import { setDefaultOptions, loadModules } from 'esri-loader';

@Component({
  selector: 'app-esri-scene',
  templateUrl: './esri-scene.component.html',
  styleUrls: ['./esri-scene.component.scss']
})

@Injectable({
  providedIn: 'root'
})

export class EsriSceneComponent implements OnInit, OnDestroy{


  @Output() sceneLoadedEvent = new EventEmitter<boolean>();
  @Output() viewCreatedEvent = new EventEmitter<any>();
  @Output() searchEvent = new EventEmitter<any>();

  // The <div> where we will place the map
  @ViewChild('divScene', { static: true }) private sceneViewEl: ElementRef;
  @ViewChild('divBookmark', { static: true }) private bookmarkEl: ElementRef;

  // The Lakes : 151.210686, -33.933950
  //private _center: Array<number> = GlobalValues._center;
  private _center: Array<number> = [Esri.homeCameraSettings.longitude, Esri.homeCameraSettings.latitude];
  private _heading: number = Esri.home2dCameraSettings.heading;
  private _elevation: number = Esri.homeCameraSettings.z;
  private _tilt: number = Esri.homeCameraSettings.tilt;

  private _initialBasemapName: string = Esri.initialBasemapName; 
  private _toggleBasemapName: string = Esri.toggleBasemapName;

  private _loaded: boolean = false;
  private _view = null; 
  private _showHomeWidget: boolean = true;
  private _showSearchWidget: boolean = false;
  private _showFullscreenWidget: boolean = false;
  private _showDaylightWidget: boolean = true;
  private _showLayerListWidget: boolean = false;
  private _showSettingsWidget: boolean = false;
  private _showBasemapToggleWidget: boolean = true;
  private _showBasemapGalleryWidget: boolean = true;
  private _showZoomWidget: boolean = true;
  private _showNavigationToggleWidget: boolean = true;
  private _showCompassWidget: boolean = true;
  private _showBookmarkWidget: boolean = true;
  private _showLogo: boolean = false;
  private _widgetPosition: string = 'top-right';
  private _searchPosition: string = 'top-left';
  private _layerListPosition: string = 'bottom-right';
  private _worldElevationUrl: string = Esri.worldElevationUrl;
  private _customElevationUrl: string = LayerIds.customElevationUrl;
  private _webSceneId: string =  LayerIds.portal_BaseScene;
  private _sceneDate: Date = Esri.sceneDate; // new Date('February 10, 2019 07:00:00');
  private _showSceneNow: boolean = false;

  private _atmosphereColour: any = [90, 125, 195, 1.0];
  private _groundSurfaceColour: any = [165, 200, 100];
  private _groundSurfaceOpacity: number = 1.0;

  // Scene quality
  private _directShadowsEnabled: boolean = true;
  private _waterReflectionEnabled: boolean = false;
  private _starsEnabled: boolean = true;
  private _atmosphereEnabled: boolean = true;
  private _atmosphereQuality: string = 'high';        // "low" | "high"
  private _qualityProfile: string = 'high';           // "low" | "medium" | "high"

  @ViewChild("scene", { static: true }) private sceneEl: ElementRef;
  @ViewChild("logo", { static: true }) private logoEl: ElementRef;
  @ViewChild("logoCustomer", { static: true }) private logoCustomerEl: ElementRef;

  public bookmarks: any = [];

  get sceneLoaded(): boolean {
    return this._loaded;
  }

  @Input()
  set center(center: Array<number>) {
    this._center = center;
  }

  get center(): Array<number> {
    return this._center;
  }

  @Input()
  set basemapName(basemapName: string) {
    this._initialBasemapName = basemapName;
  }

  get basemapName(): string {
    return this._initialBasemapName;
  }

  @Input()
  set toggleBasemapName(toggleBasemapName: string) {
    this._toggleBasemapName = toggleBasemapName;
  }

  get toggleBasemapName(): string {
    return this._toggleBasemapName;
  }

  @Input()
  set showHomeWidget(showHomeWidget: boolean) {
    this._showHomeWidget = showHomeWidget;
  }

  get showHomeWidget(): boolean {
    return this._showHomeWidget;
  }

  @Input()
  set showSearchWidget(showSearchWidget: boolean) {
    this._showSearchWidget = showSearchWidget;
  }

  get showSearchWidget(): boolean {
    return this._showSearchWidget;
  }
  
  @Input()
  set showFullscreenWidget(showFullscreenWidget: boolean) {
    this._showFullscreenWidget = showFullscreenWidget;
  }

  get showFullscreenWidget(): boolean {
    return this._showFullscreenWidget;
  }

  @Input()
  set showBasemapToggleWidget(showBasemapToggleWidget: boolean) {
    this._showBasemapToggleWidget = showBasemapToggleWidget;
  }
  
  get showBasemapToggleWidget(): boolean {
    return this._showBasemapToggleWidget;
  }

  @Input()
  set showBasemapGalleryWidget(showBasemapGalleryWidget: boolean) {
    this._showBasemapGalleryWidget = showBasemapGalleryWidget;
  }

  get showBasemapGalleryWidget(): boolean {
    return this._showBasemapGalleryWidget;
  }
  
  @Input()
  set showZoomWidget(showZoomWidget: boolean) {
    this._showZoomWidget = showZoomWidget;
  }
  
  get showZoomWidget(): boolean {
    return this._showZoomWidget;
  }
  
  @Input()
  set showNavigationToggleWidget(showNavigationToggleWidget: boolean) {
    this._showNavigationToggleWidget = showNavigationToggleWidget;
  }
  
  get showNavigationToggleWidget(): boolean {
    return this._showNavigationToggleWidget;
  }
  
  @Input()
  set showCompassWidget(showCompassWidget: boolean) {
    this._showCompassWidget = showCompassWidget;
  }
  
  get showCompassWidget(): boolean {
    return this._showCompassWidget;
  }
  
  @Input()
  set showBookmarkWidget(showBookmarkWidget: boolean) {
    this._showBookmarkWidget = showBookmarkWidget;
  }
  
  get showBookmarkWidget(): boolean {
    return this._showBookmarkWidget;
  }

  @Input()
  set showSettingsWidget(showSettingsWidget: boolean) {
    this._showSettingsWidget = showSettingsWidget;
  }

  get showSettingsWidget(): boolean {
    return this._showSettingsWidget;
  }

  @Input()
  set showLogo(showLogo: boolean) {
    this._showLogo = showLogo;
  }

  get showLogo(): boolean {
    return this._showLogo;
  }

  @Input()
  set widgetPosition(widgetPosition: string) {
    this._widgetPosition = widgetPosition;
  }

  get widgetPosition(): string {
    return this._widgetPosition;
  }

  @Input()
  set searchPosition(searchPosition: string) {
    this._searchPosition = searchPosition;
  }

  get searchPosition(): string {
    return this._searchPosition;
  }

  @Input()
  set directShadowsEnabled(directShadowsEnabled: boolean) {
    this._directShadowsEnabled = directShadowsEnabled;
  }

  get directShadowsEnabled(): boolean {
    return this._directShadowsEnabled;
  }

  @Input()
  set waterReflectionEnabled(waterReflectionEnabled: boolean) {
    this._waterReflectionEnabled = waterReflectionEnabled;
  }

  get waterReflectionEnabled(): boolean {
    return this._waterReflectionEnabled;
  }

  @Input()
  set starsEnabled(starsEnabled: boolean) {
    this._starsEnabled = starsEnabled;
  }

  get starsEnabled(): boolean {
    return this._starsEnabled;
  }

  @Input()
  set atmosphereEnabled(atmosphereEnabled: boolean) {
    this._atmosphereEnabled = atmosphereEnabled;
  }

  get atmosphereEnabled(): boolean {
    return this._atmosphereEnabled;
  }

  @Input()
  set atmosphereQuality(atmosphereQuality: string) {
    this._atmosphereQuality = atmosphereQuality;
  }

  get atmosphereQuality(): string {
    return this._atmosphereQuality;
  }

  @Input()
  set qualityProfile(qualityProfile: string) {
    this._qualityProfile = qualityProfile;
  }

  get qualityProfile(): string {
    return this._qualityProfile;
  }

  @Input()
  set view(view: any) {
    this._view = view;
  }

  get view(): any {
    return this._view;
  }

  public async initializeMap() {
    try {

      const options = {
        version: Esri.apiVersion
      };
    
      setDefaultOptions(options);

      const [
        WebScene,
        Ground,
        Camera,        
        Point,
        SceneView,
        BasemapToggle,
        BasemapGallery,
        Home,
        Fullscreen,
        Daylight,
        Expand,
        LayerList,
        ElevationLayer
      ] = await loadModules([
        'esri/WebScene', 
        'esri/Ground',
        'esri/Camera',
        'esri/geometry/Point',
        'esri/views/SceneView', 
        'esri/widgets/BasemapToggle',
        'esri/widgets/BasemapGallery',
        'esri/widgets/Home',
        'esri/widgets/Fullscreen',
        'esri/widgets/Daylight',
        'esri/widgets/Expand',
        'esri/widgets/LayerList',
        'esri/layers/ElevationLayer'
      ]);

      //
      // 3D Scene and View
      //

      // GlobalValues.lambertCentreCameraSettings

      // Subject of Interest Location
      var position = new Point( {
        longitude: this._center[0],
        latitude: this._center[1],
        z: this._elevation
      });

      // go to a location defined by a Camera object
      var camera = new Camera({
        position: position,
        heading: this._heading,
        tilt: this._tilt
      });

      // Create a map with the world elevation layer overlaid by a custom elevation layer
      let worldElevation = new ElevationLayer({
        url: this._worldElevationUrl
      });

      let customElevation = new ElevationLayer({
        url: this._customElevationUrl 
      });
      
      let scene = new WebScene({
        portalItem: {
          id: this._webSceneId
        },
        ground: new Ground({
          layers: [ worldElevation, customElevation ]//,
          //surfaceColor: this._groundSurfaceColour,
          //opacity: this._groundSurfaceOpacity
        })    
      });

      let basemap = await Esri.getBasemap(this._initialBasemapName);

      if (basemap) {
        scene.basemap = basemap;
      }

      let lighting;

      lighting = {
        directShadowsEnabled: this._directShadowsEnabled,
        waterReflectionEnabled: this._waterReflectionEnabled
      }

      if (this._showSceneNow) {
        lighting.date = Date.now();
      }
      else {
        lighting.date = this._sceneDate;
      }

      let environment;

      environment = {
        lighting: lighting,
        starsEnabled: this._starsEnabled,
        atmosphereEnabled: this._atmosphereEnabled,
        atmosphere: {
          quality: this._atmosphereQuality
        }
      }

      if (!this._atmosphereEnabled) {
        let background;

        background = {
          type: "color",
          color: this._atmosphereColour
        }

        environment.background = background;
      }

      const sceneViewProperties = {
        container: this.sceneEl.nativeElement,
        camera: camera,
        qualityProfile: this._qualityProfile,
        environment: environment,
        map: scene
      };

      this._view = new SceneView(sceneViewProperties);
      //this._view.goTo(camera);

      // Logos

      if (this._showLogo) {
        this._view.ui.add([
          {
            component: this.logoEl.nativeElement,
            //position: 'top-right',
            position: 'bottom-right',
            index: 0
          }
        ]);
      }

      // this._view.ui.add([
      //   {
      //     component: this.logoCustomerEl.nativeElement,
      //     position: 'top-right',
      //     index: 1
      //   }
      // ]);

      //
      // Widgets
      //

      // Home Widget
      if (this._showHomeWidget) {
        const home = new Home({
          view: this._view
        });

        this._view.ui.add([
          {
            component: home,
            position: this._widgetPosition,
            index: 1
          }
        ]);
      }

      // Fullscreen Widget
      if (this._showFullscreenWidget) {
        // Create the fullscreen widget
        const fullscreenWidget = new Fullscreen({
          view: this._view
        });

        // Add it to the view
        this._view.ui.add([
          {
            component: fullscreenWidget,
            position: this._widgetPosition,
            index: 2
          }
        ]);
      }

      // Basemap Toggle Widget
      if (this._showBasemapToggleWidget) {
        let toggleBasemap = await Esri.getBasemap(this._toggleBasemapName);

        const basemapToggleWidget = new BasemapToggle({
          view: this._view,
          nextBasemap: toggleBasemap 
        });

        // Add it to the view
        this._view.ui.add([
          {
            component: basemapToggleWidget,
            position: this._widgetPosition,
            index: 3
          }
        ]);
      }

      // Basemap Gallery Widget   
      if (this._showBasemapGalleryWidget) {

        const basemapGalleryWidget = new BasemapGallery({
          view: this._view 
        });

        const galleryExpand = new Expand({
          view: this._view,
          label: 'Basemaps',
          expandTooltip: 'Basemaps',
          expandIconClass: "esri-icon-basemap",
          autoCollapse: true,
          content: basemapGalleryWidget
        });

        // Add it to the view
        this._view.ui.add([
          {
            component: galleryExpand,
            position: this._widgetPosition,
            index: 4
          }
        ]);

        // Think about saving the last selected basemap to local storage - can then always have the user's preferrred basemap
        basemapGalleryWidget.on("click", (event) => {
          console.log(event);
        });
      }

      // Daylight Widget
      if (this._showDaylightWidget) {
        // Create the fullscreen widget
        const daylightWidget = new Daylight({
          view: this._view,
          visibleElements: {
            timezone: false,
            datePicker: true,
            playButtons: false,
            shadowsToggle: false
          }
        });

        // Show it in an expand widget
        const expandDaylightWidget = new Expand({
          expandIconClass: 'esri-icon-environment-settings',
          view: this._view,
          content: daylightWidget
        });

        // Add it to the view
        this._view.ui.add([
          {
            component: expandDaylightWidget,
            position: this._widgetPosition,
            index: 5
          }
        ]);
      }

      // LayerList Widget
      if (this._showLayerListWidget) {
        // Create the LayerList widget
        const layerListWidget = new LayerList({
          view: this._view
        });

        // Add it to the view
        this._view.ui.add([
          {
            component: layerListWidget,
            position: this._layerListPosition,
            index: 6
          }
        ]);
      }

      // Zoom Widget
      if (this._showZoomWidget) {
        // Move the zoom widget to be on the same side as the other widgets
        if (this._widgetPosition != 'top-left') {
          this._view.ui.move('zoom', this._widgetPosition);
        } 
      }
      else {
        // Widget is added by default, so remove the widget
        this._view.ui.remove('zoom');
      }

      // Navigation Widget
      if (this._showNavigationToggleWidget) {
        // Move the zoom widget to be on the same side as the other widgets
        if (this._widgetPosition != 'top-left') {
          this._view.ui.move('navigation-toggle', this._widgetPosition);
        } 
      }
      else {
        // Widget is added by default, so remove the widget
        this._view.ui.remove('navigation-toggle');
      }

      // Compass Widget
      if (this._showCompassWidget) {
        // Move the zoom widget to be on the same side as the other widgets
        if (this._widgetPosition != 'top-left') {
          this._view.ui.move('compass', this._widgetPosition);
        } 
      }
      else {
        // Widget is added by default, so remove the widget
        this._view.ui.remove('compass');
      }
      
      // // Move the zoom widget to be on the same side as the other widgets
      // if (this._widgetPosition != 'top-left') {
      //   this._view.ui.move('zoom', this._widgetPosition);
      //   this._view.ui.move('navigation-toggle', this._widgetPosition);
      //   this._view.ui.move('compass', this._widgetPosition);
      // } 

      // Bookmark Widget
      if (this._showBookmarkWidget) {
        // Generate and add the slides (bookmarks) to the view
        let bookmarkSlidesWidget;

        await Esri.createSceneSlides().then((presentation) => {
          //console.log('createSceneSlides then');
          this._view.presentation = presentation;
          //console.log(this._view.presentation);

          bookmarkSlidesWidget = new Expand({
            expandIconClass: 'esri-icon-bookmark',
            view: this._view,
            content: this.bookmarkEl.nativeElement
          });
    
          this._view.ui.add([
            {
              component: bookmarkSlidesWidget,
              position: this._widgetPosition,
              index: 7
            }
          ]);
        });
      }

    //});


      //
      // wait for the map / scene to load
      //
      await this._view.when(); 
      //return this._view;
      return this.view;

    
      
    } 
    catch (error) {
      console.error('Scene Loader: ', error);
    }
  }

  public goToSlide(camera) {
    //console.log("slides");
    this.view.goTo(camera); 
  }

  public async loadScene() {
    // Initialize MapView and return an instance of MapView

    this.initializeMap().then((sceneView) => {
      // The map has been initialized
      console.log('sceneView ready: ', sceneView.ready);

      this._loaded = sceneView.ready;
      this.sceneLoadedEvent.emit(true);

      // Send the scene back as well
      this.viewCreatedEvent.emit(sceneView);

      return; // sceneView;
    });
  }

  constructor() { }

  ngOnInit() {
    this.loadScene();
  }

  ngOnDestroy() {
    // destroy the map view
    if (this._view) { 
      this._view.container = null;
      console.log('Scene View destroyed');
    }
  }
}
