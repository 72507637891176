import { Injectable, Output, EventEmitter } from '@angular/core';
import { combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Esri, FeatureLayerProperties, ElevationInfo, EsriRenderer_Point, FormFieldConfig, FieldConfig, FieldDateFormat, FieldNumberFormat, GeometryPoint } from 'src/app/_esri/models/esri';
import { LayerIds } from 'src/app/_esri/models/layer-ids';
import { Global } from 'src/app/_globals/models/global';
import { LovDataService } from 'src/app/_globals/services/lov-data.service';
import { DomainLovService } from 'src/app/_esri/services/domain-lov.service';
import { FeatureLayerObjectBaseAttributes, FeatureLayerObjectBase } from 'src/app/_esri/models/base-feature-class';

// Feature Layers

export class AuditAttributes extends FeatureLayerObjectBaseAttributes {

  public AuditId: string;
  public AuditItemNumber: number;
  public AuditItemTitle: string;
  public FairwayNumber: number;
  public AuditCoursePart: string;
  public AuditCoursePart_other: string;
  public AuditItemTypeId : string;
  public AuditItemTypeId_other: string;
  public Description: string;
  public Suggestion: string;
  public AuditCategoryId: string;
  public AuditCategoryId_other: string;
  public WorkTypeId: string;
  public EstimatedCost: number;
  public RecommendationType: string;
  public RecommendationType_other: string;
  public Recommendation: string;
  public Reference: string;
  public AuditReport: string;
  public AuditDate: Date;
  public AuditConductedBy: string;
  public Comments: string;
  public Priority: number;
  public WorkRequestNumber: string;
  public CompletedDate: Date;
  public Status: string;
  
  constructor(
    protected lovDataService: LovDataService,
    protected domainLovService: DomainLovService
  ) {
    super(
      lovDataService,
      domainLovService
    );
  }

  // Virtual Fields

  get CoursePart(): string {
    if (this.AuditCoursePart) {

      return this.domainLovService.getDomainValue('DomAuditCoursePart', this.AuditCoursePart);
    }
    else {
      return null;
    }
  }
  
  get ItemType(): string {
    if (this.AuditItemTypeId) {

      return this.domainLovService.getDomainValue('DomAuditItemType', this.AuditItemTypeId);
    }
    else {
      return null;
    }
  }

  get Category(): string {
    if (this.AuditCategoryId) {

      return this.domainLovService.getDomainValue('DomAuditCategory', this.AuditCategoryId);
    }
    else {
      return null;
    }
  }

  get WorkType(): string {
    if (this.WorkTypeId) {

      return this.domainLovService.getDomainValue('DomWorkType', this.WorkTypeId);
    }
    else {
      return null;
    }
  }

  get RecommendationType_DomainDesc(): string {
    if (this.RecommendationType) {

      return this.domainLovService.getDomainValue('DomRecommendationType', this.RecommendationType);
    }
    else {
      return null;
    }
  }

  get AuditReport_DomainDesc(): string {
    if (this.AuditReport) {

      return this.domainLovService.getDomainValue('DomAuditReport', this.AuditReport);
    }
    else {
      return null;
    }
  }

  get CourseAuditor(): string {
    if (this.AuditConductedBy) {

      return this.domainLovService.getDomainValue('DomCourseAuditor', this.AuditConductedBy);
    }
    else {
      return null;
    }
  }

  get Priority_DomainDesc() {
    if (this.Priority) {
      return this.domainLovService.getDomainValue('DomPriority', this.Priority);
    }
    else {
      return null;
    }
  }

  get Status_DomainDesc() {
    if (this.Status) {
      return this.domainLovService.getDomainValue('DomStatus', this.Status);
    }
    else {
      return null;
    }
  }

  get Fairway_DomainDesc() {
    if (this.FairwayNumber) {
      return this.domainLovService.getDomainValue('DomFairway', this.FairwayNumber);
    }
    else {
      return null;
    }
  }
}

@Injectable()
export class Audit extends FeatureLayerObjectBase {
  attributes: AuditAttributes[];
  server = LayerIds.gisServer_Audit;
  folder = LayerIds.folder_Audit;
  layerId = LayerIds.layerId_Audit;
  id = 'featureLayerAuditRegister';
  title = 'Audit Register';

  minScale = 10000;
  whereClause = "1=1";
  orderByClause = 'OBJECTID DESC'; // (CreationDate doesn't exist yet on the service)

  labelInfo = {
    //symbol: Esri.text12pxWithHalo,
    symbol: Esri.text12px,
    labelExpressionInfo: {
      //expression: "$feature.AuditItemNumber + ' - ' + $feature.AuditItemTitle"  
      //expression: "$feature.AuditItemNumber"  
      expression: "$feature.AuditItemTitle + ' (' + $feature.AuditItemNumber + ')'"
    },
    maxScale: 0,
    minScale: 4000
    //where: "$feature.HasLocation = 'YES'"
  };

  //     this.legendLayerInfos = [{
  //       layer: this.featureLayer,
  //       title: "Legend"
  //     }]

  labelsVisible = true;
  labelingInfo = this.labelInfo;
  // popupEnabled = true;
  // legendEnabled = true;

  idType = 'AUDIT';
  idTypeFieldName = 'AuditId';
  fieldChangeWatchList = [];
  parentLovWatchList = [];


  // @Output() categoryLovReadyEvent = new EventEmitter<any>();
  // @Output() subCategoryLovReadyEvent = new EventEmitter<any>();
  // @Output() reportedByLovReadyEvent = new EventEmitter<any>();
  // @Output() staffLovReadyEvent = new EventEmitter<any>();

  protected async createFormFieldConfigs(): Promise<FormFieldConfig[]> {
    let formFieldConfig: FormFieldConfig;
    let fieldConfigs: FieldConfig[] = [];
    let fieldConfig: FieldConfig;
    let format: any;

    // Item

    fieldConfigs = [];

    fieldConfig = new FieldConfig('AuditId', 'Audit Id:', 'text');
    fieldConfig.visible = false;
    fieldConfig.required = false;
    fieldConfig.readOnly = true;
    fieldConfig.maxLength = 20;
    fieldConfig.includeInTable = false;
    fieldConfig.includeInTableIndex = 1;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('AuditItemNumber', 'Item No:', 'number');
    fieldConfig.includeInTable = true;
    fieldConfig.includeInTableIndex = 0;
    fieldConfig.includeInCard = true;
    fieldConfig.includeInCard_Content_Row1 = true;
    fieldConfig.includeInCard_Index = 0;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('AuditItemTitle', 'Item Title:', 'text');
    fieldConfig.maxLength = 200;
    fieldConfig.required = true;
    fieldConfig.includeInTable = true;
    fieldConfig.includeInTableIndex = 4;
    fieldConfig.includeInCard = true;
    fieldConfig.includeInCard_Title = true;
    fieldConfig.includeInCard_Index = 1;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('FairwayNumber', 'Fairway Number:', 'number');
    fieldConfig.domain = 'DomFairway';
    fieldConfig.domain_VirtualFieldName = 'Fairway_DomainDesc';
    fieldConfig.includeInTable = true;
    fieldConfig.includeInTableIndex = 2;
    fieldConfig.includeInCard = true;
    fieldConfig.includeInCard_Content_Row1 = true;
    fieldConfig.includeInCard_Index = 1;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('AuditCoursePart', 'Course Part:', 'text');
    //fieldConfig.maxLength = 20;
    fieldConfig.includeInTable = true;
    fieldConfig.includeInTableIndex = 3;
    fieldConfig.includeInCard = true;
    fieldConfig.includeInCard_Content_Row1 = true;
    fieldConfig.includeInCard_Index = 2;
    fieldConfig.domain = 'DomAuditCoursePart';
    fieldConfig.domain_VirtualFieldName = 'CoursePart';
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('AuditCoursePart_other', 'Course Part (other):', 'text');
    fieldConfig.maxLength = 255;
    //fieldConfig.includeInTable = true;
    //fieldConfig.includeInTableIndex = 2;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('AuditItemTypeId', 'Audit Item Type:', 'text');
    fieldConfig.domain = 'DomAuditItemType';
    fieldConfig.domain_VirtualFieldName = 'ItemType';
    fieldConfig.visible = false;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('AuditItemTypeId_other', 'Audit Item Type (other):', 'text');
    fieldConfig.maxLength = 255;
    fieldConfig.visible = false;
    fieldConfigs.push(fieldConfig);

    formFieldConfig = new FormFieldConfig('Item', fieldConfigs);
    this.formFieldConfigs.push(formFieldConfig);

    // Description

    fieldConfigs = [];

    fieldConfig = new FieldConfig('Description', 'Description:', 'text-area');
    fieldConfig.includeInTable = true;
    fieldConfig.includeInTableIndex = 5;
    fieldConfig.maxLength = 5000;
    fieldConfig.includeInCard = true;
    fieldConfig.includeInCard_SubTitle = true;
    fieldConfig.includeInCard_Index = 0;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('Suggestion', 'Suggestions:', 'text-area');
    fieldConfig.maxLength = 5000;
    fieldConfig.includeInCard = true;
    fieldConfig.includeInCard_Content_Row3 = true;
    fieldConfig.includeInCard_Index = 0;
    fieldConfigs.push(fieldConfig);

    //fieldConfig = new FieldConfig('AuditCategoryId', 'Audit Category:', 'text');
    fieldConfig = new FieldConfig('AuditCategoryId', 'Audit Category:', 'radio-v');
    //fieldConfig.maxLength = 20;
    fieldConfig.includeInTable = true;
    fieldConfig.includeInTableIndex = 6;
    fieldConfig.includeInCard = true;
    fieldConfig.includeInCard_Content_Row2 = true;
    fieldConfig.includeInCard_Index = 0;
    fieldConfig.domain = 'DomAuditCategory';
    fieldConfig.domain_VirtualFieldName = 'Category';
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('AuditCategoryId_other', 'Audit Category (other):', 'text');
    fieldConfig.maxLength = 255;
    // fieldConfig.includeInTable = true;
    // fieldConfig.includeInTableIndex = 1;
    fieldConfigs.push(fieldConfig);

    formFieldConfig = new FormFieldConfig('Description', fieldConfigs);
    this.formFieldConfigs.push(formFieldConfig);

    // Recommendation

    fieldConfigs = [];

    fieldConfig = new FieldConfig('WorkTypeId', 'Work Program:', 'text');
    //fieldConfig.maxLength = 20;
    fieldConfig.includeInTable = true;
    fieldConfig.includeInTableIndex = 7;
    fieldConfig.includeInCard = true;
    fieldConfig.includeInCard_Content_Row2 = true;
    fieldConfig.includeInCard_Index = 1;
    fieldConfig.domain = 'DomWorkType';
    fieldConfig.domain_VirtualFieldName = 'WorkType';
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('EstimatedCost', 'Estimated Cost:', 'number');
    format = new FieldNumberFormat(2);
    fieldConfig.prefix = '$';
    fieldConfig.format = format;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('RecommendationType', 'Recommendation:', 'text');
    //fieldConfig.maxLength = 30;
    fieldConfig.domain = 'DomRecommendationType';
    fieldConfig.domain_VirtualFieldName = 'RecommendationType_DomainDesc';
    fieldConfig.visible = false;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('RecommendationType_other', 'Recommendation (other):', 'text');
    fieldConfig.maxLength = 255;
    fieldConfig.visible = false;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('Recommendation', 'Recommendation Details:', 'text-area');
    fieldConfig.maxLength = 5000;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('Reference', 'Reference:', 'text');
    fieldConfig.maxLength = 5000;
    fieldConfig.visible = false;
    fieldConfigs.push(fieldConfig);

    formFieldConfig = new FormFieldConfig('Recommendation', fieldConfigs);
    this.formFieldConfigs.push(formFieldConfig);

    // Auditor

    fieldConfigs = [];

    fieldConfig = new FieldConfig('AuditReport', 'Audit Report:', 'text');
    //fieldConfig.maxLength = 20;
    fieldConfig.domain = 'DomAuditReport';
    fieldConfig.domain_VirtualFieldName = 'AuditReport_DomainDesc';
    fieldConfig.visible = false;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('AuditDate', 'Audit Date:', 'date');
    format = new FieldDateFormat();
    fieldConfig.format = format;
    fieldConfig.visible = true;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('AuditConductedBy', 'Audit Conducted By:', 'radio-v');
    //fieldConfig.maxLength = 20;
    fieldConfig.domain = 'DomCourseAuditor';
    fieldConfig.domain_VirtualFieldName = 'CourseAuditor';
    fieldConfig.visible = false;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('Comments', 'Comments:', 'text-area');
    fieldConfig.maxLength = 1000;
    fieldConfig.visible = false;
    fieldConfigs.push(fieldConfig);

    formFieldConfig = new FormFieldConfig('Auditor', fieldConfigs);
    this.formFieldConfigs.push(formFieldConfig);

    // Standard (and hidden)

    fieldConfigs = [];

    fieldConfig = new FieldConfig('Priority', 'Priority:', 'number');
    fieldConfig.domain = 'DomPriority';
    fieldConfig.domain_VirtualFieldName = 'Priority_DomainDesc';
    fieldConfig.visible = false;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('WorkRequestNumber', 'Work Request Number:', 'text');
    fieldConfig.maxLength = 30;
    fieldConfig.visible = false;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('CompletedDate', 'Completed Date:', 'date');
    format = new FieldDateFormat();
    fieldConfig.format = format;
    fieldConfig.visible = false;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('Status', 'Status:', 'text');
    fieldConfig.maxLength = 20;
    fieldConfig.domain = 'DomStatus';
    fieldConfig.domain_VirtualFieldName = 'Status_DomainDesc'
    fieldConfig.visible = false;
    fieldConfigs.push(fieldConfig);

    formFieldConfig = new FormFieldConfig('Hidden', fieldConfigs);
    formFieldConfig.visible = false;
    this.formFieldConfigs.push(formFieldConfig);


    // Virtual (and hidden)

    fieldConfigs = [];

    fieldConfig = new FieldConfig('CoursePart', 'CoursePart:', 'text');
    fieldConfig.readOnly = true;
    fieldConfig.visible = false;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('ItemType', 'ItemType:', 'text');
    fieldConfig.readOnly = true;
    fieldConfig.visible = false;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('Category', 'Category:', 'text');
    fieldConfig.readOnly = true;
    fieldConfig.visible = false;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('WorkType', 'WorkType:', 'text');
    fieldConfig.readOnly = true;
    fieldConfig.visible = false;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('RecommendationType_DomainDesc', 'RecommendationType:', 'text');
    fieldConfig.readOnly = true;
    fieldConfig.visible = false;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('AuditReport', 'AuditReport:', 'text');
    fieldConfig.readOnly = true;
    fieldConfig.visible = false;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('CourseAuditor', 'CourseAuditor:', 'text');
    fieldConfig.readOnly = true;
    fieldConfig.visible = false;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('Priority_DomainDesc', 'Priority:', 'text');
    fieldConfig.readOnly = true;
    fieldConfig.visible = false;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('Status_DomainDesc', 'Status:', 'text');
    fieldConfig.readOnly = true;
    fieldConfig.visible = false;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('Fairway_DomainDesc', 'Fairway:', 'text');
    fieldConfig.readOnly = true;
    fieldConfig.visible = false;
    fieldConfigs.push(fieldConfig);

    formFieldConfig = new FormFieldConfig('Virtual', fieldConfigs);
    this.formFieldConfigs.push(formFieldConfig);

    super.createFormFieldConfigs();

    return;
  }

  protected updateVirtualFields() {
    // Reset
    this.attributes = [];

    this.features.forEach((f) => {
      let attr: AuditAttributes = new AuditAttributes(this.lovDataService, this.domainLovService);

      attr.AuditId = f.attributes.AuditId;
      attr.AuditItemNumber = f.attributes.AuditItemNumber;
      attr.AuditItemTitle = f.attributes.AuditItemTitle;
      attr.FairwayNumber = f.attributes.FairwayNumber;
      attr.AuditCoursePart = f.attributes.AuditCoursePart;
      attr.AuditCoursePart_other = f.attributes.AuditCoursePart_other;
      attr.AuditItemTypeId  = f.attributes.AuditItemTypeId;
      attr.AuditItemTypeId_other = f.attributes.AuditItemTypeId_other;
      attr.Description = f.attributes.Description;
      attr.Suggestion = f.attributes.Suggestion;
      attr.AuditCategoryId = f.attributes.AuditCategoryId;
      attr.AuditCategoryId_other = f.attributes.AuditCategoryId_other;
      attr.WorkTypeId = f.attributes.WorkTypeId;
      attr.EstimatedCost = f.attributes.EstimatedCost;
      attr.RecommendationType = f.attributes.RecommendationType;
      attr.RecommendationType_other = f.attributes.RecommendationType_other;
      attr.Recommendation = f.attributes.Recommendation;
      attr.Reference = f.attributes.Reference;
      attr.AuditReport = f.attributes.AuditReport;
      attr.AuditDate = f.attributes.AuditDate;
      attr.AuditConductedBy = f.attributes.AuditConductedBy;
      attr.Comments = f.attributes.Comments;
      attr.Priority = f.attributes.Priority;
      attr.WorkRequestNumber = f.attributes.WorkRequestNumber;
      attr.CompletedDate = f.attributes.CompletedDate;
      attr.Status = f.attributes.Status;

      // Common
      attr.OBJECTID = f.attributes.OBJECTID;
      attr.ClubId = f.attributes.ClubId;
      attr.Active = f.attributes.Active;
      attr.GlobalID = f.attributes.GlobalID;
      attr.HasLocation = f.attributes.HasLocation;
      attr.CreationDate = f.attributes.CreationDate;
      attr.Creator = f.attributes.Creator;
      attr.EditDate = f.attributes.EditDate;
      attr.Editor = f.attributes.Editor;

      // Populate virtual / calculated fields
      f.attributes.CoursePart = attr.CoursePart;
      f.attributes.ItemType = attr.ItemType;
      f.attributes.Category = attr.Category;
      f.attributes.WorkType = attr.WorkType;
      f.attributes.RecommendationType_DomainDesc = attr.RecommendationType_DomainDesc;
      f.attributes.AuditReport_DomainDesc = attr.AuditReport_DomainDesc;
      f.attributes.CourseAuditor = attr.CourseAuditor;
      f.attributes.Priority_DomainDesc = attr.Priority_DomainDesc;
      f.attributes.Status_DomainDesc = attr.Status_DomainDesc;
      f.attributes.Fairway_DomainDesc = attr.Fairway_DomainDesc;

      this.attributes.push(attr);
    });
  }

  // protected getLovs() {

  //   this.lovReadyEvent.emit(true);  // No LOV's, so emit event

  //   // All LOV Domains
  //   // combineLatest([
  //   //   this.categoryLovReadyEvent,
  //   //   this.subCategoryLovReadyEvent,
  //   //   this.reportedByLovReadyEvent,
  //   //   this.staffLovReadyEvent
  //   // ]).pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
  //   //   console.log('WorkRequest/FeatureBase getLovs() lovsReady()');
  //   //   this.lovReadyEvent.emit(true);
  //   // });

  //   this.domains_lov = [];
  //   this.domains_lovSubDomain = [];

  //   // let workRequestCategoryLOV: WorkRequestCategoryLOV = new WorkRequestCategoryLOV(this.lovDataService, this.domainLovService);
  //   // workRequestCategoryLOV.getLayer().then(() => {
  //   //   workRequestCategoryLOV.getDomain('LovWorkRequestCategory', 'coded-value').then((domain) => {
  //   //     this.domains_lov.push(domain);
  //   //     //console.log('getLovs() categoryLovReadyEvent.emit');
  //   //     this.categoryLovReadyEvent.emit();
  //   //   });
  //   // });
  // }
}
