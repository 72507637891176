<app-header>
    <span title>{{title}}</span>
    <span toolbar-extras>
    </span>
</app-header>

<div *ngIf='!thinking' style="height: 4px;"></div>
<div *ngIf='thinking'><mat-progress-bar mode="query"></mat-progress-bar></div>

<!-- Slider () Row -->
<!-- <div fxLayout='row' fxLayoutAlign="space-between center" class='date-slader-row'>
    <div class='chart-box' [ngClass]="{'dark-chart-box': darkMode}" style="width: 100%;">
        <div fxFlex="200px" class='chart-title' [ngClass]="{'dark-chart-title': darkMode}">Incident Date Range</div>
        <div #timeSlider [hidden]='!loaded' class='time-slider' [ngClass]="{'dark': darkMode}">
            <ngx-slider *ngIf='timeSliderReady'
                [(value)]="dateFrom"
                [(highValue)]="dateTo"
                [(options)]="timeSliderOptions"
                (userChangeEnd)="timeSliderDateRangeChange($event)"
            ></ngx-slider>
        </div>
    </div>
</div> -->

<!-- Chips Row -->
<div fxLayout='row' fxLayoutAlign="center center" class='chips-row'>
    <div>
        <mat-chip-list #chipListAll aria-label="">
            <span *ngFor="let chart of dashboardCharts">
                <mat-chip [selectable]="true" [removable]="removableChip" *ngIf="chart.visibleChip" (removed)="removeChip(chart.chartName)">
                    {{chart.label}}
                    <mat-icon matChipRemove *ngIf="removableChip">cancel</mat-icon>
                </mat-chip>
            </span>
        </mat-chip-list>
    </div>
</div>

<!-- Dashboard Row 1 -->
<div fxLayout='row' fxLayoutAlign="space-between center" class='chart-row chart-row-3'>
    <!-- Chart 1 -->
    <div fxFlex="33" class='chart-box' [ngClass]="{'dark-chart-box': darkMode}">   
        <div fxLayout='column' fxLayoutAlign="space-between center">
            <div [ngClass]="{'dark': darkMode}">
                <div fxLayout='row' fxShow fxHide.lt-md fxLayoutAlign="space-between center">
                    <ngx-charts-advanced-pie-chart 
                        [view]="[0.33 * chartWidth, chartHeight]"
                        [results]="getChartData('WORKPROGRAM')"
                        [label]="'Number of audit items selected'"
                        [gradient]="true" 
                        [scheme]='customColorScheme'
                        (select)="selectChart($event, 'WORKPROGRAM', 'DomWorkType', 'WorkTypeId', false)">
                    </ngx-charts-advanced-pie-chart>
                </div>
                <div fxLayout='row' fxHide fxShow.lt-md fxLayoutAlign="space-between center">
                    <ngx-charts-pie-chart 
                        [view]="[chartWidth, chartHeight]"
                        [results]="getChartData('WORKPROGRAM')"
                        [labels]="true"
                        [gradient]="true"
                        [scheme]='customColorScheme'
                        (select)="selectChart($event, 'WORKPROGRAM', 'DomWorkType', 'WorkTypeId', false)">
                    </ngx-charts-pie-chart>
                </div>
            </div>
            <div>
                <span class='chart-title' [ngClass]="{'dark-chart-title': darkMode}">Work Type</span>
            </div>
        </div>
    </div>
    <!-- Chart 2 -->
    <div fxFlex="33" class='chart-box' [ngClass]="{'dark-chart-box': darkMode}">
        <div fxLayout='column' fxLayoutAlign="space-between center">
            <div fxLayout='row' fxShow fxHide.lt-md fxLayoutAlign="space-between center">
                <div [ngClass]="{'dark': darkMode}">
                    <ngx-charts-bar-vertical 
                        [view]="[0.33 * chartWidth, chartHeight]"
                        [results]="getChartData('FAIRWAY')"
                        [xAxisLabel]="'Hole'" 
                        [yAxisLabel]="'Count'"
                        [showXAxisLabel]="false"
                        [showYAxisLabel]="false"
                        [xAxis]="true"
                        [yAxis]="true"
                        [trimXAxisTicks]="true"
                        [maxXAxisTickLength]='10'
                        [rotateXAxisTicks]='true'    
                        [tooltipDisabled]='true'
                        [xAxisLabel]="'Audit items for each fairway'"   
                        [gradient]="true"
                        [roundEdges]='false'
                        [noBarWhenZero]='false'
                        [showDataLabel]='true'
                        [scheme]='customColorScheme'
                        (select)="selectChart($event, 'FAIRWAY', 'DomFairway', 'FairwayNumber', false, true)">
                    </ngx-charts-bar-vertical>
                </div>
            </div>
            <div fxLayout='row' fxHide fxShow.lt-md fxLayoutAlign="space-between center">
                <div [ngClass]="{'dark': darkMode}">
                    <ngx-charts-pie-chart 
                        [view]="[0.33 * chartWidth, chartHeight]"
                        [results]="getChartData('FAIRWAY')"
                        [labels]="true"
                        [gradient]="true" 
                        [scheme]='customColorScheme'
                        (select)="selectChart($event, 'FAIRWAY', 'DomFairway', 'FairwayNumber', false, true)">
                    </ngx-charts-pie-chart> 
                </div>
            </div>
            <div>
                <span class='chart-title' [ngClass]="{'dark-chart-title': darkMode}">Fairway</span>
            </div>
        </div>
    </div>
    <!-- Chart 3 -->
    <div fxFlex="33" class='chart-box' [ngClass]="{'dark-chart-box': darkMode}">
        <div fxLayout='column' fxLayoutAlign="space-between center">
            <div fxLayout='row' fxShow fxHide.lt-md fxLayoutAlign="space-between center">
                <div [ngClass]="{'dark': darkMode}">
                    <ngx-charts-bar-vertical 
                        [view]="[0.33 * chartWidth, chartHeight]"
                        [results]="getChartData('COURSEPART')"
                        [xAxisLabel]="'Course Part'" 
                        [yAxisLabel]="'Count'"
                        [showXAxisLabel]="false"
                        [showYAxisLabel]="false"
                        [xAxis]="true"
                        [yAxis]="true"
                        [trimXAxisTicks]="true"
                        [maxXAxisTickLength]='5'
                        [rotateXAxisTicks]='true'
                        [tooltipDisabled]='false' 
                        [gradient]="true" 
                        [roundEdges]='false' 
                        [noBarWhenZero]='false'
                        [showDataLabel]='true'
                        [scheme]='customColorScheme'
                        (select)="selectChart($event, 'COURSEPART', 'DomAuditCoursePart', 'AuditCoursePart', true)">
                    </ngx-charts-bar-vertical>
                </div>
            </div>
            <div fxLayout='row' fxHide fxShow.lt-md fxLayoutAlign="space-between center">
                <div [ngClass]="{'dark': darkMode}">
                    <ngx-charts-pie-chart 
                        [view]="[0.33 * chartWidth, chartHeight]"
                        [results]="getChartData('COURSEPART')"
                        [labels]="true"
                        [gradient]="true" 
                        [scheme]='customColorScheme'
                        (select)="selectChart($event, 'COURSEPART', 'DomAuditCoursePart', 'AuditCoursePart', true)">
                    </ngx-charts-pie-chart> 
                </div>
            </div>
            <div>
                <span class='chart-title' [ngClass]="{'dark-chart-title': darkMode}">Course Part</span>
            </div>
        </div>
    </div>
</div>

<!-- Image Gallery Row -->
<div fxLayout='row' class='image-row'>
    <app-esri-attachment style='width: 100%'></app-esri-attachment> 
</div>

<!-- Map Row -->
<div fxLayout='row' class='map-row'>
    <app-esri-map fxLayout='row' style='width: 100%;' 
        [sidePanelWidth]='sidePanelWidth' 
        [showFullscreenWidget]='true' 
        [showLegendWidget]='true'
        [legendLayerInfos]='legendLayerInfos'
        [hideBasemap]='false' 
        [basemapName]='basemapName'
        (viewCreatedEvent)='viewCreatedEvent($event)'>
        
        <div #popupMenu [hidden]="!loaded">
            <app-esri-popup-menu [popupType]="'MENU'">
                <div class="popup-menu-items">
                    <button mat-menu-item *ngIf="selectedFeature" (click)='currentModeService.currentModeData.next("edit-attribute")'>
                        <mat-icon class='material-icons-sharp'>edit</mat-icon>
                        <span>Edit this item</span>
                    </button>
                    <button mat-menu-item *ngIf="!selectedFeature" (click)='createFeatureFromPopupMenuEvent()'>
                        <mat-icon class='material-icons-sharp'>add_location</mat-icon>
                        <span>Create a new item here</span>
                    </button>
                    <button mat-menu-item *ngIf="selectedFeature" (click)='moveFeatureFromPopupMenuEvent()'>
                        <mat-icon class='material-icons-sharp'>edit_location</mat-icon>
                        <span>Move the selected item here</span>
                    </button>
                </div>
            </app-esri-popup-menu>
        </div>

        <div #popupData [hidden]="!loaded">
            <app-esri-popup-menu [popupType]="'DATA'">
                <div class="popup-data-items">
                    <app-esri-edit-form [readOnly]='true'></app-esri-edit-form>
                </div>
            </app-esri-popup-menu>   
        </div>
    </app-esri-map>
</div>

<!-- Table Data Row -->
<div *ngIf='showDataTable' fxLayout='row' class='table-data-row'>                 
    <app-esri-view-table (addButtonSelectedEvent)='addBtnEvent()'></app-esri-view-table>
</div>

<!-- Footer -->
<app-footer></app-footer>
