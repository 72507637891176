import { Injectable } from '@angular/core';
import { PageOrientation, Paragraph, PageNumberFormat } from "docx";
import { MsWordService } from 'src/app/_globals/services/ms-word.service';

@Injectable({
  providedIn: 'root'
})

export class AuditReportService extends MsWordService {

  public maxHoleNumber: number = 19; //19
  public fileName: string = 'AuditReport';

  public async runReport(reportId?: string) {
    
    //switch (reportId.toUpperCase()) {
    //  case 'COURSEAUDIT':
          // COURSEAUDIT report
          let numberSteps: number = 8;
          this.fileName = 'CourseAuditReport';

          await this.initialiseReport();
          await this.generateCourseAuditReport(numberSteps);
          this.saveDocumentFile(this.fileName, numberSteps);
    //    break;
    //}
  }

  //
  // COURSEAUDIT Report
  //
  private async generateCourseAuditReport(numberSteps: number): Promise<any[]> {

    let currentStepNumber: number = 0;
    let currentStepName: string = '';

    // Title page
    currentStepName = 'Writing title page...';
    this.updateProgress(numberSteps, currentStepNumber++, currentStepName, 1, 1, currentStepName);
    console.log('***** ' + currentStepName);

    this.document.addSection(
      this.titlePage
    );

    // Table of Contents
    currentStepName = 'Writing table of contents...';
    this.updateProgress(numberSteps, currentStepNumber++, currentStepName, 1, 1, currentStepName);
    console.log('***** ' + currentStepName);

    this.document.addSection(
      this.tableOfContents
    );

    //
    // Get the introduction and audit details from parent record
    //
    currentStepName = 'Writing introduction...';
    this.updateProgress(numberSteps, currentStepNumber++, currentStepName, 1, 1, currentStepName);
    console.log('***** ' + currentStepName);

    await this.createHeadingSection_1("Introduction");

    //
    // Holes 1 to 18 (19)
    //

    currentStepName = 'Writing audit items for holes...';
    currentStepNumber = currentStepNumber++;
    console.log('***** ' + currentStepName);

    for (let i = 1; i <= this.maxHoleNumber; i++) {
      this.updateProgress(numberSteps, currentStepNumber, currentStepName, this.maxHoleNumber, i, 'Audit items for hole ' + i);
      console.log('***** Hole: ', i);
      
      // Group the items by FairwayNumber
      let items = this.features.filter(x => x.attributes.FairwayNumber === i).sort((a, b) => a.attributes.AuditCoursePart.localeCompare(b.attributes.AuditCoursePart));

      if (items.length > 0) {
        let title = "Audit Items for Hole " + i.toString();
        await this.createCourseAuditReportSections(title, items, this.includeStaticMapPerItem, true);
      }
    }

    //
    // Practice Green - FairwayNumber = 0
    //

    currentStepName = 'Writing practice green...';
    this.updateProgress(numberSteps, currentStepNumber++, currentStepName, 1, 1, currentStepName);
    console.log('***** ' + currentStepName);

    let items = this.features.filter(x => x.attributes.FairwayNumber === 0).sort((a, b) => a.attributes.AuditCoursePart.localeCompare(b.attributes.AuditCoursePart));

    if (items.length > 0) {
      let title = "Practice Green";
      await this.createCourseAuditReportSections(title, items, this.includeStaticMapPerItem, true);
    }

    //
    // All Course - FairwayNumber = 99
    //

    currentStepName = 'Writing all course-wide items...';
    this.updateProgress(numberSteps, currentStepNumber++, currentStepName, 1, 1, currentStepName);
    console.log('***** ' + currentStepName);

    items = this.features.filter(x => x.attributes.FairwayNumber === 99).sort((a, b) => a.attributes.AuditCoursePart.localeCompare(b.attributes.AuditCoursePart));

    if (items.length > 0) {
      let title = "Course";
      await this.createCourseAuditReportSections(title, items, this.includeStaticMapPerItem, false);
    }

    //
    // Get the discussion / conclusion from parent record
    //

    currentStepName = 'Writing summary and conclusion...';
    this.updateProgress(numberSteps, currentStepNumber++, currentStepName, 1, 1, currentStepName);
    console.log('***** ' + currentStepName);

    await this.createHeadingSection_1("Summary and Conclusion");

    return;
  }

  private async createCourseAuditReportSections(headingTitle: string, items: any, includeStaticMaps: boolean, includeHeadingStaticMap: boolean): Promise<any> {

    if (includeHeadingStaticMap) {
      // Create a map for items on the current hole
      let objectids: number[] = [];
      items.forEach(x => {
        objectids.push(x.attributes.OBJECTID);
      });

      const extent = await this.featuresService.queryExtent(this.featureLayer, "1=1", objectids);
      let url = items[0].layer.url + '/' + items[0].layer.layerId; 

      const mapImageUrl = await this.getStaticMapUrl(extent, url, items[0].layer.title, objectids);
      const mapImageBlob = await this.getStaticMap(mapImageUrl);

      // Write the Heading page with a map
      await this.createHeadingSection_1(headingTitle, mapImageBlob);
    }
    else {
      await this.createHeadingSection_1(headingTitle);
    }

    // Get the images
    let reportImages = await this.processReportImages(items, includeStaticMaps);

    // Write the details section
    for (let j = 0; j < reportImages.length; j++) {
      console.log('  *** Creating section: ', j);
      await this.createCourseAuditReportItem(reportImages[j].feature, reportImages[j].imageSizeInfos, reportImages[j].mapImageBlob, reportImages[j].newHeading1);
    }

    return;

  }

  private async createCourseAuditReportItem(feature: any, imageSizeInfos: any[], mapImageBlob: any, newHeading1?: boolean): Promise<any[]> {

    // https://docx.js.org/#/usage/styling-with-js

    // Get the domain values
    let coursePart: string = '';
    if (feature.attributes.AuditCoursePart === 'other') {
      coursePart = feature.attributes.AuditCoursePart_other;
    }
    else {
      coursePart = this.getDomainValue("AuditCoursePart", feature.attributes.AuditCoursePart, feature);
    }

    // Category
    let category: Paragraph[] = [];
    if (feature.attributes.AuditCategoryId === 'other') {
      category = this.createDomainList("AuditCategoryId", feature.attributes.AuditCategoryId, feature, feature.attributes.AuditCategoryId_other);
    }
    else {
      category = this.createDomainList("AuditCategoryId", feature.attributes.AuditCategoryId, feature);
    }

    // Work Program
    const workProgram: Paragraph[] = this.createDomainList("WorkTypeId", feature.attributes.WorkTypeId, feature);

    let headings: any[] = [];
    //let textItems: any[] = [];

    headings.push(this.createHeading_3("Category"));
    //textItems.push(...category);

    headings.push(this.createHeading_3("Work Program"));
    //textItems.push(...workProgram);

    const categoryWorkProgram = this.createTextTable_1Row_2Col(headings, category, workProgram);

    // Title, description and suggestions
    const itemTitle: string = feature.attributes.AuditItemTitle + " (Item: " + feature.attributes.AuditItemNumber + ")";
    const description: Paragraph[] = this.createParagraphsFromHtml(feature.attributes.Description);
    const suggestions: Paragraph[] = this.createParagraphsFromHtml(feature.attributes.Suggestion);

    // Estimated Cost
    let estimatedCost :string = this.shortLine;
    if (feature.attributes.EstimatedCost) {
      estimatedCost = feature.attributes.EstimatedCost;
    }

    // Recommendation
    let recommendation: Paragraph[] = [];
    if (feature.attributes.Recommendation) {
      recommendation = this.createParagraphsFromHtml(feature.attributes.Recommendation);
    }
    else {
      recommendation.push(this.createParagraph(this.fullLine));
      recommendation.push(this.createParagraph(this.fullLine));
      //recommendation.push(this.createParagraph(this._fullLine));
      //recommendation.push(this.createParagraph(this._fullLine));
      //recommendation.push(this.createParagraph(this._fullLine));
    }


    // Attached Images - FYI: Document needs to be created first for Media objects
    let imageTable = null;
    if (imageSizeInfos) {
      imageTable = this.createImageTable(imageSizeInfos);
    }

    // Section Children
    let children: any[] = [];

    // This would miss bits, so use individual pushes
    // children = [
    //   this.createHeading_2(coursePart + " - " + itemTitle),
    //   ...description,
    //   (mapImageBlob) ? this.createImage(mapImageBlob, this.staticMapService.width, this.staticMapService.height) : '',
    //   (imageTable) ? imageTable : '',
    //   ...recommendation
    // ]

    children.push(this.createHeading_2(coursePart + " - " + itemTitle));
    children.push(...description);

    if (imageTable) {
      children.push(imageTable);
    }

    if (mapImageBlob) {
      children.push(this.createHeading_3("Location"));
      children.push(this.createImage(mapImageBlob, this.staticMapService.width, this.staticMapService.height));
    }

    children.push(this.createHeading_3("Suggestions"));
    children.push(...suggestions);


    // children.push(this.createHeading_3("Category"));
    // children.push(...category);

    // children.push(this.createHeading_3("Work Program"));
    // children.push(...workProgram);

    children.push(categoryWorkProgram),

    children.push(this.createHeading_3("Estimated Cost"));
    children.push(this.createParagraph(estimatedCost));

    children.push(this.createHeading_3("Recommendation"));
    children.push(...recommendation);

    // Create a new document section and add the details
    this.document.addSection({
      titlePage: false,
      headers: {
        default: this.header,
        //first: this._header,
        //even: this._header,
      },
      footers: {
        default: this.footer,
      },
      properties: {
        pageNumberFormatType: PageNumberFormat.DECIMAL,
        //...this._pageBorderProperties,
      },
      size: {
        orientation: PageOrientation.PORTRAIT,
      },
      margins: this.margins,
      children: children
    });

    return;
  }

  //
  // Other Reports (future)
  //
}
