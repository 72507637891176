import { Component, OnInit, OnDestroy, AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, ElementRef } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { getTime, toDate, format, isBefore, parse, addYears } from 'date-fns';
import { Global, GroupByRange, Suburb } from 'src/app/_globals/models/global';
import { BaseFeaturePage } from 'src/app/_esri/models/base-feature-page';
import { FeaturesService } from 'src/app/_esri/services/features.service';
import { CourseService } from 'src/app/_course/services/course.service';
import { SurroundsService } from 'src/app/_course/services/surrounds.service';
import { Esri, EsriGraphic, ApplyEditsResultMessage, GeometryPoint, DashboardChart } from 'src/app/_esri/models/esri';
import { CurrentModeService } from 'src/app/_globals/services/current-mode.service';
import { GeocodeDataService } from 'src/app/_globals/services/geocode-data.service';
import { FormChangeService } from 'src/app/_globals/services/form-change.service';
import { FormFieldChangeService } from 'src/app/_globals/services/form-field-change.service';
import { FormFieldUpdateService } from 'src/app/_globals/services/form-field-update.service';
import { DomainLovService } from 'src/app/_esri/services/domain-lov.service';
import { LovDataService } from 'src/app/_globals/services/lov-data.service';
//import { MemberService } from 'src/app/member/services/member.service';
import { Member, MemberAttributes } from 'src/app/member/models/member';
import { CsvReadService } from 'src/app/_globals/services/csv-read.service';


// import * as shape from 'd3-shape';
// linearCurve= shape.curveLinearClosed;
// [curve]="linearCurve"

@Component({
  selector: 'app-member',
  templateUrl: './member.component.html',
  styleUrls: [
    './member.component.scss',
    '../_styles/base-feature-page.scss'
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class MemberComponent extends BaseFeaturePage implements OnInit, OnDestroy, AfterViewInit {
  title = 'Membership';
  //subTitle = '';
  showTimeSliderWidget = false;
  //dateFilterAttributeName = '';

  showDataCards = false;
  showDataTable = false;
  showBaseLayers = false;
  showImageGallery = false;
  includeHillshade = false;
  darkMode = false;

  public member_basemapName = 'gray'; // dark-gray
  public centre = Esri.initialCenter_Global;
  public zoom = 3;

  dashboardCharts: DashboardChart[] = [
    { chartName: 'GENDER' },
    { chartName: 'MEMBER_TYPE' },
    { chartName: 'AGE' },
    { chartName: 'MEMBER_YEARS' },
    { chartName: 'AGE_10_YEAR_AVG' },
    { chartName: 'NEW_MEMBERS_MEMBER_TYPE' },
    { chartName: 'NEW_MEMBERS_GENDER' },
    { chartName: 'NEW_MEMBERS_AGE' },
    { chartName: 'NEW_MEMBERS_AGE_10_YEAR_AVG' },
    { chartName: 'NEW_MEMBERS_FEMALE_AGE_10_YEAR' }
  ];

  private _csvDataArray: any = [];   // used by CSV load

  constructor(
    protected ref: ChangeDetectorRef,
    protected dialog: MatDialog,
    protected currentModeService: CurrentModeService,
    protected geocodeDataService: GeocodeDataService,
    protected formChangeService: FormChangeService,
    protected formFieldChangeService: FormFieldChangeService,
    protected formFieldUpdateService : FormFieldUpdateService,
    protected elementRef: ElementRef,
    //
    private csvReadService: CsvReadService,
  ) {     
    super(
    ref, 
    dialog, 
    currentModeService, 
    geocodeDataService, 
    formChangeService, 
    formFieldChangeService, 
    formFieldUpdateService,
    elementRef
  );
  }

  ngOnInit(): void {
    this.thinking = true;

    // Get layers, features, lovs, domains, etc
    this.featureLayerObject = new Member();
    super.ngOnInit();
  }

  //
  // Get attributes from feature set and assign to class "featureAttributes", (view table, and view cards )- calc virtual fields
  //
  protected refreshDisplay() {
    this.featureLayerObject.updateVirtualFields();
    super.refreshDisplay();

    //console.log('Attempting to load csv data');
    //this.createFeatures(this.featureLayerObject.featureLayer);
  }

  // Build data for charts
  protected createCharts() {
    super.createCharts();
    const dataArray = this.featureLayerObject.attributes;

    const ageRange: GroupByRange[] = [
      {From: 0, To: 17 , Label: '< 17'},
      {From: 18, To: 25},
      {From: 26, To: 33},
      {From: 34, To: 41},
      {From: 42, To: 49},
      {From: 50, To: 57},
      {From: 58, To: 65},
      {From: 66, To: 73},
      {From: 74, To: 81},
      {From: 82, To: 89},
      {From: 90, To: 110, Label: '> 90'}
    ];

    
    const tenureRange: GroupByRange[] = [
      {From: 0, To: 9, Label: '0-9'},
      {From: 10, To: 19},
      {From: 20, To: 29},
      {From: 30, To: 39},
      {From: 40, To: 49},
      {From: 50, To: 59},
      {From: 60, To: 69},
      {From: 70, To: 79},
    ];
    
    //console.log('this.queryFeatures() memberArray:', memberArray);

    this.dashboardCharts.find(x => x.chartName == 'GENDER').summaryData = this.createChartDataSet_Count(dataArray, 'Gender'); //, 'value');

    this.dashboardCharts.find(x => x.chartName == 'MEMBER_TYPE').summaryData = this.createChartDataSet_Count_Split(dataArray, 'MembershipCategory', 'Gender', 'ASC'); //, 'value');

    //this.dashboardCharts.find(x => x.chartName == 'AGE').summaryData = this.createChartDataSetValueCount_Split(this._members, 'AgeFinancialYearEnd', 'Gender', 'ASC'); //, 'name');
    this.dashboardCharts.find(x => x.chartName == 'AGE').summaryData = this.createChartDataSet_Count_Split_Grouped(dataArray, 'AgeFinancialYearEnd', ageRange, 'Gender', 'ASC', true); //, 'name');

    // //this.dashboardCharts.find(x => x.chartName == 'MEMBER_YEARS').summaryData = this.createChartDataSet_ValueCount_Split(this._members, 'MembershipTenure', 'Gender', 'DESC'); //, 'name');
    this.dashboardCharts.find(x => x.chartName == 'MEMBER_YEARS').summaryData = this.createChartDataSet_Count_Split_Grouped(dataArray, 'MembershipTenureFinancialYearEnd', tenureRange, 'Gender', 'ASC', true); //, 'name');

    const newMembers = dataArray.filter(x => x.MembershipStartDate >= Global.getCurrentFinancialYearStartDate());

    this.dashboardCharts.find(x => x.chartName == 'NEW_MEMBERS_MEMBER_TYPE').summaryData = this.createChartDataSet_Count_Split(newMembers, 'MembershipCategory', 'Gender', 'ASC');

    this.dashboardCharts.find(x => x.chartName == 'NEW_MEMBERS_GENDER').summaryData = this.createChartDataSet_Count(newMembers, 'Gender');

    this.dashboardCharts.find(x => x.chartName == 'NEW_MEMBERS_AGE').summaryData = this.createChartDataSet_Count_Split_Grouped(newMembers, 'AgeFinancialYearEnd', ageRange, 'Gender', 'ASC', true); 

    // Get last 10 finacial years
    let today: Date = new Date();
    const financialYearRange: GroupByRange[] = [];

    for (let i = -9; i <= 0; i++) { 
      financialYearRange.push(Global.getFinancialYearDates(addYears(today, i)));
    };

    // Existing Memebrs - joined prior to the end of financial year group (or start ????)
    const existingMemberGroups = this.createDataGroup_BeforeDate(dataArray, 'MembershipStartDate', financialYearRange, true);
    this.dashboardCharts.find(x => x.chartName == 'AGE_10_YEAR_AVG').summaryData = this.createChartDataSet_Average(existingMemberGroups, 'AgeFinancialYearEnd', true);

    // New Members - Joined within the financial year
    const memberGroups = this.createDataGroup_Date(dataArray, 'MembershipStartDate', financialYearRange);
    this.dashboardCharts.find(x => x.chartName == 'NEW_MEMBERS_AGE_10_YEAR_AVG').summaryData = this.createChartDataSet_Average(memberGroups, 'AgeFinancialYearEnd', true);

    // Stats for Female members
    const femaleMembers = dataArray.filter(x => x.Gender.toUpperCase() === 'FEMALE');
    this.dashboardCharts.find(x => x.chartName == 'NEW_MEMBERS_FEMALE_AGE_10_YEAR').summaryData = this.createChartDataSet_Count_GroupedRange(femaleMembers, 'MembershipStartDate', financialYearRange, true);
  
  
  }

  // This fn creates features from a CSV
  private async XXcreateFeatures(featureLayer: any) {

    let suburbs: Suburb[] = [];

    await this.getSuburbGeocode().then( (data) => {
      suburbs = data;
    });
    console.log('suburbs', suburbs);

    await this.getData().then( (data) => {
      this._csvDataArray = data;
    });
    //console.log('this._memberDataArray', this._memberDataArray);


    let result: ApplyEditsResultMessage = new ApplyEditsResultMessage();
    let featuresService: FeaturesService = new FeaturesService();
    let params;
    let resultMessage: string = '';
    let geometry: GeometryPoint;
    let newFeatures: any[] = [];
    
    for (let element of this._csvDataArray.data) {
      const attributes = {
        'MembershipNumber': element['Member Number'],
        'FullName': element['Member Name'],
        'Suburb': element['City'],
        'State': element['State'],
        'Postcode': element['Postal Code'],
        'MembershipTypeId': element['MembershipTypeId'],    
        'MembershipCategoryId': element['MembershipCategoryId'], 
        'MembershipStartDate': getTime(parse(element['Member Since'], 'dd/MM/yyyy', new Date())),
        'DateOfBirth': getTime(parse(element['Date of Birth'], 'dd/MM/yyyy', new Date())),
        'Gender': element['Gender']
      }
      //console.log('this.createFeatures attributes', attributes);

      const geocodedSuburb = suburbs.find(x => x.PlaceName.toUpperCase() === element['City'].toUpperCase());
      //console.log('this.createFeatures geocodedSuburb', geocodedSuburb);


      if (geocodedSuburb) {
        const longitude = geocodedSuburb.Longitude;
        const latitude = geocodedSuburb.Latitude;
        geometry = new GeometryPoint(longitude, latitude);
        //console.log('this.createFeatures geometry', geometry);
      }
      else {
        geometry = null;
      }

      // Create a new feature
      const newFeature: EsriGraphic = { 
        geometry,
        attributes 
      };
      //console.log('this.createFeatures newFeature', newFeature);

      newFeatures.push(newFeature);
    }

    // Setup the applyEdits parameter with adds
    const adds = {
      addFeatures: newFeatures
    };
    params = adds;


    console.log('createFeatures() params', params);

    // Send the adds to the GIS server (ESRI feature service)
    await featuresService.applyEditsFeatureLayer(featureLayer, params).then((editResult) => {
      result = editResult;  
      console.log('createFeatures() applyEditsFeatureLayer result', result);
      //result.recordId = recordId;
    });

  }

  public async getData(): Promise<any> {
    let members: any[] = [];
    let fileName: string = 'assets/data/MemberData.csv';

    await this.csvReadService.readCsvFile(fileName, true).then( (parsedData) => {
      members = parsedData;
    });

    return members;
  }

  public async getSuburbGeocode(): Promise<any> {
    let suburbs: Suburb[] = [];
    let fileName: string = 'assets/data/SuburbsGeocode.csv';

    await this.csvReadService.readCsvFile(fileName, true).then( (parsedData) => {
      for (let element of parsedData.data) {
        const suburb: Suburb = {
          PlaceName: element.PlaceName,
          Longitude: element.Longitude,
          Latitude: element.Latitude
        }
        suburbs.push(suburb);
      };
    });

    //console.log('getSuburbGeocode() suburbs', suburbs);
    return suburbs;
  }
}
