import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export interface CurrentModeData {
  formName: string
  mode: string,
  event?: any,
}

@Injectable({
  providedIn: 'root'
})

export class CurrentModeService {
  public currentModeData = new Subject();

  constructor() { }
}
