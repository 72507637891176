import { Component, OnInit } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { HoleGalleryComponent } from 'src/app/map-gallery/dialogs/hole-gallery/hole-gallery.component';
import { Global } from 'src/app/_globals/models/global';

@Component({
  selector: 'app-map-gallery',
  templateUrl: './map-gallery.component.html',
  styleUrls: ['./map-gallery.component.scss']
})
export class MapGalleryComponent implements OnInit {

  public title = 'Map Gallery';

  // Current Query Settings
  public gameGender: string = 'F';
  public gameTeeMarkerType: string = 'RED';
  public gameDriveLength: string = 'PAR';
  public gameFairwayNumber: string = '99';  // All

  public assetHostServer = Global.assetHostServer;

  private _matSnackBarConfig: MatSnackBarConfig = {
    duration: 1000
  }
 
  constructor(
    private _snackBar: MatSnackBar,
    private _bottomSheet: MatBottomSheet
  ) { }

  ngOnInit(): void {
  }

  public setGameGender(choice: string) {
    this.gameGender = choice;
    // Enable / disable gender buttons as required based on tee selected
    switch (this.gameGender) {    
      case 'M':
        if (this.gameTeeMarkerType === 'GOLD') {
          this.gameTeeMarkerType = 'BLACK';
        }
        break;

      case 'F':
        if (this.gameTeeMarkerType === 'BLACK' || this.gameTeeMarkerType === 'WHITE') {
          this.gameTeeMarkerType = 'GOLD';
        }
        break;
    }

    this.showSnackBarMessage('Game gender set to "' +  choice + '"');
  }

  public setTeeMarkerType(choice: string) {
    this.gameTeeMarkerType = choice;
    this.showSnackBarMessage('Tee marker type set to "' +  choice + '"');
  }

  private showSnackBarMessage(message: string) {
    this._snackBar.open(message, "",  this._matSnackBarConfig);
  };

  public setGameDriveLength(choice: string) {
    this.gameDriveLength = choice;
    this.showSnackBarMessage('Drive length set to "' +  choice + '"');
  }

  public queryGameHole(choice: string) {
    this.gameFairwayNumber = choice;
    this.openBottomSheet(choice);
  }

  private openBottomSheet(choice: string): void {
    this._bottomSheet.open(HoleGalleryComponent, {
      data: {
        title: 'Map Gallery for Hole ' + choice,
        gameGender: this.gameGender,
        gameTeeMarkerType: this.gameTeeMarkerType,
        gameDriveLength: this.gameDriveLength,
        gameFairwayNumber: this.gameFairwayNumber
      }
    });
  }

}
