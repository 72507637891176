<app-header>
    <span title>{{title}}</span>
    <span toolbar-extras>
    </span>
</app-header>

<div *ngIf='!thinking' style="height: 4px;"></div>
<div *ngIf='thinking'><mat-progress-bar mode="query"></mat-progress-bar></div>

<!-- Chips Row -->
<div fxLayout='row' fxLayoutAlign="center center" class='chips-row'>
    <div>
        <mat-chip-list #chipListAll aria-label="">
            <span *ngFor="let chart of dashboardCharts">
                <mat-chip [selectable]="true" [removable]="removableChip" *ngIf="chart.visibleChip" (removed)="removeChip(chart.chartName)">
                    {{chart.label}}
                    <mat-icon matChipRemove *ngIf="removableChip">cancel</mat-icon>
                </mat-chip>
            </span>
        </mat-chip-list>
    </div>
</div>

<!-- Dashboard Row 1 -->
<div fxLayout='row' fxLayoutGap="20px" fxLayoutAlign="space-between center" class='chart-row chart-row-1'>
    <!-- Chart 1 -->
    <div fxFlex="33" class='chart-box' [ngClass]="{'dark-chart-box': darkMode}">
        <div fxLayout='column' fxLayoutAlign="space-between center"> 
            <div fxLayout='row' fxShow fxHide.lt-md fxLayoutAlign="space-between center">
                <div [ngClass]="{'dark': darkMode}">
                    <ngx-charts-bar-vertical-stacked 
                        [view]="[0.33 * chartWidth, chartHeight]"
                        [results]="getChartData('ORIGIN_TOTAL_YEAR')"
                        [xAxisLabel]="''" 
                        [yAxisLabel]="'Number of rounds'"
                        [showXAxisLabel]='false'
                        [showYAxisLabel]='true'
                        [xAxis]='true'
                        [yAxis]='true'
                        [trimXAxisTicks]='true'
                        [maxXAxisTickLength]='15'
                        [rotateXAxisTicks]='true'    
                        [tooltipDisabled]='false' 
                        [gradient]='true'
                        [noBarWhenZero]='true'
                        [barPadding]='20'
                        [showDataLabel]='true'
                        [scheme]='customColorScheme'
                        (select)="selectChart_Rounds($event, 'ORIGIN_TOTAL_YEAR')"
                        >
                    </ngx-charts-bar-vertical-stacked>
                </div>
            </div>
            <div fxLayout='row' fxHide fxShow.lt-md fxLayoutAlign="space-between center">
            </div>
            <div>
                <span class='chart-title' [ngClass]="{'dark-chart-title': darkMode}">Number of Rounds Played - Year</span>
            </div>
        </div>
    </div>
    <!-- Chart 2 -->
    <div fxFlex="33" class='chart-box' [ngClass]="{'dark-chart-box': darkMode}">
        <div fxLayout='column' fxLayoutAlign="space-between center"> 
            <div [ngClass]="{'dark': darkMode}">
                <ngx-charts-pie-chart
                    [view]="[0.33 * chartWidth, chartHeight]"
                    [results]="getChartData('ORIGIN')"
                    [labels]="true"
                    [gradient]="true" 
                    [scheme]='customColorScheme'
                    (select)="selectChart_Rounds($event, 'ORIGIN')">
                </ngx-charts-pie-chart>
            </div>

            <div>
                <span class='chart-title' [ngClass]="{'dark-chart-title': darkMode}">Number of Rounds Played - Origin</span>
            </div>
        </div>
    </div>
    <!-- Chart 3 -->
    <div fxFlex="33" class='chart-box' [ngClass]="{'dark-chart-box': darkMode}">
        <div fxLayout='column' fxLayoutAlign="space-between center"> 
            <div fxLayout='row' fxShow fxHide.lt-md fxLayoutAlign="space-between center">
                <div [ngClass]="{'dark': darkMode}">
                    <ngx-charts-bar-vertical 
                        [view]="[0.33 * chartWidth, chartHeight]"
                        [results]="getChartData('ROUND_TOTAL_DAY')"
                        [xAxisLabel]="'Action Taken'" 
                        [yAxisLabel]="'($)'"
                        [showXAxisLabel]="false"
                        [showYAxisLabel]="false"
                        [xAxis]="true"
                        [yAxis]="true"
                        [trimXAxisTicks]="true"
                        [maxXAxisTickLength]='5'
                        [rotateXAxisTicks]='true'
                        [tooltipDisabled]='false' 
                        [gradient]="true" 
                        [roundEdges]='false' 
                        [noBarWhenZero]='true'
                        [showDataLabel]='true'
                        [scheme]='customColorScheme'
                        (select)="selectChart_Rounds($event, 'ROUND_TOTAL_DAY')">
                    </ngx-charts-bar-vertical> 
                </div>
            </div>
            <div fxLayout='row' fxHide fxShow.lt-md fxLayoutAlign="space-between center">
            </div>
            <div>
                <span class='chart-title' [ngClass]="{'dark-chart-title': darkMode}">Number of Rounds Played - Day</span>
            </div>
        </div>
    </div>
</div>

<!-- Dashboard Row 2 -->
<div fxLayout='row' fxLayoutGap="20px" fxLayoutAlign="space-between center" class='chart-row chart-row-1'>
    <!-- Chart 1 -->
    <div fxFlex="100" class='chart-box' [ngClass]="{'dark-chart-box': darkMode}">
        <div fxLayout='column' fxLayoutAlign="space-between center"> 
            <div fxLayout='row' fxShow fxHide.lt-md fxLayoutAlign="space-between center">
                <div [ngClass]="{'dark': darkMode}">
                    <ngx-charts-bar-vertical-stacked 
                        [view]="[chartWidth, chartHeight]"
                        [results]="getChartData('ORIGIN_TOTAL_PERIOD')"
                        [xAxisLabel]="''" 
                        [yAxisLabel]="'Number of rounds'"
                        [showXAxisLabel]='false'
                        [showYAxisLabel]='true'
                        [xAxis]='true'
                        [yAxis]='true'
                        [trimXAxisTicks]='true'
                        [maxXAxisTickLength]='15'
                        [rotateXAxisTicks]='true'    
                        [tooltipDisabled]='false' 
                        [gradient]='true'
                        [noBarWhenZero]='true'
                        [barPadding]='20'
                        [showDataLabel]='true'
                        [scheme]='customColorScheme'
                        >
                    </ngx-charts-bar-vertical-stacked>
                </div>
            </div>
            <div fxLayout='row' fxHide fxShow.lt-md fxLayoutAlign="space-between center">
            </div>
            <div>
                <span class='chart-title' [ngClass]="{'dark-chart-title': darkMode}">Number of Rounds Played - Month</span>
            </div>
        </div>
    </div>
</div>

<!-- Dashboard Row 3 -->
<div fxLayout='row' fxLayoutGap="20px" fxLayoutAlign="space-between center" class='chart-row chart-row-1'>
    <!-- Chart 1 -->
    <div fxFlex="100" class='chart-box' [ngClass]="{'dark-chart-box': darkMode}">
        <div fxLayout='column' fxLayoutAlign="space-between center"> 
            <div fxLayout='row' fxShow fxHide.lt-md fxLayoutAlign="space-between center">
                <div [ngClass]="{'dark': darkMode}">
                    <ngx-charts-bar-vertical-2d 
                        [view]="[chartWidth, chartHeight]"
                        [results]="getChartData('ROUND_TOTAL_PERIOD_DAY')"
                        [xAxisLabel]="''" 
                        [yAxisLabel]="'Number of rounds'"
                        [showXAxisLabel]='false'
                        [showYAxisLabel]='true'
                        [xAxis]='true'
                        [yAxis]='true'
                        [trimXAxisTicks]='true'
                        [maxXAxisTickLength]='10'
                        [rotateXAxisTicks]='true'    
                        [tooltipDisabled]='false' 
                        [gradient]='true'
                        [noBarWhenZero]='true'
                        [roundEdges]='false'
                        [barPadding]='1'
                        [groupPadding]='1'
                        [showDataLabel]='false'
                        [scheme]='customColorScheme'
                        >
                    </ngx-charts-bar-vertical-2d>
                </div>
            </div>
            <div fxLayout='row' fxHide fxShow.lt-md fxLayoutAlign="space-between center">
            </div>
            <div>
                <span class='chart-title' [ngClass]="{'dark-chart-title': darkMode}">Number of Rounds Played - Month - Day of the Week (Sunday to Saturday)</span>
            </div>
        </div>
    </div>
</div>

<!-- Footer -->
<app-footer></app-footer>