import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Papa } from 'ngx-papaparse';

@Injectable({
  providedIn: 'root'
})

export class CsvReadService {

  //public options: any = {};
  public data: any;


  constructor(
      private http: HttpClient,
      private papa: Papa
    ) { }


  // public async parseCSV(): Promise<any> {
  //   // Read a csv file
  //   this.http.get(this.fileName, {responseType: 'blob'}).subscribe( (data) => {
  //     this.data = data;

  //     console.log('parseCSV() this.data', this.data);

  //     // Parse the CSV into an array
  //     this.papa.parse(this.data, {
  //       complete: ( (results, file) => {
  
  //         //console.log(results);
  //         console.log('parseCSV() this.papa.parse', results);


  //         //results.data
  //         //results.errors
  //         //results.meta.fields

  //         return results;
  //       }),
  //       header: true,
  //       skipEmptyLines: true
  //     });
  //   });

  //   // //let input = $event.target;  
  //   // let reader = new FileReader();  
  //   //reader.readAsText(input.files[0]);
  //   // reader.onload = () => {  
  //   //   let csvData = reader.result;  
  //   // }; 
  // };


  public async readCsvFile(fileName: string, header: boolean): Promise<any> {
    let fileData: any;

    await this.getFileViaHttp(fileName).then(
      data => {
        fileData = data;
      },
      error => {
        console.error('There was an error! [csvReadService.getSuburbGeocode()]', error);
      }
    );

    // Parse the CSV into an array
    const parsedData = await this.parseCsv(fileData, header);
    return parsedData;
  }

  private async getFileViaHttp(fileName: string): Promise<any> {
    return await this.http.get(fileName, {responseType: 'blob'}).toPromise();
  }

  private async parseCsv(fileData, header: boolean): Promise<any> {
    return new Promise((resolve, reject) => {
      this.papa.parse(fileData, {
        header,
        skipEmptyLines: true,
        // transform: (value: string): string => {
        //   return value.trim();
        // },
        complete: (results) => {
          return resolve(results);
        },
        error: (error) => {
          return reject(error);
        },
      });
    });
  }

}
