<h1 mat-dialog-title>{{data.title}}</h1>
<div mat-dialog-content>
    <div class="player-wrapper">
        <vg-player>
            <video #media [vgMedia]="media" id="singleVideo" preload="auto" controls>
                <source src="{{data.url}}" type="video/mp4">
                <!-- <source src="http://static.videogular.com/assets/videos/videogular.mp4" type="video/mp4"> -->
                <!-- <source src="http://static.videogular.com/assets/videos/videogular.ogg" type="video/ogg"> -->
                <!-- <source src="http://static.videogular.com/assets/videos/videogular.webm" type="video/webm"> -->
            </video>
        </vg-player>
    </div>
</div>
<div mat-dialog-actions>
    <button mat-button mat-dialog-close>Close</button>
</div>


