<mat-expansion-panel #expansionPanel class="">
    <mat-expansion-panel-header>
        <mat-panel-title>Associated Records</mat-panel-title>
        <mat-panel-description>
            <span class='' [hidden]="!featureSelected">{{numberOfAssociatedRecordsMsg}}</span>
            <!-- [hidden]="galleryLoaded" -->
            <span class='' [hidden]="featureSelected">Select an item from the map or the table</span>
            <!-- <mat-icon>account_circle</mat-icon> -->
        </mat-panel-description>
    </mat-expansion-panel-header>

    <!-- <mat-tab-group animationDuration="0ms" [color]="'accent'" [backgroundColor]="'primary'"> -->
    <mat-tab-group animationDuration="0ms">
        <mat-tab label="Contacts" *ngIf='showContacts'>
            <app-esri-view-table #contactsTable></app-esri-view-table>
        </mat-tab>

        <mat-tab label="Assets" *ngIf='showAssets'>
            <app-esri-view-table #assetsTable></app-esri-view-table>
        </mat-tab>

        <mat-tab label="Risks" *ngIf='showRisks'>
            <app-esri-view-table #risksTable></app-esri-view-table>
        </mat-tab>

        <mat-tab label="Projects" *ngIf='showProjects'>
            <app-esri-view-table #projectsTable></app-esri-view-table>
        </mat-tab>

        <mat-tab label="Incidents" *ngIf='showIncidents'>
            <app-esri-view-table #incidentsTable></app-esri-view-table>
        </mat-tab>

        <mat-tab label="Maintenance" *ngIf='showMaintenance'>
            <app-esri-view-table #maintenanceTable></app-esri-view-table>
        </mat-tab>
    </mat-tab-group>

</mat-expansion-panel>