<app-header>
    <span title>{{title}}</span>
    <span toolbar-extras>

        <!-- <button fxHide fxShow.gt-xs mat-button [matMenuTriggerFor]="selectedControlStatusMenu"><mat-icon>list_alt</mat-icon>&nbsp;Select control status...</button>
        <button fxShow fxHide.gt-xs mat-icon-button [matMenuTriggerFor]="selectedControlStatusMenu" aria-label="Example icon-button with a menu">
            <mat-icon>list_alt</mat-icon>
        </button>
        <mat-menu #selectedControlStatusMenu="matMenu">
            <button mat-menu-item (click)='controlStatusSelectedEvent("REVIEW")'>Under Review</button>
            <button mat-menu-item (click)='controlStatusSelectedEvent("PROPOSED")'>Proposed</button>
            <button mat-menu-item (click)='controlStatusSelectedEvent("IMPLEMENTED")'>Implemented</button>
            <button mat-menu-item (click)='controlStatusSelectedEvent("REJECTED")'>Rejected</button>
            <button mat-menu-item (click)='controlStatusSelectedEvent("All")'>All</button>
        </mat-menu> -->

        <!-- <button fxHide fxShow.gt-xs mat-button [matMenuTriggerFor]="selectedResidualMenu"><mat-icon>list_alt</mat-icon>&nbsp;Select residual risk...</button>
        <button fxShow fxHide.gt-xs mat-icon-button [matMenuTriggerFor]="selectedResidualMenu" aria-label="Example icon-button with a menu">
            <mat-icon>list_alt</mat-icon>
        </button>
        <mat-menu #selectedResidualMenu="matMenu">
            <button mat-menu-item (click)='residualRiskSelectedEvent("Extreme")'>Extreme</button>
            <button mat-menu-item (click)='residualRiskSelectedEvent("High")'>High</button>
            <button mat-menu-item (click)='residualRiskSelectedEvent("Moderate")'>Moderate</button>
            <button mat-menu-item (click)='residualRiskSelectedEvent("Low")'>Low</button>
            <button mat-menu-item (click)='residualRiskSelectedEvent("All")'>All</button>
        </mat-menu> -->
    </span>
</app-header>

<div *ngIf='!thinking' style="height: 4px;"></div>
<div *ngIf='thinking'><mat-progress-bar mode="query"></mat-progress-bar></div>

<!-- Slider (Date) Row -->
<div fxLayout='row' fxLayoutAlign="space-between center" class='date-slader-row'>
    <!-- Date Range Selector -->
    <div class='chart-box' [ngClass]="{'dark-chart-box': darkMode}" style="width: 100%;">
        <div fxFlex="200px" class='chart-title' [ngClass]="{'dark-chart-title': darkMode}">Incident Date Range</div>
        <div #timeSlider [hidden]='!loaded' class='time-slider' [ngClass]="{'dark': darkMode}">
            <ngx-slider *ngIf='timeSliderReady'
                [(value)]="dateFrom"
                [(highValue)]="dateTo"
                [(options)]="timeSliderOptions"
                (userChangeEnd)="timeSliderDateRangeChange($event)"
            ></ngx-slider>
        </div>
    </div>
</div>

<!-- Chips Row -->
<div fxLayout='row' fxLayoutAlign="center center" class='chips-row'>
    <div>
        <mat-chip-list #chipListAll aria-label="">
            <span *ngFor="let chart of dashboardCharts">
                <mat-chip [selectable]="true" [removable]="removableChip" *ngIf="chart.visibleChip" (removed)="removeChip(chart.chartName)">
                    {{chart.label}}
                    <mat-icon matChipRemove *ngIf="removableChip">cancel</mat-icon>
                </mat-chip>
            </span>
        </mat-chip-list>
    </div>
</div>

<!-- Dashboard Row 1 -->
<div fxLayout='row' fxLayoutGap="20px" fxLayoutAlign="space-between center" class='chart-row chart-row-3'>
    <!-- Chart 1 -->
    <div fxFlex="33" class='chart-box' [ngClass]="{'dark-chart-box': darkMode}">
        <div fxLayout='column' fxLayoutAlign="space-between center">
            <div [ngClass]="{'dark': darkMode}">
                <div fxLayout='row' fxShow fxHide.lt-md fxLayoutAlign="space-between center">
                    <ngx-charts-advanced-pie-chart 
                        [view]="[0.33 * chartWidth, chartHeight]"
                        [results]="getChartData('STATUS')"
                        [label]="'Number of incidents selected'"
                        [gradient]="true" 
                        [scheme]='customColorScheme'
                        (select)="selectChart($event, 'STATUS', 'DomIncidentStatus', 'Status', false)">
                    </ngx-charts-advanced-pie-chart>                
                </div>
                <div fxLayout='row' fxHide fxShow.lt-md fxLayoutAlign="space-between center">
                    <ngx-charts-pie-chart
                        [view]="[chartWidth, chartHeight]"
                        [results]="getChartData('STATUS')"
                        [labels]="true"
                        [gradient]="true" 
                        [scheme]='customColorScheme'
                        (select)="selectChart($event, 'STATUS', 'DomIncidentStatus', 'Status', false)">
                    </ngx-charts-pie-chart>
                </div>
            </div>         
            <div>
                <span class='chart-title' [ngClass]="{'dark-chart-title': darkMode}">Status</span>
            </div>
        </div>
    </div>
    <!-- Chart 2 -->
    <div fxFlex="33" class='chart-box' [ngClass]="{'dark-chart-box': darkMode}">
        <div fxLayout='column' fxLayoutAlign="space-between center">
            <div [ngClass]="{'dark': darkMode}">
                <ngx-charts-bar-vertical 
                    [view]="[0.33 * chartWidth, chartHeight]"
                    [results]="getChartData('FAIRWAY')"
                    [xAxisLabel]="'Hole'" 
                    [yAxisLabel]="'Count'"
                    [showXAxisLabel]="false"
                    [showYAxisLabel]="false"
                    [xAxis]="true"
                    [yAxis]="true"
                    [trimXAxisTicks]="true"
                    [maxXAxisTickLength]='5'
                    [rotateXAxisTicks]='true'  
                    [tooltipDisabled]='true' 
                    [gradient]="true"
                    [roundEdges]='false'
                    [noBarWhenZero]='true'
                    [showDataLabel]='true'
                    [scheme]='customColorScheme'
                    (select)="selectChart($event, 'FAIRWAY', 'DomFairway', 'FairwayNumber', false, true)">
                </ngx-charts-bar-vertical>
            </div>
            <div>
                <span class='chart-title' [ngClass]="{'dark-chart-title': darkMode}">Fairway</span>
            </div>
        </div>
    </div>
    <!-- Chart 3 -->
    <div fxFlex="33" class='chart-box' [ngClass]="{'dark-chart-box': darkMode}">
        <div fxLayout='column' fxLayoutAlign="space-between center">
            <div [ngClass]="{'dark': darkMode}">
                <ngx-charts-pie-chart 
                    [view]="[0.33 * chartWidth, chartHeight]"
                    [results]="getChartData('PREMISE')"
                    [labels]="true"
                    [gradient]="true" 
                    [scheme]='customColorScheme'
                    (select)="selectChart($event, 'PREMISE', 'DomYesNo', 'OccurredOnPremise', true)">
                </ngx-charts-pie-chart>
            </div>
            <div>
                <span class='chart-title' [ngClass]="{'dark-chart-title': darkMode}">Occured on the course</span>
            </div>
        </div>
    </div>
</div>

<!-- Dashboard Row 2 -->
<div fxLayout='row' fxLayoutGap="20px" fxLayoutAlign="space-between center" class='chart-row chart-row-3'>
    <!-- Chart 1 -->    
    <div fxFlex="33" class='chart-box' [ngClass]="{'dark-chart-box': darkMode}">
        <div fxLayout='column' fxLayoutAlign="space-between center">
            <div [ngClass]="{'dark': darkMode}">
                <ngx-charts-bar-vertical 
                    [view]="[0.33 * chartWidth, chartHeight]"
                    [results]="getChartData('CATEGORY')"
                    [xAxisLabel]="'Category'" 
                    [yAxisLabel]="'Count'"
                    [showXAxisLabel]="false"
                    [showYAxisLabel]="false"
                    [xAxis]="true"
                    [yAxis]="true"
                    [trimXAxisTicks]="true"
                    [maxXAxisTickLength]='5'
                    [rotateXAxisTicks]='true'
                    [tooltipDisabled]='false' 
                    [gradient]="true" 
                    [roundEdges]='false' 
                    [noBarWhenZero]='true'
                    [showDataLabel]='true'
                    [scheme]='customColorScheme'
                    (select)="selectChart($event, 'CATEGORY', 'LovIncidentCategory', 'IncidentCategoryId', true)">
                </ngx-charts-bar-vertical>
            </div>
            <div>
                <span class='chart-title' [ngClass]="{'dark-chart-title': darkMode}">Category</span>
            </div>
        </div>
    </div>
    <!-- Chart 2 --> 
    <div fxFlex="33" class='chart-box' [ngClass]="{'dark-chart-box': darkMode}">
        <div fxLayout='column' fxLayoutAlign="space-between center">
            <div [ngClass]="{'dark': darkMode}">
                <ngx-charts-bar-vertical-2d 
                    [view]="[0.33 * chartWidth, chartHeight]"
                    [results]="getChartData('SUB-CATEGORY')"
                    [xAxisLabel]="'Category'" 
                    [yAxisLabel]="'Count'"
                    [showXAxisLabel]='false'
                    [showYAxisLabel]='false'
                    [xAxis]='true'
                    [yAxis]='true'
                    [trimXAxisTicks]='false'
                    [maxXAxisTickLength]='10'
                    [rotateXAxisTicks]='true'    
                    [tooltipDisabled]='false' 
                    [gradient]='true'
                    [noBarWhenZero]='true'  
                    [roundEdges]='false'
                    [barPadding]='1'
                    [groupPadding]='4'
                    [showDataLabel]='true'
                    [scheme]='customColorScheme'
                    (select)="selectChart_SubDomain($event, 'SUB-CATEGORY')">
                </ngx-charts-bar-vertical-2d>
            </div>
            <div>
                <span class='chart-title' [ngClass]="{'dark-chart-title': darkMode}">Sub-Category</span>
            </div>
        </div>
    </div>
    <!-- Chart 3 -->
    <div fxFlex="33" class='chart-box' [ngClass]="{'dark-chart-box': darkMode}">
        <div fxLayout='column' fxLayoutAlign="space-between center">
            <div [ngClass]="{'dark': darkMode}">
                <ngx-charts-bar-vertical 
                    [view]="[0.33 * chartWidth, chartHeight]"
                    [results]="getChartData('MED-LGL')"
                    [xAxisLabel]="'Actions'" 
                    [yAxisLabel]="'Count'"
                    [showXAxisLabel]="false"
                    [showYAxisLabel]="false"
                    [xAxis]="true"
                    [yAxis]="true"
                    [trimXAxisTicks]="true"
                    [maxXAxisTickLength]='5'
                    [rotateXAxisTicks]='true'
                    [tooltipDisabled]='false' 
                    [gradient]="true" 
                    [roundEdges]='false' 
                    [noBarWhenZero]='true'
                    [showDataLabel]='true'
                    [scheme]='customColorScheme'>
                </ngx-charts-bar-vertical>                                        
            </div>
            <div>
                <span class='chart-title' [ngClass]="{'dark-chart-title': darkMode}">Actions taken</span>
            </div>
        </div>
    </div>
</div>    

<!-- Dashboard Row 3 -->
<div fxLayout='row' fxLayoutGap="20px" fxLayoutAlign="space-between center" class='chart-row chart-row-4'>
    <!-- Chart 1 -->
    <div fxFlex="25" class='chart-box' [ngClass]="{'dark-chart-box': darkMode}">
        <div fxLayout='column' fxLayoutAlign="space-between center">
            <div [ngClass]="{'dark': darkMode}">
                <ngx-charts-bar-vertical 
                    [view]="[0.25 * chartWidth, chartHeight]"
                    [results]="getChartData('AMOUNTS')"
                    [xAxisLabel]="'Action Taken'" 
                    [yAxisLabel]="'($)'"
                    [showXAxisLabel]="false"
                    [showYAxisLabel]="false"
                    [xAxis]="true"
                    [yAxis]="true"
                    [trimXAxisTicks]="true"
                    [maxXAxisTickLength]='5'
                    [rotateXAxisTicks]='true'
                    [tooltipDisabled]='false' 
                    [gradient]="true" 
                    [roundEdges]='false' 
                    [noBarWhenZero]='true'
                    [showDataLabel]='true'
                    [scheme]='customColorScheme'>
                </ngx-charts-bar-vertical>  
                </div>
            <div>
                <span class='chart-title' [ngClass]="{'dark-chart-title': darkMode}">Total Amounts ($)</span>
            </div>
        </div>
    </div>
    <!-- Chart 2 -->
    <div fxFlex="25" class='chart-box' [ngClass]="{'dark-chart-box': darkMode}">
        <div fxLayout='column' fxLayoutAlign="space-between center">
            <div [ngClass]="{'dark': darkMode}">
                <ngx-charts-pie-chart 
                    [view]="[0.25 * chartWidth, chartHeight]"
                    [results]="getChartData('INSURANCE')"
                    [labels]="true"
                    [gradient]="true" 
                    [scheme]='customColorScheme'
                    (select)="selectChart($event, 'INSURANCE', 'DomYesNo', 'Insurance', true)">
                </ngx-charts-pie-chart>
            </div>
            <div>
                <span class='chart-title' [ngClass]="{'dark-chart-title': darkMode}">Insurance</span>
            </div>
        </div>
    </div>
    <!-- Chart 3 -->
    <div fxFlex="25" class='chart-box' [ngClass]="{'dark-chart-box': darkMode}">
        <div fxLayout='column' fxLayoutAlign="space-between center">
            <div [ngClass]="{'dark': darkMode}">
                <ngx-charts-pie-chart 
                    [view]="[0.25 * chartWidth, chartHeight]"
                    [results]="getChartData('OUTCOME')"
                    [labels]="true"
                    [gradient]="true" 
                    [scheme]='customColorScheme'
                    (select)="selectChart($event, 'OUTCOME', 'DomIncidentOutcome', 'Outcome', true)">
                </ngx-charts-pie-chart>
            </div>
            <div>
                <span class='chart-title' [ngClass]="{'dark-chart-title': darkMode}">Outcome</span>
            </div>
        </div>
    </div>
    <!-- Chart 4 -->
    <div fxFlex="25" class='chart-box' [ngClass]="{'dark-chart-box': darkMode}">
        <div fxLayout='column' fxLayoutAlign="space-between center">
            <div [ngClass]="{'dark': darkMode}">
                <ngx-charts-pie-chart 
                    [view]="[0.25 * chartWidth, chartHeight]"
                    [results]="getChartData('PAIDBY')"
                    [labels]="true"
                    [gradient]="true" 
                    [scheme]='customColorScheme'
                    (select)="selectChart($event, 'PAIDBY', 'DomSettlementPaidBy', 'Insurance_SettlementPaidBy', true)">
                </ngx-charts-pie-chart>
            </div>
            <div>
                <span class='chart-title' [ngClass]="{'dark-chart-title': darkMode}">Paid by</span>
            </div>
        </div>
    </div>


</div>

<!-- Image Gallery Row -->
<div fxLayout='row' class='image-row'>
    <app-esri-attachment style='width: 100%'></app-esri-attachment> 
</div>

<!-- Map Row -->
<div fxLayout='row' class='map-row'>
    <app-esri-map fxLayout='row' style='width: 100%;' 
        [sidePanelWidth]='sidePanelWidth'  
        [showFullscreenWidget]='true' 
        [showLegendWidget]='true'
        [showLayerListWidget]='true'
        [legendLayerInfos]='legendLayerInfos'
        [hideBasemap]='false'
        [basemapName]='basemapName'
        (viewCreatedEvent)='viewCreatedEvent($event)'>

        <div #popupMenu [hidden]="!loaded">
            <app-esri-popup-menu [popupType]="'MENU'">  <!--  [latitude]='geometry.latitude' [longitude]='geometry.longitude' *ngIf='menuPopupVisible' -->
                <div class="popup-menu-items">
                    <!-- 'openEditFeatureDialog("EDIT")' -->

                    <button mat-menu-item *ngIf="selectedFeature" (click)='currentModeService.currentModeData.next("edit-attribute")'>
                        <mat-icon class='material-icons-sharp'>edit</mat-icon>
                        <span>Edit this item</span>
                    </button>

                    <button mat-menu-item *ngIf="!selectedFeature" (click)='createFeatureFromPopupMenuEvent()'>
                        <mat-icon class='material-icons-sharp'>add_location</mat-icon>
                        <span>Create a new item here</span>
                    </button>

                    <button mat-menu-item *ngIf="selectedFeature" (click)='moveFeatureFromPopupMenuEvent()'>
                        <mat-icon class='material-icons-sharp'>edit_location</mat-icon>
                        <span>Move the selected item here</span>
                    </button>

                    <!-- <button mat-menu-item (click)='reportBtnEvent()'>
                        <mat-icon class='material-icons-sharp'>text_snippet</mat-icon>
                        <span>Run the audit report</span>
                    </button> -->

                </div>
            </app-esri-popup-menu>
        </div>
        
        <div #popupData [hidden]="!loaded">
            <app-esri-popup-menu [popupType]="'DATA'">
                <div class="popup-data-items">
                    <app-esri-edit-form [readOnly]='"true"'></app-esri-edit-form>
                </div>
            </app-esri-popup-menu>   
        </div>
    </app-esri-map>
</div>

<!-- View Record Data Row -->
<div fxLayout='row' class='master-detail-row'>               
    <app-esri-edit-form-master-detail [showContacts]='true' [showAssets]='false' [showRisks]='false'></app-esri-edit-form-master-detail>
</div>

<!-- Table Data Row -->
<div *ngIf='showDataTable' fxLayout='row' class='table-data-row'>                        
    <app-esri-view-table (addButtonSelectedEvent)='addBtnEvent()'></app-esri-view-table>
</div>

<!-- Footer -->
<app-footer></app-footer>











        <!-- Asset -->
        <!-- <div fxLayout='row' fxLayoutAlign='space-between center' class='add-new-workflow-box' *ngIf='!assetHidden' @fadeInOut>
            <span class='add-new-workflow-text'>Do you want to link the incident to an asset?</span>
            <span class='add-new-workflow-button-row light-border'>
                <button mat-stroked-button [ngClass]="linkAsset ? 'selected' : ''" (click)='setCurrentMode("link-asset")'>Yes</button>
                <button mat-stroked-button [ngClass]="linkAsset === false ? 'selected' : ''" (click)='setCurrentMode("location")'>No</button>   
            </span>
        </div>
        <div fxLayout='row' fxLayoutAlign='space-between center' class='add-new-workflow-box-select' *ngIf='!assetSelectHidden' @fadeSlideInOut>                                       
            <span class='add-new-workflow-text'>Assets list here</span>
            <span class='add-new-workflow-button-row dark-border'>
                <button mat-stroked-button color="primary" (click)='setCurrentMode("location")'>OK</button>
            </span>                                        
        </div> -->
        <!-- <div *ngIf='!assetNextHidden' @fadeInSlideOut>
            <app-workflow-arrow></app-workflow-arrow>
        </div> -->

        <!-- Select Point -->
        <!-- <div fxLayout='row' fxLayoutAlign='space-between center' class='add-new-workflow-box' *ngIf='!addPointHidden' >
            <span class='add-new-workflow-text'>Select a point on the map...</span>
            <span class='add-new-workflow-button-row light-border'>
                <button mat-stroked-button color="primary" (click)='setCurrentMode("insurance")'>OK</button>  
            </span>
        </div> -->
        <!-- <div *ngIf='!addPointNextHidden' @fadeInSlideOut>
            <app-workflow-arrow></app-workflow-arrow>
        </div> -->

        <!-- Insurance -->
        <!-- <div fxLayout='row' fxLayoutAlign='space-between center' class='add-new-workflow-box' *ngIf='!insuranceHidden' @fadeInOut>
            <span class='add-new-workflow-text'>Will an insurance claim be made for this incident?</span>
            <span class='add-new-workflow-button-row light-border'>
                <button mat-stroked-button [ngClass]="insuranceClaim ? 'selected' : ''" (click)='setCurrentMode("insurance-claim")'>Yes</button>
                <button mat-stroked-button [ngClass]="insuranceClaim === false ? 'selected' : ''"(click)='setCurrentMode("insurance-claim-nil")'>No</button>   
            </span>
        </div> -->
        <!-- <div *ngIf='!insuranceNextHidden' @fadeInSlideOut>
            <app-workflow-arrow></app-workflow-arrow>
        </div> -->

        <!-- Risk -->
        <!-- <div fxLayout='row' fxLayoutAlign='space-between center' class='add-new-workflow-box' *ngIf='!riskHidden' @fadeInOut>
            <span class='add-new-workflow-text'>Do you want to link the incident to a risk item?</span>
            <span class='add-new-workflow-button-row light-border'>
                <button mat-stroked-button [ngClass]="linkRisk ? 'selected' : ''" (click)='setCurrentMode("link-risk")'>Yes</button>
                <button mat-stroked-button [ngClass]="linkRisk === false ? 'selected' : ''" (click)='setCurrentMode("link-risk-nil")'>No</button>   
            </span>
        </div> -->
        <!-- <div fxLayout='row' fxLayoutAlign='space-between center' class='add-new-workflow-box-select' *ngIf='!riskSelectHidden' @fadeSlideInOut>                                       
            <div style='width: 100%;' class='add-new-workflow-button-row dark-border'>
                <app-risk-search (riskSelectedEvent)='riskSelectedEvent($event)' (riskAcceptedEvent)='riskAcceptededEvent()' (riskClearedEvent)='riskClearedEvent()'></app-risk-search>
                <button mat-stroked-button color="primary" (click)='setCurrentMode("")'>OK</button>
            </div>    
        </div> -->
        <!-- <div *ngIf='!riskNextHidden' @fadeInOut>
            <app-workflow-arrow ></app-workflow-arrow>
        </div> -->
        




