import { Esri, FeatureLayerProperties, ElevationInfo, EsriRenderer_Polygon } from 'src/app/_esri/models/esri';  // Domain, CodedValue, UniqueIdField, GeometryProperties, SpatialReference, Field, GeometryPolygon
import { LayerIds } from 'src/app/_esri/models/layer-ids';

//

export class Course {

    public static TileLayers: FeatureLayerProperties = new FeatureLayerProperties(
        LayerIds.gisServer_AGOL_Tiles, LayerIds.folder_TileCourse, null, 'tileLayerCourse', 'Course', 'TILE'
    );

    public static GeneralHoleArea: FeatureLayerProperties = new FeatureLayerProperties(
        LayerIds.gisServer_Course, LayerIds.folder_Course, LayerIds.layerId_GeneralHoleArea, 'featureLayerGeneralHoleArea', 'General Hole Area'
    );

    public static TeeMarkerPair: FeatureLayerProperties = new FeatureLayerProperties(
        LayerIds.gisServer_Course, LayerIds.folder_Course, LayerIds.layerId_TeeMarkerPair, 'featureLayerTeeMarkerPair', 'Tee Marker'
    );

    public static TeeMarker: FeatureLayerProperties = new FeatureLayerProperties(
        LayerIds.gisServer_Course, LayerIds.folder_Course, LayerIds.layerId_TeeMarker, 'featureLayerTeeMarker', 'Tee Marker'
    );

    public static Hole: FeatureLayerProperties = new FeatureLayerProperties(
        LayerIds.gisServer_Course, LayerIds.folder_Course, LayerIds.layerId_Hole, 'featureLayerHole', 'Hole'
    );

    public static TeeingArea: FeatureLayerProperties = new FeatureLayerProperties(
        LayerIds.gisServer_Course, LayerIds.folder_Course, LayerIds.layerId_TeeingArea, 'featureLayerTeeingArea', 'Teeing Area'
    );

    public static PuttingGreen: FeatureLayerProperties = new FeatureLayerProperties(
        LayerIds.gisServer_Course, LayerIds.folder_Course, LayerIds.layerId_PuttingGreen, 'featureLayerPuttingGreen', 'Putting Green'
    );

    public static Bunker: FeatureLayerProperties = new FeatureLayerProperties(
        LayerIds.gisServer_Course, LayerIds.folder_Course, LayerIds.layerId_Bunker, 'featureLayerBunker', 'Bunker'
    );

    public static Fairway: FeatureLayerProperties = new FeatureLayerProperties(
        LayerIds.gisServer_Course, LayerIds.folder_Course, LayerIds.layerId_Fairway, 'featureLayerFairway', 'Fairway'
    );

    public static Sand: FeatureLayerProperties = new FeatureLayerProperties(
        LayerIds.gisServer_Course, LayerIds.folder_Course, LayerIds.layerId_Sand, 'featureLayerSand', 'Sand'
    );

    public static Pond: FeatureLayerProperties = new FeatureLayerProperties(
        LayerIds.gisServer_Course, LayerIds.folder_Course, LayerIds.layerId_Pond, 'featureLayerPond', 'Pond'
    );

    public static CourseExtent: FeatureLayerProperties = new FeatureLayerProperties(
        LayerIds.gisServer_Course, LayerIds.folder_Course, LayerIds.layerId_CourseExtent, 'featureLayerCourseExtent', 'Course Extent'
    );

    public static hideTeeMarker(): FeatureLayerProperties {
        Course.TeeMarker.visible = false;
        return Course.TeeMarker;
    }

    // public static createLayerProperties_TeeMarker(is3D: boolean): FeatureLayerProperties {
    //     if (is3D === true) {
    //         let elevationInfo = new ElevationInfo();
    //         elevationInfo.mode = 'on-the-ground';
    //         Course.TeeMarker.elevationInfo = elevationInfo;
    //     }
   
    //     //Course.GeneralHoleArea.renderer = Esri.dashOutlineRenderer;
    //     //Course.GeneralHoleArea.minScale = 2000;

    //     return Course.TeeMarker;
    // }

    public static createLayerProperties_GeneralHoleArea(is3D: boolean, opacity?: number): FeatureLayerProperties {
        let labelInfo = { 
            symbol: null,
            labelExpressionInfo: {
                expression: "$feature.FairwayNumber"
            },
            labelPlacement: null,
            maxScale: 0,
            minScale: 5000,
            where: "FairwayNumber <> 0"
        };

        if (is3D === true) {

            labelInfo.symbol = Esri.textCallout3d;
            labelInfo.labelPlacement = 'above-center';

            let elevationInfo = new ElevationInfo();
            elevationInfo.mode = 'on-the-ground';
            Course.GeneralHoleArea.elevationInfo = elevationInfo;
        }
        else {
            labelInfo.symbol = Esri.text16pxHaloSymbol;
        }

        Course.GeneralHoleArea.labelsVisible = false;
        Course.GeneralHoleArea.labelingInfo = labelInfo;     
        Course.GeneralHoleArea.renderer = Esri.dashOutlineRenderer;
        Course.GeneralHoleArea.minScale = 5000;
        (opacity) ? Course.GeneralHoleArea.opacity = opacity : null;

        return Course.GeneralHoleArea;
    }

    public static createLayerProperties_Fairway(is3D: boolean, opacity?: number, labelsOnly?: boolean): FeatureLayerProperties {

        let renderer;

        let labelInfo = { 
            symbol: null,
            labelExpressionInfo: {
                expression: "$feature.FairwayNumber"
            },

            labelPlacement: null,
            maxScale: 0,
            minScale: 5000,
            where: "FairwayNumber <> 0"
        };

        if (is3D === true) {
            labelInfo.symbol = Esri.textCallout3d;
            labelInfo.labelPlacement = 'above-center';

            let elevationInfo = new ElevationInfo();
            elevationInfo.mode = 'on-the-ground';
            Course.Fairway.elevationInfo = elevationInfo;
        }
        else {
            labelInfo.symbol = Esri.text16pxHaloSymbol;
        }

        if (labelsOnly === true) {
            renderer = new EsriRenderer_Polygon([0, 0, 0, 0.0], [0, 0, 0, 0.0], 0);
        }
        else {
            renderer = new EsriRenderer_Polygon([76, 115, 0, 0.65], [76, 115, 0, 0.65], 0);
        }
      
        Course.Fairway.renderer = renderer.renderer;
        Course.Fairway.minScale = 20000;
        (opacity) ? Course.Fairway.opacity = opacity : null;
        Course.Fairway.labelsVisible = true;
        Course.Fairway.labelingInfo = labelInfo;     

        return Course.Fairway;
    }

    public static createLayerPropertiesList(is3D: boolean, opacity?: number): FeatureLayerProperties[] {

        let renderer;
        let featureLayerProperties: FeatureLayerProperties[] = [];

        // Set the elevation info if the layers are required to be shown in a scene
        if (is3D === true) {
            let elevationInfo = new ElevationInfo();
            elevationInfo.mode = 'on-the-ground';

            Course.CourseExtent.elevationInfo = elevationInfo;
            //Course.GeneralHoleArea.elevationInfo = elevationInfo;
            //Course.Fairway.elevationInfo = elevationInfo;
            Course.Bunker.elevationInfo = elevationInfo;
            Course.Sand.elevationInfo = elevationInfo;
            Course.Pond.elevationInfo = elevationInfo;
            Course.TeeingArea.elevationInfo = elevationInfo;
            Course.TeeMarker.elevationInfo = elevationInfo;
            Course.TeeMarkerPair.elevationInfo = elevationInfo; 
            Course.PuttingGreen.elevationInfo = elevationInfo;
            Course.Hole.elevationInfo = elevationInfo;
        }

        // **************************************************************
        // Order is important here - it controls the display order
        // Last one listed is on top
        // **************************************************************

        // Set the renderers and add the layers to a list
        renderer = new EsriRenderer_Polygon([112, 168, 0, 0.5], [112, 168, 0, 0.5], 0);
        //renderer = new EsriRenderer_Polygon([255, 0, 0, 0.5], [255, 0, 0, 0.5], 0);
        Course.CourseExtent.renderer = renderer.renderer;
        Course.CourseExtent.minScale = 50000;
        (opacity) ? Course.CourseExtent.opacity = opacity : null;
        featureLayerProperties.push(Course.CourseExtent);

        featureLayerProperties.push(this.createLayerProperties_GeneralHoleArea(is3D, opacity));

        featureLayerProperties.push(this.createLayerProperties_Fairway(is3D, opacity));

        // renderer = new EsriRenderer_Polygon([76, 115, 0, 0.65], [76, 115, 0, 0.65], 0);
        // Course.Fairway.renderer = renderer.renderer;
        // Course.Fairway.minScale = 20000;
        // featureLayerProperties.push(Course.Fairway);

        renderer = new EsriRenderer_Polygon([255, 255, 190, 0.65], [255, 255, 190, 0.65], 0);
        Course.Bunker.renderer = renderer.renderer;
        Course.Bunker.minScale = 10000;
        (opacity) ? Course.Bunker.opacity = opacity : null;
        featureLayerProperties.push(Course.Bunker);

        renderer = new EsriRenderer_Polygon([254, 255, 220, 0.55], [254, 255, 220, 0.55], 0);
        Course.Sand.renderer = renderer.renderer;
        Course.Sand.minScale = 10000;
        (opacity) ? Course.Sand.opacity = opacity : null;
        featureLayerProperties.push(Course.Sand);

        renderer = new EsriRenderer_Polygon([49, 130, 189, 1.0], [49, 130, 189, 1.0], 0);
        Course.Pond.renderer = renderer.renderer;
        Course.Pond.minScale = 10000;
        (opacity) ? Course.Pond.opacity = opacity : null;
        featureLayerProperties.push(Course.Pond);

        renderer = new EsriRenderer_Polygon([101, 168, 67, 0.9], [101, 168, 67, 1.0], 1);
        Course.TeeingArea.renderer = renderer.renderer;
        Course.TeeingArea.minScale = 10000;
        (opacity) ? Course.TeeingArea.opacity = opacity : null;
        featureLayerProperties.push(Course.TeeingArea);

        Course.TeeMarker.maxScale = 2000;
        Course.TeeMarker.minScale = 5000;
        (opacity) ? Course.TeeMarker.opacity = opacity : null;
        featureLayerProperties.push(Course.TeeMarker);

        Course.TeeMarkerPair.minScale = 2000;
        (opacity) ? Course.TeeMarkerPair.opacity = opacity : null;
        featureLayerProperties.push(Course.TeeMarkerPair);

        renderer = new EsriRenderer_Polygon([101, 168, 67, 0.9], [101, 168, 67, 1.0], 1);
        Course.PuttingGreen.renderer = renderer.renderer;
        Course.PuttingGreen.minScale = 10000;
        (opacity) ? Course.PuttingGreen.opacity = opacity : null;
        featureLayerProperties.push(Course.PuttingGreen);
        
        Course.Hole.minScale = 5000;
        (opacity) ? Course.Hole.opacity = opacity : null;
        featureLayerProperties.push(Course.Hole);

        return featureLayerProperties;
    }
}
