import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-workflow-arrow',
  templateUrl: './workflow-arrow.component.html',
  styleUrls: ['./workflow-arrow.component.scss']
})
export class WorkflowArrowComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
