import { Component, OnInit, OnDestroy, AfterViewInit, ChangeDetectorRef, ChangeDetectionStrategy, ElementRef } from '@angular/core';
import { combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { BaseFeaturePage } from 'src/app/_esri/models/base-feature-page';
import { FeaturesService } from 'src/app/_esri/services/features.service';
import { CourseService } from 'src/app/_course/services/course.service';
import { SurroundsService } from 'src/app/_course/services/surrounds.service';
import { DashboardChart } from 'src/app/_esri/models/esri';
//import { RiskService } from 'src/app/risk/services/risk.service';
import { Global } from 'src/app/_globals/models/global';
import { CurrentModeService, CurrentModeData } from 'src/app/_globals/services/current-mode.service';
import { GeocodeDataService } from 'src/app/_globals/services/geocode-data.service';
import { FormChangeService } from 'src/app/_globals/services/form-change.service';
import { FormFieldChangeService } from 'src/app/_globals/services/form-field-change.service';
import { FormFieldUpdateService } from 'src/app/_globals/services/form-field-update.service';
import { RiskAttributes, Risk, RiskMatrix } from 'src/app/risk/models/risk';
import { RiskMatrixComponent } from 'src/app/risk/components/risk-matrix/risk-matrix.component';
import { DomainLovService } from 'src/app/_esri/services/domain-lov.service';
import { LovDataService } from 'src/app/_globals/services/lov-data.service';
import { ButtonClickService } from 'src/app/_globals/services/button-click.service';

@Component({
  selector: 'app-risk',
  templateUrl: './risk.component.html',
  styleUrls: [
    './risk.component.scss',
    '../_styles/base-feature-page.scss'
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class RiskComponent extends BaseFeaturePage implements OnInit, OnDestroy, AfterViewInit {

  title = 'Risk Register';
  subTitle = 'A register of identified risks';
  idType = 'RISK';
  idTypeFieldName = 'RiskId';
  showTimeSliderWidget = false;
  //dateFilterAttributeName = 'Date';
  allowNoLocation = true;

  showDataTable = true;
  showDataCards = false;
  //showBaseLayers = true;
  darkMode = false;

  // courseLayerOpacity = 0.3;
  // surroundsLayerOpacity = 0.1;
  // boundaryLayerOpacity = 0.3;
  // hillshadeLayerOpacity = 0.05;

  parentLovWatchList = [{ parentLovKey: 'RiskCategoryId', childLovName: 'LovRiskSubCategory', childLovKey: 'RiskSubCategoryId' }];
  fieldChangeWatchList = ['InherentRisk_Likelihood', 'InherentRisk_Consequence', 'ResidualRisk_Likelihood', 'ResidualRisk_Consequence'];

  dashboardCharts: DashboardChart[] = [
    { chartName: 'RESIDUAL-RISK' },
    { chartName: 'INHERENT-RISK' },
    { chartName: 'CATGEORY' },
    { chartName: 'SUB-CATGEORY' },
    { chartName: 'CONTROL-STATUS' },
    { chartName: 'EFFECTIVENESS' },
    { chartName: 'OWNER' },
    { chartName: 'RESPONSIBILITY' }
  ];

  public riskColorScheme = [
    {
      name: 'Low',
      value: '#4CA32E'
    },
    {
      name: 'Moderate',
      value: '#D47A0A'
    },
    {
      name: 'High',
      value: '#D4380A'
    },
    {
      name: 'Extreme',
      value: '#88102A'
    }
  ];

  // public chartColorScheme = {
  //   domain: ['#5AA454', '#C7B42C', '#FF6F00', '#A10A28']
  // };

  // Risk Matrix
  private _inherentRisk_Likelihood: any;
  private _inherentRisk_Consequence: any;
  private _residualRisk_Likelihood: any;
  private _residualRisk_Consequence: any;

  public matrixHeader = RiskMatrix.MatrixHeader_5x5;
  public matrixData = RiskMatrix.MatrixData_5x5_C;
  public matrixPanelHidden: boolean = true;

  constructor(
    protected ref: ChangeDetectorRef,
    // protected featuresService: FeaturesService,
    // protected courseService: CourseService,
    // protected surroundsService: SurroundsService,
    protected dialog: MatDialog,
    protected currentModeService: CurrentModeService,
    protected geocodeDataService: GeocodeDataService,
    protected formChangeService: FormChangeService,
    protected formFieldChangeService: FormFieldChangeService,
    protected formFieldUpdateService: FormFieldUpdateService,
    // protected lovDataService: LovDataService,
    // protected domainLovService: DomainLovService,
    //protected buttonClickService: ButtonClickService,
    protected elementRef: ElementRef,
    //
    private _bottomSheet: MatBottomSheet,

  ) {
    super(
      ref,
      // featuresService,
      // courseService,
      // surroundsService,
      dialog,
      currentModeService,
      geocodeDataService,
      formChangeService,
      formFieldChangeService,
      formFieldUpdateService,
      // lovDataService,
      // domainLovService,
      // buttonClickService,
      elementRef
    );
  }

  ngOnInit(): void {
    this.thinking = true;
    // Get WorkRequest layers, features, lovs, domains, etc
    this.featureLayerObject = new Risk();
    super.ngOnInit();
  }

  // // View Created Event
  // public viewCreatedEvent(view: any) {
  //   super.viewCreatedEvent(view);

  //   this.esriMapComponent.allowNoLocation = this.allowNoLocation;
  //   //this.esriMapComponent.hasLocation = false;

  //   // Wait until the base layers are loaded and then get the layer of interest (it will then be on top)       // Or see how I can force this layer to be on top
  //   this.riskService.loadRiskRegisterLayer(this.is3D).then((layer) => {
  //     this.featureLayer = layer;

  //     this.legendLayerInfos = [{
  //       layer: this.featureLayer,
  //       title: "Legend"
  //     }]

  //     // Get the date range from the feature layer (FYI - layer must be time enabled)
  //     //this.featuresService.queryTimeExtent(layer).then( timeExtent => {
  //     //  this.dateRangeInfo = Global.getDateRangeWithFY(timeExtent.start, timeExtent.end);
  //     //  this.initialiseTimeSlider(); 
  //     this.displayMapFeatures();
  //     //});

  //   });
  // }

  //
  // Get attributes from feature set and assign to class "featureAttributes", (view table, and view cards )- calc virtual fields
  //
  protected refreshDisplay() {
    this.featureLayerObject.updateVirtualFields();
    super.refreshDisplay();
  }

  // Build data for charts
  protected createCharts() {
    super.createCharts();
    ////this.dashboardCharts.find(x => x.chartName == 'x').summaryData = this.createChartDataSet_CountFieldValues(['MedicalActionRequired','LegalActionTaken'], ['YES', 'YES'], ['Medical','Legal']);
    ////this.dashboardCharts.find(x => x.chartName == 'x').summaryData = this.createChartDataSet_SumFieldValues(['Insurance_ClaimAmount', 'Insurance_SettlementAmount'], ['Claim','Settlement']);

    this.dashboardCharts.find(x => x.chartName == 'RESIDUAL-RISK').summaryData = this.createChartDataSet('LovRiskRating', 'ResidualRisk_Rating', true);
    this.dashboardCharts.find(x => x.chartName == 'INHERENT-RISK').summaryData = this.createChartDataSet('LovRiskRating', 'InherentRisk_Rating', true);
    this.dashboardCharts.find(x => x.chartName == 'CATGEORY').summaryData = this.createChartDataSet('LovRiskCategory', 'RiskCategoryId', true);

    ////this.dashboardCharts.find(x => x.chartName == 'SUB-CATGEORY').summaryData = this.createChartDataSet('LovRiskSubCategory', 'RiskSubCategoryId', true);

    this.dashboardCharts.find(x => x.chartName == 'CONTROL-STATUS').summaryData = this.createChartDataSet('DomRiskControlStatus', 'ControlStatus', true);
    this.dashboardCharts.find(x => x.chartName == 'EFFECTIVENESS').summaryData = this.createChartDataSet('DomRiskControlEffectiveness', 'ControlEffectiveness', true);
    this.dashboardCharts.find(x => x.chartName == 'OWNER').summaryData = this.createChartDataSet('LovStaff', 'RiskOwnerId', true);
    this.dashboardCharts.find(x => x.chartName == 'RESPONSIBILITY').summaryData = this.createChartDataSet('LovStaff', 'RiskResponsibilityId', true);


    console.log('this.dashboardCharts', this.dashboardCharts);
  }

  //
  // Workflow - Control button and editor behaviour based on current feature edit mode
  //

  protected setCurrentMode(currentModeData: CurrentModeData) {
    super.setCurrentMode(currentModeData);

    let mode = currentModeData.mode.toLowerCase();

    if (currentModeData.formName.toUpperCase() === 'FORM') {
      switch (mode) {

        case 'add':
          this.currentModeService.currentModeData.next("location-none");
          break;

        case 'location':
          this.esriMapComponent.hasLocation = true;
          this.esriMapComponent.locationNoneChoice = false;
          this.esriMapComponent.locationHidden = true;
          this.esriMapComponent.locationMap = true;
          this.esriMapComponent.locationAddress = false;
          break;

        case 'location-selected':
          // Hide the workflow box
          this.esriMapComponent.locationMap = false;
          this.esriMapComponent.locationAddress = false;
          this.esriMapComponent.locationNoneChoice = false;

          if (this.esriMapComponent.hasLocation === true) {
            this.defaultFieldValues.push({
              name: 'HasLocation',
              value: 'YES'
            });
          }
          else {
            this.defaultFieldValues.push({
              name: 'HasLocation',
              value: 'NO'
            });
          }

          this.openEditFeatureDialog(mode);
          break;

        case 'location-changed':
          console.log('make dirty location-changed', event);
          this.openEditFeatureDialog(mode, true);
          break;

        case 'edit-attribute':
          this.openEditFeatureDialog(mode);
          break;

        case 'form-submitted':
          break;
      }
    }
  }

  //
  // Form Changes Events
  //

  public featureFormFieldChangeEvent(event) {
    console.log('featureFormFieldChangeEvent', event);

    super.featureFormFieldChangeEvent(event);

    let fieldValue: any[] = [];
    let fieldUpdateData = {};
    let riskRating = '';

    switch (event.fieldName.toUpperCase()) {
      case 'INHERENTRISK_LIKELIHOOD':
        //InherentRisk_Likelihood
        this._inherentRisk_Likelihood = event.newValue;
        riskRating = RiskMatrix.getRiskRating(Global.riskMatrixSize, Global.riskMatrixVersion, this._inherentRisk_Likelihood, this._inherentRisk_Consequence);

        fieldValue.push({
          name: 'InherentRisk_Rating',
          value: riskRating
        });

        fieldUpdateData = {
          updateType: 'value',
          data: fieldValue
        }
        break;

      case 'INHERENTRISK_CONSEQUENCE':
        //InherentRisk_Consequence
        this._inherentRisk_Consequence = event.newValue;
        riskRating = RiskMatrix.getRiskRating(Global.riskMatrixSize, Global.riskMatrixVersion, this._inherentRisk_Likelihood, this._inherentRisk_Consequence);

        fieldValue.push({
          name: 'InherentRisk_Rating',
          value: riskRating
        });

        fieldUpdateData = {
          updateType: 'value',
          data: fieldValue
        }
        break;

      case 'RESIDUALRISK_LIKELIHOOD':
        //ResidualRisk_Likelihood
        this._residualRisk_Likelihood = event.newValue;
        //this._residualRisk_Consequence = this.selectedFeature.attributes.ResidualRisk_Consequence;
        riskRating = RiskMatrix.getRiskRating(Global.riskMatrixSize, Global.riskMatrixVersion, this._residualRisk_Likelihood, this._residualRisk_Consequence);

        fieldValue.push({
          name: 'ResidualRisk_Rating',
          value: riskRating
        });

        fieldUpdateData = {
          updateType: 'value',
          data: fieldValue
        }
        break;

      case 'RESIDUALRISK_CONSEQUENCE':
        //ResidualRisk_Consequence
        this._residualRisk_Consequence = event.newValue;
        //this._residualRisk_Likelihood = this.selectedFeature.attributes.ResidualRisk_Likelihood;
        riskRating = RiskMatrix.getRiskRating(Global.riskMatrixSize, Global.riskMatrixVersion, this._residualRisk_Likelihood, this._residualRisk_Consequence);

        fieldValue.push({
          name: 'ResidualRisk_Rating',
          value: riskRating
        });

        fieldUpdateData = {
          updateType: 'value',
          data: fieldValue
        }
        break;
    }

    // Update the field value / visibility in the current form
    console.log('Form Field Change', fieldUpdateData);
    if (fieldUpdateData) {
      this.formFieldUpdateService.formFieldUpdateData.next(fieldUpdateData);
    }
  }

  // Show Risk Matrix button
  public showRiskMatrixBtnEvent(): void {
    this._bottomSheet.open(RiskMatrixComponent);
  }

}
