import { Injectable } from '@angular/core';
import { FormControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { table } from 'console';
import { getTime, toDate } from 'date-fns'
import { EsriGraphic, FieldConfig, ApplyEditsResultMessage } from 'src/app/_esri/models/esri';
import { FeaturesService } from 'src/app/_esri/services/features.service';
import { NextIdService } from 'src/app/_esri/services/next-id.service';
//import { WorkForceService } from 'src/app/maintenance/services/work-force.service';

@Injectable({
  providedIn: 'root'
})

export class FormBuilderService {

  private _feature: any;

  constructor(
    public formBuilder: FormBuilder,
    private nextIdService: NextIdService,
    //private workForceService: WorkForceService
  ) { }

  public async generateFeatureForm(formFieldConfigs: any, feature: any): Promise<any> {

    let featureForm = this.formBuilder.group({});
    let index = 0;
    let stepFormGroup: FormGroup[] = [];
    let field: FieldConfig;

    // Set featue
    this._feature = feature;

    // Form steps
    for (let stepIdx in formFieldConfigs) {
      stepFormGroup[index] = this.formBuilder.group({});

      // Attributes in step section
      for (let fieldIdx in formFieldConfigs[stepIdx].fieldConfig) {
        // Get the value of the attribute and populate the form control with it
        field = formFieldConfigs[stepIdx].fieldConfig[fieldIdx];
        let value: any;

        if (feature) {
          //console.log('field.name', field.name);
          value = this.getFeatureValue(field.name);
          //console.log(value);

          // Convert epoch date to Date object
          if (field.editorType === 'date') {
            //console.log(value);
            if (value) {
              value = toDate(value);
            }                
          }
            //  date = new FormControl(new Date());
            //serializedDate = new FormControl((new Date()).toISOString());
        }
        else {
          // Check for default values
          if (field.defaultValue) {
            // Special treatment for dates
            if (field.editorType === 'date') {
              let dateValue = new Date();

              switch (field.defaultValue) {
                // case 'date+1':
                //   break;

                // case 'date-1':
                //   break;

                case 'date-now':
                default:
                  value = toDate(dateValue);
                  break;
              }
            }
            else {
              value = field.defaultValue;
            }
          }
        }

        // Create the form control and set validators
        let control;
        let validators = [];

        if (field.required === true) {
          validators.push(Validators.required);
        }
        
        if (field.maxLength) {
          validators.push(Validators.maxLength(field.maxLength));
        }

        control = new FormControl(value, validators);

        // if (field.editable === true) {
        //   control = new FormControl(value, validators);
        // }
        // else {
        //   console.log('control disabled', field.name);
        //   //control = new FormControl({value, disabled: true}, validators);
        //   //control = new FormControl(value, validators).disable({emitEvent: true});
        //   control = new FormControl(value, validators);
        //   control.disable({emitEvent: true});
        // }

        // Add to the array for the step
        stepFormGroup[index].addControl(field.name, control);
      }
      index++;
    }

    // Put the formArray together
    let formBuilderArray = this.formBuilder.array([]);

    for (let i = 0; i < stepFormGroup.length; i++) {
      formBuilderArray.push(stepFormGroup[i]);
    }

    featureForm = this.formBuilder.group({
      formArray: formBuilderArray
    });

    return featureForm;
  }

  public async submitFeatureForm(featureForm: any, feature: any, featureLayer: any, idType: string, idTypeFieldName: string, geometry: any, integration?: any): Promise<ApplyEditsResultMessage> {
    //console.log('MASTER submitFeatureForm');
    //console.log(geometry);
    //console.log(feature.geometry);

    // ************* Should check first if the form data is dirty, only continue to do update if it is **********************
    let result: ApplyEditsResultMessage = new ApplyEditsResultMessage();
    let featuresService: FeaturesService = new FeaturesService();
    let completeData: any[] = [];
    let params;
    let submitType: string = '';
    let recordId: string = '';
    let resultMessage: string = '';

    // Loop through each formArray and check if a field is a date field - convert it to an epoch date if it is 
    let re = /date/gi; 

    for (let i = 0; i < featureForm.value.formArray.length; i++) {
      for (let element in featureForm.value.formArray[i]) {
        //console.log('element:', element);
        if (element.toLowerCase().search(re) >= 0 ) {
          //console.log("Contains " ); 
          featureForm.value.formArray[i][element] = getTime(featureForm.value.formArray[i][element]);
          //console.log(this._featureForm.value.formArray[i][element]);
        }
      }
      // Join the form data into a single object
      Object.assign(completeData, featureForm.value.formArray[i]);
    }

    // Check if add or update
    if (feature) {
      // Existing Feature --> UPDATE
      submitType = 'UPDATE';
      console.log('Existing Feature --> UPDATE');

      // Update the values in the current 'this._feature' object with the updated values from the form
      Object.keys(completeData).forEach (name => {
        feature.attributes[name] = completeData[name];
      })

      let updateFeature: EsriGraphic;

      // if (featureLayer.isTable === false) {
      //   updateFeature = { 
      //     geometry: [],
      //     attributes: [] 
      //   };
      // }
      // else {
      //   updateFeature = { 
      //     attributes: [] 
      //   };
      // }

      // let updateFeature: EsriGraphic = { 
      //   geometry: [],
      //   attributes: [] 
      // };

      //updateFeature.attributes = feature.attributes;

      //query.geometry = resultsLayer2.graphics.items[0].geometry; 

      // Use the new geometry of the feature (if present)

      // Dont apply to tables, only feature layers
      if (featureLayer.isTable === false) {
        console.log('submitFeatureForm() Feature Layer is NOT a table, so geometry to be included');

        updateFeature = { 
          geometry: [],
          attributes: feature.attributes
        };

        //updateFeature.attributes = feature.attributes;

        if (geometry) {
          updateFeature.geometry = geometry;
        }
        else {
          updateFeature.geometry = feature.geometry;
        }

      }
      else {

        updateFeature = { 
          attributes: feature.attributes
        };

        //updateFeature.attributes = feature.attributes;

        console.log('submitFeatureForm() Feature Layer is a table, so no geometry included');
      }


      // Setup the applyEdits parameter with updates.
      const edits = {
        updateFeatures: [updateFeature]
      };
      params = edits;
    }
    else {
      // New Feature --> ADD
      submitType = 'ADD';
      console.log('New Feature --> ADD');
      let attributes = {};

      for (let name of Object.keys(completeData)) {
        // Look for Id field
        if ((idTypeFieldName) && (name.toUpperCase() === idTypeFieldName.toUpperCase())) {
          console.log('submitFeatureForm key field found', name);

          //await this.nextIdService.getNextId_API(idType).then(
          await this.nextIdService.getNextId(idType).then(
            data => {
              recordId = data;
              console.log('submitFeatureForm getNextId', data);
              attributes[name] = data; 
            },
            error => {
              console.error('There was an error! [nextIdService.getNextId()]', error);
            }
          );
        }
        else {
          attributes[name] = completeData[name];
        }
      }

      // Create a new feature
      // let newFeature: EsriGraphic = { 
      //   geometry: [],
      //   attributes: [] 
      // };

      let newFeature: EsriGraphic;

      if (featureLayer.isTable === false) {
        newFeature = { 
          geometry: [],
          attributes: [] 
        };

        newFeature.attributes = attributes;
        newFeature.geometry = geometry;
      }
      else {
        newFeature = { 
          attributes: [] 
        };

        newFeature.attributes = attributes;
      }

      // Setup the applyEdits parameter with adds
      const adds = {
        addFeatures: [newFeature]
      };
      params = adds;
    }

    // Send the adds to the GIS server (ESRI feature service)
    await featuresService.applyEditsFeatureLayer(featureLayer, params).then((editResult) => {
      //console.log('MASTER submitFeatureForm editResult');
      //console.log(editResult);
      result = editResult;  
      result.recordId = recordId;
      //return result;
    });
    //
    // Hook into integrations here
    //
    // .then( (result) => {
    //   console.log('MASTER submitFeatureForm editResult .THEN 2', result, submitType, params);

    //   if (integration) {
    //     let targetAttributes: any = {};
    //     let sourceAttributes = params.addFeatures[0].attributes;
    //     let geometry = params.addFeatures[0].geometry;

    //     switch (integration.name.toUpperCase()) {
    //       case 'ARCGISWORKFORCE':
    //         switch (submitType.toUpperCase()) {
    //           case 'ADD':       
    //             // if sourceAttributes.Status = 0 (Unassigned) or 1 (Assigned)
    //             targetAttributes.Description = sourceAttributes.WorkRequestTitle + " " + sourceAttributes.WorkRequestDetails;
    //             //targetAttributes.Status = sourceAttributes.Status;
    //             targetAttributes.Status = 1;
    //             targetAttributes.AssignmentType = 'e31d94dd-e7f5-41a1-8e4c-7a3147ab4f4d'; //sourceAttributes.WorkRequestSubCategoryId;
    //             targetAttributes.WorkOrderID = result.recordId;
    //             targetAttributes.Location = 'Fairway: ' + sourceAttributes.FairwayNumber;
    //             targetAttributes.DispatcherId = '964b6024-9c80-4557-9bce-dfc55ea0fa17';
    //             targetAttributes.Priority = sourceAttributes.Priority;
    //             targetAttributes.DueDate = sourceAttributes.SLADueDate;
    //             // if status = 1 (Assigned)     
    //             targetAttributes.AssignedDate = sourceAttributes.WorkRequestDate;
    //             targetAttributes.WorkerID = '11ed13e7-e69f-4216-bd3b-e6d5a42121a8';

    //             let newFeature: EsriGraphic = { 
    //               geometry: [],
    //               attributes: [] 
    //             };
          
    //             newFeature.attributes = targetAttributes;
    //             newFeature.geometry = geometry;
          
    //             // Setup the applyEdits parameter with adds
    //             const adds = {
    //               addFeatures: [newFeature]
    //             };
    //             params = adds;

    //             this.workForceService.loadWorkForceLayer(false).then( (wfLayer) => {
    //               featuresService.applyEditsFeatureLayer(wfLayer, params).then((editResult) => {
    //                 console.log('MASTER submitFeatureForm INTEGRATION editResult');
    //               });
    //             });
    //             break;

    //           case 'UPDATE':
    //             sourceAttributes = params.updateFeatures[0].attributes;
    //             geometry = params.updateFeatures[0].geometry;
    //             break;

    //           default:
    //             break;
    //         }
    //         break;

    //       default:
    //         break;
    //     }
    //   }
    // });
 
    // Return success / error msg 
    //console.log('MASTER submitFeatureForm result');
    //console.log(result);
    return result;
  }

  private getFeatureValue(featureName: string) {
    for (var key in this._feature.attributes) {
      if (this._feature.attributes.hasOwnProperty(key)) {
          if (key == featureName) {
            return this._feature.attributes[key];
          }
      }
    }    
  }
  
}
