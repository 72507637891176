import { setDefaultOptions, loadModules } from 'esri-loader';
//import { Esri, FeatureLayerProperties, ElevationInfo } from 'src/app/_esri/models/esri';
import { LayerIds } from 'src/app/_esri/models/layer-ids';

export class Esri_VisualVariablesStop {
    public value: number;
    public color: any;
    public label?: string;

    constructor(value: number, colour: any, label?: string) {
        this.value = value;
        this.color = colour;
        this.label = label;
    }
}

export class Esri_UniqueValueInfo {
    public value: string;
    public symbol: Esri_Symbol;
    // public symbol: {
    //     type: null,     // 'simple'  'simple-fill',
    //     color: null
    // } 
}

export class Esri_Symbol {
    public type: string;     // 'simple'  'simple-fill',
    public color: any;

    constructor(type: string, colour: any) {
        this.type = type;
        this.color = colour;
    }
}

export class EsriVisualVariable_Colour {
    public visualVariables_Colour = {
        type: 'color',
        field: null,
        stops: null,
        normalizationField: null,
        valueExpression: null,
        valueExpressionTitle: null,
        legendOptions: {
            showLegend: true,
            title: null
        }
    }

    constructor(field: string, stops: Esri_VisualVariablesStop[]) {
        this.visualVariables_Colour.field = field;
        this.visualVariables_Colour.stops = stops;
    }
}

export class EsriRenderer_Polygon {
    public renderer;
    public renderer_Polygon = {
        type: 'simple',
        symbol: {
            type: 'simple-fill',
            color: null,
            outline: {
                width: '0',
                color: null
            }
        }
    };

    constructor(fillColourRGBA: any[], borderColourRGBA: any[], borderWidth: number) {
        this.renderer = this.setPolygonRenderer(fillColourRGBA, borderColourRGBA, borderWidth);
        //console.log('renderer');
        //console.log(this.renderer);
    }

    private setPolygonRenderer(fillColourRGBA: any[], borderColourRGBA: any[], borderWidth: number) {
        let renderer = this.renderer_Polygon;
        renderer.symbol.color = fillColourRGBA;
        renderer.symbol.outline.color = borderColourRGBA;
        renderer.symbol.outline.width = borderWidth.toString() + 'px';
        return renderer;
    };
}

export class EsriRenderer_Polyline {
    public renderer;

    public marker = {
        color: null,
        placement: "begin-end",     //"begin"|"end"|"begin-end"
        style: "circle"             //"arrow"|"circle"|"square"|"diamond"|"cross"|"x"
    }

    public renderer_Polyline = {
        type: 'simple',
        symbol: {
            type: 'simple-line',
            color: null,
            width: '1px',
            style: 'solid',     // "dash"|"dash-dot"|"dot"|"long-dash"|"long-dash-dot"|"long-dash-dot-dot"|"none"|"short-dash"|"short-dash-dot"|"short-dash-dot-dot"|"short-dot"|"solid"
            cap: 'square',      // "butt"|"round"|"square" 
            marker: null        //
        }
    };

    constructor(colourRGBA: any[], width: number) {
        this.renderer = this.setPolylineRenderer(colourRGBA, width);
    }

    private setPolylineRenderer(colourRGBA: any[], width: number) {
        let renderer = this.renderer_Polyline;
        renderer.symbol.color = colourRGBA;
        renderer.symbol.width = width.toString() + 'px';
        return renderer;
    };
}

export class EsriRenderer_Point {
    public renderer;

    public renderer_Point = {
        type: 'simple',
        symbol: {
            type: 'simple-marker',
            color: null,
            style: 'circle',          // circle cross diamond square triangle x
            outline: {
                width: '0',
                color: null
            }
        },
        visualVariables: null
    };

    constructor(fillColourRGBA: any[], borderColourRGBA: any[], borderWidth: number, visualVariable?: any) {
        this.renderer = this.setPointRenderer(fillColourRGBA, borderColourRGBA, borderWidth);
    }

    private setPointRenderer(fillColourRGBA: any[], borderColourRGBA: any[], borderWidth: number) {
        let renderer = this.renderer_Point;
        renderer.symbol.color = fillColourRGBA;
        renderer.symbol.outline.color = borderColourRGBA;
        renderer.symbol.outline.width = borderWidth.toString() + 'px';
        return renderer;
    };
}

export class EsriRenderer_Point_UniqueValue {
    public renderer;

    public renderer_Point = {
        type: 'unique-value',
        defaultSymbol: {
            type: 'simple-marker',
            color: null,
            outline: {
                width: '0',
                color: null
            }
        },
        uniqueValueInfos: null,
        visualVariables: null
    };
}

export class Esri {

    public static apiVersion: string = '4.19';

    public static worldGeocodeServerUrl: string = 'https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer';
    public static worldElevationUrl: string = 'https://elevation3d.arcgis.com/arcgis/rest/services/WorldElevation3D/Terrain3D/ImageServer';
    public static tokenServerUrl: string = 'https://www.arcgis.com/sharing/rest/generateToken';
    public static oAuthServerUrl: string = 'https://www.arcgis.com/sharing/rest/oauth2/token';
    public static oAuthAuthoriseUrl: string = 'https://www.arcgis.com/sharing/rest/oauth2/authorize';    // https://www.arcgis.com/sharing/rest/oauth2/authorize  https://www.arcgis.com/sharing/oauth2/authorize
    //public static oAuthRedirectUrl: string = 'https://map-test.crispora.com.au/login-callback';
    public static oAuthRedirectUrl: string = 'http://localhost:4200/login-callback';
    public static portalUrl: string = "https://www.arcgis.com/sharing";

    public static geoprocessingServerUrl: string = 'https://www.arcgis.com/arcgis/rest/services/????';

    public static initialZoom: number = 16;
    public static initialCenter: Array<number> = [151.211339, -33.9315];  // [151.211339, -33.931959];
    public static initialCenter_Global: Array<number> = [151.211339, 0];  // [151.211339, -33.931959];
    public static noLocationDefault: Array<number> = [151.21237996025644, -33.933587588935445];     // used for records that have no location associated with them

    public static initialBasemapName: string = 'newspaper';  // colored-pencil community modern-antique mid-century newspaper gray-vector dark-gray-vector 
    //public static initialBasemapName: string = 'gray-vector';
    public static toggleBasemapName: string = 'satellite';
    public static sceneDate: Date = new Date('February 10, 2020 11:00:00');
    //public static accessToken: string = '';
    public static tokenExpirationRequest: string = '60';
    //public static tokenExpiresIn: number = 0;
    //public static tokenAcquired: Date = null;
    //public static tokenExpires: Date = null;

    public static selectZoom: number = 17;

    //public static baseScenePortalId: string = 'e870c18ae57545349b5343ea97fe7195';
    public static highQualitySceneGraphics: boolean = true;


    // 3D Bookmarks (Slide Details)

    // Lambert Geographic Centre of Australia
    //public lambertCentreCameraSettings = new CameraSettings(360, 0, 134.361943, -25.609121, 25512500);

    // Lambert Geographic Centre of Australia
    public static lambertCentreCameraSettings: CameraSettings = {
        heading: 360,
        tilt: 0,
        longitude: 134.361943,
        latitude: -25.609121,
        z: 25512500
    };

    // Golf Course
    public static home2dCameraSettings: CameraSettings = {
        heading: 360,
        tilt: 0,
        longitude: 151.211637,
        latitude: -33.9316169,
        z: 3100
    };

    public static homeCameraSettings: CameraSettings = {
        heading: 360,
        tilt: 60,
        longitude: 151.208920,
        latitude: -33.9497205,
        z: 935
    };

    public static frontNineCameraSettings: CameraSettings = {
        heading: 1,
        tilt: 68,
        longitude: 151.207152,
        latitude: -33.9466534,
        z: 545
    };

    public static backNineCameraSettings: CameraSettings = {
        heading: 360,
        tilt: 75,
        longitude: 151.215083,
        latitude: -33.9395624,
        z: 215
    };

    //
    // Functions
    //

    public static async getBasemap(basemapName: string) {
        //console.log('goToCameraPosition', settings);

        const options = {
            version: Esri.apiVersion
        };

        setDefaultOptions(options);

        const [
            Basemap,
            VectorTileLayer
        ] = await loadModules([
            'esri/Basemap',
            'esri/layers/VectorTileLayer'
        ]);

        let basemap;

        //
        // Basemaps
        //

        // https://developers.arcgis.com/javascript/latest/api-reference/esri-Map.html#basemap
        // https://developers.arcgis.com/javascript/latest/sample-code/sandbox/index.html?sample=basemaps-portal

        switch (basemapName) {
            case 'none':
                basemap = null;
                break;

            case 'streets':
            case 'satellite':
            case 'hybrid':
            case 'terrain':
            case 'topo':
            case 'gray':
            case 'dark-gray':
            case 'oceans':
            case 'national-geographic':
            case 'osm':
            case 'gray-vector':
            case 'dark-gray-vector':
            case 'gray-vector':
            case 'streets-vector':
            case 'topo-vector':
            case 'streets-night-vector':
            case 'streets-relief-vector':
            case 'streets-navigation-vector':
                basemap = Basemap.fromId(basemapName);
                break;

            case 'community':
                // const communityBaseLayer = new VectorTileLayer({
                //   url: 'https://www.arcgis.com/sharing/rest/content/items/273bf8d5c8ac400183fc24e109d20bcf/resources/styles/root.json'
                // });
                // // f0d3e55345d2404a92a91989cd9c5bb9  (WGS84 Tiling)
                // basemap = new Basemap({
                //     baseLayers: [communityBaseLayer],
                //     title: 'Community Basemap',
                //     id: 'communityBasemap'
                // });

                const communityBaseLayer = new VectorTileLayer({
                    url: "https://cdn.arcgis.com/sharing/rest/content/items/ab924202cc404af58b41efcf99b0aafa/resources/styles/root.json"
                });

                basemap = new Basemap({
                    id: "1757cfeb67f-basemap-17",
                    baseLayers: [communityBaseLayer]
                });

                break;

            case 'colored-pencil':
                // The vector maps don't work with webpack for some reason, ie this one and eg dark-gray-vector and gray-vector
                // They work ok with esri-loader

                const coloredPencilBaseLayer = new VectorTileLayer({
                    url: 'https://cdn.arcgis.com/sharing/rest/content/items/aa30dd52509a4fea939ccbfc3c3e14de/resources/styles/root.json'
                });

                basemap = new Basemap({
                    baseLayers: [coloredPencilBaseLayer],
                    id: '1757d18b973-basemap-26'
                });

                break;

            case 'modern-antique':
                const modernAntiqueBaseLayer = new VectorTileLayer({
                    url: 'https://cdn.arcgis.com/sharing/rest/content/items/d0d7d6eefdf54400be127647b86531df/resources/styles/root.json'
                });

                basemap = new Basemap({
                    baseLayers: [modernAntiqueBaseLayer],
                    id: '1757d18b972-basemap-23'
                });
                break;

            case 'mid-century':
                const midCenturyBaseLayer = new VectorTileLayer({
                    url: "https://cdn.arcgis.com/sharing/rest/content/items/3b660d7e42bb4631abb1af82321be0df/resources/styles/root.json"
                });

                basemap = new Basemap({
                    id: "1757d18b972-basemap-24",
                    baseLayers: [midCenturyBaseLayer]
                });
                break;

            case 'newspaper':
                const newspaperBaseLayer = new VectorTileLayer({
                    url: "https://cdn.arcgis.com/sharing/rest/content/items/317ca0b66843491ca9812e850e935dd5/resources/styles/root.json"
                });

                basemap = new Basemap({
                    id: "1757d18b970-basemap-20",
                    baseLayers: [newspaperBaseLayer]
                });
                break;

            // case 'hidden':
            //     basemap = Basemap.fromId('gray');

            //     console.log('hidden', basemap.baseLayers.length);

            //     for (let i = 0; i < basemap.baseLayers.items.length; i++) {
            //         console.log('hidden forEach', basemap.baseLayers.items[i]);
            //         basemap.baseLayers.items[i].opacity = 0;
            //     }

            //     // basemap.baseLayers.items.forEach(layer => {
            //     //     console.log('hidden forEach', layer);
            //     //     layer.opacity = 0;
            //     // })



            //     break;

            default:
                basemap = Basemap.fromId('gray-vector');
                break;
        }

        return basemap;
    }

    public static async createSceneSlides() {
        //console.log('createSceneSlides');
        const [
            Camera,
            Point,
            Viewpoint,
            Slide,
            Presentation,
            Collection
        ] = await loadModules([
            'esri/Camera',
            'esri/geometry/Point',
            'esri/Viewpoint',
            'esri/webscene/Slide',
            'esri/webscene/Presentation',
            'esri/core/Collection'
        ]);

        let presentation;
        let slides = new Collection();
        let slide;
        let viewpoint;
        let position;
        let camera;

        //
        // Home Slide
        //
        position = new Point({
            longitude: Esri.homeCameraSettings.longitude,
            latitude: Esri.homeCameraSettings.latitude,
            z: Esri.homeCameraSettings.z
        });

        camera = new Camera({
            position: position,
            heading: Esri.homeCameraSettings.heading,
            tilt: Esri.homeCameraSettings.tilt
        });

        viewpoint = new Viewpoint({
            camera: camera
        })

        slide = new Slide({
            id: 'homeSlide',
            title: 'Home',
            viewpoint: viewpoint
        })

        slides.add(slide);

        //
        // Home Slide (2D simulation)
        //
        position = new Point({
            longitude: Esri.home2dCameraSettings.longitude,
            latitude: Esri.home2dCameraSettings.latitude,
            z: Esri.home2dCameraSettings.z
        });

        camera = new Camera({
            position: position,
            heading: Esri.home2dCameraSettings.heading,
            tilt: Esri.home2dCameraSettings.tilt
        });

        viewpoint = new Viewpoint({
            camera: camera
        })

        slide = new Slide({
            id: 'homeSlide2D',
            title: 'Home (2D view)',
            viewpoint: viewpoint
        })

        slides.add(slide);

        //
        // Front Nine Slide
        //
        position = new Point({
            longitude: Esri.frontNineCameraSettings.longitude,
            latitude: Esri.frontNineCameraSettings.latitude,
            z: Esri.frontNineCameraSettings.z
        });

        camera = new Camera({
            position: position,
            heading: Esri.frontNineCameraSettings.heading,
            tilt: Esri.frontNineCameraSettings.tilt
        });

        viewpoint = new Viewpoint({
            camera: camera
        })

        slide = new Slide({
            id: 'frontNineSlide',
            title: 'Front Nine',
            viewpoint: viewpoint
        })

        slides.add(slide);

        //
        // Back Nine Slide
        //
        position = new Point({
            longitude: Esri.backNineCameraSettings.longitude,
            latitude: Esri.backNineCameraSettings.latitude,
            z: Esri.backNineCameraSettings.z
        });

        camera = new Camera({
            position: position,
            heading: Esri.backNineCameraSettings.heading,
            tilt: Esri.backNineCameraSettings.tilt
        });

        viewpoint = new Viewpoint({
            camera: camera
        })

        slide = new Slide({
            id: 'backNineSlide',
            title: 'Back Nine',
            viewpoint: viewpoint
        })

        slides.add(slide);

        //
        // Lambert Centre (earth orbit) Slide
        //
        position = new Point({
            longitude: Esri.lambertCentreCameraSettings.longitude,
            latitude: Esri.lambertCentreCameraSettings.latitude,
            z: Esri.lambertCentreCameraSettings.z
        });

        camera = new Camera({
            position: position,
            heading: Esri.lambertCentreCameraSettings.heading,
            tilt: Esri.lambertCentreCameraSettings.tilt
        });

        viewpoint = new Viewpoint({
            camera: camera
        })

        slide = new Slide({
            id: 'lambertCentreSlide',
            title: 'Orbit',
            viewpoint: viewpoint
        })

        slides.add(slide);

        //
        // Create the Presentation
        //
        presentation = new Presentation({
            slides: slides
        })

        return presentation;
    }

    public static async goToCameraPosition(view: any, settings: CameraSettings) {
        //console.log('goToCameraPosition', settings);
        const [
            Camera,
            Point
        ] = await loadModules([
            'esri/Camera',
            'esri/geometry/Point'
        ]);

        // Subject of Interest Location
        var position = new Point({
            longitude: settings.latitude,
            latitude: settings.longitude,
            z: settings.z
        });

        // go to a location defined by a Camera object
        var camera = new Camera({
            position: position,
            heading: settings.heading,
            tilt: settings.tilt
        });

        view.goTo(camera);
        return view;
    }

    //
    // Renderers
    //

    public static dashOutlineRenderer = {
        type: 'simple',
        symbol: {
            type: 'simple-fill',
            style: 'none',
            color: [0, 0, 0, 0.0],
            outline: {
                type: 'simple-line',
                width: 1,
                color: [204, 204, 204, 1.0],
                style: 'dash'
                //style: 'solid'
            }
        }
    };

    // public static outlineRenderer3d = {
    //     type: 'simple',
    //     symbol: {
    //         type: 'simple-fill',
    //         style: 'none',
    //         color: [0, 0, 0, 0.0],
    //         outline: {
    //             type: 'simple-line',
    //             width: 1,
    //             color: [204, 204, 204, 1.0],
    //             style: 'dash'
    //             //style: 'solid'
    //         }
    //     }
    // };

    public static solidOutlineRenderer = {
        type: 'simple',
        symbol: {
            type: 'simple-fill',
            style: 'none',
            //color: [0, 0, 0, 0.0],
            outline: {
                type: 'simple-line',
                width: 1,
                color: [204, 204, 204, 1.0],
                style: 'solid'
            }
        }
    };
    // Possible fill style Values:"backward-diagonal"|"cross"|"diagonal-cross"|"forward-diagonal"|"horizontal"|"none"|"solid"|"vertical"
    // Possible outline style Values:"dash"|"dash-dot"|"dot"|"long-dash"|"long-dash-dot"|"long-dash-dot-dot"|"none"|"short-dash"|"short-dash-dot"|"short-dash-dot-dot"|"short-dot"|"solid"

    // public static rendererActiveAsset_Polygon = {
    //     type: 'simple',
    //     symbol: {
    //         type: 'simple-fill',
    //         color: [255, 190, 0, 0.75],
    //         outline: {
    //             width: 1,
    //             color: 'white'
    //         }
    //     }
    // };
    public static rendererActiveAsset_Polygon = new EsriRenderer_Polygon([255, 190, 0, 0.75], [255, 255, 255, 1], 1).renderer;


    public static rendererActiveAsset_Polyline = {
        // type: 'simple',
        // symbol: {
        //     type: 'simple-fill',
        //     color: [ 255, 190, 0, 0.75 ],
        //     outline: {
        //         width: 1,
        //         color: 'white'
        //     }
        // }
    };

    // public static rendererActiveAsset_Point = {
    //     type: 'simple',
    //     symbol: {
    //         type: 'simple-fill',
    //         color: [255, 190, 0, 0.75],
    //         outline: {
    //             width: 1,
    //             color: 'white'
    //         }
    //     }
    // };
    public static rendererActiveAsset_Point = new EsriRenderer_Point([255, 190, 0, 0.75], [255, 255, 255, 1], 1).renderer;

    public static rendererGeocode_Point = {
        type: 'simple',
        symbol: {
            type: 'simple-marker',
            style: 'x',          // circle cross diamond square triangle x
            color: [255, 0, 255, 0.7],
            size: '8px',
            outline: {
                color: [255, 0, 255, 1.0],
                width: 3
            }
        }
    };
    //public static rendererGeocode_Point = new EsriRenderer_Point([255, 0, 255, 0.7], [255, 0, 255, 1.0], 1).renderer;

    public static rendererHeatMap_RedGreen = {
        type: "heatmap",
        colorStops: [
            { color: "rgba(1, 255, 0, 0)", ratio: 0 },
            { color: "#16E900", ratio: 0.083 },
            { color: "#2BD400", ratio: 0.166 },
            { color: "#40BF00", ratio: 0.249 },
            { color: "#55AA00", ratio: 0.332 },
            { color: "#6A9400", ratio: 0.415 },
            { color: "#807F00", ratio: 0.498 },
            { color: "#956A00", ratio: 0.581 },
            { color: "#AA5500", ratio: 0.664 },
            { color: "#BF3F00", ratio: 0.747 },
            { color: "#D42A00", ratio: 0.830 },
            { color: "#E91500", ratio: 0.913 },
            { color: "#FF0000", ratio: 1 }
        ],
        maxPixelIntensity: 25,
        minPixelIntensity: 0
    };

    public static rendererHeatMap_PurpleYellow = {
        type: "heatmap",
        colorStops: [
            { color: "rgba(63, 40, 102, 0)", ratio: 0 },
            { color: "#472b77", ratio: 0.083 },
            { color: "#4e2d87", ratio: 0.166 },
            { color: "#563098", ratio: 0.249 },
            { color: "#5d32a8", ratio: 0.332 },
            { color: "#6735be", ratio: 0.415 },
            { color: "#7139d4", ratio: 0.498 },
            { color: "#7b3ce9", ratio: 0.581 },
            { color: "#853fff", ratio: 0.664 },
            { color: "#a46fbf", ratio: 0.747 },
            { color: "#c29f80", ratio: 0.830 },
            { color: "#e0cf40", ratio: 0.913 },
            { color: "#ffff00", ratio: 1 }
        ],
        maxPixelIntensity: 25,
        minPixelIntensity: 0
    };

    public static rendererHeatMap_Magenta = {
        type: "heatmap",
        colorStops: [
            { color: "rgba(243, 228, 229, 0)", ratio: 0 },
            { color: "#e4becb", ratio: 0.083 },
            { color: "#d498b2", ratio: 0.166 },
            { color: "#c57298", ratio: 0.249 },
            { color: "#b95685", ratio: 0.332 },
            { color: "#ae3972", ratio: 0.415 },
            { color: "#a21d5e", ratio: 0.498 },
            { color: "#96004b", ratio: 0.581 },
            { color: "#ab006f", ratio: 0.664 },
            { color: "#c00093", ratio: 0.747 },
            { color: "#d500b7", ratio: 0.830 },
            { color: "#ea00db", ratio: 0.913 },
            { color: "#ff00ff", ratio: 1 }
        ],
        maxPixelIntensity: 25,
        minPixelIntensity: 0
    };

    // Create objectSymbol and add to renderer
    public static coneSymbol3d = {
        type: "point-3d",
        symbolLayers: [{
            type: "object",
            width: 0.5,
            height: 0.5,
            resource: {
                primitive: "cone"
            },
            material: {
                color: "#FFD700"
            }
        }]
    };

    //
    // Label Symbols
    //

    public static text10px = {
        type: "text",
        color: [104, 104, 104, 0.7],
        font: {
            family: "Arial",
            size: 10,
            weight: "normal"
        }
    }

    public static text12px = {
        type: "text",
        color: [104, 104, 104, 0.7],
        font: {
            family: "Arial",
            size: 12,
            weight: "normal"
        }
    }

    public static text14px = {
        type: "text",
        color: [104, 104, 104, 0.7],
        font: {
            family: "Arial",
            size: 14,
            weight: "normal"
        }
    }

    public static text16px = {
        type: "text",
        color: [104, 104, 104, 0.7],
        font: {
            family: "Arial",
            size: 16,
            weight: "normal"
        }
    }

    public static text10pxWithHalo = {
        type: "text",
        color: [104, 104, 104, 0.7],
        haloSize: 1,
        haloColor: "white",
        font: {
            family: "Arial",
            size: 10,
            weight: "normal"
        }
    }

    public static text12pxWithHalo = {
        type: "text",
        color: [104, 104, 104, 0.7],
        haloSize: 0.5,
        haloColor: "white",
        font: {
            family: "Arial",
            size: 12,
            weight: "normal"
        }
    }

    public static text12pxWithHalo_DarkGrey = {
        type: "text",
        color: [33, 33, 33, 0.85],
        haloSize: 0.5,
        haloColor: "white",
        font: {
            family: "Arial",
            size: 12,
            weight: "normal"
        }
    }

    public static text12pxWithHalo_DeepPurple = {
        type: "text",
        color: [103, 58, 183, 1],
        haloSize: 0.5,
        haloColor: "white",
        font: {
            family: "Arial",
            size: 12,
            weight: "normal"
        }
    }

    public static text12pxWithHalo_Amber = {
        type: "text",
        color: [255, 190, 0, 0.85],
        haloSize: 0.5,
        haloColor: "white",
        font: {
            family: "Arial",
            size: 12,
            weight: "normal"
        }
    }

    public static text12pxWithHalo_Green = {
        type: "text",
        color: [6, 128, 32, 0.85],
        haloSize: 0.5,
        haloColor: "white",
        font: {
            family: "Arial",
            size: 12,
            weight: "normal"
        }
    }

    public static text14pxWithHalo = {
        type: "text",
        color: [104, 104, 104, 0.7],
        haloSize: 1,
        haloColor: "white",
        font: {
            family: "Arial",
            size: 14,
            weight: "normal"
        }
    }

    public static text16pxHaloSymbol = {
        type: "text",
        color: [104, 104, 104, 1.0],
        haloSize: 1,
        haloColor: "white",
        font: {
            family: "Arial",
            size: 16,
            weight: "bold"
        }
    }

    public static textCallout3d = {
        type: "label-3d",
        symbolLayers: [{
            type: "text",
            material: {
                color: [104, 104, 104]
            },
            size: 16, // points
            halo: {
                color: [255, 255, 255, 0.8],
                size: 2
            }
        }],
        verticalOffset: {
            screenLength: 40,
            maxWorldLength: 100,
            minWorldLength: 20
        },
        callout: {
            type: "line",
            size: 1.0,
            color: [255, 255, 255],
            //   border: {
            //     color: [104, 104, 104]
            //   }
        }
    };

    //
    // Label Infos
    //

    public static labelInfo = {
        symbol: Esri.text10pxWithHalo,
        labelPlacement: null,
        labelExpressionInfo: {
            expression: "$feature.OBJECTID"
        },
        maxScale: 0,
        minScale: 2000,
        where: "1=1"
    };

    // labelPlacement:
    // Points	above-center, above-left, above-right, below-center, below-left, below-right, center-center, center-left, center-right
    // Polylines	above-after, above-along, above-before, above-start, above-end, below-after, below-along, below-before, below-start, below-end, center-after, center-along, center-before, center-start, center-end
    // Polygons	always-horizontal

    public static labelInfo3d = {
        symbol: Esri.text10pxWithHalo,
        labelPlacement: null,
        labelExpressionInfo: {
            expression: "$feature.OBJECTID"
        },
        maxScale: 0,
        minScale: 2000,
        where: "1=1"
    };

}

// Features / Feature Layers

export class FeatureLayerProperties {
    public url: string;
    public id: string;
    public title: string;
    public opacity?: number;
    public maxScale?: number;
    public minScale?: number;
    public legendEnabled?: boolean;
    public listMode?: string;
    public popupEnabled?: boolean;
    public popupTemplate?: any;
    public outFields?: string[];
    public definitionExpression?: string;
    public portalItem?: string;
    public renderer?: any;
    public visible?: boolean;
    public displayField?: string;
    public labelingInfo?: any;
    public labelsVisible?: boolean;
    public returnM?: boolean;
    public returnZ?: boolean;
    public featureReduction?: any;
    public copyright: string;

    // 3D attributes
    public elevationInfo?: ElevationInfo;
    public elevationInfoMode?: string;

    constructor(
        _gisServer: string,
        _folder: string,
        _layerId: string,
        _id: string,
        _title: string,
        _layerType?: string
    ) {

        this.id = _id;
        this.title = _title;

        if (_layerType) {
            _layerType = _layerType.toUpperCase();
        }

        switch (_layerType) {
            case 'TILE':
                //this.tileService();
                this.url = _gisServer + '/' + _folder + '/MapServer/';
                break;

            case 'MAP':
                //this.mapService();
                this.url = _gisServer + '/' + _folder + '/MapServer/' + _layerId;
                break;

            case 'SCENE':
                //this.sceneService();
                this.url = _gisServer + '/' + _folder + '/SceneServer/';
                break;

            case 'FEATURE':
            default:
                this.url = _gisServer + '/' + _folder + '/FeatureServer/' + _layerId;
                break;
        }
    }

    // private mapService(): any {
    //     //console.log('mapService before');
    //     //console.log(this.url);
    //     let re = /FeatureServer/gi; 
    //     this.url = this.url.replace(re, 'MapServer');
    //     //console.log('mapService after');
    //     //console.log(this.url);
    // }

    // private tileService(): any {
    //     //console.log('mapService before');
    //     //console.log(this.url);
    //     let re = /FeatureServer/gi; 
    //     let pos = this.url.lastIndexOf("/");
    //     let url = this.url.substring(0, pos)
    //     this.url = url.replace(re, 'MapServer');
    //     //console.log('mapService after');
    //     //console.log(this.url);
    // }

    // private sceneService(): any {
    //     //console.log('mapService before');
    //     //console.log(this.url);
    //     let re = /FeatureServer/gi; 
    //     let pos = this.url.lastIndexOf("/");
    //     let url = this.url.substring(0, pos)
    //     this.url = url.replace(re, 'SceneServer');       
    // }
}

export class FeatureServiceQueryResponse {
    //public featureLayerObject: FeatureLayerObject;
    public featureLayerData: any;
    public domains: Domain[];
    public extent: any;
}

//
// Feature Service
//

// export class FeatureLayerObject {
//     public objectIdFieldName: string;
//     public uniqueIdField: UniqueIdField;
//     public globalIdFieldName: string;
//     public geometryProperties: GeometryProperties;
//     public hasZ: boolean;
//     public hasM: boolean;
//     public geometryType: string;
//     public spatialReference: SpatialReference;
//     public fields: Field[];
//     public features: Feature[];
// }

// export class UniqueIdField {
//     public name: string;
//     public isSystemMaintained: boolean;
// }

export interface UniqueIdField {
    name: string;
    isSystemMaintained: boolean;
}

export class GeometryProperties {
    public shapeAreaFieldName: string;
    public shapeLengthFieldName: string;
    public units: string;
}

export class SpatialReference {
    public wkid: number;
    public latestWkid?: number;
}

export class Field {
    public name: string;
    public type: string;
    public alias: string;
    public sqlType?: string;
    public domain?: Domain;
    public defaultValue?: any;
    public length?: number;
    public description?: string;
    public editable?: string;
    public nullable?: string;
    public valueType?: string;
}

export class Feature {
    public geometry: any;
    public attributes: any;
}

export class ElevationInfo {
    public mode: string;            // "on-the-ground"|"relative-to-ground"|"absolute-height"|"relative-to-scene"
    public offset?: number;
    public unit?: string;           // "feet"|"meters"|"kilometers"|"miles"|"us-feet"|"yards"
}

//
// Geometry
//

export enum EsriGeometryType {
    Point = 'POINT',
    MultiPoint = 'MULTIPOINT',
    Polyline = 'POLYLINE',
    Polygon = 'POLYGON'
}

export class GeometryPolygon {
    public hasZ: boolean;
    public hasM: boolean;
    public rings: any[][][];
}

export class GeometryPolyline {
    public hasZ: boolean;
    public hasM: boolean;
    //public rings: any[][][];
}

export class GeometryPoint {
    public latitude: number;
    public longitude: number;
    public type?: string;
    public hasZ?: boolean;
    public hasM?: boolean;
    public x?: number;
    public y?: number;
    public z?: number;
    public m?: number;
    public spatialReference?: SpatialReference;

    constructor(longitude: number, latitude: number) {
        this.longitude = longitude;
        this.latitude = latitude;
        this.type = "point";
    }
}

// export class MultiPoint {
//     public points: number[][];

//     constructor(longitude: number, latitude: number) {

//         this.points = [];
//         this.points.push([longitude, latitude]);
//         //this.points.push([latitude]);
//     }
// }

export class GeometryMultiPoint {
    public type?: string;
    public hasZ?: boolean;
    public hasM?: boolean;
    public spatialReference?: SpatialReference;
    public points: number[][];

    constructor() {
        this.type = 'multipoint';
    }

    // constructor(longitude: number, latitude: number) {
    //     this.points = [];
    //     this.points.push([longitude, latitude]);
    //     //this.points.push([latitude]);
    // }
}
//points	Number[][]


// json = {"points":[
//     [-9816005.5426,5126478.4306999967],
//     [-9814244.3355,5126472.3226],
//     [-9813815.49,5126409.5697999969]
// ],
// "spatialReference":{"wkid":102100 }};



export interface EsriGraphic {
    geometry?: any;
    attributes: any;
}

export enum GeometryType {
    Point = 'esriGeometryPoint',
    Multipoint = 'esriGeometryMultipoint',
    Polyline = 'esriGeometryPolyline',
    Polygon = 'esriGeometryPolygon',
    Envelope = 'esriGeometryEnvelope'
}

export enum SpatialRel_REST {
    Intersects = 'esriSpatialRelIntersects',
    Contains = 'esriSpatialRelContains',
    Crosses = 'esriSpatialRelCrosses',
    EnvelopeIntersects = 'esriSpatialRelEnvelopeIntersects',
    IndexIntersects = 'esriSpatialRelIndexIntersects',
    Overlaps = 'esriSpatialRelOverlaps',
    Touches = 'esriSpatialRelTouches',
    Within = 'esriSpatialRelWithin'
}

export enum SpatialRelationship {
    Intersects = 'intersects',
    Contains = 'contains',
    Crosses = 'crosses',
    Disjoint = 'disjoint',
    EnvelopeIntersects = 'envelope-intersects',
    IndexIntersects = 'index-intersects',
    Overlaps = 'overlaps',
    Touches = 'touches',
    Within = 'within',
    Relation = 'relation'
}

export enum EsriSRUnit {
    Meter = 'esriSRUnit_Meter',
    Metre = 'esriSRUnit_Meter',
    StatuteMile = 'esriSRUnit_StatuteMile',
    Foot = 'esriSRUnit_Foot',
    Kilometer = 'esriSRUnit_Kilometer',
    Kilometre = 'esriSRUnit_Kilometer',
    NauticalMile = 'esriSRUnit_NauticalMile',
    USNauticalMile = 'esriSRUnit_USNauticalMile'
}

// export class GeometryPoint {
//     public hasZ: boolean;
//     public hasM: boolean;
//     //public rings: any[][][];
// }

//
// Domains
//

export class CodedValue {
    public name: string;
    public code: string;
}

export class Domain {
    public type: string;
    public name: string;
    public codedValues: CodedValue[];
}

export class ChildDomain {
    public code: string;
    public domain: Domain;
}

export class SubDomain {
    public name: string;
    public domains: ChildDomain[];
    public parentName?: string;
}

// export class ParentDomain {
//     public name: string;
//     public domains: ChildDomain[];
// }

//
// Geocode
//

export interface Location {
    x: number;
    y: number;
}

export interface AddressAttributes {
    Match_addr: string;
    Addr_type: string;
    StAddr: string;
    Nbrhd: string;
    Region: string;
    Postal: string;
    Country?: string;
}

export class Address {
    public AddressLine1: string;
    public AddressLine2?: string;
    public Suburb: string;
    public State: string;
    public Postcode: string;
    public Country?: string;
    public FormattedAddress: string;

    constructor(addressLine1: string, suburb: string, state: string, postcode: string, country?: string) {
        this.AddressLine1 = addressLine1;
        this.Suburb = suburb;
        this.State = this.setState(state);
        this.Postcode = postcode;
        this.Country = (country ? country : null);
        this.FormattedAddress = this.setFormattedAddress();
    }

    private setState(stateGeocode: string) {
        let state = '';
        switch (stateGeocode) {
            case 'New South Wales':
                state = 'NSW';
                break;

            case 'Queensland':
                state = 'QLD';
                break;

            case 'Victoria':
                state = 'VIC';
                break;

            case 'Tasmania':
                state = 'TAS';
                break;

            case 'Northern Territory':
                state = 'NT';
                break;

            case 'South Australia':
                state = 'SA';
                break;

            case 'Western Australia':
                state = 'WA';
                break;

            case 'Australian Capital Territory':
                state = 'ACT';
                break;

            default:
                state = stateGeocode;
                break;
        }

        return state;
    }

    private setFormattedAddress() {
        return this.AddressLine1 + ' ' + this.Suburb + ', ' + this.State + ' ' + this.Postcode; // + (this.Country ? ' ' + this.Country : '');
    }
}

export interface Extent {
    xmin: number;
    ymin: number;
    xmax: number;
    ymax: number;
    spatialReference?: SpatialReference;
}

export interface Candidate {
    address: string;
    location: Location;
    score: string;
    attributes: AddressAttributes;
    extent: Extent;
}

export interface GeocodeResponse {
    spatialReference: SpatialReference;
    candidates: Candidate[];
}

export interface Suggest {
    text: string;
    magicKey: string;
    isCollection: boolean;
}

export interface SuggestResponse {
    suggestions: Suggest[];
}

export interface ReverseGeocodeAddress {
    Match_addr: string;
    LongLabel: string;
    ShortLabel: string;
    Addr_type: string;
    Type: string;
    PlaceName: string;
    AddNum: string;
    Address: string;
    Block: string;
    Sector: string;
    Neighborhood: string;
    District: string;
    City: string;
    MetroArea: string;
    Subregion: string;
    Region: string;
    Territory: string;
    Postal: string;
    PostalExt: string;
    CountryCode: string;
}

export interface ReverseGeocodeLocation {
    spatialReference: SpatialReference;
}

export interface ReverseGeocodeResponse {
    address: ReverseGeocodeAddress;
    location: ReverseGeocodeLocation;
}

//
// Static Map 
//

export interface StaticMapResultValue {
    url: string;
}

export interface StaticMapResponse {
    paramName: string;
    dataType: string;
    value: StaticMapResultValue;
}

export interface StaticMapResponseSync {
    results: StaticMapResponse[];
    messages: any[];
}

// export interface StaticMapResponse
// {
//     results: StaticMapResults[];
//     messages: any[];
// }

//
// Fields
//

export class FieldConfigTypes {
    public formFieldConfig: FormFieldConfig[];
    public fieldConfigs: FieldConfig[];
    public tableFieldConfig: FieldConfig[];
    public cardFieldConfig: any

    // fieldConfigs = {
    //     All,
    //     Title,
    //     SubTitle,
    //     ContentRow1,
    //     ContentRow2,
    //     ContentRow3
    // }

    constructor() { };
}

export class FormFieldConfig {
    public label: string;
    public fieldConfig: FieldConfig[];
    public visible: boolean;

    constructor(label: string, fieldConfig: any[]) {
        this.label = label;
        this.fieldConfig = fieldConfig;
        this.visible = true;
    }

    public static createFieldConfig(formFieldConfigs: FormFieldConfig[]): FieldConfig[] {
        let fieldConfigs: FieldConfig[] = [];
        for (let i = 0; i < formFieldConfigs.length; i++) {
            //console.log('createFieldConfig i', formFieldConfigs[i].fieldConfig);
            for (let j = 0; j < formFieldConfigs[i].fieldConfig.length; j++) {
                //console.log('createFieldConfig j', formFieldConfigs[i].fieldConfig[j]);
                fieldConfigs.push(formFieldConfigs[i].fieldConfig[j]);
            }
        }
        return fieldConfigs;
    }

    public static createFieldConfigTable(formFieldConfigs: FormFieldConfig[]): FieldConfig[] {
        //console.log('createFieldConfigTable');

        let fieldConfigs: FieldConfig[] = [];

        // Filter out the fields that are to appear in the table
        for (let i = 0; i < formFieldConfigs.length; i++) {
            for (let j = 0; j < formFieldConfigs[i].fieldConfig.length; j++) {
                if (formFieldConfigs[i].fieldConfig[j].includeInTable === true) {
                    fieldConfigs.push(formFieldConfigs[i].fieldConfig[j]);
                }
            }
        }

        // Sort by the index
        fieldConfigs.sort(compare);

        function compare(a, b) {
            const A = a.includeInTableIndex;
            const B = b.includeInTableIndex;

            let comparison = 0;
            if (A > B) {
                comparison = 1;
            } else if (A < B) {
                comparison = -1;
            }
            return comparison;
        }

        //console.log(fieldConfigs);
        return fieldConfigs;
    }

    public static createFieldConfigCards(formFieldConfigs: FormFieldConfig[]): FieldConfig[] {
        let fieldConfigs: any;

        let All: FieldConfig[] = [];
        let Title: FieldConfig[] = [];
        let SubTitle: FieldConfig[] = [];
        let ContentRow1: FieldConfig[] = [];
        let ContentRow2: FieldConfig[] = [];
        let ContentRow3: FieldConfig[] = [];

        // Filter out the fields that are to appear in the cards
        for (let i = 0; i < formFieldConfigs.length; i++) {
            for (let j = 0; j < formFieldConfigs[i].fieldConfig.length; j++) {
                if (formFieldConfigs[i].fieldConfig[j].includeInCard === true) {
                    All.push(formFieldConfigs[i].fieldConfig[j]);
                }
            }
        }

        // Get Title
        for (let i = 0; i < All.length; i++) {
            if (All[i].includeInCard_Title === true) {
                Title.push(All[i]);
            }
        }
        // Sort by the index
        Title.sort(compare);

        // Get Sub Title
        for (let i = 0; i < All.length; i++) {
            if (All[i].includeInCard_SubTitle === true) {
                SubTitle.push(All[i]);
            }
        }
        SubTitle.sort(compare);

        // Get Content Row 1
        for (let i = 0; i < All.length; i++) {
            if (All[i].includeInCard_Content_Row1 === true) {
                ContentRow1.push(All[i]);
            }
        }
        ContentRow1.sort(compare);


        // Get Content Row 2        
        for (let i = 0; i < All.length; i++) {
            if (All[i].includeInCard_Content_Row2 === true) {
                ContentRow2.push(All[i]);
            }
        }
        ContentRow2.sort(compare);


        // Get Content Row 3
        for (let i = 0; i < All.length; i++) {
            if (All[i].includeInCard_Content_Row3 === true) {
                ContentRow3.push(All[i]);
            }
        }
        ContentRow3.sort(compare);

        function compare(a, b) {
            const A = a.includeInCard_Index;
            const B = b.includeInCard_Index;

            let comparison = 0;
            if (A > B) {
                comparison = 1;
            } else if (A < B) {
                comparison = -1;
            }
            return comparison;
        }

        fieldConfigs = {
            All,
            Title,
            SubTitle,
            ContentRow1,
            ContentRow2,
            ContentRow3
        }

        return fieldConfigs;
    }

}

export class FieldConfig {
    public name: string;                    // FieldConfig      // FieldColumnConfig
    public description?: string;            // FieldConfig      // FieldElement     // FieldColumnConfig
    public editable: boolean;               // FieldConfig      // FieldElement     // FieldColumnConfig
    public label: string;                   // FieldConfig      // FieldElement     // FieldColumnConfig
    public domain: string;                  // FieldConfig      // FieldElement
    public editorType: string;              // FieldConfig      -- text, number, date, email, number, tel, text, url | select, radio-h, radio-v, checkbox-h, checkbox-v 
    public hint?: string;                   // FieldConfig      // FieldElement
    public includeTime?: boolean;           // FieldConfig      // FieldColumnConfig
    public maxLength?: number;              // FieldConfig      // FieldColumnConfig
    public minLength?: number;              // FieldConfig      // FieldColumnConfig
    public requiredExpression?: string;     // FieldConfig      // FieldElement     // FieldColumnConfig    // Arcade expression to return true or false // FieldElement // FieldColumnConfig
    public visibilityExpression?: string;   // FieldConfig      // FieldElement     // Arcade expression to return true or false
    public required?: boolean;              // FieldConfig      // FieldElement       // FieldColumnConfig

    public direction?: string;              // FieldColumnConfig   //'asc', 'desc' 
    public sortable?: boolean;              // FieldColumnConfig
    public visible?: boolean;               // FieldColumnConfig

    //public fieldName: string;             // FieldElement

    ///public domain_lov?: Domain;              // Custom
    //public domain_lovParent?: ParentDomain;   // Custom
    public domain_lov?: string;                 // Custom
    public domain_VirtualFieldName?: string;// Custom 
    public domain_lovParent?: string;           // Custom
    public domain_lovParent_FieldName?: string; // Custom
    public domain_options: any[];               // Custom

    public format?: any;                    // Custom
    public prefix?: string;                 // Custom
    public suffix?: string;                 // Custom
    public readOnly?: boolean;              // Custom
    public includeInTable?: boolean;        // Custom
    public includeInTableIndex?: number;    // Custom

    public includeInCard?: boolean;                 // Custom
    public includeInCard_Title?: boolean;           // Custom
    public includeInCard_SubTitle?: boolean;        // Custom
    public includeInCard_Content_Row1?: boolean;    // Custom
    public includeInCard_Content_Row2?: boolean;    // Custom
    public includeInCard_Content_Row3?: boolean;    // Custom
    public includeInCard_Index?: number;            // Custom

    public defaultValue?: any;             // Custom       // Set to "date-now" for date fields (think about how to do date+1, date-1, etc)
    public geocode?: boolean;              // Custom

    //public preFieldContent?: string;            // Custom
    //public postFieldContent?: string;        // Custom

    //public input: any;                    // FieldElement // TextBoxInput TextAreaInput DateTimePickerInput
    //public menuConfig: ButtonMenuConfig;  // FieldColumnConfig

    // editorType:
    // color
    // date
    // datetime-local
    // email
    // month
    // number
    // password
    // search
    // tel
    // text
    // time
    // url
    // week

    // text-area

    constructor(name: string, label: string, editorType: string) {
        this.name = name;
        this.label = label;
        this.editorType = editorType;
        this.editable = true;
        this.visible = true;
        this.required = false;
        this.readOnly = false;
        this.includeInTable = false;
        this.includeInCard = false;
    }
}

export class FieldDateFormat {
    public dateFormat: string;

    constructor() {
        this.dateFormat = 'day-short-month-year';
    }

    // constructor(format?: string) {
    //     if (format) {
    //         this.dateFormat = format;
    //     }
    //     else {
    //         this.dateFormat = 'day-short-month-year';
    //     }
    // }
}

export class FieldNumberFormat {
    public places: number;
    public digitSeparator: boolean;

    constructor(places: number) {
        this.places = places;
        this.digitSeparator = true;
    }
}

//
// Forms
//

export class FormTemplate {
    public title: string;
    public description: string;
    public elements: GroupElement[];

    constructor(title: string, description: string, elements: GroupElement[]) {
        this.title = title;
        this.description = description;
        this.elements = elements;
    }
}

export class GroupElement {
    public type: string;
    public label: string;
    public description: string;
    public elements: FieldElement[];

    constructor(label: string, description: string, elements: FieldElement[]) {
        this.type = 'group';
        this.label = label;
        this.description = description;
        this.elements = elements;
    }
}

export class FieldElement {
    public type: string;
    public fieldName: string;
    public label: string;
    public editable?: boolean;
    public required?: boolean;
    public domain?: string;
    public input?: any;

    constructor(fieldName: string, label: string, editorType?: string) {
        this.type = 'field';
        this.fieldName = fieldName;
        this.label = label;

        //this.editable = true;
        //this.required = false;

        // switch (editorType) {
        //     case 'date':
        //         this.input = {
        //             type: 'datetime-picker',
        //             includeTime: false//,
        //             //min: 1547678342000,
        //             //max: 1610836742000
        //         }
        //         break;
        // }

        if (editorType.toLowerCase() === 'date') {
            this.input = {
                type: 'datetime-picker',
                includeTime: false
            }
        }
    }
}

//
// Attachments
//

export class AttachmentInfo {
    public id: number;
    public globalId: string;
    public name: string;
    public contentType: string;
    public size: number;
    public keywords: string;
    public exifInfo?: any;
    public url: string;
}

export class AttachmentGroup {
    public parentObjectId: number;
    public parentGlobalId: string;
    public attachmentInfos: AttachmentInfo[];
}

export class AttachmentObject {
    public fields: Field[];
    public attachmentGroups: AttachmentGroup[];
}

export interface ReportImages {
    feature: any;
    mapImageUrl: string;
    mapImageBlob: any;
    attachmentInfos: AttachmentInfo[];
    featureImageBlobs: any[];
    featureImageSizes: any[];
    imageSizeInfos: ImageSizeInfo[];
}

//
// Camera 
//

export interface CameraSettings {
    heading: number;
    tilt: number;
    fov?: number;
    latitude: number;
    longitude: number;
    z: number;
}

//
// Apply Edits
//

export class ApplyEditsResultMessage {
    public result: string;
    public objectId: string;
    public globalId: string;
    public error: string;
    public message: string;
    public recordId?: string;
}

export class ApplyEditsResult {
    public addFeatureResults: FeatureEditResult[];
    public updateFeatureResults: FeatureEditResult[];
    public deleteFeatureResults: FeatureEditResult[];
    public addAttachmentResults: any[];
    public updateAttachmentResults: any[];
    public deleteAttachmentResults: any[];
}

export class FeatureEditResult {
    public objectId: string;
    public globalId: string;
    public error: FeatureEditError;
}

export class FeatureEditError {
    public message: string;
    public name: string;
    public details: FeatureEditErrorDetail;
}

export class FeatureEditErrorDetail {
    public code: any;
}

//
//
//

export interface ImageSizeInfo {
    url: string;
    blob: Blob;
    width: number;
    height: number;
    orientation: string;
    aspectRatio: number;
}

export interface LabelExpressionInfo {
    expression?: string;
}

export interface LabelingInfo {
    labelExpressionInfo?: LabelExpressionInfo;
    maxScale?: number;
    minScale?: number;
}

export interface DrawingInfo {
    renderer?: any;
    showLabels?: boolean;           // Labels aren't supported in Feature Services (Map Services only)
    labelingInfo?: LabelingInfo;    // Labels aren't supported in Feature Services (Map Services only)
}

export interface LayerDefinition {
    definitionExpression?: string;
    drawingInfo?: DrawingInfo;
    objectIds?: any[];
}

export interface OperationalLayer {
    id: string;
    url: string;
    token: string;
    title: string;
    opacity?: number;
    visibility?: boolean;
    minScale?: number;
    maxScale?: number;
    layerDefinition?: LayerDefinition;
}

//
// Dashboard
//

export interface DashboardChart {
    chartName: string;
    chartType?: string;
    label?: string;
    description?: string;
    kpi?: string;
    visibleChip?: boolean;
    clause?: string;
    icon?: string;
    colour?: string;
    summaryData?: any[];
}

//
// Reports
//

export interface ReportDefinition {
    moduleId: string;
    reportId: string;
    reportName: string;
    reportTitleLine1: string;
    reportTitleLine2?: string;
    reportDescription?: string;
    featureLayer?: any;
    features?: any[];
    selecedFeature?: any;
    domains?: any[];
    domains_lov?: any[];
    domains_lovParent?: any[];
}

export interface ProgressMessage_Report {
    numberSteps: number;
    currentStepNumber: number;
    currentStepName: string;
    numberItems: number;
    currentItemNumber?: number;
    currentItemName?: string;
    message?: string;
    stepProgress?: number;
    itemProgress?: number;
}

//
// Next Number / Id
//

export class NextId {
    public static NextIdNumbers: FeatureLayerProperties = new FeatureLayerProperties(
        LayerIds.gisServer_NextId, LayerIds.folder_NextId, LayerIds.layerId_TblNextId, 'tableNextId', 'Next Id'
    );

    public static createLayerPropertiesList(): FeatureLayerProperties {
        let featureLayerProperties: FeatureLayerProperties;

        // Tables
        featureLayerProperties = NextId.NextIdNumbers;
        return featureLayerProperties;
    }
}

//
// Security
//

export class Credentials {
    public username: string;
    public password: string;

    constructor() { }
}
