import { Injectable } from '@angular/core';
import { FeaturesService } from 'src/app/_esri/services/features.service';
import { Esri, FeatureLayerProperties, SpatialRelationship } from 'src/app/_esri/models/esri'; //, FeatureServiceObject, ElevationInfo
import { Surrounds } from '../models/surrounds';
import { LayerIds } from 'src/app/_esri/models/layer-ids';

@Injectable({
  providedIn: 'root'
})

export class SurroundsService {

    constructor(
        private featuresService: FeaturesService
    ) { }

    public async loadCourseBoundaryLayer(is3D: boolean, opacity?: number ): Promise<any> {
        let featureLayer;
        let featureLayerProperties = Surrounds.createLayerProperties_CourseBoundary(is3D, opacity);

        await this.featuresService.loadLayer(is3D, featureLayerProperties).then(layer => {
        console.log('Loading Course Boundary Layer');
        featureLayer = layer;
        });

        return featureLayer;
    }

    public async loadSurroundsLayers(is3D: boolean, allSurrounds?: boolean): Promise<any> {
        let featureLayers: any[] = [];

        // Surrounding layers
        let featureLayerProperties_Surrounds: FeatureLayerProperties[] = [];
        featureLayerProperties_Surrounds = Surrounds.createLayerPropertiesList(is3D, allSurrounds, 0.4);

        await this.featuresService.loadLayers(is3D, featureLayerProperties_Surrounds).then(layers => {
            console.log('Loading Surrounds Layers');
            featureLayers = layers;
        });

        return featureLayers;
    }

    public async loadSurroundsLayers_Tiles(is3D: boolean, opacity?: number, allSurrounds?: boolean): Promise<any> {
        let tileLayers: any[] = [];

        Surrounds.TileLayers.opacity = opacity;

        // Surrounding layers
        await this.featuresService.loadLayer(is3D, Surrounds.TileLayers).then(layers => {
            //console.log('Loading Surrounds Tile Layers : layers', layers);
            tileLayers.push(layers);
            })
            .catch(function(error){
            console.log("Loading Surrounds Tile Layers - Error details: ", error.details);
        });

        return tileLayers;
    }

    public async loadNswProperty(is3D: boolean, opacity?: number): Promise<any> {

        let featureLayer: any;
        let featureLayerProperties = new FeatureLayerProperties(LayerIds.gisServer_NSW, LayerIds.folder_NSW_Property, LayerIds.layerId_Urban_Property, 'mapLayerNswProperty', 'NSW Property', 'MAP'); //.mapService();
        
        // Set attributes
        if (opacity) {
            featureLayerProperties.opacity = opacity;
        }
        else {
            featureLayerProperties.opacity = 0.5;
        }

        featureLayerProperties.minScale = 2500;
        //featureLayerProperties.outFields = ['housenumber'];
        //featureLayerProperties.outFields = ['*'];
        featureLayerProperties.outFields = ['housenumber','address'];
        featureLayerProperties.renderer = Esri.solidOutlineRenderer;
        
        let labelInfo = { 
            symbol: Esri.text10px,
            labelExpressionInfo: {
                expression: "$feature.housenumber"
                //expression: "$feature.propid"
                //expression: "$feature.address"
            },
            maxScale: 0,
            minScale: 2000
        };

        featureLayerProperties.labelsVisible = true;
        featureLayerProperties.labelingInfo = labelInfo; 
        //featureLayerProperties.mapService();
        
        // Retrieve and load the feature layers from the ArcGIS server(s)
        await this.featuresService.loadLayer(is3D, featureLayerProperties).then(layer => {
            console.log('Loading NSW Property Layer');
            //console.log(layer);
            featureLayer = layer;
        });

        return featureLayer;
    }

    public async loadHillshade(is3D: boolean, opacity?: number): Promise<any> {
        let featureLayer: any;
        let featureLayerProperties = new FeatureLayerProperties(LayerIds.gisServer_Hillshade, LayerIds.folder_Hillshade, LayerIds.layerId_Hillshade, 'tileLayerHillshade', 'Hillshade', 'TILE'); //.mapService();
        
        // Set attributes
        if (opacity) {
            featureLayerProperties.opacity = opacity;
        }
        else {
            featureLayerProperties.opacity = 0.1;
        }
        
        featureLayerProperties.minScale = 5000;
        //featureLayerProperties.tileService();
        
        // Retrieve and load the feature layers from the ArcGIS server(s)
        await this.featuresService.loadLayer(is3D, featureLayerProperties).then(layer => {
            console.log('Loading Hillshade Layer');
            //console.log(layer);
            featureLayer = layer;
        });

        return featureLayer;
    }

    public async load3dSurroundingBuildings(): Promise<any> {

    let featureLayer: any;
    const is3D = true;

    //https://tiles.arcgis.com/tiles/GEY7BOibXkcbWcpS/arcgis/rest/services/Hillshade/MapServer
    //https://tiles.arcgis.com/tiles/GEY7BOibXkcbWcpS/arcgis/rest/services/Tree/SceneServer
    //https://tiles.arcgis.com/tiles/GEY7BOibXkcbWcpS/arcgis/rest/services/Tree/SceneServer?token=

    let featureLayerProperties = new FeatureLayerProperties('https://tiles.arcgis.com/tiles/GEY7BOibXkcbWcpS/arcgis/rest/services', 'BuildingSurround', '', 'sceneLayerBuildingSurround', 'Surround Buildings');

    featureLayerProperties.url = 'https://tiles.arcgis.com/tiles/GEY7BOibXkcbWcpS/arcgis/rest/services/BuildingSurround/SceneServer';

    //console.log('load3dTrees() featureLayerProperties', featureLayerProperties);


    console.log('Loading BuildingSurround 3D Layer url:', featureLayerProperties.url);
    //featureLayerProperties.sceneService();

    // Retrieve and load the feature layers from the ArcGIS server(s)
    await this.featuresService.loadLayer(is3D, featureLayerProperties).then(layer => {
        //console.log('Loading BuildingSurround 3D Layer');
        //console.log(layer);
        featureLayer = layer;
    });

    return featureLayer;

    }

}
