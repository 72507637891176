<app-header>
    <span title>{{title}}</span>
    <span toolbar-extras>
    </span>
</app-header>

<div *ngIf='!thinking' style="height: 4px;"></div>
<div *ngIf='thinking'><mat-progress-bar mode="query"></mat-progress-bar></div>

<!-- Cards Row -->
<div fxLayout='row' fxLayoutGap="20px" fxLayoutAlign="space-between center" class='cards-row' >
    <app-esri-info-box *ngFor='let card of dashboardCards' fxFlex='cardWidth' [darkMode]='darkMode' [infoBoxTitle]='card.label' [infoBoxValue]='card.summaryData[0]' [infoBoxDescription]='card.description' [infoBoxIcon]='card.icon' [infoBoxColour]='card.colour'></app-esri-info-box>
</div>

<!-- Chips Row -->
<div fxLayout='row' fxLayoutAlign="center center" class='chips-row'>
    <div>
        <mat-chip-list #chipListAll aria-label="">
            <span *ngFor="let chart of dashboardCharts">
                <mat-chip [selectable]="true" [removable]="removableChip" *ngIf="chart.visibleChip" (removed)="removeChip(chart.chartName)">
                    {{chart.label}}
                    <mat-icon matChipRemove *ngIf="removableChip">cancel</mat-icon>
                </mat-chip>
            </span>
        </mat-chip-list>
    </div>
</div>

<!-- Dashboard Row 1 -->
<div fxLayout='row' fxLayoutGap="20px" fxLayoutAlign="space-between center" class='chart-row chart-row-1'>
    <!-- Chart 1 -->
    <div fxFlex="33" class='chart-box' [ngClass]="{'dark-chart-box': darkMode}">
        <div fxLayout='column' fxLayoutAlign="space-between center"> 
            <!-- <div fxLayout='row' fxShow fxHide.lt-md fxLayoutAlign="space-between center">
                <div [ngClass]="{'dark': darkMode}">
                    <ngx-charts-advanced-pie-chart 
                        [view]="[0.33 * chartWidth, chartHeight]"
                        [results]="getChartData('MEMBERSHIP_TYPE')"
                        [label]="'Number of applicants'"
                        [gradient]="true" 
                        [scheme]='customColorScheme'     
                        (select)="selectChart($event, 'MEMBERSHIP_TYPE', 'LovMembershipType', 'MembershipTypeId', false)">
                    </ngx-charts-advanced-pie-chart>
                </div>
            </div> -->
            <div fxLayout='row' fxLayoutAlign="space-between center">
                <div [ngClass]="{'dark': darkMode}">
                    <ngx-charts-pie-chart
                        [view]="[0.33 * chartWidth, chartHeight]"
                        [results]="getChartData('MEMBERSHIP_TYPE')"
                        [labels]="true"
                        [gradient]="true" 
                        [scheme]='customColorScheme' 
                        (select)="selectChart($event, 'MEMBERSHIP_TYPE', 'LovMembershipType', 'MembershipTypeId', false)">
                    </ngx-charts-pie-chart>
                </div>
            </div>
            <div>
                <span class='chart-title' [ngClass]="{'dark-chart-title': darkMode}">Number of Waiting Applicants - Membership Type</span>
            </div>
        </div>
    </div>
    <!-- Chart 2 -->
    <div fxFlex="33" class='chart-box' [ngClass]="{'dark-chart-box': darkMode}">
        <div fxLayout='column' fxLayoutAlign="space-between center"> 
            <div [ngClass]="{'dark': darkMode}">
                <ngx-charts-pie-chart
                    [view]="[0.33 * chartWidth, chartHeight]"
                    [results]="getChartData('GENDER')"
                    [labels]="true"
                    [gradient]="true" 
                    [customColors]='genderColorScheme'
                    (select)="selectChart($event, 'GENDER', 'DomGender', 'Gender', false)">
                </ngx-charts-pie-chart>
            </div>

            <div>
                <span class='chart-title' [ngClass]="{'dark-chart-title': darkMode}">Number of Waiting Applicants - Gender</span>
            </div>
        </div>
    </div>
    <!-- Chart 3 -->
    <div fxFlex="33" class='chart-box' [ngClass]="{'dark-chart-box': darkMode}">
        <div fxLayout='column' fxLayoutAlign="space-between center"> 
            <div fxLayout='row' fxShow fxHide.lt-md fxLayoutAlign="space-between center">
                <div [ngClass]="{'dark': darkMode}">
                    <ngx-charts-bar-vertical
                        [view]="[0.33 * chartWidth, chartHeight]"
                        [results]="getChartData('WAITING_TIME')"
                        [xAxisLabel]="''" 
                        [yAxisLabel]="'Number of applicants'"
                        [showXAxisLabel]='false'
                        [showYAxisLabel]='true'
                        [xAxis]='true'
                        [yAxis]='true'
                        [trimXAxisTicks]='true'
                        [maxXAxisTickLength]='15'
                        [rotateXAxisTicks]='true'    
                        [tooltipDisabled]='false' 
                        [gradient]='true'
                        [noBarWhenZero]='true'
                        [roundEdges]='false'
                        [barPadding]='1'
                        [showDataLabel]='true'
                        [scheme]='customColorScheme' 
                        >
                    </ngx-charts-bar-vertical>
                </div>
            </div>
            <div>
                <span class='chart-title' [ngClass]="{'dark-chart-title': darkMode}">Number of Waiting Applicants - Time on Waitlist</span>
            </div>
        </div>
    </div>
</div>


<!-- Dashboard Row 2 -->
<div fxLayout='row' fxLayoutGap="20px" fxLayoutAlign="center start" class='chart-row' style="margin-top: 100px; margin-bottom: 100px;">
    <div style='width: 1200px;'>
        <mgl-timeline [alternate]="alternate" [side]="side">
            <mgl-timeline-entry *ngFor="let entry of timelineData; let i = index;" (expand)="onExpandEntry($event, i)">

                <mgl-timeline-entry-header #header (click)="onHeaderClick($event)">
                    {{entry.header}}
                </mgl-timeline-entry-header>

                <mgl-timeline-entry-content [expandAnimationTiming]="contentAnimation ? '200ms ease' : '0ms'" [collapseAnimationTiming]="contentAnimation ? '100ms ease' : '0ms'"> 
                    <mat-list>
                        <span *ngFor='let item of entry.content; let j = index;'>
                            <mat-list-item>{{item.name}} - {{item.type}}. Received: {{item.received}} ({{item.status}})</mat-list-item>    
                        </span>
                    </mat-list>
                </mgl-timeline-entry-content>

                <mgl-timeline-entry-dot [class]="color ? 'primary' : 'accent'"  [size]="setDotSize(entry.count)" [expandAnimationTiming]="dotAnimation ? '200ms ease' : '0ms'" [collapseAnimationTiming]="dotAnimation ? '100ms ease' : '0ms'"></mgl-timeline-entry-dot>
                <!-- <mgl-timeline-entry-side>{{entry.side}}</mgl-timeline-entry-side> -->

            </mgl-timeline-entry>
        </mgl-timeline>
    </div>
</div>

<!-- Footer -->
<!-- <app-footer></app-footer> -->
