<span *ngIf='loginType==="POPUP"'>
    <button  mat-menu-item (click)='openPopupLogin()'>
        <mat-icon class='material-icons-sharp'>login</mat-icon>
        <span>POPUP Login</span>
    </button>
</span>

<span *ngIf='loginType==="INLINE"'>
    <button mat-menu-item (click)='openInlineLogin()'>
        <a href='{{href}}'>
            <mat-icon class='material-icons-sharp'>login</mat-icon>
            <span>INLINE Login</span>
        </a>
    </button>
</span>

<span *ngIf='loginType==="OAUTH"'>
    <button mat-menu-item (click)='oAuthLogin()'>
        <mat-icon class='material-icons-sharp'>login</mat-icon>
        <span>OAUTH Login</span>
    </button>
</span>
