import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class ProgressService {
  public progressData = new Subject();  

  constructor() { }
}
