import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NgxGalleryOptions, NgxGalleryImage } from '@nomadreservations/ngx-gallery';
import { LocalStorage } from 'ngx-webstorage';
import { ConfirmComponent, ConfirmDialogModel } from 'src/app/_esri/dialogs/confirm/confirm.component';
import { AttachmentInfo } from 'src/app/_esri/models/esri';
import { FormGroup, FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-esri-attachment',
  templateUrl: './esri-attachment.component.html',
  styleUrls: ['./esri-attachment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class EsriAttachmentComponent implements OnInit {

  private _numberOfTicks = 0;
  private _selectedFeature: any;
  public attachmentForm: FormGroup;

  // Gallery Settings
  private _galleryImage: NgxGalleryImage;
  private _attachmentInfos: AttachmentInfo[] = [];
  private _selectedGalleryImage: number = 0;
  public galleryOptions: NgxGalleryOptions[];
  public galleryImages: NgxGalleryImage[] = [];
  public fileProgress: number = 0;
  public numberOfImages: number;
  public numberOfImagesMsg: string;

  @ViewChild('attachmentUpload', { static: true }) private _attachmentUploadEl: ElementRef;

  @Input()
  set selectedFeature(selectedFeature: any) {
    this._selectedFeature = selectedFeature;
  }

  get selectedFeature(): any {
    return this._selectedFeature;
  }

  @Input()
  set attachmentInfos(attachmentInfos: AttachmentInfo[]) {
    this._attachmentInfos = attachmentInfos;
  }

  get attachmentInfos(): AttachmentInfo[] {
    return this._attachmentInfos;
  }

  // @Input()
  // set numberOfImages(numberOfImages: number) {
  //   this._numberOfImages = numberOfImages;
  // }

  // get numberOfImages(): number {
  //   return this._numberOfImages;
  // }

  // @Input()
  // set numberOfImagesMsg(numberOfImagesMsg: string) {
  //   this._numberOfImagesMsg = numberOfImagesMsg;
  // }

  // get numberOfImagesMsg(): string {
  //   return this._numberOfImagesMsg;
  // }

  @Output() attachmentInfosChange = new EventEmitter<AttachmentInfo[]>();
  @Output() galleryReadyEvent = new EventEmitter<boolean>();
  @Output() galleryImagesReadyEvent = new EventEmitter<boolean>();
  //@Output() numberOfImagesChange = new EventEmitter<number>();
  //@Output() numberOfImagesMsgChange = new EventEmitter<string>();

  @LocalStorage()
  public _token;

  constructor(
    private ref: ChangeDetectorRef,
    public dialog: MatDialog,
    private formBuilder: FormBuilder
  ) { 
    setInterval(() => {
      this._numberOfTicks++;
      this.ref.markForCheck();
    }, 1000);
  }

  ngOnInit(): void {
    // Initialise Gallery
    this.galleryOptions = [
      { 
        'image': false, 
        'thumbnailsRemainingCount': false, 
        'width': '100%', 
        'height': '150px', 
        'thumbnailsColumns': 6 
      }//,
      //{ 'breakpoint': 500, 'width': '100%' }
    ];

    this.attachmentForm = this.formBuilder.group({
      attachment: ['']
    });
  }

  public initilaiseGallery() {
    //console.log('initilaiseGallery');
    //console.log('initilaiseGallery this._feature', this._feature);

    this.galleryImages = [];

    //if (this._selectedFeature) {
    if (this._attachmentInfos) {
      // Initialise Gallery
      this._attachmentInfos.forEach (item =>  {
        //console.log('_attachmentInfos item', item);
        this._galleryImage =
        {
          small: item.url,
          medium: item.url,
          big: item.url,
          description: item.name,
          url: item.id.toString()
        };
        this.galleryImages.push(this._galleryImage);
      });

      //this._numberOfImages = (this.galleryImages) ? this.galleryImages.length : 0;
      this.createNumberImagesMessage();


      //this.numberOfImagesMsgChange.emit(this.numberOfImagesMsg);
      //this.numberOfImagesChange.emit(this.numberOfImages);
    }

    // // Send attachment info to parent component
    // this.attachmentInfosChange.emit(this._attachmentInfos);
    this.galleryReadyEvent.emit(true);
  }

  public galleryImagesReady(event) {
    console.log('galleryImagesReadyEvent esri-attachment');
    //console.log(event);
    this._selectedGalleryImage = 0;

    // enable icons?
    this.galleryImagesReadyEvent.emit(true);
  }

  public galleryImageSelected(event) {
    //console.log('galleryImageSelected event');
    this._selectedGalleryImage = event.index;
  }

  public onFileSelected(event) {
    //let file;
    if (event.target.files.length > 0) {
      let file = event.target.files[0];
      this.attachmentForm.get('attachment').setValue(file);

      //console.log('file', file);

      //const inputNode: any = this._attachmentUploadEl.nativeElement;
    
      if (typeof (FileReader) !== 'undefined') {
        const reader = new FileReader();
    
        // reader.onload = (e: any) => {
        //   console.log(e.target.result);
        // };

        reader.onloadstart = (event) => {
          console.log('start file read:');
        };

        reader.onloadend = (event) => {
          //console.log('end file read - uploading file to AGOL');
          // Push file to AGOL service
          this.uploadAttachment(file.name);
        };

        reader.onprogress = (event) => {
          if (event.lengthComputable) {
            //this.fileProgress = Math.round(event.loaded * 100 / event.total);
            console.log(Math.round(event.loaded * 100 / event.total));
          }
        };

        reader.readAsArrayBuffer(event.target.files[0]);
      }
    }
  }  

  private uploadAttachment(filename) {  
    const formData = new FormData();
    formData.append('attachment', this.attachmentForm.get('attachment').value);
    formData.append('f','json');
    //console.log('formData', formData, this._selectedFeature);

    this._selectedFeature.layer.addAttachment(this._selectedFeature, formData).then( 
      result => {
        //console.log('attachment added: ', result);

        // NEED TO CHECK FOR SUCCESS
        if (!result.error) {
          console.log('attachment add: SUCCESS - objectId', result.objectId);

          // Add the new image to the existing gallery
          const attachmentUrl = this._selectedFeature.layer.url + '/' + this._selectedFeature.layer.layerId + '/' + this._selectedFeature.attributes.OBJECTID + '/attachments/' + result.objectId + '?token=' + this._token;
          //console.log('attachmentUrl: ', attachmentUrl);

          // Update the image gallery to see new image
          this._galleryImage =
          {
            small: attachmentUrl,
            medium: attachmentUrl,
            big: attachmentUrl,
            description: filename,
            url: result.objectId.toString()
          };

          //console.log('this.galleryImages.push: ', this._galleryImage);
          this.galleryImages.push(this._galleryImage);
          
          // Update the number of images
          //this._numberOfImages = (this.galleryImages) ? this.galleryImages.length : 0;
          this.createNumberImagesMessage();
          //this.numberOfImagesChange.emit(this._numberOfImages);

          this.galleryReadyEvent.emit(true);
        }
        else {
          // error
          console.log('attachment add: FAIL', result.error);
        }
      },
      error => {
        console.log('attachment adding failed: ', error);
      }
    );
  }

  public deleteAttachment() {
    const message = 'Are you sure you want to delete the selected image?';
    const dialogData = new ConfirmDialogModel('Confirm Delete', message);
    const dialogRef = this.dialog.open(ConfirmComponent, {
      maxWidth: '400px',
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(dialogResponse => {

      if (dialogResponse === true) {

        console.log("deleting image");

        const index = this._selectedGalleryImage;
        const attachmentIds = [this.galleryImages[index].url];

        this._selectedFeature.layer.deleteAttachments(this._selectedFeature, attachmentIds).then(
          result => {
            console.log('attachment deleted: ', result);
            // NEED TO CHECK FOR SUCCESS ?
            // Remove image from the gallery
            this.galleryImages.splice(index, 1);

            // Update the number of images           
            this.createNumberImagesMessage();
            //this.numberOfImagesChange.emit(this._numberOfImages);

          },
          error => {
            console.log('attachment delete failed: ', error);
          }
        );
      }
      else {
        console.log("NOT deleting image");
      }
    });
  }


  private createNumberImagesMessage() {
    let msg: string;

    this.numberOfImages = (this.galleryImages) ? this.galleryImages.length : 0;

    if (this.numberOfImages === 0) {
      msg = 'There are no images to display';
    }
    else if (this.numberOfImages === 1) {
      msg = '1 image to display';
    }
    else {
      msg = this.numberOfImages.toString() + ' images to display';
    }

    this.numberOfImagesMsg = msg;
  }
}

