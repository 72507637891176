import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

//GeocodeDataService

export class GeocodeDataService {
  public geocodeData = new Subject();
  constructor() { }
}

