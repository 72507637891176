import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MaterialModule } from '../app/_globals/material-module';
import { YouTubePlayerModule } from '@angular/youtube-player';
import { VgCoreModule } from 'ngx-videogular';
import { NgxGalleryModule } from '@nomadreservations/ngx-gallery';
import { AngularSplitModule } from 'angular-split';
import { NgxWebstorageModule } from 'ngx-webstorage';
//import { QuillModule } from 'ngx-quill'
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { NgxChartsModule }from '@swimlane/ngx-charts';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
//import { ScrollingModule } from '@angular/cdk/scrolling';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { EsriModule } from './_esri/esri.module';
import { CourseModule } from './_course/course.module';
import { NavModule } from 'src/app/_nav/nav.module';
import { MglTimelineModule } from 'angular-mgl-timeline';

// used to create fake backend
// import { fakeBackendProvider } from 'src/app/_nav/helpers/fake-backend';
// import { HTTP_INTERCEPTORS } from '@angular/common/http';
// import { JwtInterceptor } from 'src/app/_nav/helpers/jwt-interceptor';
// import { ErrorInterceptor } from 'src/app/_nav/helpers/error-interceptor';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MaterialModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    AppRoutingModule,
    EsriModule,
    CourseModule,
    NavModule,
    YouTubePlayerModule,
    VgCoreModule,
    NgxGalleryModule,
    AngularSplitModule.forRoot(),
    NgxWebstorageModule.forRoot(),
    //QuillModule.forRoot(),
    CKEditorModule,
    NgxChartsModule,
    NgxSliderModule,
    //ScrollingModule,
    NgScrollbarModule,
    MglTimelineModule
  ],
  exports: [
  ],
  providers: [
    // { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    // { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },

    // // provider used to create fake backend
    // fakeBackendProvider
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
