import { Injectable, Output, EventEmitter } from '@angular/core';
import { combineLatest } from 'rxjs';
import { reduce, takeUntil } from 'rxjs/operators';
import { Esri, FormFieldConfig, FieldConfig, FieldDateFormat, FieldNumberFormat, Domain, SubDomain } from 'src/app/_esri/models/esri';
import { LayerIds } from 'src/app/_esri/models/layer-ids';
import { Global } from 'src/app/_globals/models/global';
import { LovDataService } from 'src/app/_globals/services/lov-data.service';
import { DomainLovService } from 'src/app/_esri/services/domain-lov.service';
import { FeatureLayerObjectBaseAttributes, FeatureLayerObjectBase } from 'src/app/_esri/models/base-feature-class';
import { ContactBaseAttributes, ContactBase } from 'src/app/_esri/models/common-feature-class';

// Member Loads

// export class MemberDataLoadAttributes extends ContactBaseAttributes {

//   public DataLoadId: string;
//   public DataSourceId: string;
//   public DateDataLoaded: Date;

//   constructor(
//     protected lovDataService: LovDataService,
//     protected domainLovService: DomainLovService
//   ) {
//     super(
//       lovDataService,
//       domainLovService
//     );
//   }

//   // Virtual Attributes

//   static get LatestLoad(): number {
//     return 1;
//   }

//   // get FullAddress_Display(): string {
//   //   return Global.formatFullAddress(this.AddressLine1, this.AddressLine2, this.Suburb, this.State, this.Postcode, this.Country);
//   // }

//   // get MembershipType(): string {
//   //   if (this.MembershipTypeId) {
//   //     return this.domainLovService.getDomainLovValue('LovMembershipDataLoadSource', this.DataSourceId);
//   //   }
//   //   else {
//   //     return null;
//   //   }
//   // }

// }


// @Injectable()
// export class MemberDataLoad extends FeatureBase {
//   attributes: MemberDataLoadAttributes[];
//   server = LayerIds.gisServer_MembersDataLoad;
//   folder = LayerIds.folder_MembersDataLoad;
//   layerId = LayerIds.layerId_MembersDataLoad;
//   id = 'tableMembershipDataLoad';
//   title = 'MembershipDataLoad';


//   whereClause = "1=1";

//   //@Output() typeLovReadyEvent = new EventEmitter<any>();




//   protected async createFormFieldConfigs(): Promise<FormFieldConfig[]> {
//     let formFieldConfig: FormFieldConfig;
//     let fieldConfigs: FieldConfig[] = [];
//     let fieldConfig: FieldConfig;
//     let format: any;

//     // Source

//     fieldConfigs = [];

//     fieldConfig = new FieldConfig('DataLoadId', 'DataLoadId:', 'text');
//     fieldConfig.visible = false;
//     fieldConfig.required = false;
//     fieldConfig.editable = false;
//     fieldConfig.maxLength = 20;
//     fieldConfigs.push(fieldConfig);

//     fieldConfig = new FieldConfig('DataSourceId', 'DataSourceId', 'text'); /////
//     fieldConfig.maxLength = 50;
//     fieldConfig.editable = false;
//     fieldConfig.visible = false;
//     fieldConfigs.push(fieldConfig);

//     fieldConfig = new FieldConfig('DateDataLoaded', 'LoadDate', 'date');
//     fieldConfig.editable = false;
//     fieldConfig.visible = false;
//     fieldConfigs.push(fieldConfig);

//     formFieldConfig = new FormFieldConfig('Source', fieldConfigs);
//     this.formFieldConfigs.push(formFieldConfig);

//     // Virtual (and hidden)

//     // fieldConfigs = [];

//     // fieldConfig = new FieldConfig('MembershipType', 'MembershipType:', 'text');
//     // fieldConfig.readOnly = true;
//     // fieldConfig.visible = false;
//     // fieldConfigs.push(fieldConfig);

//     // formFieldConfig = new FormFieldConfig('Virtual', fieldConfigs);
//     // this.formFieldConfigs.push(formFieldConfig);

//     super.createFormFieldConfigs();

//     return this.formFieldConfigs;
//   }

//   protected updateVirtualFields() {
//     // Reset
//     this.attributes = [];

//     this.features.forEach((f) => {
//       let attr: MemberDataLoadAttributes = new MemberDataLoadAttributes(this.lovDataService, this.domainLovService);

//       // Data Load
//       attr.DataLoadId = f.attributes.DataLoadId;
//       attr.DataSourceId = f.attributes.DataSource;
//       attr.DateDataLoaded = f.attributes.DateDataLoaded;

//       // Common
//       attr.OBJECTID = f.attributes.OBJECTID;
//       attr.ClubId = f.attributes.ClubId;
//       attr.Active = f.attributes.Active;
//       attr.GlobalID = f.attributes.GlobalID;
//       attr.HasLocation = f.attributes.HasLocation;
//       attr.CreationDate = f.attributes.CreationDate;
//       attr.Creator = f.attributes.Creator;
//       attr.EditDate = f.attributes.EditDate;
//       attr.Editor = f.attributes.Editor;

//       // Populate virtual / calculated fields
//       // f.attributes.MembershipType = attr.MembershipType;


//       this.attributes.push(attr);
//     });
//   }

//   protected getLovs() {

//     //this.lovReadyEvent.emit(true);  // No LOV's, so emit event

//     //All LOV Domains
//     combineLatest([
//       this.typeLovReadyEvent,
//       this.categoryLovReadyEvent
//     ]).pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
//       console.log('Membership/FeatureBase getLovs() lovsReady()');
//       this.lovReadyEvent.emit(true);
//     });

//     this.domains_lov = [];
//     this.domains_lovSubDomain = [];

//     //let membershipTypeLOV: MembershipTypeLOV = new MembershipTypeLOV(this.lovDataService, this.domainLovService);
//     let membershipTypeLOV: MembershipTypeLOV = new MembershipTypeLOV();
//     membershipTypeLOV.getLayer().then(() => {
//       membershipTypeLOV.getDomain('LovMembershipType', 'coded-value').then((domain) => {
//         this.domains_lov.push(domain);
//         this.typeLovReadyEvent.emit();
//       });
//     });

//     //let membershipCategoryLOV: MembershipCategoryLOV = new MembershipCategoryLOV(this.lovDataService, this.domainLovService);
//     let membershipCategoryLOV: MembershipCategoryLOV = new MembershipCategoryLOV();
//     membershipCategoryLOV.getLayer().then(() => {
//       membershipCategoryLOV.getDomain('LovMembershipCategory', 'coded-value').then((domain) => {
//         this.domains_lov.push(domain);
//         this.categoryLovReadyEvent.emit();
//       });
//     });
//   }
// }






// Members

export class MemberAttributes extends ContactBaseAttributes {
  public MembershipNumber: string;
  public MembershipTypeId: string;
  public MembershipCategoryId: string;
  public MembershipStartDate: Date;
  public DateOfBirth: Date;
  public Gender: string;
  public DataLoadId: string;
  public DataSource: string;
  public DateDataLoaded: Date;

  constructor(
    protected lovDataService: LovDataService,
    protected domainLovService: DomainLovService
  ) {
    super(
      lovDataService,
      domainLovService
    );
  }

  // Virtual Attributes

  get MembershipTenure(): number {
    return Global.calculateYearsToNow(this.MembershipStartDate);
  }

  get MembershipTenureFinancialYearEnd(): number {
    return Global.calculateYears(Global.getCurrentFinancialYearEndDate(), this.MembershipStartDate);
  }

  get Age(): number {
    return Global.calculateYearsToNow(this.DateOfBirth);
  }

  get AgeFinancialYearEnd(): number {
    return Global.calculateYears(Global.getCurrentFinancialYearEndDate(), this.DateOfBirth);
  }

  get FullAddress_Display(): string {
    return Global.formatFullAddress(this.AddressLine1, this.AddressLine2, this.Suburb, this.State, this.Postcode, this.Country);
  }

  get MembershipType(): string {
    if (this.MembershipTypeId) {
      return this.domainLovService.getDomainLovValue('LovMembershipType', this.MembershipTypeId);
    }
    else {
      return null;
    }
  }

  get MembershipCategory(): string {
    if (this.MembershipCategoryId) {
      return this.domainLovService.getDomainLovValue('LovMembershipCategory', this.MembershipCategoryId);
    }
    else {
      return null;
    }
  }
}

@Injectable()
export class Member extends FeatureLayerObjectBase {
  attributes: MemberAttributes[];
  server = LayerIds.gisServer_Members;
  folder = LayerIds.folder_Members;
  layerId = LayerIds.layerId_Members;
  id = 'featureLayerMembership';
  title = 'Membership';

  minScale = 0;
  whereClause = "1=1";

  // Labels
  labelInfo = {
    //symbol: Esri.text12pxWithHalo,
    symbol: Esri.text12px,
    labelExpressionInfo: {
      //expression: "$feature.AuditItemNumber + ' - ' + $feature.AuditItemTitle"  
      //expression: "$feature.AuditItemNumber"  
      //expression: "$feature.AuditItemTitle + ' (' + $feature.AuditItemNumber + ')'"  
    },
    maxScale: 0,
    minScale: 4000
    //where: "$feature.HasLocation = 'YES'"
  };

  labelsVisible = false;
  labelingInfo = this.labelInfo;
  popupEnabled = false;
  legendEnabled = false;

  @Output() typeLovReadyEvent = new EventEmitter<any>();
  @Output() categoryLovReadyEvent = new EventEmitter<any>();

  // Clustering
  clusterConfig = {
    type: "cluster",
    clusterRadius: "100px",
    // {cluster_count} is an aggregate field containing
    // the number of features comprised by the cluster
    popupEnabled: false,
    popupTemplate: {
      content: "This cluster represents {cluster_count} members.",
      fieldInfos: [{
        fieldName: "cluster_count",
        format: {
          places: 0,
          digitSeparator: true
        }
      }]
    },
    clusterMinSize: "24px",
    clusterMaxSize: "60px",
    labelsVisible: true,
    labelingInfo: [{
      deconflictionStrategy: "none",
      labelExpressionInfo: {
        expression: "Text($feature.cluster_count, '#,###')"
      },
      symbol: {
        type: "text",
        //color: "#004a5d",
        color: "#ffffff",
        font: {
          weight: "bold",
          family: "Noto Sans",
          size: "12px"
        }
      },
      labelPlacement: "center-center",
    }]
  }

  // Renderer
  featureReduction = this.clusterConfig;

  renderer = {
    type: 'simple',
    field: 'OBJECTID',
    symbol: {
      type: 'simple-marker',
      size: 10,
      //color: [ 255, 190, 0, 0.75 ],
      color: [103, 58, 183, 0.75],
      outline: {
        width: 1,
        color: 'white'
      }
    }
  };

  protected async createFormFieldConfigs(): Promise<FormFieldConfig[]> {
    let formFieldConfig: FormFieldConfig;
    let fieldConfigs: FieldConfig[] = [];
    let fieldConfig: FieldConfig;
    let format: any;

    // Member

    fieldConfigs = [];

    fieldConfig = new FieldConfig('MembershipNumber', 'Member Number:', 'text');
    fieldConfig.maxLength = 20;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('FullName', 'Member Name:', 'text');
    fieldConfig.maxLength = 150;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('MembershipTypeId', 'Membership Type:', 'text');
    fieldConfig.domain_lov = 'LovMembershipType'
    fieldConfig.domain_VirtualFieldName = 'MembershipType';
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('MembershipCategoryId', 'Membership Category:', 'text');
    fieldConfig.maxLength = 20;
    fieldConfig.domain_lov = 'LovMembershipCategory'
    fieldConfig.domain_VirtualFieldName = 'MembershipCategory';
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('MembershipStartDate', 'Member Since', 'date');
    format = new FieldDateFormat();
    fieldConfig.format = format;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('MembershipTenure', 'Membership Tenure:', 'number');
    format = new FieldNumberFormat(0);
    fieldConfig.editable = false;
    fieldConfig.format = format;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('DateOfBirth', 'Date of Birth', 'date');
    format = new FieldDateFormat();
    fieldConfig.format = format;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('Age', 'Age:', 'number');
    format = new FieldNumberFormat(0);
    fieldConfig.editable = false;
    fieldConfig.format = format;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('AgeFinancialYearEnd', 'Age (end FY):', 'number');
    format = new FieldNumberFormat(0);
    fieldConfig.editable = false;
    fieldConfig.format = format;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('Gender', 'Gender:', 'text');
    fieldConfig.domain = 'DomGender';
    fieldConfig.maxLength = 10;
    fieldConfigs.push(fieldConfig);

    formFieldConfig = new FormFieldConfig('Member', fieldConfigs);
    this.formFieldConfigs.push(formFieldConfig);

    // Source

    fieldConfigs = [];

    fieldConfig = new FieldConfig('DataLoadId', 'DataLoadId:', 'text');
    fieldConfig.visible = false;
    fieldConfig.required = false;
    fieldConfig.editable = false;
    fieldConfig.maxLength = 20;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('DataSource', 'DataSource', 'text');
    fieldConfig.maxLength = 50;
    fieldConfig.editable = false;
    fieldConfig.visible = false;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('DateDataLoaded', 'LoadDate', 'date');
    fieldConfig.editable = false;
    fieldConfig.visible = false;
    fieldConfigs.push(fieldConfig);

    formFieldConfig = new FormFieldConfig('Source', fieldConfigs);
    this.formFieldConfigs.push(formFieldConfig);

    // Virtual (and hidden)

    fieldConfigs = [];

    fieldConfig = new FieldConfig('MembershipType', 'MembershipType:', 'text');
    fieldConfig.readOnly = true;
    fieldConfig.visible = false;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('MembershipCategory', 'MembershipCategory:', 'text');
    fieldConfig.readOnly = true;
    fieldConfig.visible = false;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('MembershipTenure', 'MembershipTenure:', 'number');
    fieldConfig.readOnly = true;
    fieldConfig.visible = false;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('MembershipTenureFinancialYearEnd', 'MembershipTenureFinancialYearEnd:', 'number');
    fieldConfig.readOnly = true;
    fieldConfig.visible = false;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('Age', 'Age:', 'number');
    fieldConfig.readOnly = true;
    fieldConfig.visible = false;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('AgeFinancialYearEnd', 'AgeFinancialYearEnd:', 'number');
    fieldConfig.readOnly = true;
    fieldConfig.visible = false;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('FullAddress_Display', 'FullAddress_Display:', 'text');
    fieldConfig.readOnly = true;
    fieldConfig.visible = false;
    fieldConfigs.push(fieldConfig);

    formFieldConfig = new FormFieldConfig('Virtual', fieldConfigs);
    this.formFieldConfigs.push(formFieldConfig);

    super.createFormFieldConfigs();

    return this.formFieldConfigs;
  }

  protected updateVirtualFields() {
    // Reset
    this.attributes = [];

    this.features.forEach((f) => {
      let attr: MemberAttributes = new MemberAttributes(this.lovDataService, this.domainLovService);

      attr.MembershipNumber = f.attributes.MembershipNumber;
      attr.MembershipTypeId = f.attributes.MembershipTypeId;
      attr.MembershipCategoryId = f.attributes.MembershipCategoryId;
      attr.MembershipStartDate = f.attributes.MembershipStartDate;
      attr.DateOfBirth = f.attributes.DateOfBirth;
      attr.Gender = f.attributes.Gender;

      // Contact
      attr.FullAddress = f.attributes.FullAddress;
      attr.AddressLine1 = f.attributes.AddressLine1;
      attr.AddressLine2 = f.attributes.AddressLine2;
      attr.Suburb = f.attributes.Suburb;
      attr.State = f.attributes.State;
      attr.City = f.attributes.City;
      attr.Postcode = f.attributes.Postcode;
      attr.Country = f.attributes.Country;
      attr.FullName = f.attributes.FullName;
      attr.FirstName = f.attributes.FirstName;
      attr.LastName = f.attributes.LastName;
      attr.HomeNumber = f.attributes.HomeNumber;
      attr.MobileNumber = f.attributes.MobileNumber;
      attr.WorkNumber = f.attributes.WorkNumber;
      attr.FaxNumber = f.attributes.FaxNumber;
      attr.Email = f.attributes.Email;
      attr.DataLoadId = f.attributes.DataLoadId;
      attr.DataSource = f.attributes.DataSource;
      attr.DateDataLoaded = f.attributes.DateDataLoaded;

      // Common
      attr.OBJECTID = f.attributes.OBJECTID;
      attr.ClubId = f.attributes.ClubId;
      attr.Active = f.attributes.Active;
      attr.GlobalID = f.attributes.GlobalID;
      attr.HasLocation = f.attributes.HasLocation;
      attr.CreationDate = f.attributes.CreationDate;
      attr.Creator = f.attributes.Creator;
      attr.EditDate = f.attributes.EditDate;
      attr.Editor = f.attributes.Editor;

      // Populate virtual / calculated fields
      f.attributes.MembershipType = attr.MembershipType;
      f.attributes.MembershipCategory = attr.MembershipCategory;
      f.attributes.MembershipTenure = attr.MembershipTenure;
      f.attributes.MembershipTenureFinancialYearEnd = attr.MembershipTenureFinancialYearEnd;
      f.attributes.Age = attr.Age;
      f.attributes.AgeFinancialYearEnd = attr.AgeFinancialYearEnd;
      f.attributes.FullAddress_Display = attr.FullAddress_Display;

      this.attributes.push(attr);
    });
  }

  protected getLovs() {

    //this.lovReadyEvent.emit(true);  // No LOV's, so emit event

    //All LOV Domains
    combineLatest([
      this.typeLovReadyEvent,
      this.categoryLovReadyEvent
    ]).pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      console.log('Membership/FeatureBase getLovs() lovsReady()');
      this.lovReadyEvent.emit(true);
    });

    this.domains_lov = [];
    this.domains_lovSubDomain = [];

    //let membershipTypeLOV: MembershipTypeLOV = new MembershipTypeLOV(this.lovDataService, this.domainLovService);
    let membershipTypeLOV: MembershipTypeLOV = new MembershipTypeLOV();
    membershipTypeLOV.getFeatureLayerConfigAndData().then(() => {
      membershipTypeLOV.getDomain('LovMembershipType', 'coded-value').then((domain) => {
        this.domains_lov.push(domain);
        this.typeLovReadyEvent.emit();
      });
    });

    //let membershipCategoryLOV: MembershipCategoryLOV = new MembershipCategoryLOV(this.lovDataService, this.domainLovService);
    let membershipCategoryLOV: MembershipCategoryLOV = new MembershipCategoryLOV();
    membershipCategoryLOV.getFeatureLayerConfigAndData().then(() => {
      membershipCategoryLOV.getDomain('LovMembershipCategory', 'coded-value').then((domain) => {
        this.domains_lov.push(domain);
        this.categoryLovReadyEvent.emit();
      });
    });
  }
}

// Rounds

export class RoundAttributes extends FeatureLayerObjectBaseAttributes {
  public Year: number;
  public MonthLabel: string;
  public MonthNumber: number;
  public RoundDate: Date;
  public Sunday: number;
  public Monday: number;
  public Tuesday: number;
  public Wednesday: number;
  public Thursday: number;
  public Friday: number;
  public Saturday: number;
  // Depends!
  public Gender?: string;
  public PlayerOrigin?: string;

  constructor(
    protected lovDataService: LovDataService,
    protected domainLovService: DomainLovService
  ) {
    super(
      lovDataService,
      domainLovService
    );
  }

  // Virtual Fields
  public get Total(): number {
    return this.Sunday + this.Monday + this.Tuesday + this.Wednesday + this.Thursday + this.Friday + this.Saturday;
  }

  public get PeriodLabel(): string {
    return this.Year + '-' + this.MonthNumber.toString().padStart(2, '0');
  }
}

export abstract class Round extends FeatureLayerObjectBase {
  attributes: RoundAttributes[];
  server = LayerIds.gisServer_Members;
  folder = LayerIds.folder_Members;
  whereClause = "1=1";

  protected genderVisible: boolean = false;
  protected originVisible: boolean = false;

  protected async createFormFieldConfigs(): Promise<FormFieldConfig[]> {
    let formFieldConfig: FormFieldConfig;
    let fieldConfigs: FieldConfig[] = [];
    let fieldConfig: FieldConfig;
    let format: any;

    // Member Rounds

    fieldConfigs = [];

    fieldConfig = new FieldConfig('Year', 'Year:', 'number');
    format = new FieldNumberFormat(0);
    fieldConfig.format = format;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('MonthLabel', 'Month Label:', 'text');
    fieldConfig.maxLength = 20;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('MonthNumber', 'Month Number:', 'number');
    format = new FieldNumberFormat(0);
    fieldConfig.format = format;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('RoundDate', 'RoundDate', 'date');  //DateOfBirth
    format = new FieldDateFormat();
    fieldConfig.format = format;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('Gender', 'Gender:', 'text');
    fieldConfig.domain = 'DomGender';
    fieldConfig.visible = this.genderVisible;
    fieldConfig.maxLength = 20;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('PlayerOrigin', 'PlayerOrigin:', 'text');
    fieldConfig.domain = 'DomPlayerOrigin';
    fieldConfig.visible = this.originVisible;
    fieldConfig.maxLength = 20;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('Sunday', 'Sunday:', 'number');
    format = new FieldNumberFormat(0);
    fieldConfig.format = format;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('Monday', 'Monday:', 'number');
    format = new FieldNumberFormat(0);
    fieldConfig.format = format;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('Tuesday', 'Tuesday:', 'number');
    format = new FieldNumberFormat(0);
    fieldConfig.format = format;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('Wednesday', 'Wednesday:', 'number');
    format = new FieldNumberFormat(0);
    fieldConfig.format = format;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('Thursday', 'Thursday:', 'number');
    format = new FieldNumberFormat(0);
    fieldConfig.format = format;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('Friday', 'Friday:', 'number');
    format = new FieldNumberFormat(0);
    fieldConfig.format = format;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('Saturday', 'Saturday:', 'number');
    format = new FieldNumberFormat(0);
    fieldConfig.format = format;
    fieldConfigs.push(fieldConfig);

    formFieldConfig = new FormFieldConfig('Round', fieldConfigs);
    this.formFieldConfigs.push(formFieldConfig);

    fieldConfigs = [];

    fieldConfig = new FieldConfig('Total', 'Total:', 'number');
    format = new FieldNumberFormat(0);
    fieldConfig.format = format;
    fieldConfigs.push(fieldConfig);

    formFieldConfig = new FormFieldConfig('Virtual', fieldConfigs);
    this.formFieldConfigs.push(formFieldConfig);

    super.createFormFieldConfigs();

    return;
  }

  protected updateVirtualFields() {
    // Reset
    this.attributes = [];

    this.features.forEach((f) => {
      let attr: RoundAttributes = new RoundAttributes(this.lovDataService, this.domainLovService);

      attr.Year = f.attributes.Year;
      attr.MonthLabel = f.attributes.MonthLabel;
      attr.MonthNumber = f.attributes.MonthNumber;
      attr.RoundDate = f.attributes.RoundDate;
      attr.Gender = f.attributes.Gender;
      attr.PlayerOrigin = f.attributes.PlayerOrigin;
      attr.Sunday = f.attributes.Sunday;
      attr.Monday = f.attributes.Monday;
      attr.Tuesday = f.attributes.Tuesday;
      attr.Wednesday = f.attributes.Wednesday;
      attr.Thursday = f.attributes.Thursday;
      attr.Friday = f.attributes.Friday;
      attr.Saturday = f.attributes.Saturday;

      // Common
      attr.OBJECTID = f.attributes.OBJECTID;
      attr.ClubId = f.attributes.ClubId;
      attr.Active = f.attributes.Active;
      attr.GlobalID = f.attributes.GlobalID;
      attr.HasLocation = f.attributes.HasLocation;
      attr.CreationDate = f.attributes.CreationDate;
      attr.Creator = f.attributes.Creator;
      attr.EditDate = f.attributes.EditDate;
      attr.Editor = f.attributes.Editor;

      // Populate virtual / calculated fields
      f.attributes.Total = attr.Total;
      f.attributes.PeriodLabel = attr.PeriodLabel;


      this.attributes.push(attr);
    });
  }

  protected getLovs() {

    console.log('MemberRound / PlayerOrigin getLovs()');

    this.domains_lov = [];
    this.domains_lovSubDomain = [];

    this.lovReadyEvent.emit(true);
  }
}

@Injectable()
export class MemberRoundGender extends Round {
  layerId = LayerIds.layerId_Members_RoundGender;
  id = 'tableMemberRoundGender';
  title = 'Player Gender Rounds';

  genderVisible = true;
}

@Injectable()
export class MemberRoundOrigin extends Round {
  layerId = LayerIds.layerId_Members_RoundOrigin;
  id = 'tableMemberRoundOrigin';
  title = 'Player Origin Rounds';

  originVisible = true;
}

// Applicants

export class MemberApplictionAttributes extends ContactBaseAttributes {

  public ApplicationId: string;
  public MembershipTypeId: string;
  public MembershipCategoryId: string;
  public DateReceived: Date;
  public DateOfBirth: Date;
  public Gender: string;
  public DateInterviewed: Date;
  public Proposer: string;
  public Seconder_1: string;
  public Seconder_2: string;
  public Seconder_3: string;
  public StatusId: string;
  public Comments: string;
  public KnownToMember: string;

  constructor(
    protected lovDataService: LovDataService,
    protected domainLovService: DomainLovService
  ) {
    super(
      lovDataService,
      domainLovService
    );
  }

  // Virtual Attributes
  get Age(): number {
    return Global.calculateYearsToNow(this.DateOfBirth);
  }

  get AgeFinancialYearEnd(): number {
    return Global.calculateYears(Global.getCurrentFinancialYearEndDate(), this.DateOfBirth);
  }

  get FullAddress_Display(): string {
    return Global.formatFullAddress(this.AddressLine1, this.AddressLine2, this.Suburb, this.State, this.Postcode, this.Country);
  }

  public get FinancialYear(): string {
    //console.log('get FinancialYear() this.DateReceived', this.DateReceived)
    return Global.getFinancialYear(this.DateReceived);
  }

  public get FinancialPeriod(): number {
    return Global.getFinancialPeriod(this.DateReceived);
  }

  public get Year(): number {
    const _date = new Date(this.DateReceived);
    return _date.getFullYear();
  }

  public get MonthNumber(): number {
    // Months are 0 based
    const _date = new Date(this.DateReceived);
    return _date.getMonth() + 1;
  }

  public get MonthLabel(): string {
    return Global.getMonthShortName(this.MonthNumber);
  }

  public get PeriodLabel(): string {
    return this.Year + '-' + this.MonthNumber.toString().padStart(2, '0');
  }

  public get FinancialPeriodLabel(): string {
    return this.FinancialYear + '/' + this.FinancialPeriod.toString().padStart(2, '0');
  }

  public get MembershipType(): string {
    if (this.MembershipTypeId) {
      return this.domainLovService.getDomainLovValue('LovMembershipType', this.MembershipTypeId);
    }
    else {
      return null;
    }
  }

  public get MembershipCategory(): string {
    if (this.MembershipCategoryId) {
      return this.domainLovService.getDomainLovValue('LovMembershipCategory', this.MembershipCategoryId);
    }
    else {
      return null;
    }
  }

  public get ApplicationStatus(): string {
    if (this.StatusId) {
      return this.domainLovService.getDomainLovValue('LovApplicationStatus', this.StatusId);
    }
    else {
      return null;
    }
  }

  public get YearsWaiting(): number {
    return Global.calculateYearsToNow(this.DateReceived);
  }

  public get MonthsWaiting(): number {
    return Global.calculateMonthsToNow(this.DateReceived);
  }

  public get DaysWaiting(): number {
    return Global.calculateDaysToNow(this.DateReceived);
  }

}

@Injectable()
export class MemberAppliction extends FeatureLayerObjectBase {
  attributes: MemberApplictionAttributes[];
  server = LayerIds.gisServer_Members;
  folder = LayerIds.folder_Members;
  layerId = LayerIds.layerId_Members_Application;
  id = 'tableMembershipApplication';
  title = 'Membership Application';

  whereClause = "1=1";

  @Output() typeLovReadyEvent = new EventEmitter<any>();
  @Output() categoryLovReadyEvent = new EventEmitter<any>();
  @Output() statusLovReadyEvent = new EventEmitter<any>();

  protected async createFormFieldConfigs(): Promise<FormFieldConfig[]> {
    let formFieldConfig: FormFieldConfig;
    let fieldConfigs: FieldConfig[] = [];
    let fieldConfig: FieldConfig;
    let format: any;

    // Applicant

    fieldConfigs = [];

    fieldConfig = new FieldConfig('ApplicationId', 'Application Id:', 'text');
    fieldConfig.maxLength = 20;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('FullName', 'Member Name:', 'text');
    fieldConfig.maxLength = 150;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('MembershipTypeId', 'Membership Type:', 'text');
    fieldConfig.maxLength = 20;
    fieldConfig.domain_lov = 'LovMembershipType'
    fieldConfig.domain_VirtualFieldName = 'MembershipType';
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('MembershipCategoryId', 'Membership Category:', 'text');
    fieldConfig.maxLength = 20;
    fieldConfig.domain_lov = 'LovMembershipCategory'
    fieldConfig.domain_VirtualFieldName = 'MembershipCategory';
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('DateReceived', 'Date Received', 'date');
    format = new FieldDateFormat();
    fieldConfig.format = format;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('DateOfBirth', 'Date of Birth', 'date');
    format = new FieldDateFormat();
    fieldConfig.format = format;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('Age', 'Age:', 'number');
    format = new FieldNumberFormat(0);
    fieldConfig.editable = false;
    fieldConfig.format = format;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('AgeFinancialYearEnd', 'Age (end FY):', 'number');
    format = new FieldNumberFormat(0);
    fieldConfig.editable = false;
    fieldConfig.format = format;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('Gender', 'Gender:', 'text');
    fieldConfig.domain = 'DomGender';
    fieldConfig.maxLength = 10;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('DateInterviewed', 'Date of Interview', 'date');
    format = new FieldDateFormat();
    fieldConfig.format = format;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('Proposer', 'Proposer:', 'text');
    fieldConfig.maxLength = 150;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('Seconder_1', 'Seconder_1:', 'text');
    fieldConfig.maxLength = 150;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('Seconder_2', 'Seconder_2:', 'text');
    fieldConfig.maxLength = 150;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('Seconder_3', 'Seconder_3:', 'text');
    fieldConfig.maxLength = 150;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('Comments', 'Comments:', 'text');
    fieldConfig.maxLength = 1000;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('KnownToMember', 'KnownToMember:', 'text');
    fieldConfig.maxLength = 10;
    fieldConfig.domain = 'DomYesNo';
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('StatusId', 'Status:', 'text');
    fieldConfig.maxLength = 20;
    fieldConfig.domain_lov = 'LovApplicationStatus';
    fieldConfig.domain_VirtualFieldName = 'ApplicationStatus';
    fieldConfigs.push(fieldConfig);

    formFieldConfig = new FormFieldConfig('Applicant', fieldConfigs);
    this.formFieldConfigs.push(formFieldConfig);

    // Source

    fieldConfigs = [];

    fieldConfig = new FieldConfig('DataLoadId', 'DataLoadId:', 'text');
    fieldConfig.visible = false;
    fieldConfig.required = false;
    fieldConfig.editable = false;
    fieldConfig.maxLength = 20;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('DataSource', 'DataSource', 'text');
    fieldConfig.maxLength = 50;
    fieldConfig.editable = false;
    fieldConfig.visible = false;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('DateDataLoaded', 'LoadDate', 'date');
    fieldConfig.editable = false;
    fieldConfig.visible = false;
    fieldConfigs.push(fieldConfig);

    formFieldConfig = new FormFieldConfig('Source', fieldConfigs);
    this.formFieldConfigs.push(formFieldConfig);

    // Virtual (and hidden)

    fieldConfigs = [];

    fieldConfig = new FieldConfig('MembershipType', 'MembershipType:', 'text');
    fieldConfig.readOnly = true;
    fieldConfig.visible = false;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('MembershipCategory', 'MembershipCategory:', 'text');
    fieldConfig.readOnly = true;
    fieldConfig.visible = false;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('ApplicationStatus', 'Application Status:', 'text');
    fieldConfig.readOnly = true;
    fieldConfig.visible = false;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('FinancialYear', 'Financial Year:', 'number');
    fieldConfig.readOnly = true;
    fieldConfig.visible = false;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('FinancialPeriod', 'Financial Period:', 'number');
    fieldConfig.readOnly = true;
    fieldConfig.visible = false;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('PeriodLabel', 'Period Label:', 'text');
    fieldConfig.readOnly = true;
    fieldConfig.visible = false;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('Year', 'Year:', 'number');
    fieldConfig.readOnly = true;
    fieldConfig.visible = false;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('MonthNumber', 'Month Number:', 'number');
    fieldConfig.readOnly = true;
    fieldConfig.visible = false;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('MonthLabel', 'Month Label:', 'text');
    fieldConfig.readOnly = true;
    fieldConfig.visible = false;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('YearsWaiting', 'Years Waiting:', 'number');
    fieldConfig.readOnly = true;
    fieldConfig.visible = false;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('MonthsWaiting', 'Months Waiting:', 'number');
    fieldConfig.readOnly = true;
    fieldConfig.visible = false;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('DaysWaiting', 'Days Waiting:', 'number');
    fieldConfig.readOnly = true;
    fieldConfig.visible = false;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('FullAddress_Display', 'FullAddress_Display:', 'text');
    fieldConfig.readOnly = true;
    fieldConfig.visible = false;
    fieldConfigs.push(fieldConfig);

    formFieldConfig = new FormFieldConfig('Virtual', fieldConfigs);
    this.formFieldConfigs.push(formFieldConfig);

    super.createFormFieldConfigs();

    return this.formFieldConfigs;
  }

  protected updateVirtualFields() {
    // Reset
    this.attributes = [];

    this.features.forEach((f) => {
      let attr: MemberApplictionAttributes = new MemberApplictionAttributes(this.lovDataService, this.domainLovService);

      attr.ApplicationId = f.attributes.ApplicationId;
      attr.MembershipTypeId = f.attributes.MembershipTypeId;
      attr.MembershipCategoryId = f.attributes.MembershipCategoryId;
      attr.DateReceived = f.attributes.DateReceived;
      attr.DateOfBirth = f.attributes.DateOfBirth;
      attr.Gender = f.attributes.Gender;
      attr.DateInterviewed = f.attributes.DateInterviewed;
      attr.Proposer = f.attributes.Proposer;
      attr.Seconder_1 = f.attributes.Seconder_1;
      attr.Seconder_2 = f.attributes.Seconder_2;
      attr.Seconder_3 = f.attributes.Seconder_3;
      attr.StatusId = f.attributes.StatusId;
      attr.Comments = f.attributes.Comments;
      attr.KnownToMember = f.attributes.KnownToMember;

      // Contact
      attr.FullAddress = f.attributes.FullAddress;
      attr.AddressLine1 = f.attributes.AddressLine1;
      attr.AddressLine2 = f.attributes.AddressLine2;
      attr.Suburb = f.attributes.Suburb;
      attr.State = f.attributes.State;
      attr.City = f.attributes.City;
      attr.Postcode = f.attributes.Postcode;
      attr.Country = f.attributes.Country;
      attr.FullName = f.attributes.FullName;
      attr.FirstName = f.attributes.FirstName;
      attr.LastName = f.attributes.LastName;
      attr.HomeNumber = f.attributes.HomeNumber;
      attr.MobileNumber = f.attributes.MobileNumber;
      attr.WorkNumber = f.attributes.WorkNumber;
      attr.FaxNumber = f.attributes.FaxNumber;
      attr.Email = f.attributes.Email;

      // Common
      attr.OBJECTID = f.attributes.OBJECTID;
      attr.ClubId = f.attributes.ClubId;
      attr.Active = f.attributes.Active;
      attr.GlobalID = f.attributes.GlobalID;
      attr.HasLocation = f.attributes.HasLocation;
      attr.CreationDate = f.attributes.CreationDate;
      attr.Creator = f.attributes.Creator;
      attr.EditDate = f.attributes.EditDate;
      attr.Editor = f.attributes.Editor;

      // Populate virtual / calculated fields
      f.attributes.Age = attr.Age;
      f.attributes.AgeFinancialYearEnd = attr.AgeFinancialYearEnd;
      f.attributes.FullAddress_Display = attr.FullAddress_Display;
      f.attributes.MembershipType = attr.MembershipType;
      f.attributes.MembershipCategory = attr.MembershipCategory;
      f.attributes.ApplicationStatus = attr.ApplicationStatus;
      f.attributes.FinancialYear = attr.FinancialYear;
      f.attributes.FinancialPeriod = attr.FinancialPeriod;
      f.attributes.Year = attr.Year;
      f.attributes.MonthNumber = attr.MonthNumber;
      f.attributes.MonthLabel = attr.MonthLabel;
      f.attributes.PeriodLabel = attr.PeriodLabel;
      f.attributes.FinancialPeriodLabel = attr.FinancialPeriodLabel;
      f.attributes.FinancialPeriod = attr.FinancialPeriod;

      f.attributes.YearsWaiting = attr.YearsWaiting;
      f.attributes.MonthsWaiting = attr.MonthsWaiting;
      f.attributes.DaysWaiting = attr.DaysWaiting;

      this.attributes.push(attr);
    });
  }

  protected getLovs() {
    //All LOV Domains
    combineLatest([
      this.typeLovReadyEvent,
      this.categoryLovReadyEvent,
      this.statusLovReadyEvent
    ]).pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      console.log('Membership/FeatureBase getLovs() lovsReady()');
      this.lovReadyEvent.emit(true);
    });

    this.domains_lov = [];
    this.domains_lovSubDomain = [];

    //let membershipTypeLOV: MembershipTypeLOV = new MembershipTypeLOV(this.lovDataService, this.domainLovService);
    let membershipTypeLOV: MembershipTypeLOV = new MembershipTypeLOV();
    membershipTypeLOV.getFeatureLayerConfigAndData().then(() => {
      membershipTypeLOV.getDomain('LovMembershipType', 'coded-value').then((domain) => {
        this.domains_lov.push(domain);
        this.typeLovReadyEvent.emit();
      });
    });

    //let membershipCategoryLOV: MembershipCategoryLOV = new MembershipCategoryLOV(this.lovDataService, this.domainLovService);
    let membershipCategoryLOV: MembershipCategoryLOV = new MembershipCategoryLOV();
    membershipCategoryLOV.getFeatureLayerConfigAndData().then(() => {
      membershipCategoryLOV.getDomain('LovMembershipCategory', 'coded-value').then((domain) => {
        this.domains_lov.push(domain);
        this.categoryLovReadyEvent.emit();
      });
    });

    let applicationStatusLOV: ApplicationStatusLOV = new ApplicationStatusLOV();
    applicationStatusLOV.getFeatureLayerConfigAndData().then(() => {
      applicationStatusLOV.getDomain('LovApplicationStatus', 'coded-value').then((domain) => {
        this.domains_lov.push(domain);
        this.statusLovReadyEvent.emit();
      });
    });
  }
}

// Reciprocal

export class MemberReciprocalClubAttributes extends ContactBaseAttributes {
  public ReciprocalClubId: string;
  public ClubName: string;
  public OfficePhoneNumber: string;
  public ProfessionalPhoneNumber: string;
  public Website: string;
  public LogoFileName: string;

  constructor(
    protected lovDataService: LovDataService,
    protected domainLovService: DomainLovService
  ) {
    super(
      lovDataService,
      domainLovService
    );
  }

  // Virtual Attributes
  get FullAddress_Display(): string {
    return Global.formatFullAddress(this.AddressLine1, this.AddressLine2, this.Suburb, this.State, this.Postcode, this.Country);
  }
}

@Injectable()
export class MemberReciprocalClub extends FeatureLayerObjectBase {
  attributes: MemberReciprocalClubAttributes[];
  server = LayerIds.gisServer_Members;
  folder = LayerIds.folder_Members;
  layerId = LayerIds.layerId_Members_ReciprocalClub;
  id = 'featureLayerMembershipReciprocalClub';
  title = 'Membership Reciprocal Club';

  minScale = 0;
  maxScale = 0;
  whereClause = "1=1";

  // Labels
  labelInfo = {
    //deconflictionStrategy: "none",
    labelExpressionInfo: {
      expression: "$feature.ClubName + ', ' + $feature.City"
    },
    symbol: Esri.text12pxWithHalo_DarkGrey,
    maxScale: 0,
    minScale: 0
    //where: "$feature.HasLocation = 'YES'"
  };

  labelsVisible = true;
  labelingInfo = this.labelInfo;
  popupEnabled = false;
  legendEnabled = false;

  greenColour = [6, 128, 32, 0.75];
  amberColour = [ 255, 190, 0, 0.50 ];
  deepPurpleColour = [103, 58, 183, 0.75];
  magentaColour = [175, 80, 184, 0.75];

  renderer = {
    type: 'simple',
    field: 'OBJECTID',
    symbol: {
      type: 'simple-marker',
      size: 6,
      color: this.magentaColour,
      outline: {
        width: 1,
        color: 'white'
      }
    }
  };

  // @Output() typeLovReadyEvent = new EventEmitter<any>();
  // @Output() categoryLovReadyEvent = new EventEmitter<any>();
  // @Output() statusLovReadyEvent = new EventEmitter<any>();

  protected async createFormFieldConfigs(): Promise<FormFieldConfig[]> {
    let formFieldConfig: FormFieldConfig;
    let fieldConfigs: FieldConfig[] = [];
    let fieldConfig: FieldConfig;
    let format: any;

    // Applicant

    fieldConfigs = [];

    fieldConfig = new FieldConfig('ReciprocalClubId', 'Reciprocal Club Id:', 'text');
    fieldConfig.maxLength = 20;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('ClubName', 'Club Name:', 'text');
    fieldConfig.maxLength = 150;
    fieldConfig.includeInTable = true;
    fieldConfig.includeInTableIndex = 0;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('OfficePhoneNumber', 'Office Number', 'tel');
    fieldConfig.maxLength = 15;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('ProfessionalPhoneNumber', 'Pro Phone Number', 'tel');
    fieldConfig.maxLength = 15;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('Email', 'Email:', 'email');
    fieldConfig.includeInTable = true;
    fieldConfig.includeInTableIndex = 3;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('Website', 'Website:', 'url');
    fieldConfig.maxLength = 150;
    fieldConfig.includeInTable = true;
    fieldConfig.includeInTableIndex = 4;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('LogoFileName', 'LogoFileName:', 'text');
    fieldConfig.maxLength = 50;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('City', 'City:', 'text');
    fieldConfig.readOnly = true;
    fieldConfig.visible = true;
    fieldConfig.includeInTable = true;
    fieldConfig.includeInTableIndex = 1;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('Country', 'Country:', 'text');
    fieldConfig.readOnly = true;
    fieldConfig.visible = true;
    fieldConfig.includeInTable = true;
    fieldConfig.includeInTableIndex = 2;
    fieldConfigs.push(fieldConfig);

    formFieldConfig = new FormFieldConfig('ReciprocalClub', fieldConfigs);
    this.formFieldConfigs.push(formFieldConfig);

    // Virtual (and hidden)

    fieldConfigs = [];



    formFieldConfig = new FormFieldConfig('Virtual', fieldConfigs);
    this.formFieldConfigs.push(formFieldConfig);

    super.createFormFieldConfigs();

    return this.formFieldConfigs;
  }

  protected updateVirtualFields() {
    // Reset
    this.attributes = [];

    this.features.forEach((f) => {
      let attr: MemberReciprocalClubAttributes = new MemberReciprocalClubAttributes(this.lovDataService, this.domainLovService);

      attr.ReciprocalClubId = f.attributes.ReciprocalClubId;
      attr.ClubName = f.attributes.ClubName;
      attr.OfficePhoneNumber = f.attributes.OfficePhoneNumber;
      attr.ProfessionalPhoneNumber = f.attributes.ProfessionalPhoneNumber;
      attr.Website = f.attributes.Website;
      attr.LogoFileName = f.attributes.LogoFileName;

      // Contact
      attr.FullAddress = f.attributes.FullAddress;
      attr.AddressLine1 = f.attributes.AddressLine1;
      attr.AddressLine2 = f.attributes.AddressLine2;
      attr.POBox = f.attributes.POBox;
      attr.Suburb = f.attributes.Suburb;
      attr.State = f.attributes.State;
      attr.City = f.attributes.City;
      attr.Postcode = f.attributes.Postcode;
      attr.County = f.attributes.County;
      attr.Country = f.attributes.Country;
      attr.FullName = f.attributes.FullName;
      attr.FirstName = f.attributes.FirstName;
      attr.LastName = f.attributes.LastName;
      //attr.HomeNumber = f.attributes.HomeNumber;
      //attr.MobileNumber = f.attributes.MobileNumber;
      //attr.WorkNumber = f.attributes.WorkNumber;
      //attr.FaxNumber = f.attributes.FaxNumber;
      attr.Email = f.attributes.Email;

      // Common
      attr.OBJECTID = f.attributes.OBJECTID;
      attr.ClubId = f.attributes.ClubId;
      attr.Active = f.attributes.Active;
      attr.GlobalID = f.attributes.GlobalID;
      attr.HasLocation = f.attributes.HasLocation;
      attr.CreationDate = f.attributes.CreationDate;
      attr.Creator = f.attributes.Creator;
      attr.EditDate = f.attributes.EditDate;
      attr.Editor = f.attributes.Editor;

      // Populate virtual / calculated fields
      f.attributes.FullAddress_Display = attr.FullAddress_Display;

      this.attributes.push(attr);
    });
  }

  protected getLovs() {
    this.domains_lov = [];
    this.domains_lovSubDomain = [];
    //console.log('MemberReciprocalClub this.lovReadyEvent.emit');
    this.lovReadyEvent.emit(true);
  }

  public async getClubById(id): Promise<any> {
    //console.log('getClubById id', id, this.features);
    let index = -1;
    let club = {};

    this.features.find(function (item, i) {
      if (item.attributes.ReciprocalClubId === id) {
        //console.log('getClubById found!!', i);
        index = i;
        return i;
      }
    });

    const selectedFeature = this.features[index];
    //console.log('getClubById selectedFeature', selectedFeature);

    if (selectedFeature) {
      club = {
        clubId: selectedFeature.attributes.ReciprocalClubId,
        clubName: selectedFeature.attributes.ClubName,
        latitude: selectedFeature.geometry.latitude,
        longitude: selectedFeature.geometry.longitude
      }
    }
    else {
      console.log('getClubById selectedFeature not found', id);
    }

    return club;
  }
}

// Reciprocal Rounds

export class MemberReciprocalGamePlayAttributes extends FeatureLayerObjectBaseAttributes {
  public HomeClubId: string;
  public HomeClubName: string;
  public ReciprocalClubId: string;
  public ReciprocalClubName: string;
  public MembershipNumber: string;
  public MemberName: string;
  public GameDate: Date;


  constructor(
    protected lovDataService: LovDataService,
    protected domainLovService: DomainLovService
  ) {
    super(
      lovDataService,
      domainLovService
    );
  }

  // Virtual Attributes

  // Reciprocal Club from MemberReciprocalClub layer : ReciprocalClubId, ClubName
  // Home club from CourseBoundary layer : ClubId, ClubName
}

@Injectable()
export class MemberReciprocalGamePlay extends FeatureLayerObjectBase {
  attributes: MemberReciprocalGamePlayAttributes[];
  server = LayerIds.gisServer_Members;
  folder = LayerIds.folder_Members;
  layerId = LayerIds.layerId_Members_ReciprocalGamePlay;
  id = 'featureLayerMembershipReciprocalGamePlay';
  title = 'Membership Reciprocal Game Play';
  minScale = 0;
  maxScale = 0;
  greenColour = [ 6, 128, 32, 0.75 ];
  amberColour = [ 255, 190, 0, 0.75 ];
  deepPurpleColour = [103, 58, 183, 0.75 ];
  redColour = [ 255, 0, 0, 0.75 ];

  // public clusterMinSize: number = 40;
  // public clusterMaxSize: number = 100;

  // Clustering
  clusterConfig = {
    type: "cluster",
    clusterRadius: "80px",
    // {cluster_count} is an aggregate field containing
    // the number of features comprised by the cluster
    popupEnabled: false,
    popupTemplate: {
      content: "This cluster represents {cluster_count} members.",
      fieldInfos: [{
        fieldName: "cluster_count",
        format: {
          places: 0,
          digitSeparator: true
        }
      }]
    },
    // clusterMinSize: "24px",
    // clusterMaxSize: "60px",
    clusterMinSize: "40px",
    clusterMaxSize: "100px",
    labelsVisible: true,
    labelingInfo: [{
      deconflictionStrategy: "none",
      labelExpressionInfo: {
        expression: "Text($feature.cluster_count, '#,###')"
      },
      symbol: {
        type: "text",
        color: this.deepPurpleColour,
        font: {
          weight: "bold",
          family: "Noto Sans",
          size: "16px"
        }
      },
      labelPlacement: "above-left",
    }]
  }

  clusterConfigVisitor = {
    type: "cluster",
    clusterRadius: "80px",
    // {cluster_count} is an aggregate field containing
    // the number of features comprised by the cluster
    popupEnabled: false,
    popupTemplate: {
      content: "This cluster represents {cluster_count} members.",
      fieldInfos: [{
        fieldName: "cluster_count",
        format: {
          places: 0,
          digitSeparator: true
        }
      }]
    },
    clusterMinSize: "40px",
    clusterMaxSize: "100px",
    labelsVisible: true,
    labelingInfo: [{
      deconflictionStrategy: "none",
      labelExpressionInfo: {
        expression: "Text($feature.cluster_count, '#,###')"
      },
      symbol: {
        type: "text",
        color: this.redColour,
        font: {
          weight: "bold",
          family: "Noto Sans",
          size: "16px"
        }
      },
      labelPlacement: 'above-right',
    }]
  }


  // above-center, above-left, above-right, below-center, below-left, below-right, center-center, center-left, center-right


  // Renderer
  featureReduction = this.clusterConfig;

  renderer = {
    type: 'simple',
    field: 'OBJECTID',
    symbol: {
      type: 'simple-marker',
      size: 10,
      color: this.deepPurpleColour,
      outline: {
        width: 5,
        color: "rgba( 103, 58, 183, 0.5)"
      }
    }
  };

  rendererVisitor = {
    type: 'simple',
    field: 'OBJECTID',
    symbol: {
      type: 'simple-marker',
      size: 10,
      color: this.redColour,
      outline: {
        width: 5,
        color: "rgba( 255, 0, 0, 0.5)"
      }
    }
  };

  // @Output() typeLovReadyEvent = new EventEmitter<any>();
  // @Output() categoryLovReadyEvent = new EventEmitter<any>();
  // @Output() statusLovReadyEvent = new EventEmitter<any>();

  protected async createFormFieldConfigs(): Promise<FormFieldConfig[]> {
    let formFieldConfig: FormFieldConfig;
    let fieldConfigs: FieldConfig[] = [];
    let fieldConfig: FieldConfig;
    let format: any;


    /*

// use layer swipe widget to show home vs away info on map

*/


    // Game Play

    fieldConfigs = [];

    fieldConfig = new FieldConfig('HomeClubId', 'Home Club Id:', 'text');
    fieldConfig.maxLength = 20;
    fieldConfig.defaultValue = Global.clubId;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('HomeClubName', 'Home Club Name:', 'text');
    fieldConfig.maxLength = 150;
    //fieldConfig.defaultValue
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('ReciprocalClubId', 'Reciprocal Club Id:', 'text');
    fieldConfig.maxLength = 20;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('ReciprocalClubName', 'Reciprocal Club Name:', 'text');
    fieldConfig.maxLength = 150;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('MembershipNumber', 'Membership Number', 'text');
    fieldConfig.maxLength = 20;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('MemberName', 'MemberName', 'text');
    fieldConfig.maxLength = 150;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('GameDate', 'GameDate:', 'date');
    format = new FieldDateFormat();
    fieldConfig.format = format;
    fieldConfigs.push(fieldConfig);

    formFieldConfig = new FormFieldConfig('ReciprocalGamePlay', fieldConfigs);
    this.formFieldConfigs.push(formFieldConfig);

    // Virtual (and hidden)

    fieldConfigs = [];

    formFieldConfig = new FormFieldConfig('Virtual', fieldConfigs);
    this.formFieldConfigs.push(formFieldConfig);

    super.createFormFieldConfigs();
    return this.formFieldConfigs;
  }

  protected updateVirtualFields() {
    // Reset
    this.attributes = [];

    this.features.forEach((f) => {
      let attr: MemberReciprocalGamePlayAttributes = new MemberReciprocalGamePlayAttributes(this.lovDataService, this.domainLovService);

      attr.HomeClubId = f.attributes.HomeClubId;
      attr.HomeClubName = f.attributes.HomeClubName;
      attr.ReciprocalClubId = f.attributes.ReciprocalClubId;
      attr.ReciprocalClubName = f.attributes.ReciprocalClubName;
      attr.MembershipNumber = f.attributes.MembershipNumber;
      attr.MemberName = f.attributes.MemberName;
      attr.GameDate = f.attributes.GameDate;

      // Common
      attr.OBJECTID = f.attributes.OBJECTID;
      attr.ClubId = f.attributes.ClubId;
      attr.Active = f.attributes.Active;
      attr.GlobalID = f.attributes.GlobalID;
      attr.HasLocation = f.attributes.HasLocation;
      attr.CreationDate = f.attributes.CreationDate;
      attr.Creator = f.attributes.Creator;
      attr.EditDate = f.attributes.EditDate;
      attr.Editor = f.attributes.Editor;

      // Populate virtual / calculated fields
      //f.attributes.FullAddress_Display = attr.FullAddress_Display;

      this.attributes.push(attr);
    });
  }

  protected getLovs() {
    this.domains_lov = [];
    this.domains_lovSubDomain = [];
    this.lovReadyEvent.emit(true);


    // Get unique Club names and Id's
    //public ReciprocalClubId: string; 
    //public ClubName: string;
  }
}

// LOV's

export class MembershipTypeLOV extends FeatureLayerObjectBase {
  //attributes: MembershipTypeAttributes[];
  server = LayerIds.gisServer_Members;
  folder = LayerIds.folder_Members;
  layerId = LayerIds.layerId_Members_Type_LOV;
  id = 'tableMembershipType_LOV';
  title = 'Membership Type LOV';

  whereClause = "ClubId='" + Global.clubId + "' AND Active <> 'NO'";
  outFields = ['MembershipTypeId', 'Description'];

  protected async createFormFieldConfigs(): Promise<FormFieldConfig[]> {
    //let formFieldConfigs: FormFieldConfig[] = [];
    let formFieldConfig: FormFieldConfig;
    let fieldConfigs: FieldConfig[] = [];
    let fieldConfig: FieldConfig;
    //let format: any;

    fieldConfig = new FieldConfig('MembershipTypeId', 'Membership Type Id', 'text');
    fieldConfig.required = true;
    fieldConfig.maxLength = 20;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('Description', 'Description', 'text');
    fieldConfig.required = true;
    fieldConfig.maxLength = 200;
    fieldConfigs.push(fieldConfig);

    formFieldConfig = new FormFieldConfig('Description', fieldConfigs);
    this.formFieldConfigs.push(formFieldConfig);

    super.createFormFieldConfigs();
    return this.formFieldConfigs;
  }
}

export class MembershipCategoryLOV extends FeatureLayerObjectBase {
  //attributes: MembershipCategoryAttributes[];
  server = LayerIds.gisServer_Members;
  folder = LayerIds.folder_Members;
  layerId = LayerIds.layerId_Members_Category_LOV;
  id = 'tableMembershipCategory_LOV';
  title = 'Membership Category LOV';

  whereClause = "ClubId='" + Global.clubId + "' AND Active <> 'NO'";
  outFields = ['MembershipCategoryId', 'Description'];

  protected async createFormFieldConfigs(): Promise<FormFieldConfig[]> {
    //let formFieldConfigs: FormFieldConfig[] = [];
    let formFieldConfig: FormFieldConfig;
    let fieldConfigs: FieldConfig[] = [];
    let fieldConfig: FieldConfig;
    //let format: any;

    fieldConfig = new FieldConfig('MembershipCategoryId', 'Membership Category Id', 'text');
    fieldConfig.required = true;
    fieldConfig.maxLength = 20;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('Description', 'Description', 'text');
    fieldConfig.required = true;
    fieldConfig.maxLength = 200;
    fieldConfigs.push(fieldConfig);

    formFieldConfig = new FormFieldConfig('Description', fieldConfigs);
    this.formFieldConfigs.push(formFieldConfig);

    super.createFormFieldConfigs();
    return this.formFieldConfigs;
  }
}

export class ApplicationStatusLOV extends FeatureLayerObjectBase {
  server = LayerIds.gisServer_Members;
  folder = LayerIds.folder_Members;
  layerId = LayerIds.layerId_Members_ApplicationStatus_LOV;
  id = 'tableApplicationStatus_LOV';
  title = 'Application Status LOV';

  whereClause = "ClubId='" + Global.clubId + "' AND Active <> 'NO'";
  outFields = ['StatusId', 'Description'];

  protected async createFormFieldConfigs(): Promise<FormFieldConfig[]> {
    let formFieldConfig: FormFieldConfig;
    let fieldConfigs: FieldConfig[] = [];
    let fieldConfig: FieldConfig;
    //let format: any;

    fieldConfig = new FieldConfig('StatusId', 'Status Id', 'text');
    fieldConfig.required = true;
    fieldConfig.maxLength = 20;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('Description', 'Description', 'text');
    fieldConfig.required = true;
    fieldConfig.maxLength = 200;
    fieldConfigs.push(fieldConfig);

    formFieldConfig = new FormFieldConfig('Description', fieldConfigs);
    this.formFieldConfigs.push(formFieldConfig);

    super.createFormFieldConfigs();
    return this.formFieldConfigs;
  }
}

// export class DataSourceLOV extends FeatureBase {
//   //attributes: MembershipTypeAttributes[];
//   server = LayerIds.gisServer_MembersDataLoad;
//   folder = LayerIds.folder_MembersDataLoad;
//   layerId = LayerIds.layerId_DataSource_LOV;
//   id = 'tableDataSource_LOV';
//   title = 'Data Source LOV';

//   whereClause = "ClubId='" + Global.clubId + "' AND Active <> 'NO'";
//   outFields = ['DataSourceId', 'Description'];

//   protected async createFormFieldConfigs(): Promise<FormFieldConfig[]> {
//     //let formFieldConfigs: FormFieldConfig[] = [];
//     let formFieldConfig: FormFieldConfig;
//     let fieldConfigs: FieldConfig[] = [];
//     let fieldConfig: FieldConfig;
//     //let format: any;

//     fieldConfig = new FieldConfig('DataSourceId', 'Data Source Id', 'text');
//     fieldConfig.required = true;
//     fieldConfig.maxLength = 20;
//     fieldConfigs.push(fieldConfig);

//     fieldConfig = new FieldConfig('Description', 'Description', 'text');
//     fieldConfig.required = true;
//     fieldConfig.maxLength = 200;
//     fieldConfigs.push(fieldConfig);

//     formFieldConfig = new FormFieldConfig('Description', fieldConfigs);
//     this.formFieldConfigs.push(formFieldConfig);

//     super.createFormFieldConfigs();
//     return this.formFieldConfigs;
//   }
// }

// public static layerId_Members_SourceMapping = '4';
