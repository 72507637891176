import { Component, OnInit } from '@angular/core';
import { Risk, RiskMatrix } from 'src/app/risk/models/risk';

@Component({
  selector: 'app-risk-matrix',
  templateUrl: './risk-matrix.component.html',
  styleUrls: ['./risk-matrix.component.scss']
})
export class RiskMatrixComponent implements OnInit {

  public matrixHeader = RiskMatrix.MatrixHeader_5x5;
  public matrixData = RiskMatrix.MatrixData_5x5_C;

  constructor() { }

  ngOnInit(): void {
  }
}
