import { Component, Inject, OnDestroy, OnInit, ViewChild, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Esri, FormFieldConfig, FieldConfig, Feature, Field, Domain, SubDomain, GeometryPoint, AttachmentInfo, GeocodeResponse } from 'src/app/_esri/models/esri';
import { EsriEditFormComponent } from 'src/app/_esri/components/esri-edit-form/esri-edit-form.component';
import { EsriEditFormMasterDetailComponent } from 'src/app/_esri/components/esri-edit-form-master-detail/esri-edit-form-master-detail.component';
import { EsriEditFormButtonsComponent } from 'src/app/_esri/components/esri-edit-form-buttons/esri-edit-form-buttons.component';

import { CurrentModeService, CurrentModeData } from 'src/app/_globals/services/current-mode.service';
import { ButtonClickService } from 'src/app/_globals/services/button-click.service';
import { BaseEditForm, EditFormData } from 'src/app/_esri/models/base-edit-form-class';

// export interface DialogData {
// export interface EditFormData {
//   title: string,
//   formFieldConfigs: FormFieldConfig[],
//   featureLayer: any,
//   feature: any,
//   geometry?: any,
//   domains?: Domain[],
//   domains_lov?: Domain[],
//   domains_lovSubDomain?: SubDomain[],
//   useFormStepper?: boolean,
//   currentMode?: string,
//   fieldChangeWatchList?: string[],
//   parentLovWatchList?: any[],
//   defaultFieldValues?: any[],
//   defaultFieldVisibility?: any[],
//   markDirtyWhenOpened?: boolean,
//   idType?: string,
//   idTypeFieldName?: string,
// }

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})

export class EditComponent extends BaseEditForm implements OnInit, OnDestroy {

  // System Environment
  //protected ngUnsubscribe = new Subject<void>();
  //protected buttonClickService = new ButtonClickService();

  //public featureForm: any;
  public broadcastCurrentMode: boolean = true;

  // Buttons
  // @Output() addBtnSelectedEvent = new EventEmitter<any>();
  // @Output() editBtnSelectedEvent = new EventEmitter<any>();
  // @Output() saveBtnSelectedEvent = new EventEmitter<any>();
  // @Output() attachmentBtnSelectedEvent = new EventEmitter<any>();
  // @Output() deleteBtnSelectedEvent = new EventEmitter<any>();
  // @Output() cancelBtnSelectedEvent = new EventEmitter<any>();

  // @Output() misc1BtnSelectedEvent = new EventEmitter<any>();
  // @Output() misc2BtnSelectedEvent = new EventEmitter<any>();
  // @Output() misc3BtnSelectedEvent = new EventEmitter<any>();

  @Output() closeBtnSelectedEvent = new EventEmitter<any>();

  // Form Changes
  //@Output() featureFormChangeEvent = new EventEmitter<any>();
  //@Output() featureFormFieldChangeEvent = new EventEmitter<any>();

  //@Output() addressSelectedEvent = new EventEmitter<GeocodeResponse>();
  //@Output() featureFormLoadedEvent = new EventEmitter<any>();

  // Elements
  //@ViewChild(EsriEditFormComponent, { static: true }) private _esriEditFormComponent: EsriEditFormComponent;
  //@ViewChild(EsriEditFormMasterDetailComponent, { static: true }) private _esriEditFormComponent: EsriEditFormMasterDetailComponent;
  //@ViewChild(EsriEditFormButtonsComponent, { static: true }) private _esriEditFormButtonsComponent: EsriEditFormButtonsComponent;

  constructor(
    protected ref: ChangeDetectorRef,
    protected currentModeService: CurrentModeService,
    protected buttonClickService: ButtonClickService,
    public dialogRef: MatDialogRef<EditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: EditFormData,
  ) { 
    super(
      ref,
      currentModeService,
      buttonClickService
    )
  }


  public closeDialog() {

    //console.log('closeDialog()');

    // Dodgey fix here - really clumsy way to do this. 
    // Need a major rethink of how this is done!!!!

    // if (this.broadcastCurrentMode === true) {
    //   this.dialogRef.componentInstance.closeBtnSelectedEvent.emit('close');
    //   this.currentModeService.currentModeData.next('close');
    // }
    // else {
    //   console.log('closeDialog() broadcastCurrentMode false');
    //   this.dialogRef.componentInstance.closeBtnSelectedEvent.emit('close');
    // }


    let currentModeData: CurrentModeData = {
      formName: this.esriEditFormButtonsComponent.formName,
      mode: 'close'
    }
    this.currentModeService.currentModeData.next(currentModeData);

    //this.currentModeService.currentModeData.next('close');

  }


}



