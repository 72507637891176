import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginCallbackComponent } from 'src/app/_nav/components/login-callback/login-callback.component';
import { LoginComponent } from 'src/app/_nav/components/login/login.component';
//import { AuthGuard } from 'src/app/_nav/helpers/auth-guard';
//import { Role } from 'src/app/_nav/models/role';

//canActivate: [AuthGuard],

const routes: Routes = [ 
  { path: 'asset-register', loadChildren: () => import('./asset-register/asset-register.module').then(m => m.AssetRegisterModule) }, 
  { path: 'audit', loadChildren: () => import('./audit/audit.module').then(m => m.AuditModule) }, 
  { path: 'game', loadChildren: () => import('./game/game.module').then(m => m.GameModule) },  
  { path: 'home', loadChildren: () => import('./home/home.module').then(m => m.HomeModule) }, 
  { path: 'maintenance', loadChildren: () => import('./maintenance/maintenance.module').then(m => m.MaintenanceModule) }, 
  { path: 'map-gallery', loadChildren: () => import('./map-gallery/map-gallery.module').then(m => m.MapGalleryModule) },
  { path: 'member', loadChildren: () => import('./member/member.module').then(m => m.MemberModule) },
  { path: 'risk', loadChildren: () => import('./risk/risk.module').then(m => m.RiskModule) },
  { path: 'incident', loadChildren: () => import('./incident/incident.module').then(m => m.IncidentModule) },
  { path: 'login', component: LoginComponent },
  //{ path: 'login-callback', component: LoginCallbackComponent },
  { path: '', loadChildren: () => import('./home/home.module').then(m => m.HomeModule) },
  { path: 'project', loadChildren: () => import('./project/project.module').then(m => m.ProjectModule) },

  //{ path: '**', component: PageNotFoundComponent }  // Wildcard route for a 404 page
];

@NgModule({
  // SPA type apps need to use the hash in the URL, otherwise the hosting server wont be able to find pages directly from a url
  // Unfortunately hash is incompatable with OAuth!!!!! - unable to parse the access_token because the # is treated as a route - set "initialNavigation: false"
  // I think I have now decided NOT to use hash navigation - causing too much grief for little benefit --> will just have to always navigate via the menu
  // The token generated from the ArcGIS REST API doesn't work with portal items
  // Advantage is that ESRI now handles all the security around login/passwords

  imports: [RouterModule.forRoot(routes)],
  //imports: [RouterModule.forRoot(routes, { useHash: true })],   
  //imports: [RouterModule.forRoot(routes, { useHash: false, initialNavigation: true })], 
  
  exports: [RouterModule]
})

export class AppRoutingModule { }
