import { Injectable, OnInit, OnDestroy } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AlignmentType,  Document, PageOrientation, HeadingLevel, Packer, Paragraph,  TextRun, SymbolRun, UnderlineType } from "docx";
import { Media, TextWrappingType, TextWrappingSide, TabStopPosition, TabStopType, OutlineLevel } from "docx";
import { HorizontalPositionRelativeFrom, HorizontalPositionAlign, VerticalPositionRelativeFrom, VerticalPositionAlign, Header, Footer, PageNumber, PageNumberFormat } from "docx";
import { Table, TableRow, TableCell, WidthType, BorderStyle, ShadingType, PageBorderDisplay, PageBorderOffsetFrom, PageBorderZOrder, TableOfContents, StyleLevel } from "docx";
import { Domain, SubDomain, ImageSizeInfo, ReportImages, ProgressMessage_Report } from 'src/app/_esri/models/esri';
import { FeaturesService } from 'src/app/_esri/services/features.service';
import { ProgressService } from 'src/app/_globals/services/progress.service';
import { StaticMapService } from 'src/app/_esri/services/static-map.service';
import { saveAs } from 'file-saver/dist/FileSaver';
import { parse } from 'node-html-parser';

// CHECK THIS ONE OUT:
//
// https://www.npmjs.com/package/html-to-docx
//
// https://www.npmjs.com/package/node-html-parser

//
// https://stackblitz.com/edit/angular-open-window?file=src%2Fapp%2Fwindow.component.ts

@Injectable({
  providedIn: 'root'
})

export abstract class MsWordService implements OnInit, OnDestroy {

  public reportTitleLine1: string = 'Report';
  public reportTitleLine2: string = '';
  public reportDescription: string = '';
  
  public document;
  public featureLayer = null;
  public features: any[] = [];
  public domains: Domain[] = [];
  public domains_lov: Domain[] = [];
  public domains_lovParent: SubDomain[] = [];
  public includeStaticMapPerItem: boolean = false;

  public titlePage;
  public tableOfContents;
  public header;
  public footer;

  private _reportCreator: string = 'CrispOra Solutions';
  private _headerText = this.reportTitleLine1 + ( this.reportTitleLine2 ? ' - ' + this.reportTitleLine2 : '');
  private _topBanner;
  private _bottomBanner;
  private _pageBorderColour: string = 'EAEAEA';
  private _pageBorderoffset: number = 400;
  private _pageBorderSize: number = 2;
  private _borderColour: string = '717171';
  private _borderSize: number = 2;
  private _fontColour: string = '717171';
  private _lightFontColour: string = 'FFFFFF';
  private _fillColour: string = '717171';
  private _deepPurpleColour: string = '673ab7';
  private _topBannerImageUrl = './assets/images/reports/TopTriangleLogo45d.png';
  private _bottomBannerImageUrl = './assets/images/reports/BottomBanner.png';
  private _headingBannerImageUrl = './assets/images/reports/HeadingBanner.png';
  private _bottomRightTriangleImageUrl = './assets/images/reports/BottomRightTriangle.png';
  private _topRightTriangleImageUrl = './assets/images/reports/TopRightTriangle.png';
  private _headingBannerImage;
  private _bottomRightTriangleImage;
  private _topRightTriangleImage;
  private _headingBanner;
  private _topRightTriangle;
  private _bottomRightTriangle;

  //private _font: string = 'Calibri';
  private _font: string = 'Segoe UI';
  private _maxImageWidth: number = 300;
  private _mmToPx: number = 3.7795275591;
  private _mmToTwip: number = 56.692556267362;

  public shortLine: string = "____________________________________";
  public fullLine: string = "____________________________________________________________________________________________________________";

  public progressData: any;

  // private _totalPages:any = PageNumber.TOTAL_PAGES; //- 1
  // private _totalPagesX: number = <number>this._totalPages - 1;

  private _numbering = {
    config: [
      {
        reference: "BulletList",
        levels: [
          {
            level: 0,
            format: "lowerLetter",
            text: "%1",
            alignment: AlignmentType.START,
            style: {
              run: {
                size: 24,
                bold: false,
                color: this._fontColour,
                font: this._font  
              },
              paragraph: {
                indent: { left: 720, hanging: 260 },
              },
            },
          },
        ],
      }]
  };

  private _styles = {
    paragraphStyles: [
    {
      id: "Title1",
      name: "Title Style - Size 1",
      run: {
          size: 144,
          bold: true,
          italics: false,
          // underline: {
          //   type: UnderlineType.SINGLE,
          //   color: "990011",
          // },
          color: this._deepPurpleColour,
          font: this._font,
      },
    },
    {
      id: "Title2",
      name: "Title Style Size 2",
      run: {
          size: 72,
          bold: true,
          italics: false,
          color: this._deepPurpleColour,
          font: this._font,
      },
    },
    {
      id: "ToC",
      name: "ToC Style",
      next: "Heading1",
      run: {
          size: 14,
          bold: false,
          italics: true,
          color: this._fontColour,
          font: this._font,
      },
    },
    {
      id: "Heading1W",
      name: "Heading 1 (white font)",
      basedOn: "Normal",
      next: "Normal",
      quickFormat: true,
      run: {
        size: 28,
        bold: true,
        color: this._lightFontColour,
        font: this._font,
      },
      paragraph: {    // need to set outline level to 1 - doesn't appear in TOC's at the moment
        alignment: AlignmentType.LEFT,
        spacing: {
          before: 120,
          after: 120
        },
      },
    },
    {
      id: "Heading1",
      name: "Heading 1",
      basedOn: "Normal",
      next: "Normal",
      //quickFormat: true,
      run: {
        size: 54,
        bold: true,
        color: this._lightFontColour,
        font: this._font,
      },
      paragraph: {
        alignment: AlignmentType.LEFT,
        spacing: {
          before: 800,
          after: 1600
        },
      },
    },
    {
      id: "Heading1Plain",
      name: "Heading 1 plain",
      basedOn: "Normal",
      next: "Normal",
      //quickFormat: true,
      run: {
        size: 54,
        bold: true,
        color: this._fontColour,
        font: this._font,
        underline: {
          type: UnderlineType.SINGLE,
          color: this._borderColour,
        },
      },
      paragraph: {
        alignment: AlignmentType.CENTER,
        spacing: {
          before: 240,
          after: 120
        },
      },
    },
    {
      id: "Heading2",
      name: "Heading 2",
      basedOn: "Normal",
      next: "Normal",
      quickFormat: true,
      run: {
        size: 28,
        bold: true,
        color: this._fontColour,
        font: this._font,
        // underline: {
        //   type: UnderlineType.SINGLE,
        //   color: "717171",
        // },
      },
      paragraph: {
        spacing: {
          before: 240,
          after: 120
        },
      },
    },
    {
      id: "Heading3",
      name: "Heading 3",
      basedOn: "Normal",
      next: "Normal",
      quickFormat: true,
      run: {
        size: 24,
        bold: true,
        color: this._fontColour,
        font: this._font,
        // underline: {
        //   type: UnderlineType.SINGLE,
        //   color: "717171",
        // },
      },
      paragraph: {
        spacing: {
          before: 240,
          after: 120
        },
      },
    },
    {
      id: "Body",
      name: "Body",
      basedOn: "Normal",
      next: "Normal",
      quickFormat: true,
      run: {
        size: 22,
        bold: false,
        color: this._fontColour,
        font: this._font,   
      },
      paragraph: {
        alignment: AlignmentType.JUSTIFIED,
        spacing: {
          before: 240,
          after: 120
        },
      },
    },
    {
      id: "Header",
      name: "Header",
      basedOn: "Normal",
      next: "Normal",
      quickFormat: true,
      run: {
        size: 20,
        bold: false,
        color: this._fontColour,
        font: this._font,   
      },
      // paragraph: {
      //   alignment: AlignmentType.JUSTIFIED,
      //   spacing: {
      //     before: 240,
      //     after: 120
      //   },
      // },
    },
    {
      id: "List",
      name: "List",
      //next: "Normal",
      run: {
        size: 24,
        bold: false,
        color: this._fontColour,
        font: this._font,   
      },
      paragraph: {
        alignment: AlignmentType.LEFT,
        indent: { left: 720, hanging: 260 },
        spacing: {
          before: 120,
          after: 120
        },
      },
    }]
  }

  private _marginsTitlePage = {
    top: 0,   
    right: 0, 
    bottom: 0,
    left: 0,
  }

  public margins = {
    top: 400,   
    right: 600, 
    bottom: 400,
    left: 1000,
  }

  private _titlePageBorderProperties = {
    pageBorderLeft: {
      style: BorderStyle.SINGLE,
      size: this._pageBorderSize,
      color: this._pageBorderColour,
      space: this._pageBorderoffset,
    },
    pageBorderRight: {
      style: BorderStyle.SINGLE,
      size: this._pageBorderSize,
      color: this._pageBorderColour,
      space: this._pageBorderoffset,
    },
    pageBorderTop: {
      style: BorderStyle.SINGLE,
      size: this._pageBorderSize,
      color: this._pageBorderColour,
      space: this._pageBorderoffset,
    },
    pageBorderBottom: {
      style: BorderStyle.SINGLE,
      size: this._pageBorderSize,
      color: this._pageBorderColour,
      space: this._pageBorderoffset,
    },
    pageBorders: {
      display: PageBorderDisplay.FIRST_PAGE,
      offsetFrom: PageBorderOffsetFrom.PAGE,
      zOrder: PageBorderZOrder.FRONT,
    }
  }

  private _pageBorderProperties = {
    pageBorderLeft: {
      style: BorderStyle.SINGLE,
      size: this._pageBorderSize,
      color: this._pageBorderColour,
      space: this._pageBorderoffset,
    },
    pageBorderRight: {
      style: BorderStyle.SINGLE,
      size: this._pageBorderSize,
      color: this._pageBorderColour,
      space: this._pageBorderoffset,
    },
    pageBorderTop: {
      style: BorderStyle.SINGLE,
      size: this._pageBorderSize,
      color: this._pageBorderColour,
      space: this._pageBorderoffset,
    },
    pageBorderBottom: {
      style: BorderStyle.SINGLE,
      size: this._pageBorderSize,
      color: this._pageBorderColour,
      space: this._pageBorderoffset,
    },
    pageBorders: {
      display: PageBorderDisplay.ALL_PAGES,
      offsetFrom: PageBorderOffsetFrom.TEXT,
      zOrder: PageBorderZOrder.FRONT,
    }
  }

  private _tableBorderProperties_NoBorder = {
    top: {
        style: BorderStyle.NONE,
        size: 0,
        color: "FFFFFF",
    },
    bottom: {
        style: BorderStyle.NONE,
        size: 0,
        color: "FFFFFF",
    },
    left: {
        style: BorderStyle.NONE,
        size: 0,
        color: "FFFFFF",
    },
    right: {
        style: BorderStyle.NONE,
        size: 0,
        color: "FFFFFF",
    },
    insideVertical: {
      style: BorderStyle.NONE,
      size: 0,
      color: "FFFFFF",
    },
      insideHorizontal: {
        style: BorderStyle.NONE,
        size: 0,
        color: "FFFFFF",
    },
  }

  private _tableBorderProperties = {
    top: {
      style: BorderStyle.SINGLE,
      size: this._borderSize,
      color: this._borderColour,
    },
    bottom: {
      style: BorderStyle.SINGLE,
      size: this._borderSize,
      color: this._borderColour,
    },
    left: {
      style: BorderStyle.SINGLE,
      size: this._borderSize,
      color: this._borderColour,
    },
    right: {
      style: BorderStyle.SINGLE,
      size: this._borderSize,
      color: this._borderColour,
    },
    insideVertical: {
      style: BorderStyle.SINGLE,
      size: this._borderSize,
      color: this._borderColour,
    },
    insideHorizontal: {
      style: BorderStyle.SINGLE,
      size: this._borderSize,
      color: this._borderColour,
    },
  }

  private _twoColumnPerPageProperties = {
    column: {
        space: 400,
        count: 2,
    },
  }

  private _footerFirst: Footer = new Footer({
    children: [new Paragraph({
      alignment: AlignmentType.RIGHT,
      // spacing: {
      //   before: 500,
      //   after: 300,
      // },
      border: {
        top: {
          color: this._borderColour,
          space: 100,
          value: "single",
          size: 6,
        },
      },
      children: [
        new TextRun({
          children: ["Page ", PageNumber.CURRENT],
        }),
        // new TextRun({
        //     children: ["Page ", PageNumber.CURRENT, " of ", this._totalPagesX.toString()],
        // }),
        // new TextRun({
        //     children: [" to ", PageNumber.TOTAL_PAGES],
        // }),
      ],
    })],
  });

  private _footerEven: Footer = new Footer({
    children: [new Paragraph({
      alignment: AlignmentType.LEFT,
      // spacing: {
      //   before: 500,
      //   after: 300,
      // },
      border: {
        top: {
          color: this._borderColour,
          space: 100,
          value: "single",
          size: 6,
        },
      },
      children: [
        new TextRun({
          children: ["Page ", PageNumber.CURRENT],
        }),
        // new TextRun({
        //     children: ["Page ", PageNumber.CURRENT, " of ", this._totalPagesX.toString()],
        // }),
        // new TextRun({
        //     children: [" to ", PageNumber.TOTAL_PAGES],
        // }),
      ],
    })],
  });

  constructor(
    private http: HttpClient,
    public staticMapService: StaticMapService,
    public featuresService: FeaturesService,
    public progressService: ProgressService
  ) { 
  }

  ngOnInit() {
  }

  ngOnDestroy() {
  }
  
  public async initialiseReport() {

    // Details for static map
    this.staticMapService.width = 600;
    this.staticMapService.height = 500;
    this.staticMapService.dpi= 96;    //256
    this.staticMapService.scale = 1999;
    this.staticMapService.outputFormat = "JPG";

    //
    // Create the document
    //

    // Note: document must be created prior, ie exist, to adding any images
    this.document = new Document({
      creator: this._reportCreator,
      title: this._headerText,
      description: this.reportDescription,
      styles: this._styles,
      // background: {
      //   color: "C45911",
      // },
      //numbering: numbering
    });

    // Get images for the header and footer
    this._bottomRightTriangleImage = await this.getImage(this._bottomRightTriangleImageUrl);
    this._topRightTriangleImage = await this.getImage(this._topRightTriangleImageUrl);

    this._topRightTriangle = new Paragraph({
      children: [
        Media.addImage(this.document, this._topRightTriangleImage, 20 * this._mmToPx, 20 * this._mmToPx, {
          floating: {
              horizontalPosition: {
                relative: HorizontalPositionRelativeFrom.PAGE,
                align: HorizontalPositionAlign.RIGHT,
              },
              verticalPosition: {
                relative: VerticalPositionRelativeFrom.PAGE,
                align: VerticalPositionAlign.TOP,
              },
              behindDocument: true,
              wrap: {
                type: TextWrappingType.NONE,
              },
          },
        })
      ],
    });
  
    this._bottomRightTriangle = new Paragraph({
      children: [
        Media.addImage(this.document, this._bottomRightTriangleImage, 20 * this._mmToPx, 20 * this._mmToPx, {
          floating: {
              horizontalPosition: {
                relative: HorizontalPositionRelativeFrom.PAGE,
                align: HorizontalPositionAlign.RIGHT,
              },
              verticalPosition: {
                relative: VerticalPositionRelativeFrom.PAGE,
                align: VerticalPositionAlign.BOTTOM,
              },
              behindDocument: true,
              wrap: {
                type: TextWrappingType.NONE,
              },
          },
        })
      ],
    });
  
    this.header = new Header({
      children: [
        this._topRightTriangle,
        new Paragraph({
          alignment: AlignmentType.LEFT,
          style: 'Header',
          border: {
          bottom: {
            color: this._borderColour,
            space: 100,
            value: "single",
            size: 4,
          }
          },
          children: [
          new TextRun(this._headerText),
          ],
        })
      ]
    });

    this.footer = new Footer({
      children: [
        this._bottomRightTriangle,
        new Paragraph({
        alignment: AlignmentType.CENTER,
        // spacing: {
        //   before: 500,
        //   after: 300,
        // },
        border: {
          top: {
            color: this._borderColour,
            space: 100,
            value: "single",
            size: 4,
          },
        },
        children: [
          new TextRun({
            children: ["- ", PageNumber.CURRENT, " -"]
          }),
          // new TextRun({
          //     children: ["Page ", PageNumber.CURRENT, " of ", this._totalPagesX.toString()],
          // }),
          // new TextRun({
          //     children: [" to ", PageNumber.TOTAL_PAGES],
          // }),
        ],
      })],
    });

    // Get image for the Heading Banner
    this._headingBannerImage = await this.getImage(this._headingBannerImageUrl);

    this._headingBanner = new Paragraph({
      children: [
        Media.addImage(this.document, this._headingBannerImage, 210 * this._mmToPx, 40 * this._mmToPx, {
          floating: {
              horizontalPosition: {
                relative: HorizontalPositionRelativeFrom.PAGE,
                align: HorizontalPositionAlign.LEFT,
              },
              verticalPosition: {
                relative: VerticalPositionRelativeFrom.PAGE,
                offset: 1000000
              },
              behindDocument: true,
              wrap: {
                type: TextWrappingType.NONE,
                //side: TextWrappingSide.BOTH_SIDES,
              },
          },
        })
      ],
    });

    const topBannerImage = await this.getImage(this._topBannerImageUrl);
    const bottomBannerImage = await this.getImage(this._bottomBannerImageUrl);

    this._topBanner = new Paragraph({
      children: [
        Media.addImage(this.document, topBannerImage, 70 * this._mmToPx, 70 * this._mmToPx, {
          floating: {
              horizontalPosition: {
                relative: HorizontalPositionRelativeFrom.PAGE,
                align: HorizontalPositionAlign.RIGHT,
              },
              verticalPosition: {
                relative: VerticalPositionRelativeFrom.PAGE,
                align: VerticalPositionAlign.TOP,
              },
              // wrap: {
              //     type: TextWrappingType.SQUARE,
              //     side: TextWrappingSide.BOTH_SIDES,
              // },
          },
        })
      ],
    });

    this._bottomBanner = new Paragraph({
      children: [
        Media.addImage(this.document, bottomBannerImage, 200 * this._mmToPx, 70 * this._mmToPx, {
          floating: {
              horizontalPosition: {
                relative: HorizontalPositionRelativeFrom.PAGE,
                align: HorizontalPositionAlign.LEFT,
              },
              verticalPosition: {
                relative: VerticalPositionRelativeFrom.PAGE,
                align: VerticalPositionAlign.BOTTOM,
              },
              // wrap: {
              //     type: TextWrappingType.SQUARE,
              //     side: TextWrappingSide.BOTH_SIDES,
              // },
          },
        })
      ],
    });

    this.titlePage = {

      titlePage: true,
      // properties: {
      //   ...this._titlePageBorderProperties,
      // },
      size: {
        orientation: PageOrientation.PORTRAIT,
      },
      margins: this._marginsTitlePage,
      children: [
        this._topBanner,
        this._bottomBanner,
        new Paragraph({
        alignment: AlignmentType.LEFT,
        style: "Title1",
        children: [
          new TextRun("").break(),
          new TextRun("").break(),
          new TextRun("").break(),
          new Paragraph({
            indent: {
              left: 1000,
            },
            style: "Title2",
            text: this.reportTitleLine1,
            outlineLevel: 0,
          }),
          new Paragraph({
            indent: {
              left: 1000,
            },
            style: "Title1",
            text: this.reportTitleLine2,
            outlineLevel: 1,
          }),
        ],
      })],

    }

    this.tableOfContents = {

      titlePage: false,
      headers: {
        default: this.header,
      },
      footers: {
          default: this.footer,
          //first: this._footerFirst,
          //even: this._footerEven,
      },
      properties: {
        pageNumberStart: 1,
        pageNumberFormatType: PageNumberFormat.DECIMAL,
        //...this._pageBorderProperties
      },
      size: {
        orientation: PageOrientation.PORTRAIT,
      },
      margins: this.margins,
      children: [
        this._headingBanner,
        this.createHeading_1("Table of Contents"),
        this.createToC()
      ]

    }

    return;
  }

  //
  // Create Document Parts
  //

  private createToC() {
    return new TableOfContents("Contents", {
      hyperlink: true,
      headingStyleRange: "1-2",
      stylesWithLevels: [new StyleLevel("ToC", 1)],
    });
  }

  public async createHeadingSection_1(text: string, mapImageBlob?: any): Promise<any[]> {

    let children: any[] = [];

    children.push(this._headingBanner);
    //children.push(new TextRun("").break());
    children.push(this.createHeading_1(text));

    if (mapImageBlob) {
      children.push(this.createHeading_3("Location"));
      children.push(this.createImage(mapImageBlob, this.staticMapService.width, this.staticMapService.height, false, 700));
    }

    // Add the detail
    this.document.addSection({
      titlePage: false,
      headers: {
        default: this.header,
      },
      footers: {
        default: this.footer,
        //first: this._footerFirst,
        //even: this._footerEven,
      },
      properties: {
        pageNumberFormatType: PageNumberFormat.DECIMAL,
        //...this._pageBorderProperties,
      },
      size: {
        orientation: PageOrientation.PORTRAIT,
      },
      margins: this.margins,
      children: [
        ...children
      ]
    });

    return;
  }

  public createHeading_1T(text: string): Table {
    const table = new Table({
      rows: [
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  text: text,
                  heading: HeadingLevel.HEADING_1,
                  //thematicBreak: true,
                  style: 'Heading1W'
                })
              ],
              shading: {
                fill: this._fillColour,
                val: ShadingType.CLEAR,
                color: "auto",
              },
              margins: {
                left: 100,
                right: 100,
                top: 0,
                bottom: 0
              }
            }),
          ],
        }),
      ],
      width: {
        size: 100,
        type: WidthType.PERCENTAGE,
      },
      borders: {
        ...this._tableBorderProperties_NoBorder
      },

    });

    return table;
  }

  public createHeading_1(text: string): Paragraph {
    return new Paragraph({
      text: text,
      heading: HeadingLevel.HEADING_1,
      style: 'Heading1'
    });
  }

  public createHeading_1_Normal(text: string): Paragraph {
    return new Paragraph({
      text: text,
      heading: HeadingLevel.HEADING_1,
      //thematicBreak: true,
      style: 'Heading1Normal'
    });
  }

  public createHeading_2(text: string): Paragraph {
    return new Paragraph({
      text: text,
      heading: HeadingLevel.HEADING_2,
      style: 'Heading2'
    });
  }

  public createHeading_3(text: string): Paragraph {
    return new Paragraph({
      text: text,
      heading: HeadingLevel.HEADING_3,
      style: 'Heading3'
    });
  }

  public createParagraph(text: string, pageBreakBefore?: boolean): Paragraph {

    let _pageBreakBefore: boolean = false;

    if (pageBreakBefore) {
      _pageBreakBefore = pageBreakBefore;
    }

    return new Paragraph({
      text: text,
      pageBreakBefore: _pageBreakBefore,
      style: 'Body'
    });
  }

  public createParagraphsFromHtml(htmlString: string): Paragraph[] {

    let paragraphs: Paragraph[] = [];
    const html = parse(htmlString);
 
    html.childNodes.forEach( (node:any) => {

      let paragraph: Paragraph;
      let re = /&nbsp;/gi; 
      let paragraphContent = '';

      //console.log('node', node);
      //console.log('node.childNodes.length', node.childNodes.length);
      //console.log('node.tagName', node.tagName);

      switch (node.tagName) {
        case "UL": 
        case "OL": 
          //let listItems: Paragraph[] = [];
          node.childNodes.forEach( (element:any) => {
            // Strip out '&nbsp;' and replace with a space
            paragraphContent = element.innerText.replace(re, ' ');
            // Create the paragraph
            paragraph = this.createBullet(paragraphContent);
            paragraphs.push(paragraph);
          });

          //console.log('write list', listItems)
          break;

        // case "LI":
        //   break;

        case "P":
        default:
          //console.log('write paragraph', node.innerText)
          // Strip out '&nbsp;' and replace with a space
          paragraphContent = node.innerText.replace(re, ' ');
          // Create the paragraph
          paragraph = this.createParagraph(paragraphContent);
          paragraphs.push(paragraph)
          break;

        // case "B":
        //   break;

        // case "I":
        //   break;
      }
    });

    return paragraphs;
  }

  public createThematicBreak(): Paragraph {
    return new Paragraph({
      thematicBreak: true,
      style: 'Body'
    });
  }

  public createParagraphFromMany(textStrings: string[]): Paragraph {

    let children = [];

    textStrings.forEach( (text) => {
      let textRun = new TextRun(text + " ");
      children.push(textRun);
    });

    return new Paragraph({
      children: children,
      style: 'Body'
    });
  }

  public createCheckbox(label?: string): Paragraph {
    return this.createSymbol('00A8', label);
  }

  public createCheckboxCross(label?: string): Paragraph {
    return this.createSymbol('00FD', label);
  }

  public createCheckboxTick(label?: string): Paragraph {
    return this.createSymbol('00FE', label);
  }

  public createCross(label?: string): Paragraph {
    return this.createSymbol('00FB', label);
  }

  public createTick(label?: string): Paragraph {
    return this.createSymbol('00FC', label);
  }

  public createBullet(label: string): Paragraph {
    return this.createSymbol('009F', label);
    // return new Paragraph({
    //   text: text,  
    //   style: "List",
    //   // numbering: {
    //   //     level: 0,
    //   //     reference: 'BulletList'
    //   // },
    // });
  }

  public createSymbol(char: string, label?: string): Paragraph {

    let symbol = new SymbolRun({
      char: char,
      symbolfont: 'Wingdings'
    });

    //children: [new TextRun("Hey everyone").bold(), new TextRun(\t"11th November 1999")],

    if (label) {
      let text = new TextRun("  " + label);  //\t"11th November 1999"

      return new Paragraph({
        children: [symbol, text],
        style: 'List'
        // tabStops: [{
        //   type: TabStopType.LEFT,
        //   position: 3268,
        // }]
      });
    }
    else {
      return new Paragraph({
        children: [symbol],
        style: 'List'
      });
    }
  }

  public createImage(image, width: number, height: number, floating?: boolean, maxWidth?: number): Paragraph {
    //console.log('createImage');

    let _width: number; // = 600;   
    let _height: number; // = 400;
    let _maxWidth: number = this._maxImageWidth;
    let _floating: boolean = false;

    if (maxWidth) {
      _maxWidth = maxWidth;
    }
  
    if (floating) {
      _floating = floating;
    }

    // Scale the image if required
    if (width > _maxWidth) {
      // Make Max width
      const aspectRatio = _maxWidth / width;
      _width = _maxWidth;
      _height = height * aspectRatio;
    }
    else {
      _width = width;   
      _height = height;
    }

    //console.log('createImage() Width is:', _width, ' Height is: ', _height);
    //console.log('createImage() image is:', image);

    if (_floating === true) {
      return new Paragraph({
        children: [
          Media.addImage(this.document, image, _width, _height, {
            floating: {
                horizontalPosition: {
                  relative: HorizontalPositionRelativeFrom.PAGE,
                  align: HorizontalPositionAlign.RIGHT,
                },
                verticalPosition: {
                  relative: VerticalPositionRelativeFrom.PARAGRAPH,
                  align: VerticalPositionAlign.TOP,
                },
                wrap: {
                    type: TextWrappingType.SQUARE,
                    side: TextWrappingSide.BOTH_SIDES,
                },
            },
          })
        ],
        alignment: AlignmentType.CENTER,
        spacing: {
          before: 100,
          after: 100,
        }
      });
    }
    else {
      return new Paragraph({
        children: [
          Media.addImage(this.document, image, _width, _height)
        ],
        alignment: AlignmentType.CENTER,
        spacing: {
          before: 100,
          after: 100,
        }
      });
    }
  }

  public createTextTable_1Row(headings: any[], textItems: any[], showBorder?: boolean): Table {

    if (headings.length != textItems.length) {
      console.error("createTextTable_1Row() - Headings length does not match textItems length.  They must be equal")
      //return;
    }

    let table: Table = null;
    let tableRows: TableRow[] = [];
    let tableCols: TableCell[] = [];

    let borderStyle = this._tableBorderProperties_NoBorder;

    if (showBorder) {
      borderStyle = this._tableBorderProperties;
    }

    // Create Table Cells with an item / heading inside each one  
    tableCols = [];

    // Headings
    headings.forEach( (heading) => {
      let tableCell = new TableCell({
        children: [heading],
      });

      tableCols.push(tableCell);
    });

    let tableRow = new TableRow({
      children: [
        ...tableCols
      ],
    });

    tableRows.push(tableRow);

    // Reset
    tableCols = [];

    // Text Data
    textItems.forEach( (textItem) => {
      let tableCell = new TableCell({
        children: [textItem],
      });
      tableCols.push(tableCell);
    });

    tableRow = new TableRow({
      children: [
        ...tableCols
      ],
    });

    tableRows.push(tableRow);

    // Create the table
    if (tableRows.length > 0) {
      table = new Table({
        rows: [
          ...tableRows
        ],
        width: {
          size: 100,
          type: WidthType.PERCENTAGE,
        },
        borders: {
          ...borderStyle
        },
      });
    }
  
    return table;
  }

  public createTextTable_1Row_2Col(headings: any[], textItemCol1: any[], textItemCol2: any[], showBorder?: boolean): Table {

    let table: Table = null;
    let tableRows: TableRow[] = [];
    let tableCols: TableCell[] = [];

    let borderStyle = this._tableBorderProperties_NoBorder;

    if (showBorder) {
      borderStyle = this._tableBorderProperties;
    }

    // Create Table Cells with an item / heading inside each one  
    tableCols = [];

    // Headings
    headings.forEach( (heading) => {
      let tableCell = new TableCell({
        children: [heading],
      });

      tableCols.push(tableCell);
    });

    // Heading Row

    let tableRow = new TableRow({
      children: [
        ...tableCols
      ],
    });

    tableRows.push(tableRow);

    // Reset
    tableCols = [];

    // Text Data

    // Col 1

    let tableCell = new TableCell({
      children: [...textItemCol1],
    });
    
    tableCols.push(tableCell);

    // Col 2

    tableCell = new TableCell({
      children: [...textItemCol2],
    });
    
    tableCols.push(tableCell);

    // Data Row

    tableRow = new TableRow({
      children: [
        ...tableCols
      ],
    });

    tableRows.push(tableRow);

    // Create the table
    if (tableRows.length > 0) {
      table = new Table({
        rows: [
          ...tableRows
        ],
        width: {
          size: 100,
          type: WidthType.PERCENTAGE,
        },
        borders: {
          ...borderStyle
        },
      });
    }
  
    return table;
  }

  public createImageTable(imageSizeInfos: ImageSizeInfo[], cellsPerRow?: number): Table {
    let table: Table = null;
    let numberItems = imageSizeInfos.length;

    let _cellsPerRow = 2;

    if (cellsPerRow) {
      _cellsPerRow = cellsPerRow;
    }

    const numberRows: number = Math.ceil(numberItems / _cellsPerRow);
    let tableRows: TableRow[] = [];
    let tableCells: TableCell[] = [];
    let tableCols: TableCell[] = [];

    // Create Table Cells with an image inside each one
    imageSizeInfos.sort((a, b)  => a.orientation.localeCompare(b.orientation)).forEach( (image) => {
      let tableCell = new TableCell({
        children: [this.createImage(image.blob, image.width, image.height)],
      });
      tableCells.push(tableCell);
    });
    
    let index = 0;

    for (let row = 0; row < numberRows; row++) {
      //console.log('current row', row);
      //console.log('numberRows', numberRows);

      tableCols = [];

      for (let col = 0; col < _cellsPerRow; col++) {
        //console.log('current index', index);
        //console.log('numberImages', numberImages);

        if (index < numberItems) {
          tableCols.push(tableCells[index]);
        }
      
        index++;
      }

      let tableRow = new TableRow({
        children: [
          ...tableCols
        ],
      });

      tableRows.push(tableRow);
    }

    if (tableRows.length > 0) {
      table = new Table({
        rows: [
          ...tableRows
        ],
        width: {
          size: 100,
          type: WidthType.PERCENTAGE,
        },
        borders: {
          ...this._tableBorderProperties_NoBorder
        },
      });
    }

    //...this._tableBorderProperties_NoBorder



    return table;
  }

  //
  // Images / Attachments
  //

  public async processReportImages(features: any[], createStaticMap?: boolean): Promise<any> {

    let reportImages: ReportImages[] = [];
    let _createStaticMap: boolean = false;

    if (createStaticMap) {

      this.staticMapService.width = 300;
      this.staticMapService.height = 200;
      this.staticMapService.dpi= 96;    //256
      this.staticMapService.scale = 1999;
      this.staticMapService.outputFormat = "JPG";

      _createStaticMap = createStaticMap;
    }

    let mapImageUrl: string = '';
    let attachmentInfos: any[] = [];;
    let mapImageBlob: any = null;
    let featureImageBlobs: any[] = [];
    let featureImageSizes: any[] = [];
    let imageSizeInfos: ImageSizeInfo[] = [];

    // this.staticMapService.width = 300;
    // this.staticMapService.height = 200;

    for (let j = 0; j < features.length; j++) {

      let reportImage: ReportImages = null;

      // Reset values and arrays
      mapImageUrl = '';
      mapImageBlob = null;
      attachmentInfos = [];
      featureImageBlobs = [];
      featureImageSizes = [];
      imageSizeInfos = [];

      console.log('  *** ObjectId:', features[j].attributes.OBJECTID);

      //
      // Attachments
      //

      // Get attachmentInfos
      let attachmentQuery = {
        objectIds: features[j].attributes.OBJECTID,
        attachmentTypes: ['image/jpeg']
      };    
      
      let attachments = await features[j].layer.queryAttachments(attachmentQuery);
      attachmentInfos = attachments[features[j].attributes.OBJECTID];
      //console.log('attachmentInfos', attachmentInfos);

      // Get / process attachments if they exist
      if (attachmentInfos) {

        await this.getFeatureAttachments(attachmentInfos).then(
          data => {
            featureImageBlobs = data;          
          },
          error => {
            console.error('There was an error! [msWordService.getFeatureAttachments()]', error);
          }
        );
        //console.log('featureImageBlobs', featureImageBlobs);
  
        await this.getImageSizes(attachmentInfos).then(
          data => {
            featureImageSizes = data;
          },
          error => {
            console.error('There was an error! [msWordService.getImageSizes()]', error);
          }
        );
        //console.log('featureImageSizes', featureImageSizes);
  
        // Join the Feature Attachment results together
        for (let i:number = 0; i < attachmentInfos.length; i++) {
          let imageSizeInfo: ImageSizeInfo = {
            url: featureImageSizes[i].url,
            blob: featureImageBlobs[i],
            width: featureImageSizes[i].width,
            height: featureImageSizes[i].height,
            orientation: featureImageSizes[i].orientation,
            aspectRatio: featureImageSizes[i].aspectRatio
          };
          imageSizeInfos.push(imageSizeInfo);
        }
        //console.log('imageSizeInfos', imageSizeInfos);
      }

      //
      // Get mapImageUrl      
      //

      // **** TO DO **** Change this to use the functions

      if (_createStaticMap === true) {

        let url = features[j].layer.url + '/' + features[j].layer.layerId;    // Feature Service URL
        const extent = this.staticMapService.calculateExtentFromPoint(features[j].geometry.x, features[j].geometry.y);

        const mapImageUrl = await this.getStaticMapUrl(extent, url, features[j].layer.title, [features[j].attributes.OBJECTID]);
        const mapImageBlob = await this.getStaticMap(mapImageUrl);

        // await this.staticMapService.generate(extent, url, features[j].layer.title, [features[j].attributes.OBJECTID]).then( 
        //   data => {
        //     mapImageUrl = data.results[0].value.url;
        //   }, 
        //   error => {
        //     console.error('There was an error! [msWordService.staticMapService.generate()]', error);
        //   }
        // );
        // //console.log('mapImageUrl', mapImageUrl);
  
        // await this.getImage(mapImageUrl).then(
        //   data => {
        //     mapImageBlob = data;
        //   },
        //   error => {
        //     console.error('There was an error! [msWordService.getImage()]', error);
        //   }
        // );
        // //console.log('mapImageBlob', mapImageBlob);
      }

      reportImage = {
        feature: features[j],
        mapImageUrl: mapImageUrl,
        mapImageBlob: mapImageBlob,
        attachmentInfos: attachmentInfos,
        featureImageBlobs: featureImageBlobs,
        featureImageSizes: featureImageSizes,
        imageSizeInfos: imageSizeInfos
      }

      reportImages.push(reportImage);
    }

    return reportImages;
  }

  //
  // HTTP / image functions
  //

  private async getImageSizes(attachmentInfos: any): Promise<any[]> {

    //let imageSizes: any[] = [];
    let imageUrls: any[] = [];

    attachmentInfos.forEach( attachment => {
      //console.log('Getting attachment size:', attachment.url);
      imageUrls.push(attachment.url);
    });

    //console.log('imageUrls', imageUrls);
    //console.log('imageUrls', JSON.stringify(imageUrls));

    //let apiUrl = "https://map-test.crispora.com.au/api/image-size/post.php";
    let apiUrl = "https://api.on-par-technology.com/image-size/post.php";

    //  Call the API
    const headers = new HttpHeaders({    
      'Content-Type': 'application/x-www-form-urlencoded'
    });

    const body = new HttpParams()
      .set('urls', JSON.stringify(imageUrls));

    try {
      // return this.http.post<any>(apiUrl, body.toString(), { headers }).pipe(
      //   map( (res) => {
      //     console.log('getImageSizes() res', res);
      //     return res;
      //   })
      // );

      return await this.http.post<any>(apiUrl, body.toString(), { headers }).toPromise();
    }
    catch (error) {
      console.error('error: ', error);
    }

    //return imageSizes;
  }

  private async getFeatureAttachments(attachmentInfos: any): Promise<any[]> {
    let featureImageBlobs: any[] = [];

    for (let i = 0; i < attachmentInfos.length; i++) {
      await this.getImage(attachmentInfos[i].url).then(
        data => {
          //console.log('getImage');
          featureImageBlobs.push(data);
        },
        error => {
          console.error('There was an error! [msWordService.getFeatureAttachments()]', error);
        }
      );
    }

    //console.log('getFeatureAttachments done');
    //console.log('getFeatureAttachments()', featureImageBlobs);
    return featureImageBlobs;
  }

  private async getImage(url): Promise<any> {
    let httpHeaders = new HttpHeaders().set('Accept', "image/webp,*/*"); 
    return await this.http.get<Blob>(url, { headers: httpHeaders, responseType: 'blob' as 'json' }).toPromise();
  }

  private async getImageSize(url: string): Promise<any[]>{

    //let apiUrl = "https://map-test.crispora.com.au/api/image-size/get.php?url=" +  url;
    let apiUrl = "https://api.on-par-technology.com/image-size/get.php?url=" +  url;

    //  Call the API
    const headers = new HttpHeaders({    
      'Content-Type': 'application/x-www-form-urlencoded'
    });

    //const body = new HttpParams().set('url', url);

    try {
      return await this.http.get<any>(apiUrl, { headers }).toPromise();
    }
    catch (error) {
      console.error('error: ', error);
    }


  }

  public async getStaticMapUrl(extent: any, url: string, title: string, objectids: number[]): Promise<any> {

    let mapImageUrl = '';
    await this.staticMapService.generate(extent, url, title, objectids).then( 
      data => {
        mapImageUrl = data.results[0].value.url;
      }, 
      error => {
        console.error('There was an error! [msWordService.staticMapService.getStaticMapUrl()]', error);
      }
    );
    //console.log('mapImageUrl', mapImageUrl);

    return mapImageUrl;
  }

  public async getStaticMap(mapImageUrl: string): Promise<any> {

    let mapImageBlob = null;

    await this.getImage(mapImageUrl).then(
      data => {
        mapImageBlob = data;
      },
      error => {
        console.error('There was an error! [msWordService.getStaticMap()]', error);
      }
    );
    //console.log('mapImageBlob', mapImageBlob);

    return mapImageBlob;
  }

  //
  // Domain / LOV Functions
  //

  public getDomainValue(fieldName: string, codedValue: any, feature: any) {
    let domainName = feature.layer.fields.find(field => field.name === fieldName).domain.name;
    //console.log('getDomainValue() domainName:', domainName);

    if (codedValue) {
      let domain = this.domains.find(domain => domain.name === domainName);
      return domain.codedValues.find(code => code.code === codedValue).name;
    }
    else {
      return;
    }
  }

  public createDomainList(fieldName: string, codedValue: string, feature: any, other?: string): Paragraph[] {
    let paragraphs: Paragraph[] = [];
    let domainName = feature.layer.fields.find(field => field.name === fieldName).domain.name;
    let domain = this.domains.find(domain => domain.name === domainName);

    domain.codedValues.forEach( element => {
      if (codedValue === element.code) {
        if (codedValue === 'other') {
          paragraphs.push(this.createCheckboxTick("Other: " + other));
        }
        else {
          paragraphs.push(this.createCheckboxTick(element.name));
        }
      }
      else {
        paragraphs.push(this.createCheckbox(element.name));
      }
    })

    return paragraphs;
  }


  //
  // Progress / Write File
  //

  public updateProgress(numberSteps: number, currentStepNumber: number, currentStepName: string, numberItems: number, currentItemNumber: number, currentItemName: string, message?: string) {

    let stepProgress = (currentStepNumber / numberSteps) * 100;
    let itemProgress = (currentItemNumber / numberItems) * 100;

    let progressMessage_Report: ProgressMessage_Report = {
      numberSteps,
      currentStepNumber,
      currentStepName,
      numberItems,
      currentItemNumber,
      currentItemName,
      message,
      stepProgress,
      itemProgress
    }

    //console.log('stepProgress', stepProgress, 'itemProgress', itemProgress);

    this.progressService.progressData.next(progressMessage_Report);
  }

  public saveDocumentFile(fileName: string, numberSteps: number) {

    let currentStepNumber = numberSteps;
    let currentStepName = 'Saving document...';

    this.updateProgress(numberSteps, currentStepNumber, currentStepName, 1, 1, currentStepName);
    console.log('***** ' + currentStepName);

    Packer.toBlob(this.document).then(blob => {
      saveAs(blob, fileName + ".docx");
      currentStepName = 'Document created';
      this.updateProgress(numberSteps, currentStepNumber, currentStepName, 1, 1, currentStepName, 'Done');
      console.log(currentStepName);
    });


    // https://italonascimento.github.io/applying-a-timeout-to-your-promises/
  }

}


// private takeScreenShotXX() {
//   console.log('width', this._view.width);
//   console.log('height', this._view.height);
//   let options = {
//     format: "jpg",
//     quality: 50,
//     //width: this._view.width * 0.8,
//     //height: this._view.height * 0.95
//     //width: this._view.width,
//     //height: this._view.width * 0.7116
//     //width: this._view.width * 0.5,
//     //height: this._view.height * 0.5
//     //width: 512,
//     //height: 256
//     width: this._view.width / 2
//   };
//   this._view.takeScreenshot();
//   // this._view.takeScreenshot(options).then( (screenshot) => {
//   //   //console.log('screenshot', screenshot);
//   //   //console.log('screenshot.dataUrl', screenshot.dataUrl);
//   //   //console.log(this._screenShotEl.nativeElement);  
//   //   //this._imgScreenShotEl.nativeElement.src = screenshot.dataUrl;
//   //   // display a preview of the image
//   //   //this.showPreview(screenshot);
//   //   //return screenshot;
//   // });
// }


// private getImage$(url): Observable<any> {
//   let httpHeaders = new HttpHeaders().set('Accept', "image/webp,*/*"); 
//   return this.http.get<Blob>(url, { headers: httpHeaders, responseType: 'blob' as 'json' });
// }


// private getImageSizes$(attachmentInfos: any): Observable<any[]> {
//   //let imageSizes: any[] = [];
//   let imageUrls: any[] = [];
//   attachmentInfos.forEach( attachment => {
//     //console.log('Getting attachment size:', attachment.url);
//     imageUrls.push(attachment.url);
//   });
//   //console.log('imageUrls', imageUrls);
//   //console.log('imageUrls', JSON.stringify(imageUrls));
//   let apiUrl = "https://map-test.crispora.com.au/api/image-size/post.php";
//   //  Call the API
//   const headers = new HttpHeaders({    
//     'Content-Type': 'application/x-www-form-urlencoded'
//   });
//   const body = new HttpParams()
//     .set('urls', JSON.stringify(imageUrls));
//   try {
//     return this.http.post<any>(apiUrl, body.toString(), { headers }).pipe(
//       map( (res) => {
//         console.log('getImageSizes() res', res);
//         return res;
//       })
//     );
//   }
//   catch (error) {
//     console.error('error: ', error);
//   }
//   //return imageSizes;
// }



