import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialModule } from 'src/app/_globals/material-module';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxGalleryModule } from '@nomadreservations/ngx-gallery';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
//import { ScrollingModule } from '@angular/cdk/scrolling';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { EsriMapComponent } from './components/esri-map/esri-map.component';
import { EsriSceneComponent } from './components/esri-scene/esri-scene.component';
import { EsriAttachmentComponent } from './components/esri-attachment/esri-attachment.component';
import { EsriEditTableComponent } from './components/esri-edit-table/esri-edit-table.component';
import { EsriEditFormComponent } from './components/esri-edit-form/esri-edit-form.component';
import { EsriPopupMenuComponent } from './components/esri-popup-menu/esri-popup-menu.component';
import { EsriViewTableComponent } from './components/esri-view-table/esri-view-table.component';
import { EsriViewCardsComponent } from './components/esri-view-cards/esri-view-cards.component';
import { EsriAddressSearchComponent } from './components/esri-address-search/esri-address-search.component';
import { EsriEditFormElementsComponent } from './components/esri-edit-form-elements/esri-edit-form-elements.component';
import { EsriEditFormButtonsComponent } from './components/esri-edit-form-buttons/esri-edit-form-buttons.component';
import { EditComponent } from './dialogs/edit/edit.component';
import { ConfirmComponent } from './dialogs/confirm/confirm.component';
import { ReportComponent } from './dialogs/report/report.component';
import { EsriViewTableElementsComponent } from './components/esri-view-table-elements/esri-view-table-elements.component';
import { EsriInfoBoxComponent } from './components/esri-info-box/esri-info-box.component';
import { EsriEditFormMasterDetailComponent } from './components/esri-edit-form-master-detail/esri-edit-form-master-detail.component';


@NgModule({
  declarations: [
    EsriMapComponent, 
    EsriSceneComponent, 
    EsriAttachmentComponent, 
    EsriEditTableComponent, 
    EsriEditFormComponent, 
    EsriEditFormElementsComponent,
    EsriEditFormButtonsComponent,
    EsriPopupMenuComponent, 
    EsriViewTableComponent,
    EsriViewCardsComponent,
    EsriAddressSearchComponent,
    EditComponent,
    ConfirmComponent,
    ReportComponent,
    EsriViewTableElementsComponent,
    EsriInfoBoxComponent,
    EsriEditFormMasterDetailComponent,
  ],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MaterialModule,
    DragDropModule,
    ReactiveFormsModule,
    NgxGalleryModule,
    CKEditorModule,
    //ScrollingModule,
    NgScrollbarModule
  ],
  providers: [
  ],
  exports: [
    EsriMapComponent,
    EsriSceneComponent,
    EsriAttachmentComponent,
    EsriEditTableComponent,
    EsriEditFormComponent,
    EsriEditFormElementsComponent,
    EsriEditFormButtonsComponent,
    EsriPopupMenuComponent,
    EsriViewTableComponent,
    EsriViewCardsComponent,
    EsriAddressSearchComponent,
    EditComponent,
    ConfirmComponent,
    ReportComponent,
    EsriInfoBoxComponent,
    EsriEditFormMasterDetailComponent,
  ]
})
export class EsriModule { }
