<!-- <mat-accordion class="map-gallery-panel" multi> -->
<mat-expansion-panel class="map-gallery-panel">
    <mat-expansion-panel-header>
        <mat-panel-title>Images</mat-panel-title>
        <mat-panel-description>
            <span class='map-gallery-no-images' [hidden]="!selectedFeature">{{numberOfImagesMsg}}</span>
            <!-- [hidden]="galleryLoaded" -->
            <span class='map-gallery-no-images' [hidden]="selectedFeature">Select an item from the map or the
                table</span>
            <!-- <mat-icon>account_circle</mat-icon> -->
        </mat-panel-description>
    </mat-expansion-panel-header>

    <!-- Image Gallery -->
    <div class='map-gallery-panel'>
        <!-- <app-esri-attachment style='width: 100%' [(numberOfImagesMsg)]='numberOfImagesMsg'></app-esri-attachment> -->
        <div>
            <ngx-gallery #gallery [hidden]='numberOfImages === 0' [options]='galleryOptions' [(images)]='galleryImages'
                (imagesReady)='galleryImagesReady($event)' (change)='galleryImageSelected($event)'></ngx-gallery>
        </div>

        <div fxLayout='row' fxLayoutAlign='center center'>
            <button mat-raised-button class="button-row" color="accent" (click)='attachmentUpload.click()'>Add a new
                image
                <mat-icon class="material-icons-sharp button-icon">file_upload</mat-icon>
            </button>

            <button mat-raised-button class="button-row" color="accent" (click)='deleteAttachment()'>Delete selected
                image
                <mat-icon class="material-icons-sharp button-icon">delete</mat-icon>
            </button>
        </div>

        <form [formGroup]="attachmentForm">
            <div>
                <input hidden #attachmentUpload type="file" name="attachment" accept='image/*'
                    (change)="onFileSelected($event)" />
            </div>
        </form>
    </div>
</mat-expansion-panel>
<!-- </mat-accordion> -->


<!-- <div >
    <ngx-gallery #gallery [hidden]='numberOfImages === 0' [options]='galleryOptions' [(images)]='galleryImages' (imagesReady)='galleryImagesReady($event)' (change)='galleryImageSelected($event)'></ngx-gallery>
</div>

<div fxLayout='row' fxLayoutAlign='center center' > 
    <button mat-raised-button class="button-row" color="accent" (click)='attachmentUpload.click()'>Add a new image
        <mat-icon class="material-icons-sharp button-icon">file_upload</mat-icon>
    </button>

    <button mat-raised-button class="button-row" color="accent" (click)='deleteAttachment()'>Delete selected image
        <mat-icon class="material-icons-sharp button-icon">delete</mat-icon>
    </button>
</div>

<form [formGroup]="attachmentForm" >      
    <div>
      <input hidden #attachmentUpload type="file" name="attachment" accept='image/*' (change)="onFileSelected($event)" />
    </div>
</form> -->


<!-- <mat-accordion class="map-gallery-panel" multi> -->
<!-- <mat-expansion-panel > -->
<!-- <mat-expansion-panel-header> -->
<!-- <mat-panel-title>Images</mat-panel-title> -->
<!-- <mat-panel-description> -->
<!-- <span class='map-gallery-no-images' [hidden]="!selectedFeature">{{numberOfImagesMsg}}</span> [hidden]="galleryLoaded" -->
<!-- <span class='map-gallery-no-images' [hidden]="selectedFeature">Select an item from the map or the table</span> -->
<!-- <mat-icon>account_circle</mat-icon> -->
<!-- </mat-panel-description> -->
<!-- </mat-expansion-panel-header> -->
<!-- Image Gallery -->
<!-- <div #mapGalleryPanel fxLayout='row' class='map-gallery-panel'>  [hidden]="!galleryLoaded" -->
<!-- <app-esri-attachment style='width: 100%' [(numberOfImagesMsg)]='numberOfImagesMsg'></app-esri-attachment>       -->
<!-- </div> -->
<!-- </mat-expansion-panel> -->
<!-- </mat-accordion> -->