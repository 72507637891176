<div #map class='map'>
    <!-- <div *ngIf='showDataCards' #sideBar ngClass="{'sidebar' : 'showDataCards', 'no-sidebar' : '!showDataCards'}"></div> -->
    <div *ngIf='sidePanelWidth > 0' #sideBar class='sidebar'>
        <div class='sidebar-content'>
            <app-esri-view-cards></app-esri-view-cards>
        </div>
    </div>
</div>

<div #logo class="logo" *ngIf='showLogo'>
    <span class="logo-cos">
        <!-- <img class="logo-cos" src='assets/images/logo-grey.png' /> -->
    </span>
     <span class="logo-client">
        <!-- <img class="logo-client" src='assets/images/logo.png' /> -->
    </span>
</div>
<!-- <div #timeSlider class='time-slider'></div> -->

<div #infoPanelTitle id='infoPanelTitle' [hidden]='infoPanelTitleHidden'>
    <mat-card class="title-panel">
        <mat-card-header>  
            <div mat-card-avatar class="card-header-image">
                <mat-icon class='material-icons-sharp'>report_problem</mat-icon>
            </div>
            <mat-card-title>{{title}}</mat-card-title>
            <mat-card-subtitle>{{subTitle}}</mat-card-subtitle>          
        </mat-card-header>
    </mat-card>
</div>

<!-- Toggle Slide to turn on/off zoom on select -->
<div #zoomToFeatureChoice [hidden]='!loaded' >
    <mat-slide-toggle color="primary" [checked]="zoomToFeature" (change)="onToggleZoomToFeatureChange($event)">
        <span class='choice-toggle'>Zoom on selection</span>
    </mat-slide-toggle>
</div>

<!-- Toggle Slide to turn on/off pan on select -->
<div #panToFeatureChoice [hidden]='!loaded'>
    <mat-slide-toggle color="primary" [checked]="panToFeature" [disabled]='zoomToFeature' (change)="onTogglePanToFeatureChange($event)">
        <span class='choice-toggle'>Pan on selection</span>
    </mat-slide-toggle>
</div>

<div #addBtn [hidden]="addBtnHidden">
    <span [hidden]='!loaded'>
        <div fxHide fxShow.gt-sm>
            <button mat-fab color="primary" matTooltip='Add a new item' aria-label="Add icon button" (click)='addBtnEvent()' >
                <mat-icon class='material-icons-sharp'>add</mat-icon>     
            </button>
        </div>
        <button fxShow fxHide.gt-sm mat-mini-fab color="primary" matTooltip='Add a new item' aria-label="Add icon button" (click)='addBtnEvent()'>
            <mat-icon class='material-icons-sharp'>add</mat-icon>     
        </button>
    </span>
</div>

<div #reportBtn [hidden]="reportBtnHidden">
    <span [hidden]='!loaded'>
        <div fxHide fxShow.gt-sm>
            <button mat-fab color="primary" matTooltip='Run a report' aria-label="Report icon button" (click)='reportBtnEvent($event)' >
                <mat-icon class='material-icons-sharp'>text_snippet</mat-icon>     
            </button>
        </div>
        <button fxShow fxHide.gt-sm mat-mini-fab color="primary" matTooltip='Run a report' aria-label="Report icon button" (click)='reportBtnEvent($event)'>
            <mat-icon class='material-icons-sharp'>text_snippet</mat-icon>     
        </button>
    </span>
</div>


<div #createIncidentBtn [hidden]="createWorkRequestBtnHidden">
    <span [hidden]='!loaded'>
        <div fxHide fxShow.gt-sm>
            <button mat-fab color="primary" matTooltip='Create a Work Request' aria-label="Create a Work Request icon button" (click)='createWorkRequestBtnEvent($event)' [disabled]="createWorkRequestBtnDisabled">
                <mat-icon class='material-icons-sharp'>build</mat-icon>     
            </button>
        </div>
        <button fxShow fxHide.gt-sm mat-mini-fab color="primary" matTooltip='create a Work Request' aria-label="Create a Work Request icon button" (click)='createWorkRequestBtnEvent($event)' [disabled]="createWorkRequestBtnDisabled">
            <mat-icon class='material-icons-sharp'>build</mat-icon>     
        </button>
    </span>
</div>

<div #createProjectBtn [hidden]="createProjectBtnHidden">
    <span [hidden]='!loaded'>
        <div fxHide fxShow.gt-sm>
            <button mat-fab color="primary" matTooltip='Create a project' aria-label="Create a project icon button" (click)='createProjectBtnEvent($event)' [disabled]="createProjectBtnDisabled">
                <mat-icon class='material-icons-sharp'>engineering</mat-icon>     
            </button>
        </div>
        <button fxShow fxHide.gt-sm mat-mini-fab color="primary" matTooltip='create a project' aria-label="Create a project icon button" (click)='createProjectBtnEvent($event)' [disabled]="createProjectBtnDisabled">
            <mat-icon class='material-icons-sharp'>engineering</mat-icon>     
        </button>
    </span>
</div>


<!-- Add a new feature -->
<div #addFeatureWorkflow [hidden]='false'>

    <!-- Location / No location -->
    <mat-card class="add-new-workflow-panel" *ngIf='locationNoneChoice' @fadeInOut>
        <mat-card-header>  
            <div mat-card-avatar class="add-new-workflow-header-image">
                <mat-icon class='material-icons-sharp'>not_listed_location</mat-icon>
            </div>
            <mat-card-title>Location Needed</mat-card-title>
            <mat-card-subtitle>Decide if a location will be associated with this new item</mat-card-subtitle>          
        </mat-card-header>
        <mat-card-content >
            <span>
                <p style='font-size: large;'>Does this new item have a location?</p>
                <p style='font-size: larger;'></p> 
            </span>
        </mat-card-content>
        <mat-card-actions>
            <span class='add-new-workflow-button-row light-border'>
                <div fxLayout='row' fxLayoutAlign='center center'>
                    <button mat-stroked-button (click)='locationEvent("location")'>Yes</button>   
                    <button mat-stroked-button (click)='locationEvent("location-selected")'>No</button> 
                    <button mat-stroked-button (click)='locationEvent("location-cancel")'>Cancel</button>
                </div>
            </span>
        </mat-card-actions>
    </mat-card>

    <!-- Ask in / out Boundary -->
    <mat-card class="add-new-workflow-panel" *ngIf='!locationHidden' @fadeInOut>
        <mat-card-header>  
            <div mat-card-avatar class="add-new-workflow-header-image">
                <mat-icon class='material-icons-sharp'>not_listed_location</mat-icon>
            </div>
            <mat-card-title>Location</mat-card-title>
            <mat-card-subtitle>For the item to be added</mat-card-subtitle>          
        </mat-card-header>
        <mat-card-content >
            <span>
                <p style='font-size: large;'>How would you like to choose the location?</p>
                <p style='font-size: larger;'>Select a location on the map with your mouse, or search for an address?</p> 
            </span>
        </mat-card-content>
        <mat-card-actions>
            <span class='add-new-workflow-button-row light-border'>
                <div fxLayout='row' fxLayoutAlign='center center'>
                    <button mat-stroked-button (click)='locationEvent("location-map")'>Map</button>   
                    <button mat-stroked-button (click)='locationEvent("location-address")'>Address</button> 
                    <button mat-stroked-button (click)='locationEvent("location-cancel")'>Cancel</button>
                </div>
            </span>
        </mat-card-actions>
    </mat-card>

    <!-- Within Boundary --> 
    <mat-card class="add-new-workflow-panel" *ngIf='locationMap' @fadeInOut>
        <mat-card-header>  
            <div mat-card-avatar class="add-new-workflow-header-image">
                <mat-icon class='material-icons-sharp'>not_listed_location</mat-icon>
            </div>
            <mat-card-title>Location</mat-card-title>
            <mat-card-subtitle>For the item to be added</mat-card-subtitle>          
        </mat-card-header>
        <mat-card-content >
            <span>
                <p style='font-size: large;'>Click on the map to select the location</p>
            </span>
        </mat-card-content>
        <mat-card-actions>
            <span class='add-new-workflow-button-row light-border'>
                <div fxLayout='row' fxLayoutAlign='center center'>
                    <span [ngClass]="locationSelectBtnDisabled ? 'light-border-disabled' : 'light-border'">
                        <button mat-stroked-button [disabled]='locationSelectBtnDisabled' (click)='locationEvent("location-selected")'>OK</button>
                    </span>
                    <button mat-stroked-button (click)='locationEvent("location-cancel")'>Cancel</button>
                </div>
            </span>
        </mat-card-actions>
    </mat-card>

    <!-- Address -->
    <mat-card class="add-new-workflow-panel" *ngIf='locationAddress' @fadeInOut>
        <mat-card-header>  
            <div mat-card-avatar class="add-new-workflow-header-image">
                <mat-icon class='material-icons-sharp'>not_listed_location</mat-icon>
            </div>
            <mat-card-title>Location</mat-card-title>
            <mat-card-subtitle>For the item to be added</mat-card-subtitle>          
        </mat-card-header>
        <mat-card-content >
            <span>
                <div class='add-new-workflow-address'> 
                    <app-esri-address-search [executeGeocode]='true'></app-esri-address-search>                                              
                </div>
            </span>
        </mat-card-content>
        <mat-card-actions>
            <span class='add-new-workflow-button-row light-border'>
                <div fxLayout='row' fxLayoutAlign='center center'>
                    <span [ngClass]="addressSelectBtnDisabled ? 'light-border-disabled' : 'light-border'">
                        <button mat-stroked-button (click)='locationEvent("location-selected")' [disabled]='addressSelectBtnDisabled'>OK</button>
                    </span>
                    <span>
                        <button mat-stroked-button (click)='locationEvent("location-cancel")' [disabled]='false'>Cancel</button>
                    </span>
                </div>
            </span>
        </mat-card-actions>
    </mat-card>      
</div>
