import { Component, Inject, OnDestroy, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { Subject } from 'rxjs';
import { Breakpoints } from '@angular/cdk/layout';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ReportDefinition } from 'src/app/_esri/models/esri';
import { AuditReportService } from 'src/app/audit/services/audit-report.service';
import { ProgressService } from 'src/app/_globals/services/progress.service';
import { takeUntil } from 'rxjs/operators';

export interface DialogData {
  title: string,
  reports: ReportDefinition[]
}

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})

export class ReportComponent implements OnInit, OnDestroy {

  public ngUnsubscribe = new Subject<void>();
  public progressData: any;

  // Events
  @Output() runReportBtnSelectedEvent = new EventEmitter<any>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialogRef: MatDialogRef<ReportComponent>,
    private auditReportService: AuditReportService,
    private progressService: ProgressService
  ) { 
    this.progressService.progressData.pipe(takeUntil(this.ngUnsubscribe)).subscribe((response: any) => {
      console.log('response', response);
      this.progressData = response;
    });
  }

  ngOnInit(): void {
    console.log('ReportComponent ngOnInit()');
    this.progressData = this.auditReportService.progressData;
  }

  ngOnDestroy() {
    console.log('ReportComponent ngOnDestroy()');
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public runReportBtnSelected(moduleId: string, reportId: string) {
    // Find the report
    const report = this.data.reports.filter(x => (x.moduleId === moduleId && x.reportId === reportId));

    // There should only be 1 matching the select criteria
    if (report.length != 1) {
      console.error('Report not found.  Module: ', moduleId, ' Report: ', reportId);
      return;
    }

    switch(moduleId) {
      case 'AUDIT':
        switch(reportId) {
          case 'COURSEAUDIT':
            this.auditReportService.reportTitleLine1 = report[0].reportTitleLine1;
            this.auditReportService.reportTitleLine2 = report[0].reportTitleLine2;
            this.auditReportService.reportDescription = report[0].reportDescription;
            this.auditReportService.featureLayer = report[0].featureLayer;
            this.auditReportService.features = report[0].features;
            this.auditReportService.domains = report[0].domains;
            this.auditReportService.domains_lov = report[0].domains_lov;
            this.auditReportService.domains_lovParent = report[0].domains_lovParent;

            this.auditReportService.runReport(reportId);

            break;

          default:
            break;
        }
        break;

      default:
        break;
    }
  }

}
