<div mat-dialog-title fxLayout='row' fxLayoutAlign='space-between center'>
    <span>{{data.title}}</span>
    <span mat-dialog-actions style='margin-bottom: 0;'>
        <button mat-dialog-close mat-mini-fab color="primary" aria-label="Close icon button">
            <mat-icon>close</mat-icon>
        </button>
    </span>
</div>

<div mat-dialog-content>
    
    <span>
        <div fxLayout="row" fxLayoutAlign="center center">
            <span *ngFor="let report of data.reports">
                <div>
                    <mat-card class="example-card">
                        <mat-card-header>
                            <div mat-card-avatar class="example-header-image"><mat-icon>text_snippet</mat-icon></div>
                            <mat-card-title>{{report.reportName}}</mat-card-title>
                            <mat-card-subtitle>Report</mat-card-subtitle>
                        </mat-card-header>
                        <!-- <img mat-card-image src="https://material.angular.io/assets/img/examples/shiba2.jpg" alt="Photo of a Shiba Inu"> -->
                        <mat-card-content>
                            <p></p>
                            <p>{{report.reportDescription}}</p>
                        </mat-card-content>
                        <mat-card-actions>
                            <div fxLayout="row" fxLayoutAlign="center center">
                                <!-- <button matTooltip={{report.reportDescription}} aria-label="Icon button" (click)='runReportBtnSelected(report.moduleId, report.reportId)'>
                                    <mat-icon>directions_run</mat-icon>     
                                </button> -->

                                <button mat-raised-button matTooltip={{report.reportDescription}} color="primary" (click)='runReportBtnSelected(report.moduleId, report.reportId)'>Run this report
                                    <mat-icon>directions_run</mat-icon>
                                </button>


                            </div>
                        </mat-card-actions>
                    </mat-card>
                </div>
            </span>
        </div>
    </span>
    <br />
    <span *ngIf='progressData'>
        <span *ngIf='progressData.message != "Done"'>Report progress...</span>
        <div fxLayout="row" fxLayoutAlign="space-between center">
            <div fxFlex="80">
                <span *ngIf='progressData.message != "Done"'>
                    <p>{{progressData.currentStepName}} (Step {{progressData.currentStepNumber}} of {{progressData.numberSteps}})</p>
                    <mat-progress-bar mode="determinate" [(value)]="progressData.stepProgress"></mat-progress-bar>
                </span>

                <span *ngIf='progressData.numberItems > 1'>
                    <p>{{progressData.currentItemName}} (Item {{progressData.currentItemNumber}} of {{progressData.numberItems}})</p>
                    <mat-progress-bar mode="determinate" [(value)]="progressData.itemProgress"></mat-progress-bar>
                </span>
        
                <p *ngIf='progressData.message === "Done"'>{{progressData.message}}</p>
            </div>

            <div fxFlex="20" style='margin-left: 20px; overflow: hidden;'>
                <mat-progress-spinner *ngIf='progressData && progressData.message != "Done"' value="100" class="example-margin" style='margin: 0 10px; overflow: hidden;' [mode]="'indeterminate'"> </mat-progress-spinner>
            </div>
        </div>
    </span>
</div>

<!-- <div mat-dialog-actions>
    <div fxLayout='row' fxLayoutAlign='space-around center' class='button-row'>
        <span *ngFor="let report of data.reports">
            <button mat-fab matTooltip={{report.reportDescription}} aria-label="Icon button" (click)='runReportBtnSelected(report.moduleId, report.reportId)'>
                <mat-icon>edit</mat-icon>     
            </button>
            <div fxHide fxShow.gt-md class='button-row-icon-text'>{{report.reportName}}</div>
        </span>
    </div>
</div> -->