<div class="info-box" 
    [ngClass]="{'info-box-red': infoBoxColour==='red',
        'info-box-orange': infoBoxColour==='orange',
        'info-box-yellow': infoBoxColour==='yellow',
        'info-box-green': infoBoxColour==='green',
        'info-box-blue': infoBoxColour==='blue',
        'info-box-purple': infoBoxColour==='purple',
        'info-box-dark-grey': infoBoxColour==='dark-grey',
        'info-box-grey': infoBoxColour==='grey',
        'info-box-red-dark': darkMode && infoBoxColour==='red',
        'info-box-orange-dark': darkMode && infoBoxColour==='orange',
        'info-box-yellow-dark': darkMode && infoBoxColour==='yellow',
        'info-box-green-dark': darkMode && infoBoxColour==='green',
        'info-box-blue-dark': darkMode && infoBoxColour==='blue',
        'info-box-purple-dark': darkMode && infoBoxColour==='purple',
        'info-box-dark-grey-dark': darkMode && infoBoxColour==='dark-grey',
        'info-box-grey-dark': darkMode && infoBoxColour==='grey',
        'info-box-nightLights-purple-1': infoBoxColour==='night-purple-1',
        'info-box-nightLights-purple-2': infoBoxColour==='night-purple-2',
        'info-box-nightLights-magenta-1': infoBoxColour==='night-magenta-1',
        'info-box-nightLights-magenta-2': infoBoxColour==='night-magenta-2',
        'info-box-nightLights-blue-1': infoBoxColour==='night-blue-1',
        'info-box-nightLights-blue-2': infoBoxColour==='night-blue-2'
    }">
    <div class="info-box-block">
        <div class="info-box-title">{{infoBoxTitle}}</div>
        <div fxLayout='row' fxLayoutAlign="space-between center" class="info-box-data">
            <mat-icon class='info-icon'>{{infoBoxIcon}}</mat-icon>    <!-- // functions construction done_outline priority_high hourglass_empty hourglass_disabled network_check -->
            <span>{{infoBoxValue}}</span>
        </div>
        <p class="info-box-description">{{infoBoxDescription}}</p>
    </div>
</div>
