import { FeatureLayerProperties, ElevationInfo, EsriRenderer_Polygon } from 'src/app/_esri/models/esri';
import { LayerIds } from 'src/app/_esri/models/layer-ids';

export class Surrounds {

    public static TileLayers: FeatureLayerProperties = new FeatureLayerProperties(
        LayerIds.gisServer_AGOL_Tiles, LayerIds.folder_TileSurrounds, null, 'tileLayerSurrounds', 'Surrounds', 'TILE'
    );

    public static Vegetation: FeatureLayerProperties = new FeatureLayerProperties(
        LayerIds.gisServer_Surrounds, LayerIds.folder_Surrounds, LayerIds.layerId_Vegetation, 'featureLayerVegetation', 'Vegetation'
    );

    public static Water: FeatureLayerProperties = new FeatureLayerProperties(
        LayerIds.gisServer_Surrounds, LayerIds.folder_Surrounds, LayerIds.layerId_Water, 'featureLayerWater', 'Water'
    );

    public static Greenspace: FeatureLayerProperties = new FeatureLayerProperties(
        LayerIds.gisServer_Surrounds, LayerIds.folder_Surrounds, LayerIds.layerId_Greenspace, 'featureLayerGolfCourse', 'Golf Course'
    );
    
    public static Park: FeatureLayerProperties = new FeatureLayerProperties(
        LayerIds.gisServer_Surrounds, LayerIds.folder_Surrounds, LayerIds.layerId_Park, 'featureLayerPark', 'Park'
    );
    
    public static RoadCorridor: FeatureLayerProperties = new FeatureLayerProperties(
        LayerIds.gisServer_Surrounds, LayerIds.folder_Surrounds, LayerIds.layerId_RoadCorridor, 'featureLayerRoadCorridor', 'Road Corridor'
    );
    
    public static CourseBoundary: FeatureLayerProperties = new FeatureLayerProperties(
        LayerIds.gisServer_Surrounds, LayerIds.folder_Surrounds, LayerIds.layerId_CourseBoundary, 'featureLayerCourseBoundary', 'Course Boundary'
    );

    public static createLayerPropertiesList(is3D: boolean, allSurrounds: boolean, opacity?: number): FeatureLayerProperties[] {
        let renderer;
        let featureLayerProperties: FeatureLayerProperties[] = [];

        // Set the elevation info if the layers are required to be shown in a scene
        if (is3D === true) {
            let elevationInfo = new ElevationInfo();
            elevationInfo.mode = 'on-the-ground';

            Surrounds.Vegetation.elevationInfo = elevationInfo;
            Surrounds.Water.elevationInfo = elevationInfo;
            Surrounds.Greenspace.elevationInfo = elevationInfo;
            Surrounds.Park.elevationInfo = elevationInfo;
            Surrounds.RoadCorridor.elevationInfo = elevationInfo;
            Surrounds.CourseBoundary.elevationInfo = elevationInfo;
        }

        // **************************************************************
        // Order is important here - it controls the display order
        // Last one listed is on top
        // **************************************************************

        // Set the renderers and add the layers to a list

        // Course Boundary
        featureLayerProperties.push(this.createLayerProperties_CourseBoundary(is3D, opacity));
        
        // Get the water and vegetation layers
        renderer = new EsriRenderer_Polygon([92, 137, 68, 0.65], [92, 137, 68, 0.65], 0); 
        Surrounds.Vegetation.renderer = renderer.renderer;
        Surrounds.Vegetation.minScale = 20000;
        (opacity) ? Surrounds.Vegetation.opacity = opacity : null;
        featureLayerProperties.push(Surrounds.Vegetation);

        renderer = new EsriRenderer_Polygon([0, 77, 168, 1.0], [0, 77, 168, 1.0], 0);
        Surrounds.Water.renderer = renderer.renderer;
        Surrounds.Water.minScale = 20000;
        (opacity) ? Surrounds.Water.opacity = opacity : null;
        featureLayerProperties.push(Surrounds.Water);

        // Return all surrounding layers
        if (allSurrounds === true) {
            // renderer = new EsriRenderer_Polygon([112, 168, 0, 1.0], [112, 168, 0, 1.0], 0); 
            // Surrounds.BowlingGreen.renderer = renderer.renderer;
            // Surrounds.BowlingGreen.minScale = 10000;
            // (opacity) ? Surrounds.BowlingGreen.opacity = opacity : null;
            // featureLayerProperties.push(Surrounds.BowlingGreen);

            renderer = new EsriRenderer_Polygon([112, 168, 0, 0.35], [112, 168, 0, 0.35], 0);
            Surrounds.Greenspace.renderer = renderer.renderer;
            Surrounds.Greenspace.minScale = 10000;
            (opacity) ? Surrounds.Greenspace.opacity = opacity : null;
            featureLayerProperties.push(Surrounds.Greenspace);

            // Surrounds.GolfCourse_2.renderer = renderer.renderer;
            // Surrounds.GolfCourse_2.minScale = 10000;
            // (opacity) ? Surrounds.GolfCourse_2.opacity = opacity : null;
            // featureLayerProperties.push(Surrounds.GolfCourse_2);

            Surrounds.Park.renderer = renderer.renderer;
            Surrounds.Park.minScale = 10000;
            (opacity) ? Surrounds.Park.opacity = opacity : null;
            featureLayerProperties.push(Surrounds.Park);

            renderer = new EsriRenderer_Polygon([104, 104, 104, 1.0], [104, 104, 104, 1.0], 0);
            Surrounds.RoadCorridor.renderer = renderer.renderer;
            Surrounds.RoadCorridor.minScale = 5000;
            (opacity) ? Surrounds.RoadCorridor.opacity = opacity : null;
            featureLayerProperties.push(Surrounds.RoadCorridor);
        }

        return featureLayerProperties;
    }

    public static createLayerProperties_CourseBoundary(is3D: boolean, opacity?: number): FeatureLayerProperties {

        let renderer;
        let featureLayerProperties: FeatureLayerProperties;

        renderer = new EsriRenderer_Polygon([0, 0, 0, 0.0], [225, 0, 0, 1.0], 2);
        Surrounds.CourseBoundary.renderer = renderer.renderer;
        Surrounds.CourseBoundary.minScale = 500000;
        (opacity) ? Surrounds.CourseBoundary.opacity = opacity : null;
        featureLayerProperties = Surrounds.CourseBoundary;

  
        return featureLayerProperties;
    }
}
