import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, ViewChild, ElementRef, ChangeDetectorRef, ChangeDetectionStrategy } from "@angular/core"; // ViewChild, ElementRef,
import { MatDialog } from '@angular/material/dialog';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
//import { faMale, faFemale } from '@fortawesome/free-solid-svg-icons';
import { Global } from 'src/app/_globals/models/global';
import { Esri, CameraSettings, FeatureServiceQueryResponse } from 'src/app/_esri/models/esri';
import { CourseService } from 'src/app/_course/services/course.service';
import { SurroundsService } from 'src/app/_course/services/surrounds.service';
import { GamePlayService } from 'src/app/game/services/game-play.service';
//import { TokenOauthService } from 'src/app/_esri/services/token-oauth.service';
//import { TokenRegisterService } from 'src/app/_esri/services/token-register.service';
//import { TokenNamedUserService } from 'src/app/_esri/services/token-named-user.service';
import { GameFlyoverComponent } from './dialogs/game-flyover/game-flyover.component';
import { GameInfoComponent } from './dialogs/game-info/game-info.component';
import { GameInfoVideoComponent } from './dialogs/game-info-video/game-info-video.component';
import { GameNotesComponent } from './dialogs/game-notes/game-notes.component';
import { HoleGalleryComponent } from 'src/app/map-gallery/dialogs/hole-gallery/hole-gallery.component';

@Component({
  selector: 'app-game',
  templateUrl: './game.component.html',
  styleUrls: [
    './game.component.scss',
    '../_styles/base-feature-page.scss'
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class GameComponent implements OnInit, OnDestroy {

  public title: string = 'Course'
  public view: any;
  public loaded: boolean = false;
  public featureServiceObject: FeatureServiceQueryResponse = new FeatureServiceQueryResponse();
  private _is3D: boolean = true;
  private _includeAllSurrounds: boolean = false;
  private _includeHillshade: boolean = true;
  private _includeNswProperty: boolean = false;
  private _numberOfTicks = 0;

  public darkMode = false;

  // Fonts
  //public faMale = faMale;
  //public faFemale = faFemale;

  // Current Query Settings

  public gameFairwayNumber: number = 99;  // All
  
  public gameGender: string = 'F';
  public gameTeeMarkerType: string = 'RED';
  public gameDriveLength: string = 'PAR';

  public showLength_PAR: boolean = true;
  public showLength_137: boolean = true;
  public showLength_150: boolean = true;
  public showLength_180: boolean = false;
  public showLength_192: boolean = true;

  // Feature Layer Defns
  private _featureLayerGamePlay: any = null;
  private _featureLayerTeeMarker: any = null;
  private _featureTblCourseGuide: any = null;
  private _featureTblGameGuide: any = null;
  private _layerViewGamePlay: any = null;

    // Full Data Set
  //private _featuresGamePlay: any[] = [];
  private _featuresTeeMarker: any[] = [];
  private _featuresCourseGuide: any[] = [];
  private _featuresGameGuide: any[] = [];

  // Search clauses
  private _whereClauseGamePlay: string = "GameGuideType = 'RED_PAR_F'";
  private _searchClauseTeeMarker: string = "RED";
  private _searchClauseCourseGuide: string = 'RED';
  private _searchClauseGameGuide: string = 'RED_PAR_F';

  // Currently Selected Values
  //private _selectedFeaturesGamePlay: any = [];
  private _selectedFeaturesTeeMarker: any = [];
  private _selectedFeaturesCourseGuide: any = [];
  private _selectedFeaturesGameGuide: any = [];

  // Controls
  public queryDriveLengthBtnBirdieDisabled: boolean = false;
  public queryDriveLengthBtnParDisabled: boolean = false;
  public queryDriveLengthBtn137Disabled: boolean = false;
  public queryDriveLengthBtn150Disabled: boolean = false;
  public queryDriveLengthBtn180Disabled: boolean = false;

  public gameGuideInfoButtonDisabled: boolean = true;
  public flyoverVideoButtonDisabled: boolean = true;
  public gameGuideVideoButtonDisabled: boolean = true;

  // Slider
  public holeSlider_autoTicks = false;
  public holeSlider_disabled = false;
  public holeSlider_invert = false;
  public holeSlider_max = 18;
  public holeSlider_min = 0;
  public holeSlider_showTicks = true;
  public holeSlider_step = 1;
  public holeSlider_thumbLabel = true;
  public holeSlider_value = 0;
  public holeSlider_vertical = false;
  public holeSlider_tickInterval = 1;

  public holeSliderOptions = {
    showTicksValues: true,
    floor: 0,
    ceil: 18,
    step: 1
  };
  
  public holeStatsPanelCollapsed = false;
  public choicePanelOpenState = true;

  // Animation
  public zoomToHole: boolean = true;

  // Scene quality
  public highQuality: boolean = true;

  public directShadows: boolean = true;
  public waterReflection: boolean = true;
  public stars: boolean = true;
  public atmosphere: boolean = true;
  public atmosphereQuality: string = 'high';          // "low" | "high"
  public qualityProfile: string = 'high';             // "low" | "medium" | "high"

  // Temporary
  public tmp_cameraSettings: string = '';

  @Input()
  set featureLayer(featureLayer: any) {
    this._featureLayerGamePlay = featureLayer;
  }

  get featureLayer(): any {
    return this._featureLayerGamePlay;
  }

  @Input()
  set selectedFeaturesTeeMarker(selectedFeaturesTeeMarker: any) {
    this._selectedFeaturesTeeMarker = selectedFeaturesTeeMarker;
  }

  get selectedFeaturesTeeMarker(): any {
    return this._selectedFeaturesTeeMarker;
  }

  @Input()
  set selectedFeaturesGame(selectedFeaturesGame: any) {
    this._selectedFeaturesCourseGuide = selectedFeaturesGame;
  }

  get selectedFeaturesGame(): any {
    return this._selectedFeaturesCourseGuide;
  }

  @Input()
  set selectedFeaturesGameGuide(selectedFeaturesGameGuide: any) {
    this._selectedFeaturesGameGuide = selectedFeaturesGameGuide;
  }

  get selectedFeaturesGameGuide(): any {
    return this._selectedFeaturesGameGuide;
  }

  @ViewChild("infoPanelTitle", { static: true }) private infoPanelTitleEl: ElementRef;
  @ViewChild("infoPanelGameChoice", { static: true }) private infoPanelGameChoiceEl: ElementRef;
  @ViewChild("infoPanelHoleStats", { static: true }) private infoPanelHoleStatsEl: ElementRef;
  @ViewChild("infoPanelHoleChoice", { static: true }) private infoPanelHoleChoiceEl: ElementRef;

  constructor(
    //private tokenOauthService: TokenOauthService,
    //private tokenNamedUserService: TokenNamedUserService,
    //private registerTokenService: TokenRegisterService,
    private ref: ChangeDetectorRef,
    private courseService: CourseService,
    private surroundsService: SurroundsService,
    private gamePlayService: GamePlayService,
    public dialog: MatDialog,
    private _bottomSheet: MatBottomSheet
  ) { 
    setInterval(() => {
      this._numberOfTicks++;
      // require view to be updated
      this.ref.markForCheck();
    }, 1000);
  }

  ngOnInit(): void {
    // Get a OAUTH token to access the secure map layers
    // this.tokenOauthService.getToken().subscribe(
    //   data => { 
    //     Esri.accessToken = data.access_token;
    //     console.log('Token received is: ', data.access_token);
    //     this.registerTokenService.registerToken();
    //   },
    //   error => {
    //     console.error('There was an error!', error);
    //   }
    // );

    this.setSceneQuality(true);

  //   // Named User Token
  //   this.tokenNamedUserService.getToken().subscribe(
  //     data => { 
  //       Esri.accessToken = data.token;
  //       //console.log(data);
  //       console.log('Token received is: ', data.token);
  //       this.registerTokenService.registerToken();
  //     },
  //     error => {
  //       console.error('There was an error!', error);
  //     }
  //   );
  }

  ngOnDestroy() {
    if (this.view) {
      // destroy the map view
      this.view.container = null;
    }

    if (this.featureServiceObject) {
      this.featureServiceObject = null;
    }
  }

  //
  // Event Listeners
  //

  public tmp_cameraButtonEvent() {
    // (1, 360, 75, -33.9417760, 151.211825, 245, 55),
    this.tmp_cameraSettings = 'camera: ' +
    ' (' + this.gameFairwayNumber.toFixed(0) + 
    ', ' + this.view.camera.heading.toFixed(0) + 
    ', ' + this.view.camera.tilt.toFixed(0) + 
    ', ' + this.view.camera.position.latitude.toFixed(7)  + 
    ', ' + this.view.camera.position.longitude.toFixed(6) + 
    ', ' + this.view.camera.position.z.toFixed(0) + 
    ', ' + this.view.camera.fov.toFixed(0) +
    '), ';

    // this.tmp_cameraSettings = 'camera settings - hole: ' + this.gameFairwayNumber + '  ' +
    // ' heading: ' + this.view.camera.heading + 
    // ' tilt: ' + this.view.camera.tilt + 
    // ' latitude: ' + this.view.camera.position.latitude + 
    // ' longitude: ' + this.view.camera.position.longitude + 
    // ' z: ' + this.view.camera.position.z + 
    // ' fov: ' + this.view.camera.fov;

    console.log(this.tmp_cameraSettings);

   


  }

  // Zoom to hole toggle
  public onToggleChange(event) {
    //console.log('before', this.zoomToHole);
    if (event.checked == true) {
      this.zoomToHole = true;
    }
    else {
      this.zoomToHole = false;
    }
    //console.log('after', this.zoomToHole)
  }

  // Select a hole from the slider
  public holeSliderChangedEvent(event) { 
    if (event.value === 0) {
      this.queryGameHole(99);
    }
    else {
      this.queryGameHole(event.value);
    }   
  }

  // View Created
  public viewCreatedEvent(view: any) {
    console.log('GameComponent viewCreatedEvent');
    const labelsOnly: boolean = true;
    this.view = view;




    // Add these layers to show callout labels and the extent of the hole
    this.courseService.loadGeneralHoleAreaLayer(this._is3D).then( layer => {
      view.map.add(layer);
    })

    // Only add the labels and not the polygons again (already in the webscene)
    this.courseService.loadFairwayLayer(this._is3D, labelsOnly).then( layer => {
      view.map.add(layer);
    })




    // // Load the base course and scene layers
    // this.courseService.loadBaseLayers(this._is3D, this._includeAllSurrounds, this._includeHillshade, this._includeNswProperty).then((layersArray) => {
    //   for (let layers of layersArray) {
    //     if (layers.length > 0) {
    //       view.map.addMany(layers);
    //     }
    //     else {
    //       view.map.add(layers);
    //     }
    //   } 
    // });  

    // this.courseService.load3dTrees().then( (layer) => {
    //   view.map.add(layer);
    // });

    // this.courseService.load3dBuildings().then( (layer) => {
    //   view.map.add(layer);
    // });

    // this.surroundsService.load3dSurroundingBuildings().then( (layer) => {
    //   view.map.add(layer);
    // });






    // Get the game details
    this.gamePlayService.loadGamePlayLayer(this._is3D).then( layers => {
      for (const layer of layers) {
        switch (layer.id.toUpperCase()) {
          case 'FEATURELAYERGAMEPLAY':
            //console.log('FEATURELAYERGAMEPLAY');
            this._featureLayerGamePlay = layer;
            view.map.add(layer);

            // Allow the layer to be filtered when options selected (without going back to GIS server)
            this.view.whenLayerView(this._featureLayerGamePlay).then((layerView) => {
              console.log('whenLayerView _featureLayerGamePlay');
              //console.log(layerView);
              this._layerViewGamePlay = layerView;
              // Filter initial view based on defaults
              this.queryFeatures();
            });
            break;

          case 'FEATURELAYERTEEMARKER':
            //console.log('FEATURELAYERTEEMARKER');
            this._featureLayerTeeMarker = layer;
            let queryFeatureTeeMarker = this._featureLayerTeeMarker.createQuery();
            queryFeatureTeeMarker.where = '1=1';
            queryFeatureTeeMarker.outFields = '*';

            this._featureLayerTeeMarker.queryFeatures(queryFeatureTeeMarker).then((response) => {
              //console.log('_featureLayerTeeMarker.queryFeatures response');
              //console.log(response.features);
              this._featuresTeeMarker = response.features;
            });
            break;

          case 'TABLECOURSEGUIDE':
            //console.log('TABLECOURSEGUIDE');
            this._featureTblCourseGuide = layer;

            // Define the query
            let queryTblCourseGuide = this._featureTblCourseGuide.createQuery();
            queryTblCourseGuide.where = '1=1';
            queryTblCourseGuide.outFields = '*';

            // Execute the query and get all the data
            this._featureTblCourseGuide.queryFeatures(queryTblCourseGuide).then((response) => {
              //console.log('_featureTblCourseGuide.queryFeatures response');
              //console.log(response.features);
              this._featuresCourseGuide = response.features;
            });
            break;

          case 'TABLEGAMEGUIDE':
            //console.log('TABLEGAMEGUIDE');
            this._featureTblGameGuide = layer;

            let queryTblGameGuide = this._featureTblGameGuide.createQuery();
            queryTblGameGuide.where = '1=1';
            queryTblGameGuide.outFields = '*';

            this._featureTblGameGuide.queryFeatures(queryTblGameGuide).then((response) => {
              //console.log('_featureTblGameGuide.queryFeatures response');
              //console.log(response.features);
              this._featuresGameGuide = response.features;
            });
            break;            
        }
      }

      this.loaded = true;
    });

    // Add the Info Panels
    this.view.ui.add([
      {
        component: this.infoPanelTitleEl.nativeElement, 
        position: 'top-left',
        index: 1
      }
    ]);

    // this.view.ui.add([
    //   {
    //     component: this.infoPanelGameChoiceEl.nativeElement, 
    //     position: 'top-left',
    //     index: 2
    //   }
    // ]);

    this.view.ui.add([
      {
        component: this.infoPanelHoleStatsEl.nativeElement, 
        position: 'top-left',
        index: 3
      }
    ]);

    this.view.ui.add([
      {
        component: this.infoPanelHoleChoiceEl.nativeElement, 
        position: 'bottom-left',
        index: 0
      }
    ]);






  }

  //
  // Data Functions
  //

  public queryGameGender(choice: string) {
    this.gameGender = choice;

    // Enable / disable gender buttons as required based on tee selected
    switch (this.gameGender) {    
      case 'M':

        if (this.gameTeeMarkerType === 'GOLD') {
          this.gameTeeMarkerType = 'BLACK';

          this.showLength_PAR = true;
          this.showLength_137 = false;
          this.showLength_150 = false;
          this.showLength_180 = false;
          this.showLength_192 = false;
        }

        if (this.gameTeeMarkerType === 'RED') {
          this.showLength_PAR = true;
          this.showLength_150 = true;
          this.showLength_137 = false;    
          this.showLength_180 = true;
          this.showLength_192 = false;
        }
      



        // Set Available Distances

        break;

      case 'F':
        if (this.gameTeeMarkerType === 'BLACK' || this.gameTeeMarkerType === 'WHITE') {
          this.gameTeeMarkerType = 'GOLD';

          this.showLength_PAR = true;
          this.showLength_137 = true;
          this.showLength_150 = true;
          this.showLength_180 = false;
          this.showLength_192 = true;
        }

        if (this.gameTeeMarkerType === 'RED') {
          this.showLength_PAR = true;
          this.showLength_150 = true;
          this.showLength_137 = true;    
          this.showLength_180 = false;
          this.showLength_192 = true;
        }


        break;
    }
    
    this.queryFeatures();
  }

  public queryTeeMarkerType(choice: string) {
    this.gameTeeMarkerType = choice;

    switch (choice) {
      case 'BLACK':
        this.showLength_PAR = true;
        this.showLength_137 = false;
        this.showLength_150 = false;
        this.showLength_180 = false;
        this.showLength_192 = false;
        break;

      case 'RED':
        this.showLength_PAR = true;
        this.showLength_150 = true;

        switch (this.gameGender) {    
          case 'M':
            this.showLength_137 = false;    
            this.showLength_180 = true;
            this.showLength_192 = false;
            break;

          case 'F':
            this.showLength_137 = true;    
            this.showLength_180 = false;
            this.showLength_192 = true;
            break;
        }
        break;

      case 'GOLD':
        this.showLength_PAR = true;
        this.showLength_137 = true;
        this.showLength_150 = true;
        this.showLength_180 = false;
        this.showLength_192 = true;
        break;
    }

    // Run Query
    this.queryFeatures();
  }

  public queryGameDriveLength(choice: string) {
    this.gameDriveLength = choice;
    this.queryFeatures();
  }

  public queryGameHole(choice: number) {
    this.choicePanelOpenState = true;
    this.holeStatsPanelCollapsed = false;
    this.gameFairwayNumber = choice;
    this.queryFeatures();
  }

  // Filter Results based on Query

  private queryFeatures() {

    // Modify where clause based on query
    this._whereClauseGamePlay = "GameGuideType = '" + this.gameTeeMarkerType + '_' + this.gameDriveLength + '_' + this.gameGender + "'";
    this._searchClauseTeeMarker =  this.gameTeeMarkerType;
    this._searchClauseCourseGuide = this.gameTeeMarkerType;
    this._searchClauseGameGuide = this.gameTeeMarkerType + '_' + this.gameDriveLength + '_' + this.gameGender;

    // console.log(this._whereClauseGamePlay);
    // console.log(this._searchClauseTeeMarker);
    // console.log(this._searchClauseCourseGuide);
    // console.log(this._searchClauseGameGuide);

    if (this.gameFairwayNumber <= 18) {
      // Holes 1 to 18
      this._whereClauseGamePlay += " and FairwayNumber = " + this.gameFairwayNumber;
    }

    // Filter the feature layer based on the selections 
    this._layerViewGamePlay.filter = {
      where: this._whereClauseGamePlay 
    }
    //console.log('GamePlay');
    //console.log(this._layerViewGamePlay);

    // Filter the data layers
    this._selectedFeaturesCourseGuide = this.getCourseGuideFeature(this._searchClauseCourseGuide);
    //console.log('Game');
    //console.log(this._selectedFeaturesGame);

    // Get the hole details when "99" (All) is not selected
    if (this.gameFairwayNumber <= 18) {
      this._selectedFeaturesTeeMarker = this.getTeeMarkerFeature(this._searchClauseTeeMarker, this.gameFairwayNumber);
      this._selectedFeaturesGameGuide = this.getGameGuideFeature(this._searchClauseGameGuide, this.gameFairwayNumber);

      // Zoom to defined hole camera setting (if enabled)
      if (this.zoomToHole) {
        this.goToCameraPosition();
      }
      else {
        console.log('zoomToHole disabled');
      }

      // Enable / disable action buttons as appropriate depending if content is available
      if (this.selectedFeaturesGameGuide.Guide) {
        this.gameGuideInfoButtonDisabled = false;
      }
      else {
        this.gameGuideInfoButtonDisabled = true;
      } 

      if (this.selectedFeaturesGameGuide.FlyOverVideoUrl) {
        this.flyoverVideoButtonDisabled = false;
      }
      else {
        this.flyoverVideoButtonDisabled = true;
      }

      if (this.selectedFeaturesGameGuide.GuideVideoUrl) {
        this.gameGuideVideoButtonDisabled = false;
      }
      else {
        this.gameGuideVideoButtonDisabled = true;
      }     
    }
    else {
      this._selectedFeaturesTeeMarker = null;
      this._selectedFeaturesGameGuide = null;
    }
  }

  private getTeeMarkerFeature(searchGame: string, searchHole: number) :any {
    //console.log('getTeeMarkerFeature()');
    //console.log(searchGame); 
    //console.log(searchHole);
    let result = [];

    this._featuresTeeMarker.forEach (feature =>  { 
      //console.log(feature);
      if (feature.attributes.TeeMarker === searchGame && feature.attributes.FairwayNumber === searchHole) {
        //console.log(feature.attributes);
        result = feature.attributes;
      }
    });
    return result;
  }

  private getCourseGuideFeature(search: string) :any {
    //console.log('getCourseGuideFeature()');
    //console.log(search); 
    let result: any = [];

    this._featuresCourseGuide.forEach (feature =>  { 
      if (feature.attributes.TeeMarker === search) {
        //console.log(feature.attributes);
        result = feature.attributes;
      }
    });
    return result;
  }

  private getGameGuideFeature(searchGame: string, searchHole: number) :any {
    //console.log('getGameGuideFeature()');
    //console.log(searchGame); 
    //console.log(searchHole);
    //console.log(this._featuresGameGuide);
    let result = [];

    this._featuresGameGuide.forEach (feature =>  { 
      if (feature.attributes.GameGuideType === searchGame && feature.attributes.FairwayNumber === searchHole) {
        //console.log(feature.attributes);
        result = feature.attributes;
      }
    });
    return result;
  }

  //
  // Dialogs
  //

  public openGameInfoDialog() {
    if (this.selectedFeaturesGameGuide.Guide) {

      this.dialog.open(GameInfoComponent, {
        data: {
          title: 'Game Guide for Hole ' + this.selectedFeaturesGameGuide.FairwayNumber,
          guide: this.selectedFeaturesGameGuide.Guide
        }
      });
    }
    else {
      console.log('openGameInfoDialog() : selectedFeaturesGameGuide has no guide description');
    }
  }

  public openGameFlyoverDialog() {
    //console.log(this.selectedFeaturesGameGuide.FlyOverVideoUrl);
    if (this.selectedFeaturesGameGuide.FlyOverVideoUrl) {
      // The YouTube player only wants the id
      let youTubeId = Global.getYouTubeId(this.selectedFeaturesGameGuide.FlyOverVideoUrl);
      //console.log(youTubeId);

      this.dialog.open(GameFlyoverComponent, {
        data: {
          url: youTubeId,
          title: 'Flyover for Hole ' + this.selectedFeaturesGameGuide.FairwayNumber,
          width: '1280',
          height: '760'
        }
      });
    }
    else {
      console.log('openGameFlyoverDialog() : selectedFeaturesGameGuide has no flyover video');
    }
  }

  public openGameInfoVideoDialog() {
    if (this.selectedFeaturesGameGuide.GuideVideoUrl) {
      this.dialog.open(GameInfoVideoComponent, {
        data: {
          url: this.selectedFeaturesGameGuide.GuideVideoUrl,
          title: 'Guide for Hole ' + this.selectedFeaturesGameGuide.FairwayNumber //,
          // width: '1280',
          // height: '760'
        }
      });
    }
    else {
      console.log('openGameInfoVideoDialog() : selectedFeaturesGameGuide has no game guide video');
    }
  }

  public openGameNotesDialog() {
    this.dialog.open(GameNotesComponent, {
      data: {
        title: 'Notes for Hole ' + this.selectedFeaturesGameGuide.FairwayNumber,
        gameTeeMarkerType: this.gameTeeMarkerType,
        gameDriveLength: this.gameDriveLength,
        gameFairwayNumber: this.selectedFeaturesGameGuide.FairwayNumber,
        gameGender: this.gameGender
      }
    });
  }

  public openMapGalleryBottomSheet(): void {
    this._bottomSheet.open(HoleGalleryComponent, {
      data: {
        title: 'Map Gallery for Hole ' + this.gameFairwayNumber,
        gameGender: this.gameGender,
        gameTeeMarkerType: this.gameTeeMarkerType,
        gameDriveLength: this.gameDriveLength,
        gameFairwayNumber: this.gameFairwayNumber
      }
    });
  }

  //
  //
  //

  public goToCameraPosition() {
    //console.log('goToCameraPosition');
    //console.log(this._selectedFeaturesGameGuide);
    
    if (this._selectedFeaturesGameGuide.ViewCamera_Heading) {
      let settings: CameraSettings = {
        heading: this._selectedFeaturesGameGuide.ViewCamera_Heading,
        tilt: this._selectedFeaturesGameGuide.ViewCamera_Tilt,
        longitude: this._selectedFeaturesGameGuide.ViewCamera_Latitude,
        latitude:this._selectedFeaturesGameGuide.ViewCamera_Longitude,
        z: this._selectedFeaturesGameGuide.ViewCamera_Altitude
      };
      //console.log('GameGuide settings');
      //console.log(settings);
      Esri.goToCameraPosition(this.view, settings);
    }
    else {
      console.log('No ViewCamera settings');
    }
  }

  private setSceneQuality(highQuality: boolean) {
    if (highQuality) {

      this.highQuality = true;
      // Display high quality scene graphics
      this.directShadows = true;
      this.waterReflection = true;
      this.stars = true;
      this.atmosphere = true;
      this.atmosphereQuality = 'high';    // "low" | "high"
      this.qualityProfile = 'high';       // "low" | "medium" | "high"
    }
    else {
      // Display low quality graphics; disable stars, water reflections, and atmosphere
      this.highQuality = false;
      this.directShadows = false;
      this.waterReflection = false;
      this.stars = false;
      this.atmosphere = false;
      this.atmosphereQuality = 'low';
      this.qualityProfile = 'low'; 
    }
  }


/*

  public subHeadingText: string = '';
  private _dataUrl: any;

  // Elements
  @ViewChild('divScreenshot', { static: true }) private _divScreenshotEl: ElementRef;
  @ViewChild('imgScreenshot', { static: true }) private _imgScreenShotEl: ElementRef;

  public takeScreenShot() {

    let options = {
      format: "jpg",
      quality: 100,
      //width: this._view.width * 0.8,
      //height: this._view.height * 0.95
      width: this.view.width,
      height: this.view.width * 0.7116
    };

    this.view.takeScreenshot(options).then( (screenshot) => {

      //console.log('screenshot.dataUrl');
      //console.log(this._screenShotEl.nativeElement);
      //console.log(screenshot.dataUrl);

      
      //this._imgScreenShotEl.nativeElement.src = screenshot.dataUrl;


      // display a preview of the image
      this.showPreview(screenshot);
      

    });
  }

  // creates an image that will be appended to the DOM
  // so that users can have a preview of what they will download
  private showPreview(screenshot) {

    

    this._divScreenshotEl.nativeElement.classList.remove("hide");

    //screenshotDiv.classList.remove("hide");

    // add the screenshot dataUrl as the src of an image element
    // const screenshotImage = document.getElementsByClassName(
    //   "js-screenshot-image"
    // )[0];

    this._imgScreenShotEl.nativeElement.width = screenshot.data.width;
    this._imgScreenShotEl.nativeElement.height = screenshot.data.height;
    this._imgScreenShotEl.nativeElement.src = screenshot.dataUrl;


    this._dataUrl = screenshot.dataUrl;
    //this.downloadImage(this.subHeadingText + ".jpg", screenshot.dataUrl);


  }

  public closePreview() {
    this._divScreenshotEl.nativeElement.classList.add("hide");
  }

  public downloadImage() { //filename, dataUrl
    // the download is handled differently in Microsoft browsers
    // because the download attribute for <a> elements is not supported

    let filename = this.subHeadingText + '.jpg';

    if (!window.navigator.msSaveOrOpenBlob) {
      // in browsers that support the download attribute
      // a link is created and a programmatic click will trigger the download
      const element = document.createElement("a");
      element.setAttribute("href", this._dataUrl);
      element.setAttribute("download", filename);
      element.style.display = "none";
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    } else {
      // for MS browsers convert dataUrl to Blob
      const byteString = atob(this._dataUrl.split(",")[1]);
      const mimeString = this._dataUrl
        .split(",")[0]
        .split(":")[1]
        .split(";")[0];
      const ab = new ArrayBuffer(byteString.length);
      const ia = new Uint8Array(ab);
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      const blob = new Blob([ab], { type: mimeString });

      // download file
      window.navigator.msSaveOrOpenBlob(blob, filename);
    }
  }

*/


}
