import { Injectable } from '@angular/core';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Injectable({
  providedIn: 'root'
})

export class PdfService {

  public pdfMake: any;

  // public fonts = {
  //   Courier: {
  //     normal: 'Courier',
  //     bold: 'Courier-Bold',
  //     italics: 'Courier-Oblique',
  //     bolditalics: 'Courier-BoldOblique'
  //   },
  //   Helvetica: {
  //     normal: 'Helvetica',
  //     bold: 'Helvetica-Bold',
  //     italics: 'Helvetica-Oblique',
  //     bolditalics: 'Helvetica-BoldOblique'
  //   },
  //   Times: {
  //     normal: 'Times-Roman',
  //     bold: 'Times-Bold',
  //     italics: 'Times-Italic',
  //     bolditalics: 'Times-BoldItalic'
  //   },
  //   Symbol: {
  //     normal: 'Symbol'
  //   },
  //   ZapfDingbats: {
  //     normal: 'ZapfDingbats'
  //   }
  // };

  constructor() { }


  async loadPdfMaker() {
    if (!this.pdfMake) {
      const pdfMakeModule = await import('pdfmake/build/pdfmake');
      const pdfFontsModule = await import('pdfmake/build/vfs_fonts');
      this.pdfMake = pdfMakeModule.default;
      this.pdfMake.vfs = pdfFontsModule.default.pdfMake.vfs;
    }
  }
  
  async generatePdf(def: any) {
    await this.loadPdfMaker();
    //const def = { content: 'A sample PDF document generated using Angular and PDFMake' };
    this.pdfMake.createPdf(def).open();
  }

  // async createPdf(def: any) {
  //   await this.loadPdfMaker();
  //   var PdfPrinter = require('pdfmake');
  //   var printer = new PdfPrinter(this.fonts);
  //   var fs = require('fs');

  //   var pdfDoc = printer.createPdfKitDocument(def);
  //   pdfDoc.pipe(fs.createWriteStream('document.pdf'));
  //   pdfDoc.end();
  // }





}
