import { Injectable, Output, EventEmitter } from '@angular/core';
import { combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { getTime, toDate, format, isBefore, differenceInCalendarDays, differenceInBusinessDays } from 'date-fns';
import { Esri, FormFieldConfig, FieldConfig, FieldDateFormat, FieldNumberFormat, Domain, SubDomain } from 'src/app/_esri/models/esri';
import { LayerIds } from 'src/app/_esri/models/layer-ids';
import { Global } from 'src/app/_globals/models/global';
import { LovDataService } from 'src/app/_globals/services/lov-data.service';
import { DomainLovService } from 'src/app/_esri/services/domain-lov.service';
import { StaffLOV, ContactBase, ContactBaseAttributes } from 'src/app/_esri/models/common-feature-class';
import { FeatureLayerObjectBaseAttributes, FeatureLayerObjectBase } from 'src/app/_esri/models/base-feature-class';

// Feature Layers

export class WorkRequestAttributes extends FeatureLayerObjectBaseAttributes {
  public WorkRequestId: string;
  public WorkRequestTitle: string;
  public WorkRequestDate: Date;
  public WorkRequestCategoryId: string;
  public WorkRequestSubCategoryId: string;
  public WorkRequestDetails: string;
  public AuditId: string;
  public IncidentId: string;
  public ReportedBy: string;
  public Priority: number;
  public Responsible: string;
  public AssignedTo: string;
  public ActionTaken: string;
  public SLADays: number;
  public SLADueDate: Date;
  public FairwayNumber: number;
  public EstimatedCost: number;
  public ActualCost: number;
  public Source: string;
  public Status: number;
  public CompletedDate: Date;
  public Comments: string;
  public WorkForce_AssignmentGlobalId: string;

  constructor(
    protected lovDataService: LovDataService,
    protected domainLovService: DomainLovService
  ) {
    super(
      lovDataService,
      domainLovService
    );
  }

  // Virtual Fields

  get Category(): string {
    if (this.WorkRequestCategoryId) {
      return this.domainLovService.getDomainLovValue('LovWorkRequestCategory', this.WorkRequestCategoryId);
    }
    else {
      return null;
    }
  }

  get SubCategory(): string {
    if (this.WorkRequestCategoryId && this.WorkRequestSubCategoryId) {
      //console.log('DomainLovService.domains_lov', DomainLovService.domains_lov);
      return this.domainLovService.getSubDomainLovValue('LovWorkRequestSubCategory', this.WorkRequestCategoryId, this.WorkRequestSubCategoryId);
    }
    else {
      return null;
    }
  }

  get ResponsibleStaffName(): string {
    if (this.Responsible) {
      return this.domainLovService.getDomainLovValue('LovStaff', this.Responsible);
    }
    else {
      return null;
    }
  };

  get AssignedToStaffName(): string {
    if (this.AssignedTo) {
      return this.domainLovService.getDomainLovValue('LovStaff', this.AssignedTo);
    }
    else {
      return null;
    }
  };

  get Priority_DomainDesc() {
    if (this.Priority) {
      return this.domainLovService.getDomainValue('DomPriority', this.Priority);
    }
    else {
      return null;
    }
  }

  get Source_DomainDesc() {
    if (this.Source) {
      return this.domainLovService.getDomainValue('DomSource', this.Source);
    }
    else {
      return null;
    }
  }

  get Status_DomainDesc() {
    if (this.Status) {
      return this.domainLovService.getDomainValue('DomStatus', this.Status);
    }
    else {
      return null;
    }
  }

  get Fairway_DomainDesc() {
    if (this.FairwayNumber) {
      return this.domainLovService.getDomainValue('DomFairway', this.FairwayNumber);
    }
    else {
      return null;
    }
  }

  get Overdue(): string {
    let today: Date = new Date; //Date.now;
    let now = getTime(today);

    if (this.SLADueDate) {
      if (!this.CompletedDate) {
        if (isBefore(this.SLADueDate, now)) {
          //this._Overdue = 'YES';
          return 'YES';
        }
        else {
          //this._Overdue = 'NO';
          return 'NO';
        }
      }
      else {
        if (isBefore(this.CompletedDate, this.SLADueDate)) {
          return 'NO';
        }
        else {
          return 'YES';
        }
      };
    }
    else {
      return null;
    }
  }

  get CalenderDaysOverdue(): number {
    let today: Date = new Date; //Date.now;
    let now = getTime(today);

    if (this.Overdue === 'YES') {
      return differenceInCalendarDays(now, this.SLADueDate);
    }
    else {
      return null;
    }
  }

  get BusinessDaysOverdue(): number {
    let today: Date = new Date; //Date.now;
    let now = getTime(today);

    if (this.Overdue === 'YES') {
      return differenceInBusinessDays(now, this.SLADueDate);
    }
    else {
      return null;
    }
  }

  get CompletedWithinSLA(): string {
    if (this.SLADueDate) {
      if (this.CompletedDate) {
        if (isBefore(this.CompletedDate, this.SLADueDate)) {
          //this._CompletedWithinSLA = 'YES';
          return 'YES';
        }
        else {
          //this._CompletedWithinSLA = 'NO';
          return 'NO';
        }
      }
      else {
        return null;
      }
    }
    else {
      return null;
    }
  }
}

@Injectable()
export class WorkRequest extends FeatureLayerObjectBase {
  attributes: WorkRequestAttributes[];
  server = LayerIds.gisServer_Maintenance;
  folder = LayerIds.folder_Maintenance;
  layerId = LayerIds.layerId_WorkRequest;
  id = 'featureLayerWorkRequest';
  title = 'Work Request';

  minScale = 10000;
  whereClause = "1=1";

  labelInfo = {
    symbol: Esri.text12pxWithHalo,
    labelExpressionInfo: {
      expression: "$feature.OBJECTID"
    },
    maxScale: 0,
    minScale: 4000,
    where: "1=1"
  };

  labelsVisible = false;
  labelingInfo = this.labelInfo;
  // popupEnabled = true;
  // legendEnabled = true;

  idType = 'MAINT';
  idTypeFieldName = 'WorkRequestId';
  fieldChangeWatchList = ['Status', 'CompletedDate', 'WorkRequestSubCategoryId'];
  parentLovWatchList = [{ parentLovKey: 'WorkRequestCategoryId', childLovName: 'LovWorkRequestSubCategory', childLovKey: 'WorkRequestSubCategoryId' }];

  @Output() categoryLovReadyEvent = new EventEmitter<any>();
  @Output() subCategoryLovReadyEvent = new EventEmitter<any>();
  @Output() reportedByLovReadyEvent = new EventEmitter<any>();
  @Output() staffLovReadyEvent = new EventEmitter<any>();

  protected async createFormFieldConfigs(): Promise<FormFieldConfig[]> {
    let formFieldConfig: FormFieldConfig;
    let fieldConfigs: FieldConfig[] = [];
    let fieldConfig: FieldConfig;
    let format: any;

    // Description

    fieldConfigs = [];

    fieldConfig = new FieldConfig('WorkRequestId', 'Request ID:', 'text');
    fieldConfig.required = false;
    fieldConfig.readOnly = true;
    fieldConfig.maxLength = 20;
    fieldConfig.includeInTable = true;
    fieldConfig.includeInTableIndex = 0;
    fieldConfig.includeInCard = true;
    fieldConfig.includeInCard_SubTitle = true;
    fieldConfig.includeInCard_Index = 1;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('WorkRequestTitle', 'Request Title:', 'text');
    fieldConfig.required = true;
    fieldConfig.maxLength = 200;
    fieldConfig.includeInTable = true;
    fieldConfig.includeInTableIndex = 1;
    fieldConfig.includeInCard = true;
    fieldConfig.includeInCard_Title = true;
    fieldConfig.includeInCard_Index = 0;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('WorkRequestDate', 'Request Date:', 'date');
    fieldConfig.required = true;
    format = new FieldDateFormat();
    fieldConfig.format = format;
    fieldConfig.defaultValue = 'date-now';
    fieldConfig.includeInTable = true;
    fieldConfig.includeInTableIndex = 2;
    fieldConfig.includeInCard = true;
    fieldConfig.includeInCard_SubTitle = true;
    fieldConfig.includeInCard_Index = 2;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('WorkRequestCategoryId', 'Category:', 'text');
    fieldConfig.required = true;
    fieldConfig.maxLength = 20;
    fieldConfig.includeInTable = true;
    fieldConfig.includeInTableIndex = 3;
    fieldConfig.includeInCard = true;
    fieldConfig.includeInCard_Content_Row1 = true;
    fieldConfig.includeInCard_Index = 3;
    fieldConfig.domain_lov = 'LovWorkRequestCategory';
    fieldConfig.domain_VirtualFieldName = 'Category';
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('WorkRequestSubCategoryId', 'Sub-category:', 'text');
    fieldConfig.required = true;
    fieldConfig.maxLength = 20;
    fieldConfig.includeInTable = true;
    fieldConfig.includeInTableIndex = 4;
    fieldConfig.includeInCard = true;
    fieldConfig.includeInCard_Content_Row1 = true;
    fieldConfig.includeInCard_Index = 4;
    fieldConfig.domain_lovParent = 'LovWorkRequestCategory';
    fieldConfig.domain_lovParent_FieldName = 'WorkRequestCategoryId';
    fieldConfig.domain_lov = 'LovWorkRequestSubCategory';
    fieldConfig.domain_VirtualFieldName = 'SubCategory';
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('WorkRequestDetails', 'Details:', 'text-area');
    fieldConfig.maxLength = 2000;
    fieldConfig.includeInCard = true;
    fieldConfig.includeInCard_Content_Row3 = true;
    fieldConfig.includeInCard_Index = 5;
    fieldConfigs.push(fieldConfig);
    fieldConfig = new FieldConfig('Source', 'Source:', 'text');
    fieldConfig.domain = 'DomSource';
    fieldConfig.domain_VirtualFieldName = 'Source_DomainDesc';
    fieldConfig.defaultValue = 'INSPECTION';
    fieldConfig.visible = true;
    fieldConfigs.push(fieldConfig);

    formFieldConfig = new FormFieldConfig('Description', fieldConfigs);
    this.formFieldConfigs.push(formFieldConfig);

    // Details

    fieldConfigs = [];

    fieldConfig = new FieldConfig('AuditId', 'Audit ID:', 'text');
    fieldConfig.required = false;
    fieldConfig.readOnly = true;
    fieldConfig.maxLength = 20;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('IncidentId', 'Incident ID:', 'text');
    fieldConfig.required = false;
    fieldConfig.readOnly = true;
    fieldConfig.maxLength = 20;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('ReportedBy', 'Reported By:', 'text');
    fieldConfig.required = false;
    fieldConfig.maxLength = 20;
    fieldConfig.domain_lov = 'LovWorkRequestReportedBy';
    fieldConfig.domain_VirtualFieldName = 'ReportedBy';
    fieldConfig.includeInCard = true;
    fieldConfig.includeInCard_Content_Row2 = true;
    fieldConfig.includeInCard_Index = 6;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('Priority', 'Priority:', 'radio-v');
    fieldConfig.domain = 'DomPriority';
    fieldConfig.domain_VirtualFieldName = 'Priority_DomainDesc';
    fieldConfig.defaultValue = 2;
    fieldConfig.visible = true;

    fieldConfig.includeInCard = true;
    fieldConfig.includeInCard_SubTitle = true;
    fieldConfig.includeInCard_Index = 3;

    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('Responsible', 'Responsible:', 'text');
    fieldConfig.required = true;
    fieldConfig.maxLength = 20;
    fieldConfig.domain_lov = 'LovStaff';
    fieldConfig.domain_VirtualFieldName = 'ResponsibleStaffName';
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('AssignedTo', 'Assigned To:', 'text');
    fieldConfig.required = true;
    fieldConfig.maxLength = 20;
    fieldConfig.domain_lov = 'LovStaff';
    fieldConfig.domain_VirtualFieldName = 'AssignedToStaffName';
    fieldConfig.includeInCard = true;
    fieldConfig.includeInCard_Content_Row2 = true;
    fieldConfig.includeInCard_Index = 7;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('ActionTaken', 'Actions Taken:', 'text-area');
    fieldConfig.maxLength = 2000;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('SLADays', 'SLA Days:', 'number');
    format = new FieldNumberFormat(0);
    fieldConfig.format = format;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('SLADueDate', 'Due Date:', 'date');
    format = new FieldDateFormat();
    fieldConfig.format = format;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('FairwayNumber', 'Fairway Number', 'number');
    fieldConfig.domain = 'DomFairway'
    fieldConfig.domain_VirtualFieldName = 'Fairway_DomainDesc';
    fieldConfig.visible = true;
    fieldConfigs.push(fieldConfig);

    formFieldConfig = new FormFieldConfig('Details', fieldConfigs);
    this.formFieldConfigs.push(formFieldConfig);

    // Completion

    fieldConfigs = [];

    fieldConfig = new FieldConfig('EstimatedCost', 'Estimated Cost:', 'number');
    format = new FieldNumberFormat(2);
    //fieldConfig.prefix = '$';
    fieldConfig.format = format;
    fieldConfig.visible = false;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('ActualCost', 'Actual Cost:', 'number');
    format = new FieldNumberFormat(2);
    //fieldConfig.prefix = '$';
    fieldConfig.format = format;
    fieldConfig.visible = false;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('Status', 'Status:', 'radio-v');
    fieldConfig.maxLength = 20;
    fieldConfig.domain = 'DomStatus';
    fieldConfig.domain_VirtualFieldName = 'Status_DomainDesc'
    fieldConfig.defaultValue = 2;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('CompletedDate', 'Completed Date:', 'date');
    fieldConfig.required = false;
    format = new FieldDateFormat();
    fieldConfig.format = format;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('Comments', 'Comments:', 'text-area');
    fieldConfigs.push(fieldConfig);

    formFieldConfig = new FormFieldConfig('Completion', fieldConfigs);
    this.formFieldConfigs.push(formFieldConfig);

    // Virtual (and hidden)

    fieldConfigs = [];

    fieldConfig = new FieldConfig('Overdue', 'Overdue:', 'text');
    fieldConfig.domain = 'DomYesNo';
    fieldConfig.readOnly = true;
    fieldConfig.visible = false;
    //fieldConfig.includeInCard = true;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('CompletedWithinSLA', 'CompletedWithinSLA:', 'text');
    fieldConfig.domain = 'DomYesNo';
    fieldConfig.readOnly = true;
    fieldConfig.visible = false;
    //fieldConfig.includeInCard = true;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('ResponsibleStaffName', 'ResponsibleStaffName:', 'text');
    fieldConfig.readOnly = true;
    fieldConfig.visible = false;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('AssignedToStaffName', 'AssignedToStaffName:', 'text');
    fieldConfig.readOnly = true;
    fieldConfig.visible = false;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('CalenderDaysOverdue', 'CalenderDaysOverdue:', 'text');
    fieldConfig.readOnly = true;
    fieldConfig.visible = false;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('BusinessDaysOverdue', 'BusinessDaysOverdue:', 'text');
    fieldConfig.readOnly = true;
    fieldConfig.visible = false;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('Category', 'Category:', 'text');
    fieldConfig.readOnly = true;
    fieldConfig.visible = false;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('SubCategory', 'Sub-Category:', 'text');
    fieldConfig.readOnly = true;
    fieldConfig.visible = false;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('Priority_DomainDesc', 'Priority:', 'text');
    fieldConfig.readOnly = true;
    fieldConfig.visible = false;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('Source_DomainDesc', 'Source:', 'text');
    fieldConfig.readOnly = true;
    fieldConfig.visible = false;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('Status_DomainDesc', 'Status:', 'text');
    fieldConfig.readOnly = true;
    fieldConfig.visible = false;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('Fairway_DomainDesc', 'Fairway:', 'text');
    fieldConfig.readOnly = true;
    fieldConfig.visible = false;
    fieldConfigs.push(fieldConfig);

    formFieldConfig = new FormFieldConfig('Virtual', fieldConfigs);
    this.formFieldConfigs.push(formFieldConfig);

    // WorkForce Integration (hidden)

    fieldConfigs = [];

    fieldConfig = new FieldConfig('WorkForce_AssignmentGlobalId', 'WorkForce_AssignmentGlobalId', 'text');
    fieldConfig.maxLength = 38;
    fieldConfig.editable = false;
    fieldConfig.visible = false;
    fieldConfigs.push(fieldConfig);

    formFieldConfig = new FormFieldConfig('WorkForce', fieldConfigs);
    formFieldConfig.visible = false;
    this.formFieldConfigs.push(formFieldConfig);

    super.createFormFieldConfigs();
    return this.formFieldConfigs;       
  }

  protected updateVirtualFields() {
    //console.log('updateAttributesWithVirtualFields this.features, this.attributes', this.features, this.attributes);

    // Reset
    this.attributes = [];

    // :(  I have to map each of these attributes individually otherwise the virtual fields don't work. v annoying
    // attr = f.attributes; doesn't work
    this.features.forEach((f) => {
      let attr: WorkRequestAttributes = new WorkRequestAttributes(this.lovDataService, this.domainLovService);
      //attr = f.attributes;

      attr.ActionTaken = f.attributes.ActionTaken;
      attr.ActualCost = f.attributes.ActualCost;
      attr.AssignedTo = f.attributes.AssignedTo;
      attr.AuditId = f.attributes.AuditId;
      attr.Comments = f.attributes.Comments;
      attr.CompletedDate = f.attributes.CompletedDate;
      attr.EstimatedCost = f.attributes.EstimatedCost;
      attr.FairwayNumber = f.attributes.FairwayNumber;
      attr.IncidentId = f.attributes.IncidentId;
      attr.Priority = f.attributes.Priority;
      attr.ReportedBy = f.attributes.ReportedBy;
      attr.Responsible = f.attributes.Responsible;
      attr.SLADays = f.attributes.SLADays;
      attr.SLADueDate = f.attributes.SLADueDate;
      attr.Source = f.attributes.Source;
      attr.Status = f.attributes.Status;

      attr.WorkForce_AssignmentGlobalId = f.attributes.WorkForce_AssignmentGlobalId;
      attr.WorkRequestCategoryId = f.attributes.WorkRequestCategoryId;
      attr.WorkRequestDate = f.attributes.WorkRequestDate;
      attr.WorkRequestDetails = f.attributes.WorkRequestDetails;
      attr.WorkRequestId = f.attributes.WorkRequestId;
      attr.WorkRequestSubCategoryId = f.attributes.WorkRequestSubCategoryId;
      attr.WorkRequestTitle = f.attributes.WorkRequestTitle;

      // Common
      attr.OBJECTID = f.attributes.OBJECTID;
      attr.ClubId = f.attributes.ClubId;
      attr.Active = f.attributes.Active;
      attr.GlobalID = f.attributes.GlobalID;
      attr.HasLocation = f.attributes.HasLocation;
      attr.CreationDate = f.attributes.CreationDate;
      attr.Creator = f.attributes.Creator;
      attr.EditDate = f.attributes.EditDate;
      attr.Editor = f.attributes.Editor;

      // Populate virtual / calculated fields
      f.attributes.Overdue = attr.Overdue;
      f.attributes.CompletedWithinSLA = attr.CompletedWithinSLA;
      f.attributes.CalenderDaysOverdue = attr.CalenderDaysOverdue;
      f.attributes.BusinessDaysOverdue = attr.BusinessDaysOverdue;
      f.attributes.Category = attr.Category;
      f.attributes.SubCategory = attr.SubCategory;
      f.attributes.ResponsibleStaffName = attr.ResponsibleStaffName;
      f.attributes.AssignedToStaffName = attr.AssignedToStaffName;

      f.attributes.Priority_DomainDesc = attr.Priority_DomainDesc;
      f.attributes.Source_DomainDesc = attr.Source_DomainDesc;
      f.attributes.Status_DomainDesc = attr.Status_DomainDesc;
      f.attributes.Fairway_DomainDesc = attr.Fairway_DomainDesc;

      // console.log('Virtual class fields', 
      //   attr.Overdue, 
      //   attr.CompletedWithinSLA, 
      //   attr.BusinessDaysOverdue, 
      //   attr.CalenderDaysOverdue, 
      //   attr.Category, 
      //   attr.SubCategory, 
      //   attr.ResponsibleStaffName, 
      //   attr.AssignedToStaffName
      // );

      this.attributes.push(attr);
    });

  }

  protected getLovs() {
    // All LOV Domains
    combineLatest([
      this.categoryLovReadyEvent,
      this.subCategoryLovReadyEvent,
      this.reportedByLovReadyEvent,
      this.staffLovReadyEvent
    ]).pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      console.log('WorkRequest/FeatureBase getLovs() lovsReady()');
      this.lovReadyEvent.emit(true);
    });

    this.domains_lov = [];
    this.domains_lovSubDomain = [];

    let workRequestCategoryLOV: WorkRequestCategoryLOV = new WorkRequestCategoryLOV();
    workRequestCategoryLOV.getFeatureLayerConfigAndData().then(() => {
      workRequestCategoryLOV.getDomain('LovWorkRequestCategory', 'coded-value').then((domain) => {
        this.domains_lov.push(domain);
        //console.log('getLovs() categoryLovReadyEvent.emit');
        this.categoryLovReadyEvent.emit();
      });
    });

    let workRequestSubCategoryLOV: WorkRequestSubCategoryLOV = new WorkRequestSubCategoryLOV();
    workRequestSubCategoryLOV.getFeatureLayerConfigAndData().then(() => {
      workRequestSubCategoryLOV.getSubDomain('LovWorkRequestSubCategory', 'coded-value').then((domain) => {
        this.domains_lovSubDomain.push(domain);
        //console.log('getLovs() subCategoryLovReadyEvent.emit');
        this.subCategoryLovReadyEvent.emit();
      });
    });

    let workRequestReportedByLOV: WorkRequestReportedByLOV = new WorkRequestReportedByLOV();
    workRequestReportedByLOV.getFeatureLayerConfigAndData().then(() => {
      workRequestReportedByLOV.getDomain('LovWorkRequestReportedBy', 'coded-value').then((domain) => {
        this.domains_lov.push(domain);
        //console.log('getLovs() reportedByLovReadyEvent.emit');
        this.reportedByLovReadyEvent.emit();
      });
    });

    let staffLOV: StaffLOV = new StaffLOV();
    staffLOV.getFeatureLayerConfigAndData().then(() => {
      staffLOV.getDomain('LovStaff', 'coded-value').then((domain) => {
        this.domains_lov.push(domain);
        //console.log('getLovs() staffLovReadyEvent.emit');
        this.staffLovReadyEvent.emit();
      });
    });
  }
}

export class WorkRequestAssetAttributes extends FeatureLayerObjectBaseAttributes {

  public AssetClass: string;
  public AssetCategory: string;
  public AssetType: string;
  public AssetId: string;
  public AssetDescription: string;
  public WorkRequestId: string;
  public WorkRequest_GlobalId: string;
}

export class WorkRequestAsset extends FeatureLayerObjectBase {
  attributes: WorkRequestAssetAttributes[];
  server = LayerIds.gisServer_Maintenance;
  folder = LayerIds.folder_Maintenance;
  layerId = LayerIds.layerId_TblWorkRequest_Asset;
  id = 'featureLayerWorkRequestAsset';
  title = 'Work Request Assets';

  minScale = 10000;
  // labelsVisible = true;
  labelingInfo = this.labelInfo;
  // popupEnabled = true;
  // legendEnabled = true;

  protected labelInfo = {
    symbol: Esri.text12pxWithHalo,
    labelExpressionInfo: {
      expression: "$feature.AssetId"
    },
    maxScale: 0,
    minScale: 4000,
    where: "1=1"
  };

  public static async createFormFieldConfig(): Promise<FormFieldConfig[]> {

    let formFieldConfigs: FormFieldConfig[] = [];
    let formFieldConfig: FormFieldConfig;
    let fieldConfigs: FieldConfig[] = [];
    let fieldConfig: FieldConfig;
    let format: any;
    //let whereClause: string = '';
    //let outFields: string[] = [];

    // Description

    fieldConfigs = [];

    fieldConfig = new FieldConfig('AssetClass', 'Asset Class', 'text');
    fieldConfig.maxLength = 20;
    //fieldConfig.domain = 'DomAssetClass';
    fieldConfig.visible = false;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('AssetCategory', 'Asset Category', 'text');
    fieldConfig.maxLength = 20;
    //fieldConfig.domain = assetCategoryDomain;
    fieldConfig.visible = false;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('AssetType', 'Asset Type:', 'text');
    fieldConfig.maxLength = 20;
    //fieldConfig.label = assetTypeLabel;
    //fieldConfig.domain = assetTypeDomain;
    fieldConfig.visible = false;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('AssetId', 'Asset Id:', 'text');
    fieldConfig.maxLength = 20;
    fieldConfig.visible = false;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('AssetDescription', 'Description:', 'text');
    fieldConfig.maxLength = 100;
    fieldConfig.visible = true;
    fieldConfigs.push(fieldConfig);

    formFieldConfig = new FormFieldConfig('Description', fieldConfigs);
    formFieldConfigs.push(formFieldConfig);

    // WorkForce Integration (hidden)

    fieldConfigs = [];

    fieldConfig = new FieldConfig('WorkRequest_GlobalId', 'WorkRequest_GlobalId', 'text');
    fieldConfig.maxLength = 38;
    fieldConfig.editable = false;
    fieldConfig.visible = false;
    fieldConfigs.push(fieldConfig);

    formFieldConfig = new FormFieldConfig('WorkForce', fieldConfigs);
    formFieldConfigs.push(formFieldConfig);

    return formFieldConfigs;
  }
}

// Tables

export class WorkRequestContactAttributes extends ContactBaseAttributes {
  public WorkRequestID: string;
  public WorkRequest_GlobalID: string;
}

export class WorkRequestContact extends ContactBase {
  attributes: WorkRequestContactAttributes[];
  server = LayerIds.gisServer_Maintenance;
  folder = LayerIds.folder_Maintenance;
  layerId = LayerIds.layerId_TblWorkRequest_Contact;
  id = 'tableWorkRequestContact';
  title = 'Work Request Contacts';

  partNameRequired = false;
  showPartName = true;
  //fullNameRequired = false;
  //showFullName = false;
  //showPartAddress = false;
  //showCountry = false;

  protected async createFormFieldConfigs(): Promise<FormFieldConfig[]> {
    let formFieldConfig: FormFieldConfig;
    let fieldConfigs: FieldConfig[] = [];
    let fieldConfig: FieldConfig;
    let format: any;

    // Workforce Integration (and hidden)

    fieldConfigs = [];

    fieldConfig = new FieldConfig('WorkRequestId', 'Work Request Id', 'text');
    fieldConfig.required = true;
    fieldConfig.editable = false;
    fieldConfig.visible = false;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('WorkRequest_GlobalID ', 'WorkRequest_GlobalID ', 'text');
    fieldConfig.maxLength = 38;
    fieldConfig.editable = false;
    fieldConfig.visible = false;
    fieldConfigs.push(fieldConfig);

    formFieldConfig = new FormFieldConfig('Workforce Integration', fieldConfigs);
    formFieldConfig.visible = false;
    this.formFieldConfigs.push(formFieldConfig);

    super.createFormFieldConfigs();

    return this.formFieldConfigs;
  }
}

export class WorkRequestCategoryLOV extends FeatureLayerObjectBase {
  server = LayerIds.gisServer_Maintenance;
  folder = LayerIds.folder_Maintenance;
  layerId = LayerIds.layerId_TblWorkRequest_Category_LOV;
  id = 'tableWorkRequestCategory_LOV';
  title = 'Work Request Category LOV';

  whereClause = "ClubId='" + Global.clubId + "' AND Active <> 'NO'";
  outFields = ['CategoryId', 'Description'];

  protected async createFormFieldConfigs(): Promise<FormFieldConfig[]> {
    //let formFieldConfigs: FormFieldConfig[] = [];
    let formFieldConfig: FormFieldConfig;
    let fieldConfigs: FieldConfig[] = [];
    let fieldConfig: FieldConfig;
    //let format: any;
    //let whereClause: string = '';
    //let outFields: string[] = [];

    fieldConfig = new FieldConfig('CategoryId', 'Category Id', 'text');
    fieldConfig.required = true;
    fieldConfig.maxLength = 20;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('Description', 'Description', 'text');
    fieldConfig.required = true;
    fieldConfig.maxLength = 200;
    fieldConfigs.push(fieldConfig);

    formFieldConfig = new FormFieldConfig('Description', fieldConfigs);
    this.formFieldConfigs.push(formFieldConfig);

    super.createFormFieldConfigs();
    return this.formFieldConfigs;
  } 
}

export class WorkRequestSubCategoryAttributes extends FeatureLayerObjectBaseAttributes {
  public CategoryId: string;
  public SubCategoryId: string;
  public Description: string;
  public SLADays: number;
  public SLABusinessDays: number;
  public DefaultResponsible: string;
  public DefaultAssignedTo: string;
  public WorkForce_AssignmentGlobalId: string;

  constructor(
    protected lovDataService: LovDataService,
    protected domainLovService: DomainLovService
  ) {
    super(
      lovDataService,
      domainLovService
    );
  }

  // Virtual Fields
  get Category(): string {
    if (this.CategoryId) {
      return this.domainLovService.getDomainLovValue('LovWorkRequestCategory', this.CategoryId);
    }
    else {
      return null;
    }
  }

  get SubCategory(): string {
    if (this.SubCategoryId) {
      return this.Description;
    }
    else {
      return null;
    }
  }

  get DefaultResponsibleStaffName(): string {
    if (this.DefaultResponsible) {
      return this.domainLovService.getDomainLovValue('LovStaff', this.DefaultResponsible);
    }
    else {
      return null;
    }
  };

  get DefaultAssignedToStaffName(): string {
    if (this.DefaultAssignedTo) {
      return this.domainLovService.getDomainLovValue('LovStaff', this.DefaultAssignedTo);
    }
    else {
      return null;
    }
  };
}

@Injectable()
export class WorkRequestSubCategoryLOV extends FeatureLayerObjectBase {
  attributes: WorkRequestSubCategoryAttributes[];
  server = LayerIds.gisServer_Maintenance;
  folder = LayerIds.folder_Maintenance;
  layerId = LayerIds.layerId_TblWorkRequest_SubCategory_LOV;
  id = 'tableWorkRequestSubCategory_LOV';
  title = 'Work Request Sub Category LOV';

  whereClause = "ClubId='" + Global.clubId + "' AND Active <> 'NO'";
  outFields = ['CategoryId', 'SubCategoryId', 'Description'];

  @Output() categoryLovReadyEvent = new EventEmitter<any>();
  @Output() subCategoryLovReadyEvent = new EventEmitter<any>();
  @Output() staffLovReadyEvent = new EventEmitter<any>();

  protected async createFormFieldConfigs(): Promise<FormFieldConfig[]> {
    let formFieldConfig: FormFieldConfig;
    let fieldConfigs: FieldConfig[] = [];
    let fieldConfig: FieldConfig;
    let format: any;

    fieldConfig = new FieldConfig('CategoryId', 'Category Id', 'text');
    fieldConfig.required = true;
    fieldConfig.maxLength = 20;
    fieldConfig.domain_lov = 'LovWorkRequestCategory';
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('SubCategoryId', 'Sub-Category Id', 'text');
    fieldConfig.required = true;
    fieldConfig.maxLength = 20;
    fieldConfig.domain_lovParent = 'LovWorkRequestCategory';
    fieldConfig.domain_lov = 'LovWorkRequestSubCategory';
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('Description', 'Description', 'text');
    fieldConfig.required = true;
    fieldConfig.maxLength = 200;
    fieldConfigs.push(fieldConfig);

    formFieldConfig = new FormFieldConfig('Description', fieldConfigs);
    this.formFieldConfigs.push(formFieldConfig);

    // Assignment and SLA

    fieldConfigs = [];

    fieldConfig = new FieldConfig('SLADays', 'SLA Days:', 'number');
    format = new FieldNumberFormat(0);
    fieldConfig.format = format;
    fieldConfig.visible = false;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('SLABusinessDays', 'SLA in Business Days', 'text');
    fieldConfig.domain = 'DomYesNo';
    fieldConfig.defaultValue = 'NO';
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('DefaultResponsible', 'Default Responsible:', 'text');
    fieldConfig.required = false;
    fieldConfig.maxLength = 20;
    fieldConfig.domain_lov = 'LovStaff';
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('DefaultAssignedTo', 'Default Assigned To:', 'text');
    fieldConfig.required = false;
    fieldConfig.maxLength = 20;
    fieldConfig.domain_lov = 'LovStaff';
    fieldConfigs.push(fieldConfig);

    formFieldConfig = new FormFieldConfig('Assignment', fieldConfigs);
    this.formFieldConfigs.push(formFieldConfig);

    // ArcGIS WorkForce Integration

    fieldConfigs = [];

    fieldConfig = new FieldConfig('WorkForce_AssignmentTypeGlobalId', 'WorkForce Assignment Global Id:', 'text');
    fieldConfig.maxLength = 38;
    fieldConfig.editable = false;
    fieldConfig.visible = false;
    fieldConfigs.push(fieldConfig);

    formFieldConfig = new FormFieldConfig('Description', fieldConfigs);
    this.formFieldConfigs.push(formFieldConfig);

    super.createFormFieldConfigs();
    return this.formFieldConfigs;
  }

  protected updateVirtualFields() {
    // Reset
    this.attributes = [];

    this.features.forEach((f) => {
      let attr: WorkRequestSubCategoryAttributes = new WorkRequestSubCategoryAttributes(this.lovDataService, this.domainLovService);

      attr.CategoryId = f.attributes.CategoryId;
      attr.SubCategoryId = f.attributes.SubCategoryId;
      attr.Description = f.attributes.Description;
      attr.SLADays = f.attributes.SLADays;
      attr.SLABusinessDays = f.attributes.SLABusinessDays;
      attr.DefaultResponsible = f.attributes.DefaultResponsible;
      attr.DefaultAssignedTo = f.attributes.DefaultAssignedTo;
      attr.WorkForce_AssignmentGlobalId = f.attributes.WorkForce_AssignmentGlobalId;

      // Common
      attr.OBJECTID = f.attributes.OBJECTID;
      attr.ClubId = f.attributes.ClubId;
      attr.Active = f.attributes.Active;
      attr.GlobalID = f.attributes.GlobalID;
      attr.HasLocation = f.attributes.HasLocation;
      attr.CreationDate = f.attributes.CreationDate;
      attr.Creator = f.attributes.Creator;
      attr.EditDate = f.attributes.EditDate;
      attr.Editor = f.attributes.Editor;

      // Populate virtual / calculated fields
      f.attributes.Category = attr.Category;
      f.attributes.SubCategory = attr.SubCategory;
      f.attributes.ResponsibleStaffName = attr.DefaultResponsibleStaffName;
      f.attributes.AssignedToStaffName = attr.DefaultAssignedToStaffName;

      this.attributes.push(attr);
    });
  }

  public getLovs() {
    // All LOV Domains
    combineLatest([
      this.categoryLovReadyEvent,
      this.subCategoryLovReadyEvent,
      this.staffLovReadyEvent
    ]).pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      console.log('WorkRequestSubCategoryLOV/FeatureBase getLovs() lovsReady()');
      this.lovReadyEvent.emit(true);
    });

    this.domains_lov = [];
    this.domains_lovSubDomain = [];

    let workRequestCategoryLOV: WorkRequestCategoryLOV = new WorkRequestCategoryLOV();
    workRequestCategoryLOV.getFeatureLayerConfigAndData().then(() => {
      workRequestCategoryLOV.getDomain('LovWorkRequestCategory', 'coded-value').then((domain) => {
        this.domains_lov.push(domain);
        //console.log('getLovs() categoryLovReadyEvent.emit');
        this.categoryLovReadyEvent.emit();
      });
    });

    let workRequestSubCategoryLOV: WorkRequestSubCategoryLOV = new WorkRequestSubCategoryLOV();
    workRequestSubCategoryLOV.getFeatureLayerConfigAndData().then(() => {
      workRequestSubCategoryLOV.getSubDomain('LovWorkRequestSubCategory', 'coded-value').then((domain) => {
        this.domains_lovSubDomain.push(domain);
        //console.log('getLovs() subCategoryLovReadyEvent.emit');
        this.subCategoryLovReadyEvent.emit();
      });
    });

    let staffLOV: StaffLOV = new StaffLOV();
    staffLOV.getFeatureLayerConfigAndData().then(() => {
      staffLOV.getDomain('LovStaff', 'coded-value').then((domain) => {
        this.domains_lov.push(domain);
        //console.log('getLovs() staffLovReadyEvent.emit');
        this.staffLovReadyEvent.emit();
      });
    });
  }
}

export class WorkRequestReportedByLOV extends FeatureLayerObjectBase {
  server = LayerIds.gisServer_Maintenance;
  folder = LayerIds.folder_Maintenance;
  layerId = LayerIds.layerId_TblWorkRequest_ReportedBy_LOV;
  id = 'tableWorkRequestReportedBy_LOV';
  title = 'Work Request Reported By LOV';

  whereClause = "ClubId='" + Global.clubId + "' AND Active <> 'NO'";
  outFields = ['ReportedBy', 'Description'];

  public static async createFormFieldConfig(): Promise<FormFieldConfig[]> {
    let formFieldConfigs: FormFieldConfig[] = [];
    let formFieldConfig: FormFieldConfig;
    let fieldConfigs: FieldConfig[] = [];
    let fieldConfig: FieldConfig;
    //let format: any;
    //let whereClause: string = '';
    //let outFields: string[] = [];

    fieldConfig = new FieldConfig('ReportedBy', 'Reported By:', 'text');
    fieldConfig.required = true;
    fieldConfig.maxLength = 20;
    fieldConfig.domain_lov = 'LovWorkRequestReportedBy';
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('Description', 'Description', 'text');
    fieldConfig.required = true;
    fieldConfig.maxLength = 200;
    fieldConfigs.push(fieldConfig);

    formFieldConfig = new FormFieldConfig('Description', fieldConfigs);
    formFieldConfigs.push(formFieldConfig);

    return formFieldConfigs;
  }
}

