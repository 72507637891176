import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import { Domain, SubDomain } from 'src/app/_esri/models/esri';
import { DomainLovService } from 'src/app/_esri/services/domain-lov.service';
//import { LovDataService } from 'src/app/_globals/services/lov-data.service';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';

@Component({
  selector: 'app-esri-edit-form-elements',
  templateUrl: './esri-edit-form-elements.component.html',
  styleUrls: ['./esri-edit-form-elements.component.scss'],
  providers: [
    {provide: MAT_DATE_LOCALE, useValue: 'en-AU'}
  ],
})


export class EsriEditFormElementsComponent implements OnInit, OnDestroy {

  // System Environment
  protected ngUnsubscribe = new Subject<void>();

  private _featureForm: any = null;
  private _field: any;
  private _idx: any;

  private _domains: Domain[] = [];
  private _domains_lov: Domain[] = [];
  private _domains_lovSubDomain: SubDomain[] = [];
  
  private _readOnly: boolean = false; 
  //public fieldInForm: boolean = true; 

  // WYSIWIG Editor
  public editor = ClassicEditor;

  // Don't use this one - HTML wont be respected when creating a MS Word document report
  public toolbarDefault = {
    toolbar: ["heading", "|", "bold", "italic", "link", "bulletedList", "numberedList", "|", "indent", "outdent", "|", "imageUpload", "blockQuote", "insertTable", "mediaEmbed", "undo", "redo"]
  };

  // MS Word report currently supports HTML paragraph and unordered lists elements only (bold, italic will be ignored, ordered list will appear as bullet list)
  public toolbarCustom = {
    //toolbar: ["bold", "italic", "|", "bulletedList", "numberedList", "|", "mediaEmbed", "|", "undo", "redo"]
    toolbar: ["bold", "italic", "|", "bulletedList", "numberedList", "|", "undo", "redo"]
  };

  @Input()
  set featureForm(featureForm: any) {
    this._featureForm = featureForm;
  }

  get featureForm(): any {
    return this._featureForm;
  }

  @Input()
  set field(field: any) {
    this._field = field;
  }

  get field(): any {
    return this._field;
  }

  @Input()
  set idx(idx: any) {
    this._idx = idx;
  }

  get idx(): any {
    return this._idx;
  }

  @Input()
  set domains(domains: Domain[]) {
    this._domains = domains;
  }

  get domains(): Domain[] {
    return this._domains;
  }

  @Input()
  set domains_lov(domains_lov: Domain[]) {
    this._domains_lov = domains_lov;
  }

  get domains_lov(): Domain[] {
    return this._domains_lov;
  }

  @Input()
  set domains_lovSubDomain(domains_lovSubDomain: SubDomain[]) {
    this._domains_lovSubDomain = domains_lovSubDomain;
  }

  get domains_lovSubDomain(): SubDomain[] {
    return this._domains_lovSubDomain;
  }

  @Input()
  set readOnly(readOnly: boolean) {
    this._readOnly = readOnly;
  }

  get readOnly(): boolean {
    return this._readOnly;
  }

  constructor(
    private _adapter: DateAdapter<any>,
    //protected lovDataService: LovDataService,
    protected domainLovService: DomainLovService
  ) { 
    this._adapter.setLocale('en-AU');
  }

  ngOnInit(): void {
    //console.log('EsriEditFormElementsComponent ngOnInit', this._readOnly);

    //console.log('EsriEditFormElementsComponent ngOnInit this.domains, domains_lov', this.domains, this.domains_lov);

    // this.domainLovService.domains = this.domains;
    // this.domainLovService.domains_lov = this.domains_lov;
    // this.domainLovService.domains_lovSubDomain = this.domains_lovSubDomain;

    // // Domains and LOV's
    // this.lovDataService.lovData.pipe(takeUntil(this.ngUnsubscribe)).subscribe( (lovData: any) => {
    //   console.log('EsriEditFormElementsComponent received lovData', lovData);
    //   this.domainLovService.domains = lovData.domains;
    //   this.domainLovService.domains_lov = lovData.domains_lov;
    //   this.domainLovService.domains_lovSubDomain = lovData.domains_lovSubDomain;
    // });

  }

  ngOnDestroy(): void {
    // Unsubscribe from events / observables
    // this.ngUnsubscribe.next();
    // this.ngUnsubscribe.complete();
  }

  public getDomainIndex(domainName: string) {      // these are firing all the time!!!!
    //console.log('getDomainIndex() domainName:', domainName);
    if (domainName) {
      return this.domains.findIndex(domain => domain.name === domainName);
    }
    else {
      return null;
    }

    //return this.domainLovService.getDomainIndex(domainName);

  }

  public getDomainValue(domainName: string, codedValue: any) {
    //console.log('getDomainValue() domainName:', domainName, codedValue);
    if (codedValue) {
      let domain = this.domains.find(domain => domain.name === domainName);
      return domain.codedValues.find(code => code.code === codedValue).name;
    }
    else {
      return;
    }
    //return this.domainLovService.getDomainValue(domainName, codedValue);
  }


  public getDomain(domainName: string)  {
    //console.log('getDomain() domainName:', domainName);
    return this.domains.find(domain => domain.name === domainName);
  }

  public getDomainLov(domainName: string) {
    let domain = this.domains_lov.find(domain => domain.name === domainName);

    if (domain) {
      return domain.codedValues;
    }
    else {
      return null;
    }
    //return this.domainLovService.getDomainLov(domainName, codedValue);
  }

  public checkIfValueIsOther(fieldName, idx): boolean {
    if (fieldName.includes("_other")) {
      const fname = fieldName.substring(0, fieldName.indexOf("_other"));
      //console.log('field names:', fieldName, fname);
      //console.log('field value:', this._featureForm.controls.formArray.controls[idx].controls[fname].value);

      if (this._featureForm.controls.formArray.controls[idx].controls[fname].value === "other") {
        return true;
      }
      else {
        return false;
      }
    }
    else {
      return false;
    }
  }
}
