import { Component, OnInit, OnDestroy, Output, Injectable, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CurrentModeService, CurrentModeData } from 'src/app/_globals/services/current-mode.service';
import { FormChangeService } from 'src/app/_globals/services/form-change.service';
import { ButtonClickService, ButtonClickData } from 'src/app/_globals/services/button-click.service';

@Component({
  selector: 'app-esri-edit-form-buttons',
  templateUrl: './esri-edit-form-buttons.component.html',
  styleUrls: ['./esri-edit-form-buttons.component.scss']
})

@Injectable({
  providedIn: 'root'
})

export class EsriEditFormButtonsComponent implements OnInit, OnDestroy {

  // System Environment
  protected ngUnsubscribe = new Subject<void>();
  //public buttonClickService: ButtonClickService;

  // Info / Status Message
  public statusMessage: string = '';
  public formName: string = '';

  // Standard Buttons
  public addBtnHidden: boolean = true;
  public editBtnHidden: boolean = true;
  public saveBtnHidden: boolean = false;
  public attachmentBtnHidden: boolean = true;
  public deleteBtnHidden: boolean = false;
  public cancelBtnHidden: boolean = true;

  public addBtnDisabled: boolean = true;
  public editBtnDisabled: boolean = true;  
  public saveBtnDisabled: boolean = true;
  public attachmentBtnDisabled: boolean = true;
  public deleteBtnDisabled: boolean = true;
  public cancelBtnDisabled: boolean = true;

  // Extra Buttons

  public misc1BtnHidden: boolean = true;
  public misc1Tooltip: string = '';
  public misc1BtnDisabled: boolean = true;
  public misc1Icon: string = '';
  public misc1Label: string = '';
  public misc1CurrentMode: string = '';

  public misc2BtnHidden: boolean = true;
  public misc2Tooltip: string = '';
  public misc2BtnDisabled: boolean = true;
  public misc2Icon: string = '';
  public misc2Label: string = '';
  public misc2CurrentMode: string = '';

  public misc3BtnHidden: boolean = true;
  public misc3Tooltip: string = '';
  public misc3BtnDisabled: boolean = true;
  public misc3Icon: string = '';
  public misc3Label: string = '';
  public misc3CurrentMode: string = '';

  // Mode
  public currentMode: string = 'start';

  // Access HTML Elements
  @ViewChild("infoMessage", { static: true }) public infoMessageEl: ElementRef;

  // Events
  // @Output() addBtnSelectedEvent = new EventEmitter<any>();
  // @Output() editBtnSelectedEvent = new EventEmitter<any>();
  // @Output() saveBtnSelectedEvent = new EventEmitter<any>();
  // @Output() attachmentBtnSelectedEvent = new EventEmitter<any>();
  // @Output() deleteBtnSelectedEvent = new EventEmitter<any>();
  // @Output() cancelBtnSelectedEvent = new EventEmitter<any>();
  // @Output() misc1BtnSelectedEvent = new EventEmitter<any>();
  // @Output() misc2BtnSelectedEvent = new EventEmitter<any>();
  // @Output() misc3BtnSelectedEvent = new EventEmitter<any>();

  constructor(
    private currentModeService: CurrentModeService,
    private formChangeService: FormChangeService,
    private buttonClickService: ButtonClickService
  ) { }

  ngOnInit(): void {
    //console.log('EsriEditFormButtonsComponent ngOnInit');

    //this.buttonClickService = new ButtonClickService();

    // Current Mode
    this.currentModeService.currentModeData.pipe(takeUntil(this.ngUnsubscribe)).subscribe((subject: CurrentModeData) => {
      console.log('EsriEditFormButtonsComponent currentModeService subscribe subject', subject);
      this.setCurrentMode(subject.mode);
    });

    // Form Changes
    this.formChangeService.formChangeData.pipe(takeUntil(this.ngUnsubscribe)).subscribe( (event) => {
      let mode: string = '';

      if (event === 'VALID') {
        //this.currentModeService.currentModeData.next('form-valid');
        mode = 'form-valid';
      }
      else {
        //this.currentModeService.currentModeData.next('form-invalid');
        mode = 'form-invalid';
      }

      let currentModeData: CurrentModeData = {
        formName: this.formName,
        mode,
        event: ''
      }
      this.currentModeService.currentModeData.next(currentModeData);
    });
  }

  ngOnDestroy() {
    //console.log('EsriEditFormButtonsComponent ngOnDestroy');
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    //this.featureFormChangeEvent.unsubscribe();
  }

  //
  // Action buttons
  //

  public addBtnSelected() {
    console.log('addBtnSelected');
    //this.currentModeService.currentModeData.next('add');
    let currentModeData: CurrentModeData = {
      formName: this.formName,
      mode: 'add',
      event: ''
    }
    this.currentModeService.currentModeData.next(currentModeData);

    //this.addBtnSelectedEvent.emit();
    let buttonClickData: ButtonClickData = {
      formName: this.formName,
      button: 'add',
      event: ''
    }
    this.buttonClickService.buttonClickData.next(buttonClickData);   
  }

  public editBtnSelected() {
    console.log('editBtnSelected');
    //this.currentModeService.currentModeData.next('edit-attribute');
    let currentModeData: CurrentModeData = {
      formName: this.formName,
      mode: 'edit-attribute',
      event: ''
    }
    this.currentModeService.currentModeData.next(currentModeData);

    //this.editBtnSelectedEvent.emit();
    let buttonClickData: ButtonClickData = {
      formName: this.formName,
      button: 'edit',
      event: ''
    }
    this.buttonClickService.buttonClickData.next(buttonClickData);   
  }

  public saveBtnSelected() {
    console.log('saveBtnSelected');
    //this.currentModeService.currentModeData.next('save');
    // let currentModeData: CurrentModeData = {
    //   formName: this.formName,
    //   mode: 'save',
    //   event: ''
    // }
    // this.currentModeService.currentModeData.next(currentModeData);

    //this.saveBtnSelectedEvent.emit();
    let buttonClickData: ButtonClickData = {
      formName: this.formName,
      button: 'save',
      event: ''
    }
    this.buttonClickService.buttonClickData.next(buttonClickData);   
  }

  public attachmentBtnSelected() {
    console.log('attachmentBtnSelected');
    //this.currentModeService.currentModeData.next('edit-attachment');
    let currentModeData: CurrentModeData = {
      formName: this.formName,
      mode: 'edit-attachment',
      event: ''
    }
    this.currentModeService.currentModeData.next(currentModeData);

    //this.attachmentBtnSelectedEvent.emit();
    let buttonClickData: ButtonClickData = {
      formName: this.formName,
      button: 'edit-attachment',
      event: ''
    }
    this.buttonClickService.buttonClickData.next(buttonClickData); 
  }

  public deleteBtnSelected() {
    console.log('deleteBtnSelected');
    //this.currentModeService.currentModeData.next('delete');
    let currentModeData: CurrentModeData = {
      formName: this.formName,
      mode: 'delete',
      event: ''
    }
    this.currentModeService.currentModeData.next(currentModeData);

    //this.deleteBtnSelectedEvent.emit();
    let buttonClickData: ButtonClickData = {
      formName: this.formName,
      button: 'delete',
      event: ''
    }
    this.buttonClickService.buttonClickData.next(buttonClickData);  
  }

  public cancelBtnSelected() {
    console.log('cancelBtnSelected');
    let mode: string = '';
    switch (this.currentMode) {
      case 'edit-attribute':
      //case 'location-changed':
      //case 'edit-popup':
        //this.currentModeService.currentModeData.next('edit-attribute-cancel');
        mode = 'edit-attribute-cancel';
        break;

      case 'edit-attachment':
        //this.currentModeService.currentModeData.next('edit-attachment-cancel');
        mode = 'edit-attachment-cancel';
        break;

      case 'add':
      //case 'add-popup':
        //this.currentModeService.currentModeData.next('add-cancel');
        mode = 'add-cancel';
        break;

      default:
        //this.currentModeService.currentModeData.next('clear');
        mode = 'clear';
        break;
    }
    let currentModeData: CurrentModeData = {
      formName: this.formName,
      mode,
      event: ''
    }
    this.currentModeService.currentModeData.next(currentModeData);

    //this.cancelBtnSelectedEvent.emit();
    let buttonClickData: ButtonClickData = {
      formName: this.formName,
      button: 'cancel',
      event: ''
    }
    this.buttonClickService.buttonClickData.next(buttonClickData); 
  }

  public misc1BtnSelected() {
    console.log('misc1BtnSelected');
    if (this.misc1CurrentMode != '') {
      //this.currentModeService.currentModeData.next(this.misc1CurrentMode);
      let currentModeData: CurrentModeData = {
        formName: this.formName,
        mode: this.misc1CurrentMode,
        event: ''
      }
      this.currentModeService.currentModeData.next(currentModeData);
    }
    
    //this.misc1BtnSelectedEvent.emit();
    let buttonClickData: ButtonClickData = {
      formName: this.formName,
      button: 'misc1',
      event: ''
    }
    this.buttonClickService.buttonClickData.next(buttonClickData); 
  }

  public misc2BtnSelected() {
    console.log('misc2BtnSelected');
    if (this.misc2CurrentMode != '') {
      //this.currentModeService.currentModeData.next(this.misc2CurrentMode);
      let currentModeData: CurrentModeData = {
        formName: this.formName,
        mode: this.misc2CurrentMode,
        event: ''
      }
      this.currentModeService.currentModeData.next(currentModeData);
    }

    //this.misc2BtnSelectedEvent.emit();
    let buttonClickData: ButtonClickData = {
      formName: this.formName,
      button: 'misc2',
      event: ''
    }
    this.buttonClickService.buttonClickData.next(buttonClickData); 
  }

  public misc3BtnSelected() {
    console.log('misc3BtnSelected');
    if (this.misc3CurrentMode != '') {
      //this.currentModeService.currentModeData.next(this.misc3CurrentMode);
      let currentModeData: CurrentModeData = {
        formName: this.formName,
        mode: this.misc3CurrentMode,
        event: ''
      }
      this.currentModeService.currentModeData.next(currentModeData);
    }

    //this.misc3BtnSelectedEvent.emit();
    let buttonClickData: ButtonClickData = {
      formName: this.formName,
      button: 'misc3',
      event: ''
    }
    this.buttonClickService.buttonClickData.next(buttonClickData); 
  }

  //
  // Functions
  //

  private setCurrentMode(mode: string) {
    console.log('EsriEditFormButtonsComponent setCurrentMode', mode);
	
    if (mode === 'form-valid' || mode === 'form-invalid') {
      // Don't set it
    }
    else {
      this.currentMode = mode;
    }
    
	  //console.log('currentMode', this.currentMode);

    switch (mode)  {
      case 'select':
		    // Enable / Disable buttons
        this.addBtnDisabled = false;
        this.editBtnDisabled = false;
        this.saveBtnDisabled = true;  
        this.attachmentBtnDisabled = true;
		    this.deleteBtnDisabled = true;
        this.cancelBtnDisabled = false;
        break;

      case 'add': 
		    // Enable / Disable buttons
        this.addBtnDisabled = true;
        this.editBtnDisabled = true;
        this.saveBtnDisabled = true;  
        this.attachmentBtnDisabled = true;
		    this.deleteBtnDisabled = true;
        this.cancelBtnDisabled = false;	  
        break;

      case 'add-cancel':
        this.currentMode = 'start';
		
        // Enable / Disable buttons
        this.addBtnDisabled = false;
        this.editBtnDisabled = true;
        this.saveBtnDisabled = true;  
        this.attachmentBtnDisabled = true;
		    this.deleteBtnDisabled = true;
        this.cancelBtnDisabled = true;	
        break;

      case 'edit-attribute':
        // Enable / Disable buttons
        this.addBtnDisabled = true;
        this.editBtnDisabled = true;
        this.saveBtnDisabled = false;  
        this.attachmentBtnDisabled = true;
		    this.deleteBtnDisabled = true;
        this.cancelBtnDisabled = false;	  
        break;
        
      case 'edit-attribute-cancel':
        this.currentMode = 'start';
		
		    // Enable / Disable buttons
		    this.addBtnDisabled = false;
        this.editBtnDisabled = true;
        this.saveBtnDisabled = true;  
        this.attachmentBtnDisabled = true;
		    this.deleteBtnDisabled = true;
        this.cancelBtnDisabled = true;	
        break;

      case 'edit-attachment':
		    // Enable / Disable buttons
	  	  this.addBtnDisabled = true;
        this.editBtnDisabled = true;
        this.saveBtnDisabled = true;  
        this.attachmentBtnDisabled = true;
		    this.deleteBtnDisabled = true;
        this.cancelBtnDisabled = false;	
        break;

      case 'edit-attachment-cancel':
        this.currentMode = 'edit-attribute';
		
		    // Enable / Disable buttons
		    this.addBtnDisabled = true;
        this.editBtnDisabled = true;
        this.saveBtnDisabled = false;  
        this.attachmentBtnDisabled = false;
		    this.deleteBtnDisabled = true;
        this.cancelBtnDisabled = false;	
        break;

      // case 'save':
		  //   // Enable / Disable buttons	  
	  	//   this.addBtnDisabled = false;
      //   this.editBtnDisabled = true;
      //   this.saveBtnDisabled = false;  
      //   this.attachmentBtnDisabled = true;
		  //   this.deleteBtnDisabled = true;
      //   this.cancelBtnDisabled = false;	

      //   this.statusMessage = '';
      //   this.infoMessageEl.nativeElement.classList.remove('error', 'warn', 'ok');

      //   break;

      case 'form-valid':
		    // Enable / Disable buttons
        this.statusMessage = 'VALID';
        this.infoMessageEl.nativeElement.classList.remove('error', 'warn', 'ok');
        this.saveBtnDisabled = false;
        break;

      case 'form-invalid':
		    // Enable / Disable buttons
        this.statusMessage = 'MORE INFO';
        this.infoMessageEl.nativeElement.classList.add('warn');
        this.saveBtnDisabled = true;
        break;     
              
      case 'form-submitted':
		    // Enable / Disable buttons
	  	  this.addBtnDisabled = false;
        this.editBtnDisabled = true;
        this.saveBtnDisabled = true;  
        this.attachmentBtnDisabled = true;
		    this.deleteBtnDisabled = true;
        this.cancelBtnDisabled = true;

        this.statusMessage = 'SAVED';
        this.infoMessageEl.nativeElement.classList.add('ok');
        this.infoMessageEl.nativeElement.classList.remove('error', 'warn');
        break;

      case 'form-submitted-error':
          this.statusMessage = '** SAVE FAILED **';
          this.infoMessageEl.nativeElement.classList.add('error');
          this.infoMessageEl.nativeElement.classList.remove('warn', 'ok');
          break;

      case 'clear':
      case 'start':
        // Enable / Disable buttons
        this.addBtnDisabled = false;
        this.editBtnDisabled = true;
        this.saveBtnDisabled = true;  
        this.attachmentBtnDisabled = true;
		    this.deleteBtnDisabled = true;
        this.cancelBtnDisabled = true;
        break;

      default:
        // No change
        break;
    }
  } 

}
