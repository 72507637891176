<app-header>
    <span title>{{title}}</span>
    <span toolbar-extras>
        <button mat-icon-button [matMenuTriggerFor]='genderMenu' aria-label='Example icon-button with a menu'>
            <mat-icon matTooltip='Set the tees for men or women'>people</mat-icon>
        </button>
        <mat-menu #genderMenu="matMenu">
            <button mat-menu-item (click)='queryGameGender("M")'>
                <!-- <mat-icon class='material-icons-sharp'>home</mat-icon> -->
                <span class="fa fa-male fa-2x" style='padding-right:30px;' aria-hidden="true"></span>
                <span>Men</span>
            </button>
            <button mat-menu-item (click)='queryGameGender("F")'>
                <span class="fa fa-female fa-2x" style='padding-right:30px;' aria-hidden="true"></span>
                <span>Women</span>
            </button>
        </mat-menu>

        <button mat-icon-button [matMenuTriggerFor]='teeMenu' aria-label="Example icon-button with share icon">
            <mat-icon matTooltip='Choose the tee markers for your game'>golf_course</mat-icon>
        </button>

        <mat-menu #teeMenu="matMenu">
            <button mat-menu-item (click)='queryTeeMarkerType("BLACK")' [disabled]='gameGender=="F"' [hidden]='gameGender=="F"'>
                <mat-icon class='material-icons-sharp' style='color:black;'>sports_golf</mat-icon>
                <span>Black</span>
            </button>
            <!-- <button mat-menu-item (click)='queryTeeMarkerType("BLUE")' [hidden]='!showTee_BLUE'>
                <mat-icon class='material-icons-sharp' style='color:blue;'>sports_golf</mat-icon>
                <span>Blue</span>
            </button>
            <button mat-menu-item (click)='queryTeeMarkerType("WHITE")' [disabled]='gameGender=="F"' [hidden]='!showTee_WHITE'>
                <mat-icon class='material-icons-sharp' style='color:gray;'>sports_golf</mat-icon>
                <span>White</span>
            </button> -->
            <button mat-menu-item (click)='queryTeeMarkerType("RED")'>
                <mat-icon class='material-icons-sharp' style='color:red;'>sports_golf</mat-icon>
                <span>Red</span>
            </button>
            <button mat-menu-item (click)='queryTeeMarkerType("GOLD")' [disabled]='gameGender=="M"' [hidden]='gameGender=="M"'>
                <mat-icon class='material-icons-sharp' style='color:gold;'>sports_golf</mat-icon>
                <span>Gold</span>
            </button>
        </mat-menu>

        <button mat-icon-button [matMenuTriggerFor]='lengthMenu' aria-label='Example icon-button with a menu'>
            <mat-icon matTooltip='Choose your drive length'>straighten</mat-icon>
        </button>
        <mat-menu #lengthMenu="matMenu">
            <!-- <button mat-menu-item (click)='queryGameDriveLength("BIRDIE")'>
                <mat-icon class='material-icons-sharp'>straighten</mat-icon>
                <span>Birdie</span>
            </button> -->
            <button mat-menu-item (click)='queryGameDriveLength("PAR")' [hidden]='!showLength_PAR'>
                <mat-icon class='material-icons-sharp'>straighten</mat-icon>
                <span>Par</span>
            </button>
            <button mat-menu-item (click)='queryGameDriveLength("137")' [hidden]='!showLength_137'>
                <mat-icon class='material-icons-sharp'>straighten</mat-icon>
                <span>137m</span>
            </button>
            <button mat-menu-item (click)='queryGameDriveLength("150")' [hidden]='!showLength_150'>
                <mat-icon class='material-icons-sharp'>straighten</mat-icon>
                <span>150m</span>
            </button>
            <button mat-menu-item (click)='queryGameDriveLength("180")' [hidden]='!showLength_180'>
                <mat-icon class='material-icons-sharp'>straighten</mat-icon>
                <span>180m</span>
            </button>
            <button mat-menu-item (click)='queryGameDriveLength("192")' [hidden]='!showLength_192'>
                <mat-icon class='material-icons-sharp'>straighten</mat-icon>
                <span>192m</span>
            </button>
        </mat-menu>
    </span>
</app-header>

<div style='padding:5px;' fxLayout='row' fxLayout.lt-lg='column' fxLayoutAlign='space-between stretch' class='fullscreen-map-container'>    
    <!-- LHS - Map-->
    <div fxFlex='100' style="height: 100%; width: 100%;">
        <app-esri-scene (viewCreatedEvent)='viewCreatedEvent($event)' style="height: 100%; width: 100%;"></app-esri-scene>     
               
        <div #infoPanelTitle [hidden]='!loaded'>
            <mat-card class="title-panel">
                <mat-card-header>                    
                    <!-- <div mat-card-avatar><app-menu></app-menu></div>  -->
                    <div mat-card-avatar class="card-header-image" 
                        [ngClass]="{
                            'card-header-image-red':gameTeeMarkerType === 'RED',
                            'card-header-image-black':gameTeeMarkerType === 'BLACK',
                            'card-header-image-blue':gameTeeMarkerType === 'BLUE',
                            'card-header-image-white':gameTeeMarkerType === 'WHITE',
                            'card-header-image-gold':gameTeeMarkerType === 'GOLD' }">
                        <i *ngIf='gameGender === "M"' class="fa fa-male fa-2x" aria-hidden="true"></i>
                        <i *ngIf='gameGender === "F"' class="fa fa-female fa-2x" aria-hidden="true"></i>
                    </div>

                    <mat-card-title>
                        <div *ngIf='gameGender==="M"'> 
                            <span>Par: {{selectedFeaturesGame.MenPar}}&nbsp;&nbsp;</span>
                            <span>Scratch: {{selectedFeaturesGame.MenScratch}}&nbsp;&nbsp;</span>
                            <span>Slope: {{selectedFeaturesGame.MenSlope}}</span>
                        </div>
                        <div *ngIf='gameGender==="F"'> 
                            <span>Par: {{selectedFeaturesGame.WomenPar}}&nbsp;&nbsp;</span>
                            <span>Scratch: {{selectedFeaturesGame.WomenScratch}}&nbsp;&nbsp;</span>
                            <span>Slope: {{selectedFeaturesGame.WomenSlope}}</span>
                        </div>
                    </mat-card-title>
                    <mat-card-subtitle>
                        <!-- <span *ngIf='gameDriveLength==="BIRDIE"'>Birdie Game</span> -->
                        <span *ngIf='gameDriveLength==="PAR"'>Par Game</span>
                        <span *ngIf='gameDriveLength==="137"'>137m</span>
                        <span *ngIf='gameDriveLength==="150"'>150m</span>
                        <span *ngIf='gameDriveLength==="180"'>180m</span>
                        <span *ngIf='gameDriveLength==="192"'>192m</span>
                    </mat-card-subtitle>
                    
                </mat-card-header>
            </mat-card>
        </div>

        <!-- <div #infoPanelGameChoice [hidden]="!loaded" >  
            <mat-expansion-panel class='game-choice-panel' [expanded]="choicePanelOpenState">
                <mat-expansion-panel-header>
                    <mat-panel-title class='panel-title'>
                        Game selection:
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div fxLayout="row" fxLayoutAlign="start center" class='choice-button-row'>
                    <button #menBtn mat-fab [ngClass]="{'game-choice-option-selected': gameGender==='M'}" (click)='queryGameGender("M")' [disabled]='false' aria-label="Icon button with a male icon">
                        <i class="fa fa-male fa-2x" aria-hidden="true"></i>
                    </button>
                    <button #womenBtn mat-fab [ngClass]="{'game-choice-option-selected': gameGender==='F'}" (click)='queryGameGender("F")' [disabled]='false' aria-label="Icon button with a female icon">
                        <i class="fa fa-female fa-2x" aria-hidden="true"></i>
                    </button>
                </div>
                <div fxLayout="row" fxLayoutAlign="start center" class='choice-button-row'>
                    <button mat-fab 
                        (click)='queryTeeMarkerType("BLACK")' 
                        class='game-choice-button-black'
                        [ngClass]="{'teeMarker-choice-button-selected': gameTeeMarkerType==='BLACK'}" 
                        [disabled]='gameGender=="F"'>
                    </button>
                    <button mat-fab 
                        (click)='queryTeeMarkerType("BLUE")' 
                        class='game-choice-button-blue'
                        [ngClass]="{'teeMarker-choice-button-selected': gameTeeMarkerType==='BLUE'}" 
                        [disabled]='false'>
                    </button>
                    <button mat-fab 
                        (click)='queryTeeMarkerType("WHITE")' 
                        class='game-choice-button-white' 
                        [ngClass]="{'teeMarker-choice-button-selected': gameTeeMarkerType==='WHITE'}" 
                        [disabled]='gameGender=="F"'>
                    </button>
                    <button mat-fab 
                        (click)='queryTeeMarkerType("RED")' 
                        class='game-choice-button-red' 
                        [ngClass]="{'teeMarker-choice-button-selected': gameTeeMarkerType==='RED'}" 
                        [disabled]='false'>
                    </button>                   
                    <button mat-fab 
                        (click)='queryTeeMarkerType("GOLD")' 
                        class='game-choice-button-gold' 
                        [ngClass]="{'teeMarker-choice-button-selected': gameTeeMarkerType==='GOLD'}" 
                        [disabled]='gameGender=="M"'>
                    </button>                  
                </div>
                <div fxLayout="row" fxLayoutAlign="start center" class='choice-button-row'>
                    <button #birdieBtn mat-fab [ngClass]="{'game-choice-option-selected': gameDriveLength==='BIRDIE'}" (click)='queryGameDriveLength("BIRDIE")' [disabled]='queryDriveLengthBtnBirdieDisabled'>Birdie</button>
                    <button #parBtn mat-fab [ngClass]="{'game-choice-option-selected': gameDriveLength==='PAR'}" (click)='queryGameDriveLength("PAR")' [disabled]='queryDriveLengthBtnParDisabled'>Par</button>
                    <button #135Btn mat-fab [ngClass]="{'game-choice-option-selected': gameDriveLength==='137'}" (click)='queryGameDriveLength("137")' [disabled]='queryDriveLengthBtn137Disabled'>137m</button>
                    <button #150Btn mat-fab [ngClass]="{'game-choice-option-selected': gameDriveLength==='150'}" (click)='queryGameDriveLength("150")' [disabled]='queryDriveLengthBtn150Disabled'>150m</button>
                    <button #180Btn mat-fab [ngClass]="{'game-choice-option-selected': gameDriveLength==='180'}" (click)='queryGameDriveLength("180")' [disabled]='queryDriveLengthBtn180Disabled'>180m</button>
                </div>
            </mat-expansion-panel>
        </div> -->

        <div #infoPanelHoleStats [hidden]="!loaded" > 
            <span *ngIf="!holeStatsPanelCollapsed">
                <mat-card *ngIf='gameFairwayNumber<=18' class="hole-stats-panel" >
                    <mat-card-header>
                        <div mat-card-avatar class="card-header-image"
                            [ngClass]="{
                                'card-header-image-red':gameTeeMarkerType === 'RED',
                                'card-header-image-black':gameTeeMarkerType === 'BLACK',
                                'card-header-image-blue':gameTeeMarkerType === 'BLUE',
                                'card-header-image-white':gameTeeMarkerType === 'WHITE',
                                'card-header-image-gold':gameTeeMarkerType === 'GOLD'
                            }">
                            <span class='hole-stats-header-text'>{{gameFairwayNumber}}</span>
                        </div>
                        <mat-card-title>
                            <span *ngIf='gameGender=="M"'>Par: {{selectedFeaturesTeeMarker.MenPar}}</span>
                            <span *ngIf='gameGender=="F"'>Par: {{selectedFeaturesTeeMarker.WomenPar}}</span>
                            <span>&nbsp;&nbsp;Distance: {{selectedFeaturesTeeMarker.Length}} metres</span>
                        </mat-card-title>
                        <mat-card-subtitle>
                            <span *ngIf='gameGender=="M"'>
                                <div>Index: {{selectedFeaturesTeeMarker.MenIndex}}</div>
                            </span>
                            <span *ngIf='gameGender=="F"'>
                                <div>Index: {{selectedFeaturesTeeMarker.WomenIndex}}</div>
                            </span>
                        </mat-card-subtitle>
                    </mat-card-header>  
                    <mat-card-actions>
                        <div fxLayout='row' fxLayoutAlign='space-evenly center'>
                            <button mat-mini-fab color="primary" (click)='goToCameraPosition()' aria-label="Example icon button with a menu icon">
                                <mat-icon>home</mat-icon>
                            </button>
                            <button mat-mini-fab color="primary" (click)="openGameInfoDialog()" aria-label="Example icon button with a menu icon" [disabled]='gameGuideInfoButtonDisabled'>
                                <mat-icon>info</mat-icon>
                            </button>
                            <button mat-mini-fab color="primary" (click)="openGameFlyoverDialog()" aria-label="Button with a flyover aeroplane icon" [disabled]='flyoverVideoButtonDisabled'>
                                <mat-icon>flight</mat-icon>
                            </button>

                            <button mat-mini-fab color="primary" (click)="openGameInfoVideoDialog()" aria-label="Example icon button with a menu icon" [disabled]='gameGuideVideoButtonDisabled'>
                                <mat-icon>videocam</mat-icon>
                            </button>
                         
                            <button mat-mini-fab color="primary"  aria-label="Map icon button with a menu icon" (click)="openMapGalleryBottomSheet()"> <!-- (click)="openBottomSheet()" [disabled]='gameGalleryButtonDisabled' -->
                                <mat-icon matTooltip='Open a map gallery for this hole in the selected game'>map</mat-icon>
                            </button>

                            <button mat-mini-fab color="primary" (click)="openGameNotesDialog()" aria-label="Example icon button with a menu icon" [disabled]='false'>
                                <mat-icon>add_comment</mat-icon>
                            </button>

                            <button mat-mini-fab color="primary" (click)="holeStatsPanelCollapsed=true" aria-label="Example icon button with a menu icon" [disabled]='false'>
                                <mat-icon>close</mat-icon>
                            </button>
                        </div>
                    </mat-card-actions>
                </mat-card>
            </span>
        </div>

        <!-- <button mat-mini-fab color="primary" aria-label="Example icon button with a menu icon" [disabled]='true'>
            <mat-icon>golf_course</mat-icon>
        </button> -->

        <!-- <button mat-mini-fab color="primary" aria-label="Example icon button with a menu icon" [disabled]='true'>
            <mat-icon>print</mat-icon>
        </button> -->

        <div #infoPanelHoleChoice [hidden]="!loaded"> 
            <div>{{tmp_cameraSettings}}</div>
            <mat-card class="hole-choice-panel">
                <mat-card-content>    
                    <div fxLayout="row" fxLayoutAlign="space-evenly center"> 
                        <span class='hole-choice-title'>Hole:</span>

                        <!-- <mat-slider
                            [disabled]="holeSlider_disabled"
                            [invert]="holeSlider_invert"
                            [max]="holeSlider_max"
                            [min]="holeSlider_min"
                            [step]="holeSlider_step"
                            [thumbLabel]="holeSlider_thumbLabel"
                            [tickInterval]="1"
                            [vertical]="holeSlider_vertical"
                            (change)='holeSliderChangedEvent($event)'>     
                        </mat-slider> -->

                        <div class='hole-slider' [ngClass]="{'dark': darkMode}" style="width: 450px;">
                            <ngx-slider
                                [(options)]="holeSliderOptions"
                                (userChangeEnd)="holeSliderChangedEvent($event)">
                            </ngx-slider>
                        </div>

                        <mat-slide-toggle color="primary" [checked]="zoomToHole" (change)="onToggleChange($event)">
                            <span class='hole-choice-toggle'>Go to hole on selection</span>
                        </mat-slide-toggle>
                        <!-- <button mat-raised-button color='warn' (click)='tmp_cameraButtonEvent()'>Ask Karen</button> -->
                    </div>
                </mat-card-content>
            </mat-card>
        </div>

    </div>
    <!-- End LHS -->
    
    <!-- RHS - Info -->
    <!-- <div fxFlex='40' fxFlex.lt-lg='790px' fxLayout='column' fxLayoutAlign='space-between none' class='results-border'>
    </div> -->
    <!-- End RHS -->
</div>

