import { Injectable } from '@angular/core';
import { FeaturesService } from 'src/app/_esri/services/features.service';
import { FeatureLayerProperties } from 'src/app/_esri/models/esri';
import { Course } from '../models/course';

import { Building, Footpath } from 'src/app/asset-register/models/asset-register';

@Injectable({
  providedIn: 'root'
})

export class CourseService {

  constructor(
    private featuresService: FeaturesService
  ) { }

  public async loadGeneralHoleAreaLayer(is3D: boolean): Promise<any> {
    let featureLayer;

    // The course layers
    let featureLayerProperties = Course.createLayerProperties_GeneralHoleArea(is3D);

    await this.featuresService.loadLayer(is3D, featureLayerProperties).then(layer => {
      console.log('Loading GeneralHoleArea Layer');
      featureLayer = layer;
    });

    return featureLayer;
  }

  public async loadFairwayLayer(is3D: boolean, labelsOnly?: boolean): Promise<any> {
    let featureLayer;

    // The course layers
    let featureLayerProperties = Course.createLayerProperties_Fairway(is3D, 1.0, labelsOnly);

    await this.featuresService.loadLayer(is3D, featureLayerProperties).then(layer => {
      console.log('Loading Fairway Layer');
      featureLayer = layer;
    });

    return featureLayer;
  }

  public async loadCourseLayers(is3D: boolean): Promise<any> {
    let featureLayers: any[] = [];

    // Surrounding layers
    let featureLayerProperties_Course: FeatureLayerProperties[] = [];
    featureLayerProperties_Course = Course.createLayerPropertiesList(is3D, 0.4);

    await this.featuresService.loadLayers(is3D, featureLayerProperties_Course).then(layers => {
        console.log('Loading Course Layers');
        featureLayers = layers;
    });

    return featureLayers;
  }

  public async loadBaseAssetLayers(is3D: boolean, opacity?: number): Promise<any> {
    let assetLayers: any[] = [];

    let featureLayer_Building = new Building();
    let featureLayer_Footpath = new Footpath();

    featureLayer_Building.whereClause = "Status = 'EXISTING'"
    featureLayer_Footpath.whereClause = "Status = 'EXISTING'"

    await featureLayer_Building.getFeatureLayerConfig();
    await featureLayer_Footpath.getFeatureLayerConfig();

    assetLayers.push(featureLayer_Footpath.featureLayer);
    assetLayers.push(featureLayer_Building.featureLayer);

    return assetLayers;
  }

  public async loadCourseLayers_Tiles(is3D: boolean, opacity?: number): Promise<any> {
    let tileLayers: any[] = [];

    Course.TileLayers.opacity = opacity;

    await this.featuresService.loadLayer(is3D, Course.TileLayers).then(layers => {
      console.log('Loading Course Tile Layers');
      tileLayers.push(layers);
    })
    .catch(function(error){
      console.log("Loading Course Tile Layers - Error details: ", error.details);
    });

    return tileLayers;
  }

  public async load3dTrees(): Promise<any> {

    let featureLayer: any;
    const is3D = true;

    //https://tiles.arcgis.com/tiles/GEY7BOibXkcbWcpS/arcgis/rest/services/Hillshade/MapServer
    //https://tiles.arcgis.com/tiles/GEY7BOibXkcbWcpS/arcgis/rest/services/Tree/SceneServer
    //https://tiles.arcgis.com/tiles/GEY7BOibXkcbWcpS/arcgis/rest/services/Tree/SceneServer?token=

    let featureLayerProperties = new FeatureLayerProperties('https://tiles.arcgis.com/tiles/GEY7BOibXkcbWcpS/arcgis/rest/services', 'Tree', '', 'sceneLayerTrees', 'Trees');

    featureLayerProperties.url = 'https://tiles.arcgis.com/tiles/GEY7BOibXkcbWcpS/arcgis/rest/services/Tree/SceneServer';

    //console.log('load3dTrees() featureLayerProperties', featureLayerProperties);


    console.log('Loading Trees 3D Layer url:', featureLayerProperties.url);
    //featureLayerProperties.sceneService();

    // Retrieve and load the feature layers from the ArcGIS server(s)
    await this.featuresService.loadLayer(is3D, featureLayerProperties).then(layer => {
        //console.log('Loading Trees 3D Layer');
        //console.log(layer);
        featureLayer = layer;
    });

    return featureLayer;

  }

  public async load3dBuildings(): Promise<any> {

    let featureLayer: any;
    const is3D = true;

    let featureLayerProperties = new FeatureLayerProperties('https://tiles.arcgis.com/tiles/GEY7BOibXkcbWcpS/arcgis/rest/services', 'Building', '', 'sceneLayerBuilding', 'Buildings');

    featureLayerProperties.url = 'https://tiles.arcgis.com/tiles/GEY7BOibXkcbWcpS/arcgis/rest/services/Building/SceneServer';

    //console.log('load3dBuildings() featureLayerProperties', featureLayerProperties);

    console.log('Loading Buildings 3D Layer url:', featureLayerProperties.url);
    //featureLayerProperties.sceneService();

    // Retrieve and load the feature layers from the ArcGIS server(s)
    await this.featuresService.loadLayer(is3D, featureLayerProperties).then(layer => {
        //console.log('Loading Buildings 3D Layer');
        //console.log(layer);
        featureLayer = layer;
    });

    return featureLayer;

  }

}
