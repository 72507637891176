import { Output, EventEmitter } from '@angular/core';
import { combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Esri, FormFieldConfig, FieldConfig, FieldDateFormat, FieldNumberFormat, Domain, SubDomain } from 'src/app/_esri/models/esri';
import { LayerIds } from 'src/app/_esri/models/layer-ids';
import { Global } from 'src/app/_globals/models/global';
import { LovDataService } from 'src/app/_globals/services/lov-data.service';
import { DomainLovService } from 'src/app/_esri/services/domain-lov.service';
import { FeatureLayerObjectBaseAttributes, FeatureLayerObjectBase } from 'src/app/_esri/models/base-feature-class';

// Common Feature Layers

//
// Staff
//

export class StaffLOV extends FeatureLayerObjectBase {
    server = LayerIds.gisServer_Staff;
    folder = LayerIds.folder_Staff;
    layerId = LayerIds.layerId_TblStaff;
    id = 'tableStaff_LOV';
    title = 'Staff LOV';
  
    whereClause = "ClubId='" + Global.clubId + "' AND Active <> 'NO'";
    outFields = ['UserId', 'Description'];
  
    public static async createFormFieldConfig(): Promise<FormFieldConfig[]> {
      let formFieldConfigs: FormFieldConfig[] = [];
      let formFieldConfig: FormFieldConfig;
      let fieldConfigs: FieldConfig[] = [];
      let fieldConfig: FieldConfig;
      //let format: any;
      //let whereClause: string = '';
      //let outFields: string[] = [];
  
      fieldConfig = new FieldConfig('UserId', 'User Id', 'text');
      fieldConfig.required = true;
      fieldConfig.maxLength = 20;
      fieldConfigs.push(fieldConfig);
  
      fieldConfig = new FieldConfig('Description', 'Description', 'text');
      fieldConfig.required = true;
      fieldConfig.maxLength = 200;
      fieldConfigs.push(fieldConfig);
  
      fieldConfig = new FieldConfig('ContactNumber', 'Contact Number:', 'text');
      fieldConfig.maxLength = 20;
      fieldConfigs.push(fieldConfig);
  
      fieldConfig = new FieldConfig('EmailAddress', 'Email Address:', 'text');
      fieldConfig.maxLength = 100;
      fieldConfigs.push(fieldConfig);
  
      formFieldConfig = new FormFieldConfig('Description', fieldConfigs);
      formFieldConfigs.push(formFieldConfig);
  
      // ArcGIS WorkForce Integration
  
      fieldConfigs = [];
  
      fieldConfig = new FieldConfig('WorkForce_UserId', 'WorkForce UserId:', 'text');
      fieldConfig.maxLength = 38;
      fieldConfigs.push(fieldConfig);
  
      fieldConfig = new FieldConfig('WorkForce_WorkerGlobalId', 'WorkForce WorkerGlobalId:', 'text');
      fieldConfig.maxLength = 38;
      fieldConfigs.push(fieldConfig);
  
      fieldConfig = new FieldConfig('WorkForce_DispatcherGlobalId', 'WorkForce DispatcherGlobalId:', 'text');
      fieldConfig.maxLength = 38;
      fieldConfigs.push(fieldConfig);
  
      formFieldConfig = new FormFieldConfig('WorkForce', fieldConfigs);
      formFieldConfigs.push(formFieldConfig);
  
      return formFieldConfigs;
    }
}


// export class ContractorLOV extends FeatureBase {
//   server = LayerIds.gisServer_Contractor;
//   folder = LayerIds.folder_Contractor;
//   layerId = LayerIds.layerId_TblContractor;
//   id = 'tableContractor_LOV';
//   title = 'Contractor LOV';

//   whereClause = "ClubId='" + Global.clubId + "' AND Active <> 'NO'";
//   outFields = ['UserId', 'Description'];

//   public static async createFormFieldConfig(): Promise<FormFieldConfig[]> {
//     let formFieldConfigs: FormFieldConfig[] = [];
//     let formFieldConfig: FormFieldConfig;
//     let fieldConfigs: FieldConfig[] = [];
//     let fieldConfig: FieldConfig;
//     //let format: any;
//     //let whereClause: string = '';
//     //let outFields: string[] = [];

//     fieldConfig = new FieldConfig('ContractorId', 'Contractor Id', 'text');
//     fieldConfig.required = true;
//     fieldConfig.maxLength = 20;
//     fieldConfigs.push(fieldConfig);

//     fieldConfig = new FieldConfig('CompanyName', 'Company Name', 'text');
//     fieldConfig.required = true;
//     fieldConfig.maxLength = 200;
//     fieldConfigs.push(fieldConfig);

//     fieldConfig = new FieldConfig('Description', 'Description', 'text');
//     fieldConfig.required = true;
//     fieldConfig.maxLength = 200;
//     fieldConfigs.push(fieldConfig);

//     fieldConfig = new FieldConfig('ABN', 'ABN:', 'text');
//     fieldConfig.maxLength = 20;
//     fieldConfigs.push(fieldConfig);

//     fieldConfig = new FieldConfig('ACN', 'ACN:', 'text');
//     fieldConfig.maxLength = 20;
//     fieldConfigs.push(fieldConfig);

//     fieldConfig = new FieldConfig('ContactNumber', 'Contact Number:', 'text');
//     fieldConfig.maxLength = 20;
//     fieldConfigs.push(fieldConfig);

//     fieldConfig = new FieldConfig('EmailAddress', 'Email Address:', 'text');
//     fieldConfig.maxLength = 100;
//     fieldConfigs.push(fieldConfig);

//     fieldConfig = new FieldConfig('WebSiteURL', 'Web Site URL:', 'text');
//     fieldConfig.maxLength = 100;
//     fieldConfigs.push(fieldConfig);

//     formFieldConfig = new FormFieldConfig('Description', fieldConfigs);
//     formFieldConfigs.push(formFieldConfig);

//     return formFieldConfigs;
//   }
// }

//
// Contacts - Base Class
//

export abstract class ContactBaseAttributes extends FeatureLayerObjectBaseAttributes {
  //public Salutation: string;
  public FirstName: string;
  public LastName: string;
  public FullName: string;
  public Position: string;
  public Company: string;
  public FullAddress: string;
  public AddressLine1: string;
  public AddressLine2: string;
  public POBox: string;
  public Suburb: string;
  public City: string;
  public State: string;
  public Postcode: string;
  public County: string;
  public Country: string;
  public HomeNumber: string;
  public MobileNumber: string;
  public WorkNumber: string;
  public FaxNumber: string;
  public Email: string;
  public Latitude: number;
  public Longitude: number;
  public SalutationTitle?: string;

  constructor(
    protected lovDataService: LovDataService,
    protected domainLovService: DomainLovService
  ) {
    super(
      lovDataService,
      domainLovService
    );
  }

  // Virtual Fields

  get FullAddress_Display(): string {
    if (this.FullAddress) {
      return this.FullAddress;
    }
    else {
      return Global.formatFullAddress(this.AddressLine1, this.AddressLine2, this.Suburb, this.State, this.Postcode, this.Country);
    }
  }
}

export abstract class ContactBase extends FeatureLayerObjectBase {
  attributes: ContactBaseAttributes[];

  protected partNameRequired: boolean = false;
  protected showPartName: boolean = false;

  protected fullNameRequired: boolean = false;
  protected showFullName: boolean = false;

  protected showPartAddress: boolean = false;
  protected showCountry: boolean = false;

  protected async createFormFieldConfigs(): Promise<FormFieldConfig[]> {

    let formFieldConfig: FormFieldConfig;
    let fieldConfigs: FieldConfig[] = [];
    let fieldConfig: FieldConfig;
    let format: any;

    // Name

    fieldConfigs = [];

    fieldConfig = new FieldConfig('SalutationTitle', 'Title', 'text');
    //fieldConfig.domain = 'DomSalutation';
    fieldConfig.required = this.partNameRequired;
    fieldConfig.visible = this.showPartName;
    fieldConfig.maxLength = 10;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('FirstName', 'First Name', 'text');
    fieldConfig.required = this.partNameRequired;
    fieldConfig.visible = this.showPartName;
    fieldConfig.maxLength = 150;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('LastName', 'Last Name', 'text');
    fieldConfig.required = this.partNameRequired;
    fieldConfig.visible = this.showPartName;
    fieldConfig.maxLength = 150;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('FullName', 'Name', 'text');
    fieldConfig.required = this.fullNameRequired;
    fieldConfig.visible = this.showFullName;
    fieldConfig.maxLength = 150;
    fieldConfig.includeInTable = true;
    fieldConfig.includeInTableIndex = 0;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('Position', 'Position', 'text');
    fieldConfig.maxLength = 150;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('Company', 'Company', 'text');
    fieldConfig.maxLength = 150;
    fieldConfigs.push(fieldConfig);

    formFieldConfig = new FormFieldConfig('Name', fieldConfigs);
    this.formFieldConfigs.push(formFieldConfig);

    // Address

    fieldConfigs = [];

    fieldConfig = new FieldConfig('FullAddress', 'Full Address', 'text');   ///// Virtual??
    fieldConfig.maxLength = 255;
    fieldConfig.geocode = true;
    //fieldConfig.domain_VirtualFieldName = 'FullAddress_Display';
    fieldConfig.includeInTable = true;
    fieldConfig.includeInTableIndex = 3;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('AddressLine1', 'Address Line 1', 'text');
    fieldConfig.maxLength = 100;
    fieldConfig.visible = this.showPartAddress;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('AddressLine2', 'Address Line 2', 'text');
    fieldConfig.maxLength = 100;
    fieldConfig.visible = this.showPartAddress;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('Suburb', 'Suburb', 'text');
    fieldConfig.maxLength = 50;
    fieldConfig.visible = this.showPartAddress;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('City', 'City', 'text');
    fieldConfig.maxLength = 50;
    fieldConfig.visible = this.showPartAddress;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('State', 'State', 'text');
    fieldConfig.maxLength = 10;
    fieldConfig.domain = 'DomState';
    fieldConfig.defaultValue = 'NSW';
    fieldConfig.visible = this.showPartAddress;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('Postcode', 'Postcode', 'number');
    fieldConfig.maxLength = 10;
    fieldConfig.visible = this.showPartAddress;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('Country', 'Country', 'text');
    fieldConfig.maxLength = 100;
    fieldConfig.defaultValue = 'Australia';
    fieldConfig.visible = this.showCountry;
    fieldConfigs.push(fieldConfig);

    formFieldConfig = new FormFieldConfig('Address', fieldConfigs);
    this.formFieldConfigs.push(formFieldConfig);

    // Contact Details

    fieldConfigs = [];

    fieldConfig = new FieldConfig('HomeNumber', 'Home Number', 'tel');
    fieldConfig.maxLength = 15;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('MobileNumber', 'Mobile Number', 'tel');
    fieldConfig.maxLength = 15;
    fieldConfig.includeInTable = true;
    fieldConfig.includeInTableIndex = 2;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('WorkNumber', 'Work Number', 'tel');
    fieldConfig.maxLength = 15;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('FaxNumber', 'Fax Number', 'tel');
    fieldConfig.maxLength = 15;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('Email', 'Email', 'email');
    fieldConfig.maxLength = 150;
    fieldConfigs.push(fieldConfig);

    formFieldConfig = new FormFieldConfig('Contact', fieldConfigs);
    this.formFieldConfigs.push(formFieldConfig);

    // Virtual

    fieldConfigs = [];

    formFieldConfig = new FormFieldConfig('Virtual', fieldConfigs);
    this.formFieldConfigs.push(formFieldConfig);


    // Hidden

    fieldConfigs = [];

    fieldConfig = new FieldConfig('Latitude', 'Latitude:', 'number');
    format = new FieldNumberFormat(8);
    fieldConfig.format = format;
    fieldConfig.visible = false;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('Longitude', 'Longitude:', 'number');
    format = new FieldNumberFormat(8);
    fieldConfig.format = format;
    fieldConfig.visible = false;
    fieldConfigs.push(fieldConfig);

    formFieldConfig = new FormFieldConfig('Hidden', fieldConfigs);
    formFieldConfig.visible = false;
    this.formFieldConfigs.push(formFieldConfig);

    super.createFormFieldConfigs();

    console.log('Contact Base this.formFieldConfigs', this.formFieldConfigs);

    return this.formFieldConfigs;
  }


}
  