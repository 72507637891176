import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { RiskComponent } from './risk.component';
//import { RiskRegisterComponent } from './components/risk-register/risk-register.component';
//import { IncidentRegisterComponent } from './components/incident-register/incident-register.component';

const routes: Routes = [
  { path: '', component: RiskComponent },
  // { path: 'register', component: RiskRegisterComponent },
  // { path: 'incident', component: IncidentRegisterComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RiskRoutingModule { }
