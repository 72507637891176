import { Injectable, Output, EventEmitter } from '@angular/core';
import { combineLatest, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Esri, FeatureLayerProperties, ElevationInfo, EsriRenderer_Point, FormFieldConfig, FieldConfig, FieldConfigTypes, FeatureServiceQueryResponse, FieldDateFormat, FieldNumberFormat, GeometryPoint } from 'src/app/_esri/models/esri';
import { ApplyEditsResultMessage, ApplyEditsResult, SpatialRelationship, EsriSRUnit, Extent, Feature, Domain, CodedValue, SubDomain, ChildDomain, EsriGeometryType, GeometryMultiPoint, GeometryPolyline, GeometryPolygon, AttachmentInfo, DashboardChart, ReportDefinition } from 'src/app/_esri/models/esri';
import { FeaturesService } from 'src/app/_esri/services/features.service';
import { DomainLovService } from 'src/app/_esri/services/domain-lov.service';
import { LovDataService } from 'src/app/_globals/services/lov-data.service';

import { Global } from 'src/app/_globals/models/global';


@Injectable()
export abstract class FeatureLayerObjectBaseAttributes {

  // System
  //protected ngUnsubscribe = new Subject<void>();

  // Common Attributes
  public ClubId: string;
  public HasLocation: string;
  public Active: string;

  // ESRI created fields
  public OBJECTID: number;
  public GlobalID: string;
  public CreationDate: Date;
  public Creator: string;
  public EditDate: Date;
  public Editor: string;

  constructor(
    protected lovDataService: LovDataService,
    protected domainLovService: DomainLovService
  ) {
    // // Domains and LOV's
    // this.lovDataService.lovData.pipe(takeUntil(this.ngUnsubscribe)).subscribe((lovData: any) => {
    //   console.log('FeatureAttributes lovDataService received lovData', lovData);
    //   this.domainLovService.domains = lovData.domains;
    //   this.domainLovService.domains_lov = lovData.domains_lov;
    //   this.domainLovService.domains_lovSubDomain = lovData.domains_lovSubDomain;
    // });
  }

  // public destroyer(): void {
  //   console.log('FeatureAttributes destroyer()');
  //   // Unsubscribe from events / observables
  //   this.ngUnsubscribe.next();
  //   this.ngUnsubscribe.complete();
  // }
}

@Injectable()
export abstract class FeatureLayerObjectBase {

  // System
  protected ngUnsubscribe = new Subject<void>();

  // ArcGIS Server connection info
  protected server: string;
  protected folder: string;
  protected layerId: string;
  protected id: string;
  protected title: string;
  protected layerType?: string;
  public layerProperties: FeatureLayerProperties;

  // Layer display info
  protected is3D: boolean = false;
  protected elevationInfoMode: string = 'relative-to-scene';
  protected returnM: boolean = true;
  protected returnZ: boolean = true;
  protected visible: boolean = true;
  protected minScale: number = 10000;
  protected popupEnabled: boolean = true;
  protected legendEnabled: boolean = true;
  protected labelsVisible: boolean = true;
  protected labelInfo: any;
  protected clusterConfig: any;
  protected renderer: any;
  protected featureReduction: any;
  protected opacity: number = 1;

  // Attribute / Field Definitions
  protected fieldConfigTypes: FieldConfigTypes;
  protected formFieldConfigs: FormFieldConfig[] = [];

  // Data returned from ArcGIS Server
  public featureLayer: any;
  public features: Feature[] = [];
  public attributes: any[] = [];
  protected geometry: any[] = [];
  protected geometryType: string; // = EsriGeometryType.Point; // enum EsriGeometryType

  protected outFields: any[] = ['*'];
  public domains: Domain[] = [];
  public domains_lov: Domain[] = [];
  public domains_lovSubDomain: SubDomain[] = [];
  protected extent: any;
  protected timeExtent: any;
  // public dateRangeInfo: any = {};
  // public dateFrom: Date;
  // public dateTo: Date;
  public whereClause: string = "1=1";
  public orderByClause: string = "CreationDate DESC";
  protected definitionExpression: string = "ACTIVE <> 'NO'";
  protected loaded: boolean = false;

  // Miscellaneous Other
  protected noLocationPoint = new GeometryPoint(151.2125795, -33.93366923);
  private _featuresService: FeaturesService = new FeaturesService();

  protected lovDataService: LovDataService = new LovDataService();
  protected domainLovService: DomainLovService = new DomainLovService(this.lovDataService);

  protected idType: string;
  protected idTypeFieldName: string;
  protected fieldChangeWatchList = []; // e.g.: ['AuditCoursePart', 'FairwayNumber', ...]
  protected parentLovWatchList = [];   // e.g.: [{parentLovKey: 'RiskCategoryId', childLovName: 'LovRiskSubCategory', childLovKey: 'RiskSubCategoryId'}, {..}, {..}]\  
  protected defaultFieldValues: any[] = [];
  protected defaultFieldVisibility: any[] = [];

  @Output() configReadyEvent = new EventEmitter<any>();
  @Output() domainReadyEvent = new EventEmitter<any>();
  @Output() lovReadyEvent = new EventEmitter<any>();
  @Output() dataReadyEvent = new EventEmitter<any>();

  @Output() featureLayerLoadedEvent = new EventEmitter<any>();
  @Output() featureLayerLoadedWithDataEvent = new EventEmitter<any>();

  // labelInfo = { 
  //     symbol: Esri.text12pxWithHalo,
  //     labelExpressionInfo: {
  //         expression: "$feature.OBJECTID"  
  //     },
  //     maxScale: 0,
  //     minScale: 4000,
  //     where: "1=1"
  // };

  constructor() {
    // if (whereClause) {
    //   this.whereClause = whereClause;
    // }

    combineLatest([
      this.configReadyEvent,
      this.lovReadyEvent,
      this.domainReadyEvent,
    ]).pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.broadcastLovData();
      this.loaded = true;
      console.log('FeatureBase constructor() this.loadedEvent.emit', this.layerProperties.id);
      this.featureLayerLoadedEvent.emit(true);
    });

    combineLatest([
      this.featureLayerLoadedEvent,
      this.dataReadyEvent
    ]).pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.featureLayerLoadedWithDataEvent.emit(true);
    });
  }

  public destroyer(): void {
    console.log('FeatureBase destroyer()');
    // Unsubscribe from events / observables
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  //
  // LOV's / Domains
  //

  protected broadcastLovData() {
    // Send the domains and lov to the service 
    const lovData = {
      domains: this.domains,
      domains_lov: this.domains_lov,
      domains_lovSubDomain: this.domains_lovSubDomain
    }

    this.lovDataService.lovData.next(lovData);
    //this.lovReadyEvent.emit();
    console.log('FeatureBase broadcastDomains()');
  }

  public async getDomain(domainName: string, domainType: string): Promise<Domain> {
    return await this.domainLovService.createDomainLov(domainName, domainType, this.features, this.outFields);
  }

  public async getSubDomain(domainName: string, domainType: string): Promise<SubDomain> {
    return await this.domainLovService.createSubDomainLov(domainName, domainType, this.features, this.outFields);
  }

  //
  // Layers
  //

  // // Get layer config and data
  // public async getFeatureLayerData(): Promise<any> {
  //   //console.log('************* -----------> getFeatureLayerData <------------------ ***************', this);

  //   await this.getFeatureLayerConfig().then(async () => {
  //     // Query Layer and Extent
  //     await this.queryFeatureLayer(this.whereClause);

  //     // Get timeExtent for time enabled layers
  //     if (this.featureLayer.timeInfo) {
  //       await this.queryFeatureLayerTimeExtent(this.whereClause);
  //     }
  //   });

  //   this.featureLayerDataReadyEvent.emit();
  //   return;
  // }

  // Get layer config and data
  public async getFeatureLayerData(): Promise<any> {
    //console.log('************* -----------> getFeatureLayerData <------------------ ***************', this);
    // Query Layer
    await this.queryFeatureLayer(this.whereClause);
    this.dataReadyEvent.emit();
    return;
  }

  // Get layer config and data
  public async getFeatureLayerConfigAndData(): Promise<any> {
    //console.log('************* -----------> getFeatureLayerConfigAndData <------------------ ***************', this);

    await this.getFeatureLayerConfig().then(async () => {
      // Query Layer and Extent
      await this.queryFeatureLayer(this.whereClause);
    });

    this.dataReadyEvent.emit();
    return;
  }

  // Get layer config
  public async getFeatureLayerConfig(): Promise<any> {
    //console.log('************* -----------> getFeatureLayerConfig <------------------ ***************', this);
    this.layerProperties = new FeatureLayerProperties(
      this.server, this.folder, this.layerId, this.id, this.title, this.layerType
    );

    console.log('FeatureBase getFeatureLayerConfig() 1', this.layerProperties.id);

    if (this.is3D === true) {
      let elevationInfo = new ElevationInfo();

      if (!this.layerProperties.elevationInfoMode) {
        elevationInfo.mode = 'relative-to-scene';
      }

      //elevationInfo.mode = 'on-the-ground'; // <-- assets
      this.layerProperties.elevationInfo = elevationInfo;
      this.layerProperties.returnM = this.returnM;
      this.layerProperties.returnZ = this.returnZ;
    }

    // Set the renderer / attributes and add the layers to a list
    this.layerProperties.visible = this.visible;
    this.layerProperties.minScale = this.minScale; // 10000;
    this.layerProperties.labelsVisible = this.labelsVisible;
    this.layerProperties.labelingInfo = this.labelInfo;
    this.layerProperties.popupEnabled = this.popupEnabled;
    this.layerProperties.legendEnabled = this.legendEnabled;

    if (this.featureReduction) {
      this.layerProperties.featureReduction = this.featureReduction;
    }

    if (this.renderer) {
      this.layerProperties.renderer = this.renderer;
    }

    if (this.definitionExpression) {
      this.layerProperties.definitionExpression = this.definitionExpression;
    }

    // Create field configs, load layer, query layer and extent, and get the time extent
    await this.createFormFieldConfigs().then(async (response) => {
      //console.log('FeatureBase getLayer() 2', this.layerProperties.id);

      // Load the layer and 
      await this._featuresService.loadLayer(this.is3D, this.layerProperties).then(response => {
        this.featureLayer = response;

        // Wait for feature layer to load
        this.featureLayer.when(() => {
          // Get timeExtent for time enabled layers
          if (this.featureLayer.timeInfo) {
            this.timeExtent = this.featureLayer.timeInfo.fullTimeExtent;
            // this.queryFeatureLayerTimeExtent(this.whereClause).then( () => {
            //   console.log('CCCC getFeatureLayerConfig() this.featureLayer', this.featureLayer);
            // });
          }

          // 2018 2014
        });

        // Get the domains
        this.getDomains();
      });
    });

    this.configReadyEvent.emit();

    return this.featureLayer;
  }

  protected async queryFeatureLayer(whereClause: string, returnDistinct?: boolean): Promise<any> {
    //console.log('FeatureBase query() this.featureLayer whereClause this.outFields', this.featureLayer, whereClause, this.outFields);
    await this._featuresService.queryFeatureLayer(this.featureLayer, whereClause, this.orderByClause, this.outFields, returnDistinct).then((response: FeatureServiceQueryResponse) => {
      //console.log('response', response, response.featureLayerData);
      this.features = response.featureLayerData.features;
      //this.domains = response.domains;

      // Domains Ready Event
      //this.domainReadyEvent.emit();
      //console.log('FeatureBase query() this.domainReadyEvent.emit()');

      if (this.featureLayer.isTable === true) {
        this.extent = null;
        this.geometry = null;

        for (let feature of response.featureLayerData.features) {
          this.attributes.push(feature.attributes);
        }
      }
      else {
        this.extent = response.extent;
        this.attributes = [];
        this.geometry = [];

        for (let feature of response.featureLayerData.features) {
          this.attributes.push(feature.attributes);
          this.geometry.push(feature.geometry);
        }
      }
    })
      .catch((error) => {
        console.error('FeatureBase query() Promise error:', error);
      });
    return;
  }

  // protected async reQuery(whereClause: string): Promise<any> {
  //   await this._featuresService.reQueryFeatureLayer(this.featureLayer, whereClause, this.orderByClause, this.outFields).then((response: FeatureServiceQueryResponse) => {
  //     //console.log('response', response, response.featureLayerData);
  //     this.features = response.featureLayerData.features;

  //     if (this.featureLayer.isTable === true) {
  //       this.extent = null;
  //       this.geometry = null;

  //       for (let feature of response.featureLayerData.features) {
  //         this.attributes.push(feature.attributes);
  //       }
  //     }
  //     else {
  //       this.extent = response.extent;
  //       this.attributes = [];
  //       this.geometry = [];

  //       for (let feature of response.featureLayerData.features) {
  //         this.attributes.push(feature.attributes);
  //         this.geometry.push(feature.geometry);
  //       }
  //     }
  //   })
  //     .catch((error) => {
  //       console.error('FeatureBase reQuery() Promise error:', error);
  //     });
  //   return;
  // }

  protected async queryFeatureLayerExtent(whereClause?: string, objectids?: number[]): Promise<Extent> {
    await this._featuresService.queryExtent(this.featureLayer, whereClause, objectids).then((response => {
      this.extent = response;
    }));
    return;
  }

  protected async queryFeatureLayerTimeExtent(whereClause?: string, objectids?: number[]): Promise<any> {
    await this._featuresService.queryTimeExtent(this.featureLayer, whereClause, objectids).then(response => {
      this.timeExtent = response;
    });
    return this.timeExtent;
  }

  protected async saveFeatureLayer(params: any): Promise<any> {
    return await this._featuresService.applyEditsFeatureLayer(this.featureLayer, params);
  }

  protected async queryRelatedFeatureLayer(relationshipId: string, objectIds: number[], outFields?: string[]): Promise<any> {

    let queryResponse = null;

    if (!outFields) {
      outFields = ['*'];
    }

    await this._featuresService.queryRelatedFeatures(this.featureLayer, relationshipId, objectIds, outFields).then((response) => {
      console.log('queryRelated response', response);
      queryResponse = response;
    });

    return queryResponse;

    //return await this._featuresService.queryRelatedFeatures(this.featureLayer, relationshipId, objectIds, outFields);
  }

  protected async queryDistinctFeatureLayer(whereClause: string, outFields: string[]) {
    this.outFields = outFields;
    return await this.queryFeatureLayer(whereClause, true);
  }
 
  protected async queryFeatureLayerDomains(fields: string[]): Promise<Domain[]> {
    let domains: Domain[];

    //console.log('getFieldDomain() this.featureLayer, field', this.featureLayer, fields);

    await this._featuresService.getFieldDomains(this.featureLayer, fields).then((response) => {
      //console.log('getFieldDomain response', response);
      domains = response;
    });

    return domains;
  }

  //
  // Field Configs
  //

  protected async createFormFieldConfigs(): Promise<FormFieldConfig[]> {
    this.fieldConfigTypes = new FieldConfigTypes();

    let fieldConfig: FieldConfig;
    let fieldConfigs: FieldConfig[] = [];
    let formFieldConfig: FormFieldConfig;

    // Standard (and hidden)

    fieldConfigs = [];

    fieldConfig = new FieldConfig('ClubId', 'Club Id', 'text');
    fieldConfig.required = true;
    fieldConfig.editable = false;
    fieldConfig.visible = false;
    fieldConfig.defaultValue = Global.clubId;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('OBJECTID', 'OBJECTID', 'text');
    fieldConfig.editable = false;
    fieldConfig.visible = false;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('GlobalID', 'GlobalID', 'text');
    fieldConfig.maxLength = 38;
    fieldConfig.editable = false;
    fieldConfig.visible = false;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('HasLocation', 'HasLocation', 'radio-h');
    fieldConfig.visible = false;
    fieldConfig.domain = 'DomYesNo';
    fieldConfig.defaultValue = 'YES';
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('Active', 'Record Active:', 'radio-h');
    fieldConfig.visible = false;
    fieldConfig.domain = 'DomYesNo';
    fieldConfig.defaultValue = 'YES';
    fieldConfigs.push(fieldConfig);

    // ESRI Generated Fields from AGOL options:
    //  - Keep track of created and updated features.
    //  - Keep track of who created and last updated features. 

    fieldConfig = new FieldConfig('CreationDate', 'Creation Date', 'date'); ``
    fieldConfig.editable = false;
    fieldConfig.visible = false;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('Creator', 'Creator', 'text');
    fieldConfig.maxLength = 128;
    fieldConfig.editable = false;
    fieldConfig.visible = false;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('EditDate', 'Edit Date', 'date');
    fieldConfig.editable = false;
    fieldConfig.visible = false;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('Editor', 'Editor', 'text');
    fieldConfig.maxLength = 128;
    fieldConfig.editable = false;
    fieldConfig.visible = false;
    fieldConfigs.push(fieldConfig);

    formFieldConfig = new FormFieldConfig('Standard', fieldConfigs);
    formFieldConfig.visible = false;
    this.formFieldConfigs.push(formFieldConfig);

    this.fieldConfigTypes.fieldConfigs = this.createFieldConfig(this.formFieldConfigs);
    //console.log('FeatureBase fieldConfigTypes.fieldConfigs', this.fieldConfigTypes.fieldConfigs);

    this.fieldConfigTypes.tableFieldConfig = this.createFieldConfigTable(this.formFieldConfigs);
    //console.log('FeatureBase fieldConfigTypes.tableFieldConfig', this.fieldConfigTypes.tableFieldConfig);

    this.fieldConfigTypes.cardFieldConfig = this.createFieldConfigCards(this.formFieldConfigs);
    //console.log('FeatureBase fieldConfigTypes.cardFieldConfig', this.fieldConfigTypes.cardFieldConfig);

    //console.log('createFormFieldConfigs done');

    return;
  }

  private createFieldConfig(formFieldConfigs: FormFieldConfig[]) {
    return FormFieldConfig.createFieldConfig(formFieldConfigs);
  }

  private createFieldConfigTable(formFieldConfigs: FormFieldConfig[]) {
    return FormFieldConfig.createFieldConfigTable(formFieldConfigs);
  }

  private createFieldConfigCards(formFieldConfigs: FormFieldConfig[]) {
    return FormFieldConfig.createFieldConfigCards(formFieldConfigs);
  }

  //
  // Data
  //

  protected getDomains() {
    this.domains = [];
    let fields = [];

    this.formFieldConfigs.forEach((formfieldConfig) => {
      formfieldConfig.fieldConfig.forEach(fieldConfig => {
        if (fieldConfig.domain) {
          fields.push(fieldConfig.name);
        }
      });
    });

    //console.log('FeatureBase getDomains() fields', fields);

    this.queryFeatureLayerDomains(fields).then((domains) => {
      this.domains = domains;
      //console.log('FeatureBase getDomains() domains', domains);
      this.domainReadyEvent.emit();
    });
  }

  protected getLovs() {
    this.lovReadyEvent.emit(true);  // No LOV's, so emit event

    this.domains_lov = [];
    this.domains_lovSubDomain = [];

    // All LOV Domains
    // combineLatest([
    //   this.categoryLovReadyEvent,
    //   this.subCategoryLovReadyEvent,
    //   this.reportedByLovReadyEvent,
    //   this.staffLovReadyEvent
    // ]).pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
    //   console.log('WorkRequest/FeatureBase getLovs() lovsReady()');
    //   this.lovReadyEvent.emit(true);
    // });

    // let workRequestCategoryLOV: WorkRequestCategoryLOV = new WorkRequestCategoryLOV(this.lovDataService, this.domainLovService);
    // workRequestCategoryLOV.getLayer().then(() => {
    //   workRequestCategoryLOV.getDomain('LovWorkRequestCategory', 'coded-value').then((domain) => {
    //     this.domains_lov.push(domain);
    //     //console.log('getLovs() categoryLovReadyEvent.emit');
    //     this.categoryLovReadyEvent.emit();
    //   });
    // });
  }

  protected distinct(fieldName, groupByField?: string, groupByValue?: any) {

    let attributes; // = [];

    if (groupByField) {
      attributes = this.attributes.filter(x => x[groupByField] === groupByValue);
    }
    else {
      attributes = this.attributes;
    }

    const items = attributes.map(x => x[fieldName]);
    const distinctItems = [...new Set(items)]
    return distinctItems;
  }

  protected updateVirtualFields() { }


  protected async getByObjectId(objectId: number): Promise<any> {
    this.whereClause = "OBJECTID = " + objectId;
    return await this.queryFeatureLayer(this.whereClause);
  }

  protected async getById(id: string): Promise<any> {
    return await this.queryFeatureLayer(this.whereClause);
  }

  protected async getByGlobalId(globalId: string): Promise<any> {
    this.whereClause = "GlobalId = '" + globalId + "'";
    return await this.queryFeatureLayer(this.whereClause);
  }

}
