import { Injectable, Output, EventEmitter } from '@angular/core';
import { combineLatest, Subject } from 'rxjs';
import { takeUntil, tap, first } from 'rxjs/operators';
import { addDays, addBusinessDays, format } from 'date-fns';
import { LovDataService } from 'src/app/_globals/services/lov-data.service';
import { DomainLovService } from 'src/app/_esri/services/domain-lov.service';
import { Incident, IncidentAttributes, IncidentSubCategoryLOV } from 'src/app/incident/models/incident';
import { Audit, AuditAttributes } from 'src/app/audit/models/audit';
import { WorkRequest, WorkRequestAttributes, WorkRequestSubCategoryLOV } from 'src/app/maintenance/models/work-request';

@Injectable({
  providedIn: 'root'
})

export class CreateWorkRequestService {

  public workRequest: any;
  private _workRequestAttributes: any = new WorkRequestAttributes(this.lovDataService, this.domainLovService);
  private _workRequestSubCategoryLOV: WorkRequestSubCategoryLOV;
  private _incidentSubCategoryLOV: IncidentSubCategoryLOV;

  // System
  protected ngUnsubscribe = new Subject<void>();

  @Output() workRequestReadyEvent = new EventEmitter<any>();
  @Output() incidentReadyEvent = new EventEmitter<any>();
  @Output() auditReadyEvent = new EventEmitter<any>();

  //constructor() { }

  constructor(
    protected lovDataService: LovDataService,
    protected domainLovService: DomainLovService
  ) { }

  public destroyer(): void {
    console.log('CreateWorkRequestService destroyer()');
    // Unsubscribe from events / observables
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public async initialiseIncidentIntegration(): Promise<any> {
    this._incidentSubCategoryLOV = new IncidentSubCategoryLOV();
    this._incidentSubCategoryLOV.getLovs();
    this._incidentSubCategoryLOV.getFeatureLayerConfigAndData().then(() => {
      console.log('****** ! incidentSubCategoryLOV getLayer ! ******');
    });

    this._incidentSubCategoryLOV.featureLayerLoadedEvent.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.incidentReadyEvent.emit();
    });
  }

  public async initialiseAuditIntegration(): Promise<any> {
    console.log('****** ! initialiseAuditIntegration ready ! ******');
    this.auditReadyEvent.emit();
  }

  public async initialiseWorkRequestIntegration(): Promise<any> {

    this.workRequest = new WorkRequest();
    this.workRequest.getLovs();
    this.workRequest.getFeatureLayerConfig().then(() => {
      console.log('****** ! workRequest loadLayer ! ******');
      this.workRequest.configReadyEvent.emit();
    });

    this._workRequestSubCategoryLOV = new WorkRequestSubCategoryLOV();
    this._workRequestSubCategoryLOV.getLovs();
    this._workRequestSubCategoryLOV.getFeatureLayerConfigAndData().then(() => {
      console.log('****** ! _workRequestSubCategoryLOV getLayer ! ******');
    });

    combineLatest([
      this.workRequest.featureLayerLoadedEvent,
      this._workRequestSubCategoryLOV.featureLayerLoadedEvent,  
    ]).pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      //console.log('Integration Ready Event ready');
      console.log('****** ! initialiseWorkRequestIntegration ready ! ******');
      this.workRequestReadyEvent.emit();

      this.auditReadyEvent.emit();  // <-- dodgey fix for Audit integration!!!!!!
    });

    return;
  }



  public async createFromIncident(selectedFeature: any): Promise<any> {

    //console.log('****** ! incidentSubCategoryLOV loadedEvent 2! ******', this._incidentSubCategoryLOV);
    console.log('****** ! incidentSubCategoryLOV loadedEvent 3! ******', this._incidentSubCategoryLOV);

    let subCat_WorkRequest: any;
    let subCat_Incident: any;

    subCat_Incident = this._incidentSubCategoryLOV.attributes.find(x => x.IncidentCategoryId === selectedFeature.attributes.IncidentCategoryId && x.IncidentSubCategoryId === selectedFeature.attributes.IncidentSubCategoryId);
    console.log('****** ! incidentSubCategoryLOV subCat_Incident ! ******', subCat_Incident);

    // Info from incident
    //this._workRequest.attributes[0].WorkRequestDate = Date.now();
    this._workRequestAttributes.WorkRequestDate = Date.now();
    this._workRequestAttributes.IncidentId = selectedFeature.attributes.IncidentId;
    this._workRequestAttributes.FairwayNumber = selectedFeature.attributes.FairwayNumber;
    //selectedIncident.GlobalID

    this._workRequestAttributes.Comments = '<p>Work requested created from incident: ' 
      + selectedFeature.attributes.IncidentId + ' - ' 
      + selectedFeature.attributes.IncidentTitle + ', dated ' 
      + format(selectedFeature.attributes.IncidentDate, 'dd/MM/yyyy') + '.</p>';

    if (selectedFeature.attributes.IncidentDetails) {
      this._workRequestAttributes.Comments += '<p>Details:</p>' + selectedFeature.attributes.IncidentDetails;
    }

    if (selectedFeature.attributes.Comments) {
      this._workRequestAttributes.Comments += '<p>Comments:</p>' + selectedFeature.attributes.Comments;
    }

    // Get default category / sub category
    this._workRequestAttributes.WorkRequestCategoryId = subCat_Incident.DefaultWorkRequestCategoryId;
    this._workRequestAttributes.WorkRequestSubCategoryId = subCat_Incident.DefaultWorkRequestSubCategoryId;


    // Get the SLA data
    subCat_WorkRequest = this._workRequestSubCategoryLOV.attributes.find(x => x.CategoryId === subCat_Incident.DefaultWorkRequestCategoryId && x.SubCategoryId === subCat_Incident.DefaultWorkRequestSubCategoryId);  
    console.log('****** ! workRequestSubCategoryLOV subCat_WorkRequest ! ******', subCat_WorkRequest, subCat_WorkRequest.DefaultResponsible);
    this._workRequestAttributes.Responsible = subCat_WorkRequest.DefaultResponsible;
    this._workRequestAttributes.AssignedTo = subCat_WorkRequest.DefaultAssignedTo;
    this._workRequestAttributes.SLADays = subCat_WorkRequest.SLADays;

    if (subCat_WorkRequest.SLABusinessDays === 'YES') {
      this._workRequestAttributes.SLADueDate = addBusinessDays(this._workRequestAttributes.WorkRequestDate, subCat_WorkRequest.SLADays);
    }
    else {
      this._workRequestAttributes.SLADueDate = addDays(this._workRequestAttributes.WorkRequestDate, subCat_WorkRequest.SLADays);
    }
    

    // Set static fields
    this._workRequestAttributes.Source = 'INCIDENT';
    this._workRequestAttributes.Status = 2;
    this._workRequestAttributes.Priority = 2;
    this._workRequestAttributes.ReportedBy = 'STAFF';

    // To be supplied by user
    //this._workRequest.attributes.WorkRequestTitle
    //this._workRequest.attributes.WorkRequestDetails

    console.log('****** createFromIncident() ******, this._workRequest', this._workRequestAttributes);

    return this._workRequestAttributes;
  }

  public async createFromAudit(selectedFeature: any): Promise<any> {
    //let subCat_WorkRequest: any;
    //let subCat_Incident: any;

    //subCat_Incident = this._incidentSubCategoryLOV.attributes.find(x => x.IncidentCategoryId === selectedFeature.attributes.IncidentCategoryId && x.IncidentSubCategoryId === selectedFeature.attributes.IncidentSubCategoryId);
    //console.log('****** ! incidentSubCategoryLOV subCat_Incident ! ******', subCat_Incident);

    // Info from incident
    //this._workRequest.attributes[0].WorkRequestDate = Date.now();
    this._workRequestAttributes.WorkRequestDate = Date.now();

    //this._workRequestAttributes.AuditId = selectedFeature.attributes.AuditId;
    //this._workRequestAttributes.AuditId = selectedFeature.attributes.GlobalID;
    this._workRequestAttributes.AuditId = selectedFeature.attributes.AuditItemNumber;

    this._workRequestAttributes.FairwayNumber = selectedFeature.attributes.FairwayNumber;


    this._workRequestAttributes.Comments = '<p>Work requested created from audit item: ' 
      + selectedFeature.attributes.AuditItemNumber + ' (' 
      + selectedFeature.attributes.AuditId + ') - ' 
      + selectedFeature.attributes.AuditItemTitle + ', dated ' 
      + format(selectedFeature.attributes.AuditDate, 'dd/MM/yyyy') + '.</p>';

    if (selectedFeature.attributes.Suggestion) {
      this._workRequestAttributes.Comments += '<p>Suggestion:</p>' + selectedFeature.attributes.Suggestion;
    }

    if (selectedFeature.attributes.Comments) {
      this._workRequestAttributes.Comments += '<p>Comments:</p>' + selectedFeature.attributes.Comments;
    }

    if (selectedFeature.attributes.Recommendation) {
      this._workRequestAttributes.WorkRequestDetails = selectedFeature.attributes.Recommendation;
    }

    // Get default category / sub category
    //this._workRequestAttributes.WorkRequestCategoryId = subCat_Incident.DefaultWorkRequestCategoryId;
    //this._workRequestAttributes.WorkRequestSubCategoryId = subCat_Incident.DefaultWorkRequestSubCategoryId;

    // Get the SLA data
    //subCat_WorkRequest = this._workRequestSubCategoryLOV.attributes.find(x => x.CategoryId === subCat_Incident.DefaultWorkRequestCategoryId && x.SubCategoryId === subCat_Incident.DefaultWorkRequestSubCategoryId);  
    //console.log('****** ! workRequestSubCategoryLOV subCat_WorkRequest ! ******', subCat_WorkRequest, subCat_WorkRequest.DefaultResponsible);
    //this._workRequestAttributes.Responsible = subCat_WorkRequest.DefaultResponsible;
    //this._workRequestAttributes.AssignedTo = subCat_WorkRequest.DefaultAssignedTo;
    //this._workRequestAttributes.SLADays = subCat_WorkRequest.SLADays;
    // if (subCat_WorkRequest.SLABusinessDays === 'YES') {
    //   this._workRequestAttributes.SLADueDate = addBusinessDays(this._workRequestAttributes.WorkRequestDate, subCat_WorkRequest.SLADays);
    // }
    // else {
    //   this._workRequestAttributes.SLADueDate = addDays(this._workRequestAttributes.WorkRequestDate, subCat_WorkRequest.SLADays);
    // }
    
    // Set static fields
    this._workRequestAttributes.Source = 'AUDIT';
    this._workRequestAttributes.Status = 2;
    this._workRequestAttributes.Priority = 2;
    this._workRequestAttributes.ReportedBy = 'STAFF';

    // To be supplied by user
    //this._workRequest.attributes.WorkRequestTitle
    // WorkRequestCategoryId
    // WorkRequestSubCategoryId
    // Responsible
    // AssignedTo

    console.log('****** createFromAudit() ******, this._workRequest', this._workRequestAttributes);

    return this._workRequestAttributes;
  }

  public async createFromIncidentXX(selectedIncident: any): Promise<any> {

    //this._workRequest = new WorkRequest();
    //this._workRequestAttributes = new WorkRequestAttributes(this.lovDataService, this.domainLovService);


    //console.log('****** createFromIncident(), incident, incidentId', selectedIncident);
    //console.log('****** createFromIncident(), incident.attributes', selectedIncident.attributes);
    //console.log('****** createFromIncident(), incident.features', incident.features);
    //console.log('****** createFromIncident(), incident.folder', incident.folder);



    //let incidentSubCategoryLOV: IncidentSubCategoryLOV = new IncidentSubCategoryLOV();
    this._incidentSubCategoryLOV = new IncidentSubCategoryLOV();

    this._incidentSubCategoryLOV.getLovs();

    this._incidentSubCategoryLOV.getFeatureLayerConfigAndData().then(() => {
      console.log('****** ! incidentSubCategoryLOV getLayer ! ******');
    });

    // Wait for layer to load

    // this._incidentSubCategoryLOV.loadedEvent.subscribe((response: any) => {
    //   console.log('****** ! incidentSubCategoryLOV loadedEvent 1! ******', this._incidentSubCategoryLOV);
    // });

    await this._incidentSubCategoryLOV.featureLayerLoadedEvent.pipe(tap(data => {
      //console.log('****** ! incidentSubCategoryLOV loadedEvent 2! ******', this._incidentSubCategoryLOV);




      console.log('****** ! incidentSubCategoryLOV loadedEvent 3! ******', this._incidentSubCategoryLOV);

      let subCat: any;



      subCat = this._incidentSubCategoryLOV.attributes.find(x => x.IncidentCategoryId === selectedIncident.attributes.IncidentCategoryId && x.IncidentSubCategoryId === selectedIncident.attributes.IncidentSubCategoryId);
      console.log('****** ! incidentSubCategoryLOV subCat ! ******', subCat);

      // Info from incident
      //this._workRequest.attributes[0].WorkRequestDate = Date.now();
      this._workRequestAttributes.WorkRequestDate = Date.now();
      this._workRequestAttributes.IncidentId = selectedIncident.attributes.IncidentId;
      this._workRequestAttributes.FairwayNumber = selectedIncident.attributes.FairwayNumber;
      //selectedIncident.GlobalID

      this._workRequestAttributes.Comments = '<p>Work requested created from incident: ' + selectedIncident.attributes.IncidentId + ' - ' + selectedIncident.attributes.IncidentTitle + ', dated ' + selectedIncident.attributes.IncidentDate + '.</p>';

      if (selectedIncident.attributes.IncidentDetails) {
        this._workRequestAttributes.Comments += '<p>Details:</p>' + selectedIncident.attributes.IncidentDetails;
      }

      if (selectedIncident.attributes.Comments) {
        this._workRequestAttributes.Comments += '<p>Comments:</p>' + selectedIncident.attributes.Comments;
      }

      // Get default category / sub category
      this._workRequestAttributes.WorkRequestCategoryId = subCat.DefaultWorkRequestCategoryId;
      this._workRequestAttributes.WorkRequestSubCategoryId = subCat.DefaultWorkRequestSubCategoryId;

      // Get the SLA data
      // this._workRequest.Responsible
      // this._workRequest.AssignedTo
      // this._workRequest.SLADays
      // this._workRequest.SLADueDate

      // Set static fields
      this._workRequestAttributes.Source = 'INCIDENT';
      this._workRequestAttributes.Status = 2;
      this._workRequestAttributes.Priority = 2;
      this._workRequestAttributes.ReportedBy = 'STAFF';

      // To be supplied by user
      //this._workRequest.attributes.WorkRequestTitle
      //this._workRequest.attributes.WorkRequestDetails

      console.log('****** createFromIncident() ******, this._workRequest', this._workRequestAttributes);



    }),
      first()
    ).toPromise();


    // this._incidentSubCategoryLOV.loadedEvent.subscribe((response: any) => {

    //   console.log('****** ! incidentSubCategoryLOV loadedEvent 3! ******', this._incidentSubCategoryLOV);

    //   let subCat: any;



    //   subCat = this._incidentSubCategoryLOV.attributes.find(x => x.IncidentCategoryId === selectedIncident.attributes.IncidentCategoryId && x.IncidentSubCategoryId === selectedIncident.attributes.IncidentSubCategoryId);
    //   console.log('****** ! incidentSubCategoryLOV subCat ! ******', subCat);

    //   // Info from incident
    //   //this._workRequest.attributes[0].WorkRequestDate = Date.now();
    //   this._workRequestAttributes.WorkRequestDate = Date.now();
    //   this._workRequestAttributes.IncidentId = selectedIncident.attributes.IncidentId;
    //   this._workRequestAttributes.FairwayNumber = selectedIncident.attributes.FairwayNumber;
    //   //selectedIncident.GlobalID

    //   this._workRequestAttributes.Comments = '<p>Work requested created from incident: ' + selectedIncident.attributes.IncidentId + ' - ' + selectedIncident.attributes.IncidentTitle + ', dated ' + selectedIncident.attributes.IncidentDate + '.</p>';

    //   if (selectedIncident.attributes.IncidentDetails) {
    //     this._workRequestAttributes.Comments += '<hr>Details:' + selectedIncident.attributes.IncidentDetails; // + '</p>';
    //   }

    //   if (selectedIncident.attributes.Comments) {
    //     this._workRequestAttributes.Comments += '<hr>Comments:' + selectedIncident.attributes.Comments; // + '</p>';
    //   }

    //   // Get default category / sub category
    //   this._workRequestAttributes.WorkRequestCategoryId = subCat.DefaultWorkRequestCategoryId;
    //   this._workRequestAttributes.WorkRequestSubCategoryId = subCat.DefaultWorkRequestSubCategoryId;

    //   // Get the SLA data
    //   // this._workRequest.Responsible
    //   // this._workRequest.AssignedTo
    //   // this._workRequest.SLADays
    //   // this._workRequest.SLADueDate

    //   // Set static fields
    //   this._workRequestAttributes.Source = 'INCIDENT';
    //   this._workRequestAttributes.Status = 'OPEN';
    //   this._workRequestAttributes.Priority = 2;
    //   this._workRequestAttributes.ReportedBy = 'EMPLOYEE';

    //   // To be supplied by user
    //   //this._workRequest.attributes.WorkRequestTitle
    //   //this._workRequest.attributes.WorkRequestDetails

    //   console.log('****** createFromIncident() ******, this._workRequest', this._workRequestAttributes);
    // }); 

    return this._workRequestAttributes;
  }
}
