<mat-toolbar color="primary">
    <app-menu (loginEvent)='oAuthLogin()' (logoutEvent)='oAuthLogout()'></app-menu>
    <span style='padding-left: 20px;'><ng-content select="[title]"></ng-content></span>
    <span class="toolbar-spacer"></span>
    <ng-content select="[toolbar-extras]"></ng-content>
    <!-- <span #signIn [hidden]='signedIn'>
        <button mat-menu-item (click)='oAuthLogin()'>
            <mat-icon class='material-icons-sharp toolbar-icon'>login</mat-icon>
        </button>
    </span> -->
    <span #signOut [hidden]='!signedIn'>
        <!-- <button mat-menu-item (click)='oAuthLogout()'>
            <span class='toolbar-icon'>Hi {{username}}&nbsp;</span>
            <mat-icon class='material-icons-sharp toolbar-icon'>logout</mat-icon>
        </button> -->
        <!-- <span class='toolbar-icon'>Hi {{username}}&nbsp;</span> -->
        <span class='toolbar-icon'>
            <mat-icon class='material-icons-sharp'>done_outline</mat-icon>
        </span>
    </span>
</mat-toolbar>
