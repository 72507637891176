<div [formGroup]='featureForm' >
    <div formArrayName="formArray">
        <div formGroupName={{idx}}>

        
          <!-- Normal Field (ie, not an "_other" field)-->
          <span *ngIf='!field.name.includes("_other")'>
            <!-- Hidden Field -->
            <span *ngIf='!field.visible'>
              <input [hidden]='true' formControlName='{{field.name}}'>
            </span>

            <!-- Simple Text Field -->
            <span *ngIf='field.visible && !field.geocode && !field.domain && !field.domain_lov && !field.domain_lovParent && (field.editorType === "text" || field.editorType === "number" || field.editorType === "email" || field.editorType === "tel" || field.editorType === "url")' fxLayout="row" fxLayoutAlign="space-between center">
              <mat-label *ngIf='readOnly' class='form-label form-font-small'>{{field.label}}</mat-label>
              <mat-form-field class='form-field' [ngClass]="{'form-field-100': readOnly === false, 'form-field-50 form-font-small': readOnly === true, 'float: right;': readOnly === true}"> 
                <mat-label *ngIf='!readOnly' class='form-label'>{{field.label}}</mat-label>
                <input matInput type={{field.editorType}} formControlName={{field.name}} required={{field.required}} readonly={{field.readOnly}}>
                <span *ngIf='field.prefix' matPrefix>&nbsp;<span [innerHTML]='field.prefix'></span></span>
                <span *ngIf='field.suffix' matSuffix>&nbsp;<span [innerHTML]='field.suffix'></span></span>
              </mat-form-field>
            </span>
      
            <!-- Textarea Field -->
            <span *ngIf='field.visible && !field.geocode && !field.domain && !field.domain_lov && !field.domain_lovParent && field.editorType === "text-area"'>
              <span *ngIf='!readOnly'>
                <mat-label class='form-label'>
                  <span  class='mat-form-field-infix quill-editor-label' [ngClass]="{'field-invalid-label': field.required === true
                  && featureForm.controls.formArray.controls[idx].controls[field.name].invalid
                  && (featureForm.controls.formArray.controls[idx].controls[field.name].dirty 
                  ||  featureForm.controls.formArray.controls[idx].controls[field.name].touched)}">{{field.label}}
                      <span *ngIf='field.required === true' [ngClass]="{'mat-placeholder-required mat-form-field-required-marker':field.required === true}">*</span>
                    </span>
                </mat-label>
                <ckeditor [editor]="editor" [config]="toolbarCustom" formControlName={{field.name}}></ckeditor>
                <br />
              </span>
              <span *ngIf='readOnly' fxLayout="row" fxLayoutAlign="space-between start">
                <mat-label class='form-label form-font-small'>{{field.label}}</mat-label>
                <span class='form-field-50 form-font-small'>
                  <textarea class='form-field-textarea-readonly form-font-small' formControlName={{field.name}} required={{field.required}}></textarea>
                </span>
              </span>
            </span>
            
            <!-- Date Field -->
            <span *ngIf='field.visible && !field.geocode && !field.domain && !field.domain_lov && !field.domain_lovParent && field.editorType === "date"' fxLayout="row" fxLayoutAlign="space-between center">
              <mat-label *ngIf='readOnly' class='form-label form-font-small'>{{field.label}}</mat-label>
              <mat-form-field class='form-field' [ngClass]="{'form-field-100': readOnly === false, 'form-field-50 form-font-small': readOnly === true, 'float: right;': readOnly === true}"> 
                <mat-label *ngIf='!readOnly' class='form-label'>{{field.label}}</mat-label>
                <input matInput [matDatepicker]='picker' formControlName={{field.name}} required={{field.required}}>
                <mat-datepicker-toggle matSuffix [for]='picker'></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </mat-form-field>         
            </span>    
            
            <!-- ArcGIS Domain Field -->
            <span *ngIf='field.visible && !field.geocode && field.domain && !field.domain_lovParent && !field.domain_lov && (field.editorType === "select" || field.editorType === "text" || field.editorType === "number")' fxLayout="row" fxLayoutAlign="space-between center">
              <mat-label *ngIf='readOnly' class='form-label form-font-small'>{{field.label}}</mat-label>  
              <mat-form-field class='form-field' [ngClass]="{'form-field-100': readOnly === false, 'form-field-50 form-font-small': readOnly === true, 'float: right;': readOnly === true}">
                <mat-label *ngIf='!readOnly' class='form-label'>{{field.label}}</mat-label>
                <mat-select id={{field.name}} formControlName={{field.name}} required={{field.required}}>
                    <mat-option [disabled]='field.readOnly' *ngFor='let item of domains[getDomainIndex(field.domain)].codedValues' [value]='item.code'>{{item.name}}</mat-option>   
                </mat-select>
              </mat-form-field>
            </span>

            <span *ngIf='field.visible && !field.geocode && field.domain && !field.domain_lovParent && !field.domain_lov && field.editorType === "radio-h"'>
                <mat-label class='form-label' [ngClass]="{'form-font-small' : readOnly === true}">{{field.label}}</mat-label>
                <br *ngIf='!readOnly' />
                <mat-radio-group id={{field.name}} [ngClass]="{'float: right;': readOnly === true}" formControlName={{field.name}} required={{field.required}} aria-label="Select an option">
                  <mat-radio-button class='form-field form-field-radio-button-h' [ngClass]="{'form-font-small' : readOnly === true}" [disabled]='field.readOnly' *ngFor='let item of domains[getDomainIndex(field.domain)].codedValues' [value]='item.code'>{{item.name}}</mat-radio-button>
                </mat-radio-group>
            </span>   
            
            <span *ngIf='field.visible && !field.geocode && field.domain && !field.domain_lovParent && !field.domain_lov && field.editorType === "radio-v"'>
                <mat-label class='form-label' [ngClass]="{'form-font-small' : readOnly === true}">{{field.label}}</mat-label>
                <mat-radio-group id={{field.name}} class='form-field-radio-group-v' [ngClass]="{'form-field-100': readOnly === false, 'form-field-50 form-font-small': readOnly === true}" formControlName={{field.name}} required={{field.required}} aria-label="Select an option">
                    <mat-radio-button class='form-field form-field-radio-button-v' [ngClass]="{'form-font-small' : readOnly === true}" [disabled]='field.readOnly' *ngFor='let item of domains[getDomainIndex(field.domain)].codedValues' [value]='item.code'>{{item.name}}</mat-radio-button>
                </mat-radio-group>
            </span>
        
            <span *ngIf='field.visible && !field.geocode && field.domain && !field.domain_lovParent && !field.domain_lov && field.editorType === "checkbox-h"'>check-h to do</span>
            <span *ngIf='field.visible && !field.geocode && field.domain && !field.domain_lovParent && !field.domain_lov && field.editorType === "checkbox-v"'>check-v to do</span>              

            <!-- ArcGIS LOV table Field -->
            <!-- should change this to use ngCase -->
            <span *ngIf='field.visible && !field.geocode && !field.domain && !field.domain_lovParent && field.domain_lov && (field.editorType === "select" || field.editorType === "text")' fxLayout="row" fxLayoutAlign="space-between center">
              <mat-label *ngIf='readOnly' class='form-label form-font-small'>{{field.label}}</mat-label>  
              <mat-form-field class='form-field' [ngClass]="{'form-field-100': readOnly === false, 'form-field-50 form-font-small': readOnly === true, 'float: right;': readOnly === true}" ngClass={{field.class}}> 
                <mat-label *ngIf='!readOnly' class='form-label'>{{field.label}}</mat-label>
                <mat-select id={{field.name}} formControlName={{field.name}} required={{field.required}}>
                  <mat-option [disabled]='field.readOnly' *ngFor='let item of getDomainLov(field.domain_lov)' [value]='item.code'>{{item.name}}</mat-option>                   
                </mat-select>
              </mat-form-field>
            </span> 
            
            <span *ngIf='field.visible && !field.geocode && !field.domain && !field.domain_lovParent && field.domain_lov && field.editorType === "radio-h"'>
                <mat-label class='form-label'>{{field.label}}</mat-label>
                <br *ngIf='!readOnly' />
                <mat-radio-group id={{field.name}} formControlName={{field.name}} required={{field.required}} [disabled]='field.readOnly' aria-label="Select an option">
                    <mat-radio-button class='form-field form-field-radio-button-h' [ngClass]="{'form-font-small' : readOnly === true}" [disabled]='field.readOnly' *ngFor='let item of getDomainLov(field.domain_lov)' [value]='item.code'>{{item.name}}</mat-radio-button>
                </mat-radio-group>
            </span> 
            
            <span *ngIf='field.visible && !field.geocode && !field.domain && !field.domain_lovParent && field.domain_lov && field.editorType === "radio-v"'>
                <mat-label class='form-label'>{{field.label}}</mat-label>
                <mat-radio-group id={{field.name}} class='form-field-radio-group-v' formControlName={{field.name}} required={{field.required}} aria-label="Select an option">
                    <mat-radio-button class='form-field form-field-radio-button-v' [ngClass]="{'form-font-small' : readOnly === true}" [disabled]='field.readOnly' *ngFor='let item of getDomainLov(field.domain_lov)' [value]='item.code'>{{item.name}}</mat-radio-button>
                </mat-radio-group>
            </span>
            
            <span *ngIf='field.visible && !field.geocode && !field.domain && !field.domain_lovParent && field.domain_lov && field.editorType === "checkbox-h"'>check-h to do</span>
            <span *ngIf='field.visible && !field.geocode && !field.domain && !field.domain_lovParent && field.domain_lov && field.editorType === "checkbox-v"'>check-v to do</span>

            <!-- ArcGIS LOV table Field with Parent -->
            <span *ngIf='field.visible && !field.geocode && !field.domain && field.domain_lovParent && field.domain_lov' fxLayout="row" fxLayoutAlign="space-between center">
              <mat-label *ngIf='readOnly' class='form-label form-font-small'>{{field.label}}</mat-label> 
              <mat-form-field class='form-field' [ngClass]="{'form-field-100': readOnly === false, 'form-field-50 form-font-small': readOnly === true, 'float: right;': readOnly === true}" ngClass={{field.class}}> 
                <mat-label *ngIf='!readOnly' lass='form-label'>{{field.label}}</mat-label>
                <mat-select id={{field.name}} formControlName={{field.name}} required={{field.required}}>
                  <mat-option [disabled]='field.readOnly' *ngFor='let item of field.domain_options' [value]='item.code'>{{item.name}}</mat-option>                   
                </mat-select>
              </mat-form-field>
            </span> 

            <!-- Geocode / Address Lookup field -->
            <span *ngIf='field.visible && field.geocode'>
                <app-esri-address-search
                  [featureForm]="featureForm" 
                  [field]="field" 
                  [idx]="idx" 
                  [readOnly]="readOnly">
                </app-esri-address-search>
            </span>

            <!-- Validation / Error info -->
            <span *ngIf='!readOnly'>
              <div *ngIf="featureForm.controls.formArray.controls[idx].controls[field.name].invalid 
                          && (featureForm.controls.formArray.controls[idx].controls[field.name].dirty 
                          || featureForm.controls.formArray.controls[idx].controls[field.name].touched)" class="alert alert-danger">
                  <div *ngIf="featureForm.controls.formArray.controls[idx].controls[field.name].errors.required" class='field-invalid'>
                  <span style='padding-bottom: 8px;'>The "{{field.name}}" field is a required field.</span>
                  </div>
                  <div *ngIf="featureForm.controls.formArray.controls[idx].controls[field.name].errors.maxlength">
                  <span style='padding-bottom: 8px;'>{{field.name}} must be no more than {{field.maxLength}} characters long.</span>
                  </div>
              </div> 
            </span>
          </span>

          <!-- "other" Field -->
          <span *ngIf='field.name.includes("_other")'>
            <span *ngIf="checkIfValueIsOther(field.name, idx)">
              <span *ngIf='field.visible' fxLayout="row" fxLayoutAlign="space-between center">
                <mat-label *ngIf='readOnly' class='form-label form-font-small'>{{field.label}}</mat-label>
                <mat-form-field class='form-field' [ngClass]="{'form-field-100': readOnly === false, 'form-field-50 form-font-small': readOnly === true, 'float: right;': readOnly === true}"> 
                  <mat-label *ngIf='!readOnly' class='form-label'>{{field.label}}</mat-label>
                  <input matInput type="text" formControlName={{field.name}} required={{field.required}} >
                </mat-form-field>
              </span>
            </span>
          </span>

        </div>
    </div>
</div>
        





    
    <!-- <span *ngIf='!field.visible'>
      <input [hidden]='true' formControlName='{{field.name}}'>
    </span> -->
 
    <!-- Simple Text Field -->
    <!-- <span *ngIf='field.visible && !field.domain && !field.domain_lov && !field.domain_lovParent && field.editorType != "date" && field.editorType != "text-area"'> -->
    <!-- <span *ngIf='field.visible && !field.geocode && !field.domain && !field.domain_lov && !field.domain_lovParent && (field.editorType === "text" || field.editorType === "number" || field.editorType === "email" || field.editorType === "tel" || field.editorType === "url")'>
      <mat-form-field class='form-field form-field-100' > 
        <mat-label class='form-label'>{{field.label}}</mat-label>
        <input matInput type={{field.editorType}} formControlName={{field.name}} required={{field.required}}>
        <span *ngIf='field.prefix' matPrefix>&nbsp;<span [innerHTML]='field.prefix'></span></span>
        <span *ngIf='field.suffix' matSuffix>&nbsp;<span [innerHTML]='field.suffix'></span></span>
      </mat-form-field>
    </span> -->

    <!-- Textarea Field -->
    <!-- <span *ngIf='field.visible && !field.geocode && !field.domain && !field.domain_lov && !field.domain_lovParent && field.editorType === "text-area"'>
      <mat-label class='form-label'>
         <span class='mat-form-field-infix quill-editor-label' 
            [ngClass]="{'field-invalid-label': field.required === true
                         && featureForm.controls.formArray.controls[i].controls[field.name].invalid
                         && (featureForm.controls.formArray.controls[i].controls[field.name].dirty 
                         ||  featureForm.controls.formArray.controls[i].controls[field.name].touched)}">{{field.label}}
            <span *ngIf='field.required === true' [ngClass]="{'mat-placeholder-required mat-form-field-required-marker':field.required === true}">*</span>
          </span>
      </mat-label>
      <ckeditor [editor]="editor" [config]="toolbarCustom" formControlName={{field.name}}></ckeditor>
      <br />
    </span> -->

    <!-- Date Field -->
    <!-- <span *ngIf='field.visible && !field.geocode && !field.domain && !field.domain_lov && !field.domain_lovParent && field.editorType === "date"'>
      <mat-form-field class='form-field form-field-100'> 
        <mat-label class='form-label'>{{field.label}}</mat-label>
        <input matInput [matDatepicker]='picker' formControlName={{field.name}} required={{field.required}}>
        <mat-datepicker-toggle matSuffix [for]='picker'></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>         
    </span> -->

    <!-- ArcGIS Domain Field -->
    <!-- <span *ngIf='field.visible && !field.geocode && field.domain && !field.domain_lovParent && !field.domain_lov && (field.editorType === "select" || field.editorType === "text")'>
      <mat-form-field class='form-field form-field-100'>
        <mat-label class='form-label'>{{field.label}}</mat-label>
      <mat-select id={{field.name}} formControlName={{field.name}} required={{field.required}}>
        <mat-option [disabled]='field.readOnly' *ngFor='let item of domains[getDomainIndex(field.domain)].codedValues' [value]='item.code'>{{item.name}}</mat-option>                   
      </mat-select>
    </mat-form-field>
    </span> -->

    <!-- <span *ngIf='field.visible && !field.geocode && field.domain && !field.domain_lovParent && !field.domain_lov && field.editorType === "radio-h"'>
      <mat-label class='form-label'>{{field.label}}</mat-label>
      <br />
      <mat-radio-group id={{field.name}} formControlName={{field.name}} required={{field.required}} aria-label="Select an option">
        <mat-radio-button class='form-field form-field-radio-button-h' [disabled]='field.readOnly' *ngFor='let item of domains[getDomainIndex(field.domain)].codedValues' [value]='item.code'>{{item.name}}</mat-radio-button>
      </mat-radio-group>
    </span> -->

    <!-- <span *ngIf='field.visible && !field.geocode && field.domain && !field.domain_lovParent && !field.domain_lov && field.editorType === "radio-v"'>
      <mat-label class='form-label'>{{field.label}}</mat-label>
      <mat-radio-group id={{field.name}} class='form-field-radio-group-v' formControlName={{field.name}} required={{field.required}} aria-label="Select an option">
        <mat-radio-button class='form-field form-field-radio-button-v' [disabled]='field.readOnly' *ngFor='let item of domains[getDomainIndex(field.domain)].codedValues' [value]='item.code'>{{item.name}}</mat-radio-button>
      </mat-radio-group>
    </span> -->

    <!-- <span *ngIf='field.visible && !field.geocode && field.domain && !field.domain_lovParent && !field.domain_lov && field.editorType === "checkbox-h"'>check-h to do</span> -->
    <!-- <span *ngIf='field.visible && !field.geocode && field.domain && !field.domain_lovParent && !field.domain_lov && field.editorType === "checkbox-v"'>check-v to do</span> -->

    <!-- ArcGIS LOV table Field -->
    <!-- should change this to use ngCase -->
    <!-- <span *ngIf='field.visible && !field.geocode && !field.domain && !field.domain_lovParent && field.domain_lov && (field.editorType === "select" || field.editorType === "text")'>
      <mat-form-field class='form-field form-field-100' ngClass={{field.class}}> 
        <mat-label class='form-label'>{{field.label}}</mat-label>
        <mat-select id={{field.name}} formControlName={{field.name}} required={{field.required}}>
          <mat-option [disabled]='field.readOnly' *ngFor='let item of getDomainLov(field.domain_lov)' [value]='item.code'>{{item.name}}</mat-option>                   
        </mat-select>
      </mat-form-field>
    </span>  -->

    <!-- <span *ngIf='field.visible && !field.geocode && !field.domain && !field.domain_lovParent && field.domain_lov && field.editorType === "radio-h"'>
      <mat-label class='form-label'>{{field.label}}</mat-label>
      <br />
      <mat-radio-group id={{field.name}} formControlName={{field.name}} required={{field.required}} [disabled]='field.readOnly' aria-label="Select an option">
        <mat-radio-button class='form-field form-field-radio-button-h' [disabled]='field.readOnly' *ngFor='let item of getDomainLov(field.domain_lov)' [value]='item.code'>{{item.name}}</mat-radio-button>
      </mat-radio-group>
    </span>  -->

    <!-- <span *ngIf='field.visible && !field.geocode && !field.domain && !field.domain_lovParent && field.domain_lov && field.editorType === "radio-v"'>
      <mat-label class='form-label'>{{field.label}}</mat-label>
      <mat-radio-group id={{field.name}} class='form-field-radio-group-v' formControlName={{field.name}} required={{field.required}} aria-label="Select an option">
        <mat-radio-button class='form-field form-field-radio-button-v' [disabled]='field.readOnly' *ngFor='let item of getDomainLov(field.domain_lov)' [value]='item.code'>{{item.name}}</mat-radio-button>
      </mat-radio-group>
    </span> -->

    <!-- <span *ngIf='field.visible && !field.geocode && !field.domain && !field.domain_lovParent && field.domain_lov && field.editorType === "checkbox-h"'>check-h to do</span> -->
    <!-- <span *ngIf='field.visible && !field.geocode && !field.domain && !field.domain_lovParent && field.domain_lov && field.editorType === "checkbox-v"'>check-v to do</span> -->

    <!-- ArcGIS LOV table Field with Parent -->
    <!-- <span *ngIf='field.visible && !field.geocode && !field.domain && field.domain_lovParent && field.domain_lov'>
      <mat-form-field class='form-field form-field-100' ngClass={{field.class}}> 
        <mat-label class='form-label'>{{field.label}}</mat-label>
        <mat-select id={{field.name}} formControlName={{field.name}} required={{field.required}}>
          <mat-option [disabled]='field.readOnly' *ngFor='let item of field.domain_options' [value]='item.code'>{{item.name}}</mat-option>                   
        </mat-select>
      </mat-form-field>
    </span>  -->

    <!-- Geocode / Address Lookup field -->
    <!-- <span *ngIf='field.visible && field.geocode'>
      <mat-form-field class='form-field form-field-100'>
        <mat-label class='form-label'>{{field.label}}</mat-label>
        <input matInput type={{field.editorType}} formControlName={{field.name}} required={{field.required}} [matAutocomplete]='auto'>
        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" (optionSelected)='onAddressSelected($event)'>
          <mat-option *ngFor="let item of geocodeSuggestAutoComplete$ | async; let index = index" [value]="item.text" data-magicKey={{item.magicKey}} data-isCollection={{item.isCollection}}>
             {{item.text}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </span> -->
