<div mat-dialog-title fxLayout='row' fxLayoutAlign='space-between center'>
    <span>{{data.title}}</span>
    <span mat-dialog-actions style='margin-bottom: 0;'>
        <!-- TO DO: **** CHECK IF FORM IS DIRTY **** - WARN USER OF UNSAVED CHANGES PRIOR TO CLOSING DIALOG -->
        <button mat-dialog-close mat-mini-fab color="primary" aria-label="Close icon button" (click)='closeDialog()'>
            <mat-icon>close</mat-icon>
        </button>
    </span>
</div>
<div mat-dialog-content>
    <app-esri-edit-form #editForm [readOnly]='false' (featureFormLoadedEvent)='formLoadedEvent($event)'>
    </app-esri-edit-form>
</div>

<div mat-dialog-actions>
  <app-esri-edit-form-buttons style="width: 100%;"></app-esri-edit-form-buttons>
</div>
