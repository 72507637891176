import { Component, OnInit, HostBinding, HostListener, Input } from '@angular/core';
 
@Component({
  selector: 'app-esri-popup-menu',
  templateUrl: './esri-popup-menu.component.html',
  styleUrls: ['./esri-popup-menu.component.scss']
})

export class EsriPopupMenuComponent implements OnInit {

  // private _longitude: number;
  // private _latitude: number;
  // private x: string;
  // private y: string;
  private _popupType: string;   // DATA MENU

  @Input()
  set popupType(popupType: string) {
    this._popupType = popupType;
  }

  get popupType(): string {
    return this._popupType;
  }

  // @Input()
  // set latitude(latitude: number) {
  //   this._latitude = latitude;
  // }

  // get latitude(): number {
  //   return this._latitude;
  // }

  // @Input()
  // set longitude(longitude: number) {
  //   this._longitude = longitude;
  // }

  // get longitude(): number {
  //   return this._longitude;
  // }

  constructor() { }
 
  ngOnInit() {
  }
 
  public open(event) {
    // this.x = `${event.screenPoint.x}px`,
    // this.y = `${event.screenPoint.y}px`
    // //this.visibility = "visible";

    // this.x = `${event.native.screenX}px`;
    // this.y = `${event.screenPoint.y}px`;
    //this.visibility = "visible";

    //this._latitude = event.mapPoint.latitude;
    //this._longitude = event.mapPoint.longitude;
  }

  public close() {
    //this.visibility = "hidden";
  }

  // @HostListener('document:click')
  // public onDocumentClick() {
  //   // if (this.visibility === "visible") {
  //   //   this.hide();
  //   // }
  // }
}
