<div *ngIf="formLoaded">  
  <form [formGroup]='featureForm'>
    <span [ngSwitch]="useFormStepper">

      <!-- NO STEPPER -->
      <span *ngSwitchCase="false">
        <span *ngFor='let step of formFieldConfigs; let i = index'>
            <!-- Fields -->
            <span *ngFor='let field of step.fieldConfig; let even = even; let odd = odd'>
                <app-esri-edit-form-elements 
                  [featureForm]="featureForm" 
                  [field]="field" 
                  [idx]="i" 
                  [domains]="domains"
                  [domains_lov]="domains_lov"
                  [domains_lovParent]="domains_lovParent"
                  [readOnly]="readOnly"
                  (addressSelectedEvent)="addressSelected($event)">
                </app-esri-edit-form-elements>
            </span> 
            <!-- end field for -->
        </span>
      </span>

      <!-- GRID -->
      <!-- <span *ngSwitchCase="false">
        <span *ngFor='let step of formFieldConfigs; let i = index'>
          <h3>{{step.label}}</h3>
          <div fxLayout="row wrap"  fxLayout.lt-sm="column"  fxLayoutGap="32px"  fxLayoutAlign="flex-start">
            <ng-container *ngFor='let field of step.fieldConfig; let even = even; let odd = odd'>
              <span fxFlex="0 1 calc(50% - 32px)" fxFlex.lt-lg="0 1 calc(50% - 32px)" fxFlex.lt-md="100%">
                <app-esri-edit-form-elements 
                  [featureForm]="featureForm" 
                  [field]="field" 
                  [idx]="i" 
                  [domains]="domains"
                  [domains_lov]="domains_lov"
                  [domains_lovParent]="domains_lovParent"
                  [readOnly]="readOnly"
                  (addressSelectedEvent)="addressSelected($event)">
                </app-esri-edit-form-elements>
              </span>
            </ng-container>
          </div>
        </span>
      </span> -->

      <!-- STEPPER -->
      <span *ngSwitchCase="true">
        <mat-horizontal-stepper #stepper [linear]='false' formArrayName='formArray'>
          <span *ngFor='let step of formFieldConfigs; let i = index'> 
            <mat-step *ngIf='step.visible' formGroupName={{i}} [stepControl]='formArray?.get([i])' label="{{step.label}}">
              <!-- Prev / Next Buttons -->        
              <div *ngIf='i == 0' fxLayout='row' fxLayoutAlign='end center'>
                <button mat-button color='accent' aria-label='Next button with an icon' matTooltip='Next' matStepperNext type='button'>
                  Next&nbsp;
                  <span class="fa fa-arrow-circle-right fa-3x"></span>
                </button>
              </div>
              <div *ngIf='i > 0' fxLayout='row' fxLayoutAlign='space-between center'>
                <button *ngIf='i > 0' mat-button color='accent' aria-label='Previous button with an icon' matTooltip='Previous' matStepperPrevious type='button'>   
                  <i class="fa fa-arrow-circle-left fa-3x"></i>
                  &nbsp;Previous
                </button>
                <button *ngIf='i < (formFieldConfigs.length - 2)' mat-button color='accent' aria-label='Next button with an icon' matTooltip='Next' matStepperNext type='button'>
                  Next&nbsp;
                  <i class="fa fa-arrow-circle-right fa-3x"></i>
                </button>
              </div>
              <br /> 

              <!-- Fields -->
              <span *ngFor='let field of step.fieldConfig'>      
                <app-esri-edit-form-elements 
                  [featureForm]="featureForm" 
                  [field]="field" 
                  [idx]="i" 
                  [domains]="domains"
                  [domains_lov]="domains_lov"
                  [domains_lovParent]="domains_lovParent"
                  [readOnly]="readOnly">
                </app-esri-edit-form-elements>
              </span> <!-- end field for -->
              
            </mat-step>
          </span> <!-- end step for -->
        </mat-horizontal-stepper>
      </span>
    </span>
  </form>    

  <!-- ArcGIS Save/update Result -->
  <div>
    <div #submitResult class='submit-message-result' [ngClass]="{
      'submit-success':result.result === 'SUCCESS',
      'submit-error':result.result === 'ERROR'}">
        {{result.result}}
    </div>
    <div #submitMessage class='submit-message-error' *ngIf='result.result != "SUCCESS"'>
      {{result.message}}
    </div>
  </div>

  <!-- <p>Form Touched: {{ featureForm.touched }} </p> -->
  <!-- <p>Form Status: {{ featureForm.status }} </p> -->
  <!-- <p>Form Pristine: {{ featureForm.pristine }} </p> -->
  <!-- <p>Form Value: {{ featureForm.value | json }} </p> -->
  <!-- <p>Geometry Value: {{ geometry | json }} </p> -->
  <!-- <p>Form Material: {{ featureForm.value.formArray[1].Material }} </p> -->
  <!-- <p>Form completeData: {{ completeData | json }} </p> -->
</div>


            <!-- <div fxLayout='row' fxLayoutAlign="space-between center">
              <div fxFlex="45">
                <span *ngIf='even'>
                  <app-esri-edit-form-elements 
                    [featureForm]="featureForm" 
                    [field]="field" 
                    [idx]="i" 
                    [domains]="domains"
                    [domains_lov]="domains_lov"
                    [domains_lovParent]="domains_lovParent"
                    [readOnly]="readOnly"
                    (addressSelectedEvent)="addressSelected($event)">
                  </app-esri-edit-form-elements>
                </span>
              </div>
              <div fxFlex="45">
                <span *ngIf='odd'>
                  <app-esri-edit-form-elements 
                    [featureForm]="featureForm" 
                    [field]="field" 
                    [idx]="i" 
                    [domains]="domains"
                    [domains_lov]="domains_lov"
                    [domains_lovParent]="domains_lovParent"
                    [readOnly]="readOnly"
                    (addressSelectedEvent)="addressSelected($event)">
                  </app-esri-edit-form-elements>
                </span>
              </div>
            </div> -->