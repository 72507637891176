export class LayerIds {
    //
    // Connections
    //

    public static tokenUser: string = 'gcurley';
    public static tokenPassword: string = '';
    public static oAuthClientId = 'iQz7zsojuwbD4lpF';
    public static oAuthClientSecret = 'faf0b11f5f1642859ab9b5fd7e8f7dd7';


    //https://services.arcgisonline.com/arcgis/rest/services/Elevation/World_Hillshade/MapServer

    //
    // GIS Servers
    //

    public static gisServer_AGOL: string = 'https://services.arcgis.com/GEY7BOibXkcbWcpS/arcgis/rest/services';
    //public static gisServer_AGOL: string = 'https://www.arcgis.com/sharing/rest';
    public static gisServer_AGOL_Tiles: string = 'https://tiles.arcgis.com/tiles/GEY7BOibXkcbWcpS/arcgis/rest/services';
    public static gisServer_NSW: string = 'https://maps.six.nsw.gov.au/arcgis/rest/services/public';
    public static gisServer_Enterprise: string = '';

    public static gisServer_Assets: string = LayerIds.gisServer_AGOL;
    public static gisServer_Audit: string = LayerIds.gisServer_AGOL;
    public static gisServer_Course: string = LayerIds.gisServer_AGOL;
    public static gisServer_Surrounds: string = LayerIds.gisServer_AGOL;
    public static gisServer_Game: string = LayerIds.gisServer_AGOL;
    public static gisServer_GameTables: string = LayerIds.gisServer_AGOL;
    public static gisServer_Members: string = LayerIds.gisServer_AGOL;
    public static gisServer_Maintenance: string = LayerIds.gisServer_AGOL;
    public static gisServer_Project: string = LayerIds.gisServer_AGOL;
    public static gisServer_Risk: string = LayerIds.gisServer_AGOL;
    public static gisServer_Incident: string = LayerIds.gisServer_AGOL;
    public static gisServer_NextId: string = LayerIds.gisServer_AGOL;
    public static gisServer_Staff: string = LayerIds.gisServer_AGOL;
    public static gisServer_WorkForce: string = LayerIds.gisServer_AGOL;

    //
    // DTM / Elevation
    //
    public static gisServer_CustomDTM: string = LayerIds.gisServer_AGOL_Tiles;
    public static folder_CustomDTM: string = 'DTM_Elevation_WEL';
    //public static layerId_CustomDTM = '0';
    public static customElevationUrl: string = LayerIds.gisServer_CustomDTM + '/' + LayerIds.folder_CustomDTM + '/ImageServer';


    //public static treeUrl: string = LayerIds.gisServer_CustomDTM + '/' + LayerIds.folder_CustomDTM + '/ImageServer';

    //
    // Hillshade
    //

    public static gisServer_Hillshade: string = LayerIds.gisServer_AGOL_Tiles;
    public static folder_Hillshade: string = 'Hillshade';
    public static layerId_Hillshade = '0';

    //
    // Folders
    //

    // Feature Layers
    public static folder_Assets: string = 'Assets';
    public static folder_Audit: string = 'Audit';
    public static folder_Course: string = 'Golf_Course';
    public static folder_Surrounds: string = 'Golf_Course_Surrounds';
    public static folder_Game: string = 'Games';
    //public static folder_GameTables: string = 'Games';
    public static folder_Members: string = 'Membership';
    public static folder_Maintenance: string = 'Maintenance';
    public static folder_Project: string = 'Project';
    public static folder_Risk: string = 'Risk';
    public static folder_Incident: string = 'Risk';
    public static folder_NextId: string = 'Risk';
    public static folder_Staff: string = 'Maintenance';
    public static folder_WorkForce: string = 'workforce_3886a0e7c4f74f9c8be5740070cf31ac';

    // Tile Layers
    public static folder_TileCourse: string = 'Course_Tile_Layer';
    public static folder_TileSurrounds: string = 'Surrounds_Tile_Layer';

    //
    // Portal Id's
    //
    public static portal_BaseScene: string = 'e870c18ae57545349b5343ea97fe7195';


    //
    // General Table Id's
    //

    // Next Numbers
    public static layerId_TblNextId = '13';

    // Staff
    public static layerId_TblStaff = '7';


    //
    // Layer Id's
    //

    //
    // Course
    //

    // Feature Layers
    public static layerId_GeneralHoleArea = '0';
    public static layerId_TeeMarkerPair = '1';
    public static layerId_TeeMarker = '2';
    public static layerId_Hole = '3';
    public static layerId_TeeingArea = '4';
    public static layerId_PuttingGreen = '5';
    public static layerId_Bunker = '6';
    public static layerId_Fairway = '7';
    public static layerId_Sand = '8';
    public static layerId_Pond = '9';
    public static layerId_CourseExtent = '10';

    // Tile Layers
    public static tileLayerId_GeneralHoleArea = '0';
    public static tileLayerId_TeeMarkerPair = '1';
    public static tileLayerId_TeeMarker = '2';
    public static tileLayerId_Hole = '3';
    public static tileLayerId_TeeingArea = '4';
    public static tileLayerId_PuttingGreen = '5';
    public static tileLayerId_Bunker = '6';
    public static tileLayerId_Fairway = '7';
    public static tileLayerId_Sand = '8';
    public static tileLayerId_Pond = '9';
    public static tileLayerId_CourseExtent = '10';

    ////https://tiles.arcgis.com/tiles/GEY7BOibXkcbWcpS/arcgis/rest/services/Course_Tile_Layer/MapServer?



    //
    // Surrounds
    //

    // Feature Layers
    public static layerId_Vegetation = '0';
    public static layerId_Water = '1';
    public static layerId_Greenspace = '2';
    public static layerId_Park = '3';
    public static layerId_RoadCorridor = '4';
    public static layerId_CourseBoundary = '5';

    // Tile Layers
    public static tileLayerId_Vegetation = '0';
    public static tileLayerId_Water = '1';
    public static tileLayerId_Greenspace = '2';
    public static tileLayerId_Park = '3';
    public static tileLayerId_RoadCorridor = '4';
    public static tileLayerId_CourseBoundary = '5';

    // Assets
    public static layerId_BallCleaner = '0';
    public static layerId_Bin = '1';
    public static layerId_Bridge = '2';
    public static layerId_Building = '3';
    public static layerId_Electrical_Conduit = '4';
    public static layerId_Electrical_EndPoint = '5';
    public static layerId_Electrical_Substation = '6';
    public static layerId_Fence = '7';
    public static layerId_LineMarking = '8';
    public static layerId_Footpath = '9';
    public static layerId_PracticeNet = '10';
    public static layerId_Sign = '11';
    public static layerId_Stormwater_Conduit = '12';
    public static layerId_Stormwater_Pit = '13';
    public static layerId_Underpass = '14';
    public static layerId_WaterSupply_Conduit = '15';
    public static layerId_WaterSupply_EndPoint = '16';
    public static layerId_Telecommunication = '17';
    public static layerId_WeirGPT = '18';

    // Audit
    public static layerId_Audit = '0';
    public static layerId_CourseImprovement_Point = '1';
    public static layerId_CourseImprovement_Polyline = '2';
    public static layerId_CourseImprovement_Polygon = '3';

    // Game Play
    public static layerId_GamePlay = '0';

    public static layerId_TblCourseGuide = '1';
    public static layerId_TblGameGuide = '2';

    // Risk
    public static layerId_RiskRegister = '1';
    
    public static layerId_TblRiskRegister_LOV= '4';
    public static layerId_TblRiskCategory_LOV = '5';
    public static layerId_TblRiskSubCategory_LOV = '6';
    //public static layerId_TblRiskStaff_LOV = '7';
    public static layerId_TblRiskLikelihood_LOV = '8';
    public static layerId_TblRiskConsequence_LOV = '9';
    public static layerId_TblRiskRating_LOV = '10';
 
    public static relationshipId_Risk_Register_LOV = '4';
    public static relationshipId_Risk_Category_LOV = '3';
    public static relationshipId_Risk_SubCategory_LOV = '6';

    // Incidents

    public static layerId_IncidentRegister = '0';

    public static layerId_TblIncidentCategory_LOV = '2';
    public static layerId_TblIncidentSubCategory_LOV = '3';
    public static layerId_TblIncidentContacts = '14';

    public static relationshipId_Incident_Contacts = '1';

    // Maintenance

    public static layerId_WorkRequest = '0';
    public static layerId_TblWorkRequest_Asset = '1';

    public static layerId_TblWorkRequest_Contact = '2';
    public static layerId_TblWorkRequest_StatusHistory = '3';
    public static layerId_TblWorkRequest_Category_LOV = '4';
    public static layerId_TblWorkRequest_SubCategory_LOV = '5';
    public static layerId_TblWorkRequest_ReportedBy_LOV = '6';

    //public static layerId_Project = '0';
    //public static layerId_Project_ = '0';
    
    // ArcGIS WorkForce Integration

    public static layerId_WorkForce_Assignments = '0';
    public static layerId_WorkForce_Workers = '1';
    public static layerId_WorkForce_TblDispatchers = '2';
    public static layerId_WorkForce_TblAssignmentTypes = '3';
    public static layerId_WorkForce_TblAssignIntegrations = '4';

    // Projects
    public static layerId_Project = '0';

    public static layerId_TblProject_Category_LOV = '4';
    public static layerId_TblProject_SubCategory_LOV = '5';


    // Members 
    public static layerId_Members = '0';
    public static layerId_Members_ReciprocalClub = '1';
    public static layerId_Members_ReciprocalGamePlay = '2';

    public static layerId_Members_RoundGender = '3';
    public static layerId_Members_RoundOrigin = '4';
    public static layerId_Members_Application = '5';
    public static layerId_Members_Type_LOV = '6';
    public static layerId_Members_Category_LOV = '7';
    public static layerId_Members_ApplicationStatus_LOV = '8';
    public static layerId_Members_SourceMapping = '9';

    //
    // DFSI Spatial Services NSW (ie these are Map Services - NOT Feature Services)
    //

    // Folders
    public static folder_NSW_Property: string = 'NSW_Property';
    public static folder_NSW_Cadastre: string = 'NSW_Cadastre';
    
    // Property
    public static layerId_Property = '0';
    public static layerId_Large_Rural_Property = '1';
    public static layerId_Rural_Property = '2';
    public static layerId_Semi_Rural_Property = '3';
    public static layerId_Urban_Property = '4';

    // Cadastre
    public static layerId_Cadastre_Labels = '0';
    public static layerId_PlanExtent_Labels = '1';
    public static layerId_SectionExtent_Labels = '2';
    public static layerId_Lot_Labels = '3';
    public static layerId_Cadastre = '4';
    public static layerId_Large_Rural_PlanExtent = '5';
    public static layerId_Rural_PlanExtent = '6';
    public static layerId_SectionExtent = '7';
    public static layerId_PlanExtent = '8';
    public static layerId_Lot = '9';
}
