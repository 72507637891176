<app-header>
    <span title>{{title}}</span>
    <span toolbar-extras>
    </span>
</app-header>

<div *ngIf='!thinking' style="height: 4px;"></div>
<div *ngIf='thinking'><mat-progress-bar mode="query"></mat-progress-bar></div>

<!-- Slider (Date) Row -->
<!-- <div fxLayout='row' fxLayoutAlign="space-between center" class='date-slader-row'>
    <div class='chart-box' [ngClass]="{'dark-chart-box': darkMode}" style="width: 100%;">
        <div fxFlex="200px" class='chart-title' [ngClass]="{'dark-chart-title': darkMode}">Date Range</div>
        <div #timeSlider [hidden]='!loaded' class='time-slider' [ngClass]="{'dark': darkMode}">
            <ngx-slider *ngIf='timeSliderReady'
                [(value)]="dateFrom"
                [(highValue)]="dateTo"
                [(options)]="timeSliderOptions"
                (userChangeEnd)="timeSliderDateRangeChange($event)"
            ></ngx-slider>
        </div>
    </div>
</div> -->

<!-- Chips Row -->
<div fxLayout='row' fxLayoutAlign="center center" class='chips-row'>
    <div>
        <mat-chip-list #chipListAll aria-label="">
            <span *ngFor="let chart of dashboardCharts">
                <mat-chip [selectable]="true" [removable]="removableChip" *ngIf="chart.visibleChip" (removed)="removeChip(chart.chartName)">
                    {{chart.label}}
                    <mat-icon matChipRemove *ngIf="removableChip">cancel</mat-icon>
                </mat-chip>
            </span>
        </mat-chip-list>
    </div>
</div>

<!-- Dashboard Row 1 -->
<div fxLayout='row' fxLayoutGap="20px" fxLayoutAlign="space-between center" class='chart-row chart-row-1' style='height: 650px;'>
    <!-- Chart 1 -->
    <div fxFlex="100" class='chart-box' [ngClass]="{'dark-chart-box': darkMode}">
        <div fxLayout='column' fxLayoutAlign="space-between center"> 
            <div fxLayout='row' fxShow fxHide.lt-md fxLayoutAlign="space-between center">
                <div [ngClass]="{'dark': darkMode}">
                    <ngx-charts-bar-vertical-2d 
                        [view]="[chartWidth, chartHeight*2]"
                        [results]="getChartData('ROUND_TOTAL')"
                        [xAxisLabel]="''" 
                        [yAxisLabel]="'Number of rounds'"
                        [showXAxisLabel]='false'
                        [showYAxisLabel]='true'
                        [xAxis]='true'
                        [yAxis]='true'
                        [trimXAxisTicks]='true'
                        [maxXAxisTickLength]='30'
                        [rotateXAxisTicks]='true'    
                        [tooltipDisabled]='false' 
                        [gradient]='true'
                        [noBarWhenZero]='false'
                        [roundEdges]='false'
                        [barPadding]='1'
                        [groupPadding]='1'
                        [showDataLabel]='true'
                        [scheme]='customColorScheme'
                        [legend]='true'
                        >
                    </ngx-charts-bar-vertical-2d>
                </div>
            </div>
            <div fxLayout='row' fxHide fxShow.lt-md fxLayoutAlign="space-between center">
            </div>
            <div>
                <span class='chart-title' [ngClass]="{'dark-chart-title': darkMode}">Number of Reciprocal Rounds Played</span>
            </div>
        </div>
    </div>
</div>

<!-- Dashboard Row 2 -->
<div fxLayout='row' fxLayoutGap="20px" fxLayoutAlign="space-between center" class='chart-row chart-row-1' style='height: 650px;'>
    <!-- Chart 1 -->
    <div fxFlex="100" class='chart-box' [ngClass]="{'dark-chart-box': darkMode}">
        <div fxLayout='column' fxLayoutAlign="space-between center"> 
            <div fxLayout='row' fxShow fxHide.lt-md fxLayoutAlign="space-between center">
                <div [ngClass]="{'dark': darkMode}">
                    <ngx-charts-bar-horizontal
                        [view]="[chartWidth, chartHeight * 2]"
                        [results]="getChartData('ROUND_VARIANCE')"
                        [xAxisLabel]="''" 
                        [yAxisLabel]="'Reciprocal Club'"
                        [showXAxisLabel]='false'
                        [showYAxisLabel]='true'
                        [gradient]="true"
                        [xAxis]='true'
                        [yAxis]='true'
                        [trimYAxisTicks]='false'
                        [maxYAxisTickLength]='30'
                        [rotateXAxisTicks]='true'    
                        [tooltipDisabled]='false' 
                        [gradient]='true'
                        [noBarWhenZero]='false'
                        [roundEdges]='false'
                        [barPadding]='1'
                        [showDataLabel]='true'
                        [scheme]="customColorScheme"
                        >
                    </ngx-charts-bar-horizontal>
                </div>
            </div>
            <div fxLayout='row' fxHide fxShow.lt-md fxLayoutAlign="space-between center">
            </div>
            <div>
                <span class='chart-title' [ngClass]="{'dark-chart-title': darkMode}">Variance of Rounds Played</span>
            </div>
        </div>
    </div>
</div>

<!-- Image Gallery Row -->
<!-- <div fxLayout='row' class='image-row'>
    <app-esri-attachment style='width: 100%'></app-esri-attachment> 
</div> -->

<!-- Map Row -->
<div fxLayout='row' class='map-row' style='height: 900px;'>
    <app-esri-map fxLayout='row' style='width: 100%;' 
        [zoom]='zoom'
        [center]='centre'
        [sidePanelWidth]='sidePanelWidth' 
        [showFullscreenWidget]='true'
        [showLegendWidget]='false'
        [basemapName]='member_basemapName'

        (viewCreatedEvent)='viewCreatedEvent($event)'>

        <div #popupMenu [hidden]="!loaded">
        <app-esri-popup-menu [popupType]="'MENU'">
            <!--  [latitude]='geometry.latitude' [longitude]='geometry.longitude' *ngIf='menuPopupVisible' -->
            <div class="popup-menu-items">
            <button mat-menu-item *ngIf="selectedFeature"
                (click)='currentModeService.currentModeData.next("edit-attribute")'>
                <mat-icon class='material-icons-sharp'>edit</mat-icon>
                <span>Edit this item</span>
            </button>

            <button mat-menu-item *ngIf="!selectedFeature" (click)='createFeatureFromPopupMenuEvent()'>
                <mat-icon class='material-icons-sharp'>add_location</mat-icon>
                <span>Create a new item here</span>
            </button>

            <button mat-menu-item *ngIf="selectedFeature" (click)='moveFeatureFromPopupMenuEvent()'>
                <mat-icon class='material-icons-sharp'>edit_location</mat-icon>
                <span>Move the selected item here</span>
            </button>          
            </div>
        </app-esri-popup-menu>
        </div>

        <div #popupData [hidden]="!loaded">
        <app-esri-popup-menu [popupType]="'DATA'">
            <div class="popup-data-items">
            <app-esri-edit-form [readOnly]='true'></app-esri-edit-form>
            </div>
        </app-esri-popup-menu>
        </div>
    </app-esri-map>
</div>

<!-- Footer -->
<app-footer></app-footer>
