<div #scene class='map' style="height: 100%; width: 100%;"></div>
<div #logo class="logo" *ngIf='showLogo'>
    <span class="logo-cos">
        <img class="logo-cos" src='assets/images/logo.png' />
    </span>
     <span class="logo-client">
        <!-- <img class="logo-client" src='assets/images/logo.png' /> -->
    </span>
</div>
<!-- <div #logo class="logo">
    <img class="logo" src='assets/images/logo.png' />
</div>
    <div #logoCustomer class="logo">
    <img class="logo" src='assets/images/logo.png' />
</div> -->

<div #divBookmark class="esri-widget">
    <span *ngIf='showBookmarkWidget'>
        <div class='bookmark-widget-container'>
            <h3 class="esri-widget__heading">Bookmarks</h3>
            <span *ngIf='view'>
                <div *ngFor='let slide of view.presentation.slides.items; let i = index'>
                    <button class='bookmark-button' mat-stroked-button (click)='goToSlide(slide.viewpoint.camera)'>{{slide.title.text}}</button>
                </div>
            </span>
        </div>
    </span>
</div>

