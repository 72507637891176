<span *ngIf='field'>
    <div [formGroup]='featureForm'>
        <div formArrayName="formArray">
            <div formGroupName={{idx}}>
                <mat-form-field class='form-field' [ngClass]="{'form-field-100': readOnly === false, 'form-field-50': readOnly === true, 'float: right;': readOnly === true}">
                    <mat-label class='form-label'>{{field.label}}</mat-label>
                    <input matInput type={{field.editorType}} formControlName={{field.name}} required={{field.required}} [matAutocomplete]='auto'>
                    <button mat-button matSuffix mat-icon-button aria-label="Clear" (click)="clearField()">
                        <mat-icon>close</mat-icon>
                    </button>
                    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" (optionSelected)='onAddressSelected($event)'>
                        <mat-option *ngFor="let item of geocodeSuggestAutoComplete$ | async; let index = index" [value]="item.text" data-magicKey={{item.magicKey}} data-isCollection={{item.isCollection}}>
                            {{item.text}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>
        </div>
    </div>
</span>

<span *ngIf='!field'>
    <mat-form-field class='form-field form-field-100'>
        <mat-label class='form-label-white'>{{label}}</mat-label>
        <input matInput class='form-field-100' type='text' [formControl]='autoCompleteControl' [matAutocomplete]='auto'>
        <button mat-button *ngIf="autoCompleteControl.value" matSuffix mat-icon-button aria-label="Clear" (click)="clearField()">
            <mat-icon>close</mat-icon>
        </button>
        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" (optionSelected)='onAddressSelected($event)'>
            <mat-option #option class='form-field-100' *ngFor="let item of geocodeSuggestAutoComplete$ | async; let index = index" [value]="item.text" data-magicKey={{item.magicKey}} data-isCollection={{item.isCollection}}>
                {{item.text}}
            </mat-option>
        </mat-autocomplete>
    </mat-form-field>
</span>

<!-- <button mat-button *ngIf="autoCompleteControl.value" matSuffix mat-icon-button aria-label="accept" (click)="acceptResult()">
    <mat-icon>check_circle</mat-icon>
</button> -->
