<app-header>
    <span title>{{title}}</span>
    <span toolbar-extras></span>
</app-header>

<div id='homeDiv'>
    <section style="position: absolute; right:50px; bottom:50px;">
        <div class="company-name">Board Caddy</div>
    </section>
    
    <section style="position: absolute; left:50px; bottom:30px;" class="stage stage-250">
        <figure class="ball sphere">
            <span class="shadow"></span>
        </figure>
    </section>
    
    <section routerLink='/game' style="position: absolute; left:220px; bottom:320px;" class="stage stage-150">
        <figure class="ball bubble">
            <!-- <span class="eight"></span> -->
            <div class="ball-label">Course</div>
        </figure>
    </section>
    
    <section routerLink='/map-gallery' style="position: absolute; left:390px; bottom:460px;" class="stage stage-150">
        <figure class="ball bubble"> 
            <div class="ball-label">Maps</div>
        </figure>
    </section>
    
    <section routerLink='/member' style="position: absolute; left:560px; bottom:580px;" class="stage stage-150">
        <figure class="ball bubble">
            <div class="ball-label">Membership</div>
        </figure>
    </section>
    
    <section routerLink='/incident' style="position: absolute; left:730px; bottom:660px;" class="stage stage-150">
        <figure class="ball bubble">
            <div class="ball-label">Incidents</div>
        </figure>
    </section>
    
    <section routerLink='/risk' style="position: absolute; left:900px; bottom:720px;" class="stage stage-150">
        <figure class="ball bubble">
            <div class="ball-label">Risk</div>
        </figure>
    </section>
    
    <section routerLink='/maintenance' style="position: absolute; left:1070px; bottom:720px;" class="stage stage-150">
        <figure class="ball bubble">
            <div class="ball-label-right">Maintenance</div>
        </figure>
    </section>
    
    <section routerLink='/' style="position: absolute; left:1260px; bottom:650px;" class="stage stage-150">
        <figure class="ball bubble">
            <div class="ball-label-right">Projects</div>
        </figure>
    </section>
    
    <section routerLink='/asset-register' style="position: absolute; left:1430px; bottom:500px;" class="stage stage-150">
        <figure class="ball bubble">
            <div class="ball-label-right">Assets</div>
        </figure>
    </section>
    
    <section routerLink='/audit' style="position: absolute; left:1580px; bottom:320px;" class="stage stage-150">
        <figure class="ball bubble">
            <div class="ball-label-right">Audit</div>
        </figure>
    </section>
    
    
    <section style="position: absolute; left:1550px; bottom:100px;" class="stage stage-300-150">
        <figure class="flat flat-sphere">
            <span class="shadow"></span>
        </figure>
    </section>
</div>



<!-- <br> -->

<!-- <div class="content" role="main">
    <button routerLink="/home">Home</button>
    <button routerLink="/asset-register">Assets</button>
    <button routerLink="/maintenance">Maintenance</button>
    <button routerLink="/audit">Audit</button>
    <button routerLink="/incident">Incident Register</button>
    <button routerLink="/risk">Risk Register</button>
    <button routerLink="/game">Game</button>
    <button routerLink="/member">Members</button>
    <button routerLink="/map-gallery">Maps</button>
</div> -->

<!-- <div>
    <h2>Platform information:</h2>
    <p>Is Android: {{platform.ANDROID}}</p>
    <p>Is iOS: {{platform.IOS}}</p>
    <p>Is Firefox: {{platform.FIREFOX}}</p>
    <p>Is Blink: {{platform.BLINK}}</p>
    <p>Is Webkit: {{platform.WEBKIT}}</p>
    <p>Is Trident: {{platform.TRIDENT}}</p>
    <p>Is Edge: {{platform.EDGE}}</p>
    <p>Is Safari: {{platform.SAFARI}}</p>
    <p>Supported input types: {{supportedInputTypes}}</p>
    <p>Supports passive event listeners: {{supportsPassiveEventListeners}}</p>
    <p>Supports scroll behavior: {{supportsScrollBehavior}}</p>
</div> -->

<!-- <div style="margin-top: 50px;">
    <ul id="categories" class="clr">
        <li>
            <div class="red-hexagon" routerLink="/asset-register" style="cursor:pointer;">
                <img class="center" style="width:128px; height:128px;" src="assets/images/icons/icon-service-128.png" />
                <h1>Assets</h1>
                <p>Gain an insight into your customer service requests</p>
            </div>
        </li>
        <li>
            <div class="orange-hexagon" routerLink="/game" style="cursor:pointer;">
                <img class="center" style="width:128px; height:128px;" src="assets/images/icons/icon-works-128.png" />
                <h1>Game</h1>
                <p>Manage your operational and capital works programs</p>
            </div>
        </li>
        <li class="pusher"></li>
        <li>
            <div class="yellow-hexagon" routerLink="/audit" style="cursor:pointer;">
                <img class="center" style="width:128px; height:128px;" src="assets/images/icons/icon-da-128.png" />
                <h1>Audit</h1>
                <p>Get an overview and see the status of submitted development applications</p>
            </div>
        </li>
        <li>
            <div class="green-hexagon">
                <img class="center" style="width:128px; height:128px;" src="assets/images/icons/icon-exec-overview1-128.png" />
                <h1>Executive Dashboard</h1>
                <p>Get an overall view of service requests, council works, and DA's all in one place</p>
            </div>
        </li>
        <li>
            <div class="blue-hexagon" routerLink="/maps">
                <img class="center" style="width:128px; height:128px;" src="assets/images/icons/icon-map-gallery-128.png" />
                <h1>Map Gallery</h1>
                <p>Just the maps</p>
            </div>
        </li>
        <li>
            <div class="purple-hexagon">
                <img class="center" style="width:128px; height:128px;" src="assets/images/icons/icon-admin-128.png" />
                <h1>Settings</h1>
                <p>Manage the CrispOra suite of appliactions</p>
            </div>
        </li>
    </ul>
</div>    -->
