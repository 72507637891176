import { Component, OnInit, OnDestroy, AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, ElementRef, Output, EventEmitter } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { BaseFeaturePage } from 'src/app/_esri/models/base-feature-page';
import { MatDialog } from '@angular/material/dialog';
import { getTime, toDate, format, isBefore, parse, addYears } from 'date-fns';
import { FeaturesService } from 'src/app/_esri/services/features.service';
import { Esri, EsriGraphic, ApplyEditsResultMessage, GeometryPoint, DashboardChart } from 'src/app/_esri/models/esri';
import { CurrentModeService } from 'src/app/_globals/services/current-mode.service';
import { GeocodeDataService } from 'src/app/_globals/services/geocode-data.service';
import { FormChangeService } from 'src/app/_globals/services/form-change.service';
import { FormFieldChangeService } from 'src/app/_globals/services/form-field-change.service';
import { FormFieldUpdateService } from 'src/app/_globals/services/form-field-update.service';
import { MemberReciprocalGamePlay, MemberReciprocalClub, Member, MemberAttributes, MemberRoundGender, MemberRoundOrigin } from 'src/app/member/models/member';
import { CsvReadService } from 'src/app/_globals/services/csv-read.service';
import { Global, GroupBy, GroupArray, GroupByRange } from 'src/app/_globals/models/global';
import { combineLatest } from 'rxjs';


@Component({
  selector: 'app-member-reciprocal-round',
  templateUrl: './member-reciprocal-round.component.html',
  styleUrls: [
    './member-reciprocal-round.component.scss',
    '../../../_styles/base-feature-page.scss'
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class MemberReciprocalRoundComponent extends BaseFeaturePage implements OnInit, OnDestroy, AfterViewInit {

  title = 'Rounds Played - Reciprocal Clubs';
  //subTitle = '';
  showTimeSliderWidget = false;


  showDataCards = false;
  showDataTable = false;
  showBaseLayers = false;
  showImageGallery = false;
  darkMode = false;
  includeNswProperty = false;

  public member_basemapName = 'gray'; // dark-gray gray
  public centre = Esri.initialCenter_Global;
  public zoom = 3;

  dashboardCharts: DashboardChart[] = [
     { chartName: 'ROUND_TOTAL' },
     { chartName: 'ROUND_VARIANCE' },
    // { chartName: 'ROUND_TOTAL_DAY' }, 
    // { chartName: 'GENDER_TOTAL_PERIOD' },
    // { chartName: 'ROUND_TOTAL_PERIOD_DAY' }, 
  ];

  private _csvDataArray: any = [];   // used by CSV load 
  private _dataArray_filter: any = [];
  private _dataArray_filter_VisitorPlayer: any = [];
  private _dataArray_filter_HomePlayer: any = [];

  private _dateFilterAttributeName_HomePlayer = 'GameDate';
  private _dateFilterAttributeName_VisitorPlayer = 'GameDate';
  protected featureLayerObject_VisitorPlayer: any;
  protected featureLayerObject_HomePlayer: any;
  public leadingLayerList: any[] = [];
  public trailingLayerList: any[] = [];
  

  @Output() visitorPlayerLayerReadyEvent = new EventEmitter<any>();
  @Output() homePlayerLayerReadyEvent = new EventEmitter<any>();

  constructor(
    protected ref: ChangeDetectorRef,
    protected dialog: MatDialog,
    protected currentModeService: CurrentModeService,
    protected geocodeDataService: GeocodeDataService,
    protected formChangeService: FormChangeService,
    protected formFieldChangeService: FormFieldChangeService,
    protected formFieldUpdateService: FormFieldUpdateService,
    protected elementRef: ElementRef,
    //
    private csvReadService: CsvReadService,
  ) {
    super(
      ref,
      dialog,
      currentModeService,
      geocodeDataService,
      formChangeService,
      formFieldChangeService,
      formFieldUpdateService,
      elementRef
    );
  }

  //(userChangeEnd)="timeSliderDateRangeChange($event)"

  ngOnInit(): void {
    this.thinking = true;
    // Get layers, features, lovs, domains, etc

    this.featureLayerObject = new MemberReciprocalClub();                     // List of clubs and locations
    this.featureLayerObject_HomePlayer = new MemberReciprocalGamePlay();      // Club members playing away
    this.featureLayerObject_VisitorPlayer = new MemberReciprocalGamePlay();   // Visitor club members playing locally
    
    const homePlayerDefnExpression = "HomeClubId = '" + Global.clubId + "'";     // The Lakes
    const visitorPlayerDefnExpression = "HomeClubId <> '" + Global.clubId + "'"; // Visitors

    this.featureLayerObject_HomePlayer.definitionExpression = homePlayerDefnExpression;
    this.featureLayerObject_VisitorPlayer.definitionExpression = visitorPlayerDefnExpression;

    // Wait for layers to load
    this.featureLayerObject.featureLayerLoadedEvent.pipe(takeUntil(this.ngUnsubscribe)).subscribe((response: any) => {
      this.featureLayerObject.getFeatureLayerData();
    });
 
    this.featureLayerObject_HomePlayer.featureLayerLoadedWithDataEvent.pipe(takeUntil(this.ngUnsubscribe)).subscribe((response: any) => {
      console.log('****** ! all Home Club data ready ! ******');

      // Add the layer to the map
      this.view.map.add(this.featureLayerObject_HomePlayer.featureLayer);

      // Emit Ready Event
      this.homePlayerLayerReadyEvent.emit();
    });

    this.featureLayerObject_VisitorPlayer.featureLayerLoadedWithDataEvent.pipe(takeUntil(this.ngUnsubscribe)).subscribe((response: any) => {
      console.log('****** ! all Visitor Club data ready ! ******', this.featureLayerObject_VisitorPlayer);
      // Define the renderer and cluster
      this.featureLayerObject_VisitorPlayer.featureLayer.renderer = this.featureLayerObject_VisitorPlayer.rendererVisitor;
      this.featureLayerObject_VisitorPlayer.featureLayer.featureReduction = this.featureLayerObject_VisitorPlayer.clusterConfigVisitor;

      // Add the layer to the map
      this.view.map.add(this.featureLayerObject_VisitorPlayer.featureLayer);
      
      // Emit Ready Event
      this.visitorPlayerLayerReadyEvent.emit();
    });

    combineLatest([
      this.featureLayerObject.featureLayerLoadedWithDataEvent,
      this.visitorPlayerLayerReadyEvent,
      this.homePlayerLayerReadyEvent
    ]).pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      // Create and Show Swipe Widget
      this.leadingLayerList = [this.featureLayerObject_HomePlayer.featureLayer, this.featureLayerObject.featureLayer];
      this.trailingLayerList = [this.featureLayerObject_VisitorPlayer.featureLayer, this.featureLayerObject.featureLayer];
      this.esriMapComponent.createSwipeWidget(this.leadingLayerList, this.trailingLayerList, 75);

      // Create and Show Legend
      this.legendLayerInfos = [
        {
          layer: this.featureLayerObject_HomePlayer.featureLayer,
          title: "The Lakes members playing at reciprocal clubs"
        },
        {
          layer: this.featureLayerObject_VisitorPlayer.featureLayer,
          title: "Members from reciprocal clubs playing at The Lakes"
        }
      ];

      this.esriMapComponent.createLegendWidget(this.legendLayerInfos, 'bottom-right', 0);

      // Configure Date Range / Time Slider
      // if (this._dateFilterAttributeName_HomePlayer) {
      //   this.dateFilterAttributeName = this._dateFilterAttributeName_HomePlayer;
      //   let timeExtent_HomePlayer = this.featureLayerObject_HomePlayer.timeExtent;
      //   this.dateRangeInfo = Global.getDateRangeWithFY(timeExtent_HomePlayer.start, timeExtent_HomePlayer.end);
      //   this.initialiseTimeSlider();
      // }

      this.refreshDisplay_GamePlay();
    });

    // Initialise and load the extra layers
    this.featureLayerObject_VisitorPlayer.getLovs();
    this.featureLayerObject_VisitorPlayer.getFeatureLayerConfigAndData();
    //this.featureLayerObject_VisitorPlayer.getFeatureLayerConfig();
    this.featureLayerObject_VisitorPlayer.updateVirtualFields();

    this.featureLayerObject_HomePlayer.getLovs();
    this.featureLayerObject_HomePlayer.getFeatureLayerConfigAndData();
    this.featureLayerObject_HomePlayer.updateVirtualFields();

    super.ngOnInit();
  }

  public viewCreatedEvent(view: any) {
    this.esriMapComponent.addBtnHidden = true;
    this.esriMapComponent.reportBtnHidden = true;

    


    super.viewCreatedEvent(view);
  }

  //
  // Get attributes from feature set and assign to class "featureAttributes", (view table, and view cards )- calc virtual fields
  //

  protected refreshDisplay() {
    this.featureLayerObject.updateVirtualFields();
    this._dataArray_filter = this.featureLayerObject.attributes;
    super.refreshDisplay();
  }

  protected refreshDisplay_GamePlay() {
    this.featureLayerObject.updateVirtualFields();
    this.featureLayerObject_VisitorPlayer.updateVirtualFields();
    this.featureLayerObject_HomePlayer.updateVirtualFields();
    
    this._dataArray_filter = this.featureLayerObject.attributes;
    this._dataArray_filter_VisitorPlayer = this.featureLayerObject_VisitorPlayer.attributes;
    this._dataArray_filter_HomePlayer = this.featureLayerObject_HomePlayer.attributes;

    this.createCharts_GamePlay();
  }

  protected createCharts_GamePlay() {
    //super.createCharts();

    console.log('createCharts_GamePlay() this._dataArray_filter', this._dataArray_filter);

    let homePlayerCount = this.createChartDataSet_Count_Grouped(this._dataArray_filter_HomePlayer, 'ReciprocalClubId');
    let visitorPlayerCount = this.createChartDataSet_Count_Grouped(this._dataArray_filter_VisitorPlayer, 'HomeClubId');
    
    let summaryData = [];
    let summaryDataVariance = [];
    let series: any[] = [];

    for (let club of this._dataArray_filter) {

      series = [];

      let h = homePlayerCount.find(x => x.name === club.ReciprocalClubId);
      let v = visitorPlayerCount.find(x => x.name === club.ReciprocalClubId);

      if (!h) {
        h = {
          name: club.ReciprocalClubId,
          value: 0
        }
      }

      if (!v) {
        v = {
          name: club.ReciprocalClubId,
          value: 0
        }
      }

      //console.log(h, v);

      let data = {
        name: 'Lakes member playing at the reciprocal club',
        value: h.value,
        extra: {
          country: club.Country,
          city: club.City,
          clubId: club.ReciprocalClubId
        }
      }

      series.push(data);

      data = {
        name: 'Reciprocal member playing at The Lakes',
        value: v.value,
        extra: {
          country: club.Country,
          city: club.City,
          clubId: club.ReciprocalClubId
        }
      }

      series.push(data);

      let data_s = {
        name: club.ClubName,
        series
      }

      summaryData.push(data_s);

      // Variance Chart Data

      let variance = h.value - v.value;

      console.log(club.ClubName, club.City, club.Country, h.value, v.value, variance);

      let dataVariance = {
        name: club.ClubName,
        value: variance,
        extra: {
          country: club.Country,
          city: club.City,
          clubId: club.ReciprocalClubId
        }
      }

      summaryDataVariance.push(dataVariance);

    }
    //console.log('createCharts_GamePlay() dataArray', dataArray);

    this.dashboardCharts.find(x => x.chartName == 'ROUND_TOTAL').summaryData = summaryData;
    this.dashboardCharts.find(x => x.chartName == 'ROUND_VARIANCE').summaryData = summaryDataVariance;
    
    //console.log('createCharts_GamePlay()', visitorPlayerCount, homePlayerCount);
    console.log('this.dashboardCharts', this.dashboardCharts);
  }

  //
  // CSV Data Load
  //

  protected async getData(): Promise<any> {
    let members: any[] = [];
    //let fileName: string = 'assets/data/ReciprocalRounds-2019.csv';
    //let fileName: string = 'assets/data/ReciprocalRounds-2020.csv';
    let fileName: string = 'assets/data/ReciprocalRounds-2021.csv';

    await this.csvReadService.readCsvFile(fileName, true).then((parsedData) => {
      members = parsedData;
    });

    return members;
  }

  // This fn creates features from a CSV
  private async XXcreateFeatures(featureLayer: any) {

    await this.getData().then((data) => {
      this._csvDataArray = data;
    });
    //console.log('this._memberDataArray', this._memberDataArray);

    let result: ApplyEditsResultMessage = new ApplyEditsResultMessage();
    let featuresService: FeaturesService = new FeaturesService();
    let params;
    let resultMessage: string = '';
    //let geometry: GeometryPoint;
    let newFeatures: any[] = [];

    for (let element of this._csvDataArray.data) {

      //console.log('this.createFeatures element', element);

      const homeClub = await this.featureLayerObject.getClubById(element['HomeClubId']);
      const reciprocalClub = await this.featureLayerObject.getClubById(element['ReciprocalClubId']);
      

      let geometry;

      const attributes = {
        'HomeClubId': element['HomeClubId'],
        'ReciprocalClubId': element['ReciprocalClubId'],
        'GameDate': getTime(parse(element['GameDate'], 'dd/MM/yyyy', new Date())),
        'HomeClubName': homeClub.clubName,
        'ReciprocalClubName': reciprocalClub.clubName,
      }

      if (element['HomeClubId'] === Global.clubId) {
        // The Lakes players visiting a reciprocal club
        geometry = new GeometryPoint(reciprocalClub.longitude, reciprocalClub.latitude);
      }
      else {
        // Reciprocal club visitor playing at The Lakes
        geometry = new GeometryPoint(homeClub.longitude, homeClub.latitude);
      }

      //geometry = new GeometryPoint(reciprocalClub.longitude, reciprocalClub.latitude);

      // Create a new feature
      const newFeature: EsriGraphic = {
        attributes,
        geometry
      };
      //console.log('this.createFeatures newFeature', newFeature);

      newFeatures.push(newFeature);
    }

    console.log('this.createFeatures newFeatures', newFeatures);

    // Setup the applyEdits parameter with adds
    const adds = {
      addFeatures: newFeatures
    };
    params = adds;

    console.log('createFeatures() params', params);

    // Send the adds to the GIS server (ESRI feature service)
    await featuresService.applyEditsFeatureLayer(featureLayer, params).then((editResult) => {
      result = editResult;
      console.log('createFeatures() applyEditsFeatureLayer result', result);
      //result.recordId = recordId;
    });

  }

}
