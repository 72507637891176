import { Component, Inject, OnInit, OnDestroy, Input, Output, EventEmitter, ViewChild, ChangeDetectorRef, ChangeDetectionStrategy, Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MatStepper } from '@angular/material/stepper';
import { getTime, toDate, format } from 'date-fns'
import { Domain, SubDomain, FormFieldConfig, ApplyEditsResultMessage, Address, GeometryPoint } from 'src/app/_esri/models/esri';
import { FormBuilderService } from 'src/app/_esri/services/form-builder.service';
import { CurrentModeService, CurrentModeData } from 'src/app/_globals/services/current-mode.service';
import { FormChangeService } from 'src/app/_globals/services/form-change.service';
import { FormFieldChangeService } from 'src/app/_globals/services/form-field-change.service';
import { FormFieldUpdateService } from 'src/app/_globals/services/form-field-update.service';
import { GeocodeDataService } from 'src/app/_globals/services/geocode-data.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Esri, FieldConfig, Feature, Field, AttachmentInfo, GeocodeResponse } from 'src/app/_esri/models/esri';
import { EsriEditFormMasterDetailComponent } from 'src/app/_esri/components/esri-edit-form-master-detail/esri-edit-form-master-detail.component';

import { EsriEditFormComponent } from 'src/app/_esri/components/esri-edit-form/esri-edit-form.component';
import { EsriEditFormButtonsComponent } from 'src/app/_esri/components/esri-edit-form-buttons/esri-edit-form-buttons.component';
import { ButtonClickService, ButtonClickData } from 'src/app/_globals/services/button-click.service';

export interface EditFormData {
  title: string,
  formFieldConfigs: FormFieldConfig[],
  featureLayer: any,
  feature: any,
  geometry?: any,
  domains?: Domain[],
  domains_lov?: Domain[],
  domains_lovSubDomain?: SubDomain[],
  useFormStepper?: boolean,
  currentMode?: string,
  fieldChangeWatchList?: string[],
  parentLovWatchList?: any[],
  defaultFieldValues?: any[],
  defaultFieldVisibility?: any[],
  markDirtyWhenOpened?: boolean,
  idType?: string,
  idTypeFieldName?: string,
  formName?: string,
}

@Component({
  template: ''
})

export abstract class BaseEditForm implements OnInit, OnDestroy {
  // System Environment
  protected ngUnsubscribe = new Subject<void>();
  private _numberOfTicks = 0;
  public data: EditFormData;

  @Output() addressSelectedEvent = new EventEmitter<GeocodeResponse>();

  // Elements
  @ViewChild(EsriEditFormComponent, { static: true }) public esriEditFormComponent: EsriEditFormComponent;
  @ViewChild(EsriEditFormButtonsComponent, { static: true }) public esriEditFormButtonsComponent: EsriEditFormButtonsComponent;

  constructor(
    protected ref: ChangeDetectorRef,
    protected currentModeService: CurrentModeService,
    protected buttonClickService: ButtonClickService,
  ) {
    setInterval(() => {
      this._numberOfTicks++;
      this.ref.markForCheck();
    }, 1000);
  }

  ngOnInit(): void {
    console.log('BaseEditForm ngOnInit()');

    //
    // Listen for button clicks - not needed here?
    //

    // Subscribe to button clicks
    this.buttonClickService.buttonClickData.pipe(takeUntil(this.ngUnsubscribe)).subscribe((subject: ButtonClickData) => {
      //console.log('BaseEditForm buttonClickService subject', subject);
      
      //if (subject.formName === 'FORM') {
        console.log('BaseEditForm buttonClickService subject', subject);

        switch (subject.button.toUpperCase()) {
          case 'SAVE':
            //this.saveBtnEvent(subject.event);

            let submitResult = {}

            this.esriEditFormComponent.submitFeatureForm().then((result) => {

              //console.log('this.esriEditFormComponent.submitFeatureForm', result);
              
              // check for errors
              if (result.result === 'SUCCESS') {
                //console.log('Form submit success');
                submitResult = {
                  result: result.result,
                  lastObjectId: result.objectId,
                  recordId: result.recordId,
                  message: ''
                }
              }
              else {
                //console.log('Form submit error', result);
                submitResult = {
                  result: result.result,
                  lastObjectId: null,
                  recordId: null,
                  message: result
                }
              }

              // Broadcast mode with form name and save result
              let currentModeData: CurrentModeData = {
                formName: subject.formName,
                mode: 'save-complete',
                event: submitResult
              }
              this.currentModeService.currentModeData.next(currentModeData);
            });

            break;

          default:
            console.log('BaseFeaturePage buttonClickService - no handler defined for event', subject);
            break;
        }
      //}
    });





  }

  ngOnDestroy() {
    // Unsubscribe from events / observables
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public async createEditForm(): Promise<any> {
    //
    // Send data to the form
    //
    this.esriEditFormComponent.formFieldConfigs = this.data.formFieldConfigs;
    this.esriEditFormComponent.featureLayer = this.data.featureLayer;
    this.esriEditFormComponent.feature = this.data.feature;
    this.esriEditFormComponent.geometry = this.data.geometry;
    this.esriEditFormComponent.domains = this.data.domains;
    this.esriEditFormComponent.domains_lov = this.data.domains_lov;
    this.esriEditFormComponent.domains_lovSubDomain = this.data.domains_lovSubDomain;
    this.esriEditFormComponent.useFormStepper = this.data.useFormStepper;

    this.esriEditFormButtonsComponent.currentMode = this.data.currentMode;
    this.esriEditFormComponent.fieldChangeWatchList = this.data.fieldChangeWatchList;
    this.esriEditFormComponent.parentLovWatchList = this.data.parentLovWatchList;

    this.esriEditFormComponent.defaultFieldValues = this.data.defaultFieldValues;
    this.esriEditFormComponent.defaultFieldVisibility = this.data.defaultFieldVisibility;
    this.esriEditFormComponent.markDirtyWhenOpened = this.data.markDirtyWhenOpened;
    this.esriEditFormComponent.idType = this.data.idType;
    this.esriEditFormComponent.idTypeFieldName = this.data.idTypeFieldName;
    this.esriEditFormButtonsComponent.formName = this.data.formName;

    //
    // Create the form
    //
    await this.esriEditFormComponent.initialiseEditForm(); //.then((form) => {});
    console.log('BaseEditForm  this.esriEditFormComponent.initialiseEditForm() featureForm', this.esriEditFormComponent.featureForm);

    return;
  }

  public formLoadedEvent(event) {
    this.esriEditFormComponent.formLoaded = event;
  }
}





// export abstract class BaseEditForm implements OnInit, OnDestroy {

//   // System Environment
//   protected ngUnsubscribe = new Subject<void>();
//   private _numberOfTicks = 0;

//   public useFormStepper: boolean = true;
//   public formLoaded: boolean = false;
//   public featureLayer: any = null;
//   public formFieldConfigs: FormFieldConfig[] = [];
//   public feature: any = null;

//   public domains: Domain[] = [];
//   public domains_lov: Domain[] = [];
//   public domains_lovSubDomain: SubDomain[] = [];

//   public featureForm: any = null;
//   public formControls: any = null;
//   public geometry: any = null;
//   public fieldChangeWatchList = []; // ['AuditCoursePart', 'FairwayNumber']
//   public parentLovWatchList = [];   // [{parentLovKey: 'RiskCategoryId', childLovName: 'LovRiskSubCategory', childLovKey: 'RiskSubCategoryId'}, {..}, {..}]
//   public defaultFieldValues: any[] = [];
//   public defaultFieldVisibility:any[] = [];
//   public markDirtyWhenOpened: boolean = false;
//   public result: ApplyEditsResultMessage = new ApplyEditsResultMessage();
//   public formValid: boolean = false;
//   public idType: string;
//   public idTypeFieldName: string;
//   private _readOnly: boolean = false; // = true;
//   private _masterDetail: boolean = false; // = true;
//   private _selectedAddress: Address;
//   private _country: string = 'Australia';

//   @Input()
//   set readOnly(readOnly: boolean) {
//     this._readOnly = readOnly;
//   }

//   get readOnly(): boolean {
//     return this._readOnly;
//   }

//   @Input()
//   set masterDetail(masterDetail: boolean) {
//     this._masterDetail = masterDetail;
//   }

//   get masterDetail(): boolean {
//     return this._masterDetail;
//   }

//   @Output() featureFormLoadedEvent = new EventEmitter<any>();
//   @Output() featureFormSubmittedEvent = new EventEmitter<any>();

//   //@ViewChild('submitResult', { static: true }) private submitResultEl: ElementRef;
//   @ViewChild('stepper') public stepper: MatStepper;

//   constructor(
//     private ref: ChangeDetectorRef,
//     private formBuilderService: FormBuilderService,
//     private currentModeService: CurrentModeService,
//     private formChangeService: FormChangeService,
//     private formFieldChangeService: FormFieldChangeService,
//     private formFieldUpdateService: FormFieldUpdateService,
//     private geocodeDataService: GeocodeDataService,
//   ) { 
//     setInterval(() => {
//       this._numberOfTicks++;
//       this.ref.markForCheck();
//     }, 1000);
//   }

//   ngOnInit(): void {
//     //console.log('EsriEditFormComponent ngOnInit');
//   }

//   ngOnDestroy() {
//     // Unsubscribe from events / observables
//     this.ngUnsubscribe.next();
//     this.ngUnsubscribe.complete();
//   }

//   //
//   // Initialise
//   //

//   public async initialiseEditForm(): Promise<any> {
//     console.log('initialiseEditForm()');

//     // Generate a form and set the field values if a feature exists in edit mode
//     this.formBuilderService.generateFeatureForm(this.formFieldConfigs, this.feature).then((featureForm) => {
//       //console.log('this.feature', this.feature);
//       this.featureForm = featureForm;
//       this.formControls = this.featureForm.controls['formArray'];
//       //console.log('this.formControls', this.formControls);

//       // Set defaults or supplied values
//       this.defaultFieldValues.forEach( defaultField => {
//         //console.log('defaultFieldValues', defaultField);
//         this.setFieldValue(defaultField.name, defaultField.value);
//       });

//       // Set initial show / hide
//       this.defaultFieldVisibility.forEach( defaultField => {
//         //console.log('defaultFieldVisibility', defaultField);
//         this.setFieldVisibility(defaultField.name, defaultField.value);
//       });

//       // Initialise fields in watch list
//       this.fieldChangeWatchList.forEach( field => {
//         for (let i = 0; i < this.formControls.value.length; i++) { 
//           let value = this.formControls.value[i][field];
//           if (value) {
//             let fieldValueChanges = {
//               fieldName: field,
//               newValue: value
//             }
//             // Send event message back to the calling form to handle any actions
//             this.formFieldChangeService.formFieldChangeData.next(fieldValueChanges);
//           }
//         }
//       });

//       // Initialise Parent LOV's if value is set
//       this.parentLovWatchList.forEach( lov => {
//         for (let i = 0; i < this.formControls.value.length; i++) { 
//           let value = this.formControls.value[i][lov.parentLovKey];
//           if (value) {
//             //console.log('parentLovWatchList.forEach value', value);
//             this.setParentLov(lov.childLovName, lov.childLovKey, value);
//           }
//         }
//       });

//       if (this.readOnly === true) {
//         console.log('readOnly');
//         //this.esriEditFormElementsComponent.readOnly = this.readOnly;
//         this.disableForm();
//       }
//       else {
//         console.log('NOT readonly');
//         // Listen to and subscribe to form changes
//         this.onFeatureFormChanges();

//         // Listen for address searches
//         this.geocodeDataService.geocodeData.pipe(takeUntil(this.ngUnsubscribe)).subscribe( (event) => {
//           //console.log('initialiseEditForm adddress select event', event);
//           if (event) {
//             this.addressSelected(event);
//           }
//           else {
//             this.addressCleared();
//           }    
//         });

//         // Listen for changes to fields initiated by the user  
//         this.formFieldUpdateService.formFieldUpdateData.pipe(takeUntil(this.ngUnsubscribe)).subscribe( (fieldUpdateData: any) => {
//           console.log('initialiseEditForm formFieldUpdateData fieldUpdateData', fieldUpdateData);

//           // const fieldUpdateData = {
//           //   updateType: 'visibility', | 'value' | 'mark-dirty'
//           //   data: 
//           // }

//           switch (fieldUpdateData.updateType) {
//             case 'visibility':
//               for (let update of fieldUpdateData.data) {
//                 this.setFieldVisibility(update.name, update.value);
//               };
//               break;

//             case 'value':
//               for (let update of fieldUpdateData.data) {
//                 this.setFieldValue(update.name, update.value);
//               };
//               break;            

//             default:
//               break;
//           }
//         });

//         if (this.markDirtyWhenOpened === true) {
//           this.markFormDirty();
//         }
//       }

//       // The form can render now
//       this.featureFormLoadedEvent.emit(true);
//       this.formLoaded = true;

//       return featureForm;
//     });
//   }

//   //
//   // Form Level Changes
//   //

//   public onFeatureFormChanges(): void {
//     // Form Status Changes
//     this.formControls.statusChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(status => { 
//       //console.log('this.formControls statusChanges', status);
//       this.formChangeService.formChangeData.next(status);
//     });

//     // Listen for value changes (as required)
//     this.fieldChangeWatchList.forEach( field => {
//       for (let i = 0; i < this.featureForm.controls.formArray.controls.length; i++) { 

//         // Check if field has a parent
//         let parentWatch = this.parentLovWatchList.find(x => x.childLovKey === field);

//         // Find desired field and subscribe to watch for changes in value (eg to set ParenLOV details)
//         if (this.featureForm.value.formArray[i].hasOwnProperty(field)) {
//           this.featureForm.controls.formArray.controls[i].get(field).valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(value => {
//             //console.log(field + ' changed. New value is:', value); 

//             //console.log('fieldChangeWatchList formArray.controls[i]', this.featureForm.controls.formArray.controls[i].controls);

//             let fieldValueChanges; 
//             let parent;

//             // Send the parent field name and value along with the changed value
//             if (parentWatch) {
//               //console.log('fieldChangeWatchList has parent in watch lov list', parentWatch.parentLovKey);
//               for (let j = 0; j < this.featureForm.controls.formArray.controls.length; j++) { 
//                 if (this.featureForm.value.formArray[j].hasOwnProperty(parentWatch.parentLovKey)) {
//                   parent = this.featureForm.controls.formArray.controls[j].get(parentWatch.parentLovKey);
//                   //console.log('fieldChangeWatchList parentWatch.parentLovKey, parent.value', parentWatch.parentLovKey, parent.value);
//                 }
//               }
//               fieldValueChanges = {
//                 fieldName: field,
//                 newValue: value,
//                 parentFieldName: parentWatch.parentLovKey,
//                 parentValue: parent.value
//               }

//             }
//             else {
//               fieldValueChanges = {
//                 fieldName: field,
//                 newValue: value,
//               }
//             }

//             // Send event message back to the calling form to handle any actions
//             this.formFieldChangeService.formFieldChangeData.next(fieldValueChanges);
//           });
//         }
//       }
//     });

//     // Reset Parent / Child Lov's when parent key changes
//     // eg parentLovWatchList = [{parentLovKey: 'RiskCategoryId', childLovName: 'LovRiskSubCategory', childLovKey: 'RiskSubCategoryId'}, {..}, {..}]
//     this.parentLovWatchList.forEach( lov => {
//       for (let i = 0; i < this.featureForm.controls.formArray.controls.length; i++) { 
//         // Find desired field and subscribe to watch for changes in value (eg to set ParenLOV details)
//         if (this.featureForm.value.formArray[i].hasOwnProperty(lov.parentLovKey)) {
//           this.featureForm.controls.formArray.controls[i].get(lov.parentLovKey).valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(value => {
//             //console.log(field + ' changed. New value is:', value);  
//             this.setParentLov(lov.childLovName, lov.childLovKey, value);
//           });
//         }
//       }
//     });
//   }

//   public clearForm() {
//     // Clear the form and Reset defualt values
//     if (this.featureForm) {
//       this.featureForm.reset();
//       //this.setDefaults();
//     }

//     // Clear any previous messages
//     if (this.result) {
//       this.result.result = '';
//       this.result.message = '';
//     }
//   }

//   public disableForm() {
//     this.featureForm.disable()
//   }

//   public enableForm() {
//     this.featureForm.enable()
//   }

//   public markFormDirty() {
//     if (this.featureForm.pristine === true) {
//       this.featureForm.pristine = false;
//       this.featureForm.touched = true;
//       this.formChangeService.formChangeData.next(this.featureForm.status);
//     } 
//   }

//   //
//   // Form Submit
//   //

//   public async submitFeatureForm(integration?: any): Promise<any> {

//     console.log('EsriEditFormComponent submitFeatureForm', this.featureForm);

//     // this._featureForm.status    // VALID
//     // this._featureForm.pristine  // true false
//     // this._featureForm.touched   // true false
//     // this._featureForm.errors

//     if (!this.featureForm.pristine) {
//       await this.formBuilderService.submitFeatureForm(this.featureForm, this.feature, this.featureLayer, this.idType, this.idTypeFieldName, this.geometry, integration).then( (editResult) => {
//         this.result = editResult;
//         //console.log('EsriEditFormComponent submitFeatureForm editResult', editResult);
//         this.featureFormSubmittedEvent.emit(this.result);
//       });
//     }
//     else {
//       console.log('EsriEditFormComponent submitFeatureForm no changes');
//     }

//     return this.result;
//   }  

//   //
//   // Field Level Functions
//   //

//   public setParentLov(lovName: string, fieldName: string, currentValue: any) {
//     console.log('setParentLov');
//     console.log(lovName, fieldName, currentValue);

//     // Only attempt to set the Parent if a current value exists
//     if (currentValue) {
//       //console.log('this.domains_lovParent', this.domains_lovParent);
//       let domain = this.domains_lovSubDomain.find(x => x.name === lovName).domains.find(y => y.code === currentValue).domain;

//       // Set up options in RiskSubCategory
//       // This gets the formArray that the field is in
//       const frmFldCfg = this.formFieldConfigs.find(x => x.fieldConfig.find(y => y.name === fieldName));
//       //console.log('frmFldCfg', frmFldCfg);

//       // Find the Sub-Category Field
//       let fldCfg = frmFldCfg.fieldConfig.find(x => x.name === fieldName);
//       //console.log('fldCfg', fldCfg);

//       // Update the <mat-option> values for the newly selected cat value
//       fldCfg.domain_options = domain.codedValues;
//     }
//   }

//   public setFieldValue(fieldName: string, fieldValue: any) {
//     console.log('setFieldValue', fieldName, fieldValue);

//     for (let i = 0; i < this.formControls.length; i++) {
//       if (this.featureForm.value.formArray[i].hasOwnProperty(fieldName)) {
//         let controlToUpdate = this.formControls.controls[i].get(fieldName);

//         // Check if it is a date field, convert epoch to Date if it is
//         if (fieldName.toUpperCase().indexOf('DATE') > 0) {
//           if (fieldValue) {
//             fieldValue = toDate(fieldValue);
//           }
//         }

//         controlToUpdate.setValue(fieldValue, {emitEvent: false});
//       }
//     }
//   }

//   public setFieldVisibility(fieldName: string, visible: boolean) {
//     console.log('setFieldVisibility', fieldName, visible);
//     // This gets the formArray that the field is in
//     const frmFldCfg = this.formFieldConfigs.find(x => x.fieldConfig.find(y => y.name === fieldName));

//     // Find the Field
//     let fldCfg = frmFldCfg.fieldConfig.find(x => x.name === fieldName);

//     // Set the visiblity of the field
//     fldCfg.visible = visible;


//     return;
//   }

//   public setGeometry(geometry: any) {
//     this.geometry =  geometry;
//   }

//   //
//   // Address Functions
//   //

//   public addressSelected(event: any) {
//     //console.log('addressSelected', event);

//     // Store the address
//     this._selectedAddress = new Address(event.candidates[0].attributes.StAddr, event.candidates[0].attributes.Nbrhd, event.candidates[0].attributes.Region, event.candidates[0].attributes.Postal, this._country);

//     // Create the geometry
//     this.geometry = new GeometryPoint(
//       event.candidates[0].location.x,
//       event.candidates[0].location.y
//     );

//     let addressUpdates: any[] = [
//       { name: 'AddressLine1', value: this._selectedAddress.AddressLine1 },
//       { name: 'AddressLine2', value: this._selectedAddress.AddressLine2 },
//       { name: 'Suburb', value: this._selectedAddress.Suburb },
//       { name: 'Postcode', value: this._selectedAddress.Postcode },
//       { name: 'State', value: this._selectedAddress.State },
//       { name: 'Country', value: this._selectedAddress.Country },
//       { name: 'FullAddress', value: this._selectedAddress.FormattedAddress }
//     ];

//     // Update the address fields in the form
//     addressUpdates.forEach( update => {
//       this.setFieldValue(update.name, update.value);
//     });

//     // Reverse geocoding takes a while and doesn't mark the form dirty when updating from a feature move, 
//     // so force the form to be dirty
//     this.markFormDirty();
//   }

//   public addressCleared() {
//     //console.log('addressClearedEvent_Search');
//     // Clear stored address and remove graphic
//     this._selectedAddress = null;
//     this.geometry = null;
//   }
// }
