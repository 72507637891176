<app-header>
  <span title>{{title}}</span>
  <span toolbar-extras></span>
</app-header>

<div *ngIf='!thinking' style="height: 4px;"></div>
<div *ngIf='thinking'>
  <mat-progress-bar mode="query"></mat-progress-bar>
</div>

<!-- Image Gallery Row -->
<div fxLayout='row' class='image-row'>
  <app-esri-attachment style='width: 100%'></app-esri-attachment> 
</div>

<!-- Map Row -->
<div fxLayout='row' class='map-row' style='height: 700px;'>
  <app-esri-map fxLayout='row' style='width: 100%;' 
    [zoom]='zoom'
    [center]='centre'
    [sidePanelWidth]='sidePanelWidth' 
    [showFullscreenWidget]='true'
    [showLegendWidget]='false' 
    [basemapName]='member_basemapName'
    (viewCreatedEvent)='viewCreatedEvent($event)'>

    <div #popupMenu [hidden]="!loaded">
      <app-esri-popup-menu [popupType]="'MENU'">
        <!--  [latitude]='geometry.latitude' [longitude]='geometry.longitude' *ngIf='menuPopupVisible' -->
        <div class="popup-menu-items">
          <button mat-menu-item *ngIf="selectedFeature"
            (click)='currentModeService.currentModeData.next("edit-attribute")'>
            <mat-icon class='material-icons-sharp'>edit</mat-icon>
            <span>Edit this item</span>
          </button>

          <button mat-menu-item *ngIf="!selectedFeature" (click)='createFeatureFromPopupMenuEvent()'>
            <mat-icon class='material-icons-sharp'>add_location</mat-icon>
            <span>Create a new item here</span>
          </button>

          <button mat-menu-item *ngIf="selectedFeature" (click)='moveFeatureFromPopupMenuEvent()'>
            <mat-icon class='material-icons-sharp'>edit_location</mat-icon>
            <span>Move the selected item here</span>
          </button>          
        </div>
      </app-esri-popup-menu>
    </div>

    <div #popupData [hidden]="!loaded">
      <app-esri-popup-menu [popupType]="'DATA'">
        <div class="popup-data-items">
          <app-esri-edit-form [readOnly]='true'></app-esri-edit-form>
        </div>
      </app-esri-popup-menu>
    </div>
  </app-esri-map>
</div>

<!-- Table Data Row -->
<div *ngIf='showDataTable' fxLayout='row' class='table-data-row'>
    <app-esri-view-table [ngClass]="{'dark': darkMode}" (addButtonSelectedEvent)='addBtnEvent()'></app-esri-view-table>
</div>

<!-- Footer -->
<app-footer></app-footer>