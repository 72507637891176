import { Component, OnInit, OnDestroy, AfterViewInit, Output, EventEmitter, ViewChild, ElementRef, HostListener, ChangeDetectorRef } from '@angular/core';
import { combineLatest, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { Options, LabelType, ChangeContext } from '@angular-slider/ngx-slider';
import { getTime, toDate, format, isBefore, getYear, isWithinInterval } from 'date-fns';
import { environment } from 'src/environments/environment';
import { Global, GroupByRange, GroupArray } from 'src/app/_globals/models/global';
import { Esri, Address, FormFieldConfig, FieldConfig, FeatureServiceQueryResponse, Feature, Domain, SubDomain, EsriGeometryType, GeometryPoint, GeometryMultiPoint, GeometryPolyline, GeometryPolygon, AttachmentInfo, DashboardChart, ReportDefinition } from 'src/app/_esri/models/esri';
import { FeaturesService } from 'src/app/_esri/services/features.service';
import { CourseService } from 'src/app/_course/services/course.service';
import { SurroundsService } from 'src/app/_course/services/surrounds.service';
import { EsriEditFormComponent } from 'src/app/_esri/components/esri-edit-form/esri-edit-form.component';
import { EsriViewTableComponent } from 'src/app/_esri/components/esri-view-table/esri-view-table.component';
//import { EsriViewCardsComponent } from 'src/app/_esri/components/esri-view-cards/esri-view-cards.component';
import { EsriPopupMenuComponent } from 'src/app/_esri/components/esri-popup-menu/esri-popup-menu.component';
import { EsriAttachmentComponent } from 'src/app/_esri/components/esri-attachment/esri-attachment.component';
import { EsriMapComponent } from 'src/app/_esri/components/esri-map/esri-map.component';
import { EditComponent } from 'src/app/_esri/dialogs/edit/edit.component';
import { ReportComponent } from 'src/app/_esri/dialogs/report/report.component';
import { CurrentModeService, CurrentModeData } from 'src/app/_globals/services/current-mode.service';
import { GeocodeDataService } from 'src/app/_globals/services/geocode-data.service';
import { FormChangeService } from 'src/app/_globals/services/form-change.service';
import { FormFieldChangeService } from 'src/app/_globals/services/form-field-change.service';
import { FormFieldUpdateService } from 'src/app/_globals/services/form-field-update.service';

import { DomainLovService } from 'src/app/_esri/services/domain-lov.service';
import { LovDataService } from 'src/app/_globals/services/lov-data.service';
import { ButtonClickService, ButtonClickData } from 'src/app/_globals/services/button-click.service';

import { EsriEditFormMasterDetailComponent } from 'src/app/_esri/components/esri-edit-form-master-detail/esri-edit-form-master-detail.component';

@Component({
  template: '',
})

export abstract class BaseFeaturePage implements OnInit, OnDestroy, AfterViewInit {

  // Main Object
  protected featureLayerObject: any;

  // System Environment
  protected ngUnsubscribe = new Subject<void>();
  private _numberOfTicks = 0;

  // Titles
  protected title: string = '';
  protected subTitle: string = '';

  // Layer Details
  protected is3D: boolean = false;
  protected includeAllSurrounds: boolean = false;
  protected includeHillshade: boolean = true;
  protected includeNswProperty: boolean = false;

  // Feature Data
  public loaded: boolean = false;
  protected _layerView = null;

  public geometryPoint: GeometryPoint = null;
  public geometryMultiPoint: GeometryMultiPoint = null;
  public geometryPolyline: GeometryPolyline = null;     // <-- still needs to be defined properly in ESRI model
  public geometryPolygon: GeometryPolygon = null;

  protected view: any;
  public geometry: any = null;

  // ****************

  protected geometryType: string = EsriGeometryType.Point; // enum EsriGeometryType
  public dateRangeInfo: any = {};
  public dateFrom: Date;
  public dateTo: Date;
  // ****************

  protected graphicsLayer: any = null;
  protected boundaryLayer: any = null;
  protected fairwayLayer: any = null;
  //protected geocodeLayer: any = null;
  public legendLayerInfos: any[] = [];
  private _highlight = null;
  protected dateFilterAttributeName: string;

  // Dashboard Charts
  public chartHeight: number = 300;
  public chartWidth: number;
  public chartWidth_2: number;
  public chartWidth_3: number;
  public chartWidth_4: number;
  public chartWidth_5: number;
  public chartWidth_6: number;
  public chartWidth_7: number;
  public chartWidth_8: number;
  public dashboardCharts: DashboardChart[] = [];
  public dashboardCards: DashboardChart[] = [];
  public removableChip: boolean = true;

  // Gallery Settings
  public galleryLoaded: boolean = false;
  private _attachmentInfos: AttachmentInfo[] = [];

  // Reports
  public reportDefinitions: ReportDefinition[] = [];

  // Popups
  private _popupType: string;       // MENU or DATA
  private _preventZoomPan: boolean = false;
  protected popupMenuNumberItems: string = '1';
  protected popupMenuNumberItems_selectedFeature: string = '2';
  protected popupMenuWidth: string = '250';

  // Dialog
  protected fieldChangeWatchList = []; // e.g.: ['AuditCoursePart', 'FairwayNumber', ...]
  protected parentLovWatchList = [];   // e.g.: [{parentLovKey: 'RiskCategoryId', childLovName: 'LovRiskSubCategory', childLovKey: 'RiskSubCategoryId'}, {..}, {..}]\  
  protected formDialogRef: any;
  protected defaultFieldValues: any[] = [];
  protected defaultFieldVisibility: any[] = [];
  private _reportDialogRef: any;
  protected formName: string = 'FORM';

  // Display Configuration
  public thinking: boolean = false;
  public showImageGallery: boolean = true;
  public showDataTable: boolean = false;
  public showDataCards: boolean = true;
  protected showDataPopup: boolean = false;
  protected showMasterDetailForm: boolean = false;
  public sidePanelWidth: number;
  public darkMode: boolean = false;
  //protected darkModeBackground: string = 'rgb(33, 33, 33)';
  protected darkModeBackground: string = 'rgb(46, 46, 46)';
  //protected darkModeBackground: string = 'rgb(58, 58, 58)';
  public basemapName: string = 'newspaper';  // newspaper dark-gray
  //public numberPopupMenuItems: number = 3;
  //public galleryInsideMap: boolean = false;
  public showTimeSliderWidget: boolean = false;
  public showActionIconsInTable: boolean = true;
  //public zoomToFeature: boolean = false;
  //public panToFeature: boolean = true;
  //private _panToFeature_previous: boolean = true;
  //public moveFeatureEnabled: boolean = false;
  protected forceFieldVisibleInTable: boolean = false;
  protected showBaseLayers: boolean = true;
  protected showBaseAssetLayers: boolean = true;
  
  private _addNewFeatureEnabled: boolean = false;
  private _selectZoomLevel = Esri.selectZoom;
  private _useFormStepper: boolean = false;
  protected courseLayerOpacity: number = 0.8;
  protected surroundsLayerOpacity: number = 0.5;
  protected boundaryLayerOpacity: number = 0.5;
  protected hillshadeLayerOpacity: number = 0.1;
  protected nswPropertyLayerOpacity: number = 0.5;

  // Charts

  // private _customColorScheme_Light = {
  //   //domain: ['#A30404', '#3F51B5', '#673AB7', '#068020', '#FF6F00', '#FFEB3B', '#F9F9F9']
  //   //domain: ['#FCAC3F', '#00A2ED', '#ED2960', '#7753BF', '#FF5B36', '#00BC68']
  //   //domain: ['#A30404', '#673AB7', '#3F51B5', '#FF6F00']
  //   domain: ['rgba(189, 0, 0, 0.5)', 'rgba(63, 81, 181, 0.5)', 'rgba(103, 58, 183, 0.5)', 'rgba(6, 128, 32, 0.5)', 'rgba(255, 111, 0, 0.5)', 'rgba(255, 235, 59, 0.5)']
  // };

  // private _customColorScheme_Dark = {
  //   domain: ['#FCAC3F', '#00A2ED', '#ED2960', '#7753BF', '#FF5B36', '#00BC68']
  // };

  // public openCloseColorScheme = {
  //   //domain: ['#A30404', '#3F51B5', '#673AB7', '#068020', '#FF6F00', '#FFEB3B', '#F9F9F9']
  //   domain: [ '#068020', '#A30404']
  // };

  private _nightLightsColorScheme = {
    domain: ['#715AB7', '#AF50B8', '#5983BB', '#786BA2', '#A669AB', '#6B83A2']
  }

  public pinkColorScheme = {
    //domain: [ '#C500FF']
    domain: ['#AF50B8']
  };

  public genderColorScheme = [
    {
      name: 'Male',
      value: '#5983BB'
    },
    {
      name: 'Female',
      value: '#AF50B8'
    },
    {
      name: 'Unknown',
      value: '#e0e0e0'
    }
  ];

  //public customColorScheme = this._customColorScheme_Light;
  public customColorScheme = this._nightLightsColorScheme;

  public pieChartAdvancedStyle: string = "";
  private _pieChartAdvancedStyle_Dark: string = "color: rgb(249, 249, 249);"

  // Current Settings
  public selectedFeature: any;
  //public numberOfImagesMsg: string;
  protected whereDateClause: string = '';
  protected whereDateClauseEpoch: string = '';
  protected filter: string = '';
  protected filterEpoch: string = '';
  protected currentMode: string = 'start';
  protected selectedAddress: Address;
  protected country: string = 'Australia';
  protected idType: string;
  protected idTypeFieldName: string;
  protected lastrecordId: string;
  private _lastObjectId: number = 0;
  private _whereClause: string; // = '1=1';

  public timeSliderReady: boolean = false;

  public timeSliderOptions: Options;

  // Feature Edit Workflow
  protected allowNoLocation: boolean = false;
  //public locationHidden: boolean = true;
  //public locationMap: boolean = false;
  //public locationAddress: boolean = false;

  //public addBtnHidden: boolean = false;
  //public addressSelectBtnDisabled: boolean = true;
  //public locationSelectBtnDisabled: boolean = true;


  // Start Review >>>>

  //public addBtnHidden: boolean = false;
  //public addPointHidden: boolean = true;
  //public addInfoHidden: boolean = true;
  public assetHidden: boolean = true;
  //public addressHidden: boolean = true;
  //public locationNextHidden: boolean = true;
  public assetNextHidden: boolean = true;
  //public addressNextHidden: boolean = true;
  //public addPointNextHidden: boolean = true;
  public assetSelectHidden: boolean = true;


  public linkAsset: boolean;
  private _assetClass: string;
  private _assetCategory: string;
  private _assetType: string;
  private _assetId: string;

  // <<<< End


  //
  protected workRequestAttributes: any;



  protected featuresService: FeaturesService;
  protected courseService: CourseService;
  protected surroundsService: SurroundsService;
  //protected geocodeDataService: GeocodeDataService;
  //protected formChangeService: FormChangeService;
  //protected formFieldChangeService: FormFieldChangeService;
  //protected formFieldUpdateService: FormFieldUpdateService;

  // Events
  //@Output() featureLayerReadyEvent = new EventEmitter<any>();
  @Output() allReadyEvent = new EventEmitter<any>();
  @Output() dataReadyEvent = new EventEmitter<any>();
  @Output() lovReadyEvent = new EventEmitter<any>();
  @Output() lovSubDomainReadyEvent = new EventEmitter<any>();
  @Output() fieldConfigReadyEvent = new EventEmitter<any>();
  @Output() attachmentInfosChange = new EventEmitter<AttachmentInfo[]>();
  @Output() galleryImagesReady = new EventEmitter<boolean>();
  //@Output() reportProgessEvent = new EventEmitter<any>();
  @Output() responsiveWidthBreakpointEvent = new EventEmitter<string>();
  @Output() featureFormLoadedEvent = new EventEmitter<string>();

  // AccessChild component attributes and functions
  @ViewChild(EsriMapComponent) esriMapComponent: EsriMapComponent;
  @ViewChild(EsriEditFormComponent) esriEditFormComponent: EsriEditFormComponent;   // Used by DATA popup in map
  @ViewChild(EsriEditFormMasterDetailComponent) esriEditFormMasterDetailComponent: EsriEditFormMasterDetailComponent;
  @ViewChild(EsriViewTableComponent) esriViewTableComponent: EsriViewTableComponent;
  //@ViewChild(EsriViewCardsComponent) esriViewCardsComponent: EsriViewCardsComponent;
  @ViewChild(EsriPopupMenuComponent) esriPopupMenuComponent: EsriPopupMenuComponent;
  @ViewChild(EsriAttachmentComponent) esriAttachmentComponent: EsriAttachmentComponent;

  // Access HTML Elements
  //@ViewChild("infoPanelTitle", { static: true }) public infoPanelTitleEl: ElementRef;
  //@ViewChild("mapGalleryPanel", { static: true }) public mapGalleryPanelEl: ElementRef;
  @ViewChild("popupMenu", { static: true }) protected popupMenuEl: ElementRef;
  @ViewChild("popupData", { static: true }) protected popupDataEl: ElementRef;
  //@ViewChild("zoomToFeatureChoice", { static: true }) private zoomToFeatureChoiceEl: ElementRef;
  //@ViewChild("panToFeatureChoice", { static: true }) private panToFeatureChoiceEl: ElementRef;
  //@ViewChild("addBtn", { static: true }) private addBtnEl: ElementRef;
  //@ViewChild("addFeatureWorkflow", { static: true }) public addFeatureWorkflowEl: ElementRef;
  @ViewChild("timeSlider", { static: true }) private timeSliderEl: ElementRef;

  // Listen to window size changes to adjust number of rows in results table
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.setResponsiveLayout();
  }

  //
  // Construct, Initialise, Destroy
  //

  constructor(
    protected ref: ChangeDetectorRef,
    // protected featuresService: FeaturesService,
    // protected courseService: CourseService,
    // protected surroundsService: SurroundsService,
    protected dialog: MatDialog,
    protected currentModeService: CurrentModeService,
    protected geocodeDataService: GeocodeDataService,
    protected formChangeService: FormChangeService,
    protected formFieldChangeService: FormFieldChangeService,
    protected formFieldUpdateService: FormFieldUpdateService,
    //protected lovDataService: LovDataService,
    //protected domainLovService: DomainLovService,
    //protected buttonClickService: ButtonClickService,
    protected elementRef: ElementRef
  ) {
    setInterval(() => {
      this._numberOfTicks++;
      this.ref.markForCheck();  // require view to be updated
    }, 100);
    //this.currentModeService.currentModeData.next('');
  }

  public ngOnInit(): void {
    this.thinking = true;
    // Services
    this.featuresService = new FeaturesService();
    this.courseService = new CourseService(this.featuresService);
    this.surroundsService = new SurroundsService(this.featuresService);

    //console.log('this.featureLayerObject.timeExtent', this.featureLayerObject.timeExtent);

    // Set Filter / Where Clause
    this._whereClause = this.featureLayerObject.whereClause;

    this.createFilterClause();
    this.featureLayerObject.whereClause = this.filter;

    console.log('this.featureLayerObject.whereClause 2', this.featureLayerObject.whereClause);


    this.featureLayerObject.getLovs();

    // Wait for feature layer to load
    this.featureLayerObject.featureLayerLoadedEvent.pipe(takeUntil(this.ngUnsubscribe)).subscribe((response: any) => {
      console.log('****** ! feature Layer object ready ! ******', this.featureLayerObject);

      // Configure Date Range / Time Slider
      if (this.dateFilterAttributeName) {
        let timeExtent = this.featureLayerObject.timeExtent;
        this.dateRangeInfo = Global.getDateRangeWithFY(timeExtent.start, timeExtent.end);
        this.initialiseTimeSlider();
      }

      // Emit Ready Event
      //this.featureLayerReadyEvent.emit();

      // Query the data after the feature layer is fully loaded and configured
      this.query();
    });

    // Set theme
    if (this.darkMode === true) {
      //this.customColorScheme = this._customColorScheme_Dark;
      this.pieChartAdvancedStyle = this._pieChartAdvancedStyle_Dark;
      this.basemapName = 'dark-gray-vector';
    }
    else {
      //this.customColorScheme = this._customColorScheme_Light;
      this.pieChartAdvancedStyle = "";
      this.basemapName = 'gray-vector';
    }

    // Make responsive
    this.setResponsiveLayout();

    // Set the sidepanel width
    if (this.showDataCards === true) {
      this.sidePanelWidth = 500;
    }
    else {
      this.sidePanelWidth = 0;
    }

    // Initialise multi point geometry
    if (this.geometryType === EsriGeometryType.MultiPoint) {
      this.geometryMultiPoint = new GeometryMultiPoint();
      this.geometryMultiPoint.points = [];
    }

    // Subscribe to user workflow actions
    this.currentModeService.currentModeData.pipe(takeUntil(this.ngUnsubscribe)).subscribe((subject: CurrentModeData) => {
      console.log('BaseEditForm currentModeService subject', subject);
      let mode: string = '';

      switch (subject.mode.toUpperCase()) {
        case 'SAVE-COMPLETE':
          if (subject.event.result === 'SUCCESS') {
            this._lastObjectId = subject.event.lastObjectId;

            this.lastrecordId = subject.event.recordId;

            //this.setCurrentMode('form-submitted');
            //this.currentModeService.currentModeData.next('form-submitted');
            mode = 'form-submitted';
          }
          else {
            this._lastObjectId = subject.event.lastObjectId;
            //this.setCurrentMode('form-submitted-error');
            //this.currentModeService.currentModeData.next('form-submitted-error');
            mode = 'form-submitted-error';
          }

          let currentModeData: CurrentModeData = {
            formName: subject.formName,
            mode
          }

          // Re-broadcast with result of save
          this.currentModeService.currentModeData.next(currentModeData);
          break;

        default:
          // Pass through to setCurrentMode()
          this.setCurrentMode(subject);
          break;
      }

    });

    // Subscribe to geocoding actions
    this.geocodeDataService.geocodeData.pipe(takeUntil(this.ngUnsubscribe)).subscribe((subject: any) => {
      console.log('this.geocodeDataService', subject);
    });

    // Listen for changes to screen size and set element sizes / layouts as appropriate
    // this.responsiveWidthBreakpointEvent.pipe(takeUntil(this.ngUnsubscribe)).subscribe( (screenSize: string) => {
    //   /*
    //       1 <= xs <= 599
    //     600 <= sm <= 959
    //     960 <= md <= 1279
    //     1280 <= lg <= 1919
    //     1920 <= xl <= 5000
    //   */

    //   // switch (screenSize) {
    //   //   case "xs":
    //   //     this.chartWidth = 400;
    //   //     break;

    //   //   case "sm":
    //   //     this.chartWidth = 600;
    //   //     break;

    //   //   case "md":
    //   //     this.chartWidth = 900;
    //   //     break;

    //   //   case "lg":
    //   //     // Screen layout change here (LHS gets set to 750 px)
    //   //     this.chartWidth = 750;
    //   //     break;   

    //   //   case "xl":
    //   //     this.chartWidth = 750;
    //   //     break;        

    //   //   default:
    //   //     break;
    //   // }

    // });
  }

  public ngOnDestroy(): void {
    console.log('BaseFeaturePage ngOnDestroy()');
    //https://www.intertech.com/angular-best-practice-unsubscribing-rxjs-observables/

    // Stop the change detector
    clearInterval();

    // Unsubscribe from events / observables
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();

    // Destroy the view
    if (this.view) {
      this.view.destroy();
    }

    // Destroy the subscribes in the feature class
    this.featureLayerObject.destroyer();
  }

  public ngAfterViewInit() {
    if (this.darkMode === true) {
      this.elementRef.nativeElement.ownerDocument.body.style.backgroundColor = this.darkModeBackground;
    }
  }

  //
  // View Created
  //

  public viewCreatedEvent(view: any) {
    console.log('base viewCreatedEvent');

    // if (this.showDataCards === true) {
    //   this.esriMapComponent.showDataCards = true;
    //   //this.esriMapComponent.sidePanelWidth = 500;
    // }
    // else {
    //   this.esriMapComponent.showDataCards = false;
    //   //this.esriMapComponent.sidePanelWidth = 0;
    // }

    this.view = view;

    // Load the Feature Layer
    this.featureLayerObject.getFeatureLayerConfig();

    if (this.showBaseLayers === true) {
      // Load the base course and scene layers
      this.loadBaseLayers_Tiles(this.is3D, this.includeAllSurrounds, this.includeHillshade, this.includeNswProperty).then((layersArray) => {
        this.loaded = true;

        // Load these on top
        if (this.showBaseAssetLayers === true) {
          this.loadBaseAssetLayers(this.is3D);
        }

      }).catch((error) => {
        // do something if a reject.
        console.log('loadBaseLayers_Tiles() Promise error:', error);
      });

      
    }
    else {
      this.loadBoundaryLayersOnly(this.is3D).then((layersArray) => {
        this.loaded = true;
      }).catch((error) => {
        // do something if a reject.
        console.log('loadBoundaryLayersOnly() Promise error:', error);
      });
    }

    // Listen for mouse clicks
    this.view.on('click', event => {
      //
      // Left Mouse Click
      //
      if (event.button === 0) {
        //console.log('lm click');
        this.view.hitTest(event).then((response) => {

          // Select the location when in create ne Feature mode
          if (this._addNewFeatureEnabled === true) {
            // New Feature Location Selected
            console.log('lm click _addNewFeatureEnabled=TRUE');

            if (this.esriMapComponent.locationHidden === false) {
              console.log('setting locationHidden');
              this.esriMapComponent.locationHidden = true;
            }

            switch (this.geometryType) {

              case EsriGeometryType.MultiPoint:
                // this.geometryMultiPoint = new GeometryMultiPoint();
                // this.geometryMultiPoint.points = [];
                this.geometryMultiPoint.points.push([response.results[0].mapPoint.longitude, response.results[0].mapPoint.latitude]);
                this.geometry = this.geometryMultiPoint;
                this.addGraphic(this.geometry);

                this.esriMapComponent.locationSelectBtnDisabled = false;

                console.log('EsriGeometryType.MultiPoint this.geometry', this.geometry);
                break;

              case EsriGeometryType.Polygon:
                //
                break;

              case EsriGeometryType.Polyline:
                //
                break;

              case EsriGeometryType.Point:
              default:
                // Capture the location and create a new feature
                this.geometryPoint = new GeometryPoint(response.results[0].mapPoint.longitude, response.results[0].mapPoint.latitude);
                this.geometry = this.geometryPoint;

                //this.currentModeService.currentModeData.next("location-selected");

                let currentModeData: CurrentModeData = {
                  formName: 'FORM',
                  mode: 'location-selected'
                }
                this.currentModeService.currentModeData.next(currentModeData);

                break;
            }

            //this.currentModeService.currentModeData.next("location-selected");
          }
          else {
            // Select (or clear) existing feature From the map
            //console.log('lm click', response);
            if (response.results.length > 0 && response.results[0].graphic && response.results[0].graphic.layer.id == this.featureLayerObject.featureLayer.id) {
              // Feature selected
              //console.log('Feature found response.results[0].graphic.attributes', response);
              this.selectFeature(response.results[0].graphic.attributes, "MAP");

              if (this.showDataPopup === true) {
                // Show the info data popup
                this.populateViewForm();
                this._popupType = 'DATA';
                this.createPopup(event, this._popupType);
              }

              if (this.showMasterDetailForm === true) {
                //this.createMasterDetailForm(event);
              }

            }
            else {
              // Clear Selection
              console.log('Feature not found');
              this.unSelectFeature();
            }
          }
        })
          .catch((error) => {
            console.log('this.view.hitTest LM click Promise error', error);
          });
      }
      //
      // Right Mouse Click
      //
      else if (event.button === 2) {
        //console.log('click eventHandler - rm click');

        // Popup a menu
        this.view.hitTest(event).then((response) => {

          this._preventZoomPan = true;

          // If a feature is selected, include an edit and move option in the menu
          if (response.results[0].graphic && response.results[0].graphic.layer.id == this.featureLayerObject.featureLayer.id) {
            //console.log('Feature found - rm click');
            this.selectFeature(response.results[0].graphic.attributes, "MAP");
          }

          // Capture the location and create a new feature
          //this.geometry = new GeometryPoint(response.results[0].mapPoint.longitude, response.results[0].mapPoint.latitude);

          switch (this.geometryType) {
            case EsriGeometryType.MultiPoint:

              this.geometryMultiPoint = new GeometryMultiPoint();
              //this.geometryMultiPoint.spatialReference = { wkid: 3857 };
              this.geometryMultiPoint.points = [];
              this.geometryMultiPoint.points.push([response.results[0].mapPoint.longitude, response.results[0].mapPoint.latitude]);
              this.geometry = this.geometryMultiPoint;
              console.log('point, this.geometry', this.geometryMultiPoint, this.geometry);


              break;

            case EsriGeometryType.Polygon:
              //
              break;

            case EsriGeometryType.Polyline:
              //
              break;

            case EsriGeometryType.Point:
            default:
              // Capture the location and create a new feature
              this.geometryPoint = new GeometryPoint(response.results[0].mapPoint.longitude, response.results[0].mapPoint.latitude);
              this.geometry = this.geometryPoint;
              break;
          }

          // Show the menu popup
          this._popupType = 'MENU';
          this.createPopup(event, this._popupType);
        })
          .catch((error) => {
            console.log('this.view.hitTest RM click Promise error', error);
          });
      }
      else {
        //console.log('click eventHandler - other click');
        //console.log(event);
      }
    });

    //
    // Listen (subscribe) to...
    //

    // Map Reports Button
    this.esriMapComponent.reportBtnSelectedEvent.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.unSelectFeature();
      //console.log('reportBtnSelectedEvent');
      this.openReportDialog();
    });


    // Map Create Incident Button
    this.esriMapComponent.createWorkRequestBtnSelectedEvent.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.createWorkRequestBtnEvent();
    });

    // Map Create Project Button
    this.esriMapComponent.createProjectBtnSelectedEvent.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.createProjectBtnEvent();
    });

    // this.esriViewTableComponent.attachActionSelectedEvent.pipe(takeUntil(this.ngUnsubscribe)).subscribe( (feature) => {
    //   this.selectFeature(feature, "ATTACH");
    //   this.attachmentBtnEvent();
    // });

    if (this.showImageGallery === true) {
      // Image Gallery Ready
      this.esriAttachmentComponent.galleryImagesReadyEvent.pipe(takeUntil(this.ngUnsubscribe)).subscribe((event) => {
        //console.log('BaseFeaturePage galleryImagesReadyEvent()');
        this.galleryLoaded = event;
      });
    }

    // Address Lookup / Geocode
    this.geocodeDataService.geocodeData.pipe(takeUntil(this.ngUnsubscribe)).subscribe((event) => {
      if (event) {
        this.addressSelected(event);
      }
      else {
        this.addressCleared();
      }
    });

    // Form Changes
    this.formChangeService.formChangeData.pipe(takeUntil(this.ngUnsubscribe)).subscribe((event) => {
      this.featureFormChangeEvent(event);
    });

    // Form Field Changes
    this.formFieldChangeService.formFieldChangeData.pipe(takeUntil(this.ngUnsubscribe)).subscribe((event) => {
      this.featureFormFieldChangeEvent(event);
    });

    // Load the Feature Layer
    //this.featureLayerObject.getLayer(); //.then( () => {
    //console.log('this.featureLayerObject.load().then', this.featureLayerObject);


    // // Data Table
    // if (this.showDataTable === true) {

    //   // Table Header Add Button
    //   this.esriViewTableComponent.addBtnSelectedEvent.pipe(takeUntil(this.ngUnsubscribe)).subscribe( () => {
    //     this.addBtnEvent();
    //   });

    //   // Table Header Reports Button
    //   this.esriViewTableComponent.reportBtnSelectedEvent.pipe(takeUntil(this.ngUnsubscribe)).subscribe( () => {
    //     this.unSelectFeature();
    //     //console.log('reportBtnSelectedEvent');
    //     this.openReportDialog();
    //   });

    //   // Table row selection
    //   this.esriViewTableComponent.tableRowSelectedEvent.pipe(takeUntil(this.ngUnsubscribe)).subscribe( (rowData) => {
    //     // Only select the feature on the map if the event resulted from clickinga row in the table
    //     if (rowData.origin === "TABLE") {
    //       //this.panToFeature = true;

    //       // Close the popup if it is open
    //       if (this.view.popup.visible === true) {
    //         this.view.popup.visible = false;
    //       }

    //       this.selectFeature(rowData.feature, rowData.origin);
    //     }
    //   });

    //   // Table Row Action Button - Edit
    //   this.esriViewTableComponent.editActionSelectedEvent.pipe(takeUntil(this.ngUnsubscribe)).subscribe( (feature) => {
    //     this.selectFeature(feature, "EDIT");
    //     this.editBtnEvent();
    //   });

    //   // Get the list of field names to display in the table
    //   this.esriViewTableComponent.tableFields = this.featureLayerObject.fieldConfigTypes.tableFieldConfig;

    //   this.esriViewTableComponent.tableFields.forEach( field => {
    //     this.esriViewTableComponent.displayedColumns.push(field.name);
    //   });

    //   this.esriViewTableComponent.showActionIconsInTable = this.showActionIconsInTable;
    //   this.esriViewTableComponent.fieldAlwaysVisible = this.forceFieldVisibleInTable;

    //   if (this.showActionIconsInTable === true) {     
    //     this.esriViewTableComponent.displayedColumns.push('actionIcons');
    //   }
    // }

    // // Cards
    // if (this.showDataCards === true) {
    //   // Card row selection
    //   this.esriMapComponent.esriViewCardsComponent.tableRowSelectedEvent.pipe(takeUntil(this.ngUnsubscribe)).subscribe( (rowData) => {
    //     // Only select the feature on the map if the event resulted from clickinga row in the table
    //     if (rowData.origin === "TABLE") {
    //       //this.panToFeature = true;

    //       // Close the popup if it is open
    //       if (this.view.popup.visible === true) {
    //         this.view.popup.visible = false;
    //       }

    //       this.selectFeature(rowData.feature, rowData.origin);
    //     }
    //   });

    //   // Card Row Action Button - Edit
    //   this.esriMapComponent.esriViewCardsComponent.editActionSelectedEvent.pipe(takeUntil(this.ngUnsubscribe)).subscribe( (feature) => {
    //     this.selectFeature(feature, "EDIT");
    //     this.editBtnEvent();
    //   });

    //   // Get the list of field names to display in the cards 
    //   this.esriMapComponent.esriViewCardsComponent.cardFieldConfigs = this.featureLayerObject.fieldConfigTypes.cardFieldConfig;
    // }

    // //this.extent = this.featureLayerObject.extent;

    // let timeExtent = this.featureLayerObject.timeExtent; 
    // this.dateRangeInfo = Global.getDateRangeWithFY(timeExtent.start, timeExtent.end);
    // this.initialiseTimeSlider();

    // this.queryFeatures();


    // //this.fieldConfigReadyEvent.emit(true);
    //});

    //console.log('base viewCreatedEvent end');
  }

  //
  // Control workflow
  //

  //protected setCurrentMode(mode: string) { 
  protected setCurrentMode(currentModeData: CurrentModeData) {  // THIS NEEDS A LOT OF WORK CurrentModeData

    // Only process parent forms here.  Child forms (with names other than FORM) should be processed in extended class
    if (currentModeData.formName.toUpperCase() === 'FORM') {

      let mode = currentModeData.mode.toLowerCase();

      // The enable / disable of buttons is controlled by the component "EsriEditFormButtonsComponent"
      console.log('BaseFeaturePage setCurrentMode mode', mode);
      //console.log('BaseFeaturePage setCurrentMode allowNoLocation hasLocation', this.esriMapComponent.allowNoLocation, this.esriMapComponent.hasLocation);

      if (mode === 'form-valid' || mode === 'form-invalid') {
        // Don't set it
      }
      else {
        this.currentMode = mode;
      }

      switch (mode) {
        case 'select':
          break;

        case 'add':
          this.resetCurrent();
          this.esriMapComponent.addBtnHidden = true;
          this.esriMapComponent.reportBtnHidden = true;
          this._addNewFeatureEnabled = true;
          this.esriMapComponent.locationSelectBtnDisabled = true;
          break;

        case 'add-popup':
          this.resetCurrent();
          this.esriMapComponent.addBtnHidden = true;
          this.esriMapComponent.reportBtnHidden = true;

          //this.currentModeService.currentModeData.next("location-selected");
          let currentModeData: CurrentModeData = {
            formName: this.formName,
            mode: 'location-selected'
          }
          this.currentModeService.currentModeData.next(currentModeData);
          break;

        case 'location':
          this.esriMapComponent.hasLocation = true;
          this.esriMapComponent.locationNoneChoice = false;
          this.esriMapComponent.addBtnHidden = true;
          this.esriMapComponent.reportBtnHidden = true;
          this.esriMapComponent.locationSelectBtnDisabled = true;
          this.esriMapComponent.locationNoneChoice = false;
          this.esriMapComponent.locationHidden = false;
          this._addNewFeatureEnabled = true;
          break;

        case 'location-none':
          this.esriMapComponent.hasLocation = false;
          this.esriMapComponent.locationNoneChoice = true;
          this.esriMapComponent.locationHidden = true;
          this.esriMapComponent.locationMap = false;
          this.esriMapComponent.locationAddress = false;
          break;

        case 'location-map':
          // Select a location on the map
          this.esriMapComponent.locationHidden = true;
          this.esriMapComponent.addressSelectBtnDisabled = true;
          this.esriMapComponent.locationMap = true;
          this.esriMapComponent.locationAddress = false;
          this.esriMapComponent.locationNoneChoice = false;
          this.esriMapComponent.addBtnHidden = true;
          this.esriMapComponent.reportBtnHidden = true;
          break;

        case 'location-address':
          // Select a location from an address lookup
          this.esriMapComponent.locationHidden = true;
          this.esriMapComponent.addressSelectBtnDisabled = true;
          this.esriMapComponent.locationMap = false;
          this.esriMapComponent.locationAddress = true;
          this.esriMapComponent.locationNoneChoice = false;
          this.esriMapComponent.addBtnHidden = true;
          this.esriMapComponent.reportBtnHidden = true;
          break;

        case 'location-selected':
          //console.log('base -> location-selected');
          this.view.popup.visible = false;
          this.esriMapComponent.addressSelectBtnDisabled = false;
          this._addNewFeatureEnabled = false;

          if (this.esriMapComponent.hasLocation === false) {
            // Use default location for records with no location associated with them
            this.geometry = new GeometryPoint(Esri.noLocationDefault[0], Esri.noLocationDefault[1]);
          }

          this.addGraphic(this.geometry);

          // if (!this._geocodeLayer) {
          //   this.addGeocodeGraphic(this.geometry);
          // }

          //   // Create new feature - attributes
          //   this.addBtnHidden = true;
          //   this.locationHidden = true;
          //   this.addPointHidden = true;
          //   this.addInfoHidden = false;
          //   this._addNewFeatureEnabled = false;

          //   // this.esriEditFormComponent.initialiseEditForm().then( () => {
          //   //   // Always show the first step when form opens
          //   //   if (this.esriEditFormComponent.stepper) {
          //   //     this.esriEditFormComponent.stepper.selectedIndex = 0;
          //   //   }

          //   //   // Enable the edit tab and set focus to it
          //   //   this.editTabDisabled = false;
          //   //   this.selectedTab.setValue(1);

          //   //   // Add a reference to the point to the defualt location defined for risks that have no location
          //   //   if (mode === 'location-none') {
          //   //     this.esriEditFormComponent.geometry = Audit.NoLocationPoint;
          //   //     this.esriEditFormComponent.setFieldValue('HasLocation', 'NO');
          //   //   }
          //   //   else {
          //   //     this.esriEditFormComponent.setFieldValue('HasLocation', 'YES');
          //   //   }

          //   // });

          break;

        case 'close':
          this.resetCurrent();
          this.esriMapComponent.addBtnHidden = false;
          this.esriMapComponent.reportBtnHidden = false;
          break;

        case 'location-cancel':
        case 'add-cancel':
          this.resetCurrent();
          this.esriMapComponent.locationMap = false;
          this.esriMapComponent.locationAddress = false;
          this.esriMapComponent.locationHidden = true;
          this.esriMapComponent.locationNoneChoice = false;
          this.esriMapComponent.addBtnHidden = false;
          this.esriMapComponent.reportBtnHidden = false;
          break;

        case 'move':
          this.moveFeatureGraphic(this.geometry);
          break;

        case 'location-changed':
          //this.formFieldUpdateService.formFieldUpdateData.
          break;

        case 'edit-attribute':
          //this.openEditFeatureDialog(mode);
          this.view.popup.visible = false;
          break;

        case 'form-valid':
          break;

        case 'form-invalid':
          break;

        case 'form-submitted':
          this.unSelectFeature();
          this.removeGraphic();

          //Re-query (from server) to reflect changes in table, map, and dashboard
          this.displayMapFeatures(true, this._lastObjectId);
          this.esriMapComponent.addBtnHidden = false;
          this.esriMapComponent.reportBtnHidden = false;

          if (this.lastrecordId) {
            let fieldValue: any[] = [];
            let fieldUpdateData = {};

            fieldValue.push({
              name: this.idTypeFieldName,
              value: this.lastrecordId
            });

            fieldUpdateData = {
              updateType: 'value',
              data: fieldValue
            }

            if (fieldUpdateData) {
              this.formFieldUpdateService.formFieldUpdateData.next(fieldUpdateData);
            }
          }

          //this.queryFeatureLayerData();
          this.query();

          break;

        case 'clear':
        case 'start':
        default:
          this.unSelectFeature();
          break;
      }
    }

  }

  //
  // Right Mouse Click - Popup Menu
  //

  public createFeatureFromPopupMenuEvent() {
    //this.setCurrentMode('add-popup');
    let currentModeData: CurrentModeData = {
      formName: this.formName,
      mode: 'add-popup'
    }
    this.setCurrentMode(currentModeData);
  }

  public moveFeatureFromPopupMenuEvent() {
    //this.setCurrentMode('move');
    let currentModeData: CurrentModeData = {
      formName: this.formName,
      mode: 'move'
    }
    this.setCurrentMode(currentModeData);
  }

  public moveMultiPointFeatureFromPopupMenuEvent() {
    //this.setCurrentMode('move-multi');
    let currentModeData: CurrentModeData = {
      formName: this.formName,
      mode: 'move-multi'
    }
    this.setCurrentMode(currentModeData);
  }

  public addMultiPointFeatureFromPopupMenuEvent() {
    //this.setCurrentMode('add-multi');
    let currentModeData: CurrentModeData = {
      formName: this.formName,
      mode: 'add-multi'
    }
    this.setCurrentMode(currentModeData);
  }

  public removeMultiPointFeatureFromPopupMenuEvent() {
    //this.setCurrentMode('delete-multi');
    let currentModeData: CurrentModeData = {
      formName: this.formName,
      mode: 'delete-multi'
    }
    this.setCurrentMode(currentModeData);
  }

  //
  // Add / Move Feature Functions
  //

  private async addGraphic(geometry: any): Promise<any> {
    //console.log('addGraphic geometry', geometry);

    //    this.removeGraphic();

    // Client Side Feature Layer

    await this.featuresService.createFeatures_ClientFeatureLayer('clientSideFeatureLayer', 'Feature Location', this.geometryType, this.geometry).then((layer) => {
      console.log('addGraphic createFeatureLayerFeatures', layer);
      this.graphicsLayer = layer;

      // Make sure a graphic object exists
      if (this.graphicsLayer.source.items.length > 0) {
        // Add the layer to the map
        this.view.map.add(this.graphicsLayer);
        this.esriMapComponent.addressSelectBtnDisabled = false;
        this.esriMapComponent.locationSelectBtnDisabled = false;
      }
    });

    // Graphics Layer <-- this has an issue where the symbols don't show immediately on the map.  V. frustrating!!!!!

    // await this.featuresService.createGraphicsLayerFeatures('graphicsLayerFeatureLocation', 'Feature Location', this.geometryType, this.geometry).then( (layer) => {     
    //   console.log('addGraphic creatGraphicsLayerFeatures', layer);
    //   this.graphicsLayer = layer;

    //   // Make sure a graphic object exists
    //   if (this.graphicsLayer.graphics.items.length > 0) {
    //     // Add the layer to the map
    //     this.view.map.add(this.graphicsLayer);
    //     this.esriMapComponent.addressSelectBtnDisabled = false;
    //     this.esriMapComponent.locationSelectBtnDisabled = false;
    //   }
    // });

    return;
  }

  private moveFeatureGraphic(geometry: any) {
    console.log('moveFeatureGraphic', geometry, this.geometry);

    // Remove any existing graphics from the map 
    if (this.graphicsLayer) {
      this.view.map.remove(this.graphicsLayer);
    }

    this.addGraphic(geometry).then(() => {
      //this.setCurrentMode('location-changed');
      let currentModeData: CurrentModeData = {
        formName: this.formName,
        mode: 'location-changed'
      }
      this.setCurrentMode(currentModeData);
    });
  }

  private removeGraphic() {
    console.log('removing');

    if (this.graphicsLayer) {
      this.view.map.remove(this.graphicsLayer);
      this.graphicsLayer = null;
      //this.geometry = null;
    }

    if (this.esriEditFormComponent) {
      this.esriEditFormComponent.geometry = null;
    }


    // if (this.esriEditFormMasterDetailComponent) {
    //   this.esriEditFormMasterDetailComponent.geometry = null;
    // }


  }

  protected resetCurrent() {

    console.log('BaseFeaturePage resetCurrent()');

    this.unSelectFeature();
    this.selectedAddress = null;
    this.removeGraphic();
    this.defaultFieldValues = [];
    this.defaultFieldVisibility = [];

    if (this.geometryMultiPoint) {
      this.geometryMultiPoint.points = [];
    }
  }

  //
  // Chart Filters
  //

  private createSelectClause(domainName: string, keyField: string, keyName: string, otherClause: string, keyFieldIsNumeric?: boolean) {
    console.log('createSelectClause', domainName, keyField, keyName, otherClause, keyFieldIsNumeric);

    let clause: string = '';
    let _keyFieldIsNumeric: boolean = false;
    const domains = [...this.featureLayerObject.domains, ...this.featureLayerObject.domains_lov];

    if (keyFieldIsNumeric) {
      _keyFieldIsNumeric = keyFieldIsNumeric;
    }

    if (keyName != 'Not set') {
      let code = domains.filter(x => x.name === domainName)[0].codedValues.find(x => x.name === keyName).code;

      if (_keyFieldIsNumeric === true) {
        clause = keyField + " = " + code;
      }
      else {
        clause = keyField + " = '" + code + "'";
      }
    }
    else {
      clause = otherClause;
    }

    return clause;
  }

  private queryChoiceSelectedEvent(selectAll?: boolean) {
    let clause: string = '';
    let idx = 0;
    let _selectAll: boolean = false;

    if (selectAll) {
      _selectAll = selectAll;
    }

    this.dashboardCharts.filter(x => x.visibleChip === true).forEach(element => {
      if (idx === 0) {
        clause += element.clause;
      }
      else {
        clause += ' AND ' + element.clause;
      }
      idx++;
    });

    //console.log('queryChoiceSelectedEvent() clause 1', clause);

    // No filters, so query all
    if (clause === '' || _selectAll === true) {
      //this._whereClause = "1=1";
      this._whereClause = this.featureLayerObject.whereClause;
    }
    else {
      this._whereClause = clause;
    }

    //console.log('queryChoiceSelectedEvent() this._whereClause ', this._whereClause);

    //this.queryFeatures();
    //this.queryFeatureLayerData();
    this.query();
  }

  private allSelect(event) {
    this.queryChoiceSelectedEvent(true);
  }

  protected createCharts() {
    //console.log('base createCharts()');
  }

  //
  // Query Functions
  //

  protected query() {
    this.queryFeatureLayerData().then( () => {
      this.displayPageComponents();
      this.refreshDisplay();
    });
  }

  protected timeSliderDateRangeChange(changeContext: ChangeContext): void {
    console.log('changeContext', changeContext);

    let queryFromDate = changeContext.value;
    let queryToDate = changeContext.highValue;

    this.whereDateClause = this.dateFilterAttributeName + " between '" + format(queryFromDate, 'yyyy-MM-dd') + "' and '" + format(queryToDate, 'yyyy-MM-dd') + "'";
    this.whereDateClauseEpoch = this.dateFilterAttributeName + " between " + queryFromDate + " and " + queryToDate;

    //this.queryFeatures(); 
    //this.queryFeatureLayerData();
    this.query();
  }

  protected async queryFeatureLayerData(): Promise<any> {
    console.log('queryFeatureLayerData() base');
    this.thinking = true;

    if (this.selectedFeature) {
      this.unSelectFeature();
    }

    this.createFilterClause();
    //console.log('reQuery this._whereClause, this.filter', this._whereClause, this.filter);

    // Query the layer
    await this.featureLayerObject.queryFeatureLayer(this.filter).then(() => {
      if (this.featureLayerObject.features) {
        console.log('Number of features found - queryFeatureLayerData(): ', this.featureLayerObject.features.length);
        //this.features = this.featureLayerObject.features;
      }
      else {
        console.log('No features found - queryFeatureLayerData()');
      }

      this.thinking = false;
      this.dataReadyEvent.emit(true);
    });

    return;
  }

  protected async displayMapFeatures(gotoTablePageOfObject?: boolean, objectId?: number): Promise<any> {

    this.thinking = true;

    this.createFilterClause();

    if (this.selectedFeature) {
      this.unSelectFeature();
    }

    // Add the layer

    // Add the data to the results table
    if (this.featureLayerObject.features.length > 0) {

      // Don't attempt to display features from a table
      if (this.featureLayerObject.featureLayer.isTable != true) {

        // Add the layer to the map
        this.view.map.add(this.featureLayerObject.featureLayer);

        this.view.whenLayerView(this.featureLayerObject.featureLayer).then(layerView => {
          //console.log('******** whenLayerView ********');
          this._layerView = layerView;
          this._layerView.filter = {
            where: this.filterEpoch
          }
        })
          .catch((error) => {
            console.log('this.view.whenLayerView() Promise error:', error);
          });

        // Zoom to extents of query
        //if (this._whereClause != '1=1') {
        this.showQueryResults();
        //}
        //else {
        //  this.showQueryResults(true);
        //}
      }

      if (this.showDataTable === true) {
        if (gotoTablePageOfObject === true) {
          // Find and go to the page of the last feature that was edited / added
          this.esriViewTableComponent.gotoPageNumberObjectId(objectId);
        }
      }
    }
    else {
      // No Features Found
      console.log('No features were found that match the selection criteria');
      this.featureLayerObject.features = [];
      this.unSelectFeature();

      if (this.showDataTable === true) {
        // Remove data from the results table
        if (this.esriViewTableComponent.tableDataSource) {
          this.esriViewTableComponent.attributes = null;
          this.esriViewTableComponent.initilaiseViewTable();
        }
      }
      if (this.showDataCards === true) {
        this.esriMapComponent.esriViewCardsComponent.attributes = null;
        this.esriMapComponent.esriViewCardsComponent.initialiseViewCards();
      }
    }

    this.thinking = false;

    //console.log('dataReadyEvent emit');
    this.dataReadyEvent.emit(true);

    return;
  }

  protected createFilterClause() {
    //console.log('queryFeatures this._whereClause 1', this._whereClause, this.whereDateClause);

    this.filter = '';
    this.filterEpoch = '';

    //let where: string = '';
    //let whereEpoch: string = '';

    // if (queryAll === true) {
    //   //this.filter = "1=1";
    //   this.filter = this.featureLayerObject.whereClause;
    // }
    // else {

      //if (this._whereClause === "1=1") {
      if (this._whereClause === this.featureLayerObject.whereClause) {
        if (this.whereDateClause) {
          this.filter = this.whereDateClause;
          this.filterEpoch = this.whereDateClauseEpoch;
        }
        else {
          //this.filter = this._whereClause;
          this.filter = this.featureLayerObject.whereClause;
        }
      }
      else {
        if (this.whereDateClause) {
          this.filter = this._whereClause + ' AND ' + this.whereDateClause;
          this.filterEpoch = this._whereClause + ' AND ' + this.whereDateClauseEpoch;
        }
        else {
          this.filter = this._whereClause;
        }
      }

    //}



    // return {
    //   where: this.filter, 
    //   whereEpoch: this.filterEpoch
    // }
  }
  
  protected displayPageComponents() {

    console.log('BaseFeaturePage displayPageComponents()', this.featureLayerObject);

    //this.featureLayer = this.featureLayerObject.featureLayer;

    // Configure Data Table
    if (this.showDataTable === true) {

      // Table Header Add Button
      this.esriViewTableComponent.addHeaderSelectedEvent.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
        this.addBtnEvent();
      });

      // Table Header Reports Button
      this.esriViewTableComponent.reportHeaderSelectedEvent.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
        this.unSelectFeature();
        //console.log('reportBtnSelectedEvent');
        this.openReportDialog();
      });

      // Table row selection
      this.esriViewTableComponent.tableRowSelectedEvent.pipe(takeUntil(this.ngUnsubscribe)).subscribe((rowData) => {
        // Only select the feature on the map if the event resulted from clickinga row in the table
        if (rowData.origin === "TABLE") {
          //this.panToFeature = true;

          // Close the popup if it is open
          if (this.view.popup.visible === true) {
            this.view.popup.visible = false;
          }

          this.selectFeature(rowData.feature, rowData.origin);
        }
      });

      // Table Row Action Button - Edit
      this.esriViewTableComponent.editActionSelectedEvent.pipe(takeUntil(this.ngUnsubscribe)).subscribe((feature) => {
        this.selectFeature(feature, "EDIT");
        this.editBtnEvent();
      });

      // Get the list of field names to display in the table
      this.esriViewTableComponent.tableFields = this.featureLayerObject.fieldConfigTypes.tableFieldConfig;

      this.esriViewTableComponent.tableFields.forEach(field => {
        this.esriViewTableComponent.displayedColumns.push(field.name);
      });

      this.esriViewTableComponent.showActionIconsInTable = this.showActionIconsInTable;
      this.esriViewTableComponent.fieldAlwaysVisible = this.forceFieldVisibleInTable;

      if (this.showActionIconsInTable === true) {
        this.esriViewTableComponent.displayedColumns.push('actionIcons');
      }
    }

    // Configure Cards
    if (this.showDataCards === true) {
      // Card row selection
      this.esriMapComponent.esriViewCardsComponent.tableRowSelectedEvent.pipe(takeUntil(this.ngUnsubscribe)).subscribe((rowData) => {
        // Only select the feature on the map if the event resulted from clickinga row in the table
        if (rowData.origin === "TABLE") {
          //this.panToFeature = true;

          // Close the popup if it is open
          if (this.view.popup.visible === true) {
            this.view.popup.visible = false;
          }

          this.selectFeature(rowData.feature, rowData.origin);
        }
      });

      // Card Row Action Button - Edit
      this.esriMapComponent.esriViewCardsComponent.editActionSelectedEvent.pipe(takeUntil(this.ngUnsubscribe)).subscribe((feature) => {
        this.selectFeature(feature, "EDIT");
        this.editBtnEvent();
      });

      // Get the list of field names to display in the cards 
      this.esriMapComponent.esriViewCardsComponent.cardFieldConfigs = this.featureLayerObject.fieldConfigTypes.cardFieldConfig;
    }






    // // Configure Date Range / Time Slider
    // if (this.dateFilterAttributeName) {
    //   let timeExtent = this.featureLayerObject.timeExtent;
    //   this.dateRangeInfo = Global.getDateRangeWithFY(timeExtent.start, timeExtent.end);
    //   this.initialiseTimeSlider();
    // }







    this.displayMapFeatures();

    //this.fieldConfigReadyEvent.emit(true);
  }

  protected refreshDisplay() {
    console.log('BaseFeaturePage refreshDisplay()');

    if (this.showDataTable === true) {
      //this.esriViewTableComponent.featureAttributes = this.featureAttributes;
      this.esriViewTableComponent.attributes = this.featureLayerObject.attributes;
      this.esriViewTableComponent.initilaiseViewTable();
    }

    if (this.showDataCards === true) {
      //this.esriMapComponent.esriViewCardsComponent.featureAttributes = this.featureAttributes;
      this.esriMapComponent.esriViewCardsComponent.attributes = this.featureLayerObject.attributes;
      this.esriMapComponent.esriViewCardsComponent.initialiseViewCards();
    }

    this.createCharts();

    if (this._layerView) {
      if (this.showTimeSliderWidget === true) {
        this._layerView.filter = {
          where: this.filterEpoch
        }
      }
      else {
        this._layerView.filter = {
          where: this.filter
        }
      }
    }
  }

  protected initialiseTimeSlider() {
    this.dateFrom = this.dateRangeInfo.financialYear.start.getTime();
    this.dateTo = this.dateRangeInfo.financialYear.end.getTime();

    this.timeSliderOptions = {
      floor: 0,
      ceil: 0,
      stepsArray: this.dateRangeInfo.months.map((date: Date) => {
        return { value: date.getTime() };
      }),
      translate: (value: number, label: LabelType): string => {
        return format(value, 'd MMM yy');
      },
      showTicks: false,
      draggableRange: true
    };
    this.whereDateClause = this.dateFilterAttributeName + " between '" + format(this.dateFrom, 'yyyy-MM-dd') + "' and '" + format(this.dateTo, 'yyyy-MM-dd') + "'";
    this.whereDateClauseEpoch = this.dateFilterAttributeName + " between " + this.dateFrom + " and " + this.dateTo;


    //this.reQuery();

    this.timeSliderReady = true;

    console.log('initialiseTimeSlider() this.dateFrom, this.dateTo', this.dateFrom, this.dateTo);
  }

  private showQueryResults(defaultExtent?: boolean) {
    if (this.esriMapComponent.zoomToFeature === true) {
      // Pan and Zoom to feature
      this.view.goTo(this.featureLayerObject.extent)
        // this.view.goTo({
        //   target: this.selectedFeature,
        //   zoom: this._selectZoomLevel //this._mapZoomLevel
        // })
        .catch(e => {
          console.error(e);
        });
    }
    else {
      if (this.esriMapComponent.panToFeature === true) {
        // Pan to feature
        this.view.goTo({
          target: this.selectedFeature,
        })
          .catch(e => {
            console.error(e);
          });
      }
      else {
        // Do nothing
      }
    }

    // if (defaultExtent === true) {
    //   // Go to default extent / home
    //   this.view.goTo({
    //     center: Esri.initialCenter,
    //     zoom: Esri.initialZoom  
    //   });
    // }
    // else {
    //   //console.log('this._features extent', this._featureServiceObject.extent);
    //   this.view.goTo(this._featureServiceObject.extent);
    // }


  }

  private queryAttachments() {
    this.galleryLoaded = false;

    console.log('this.selectedFeature', this.selectedFeature);

    // Query Feature service to get attachments
    if (this.selectedFeature) {

      let attachmentQuery = {
        objectIds: this.selectedFeature.attributes.OBJECTID,
        attachmentTypes: ['image/jpeg']
      };

      this.esriAttachmentComponent.selectedFeature = this.selectedFeature;

      this.selectedFeature.layer.queryAttachments(attachmentQuery).then(attachments => {
        //console.log('attachments', attachments);
        //if (attachments.length > 0) {
        if (attachments) {
          if (attachments[this.selectedFeature.attributes.OBJECTID]) {
            //console.log('attachments', attachments);
            this._attachmentInfos = attachments[this.selectedFeature.attributes.OBJECTID];

            if (!environment.production) {
              console.log('Number of attachments found:', this._attachmentInfos.length.toString());
            }

            //console.log('esriAttachmentComponent', this.esriAttachmentComponent);
            //this.esriAttachmentComponent.selectedFeature = this.selectedFeature;
            this.esriAttachmentComponent.attachmentInfos = this._attachmentInfos;
            this.esriAttachmentComponent.initilaiseGallery();

            // Send attachment info to parent component
            this.attachmentInfosChange.emit(this._attachmentInfos);
          }
          else {
            console.log('No attachments A');
            if (this.esriAttachmentComponent) {
              //this.esriAttachmentComponent.selectedFeature = this.selectedFeature;
              this.esriAttachmentComponent.attachmentInfos = [];
              this.esriAttachmentComponent.initilaiseGallery();
            }
          }
        }
        else {
          console.log('No attachments B');
          if (this.esriAttachmentComponent) {
            //this.esriAttachmentComponent.selectedFeature = this.selectedFeature;
            this.esriAttachmentComponent.attachmentInfos = [];
            this.esriAttachmentComponent.initilaiseGallery();
          }
        }
      });
    }
  }

  //
  // Form Changes
  //

  // public masterDetailFormLoadedEvent(event) {
  //   this.esriEditFormMasterDetailComponent.formLoaded = event;
  // }

  protected featureFormChangeEvent(event) {
    //console.log('Base Form Change', event);
  }

  protected featureFormFieldChangeEvent(event) {
    //console.log('Base Form Field Change', event);
  }

  //
  // Button Events
  //

  // Add button
  public addBtnEvent() {
    //alert(this.view.extent.xmin + ',' + this.view.extent.ymin + ',' + this.view.extent.xmax + ',' + this.view.extent.ymax + ',' + this.view.extent.spatialReference.wkid);
    //console.log(this.view.extent.xmin + ',' + this.view.extent.ymin + ',' + this.view.extent.xmax + ',' + this.view.extent.ymax + ',' + this.view.extent.spatialReference.wkid);
    //alert(this.view.extent.center.x + ',' + this.view.extent.center.y + ',' + this.view.extent.center.longitude + ',' + this.view.extent.center.latitude );
    //this.setCurrentMode('add');
    let currentModeData: CurrentModeData = {
      formName: this.formName,
      mode: 'add'
    }
    this.setCurrentMode(currentModeData);
    //this.currentModeService.currentModeData.next('add');
  }

  // Edit button
  private editBtnEvent() {
    //this.setCurrentMode('edit-attribute');
    let currentModeData: CurrentModeData = {
      formName: this.formName,
      mode: 'edit-attribute'
    }
    this.setCurrentMode(currentModeData);
  }

  // // Save button
  // private saveBtnEvent(event) {

  //   console.log('event.result', event.result);
  //   console.log(event.lastObjectId);

  //   if (event.result === 'SUCCESS') {
  //     this._lastObjectId = event.lastObjectId;

  //     this.lastrecordId = event.recordId;

  //     this.currentModeService.currentModeData.next('form-submitted');
  //     //this.setCurrentMode('form-submitted');
  //   }
  //   else {
  //     this._lastObjectId = event.lastObjectId;
  //     this.currentModeService.currentModeData.next('form-submitted-error');
  //   }
  // }

  // Add / edit attachments button

  private attachmentBtnEvent() {



    // if (this._attachmentInfos) {
    //   this._attachmentInfos.forEach (item =>  {
    //     //console.log(item.url);
    //     this._galleryImage =
    //     {
    //       small: item.url,
    //       medium: item.url,
    //       big: item.url,
    //       description: item.name
    //     };
    //     this._galleryImages.push(this._galleryImage);
    //   });
    // }




    // // Query Feature service to get attachments
    // if (this.selectedFeature) {

    //   let attachmentQuery = {
    //     objectIds: this.selectedFeature.attributes.OBJECTID,
    //     attachmentTypes: ['image/jpeg']
    //   };

    //   //this.featureLayer.queryAttachments(attachmentQuery).then(attachments => {
    //   this.selectedFeature.layer.queryAttachments(attachmentQuery).then(attachments => {
    //     if (attachments[this.selectedFeature.attributes.OBJECTID]) {
    //       this._attachmentInfos = attachments[this.selectedFeature.attributes.OBJECTID];
    //       this._attachmentInfos.forEach (item =>  {
    //         //console.log(item.url);
    //         this._galleryImage =
    //         {
    //           small: item.url,
    //           medium: item.url,
    //           big: item.url,
    //           description: item.name
    //         };
    //         this._galleryImages.push(this._galleryImage);
    //       });

    //       console.log('Number of attachments found:', this._galleryImages.length.toString());

    //       // Send attachment info to parent component
    //       this.attachmentInfosChange.emit(this._attachmentInfos);
    //     }
    //     else {
    //       console.log('No attachments');
    //       this.galleryReadyEvent.emit(true);
    //     }
    //   }); 
    // }



    //this.setCurrentMode('edit-attachment');
    let currentModeData: CurrentModeData = {
      formName: this.formName,
      mode: 'edit-attachment'
    }
    this.setCurrentMode(currentModeData);
  }

  // Cancel button - Clear the selected feature | cancel esit attribute | cancel edit attachment
  private cancelBtnEvent() {

    console.log('AuditComponent cancelBtnEvent');

    // switch (this._currentMode) {
    //   case 'editAttribute':
    //   case 'location-changed':
    //   case 'edit-popup':
    //     this.setCurrentMode('cancelEditAttribute');
    //     break;

    //   case 'editAttachment':
    //     this.setCurrentMode('cancelEditAttachment');
    //     break;

    //   case 'add':
    //   case 'add-popup':
    //     this.setCurrentMode('cancelAdd');
    //     break;

    //   default:
    //     this.setCurrentMode('clear');
    //     break;
    // }

    ///this.esriEditFormComponent.clearEditForm(); 
  }

  // Delete button
  private deleteBtnEvent() {
    //this.setCurrentMode('delete');
    let currentModeData: CurrentModeData = {
      formName: this.formName,
      mode: 'delete'
    }
    this.setCurrentMode(currentModeData);
  }

  // Report button
  private reportBtnEvent() {
    this.openReportDialog();
  }

  //
  // Feature Selection Functions
  //

  //protected selectFeature(feature: any, emitTableRowSelectEvent: boolean, geometry?: any) {
  protected selectFeature(feature: any, origin: string) {
    console.log('selectFeature, feature, origin', feature, origin);
    //this.setCurrentMode('select');

    let currentModeData: CurrentModeData = {
      formName: this.formName,
      mode: 'select'
    }
    this.setCurrentMode(currentModeData);

    // find the feature in this._features
    let index = -1;

    //_filteredFeatures  features

    this.featureLayerObject.features.find(function (item, i) {
      if (item.attributes.OBJECTID === feature.OBJECTID) {
        index = i;
        return i;
      }
    });

    // Store the selected feature
    this.selectedFeature = this.featureLayerObject.features[index];
    console.log('selectFeature this.selectedFeature', this.selectedFeature);

    // Highlight the feature in the table
    // Only select the feature in the table if the event resulted from clicking a feature in the map
    if (origin === 'MAP') {

      if (this.showDataTable === true) {
        this.esriViewTableComponent.tableRowSelected(this.selectedFeature.attributes, 'MAP');
      }

      if (this.showDataCards === true) {
        this.esriMapComponent.esriViewCardsComponent.cardSelected(this.selectedFeature.attributes, 'MAP');
      }
    }
    else if (origin === 'TABLE') {
      if (this.showMasterDetailForm === true) {
        //this.createMasterDetailForm('');
      }
    }

    // Highlight and goto the feature on the map
    this.highlightFeature();

    // Query any attachments
    this.queryAttachments();

    //
    //this.moveFeatureEnabled = true;
  }

  protected unSelectFeature() {
    console.log('BaseFeaturePage unSelectFeature', this.selectedFeature);

    if (this.selectedFeature) {
      // Remove selected feature from store
      this.selectedFeature = null;

      // Remove Highlight the feature in the table
      //this.esriViewTableComponent.tableRowSelected(null, emitTableRowSelectEvent);
      if (this.showDataTable === true) {
        this.esriViewTableComponent.tableRowSelected(null, "UNSELECT");
      }

      if (this.showDataCards === true) {
        this.esriMapComponent.esriViewCardsComponent.cardSelected(null, "UNSELECT");
      }

      // Remove Highlight on the map	  
      this.unHighlightFeature();

      // Close Popup
      this.view.popup.visible = false;

      // Close the Image Gallery
      this.galleryLoaded = false;
      this.esriAttachmentComponent.attachmentInfos = null;
    }
    //
    //this.moveFeatureEnabled = false;
  }

  // Highlights the feature
  private highlightFeature() {
    //console.log('highlightFeature');

    // Remove previous highlight if there is one
    this.unHighlightFeature();

    // highlight the feature on the view
    this._highlight = this._layerView.highlight(this.selectedFeature);

    if (this._preventZoomPan === false) {
      if (this.esriMapComponent.zoomToFeature === true) {
        // Pan and Zoom to feature
        this.view.goTo({
          target: this.selectedFeature,
          zoom: this._selectZoomLevel //this._mapZoomLevel
        })
          .catch(e => {
            console.error(e);
          });
      }
      else {
        if (this.esriMapComponent.panToFeature === true) {
          // Pan to feature
          this.view.goTo({
            target: this.selectedFeature,
          })
            .catch(e => {
              console.error(e);
            });
        }
        else {
          // Do nothing
        }
      }
    }
    else {
      // Do nothing - ignore zoom (otherwise right click menu disappears)
    }

    // Reset
    this._preventZoomPan = false;

  }

  // Un-highlight a feature if it is Highlighted
  private unHighlightFeature() {
    if (this._highlight) {
      this._highlight.remove();
      this._highlight = null;
    }
  }

  //
  // Dialogs, menus, and popups
  //

  protected createPopup(event: any, popupType: string) {
    console.log('base createPopup', event, popupType);

    switch (popupType.toUpperCase()) {
      case 'MENU':

        this.popupMenuEl.nativeElement.classList.remove(
          'popup-menu-height-1',
          'popup-menu-height-2',
          'popup-menu-height-3',
          'popup-menu-height-4',
          'popup-menu-height-5',
          'popup-menu-height-6'
        );

        let popupHeightClass;
        let popupWidthClass = 'popup-width-' + this.popupMenuWidth;

        if (this.selectedFeature) {
          popupHeightClass = 'popup-menu-height-' + this.popupMenuNumberItems_selectedFeature;
        }
        else {
          popupHeightClass = 'popup-menu-height-' + this.popupMenuNumberItems;
        }

        this.popupMenuEl.nativeElement.classList.add('popup-menu', popupHeightClass, popupWidthClass);

        //this.geometry = event.mapPoint;
        this.view.popup.dockEnabled = false;
        //this.dataPopupVisible = false;
        //this.menuPopupVisible = true;
        this.view.popup.title = null;
        this.view.popup.location = event.mapPoint;
        this.view.popup.content = this.popupMenuEl.nativeElement;
        this.view.popup.visible = true;
        break;

      case 'DATA':

        //if (this.showDataPopup === true) {
        this.esriEditFormComponent.initialiseEditForm().then(() => {
          this.popupDataEl.nativeElement.classList.add('popup-menu', 'popup-height-700', 'popup-width-300', 'popup-padding-nil-right');   // , 'popup-padding-nil-right'

          this.view.popup.dockOptions.position = 'top-left';
          this.view.popup.dockEnabled = true;
          //this.dataPopupVisible = true;
          //this.menuPopupVisible = false;
          this.featureLayerObject.featureLayer.popupTemplate = {
            title: 'Selected item info...',
            location: event.mapPoint,
            content: this.popupDataEl.nativeElement
          }
          this.view.popup.visible = true;
        });
        //}

        break;

      default:
        this.view.popup.content = '';
        break;
    }
  }

  protected openEditFeatureDialog(mode: string, markDirtyWhenOpened?: boolean) {

    let dialogTitle = '';

    // if (dialogTitle) {
    //   _dialogTitle = dialogTitle;
    // }
    // else {
    if ((mode.toUpperCase().indexOf('ADD') > 0) || (mode.toUpperCase().indexOf('SELECTED') > 0)) {
      dialogTitle = 'Create a new item';
    }
    else {
      dialogTitle = 'Edit an item';
    }
    //}

    // Exit fullscreen map mode if in it (otherwise, won't see the dialog)
    this.esriMapComponent.exitFullscreen();

    // TO DO: **** Set max/min width based on screen size ****
    let minWidth = '50%';
    let maxWidth = '50%';

    this.formDialogRef = this.dialog.open(EditComponent, {
      disableClose: true,
      minWidth: minWidth,
      maxWidth: maxWidth,
      data: {
        title: dialogTitle,
        //formFieldConfigs: this.formFieldConfigs,
        formFieldConfigs: this.featureLayerObject.formFieldConfigs,
        featureLayer: this.featureLayerObject.featureLayer,
        feature: this.selectedFeature,
        geometry: this.geometry,
        domains: this.featureLayerObject.domains,
        domains_lov: this.featureLayerObject.domains_lov,
        domains_lovSubDomain: this.featureLayerObject.domains_lovSubDomain,
        useFormStepper: false,
        currentMode: mode,
        fieldChangeWatchList: this.fieldChangeWatchList,
        parentLovWatchList: this.parentLovWatchList,
        defaultFieldValues: this.defaultFieldValues,
        defaultFieldVisibility: this.defaultFieldVisibility,
        markDirtyWhenOpened: markDirtyWhenOpened,
        idType: this.idType,
        idTypeFieldName: this.idTypeFieldName,
        formName: this.formName,
      }
    });

    this.formDialogRef.componentInstance.createEditForm(); //.then( () => {
    //console.log('EditComponent formDialogRef', this.esriEditFormComponent.featureForm);
    //});


    // Listen for button clicks

    // Subscribe to user workflow actions
    // this.formDialogRef.buttonClickService.buttonClickData.pipe(takeUntil(this.ngUnsubscribe)).subscribe((response: any) => {
    //   console.log('formDialogRef BaseFeaturePage buttonClickService response', response); 
    // });


    // *********** TO DO - convert to use "buttonClickService" **************

    // // Subscribe to user workflow actions
    // this.buttonClickService.buttonClickData.pipe(takeUntil(this.ngUnsubscribe)).subscribe((response: any) => {

    //   console.log('BaseFeaturePage openEditFeatureDialog() buttonClickService response', response);

    //   // switch (response.button.toUpperCase()) {
    //   //   case 'SAVE':
    //   //     this.saveBtnEvent(response.event);
    //   //     break;

    //   //   default:
    //   //     console.log('BaseFeaturePage buttonClickService - no handler defined for event', response);
    //   //     break;
    //   // }

    // });



    // this.formDialogRef.componentInstance.addBtnSelectedEvent.pipe(takeUntil(this.ngUnsubscribe)).subscribe((event) => {
    //   this.addBtnEvent();
    // });

    // this.formDialogRef.componentInstance.editBtnSelectedEvent.pipe(takeUntil(this.ngUnsubscribe)).subscribe((event) => {
    //   this.editBtnEvent();
    // });

    // this.formDialogRef.componentInstance.saveBtnSelectedEvent.pipe(takeUntil(this.ngUnsubscribe)).subscribe((event) => {
    //   this.saveBtnEvent(event);
    // });

    // this.formDialogRef.componentInstance.attachmentBtnSelectedEvent.pipe(takeUntil(this.ngUnsubscribe)).subscribe((event) => {
    //   this.attachmentBtnEvent();
    // });

    // this.formDialogRef.componentInstance.deleteBtnSelectedEvent.pipe(takeUntil(this.ngUnsubscribe)).subscribe((event) => {
    //   this.deleteBtnEvent();
    // });

    // this.formDialogRef.componentInstance.cancelBtnSelectedEvent.pipe(takeUntil(this.ngUnsubscribe)).subscribe((event) => {
    //   this.cancelBtnEvent();
    // });



  }

  public openReportDialog(dialogTitle?: string) {
    //console.log('base openReportDialog');

    let _dialogTitle = 'Reports';

    if (dialogTitle) {
      _dialogTitle = dialogTitle;
    }

    // Exit fullscreen map mode if in it (otherwise, won't see the dialog)
    this.esriMapComponent.exitFullscreen();

    // TO DO: **** Set max/min width based on screen size ****
    let minWidth = '35%';
    let maxWidth = '60%';

    this._reportDialogRef = this.dialog.open(ReportComponent, {
      disableClose: true,
      minWidth: minWidth,
      maxWidth: maxWidth,
      data: {
        title: _dialogTitle,
        reports: this.reportDefinitions
      }
    });

    // Listen for button clicks

    this._reportDialogRef.componentInstance.runReportBtnSelectedEvent.subscribe((event) => {
      //console.log('runReportBtnSelectedEvent', event);
      this.reportBtnEvent();
    });

    this.dialog.afterAllClosed.subscribe((event) => {
      // Clean up
      this._reportDialogRef.componentInstance.runReportBtnSelectedEvent.unsubscribe();
    });
  }

  private populateViewForm() {
    console.log('populateViewForm', this.featureLayerObject.domains);
    //this.esriEditFormComponent.formFieldConfigs = this.formFieldConfigs;
    this.esriEditFormComponent.formFieldConfigs = this.featureLayerObject.formFieldConfigs;
    this.esriEditFormComponent.featureLayer = this.featureLayerObject.featureLayer;
    this.esriEditFormComponent.feature = this.selectedFeature;
    this.esriEditFormComponent.domains = this.featureLayerObject.domains;
    this.esriEditFormComponent.domains_lov = this.featureLayerObject.domains_lov;
    this.esriEditFormComponent.domains_lovSubDomain = this.featureLayerObject.domains_lovSubDomain;
    this.esriEditFormComponent.useFormStepper = false;
  }

  //
  // Responsive Actions
  //

  private setResponsiveLayout() {
    // Get current window size
    let width = window.innerWidth;
    let height: number = 0;

    this.chartWidth = width - 80;
    this.chartWidth_2 = Math.floor(this.chartWidth / 2);
    this.chartWidth_3 = Math.floor(this.chartWidth / 3);
    this.chartWidth_4 = Math.floor(this.chartWidth / 4);
    this.chartWidth_5 = Math.floor(this.chartWidth / 5);
    this.chartWidth_6 = Math.floor(this.chartWidth / 6);
    this.chartWidth_7 = Math.floor(this.chartWidth / 7);
    this.chartWidth_8 = Math.floor(this.chartWidth / 8);

    /*
         1 <= xs <= 599
       600 <= sm <= 959
       960 <= md <= 1279
      1280 <= lg <= 1919
      1920 <= xl <= 5000
    */

    if (width <= 599) {
      this.responsiveWidthBreakpointEvent.emit("xs");
    }
    else if (width <= 959) {
      this.responsiveWidthBreakpointEvent.emit("sm");
    }
    else if (width <= 1279) {
      this.responsiveWidthBreakpointEvent.emit("md");
    }
    else if (width <= 1919) {
      this.responsiveWidthBreakpointEvent.emit("lg");
    }
    else {
      this.responsiveWidthBreakpointEvent.emit("xl");
    }
  }

  //
  // Address / Geocode
  //

  // Address selected from autocomplete in search **** NOT SURE IF I NEED THIS YET ***** - might be able to use the other one from above
  public addressSelected(event: any) {

    //console.log('addressSelectedEvent_Search returned is ', event);

    // Store the address
    this.selectedAddress = new Address(event.candidates[0].attributes.StAddr, event.candidates[0].attributes.Nbrhd, event.candidates[0].attributes.Region, event.candidates[0].attributes.Postal, this.country);

    // Create the geometry
    this.geometry = new GeometryPoint(
      event.candidates[0].location.x,
      event.candidates[0].location.y
    );

    if (this.graphicsLayer) {
      this.view.map.remove(this.graphicsLayer);
    }

    this.addGraphic(this.geometry).then(() => {
      this.view.goTo({
        center: [this.geometry.longitude, this.geometry.latitude],
        zoom: this._selectZoomLevel
      });
    });
  }

  public addressCleared() {
    //console.log('addressClearedEvent_Search');
    // Clear stored address and remove graphic
    this.selectedAddress = null;
    this.geometry = null;
    this.removeGraphic();
    this.esriMapComponent.addressSelectBtnDisabled = true;
  }

  // Load as a Tile Service (course and ssurrounds)
  public async loadBaseLayers_Tiles(is3D: boolean, includeAllSurrounds?: boolean, includeHillshade?: boolean, includeNswProperty?: boolean): Promise<any> {

    // Retrieve and load the Course feature layers from the ArcGIS server(s)

    // Course layers
    this.courseService.loadCourseLayers_Tiles(is3D, this.courseLayerOpacity).then(layers => {
      this.addLayerToMap(layers);
    })
      .catch(function (error) {
        console.log("Loading Course Tile Layers - Error details: ", error.details);
      });

    // Surrounding layers
    this.surroundsService.loadSurroundsLayers_Tiles(is3D, this.surroundsLayerOpacity, includeAllSurrounds).then(layers => {
      this.addLayerToMap(layers);
    })
      .catch(function (error) {
        console.log("Loading Surrounds Tile Layers - Error details: ", error.details);
      });

    // Course Boundary
    this.surroundsService.loadCourseBoundaryLayer(is3D, this.boundaryLayerOpacity).then(layer => {
      this.addLayerToMap(layer);
      // Used for spatial query to determine if feature is inside or outside the course boundary
      this.boundaryLayer = layer;
    })
      .catch(function (error) {
        console.log("Loading Course Boundary Layer - Error details: ", error.details);
      });

    // Get the hillshade too if it is requested
    if (includeHillshade === true) {
      this.surroundsService.loadHillshade(is3D, this.hillshadeLayerOpacity).then(layer => {
        this.addLayerToMap(layer);
      })
        .catch(function (error) {
          console.log("Loading Hillshade Layers - Error details: ", error.details);
        });
    }

    // Get the NSW property boundaries
    if (includeNswProperty === true) {
      this.surroundsService.loadNswProperty(is3D, this.nswPropertyLayerOpacity).then(layer => {
        this.addLayerToMap(layer);
      })
        .catch(function (error) {
          console.log("Loading NSW Property Layers - Error details: ", error.details);
        });
    }

    // Used for spatial query to determine fairway features are in
    this.courseService.loadFairwayLayer(is3D).then(layer => {
      this.fairwayLayer = layer;
    })
      .catch(function (error) {
        console.log("Loading Fairway Layer - Error details: ", error.details);
      });

    return;
  }

  public async loadBoundaryLayersOnly(is3D: boolean): Promise<any> {

    // Course Boundary
    this.surroundsService.loadCourseBoundaryLayer(is3D, 0.5).then(layer => {
      //this.addLayerToMap(layer);
      // Used for spatial query to determine if feature is inside or outside the course boundary
      this.boundaryLayer = layer;
    })
      .catch(function (error) {
        console.log("Loading Course Boundary Layer - Error details: ", error.details);
      });

    // Used for spatial query to determine fairway features are in
    this.courseService.loadFairwayLayer(is3D).then(layer => {
      this.fairwayLayer = layer;
    })
      .catch(function (error) {
        console.log("Loading Fairway Layer - Error details: ", error.details);
      });

    return;
  }

  public async loadBaseAssetLayers(is3D: boolean): Promise<any> {


    this.courseService.loadBaseAssetLayers(is3D, this.courseLayerOpacity).then(layers => {
      console.log('Loading Base Asset Layers, layers', layers);
      this.addLayerToMap(layers);
    })
    .catch(function (error) {
      console.log("Loading BaseAsset Layers - Error details: ", error.details);
    });


  }

  // Load as Feature Services
  public async loadBaseLayers(is3D: boolean, includeAllSurrounds?: boolean, includeHillshade?: boolean, includeNswProperty?: boolean): Promise<any> {
    let featureLayers: any[] = [];
    // The course layers
    console.log('Loading Base Layers');
    //let featureLayerProperties_Course = Course.createLayerPropertiesList(is3D, 0.6);

    // Surrounding layers
    await this.surroundsService.loadSurroundsLayers(is3D, includeAllSurrounds).then(layers => {
      //console.log('Loading Surrounds Layers');
      //console.log(layers);
      featureLayers.push(layers);
    })
      .catch(function (error) {
        console.log("Loading Surrounds Layers - Error details: ", error.details);
      });

    // Retrieve and load the Course feature layers from the ArcGIS server(s)
    await this.courseService.loadCourseLayers(is3D).then(layers => {
      console.log('Loading Course Layers');
      featureLayers.push(layers);
    })
    .catch(function (error) {
      console.log("Loading Course Layers - Error details: ", error.details);
    });

    // Get the hillshade too if it is requested
    if (includeHillshade === true) {
      await this.surroundsService.loadHillshade(is3D).then(layers => {
        //console.log('load Hillshade Layers');
        //console.log(layers);
        featureLayers.push(layers);
      })
      .catch(function (error) {
        console.log("Loading Hillshade Layers - Error details: ", error.details);
      });
    }

    // Get the NSW property boundaries
    if (includeNswProperty === true) {
      await this.surroundsService.loadNswProperty(is3D).then(layers => {
        //console.log('load NSW Property Layers');
        //console.log(layers);
        featureLayers.push(layers);
      })
        .catch(function (error) {
          console.log("Loading NSW Property Layers - Error details: ", error.details);
        });
    }

    //console.log('return');
    //console.log(featureLayers);
    // By returning a clone "..." (I think) - it might eliminate the stack size error I was getting - time will tell
    return [...featureLayers];
  }

  // Add map layer(s) to view
  private addLayerToMap(layers) {
    if (layers.length > 0) {
      this.view.map.addMany(layers);
    }
    else {
      this.view.map.add(layers);
    }
  }


  //
  // Link to Maintenance
  //

  protected createWorkRequestBtnEvent() {
    console.log('****** !!! createWorkRequestBtnEvent() !!! ******');
    this.createWorkRequest();
  };

  protected async createWorkRequest(): Promise<any> { }

  protected openEditFeatureDialog_WorkRequest(dialogTitle: string, createWorkRequestService: any, formName: string) {
    // Exit fullscreen map mode if in it (otherwise, won't see the dialog)
    this.esriMapComponent.exitFullscreen();

    // TO DO: **** Set max/min width based on screen size ****
    let minWidth = '50%';
    let maxWidth = '50%';

    //let fieldChangeWatchList_WorkRequest = ['Status', 'CompletedDate', 'WorkRequestSubCategoryId'];
    //let parentLovWatchList_WorkRequest = [{ parentLovKey: 'WorkRequestCategoryId', childLovName: 'LovWorkRequestSubCategory', childLovKey: 'WorkRequestSubCategoryId' }];
    let fieldChangeWatchList_WorkRequest = createWorkRequestService.workRequest.fieldChangeWatchList;
    let parentLovWatchList_WorkRequest = createWorkRequestService.workRequest.parentLovWatchList;
    let defaultFieldValues_WorkRequest = [];
    let defaultFieldVisibility_WorkRequest = [];

    // Assign the geometry - Work Requests are MultiPoint
    console.log('this.selectedFeature.geometry', this.selectedFeature, this.selectedFeature.geometry)
    const longitude = this.selectedFeature.geometry.longitude;
    const latitude = this.selectedFeature.geometry.latitude;

    let geometry = new GeometryMultiPoint();
    geometry.points = [];
    geometry.points.push([longitude, latitude]);

    // Get the Incident Parent key fields
    defaultFieldValues_WorkRequest = [
      {
        name: 'WorkRequestDate',
        value: this.workRequestAttributes.WorkRequestDate
      },
      {
        name: 'IncidentId',
        value: this.workRequestAttributes.IncidentId
      },
      {
        name: 'AuditId',
        value: this.workRequestAttributes.AuditId
      },
      {
        name: 'FairwayNumber',
        value: this.workRequestAttributes.FairwayNumber
      },
      {
        name: 'WorkRequestDetails',
        value: this.workRequestAttributes.WorkRequestDetails
      },
      {
        name: 'Comments',
        value: this.workRequestAttributes.Comments
      },
      {
        name: 'WorkRequestCategoryId',
        value: this.workRequestAttributes.WorkRequestCategoryId
      },
      {
        name: 'WorkRequestSubCategoryId',
        value: this.workRequestAttributes.WorkRequestSubCategoryId
      },
      {
        name: 'Source',
        value: this.workRequestAttributes.Source
      },
      {
        name: 'Status',
        value: this.workRequestAttributes.Status
      },
      {
        name: 'Priority',
        value: this.workRequestAttributes.Priority
      },
      {
        name: 'ReportedBy',
        value: this.workRequestAttributes.ReportedBy
      },
      {
        name: 'Responsible',
        value: this.workRequestAttributes.Responsible
      },
      {
        name: 'AssignedTo',
        value: this.workRequestAttributes.AssignedTo
      },
      {
        name: 'SLADays',
        value: this.workRequestAttributes.SLADays
      },
      {
        name: 'SLADueDate',
        value: this.workRequestAttributes.SLADueDate
      },
    ];

    this.formDialogRef = this.dialog.open(EditComponent, {
      disableClose: true,
      minWidth: minWidth,
      maxWidth: maxWidth,
      data: {
        title: dialogTitle,
        //formFieldConfigs: this.featureLayerObject_WorkRequest.formFieldConfigs,
        formFieldConfigs: createWorkRequestService.workRequest.formFieldConfigs,
        featureLayer: createWorkRequestService.workRequest.featureLayer,
        feature: null,
        geometry: geometry,
        domains: createWorkRequestService.workRequest.domains,
        domains_lov: createWorkRequestService.workRequest.domains_lov,
        domains_lovSubDomain: createWorkRequestService.workRequest.domains_lovSubDomain,
        useFormStepper: false,
        // currentMode: mode_Contact,
        fieldChangeWatchList: fieldChangeWatchList_WorkRequest,
        parentLovWatchList: parentLovWatchList_WorkRequest,
        defaultFieldValues: defaultFieldValues_WorkRequest,
        defaultFieldVisibility: defaultFieldVisibility_WorkRequest,
        // markDirtyWhenOpened: markDirtyWhenOpened_Contact,
        idType: createWorkRequestService.workRequest.idType,
        idTypeFieldName: createWorkRequestService.workRequest.idTypeFieldName,
        formName
      }
    });

    this.formDialogRef.componentInstance.createEditForm();
  }

  //
  // Link to Projects
  //

  protected createProjectBtnEvent() { };

  //
  // Chart Functions
  //

  public getChartData(chartName) {
    return this.dashboardCharts.find(x => x.chartName == chartName).summaryData;
  }

  public combineChartData(chartNames: string[]) {
    // not sure if this works yet
    let summaryData = [];
    let chartName = 'STATS';
    let chartData: any = {};

    chartNames.forEach((chart) => {
      summaryData.push(this.dashboardCharts.find(x => x.chartName == chart).summaryData);
      //console.log('chart', this.dashboardCharts.find(x => x.chartName == chart).summaryData);
    });

    chartData.chartName = chartName;
    chartData.summaryData = summaryData;

    return chartData;
  }

  public selectChart(event: any, chartName: string, domainName: string, keyField: string, includeOther: boolean, keyFieldIsNumeric?: boolean) {
    console.log('selectChart()', event, chartName, domainName, keyField, includeOther, keyFieldIsNumeric);

    let otherClause: string = '';
    let _keyFieldIsNumeric: boolean = false;

    if (keyFieldIsNumeric) {
      _keyFieldIsNumeric = keyFieldIsNumeric;
    }

    if (includeOther === true) {
      otherClause = keyField + " IS NULL OR " + keyField + " = 'Other'";
    }
    else {
      otherClause = keyField + " IS NULL";
    }

    let chart = this.dashboardCharts.find(x => x.chartName == chartName);

    //console.log('selectChart() chart', chart);
    chart.clause = this.createSelectClause(domainName, keyField, event.name, otherClause, _keyFieldIsNumeric);

    //console.log('selectChart() chart.clause', chart.clause);

    if (domainName === 'DomYesNo') {
      chart.label = keyField + ': ' + event.name;
    }
    else if (event.name === 'Not set') {
      chart.label = keyField + ': ' + event.name;
    }
    else {
      chart.label = event.name;
    }

    chart.visibleChip = true;

    this.queryChoiceSelectedEvent();
  }

  public selectChart_SubDomain(event: any, chartName: string) {
    //console.log(event);
    let chart = this.dashboardCharts.find(x => x.chartName == chartName);
    chart.clause = event.extra.parentFieldName + " = '" + event.extra.parentFieldValue + "' AND " + event.extra.childFieldName + " = '" + event.extra.childFieldValue + "'";
    chart.label = event.name;
    chart.visibleChip = true;
    this.queryChoiceSelectedEvent();
  }

  protected removeChip(chartName: string) {
    let chart = this.dashboardCharts.find(x => x.chartName.toUpperCase() == chartName.toUpperCase());

    // Remove the chip from the displayed filter list
    if (chart) {
      chart.visibleChip = false;
    }

    // Re-query
    this.queryChoiceSelectedEvent();
  }

  // Chart Data Functions

  protected createChartDataSet(domainName: string, keyField: string, includeOther: boolean) {
    let keys = [];
    let runningCount = 0;
    let summaryData = [];
    const domains = [...this.featureLayerObject.domains, ...this.featureLayerObject.domains_lov];

    domains.filter(x => x.name === domainName)[0].codedValues.forEach(key => {
      keys.push(key);
    });

    // Count the number of features for each key value and add to the summary data
    keys.forEach(key => {
      let count = this.featureLayerObject.features.filter(x => x.attributes[keyField] === key.code).length;  // this.features.filter this._filteredFeatures.filter
      runningCount += count;

      let data = {
        name: key.name,
        value: count
      }
      summaryData.push(data);
    });

    // Include an "Other" option in the dataset
    // Get any remaining values that do not have a key value assigned to them, eg nulls
    if (includeOther === true) {
      let value = 0;

      // If the running count is less than the feature count, then some records haven't been included in the summary data
      if (runningCount < this.featureLayerObject.features.length) {
        value = this.featureLayerObject.features.length - runningCount;    // this.features.length this._filteredFeatures.length

        // Group them together as 'Other'
        let data = {
          name: 'Not set',
          value: this.featureLayerObject.features.length - runningCount  // this.features.length this._filteredFeatures.length
        }

        summaryData.push(data);
      }
    }

    //console.log('summaryData', summaryData);

    return summaryData;
  }

  protected createChartDataSet_SubCategory(dataArray: any, categoryIdFieldName: string, categoryFieldName: string, subCategoryIdFieldName: string, subCategoryFieldName: string) {
    //let runningCount = 0;
    let summaryData = [];
    let series: any[] = [];
    let sort = 'ASC'

    const categories = this.featureLayerObject.distinct(categoryFieldName);
    //console.log('categories', categories);

    for (let category of categories) {
      // Reset
      series = [];

      // Count the number of features for each key value and add to the summary data
      const valuesToGroupBy = dataArray.filter(x => x.Category === category);
      const categoryId = valuesToGroupBy[0][categoryIdFieldName];
      const subCategories = this.featureLayerObject.distinct(subCategoryFieldName, categoryFieldName, category);
      //console.log('subCategories', subCategories);

      subCategories.forEach(subCategory => {
        let group = valuesToGroupBy.filter(x => x.Category === category && x.SubCategory === subCategory);
        const subCategoryId = group[0][subCategoryIdFieldName];
        let count = group.length;
        //runningCount += count;

        let data = {
          name: subCategory,
          value: count,
          extra: {
            parentFieldName: categoryIdFieldName,
            parentFieldValue: categoryId,
            childFieldName: subCategoryIdFieldName,
            childFieldValue: subCategoryId
          }
        }
        series.push(data);
      });

      let data_s = {
        name: category,
        series
      }

      summaryData.push(data_s);
    }

    let sortField = 'name';

    if (sort === 'DESC') {
      Global.sortArrayDesc(summaryData, sortField);
    }
    else {
      Global.sortArrayAsc(summaryData, sortField);
    }

    //console.log('summaryData', summaryData);
    return summaryData;
  }

  protected createChartDataSet_Count(dataArray: any, field: string, sort?: string, sortField?: string) {


    //console.log('this.createChartDataSet_GetUniqueValueCount() dataArray', dataArray);

    let valuesToSummarise = Array.from(new Set(dataArray.map((item: any) => item[field])));
    //console.log('this.createChartDataSet_GetUniqueValueCount() valuesToSummarise', valuesToSummarise);

    // sort = ASC or DESC
    // sortField = name or value
    let summaryData = [];
    let idx = 0;

    valuesToSummarise.forEach(name => {
      // Count the number of features for the key value and add to the summary data
      let count = dataArray.filter(x => x[field] === name).length;
      //console.log('count', count);

      let data = {
        name,
        value: count
      }

      summaryData.push(data);
      idx++
    });

    if (!sortField) {
      sortField = 'name';
    }

    if (sort === 'DESC') {
      Global.sortArrayDesc(summaryData, sortField);
    }
    else {
      Global.sortArrayAsc(summaryData, sortField);
    }

    return summaryData;
  }

  protected createChartDataSet_CountMany(fields: string[], valueToSummarise: string[], labels?: string[]) {
    // This fn will count records that match the value for the nominated fields
    // The fields and valueToSummarise should be 1:1 and in the matching order

    let summaryData = [];
    let idx = 0;

    fields.forEach(keyField => {
      // Count the number of features for the key value and add to the summary data
      let count = this.featureLayerObject.features.filter(x => x.attributes[keyField] === valueToSummarise[idx]).length; // this.features.filter this._filteredFeatures.filter
      let name = keyField;

      if (labels) {
        name = labels[idx];
      }

      let data = {
        name,
        value: count
      }

      summaryData.push(data);
      idx++
    });

    return summaryData;
  }

  protected createChartDataSet_SumMany(dataArray: any[], fields: string[], labels: string[]) {
    let summaryData = [];
    let idx = 0;

    fields.forEach(keyField => {
      let sum = 0;

      // for (let i = 0; i < this.featureLayerObject.features.length; i++) {    // this.features.length this._filteredFeatures.length
      //   sum += this.featureLayerObject.features[i].attributes[keyField];
      //   //console.log('createChartDataSet_SumFieldValues sum', this.features[i], sum);
      // }

      for (let i = 0; i < dataArray.length; i++) {    // this.features.length this._filteredFeatures.length
        sum += dataArray[i][keyField];
      }

      let data = {
        name: labels[idx],
        value: sum,
        extra: {
          code: keyField
        }
      }

      summaryData.push(data);
      idx++
    })

    return summaryData;
  }

  protected createChartDataSet_Overdue(dateField: string, dateComparisonField: string, label: string, whereField?: string, whereIsValue?: any) {
    //console.log('now', dateField, whereField, whereIsValue);
    let summaryData = [];
    let count = 0;
    let filteredFeatures;

    if (whereField) {
      // ?? have an "operator" value??? ie: <, =, >, null, etc
      filteredFeatures = this.featureLayerObject.features.filter(x => x.attributes[whereField] === whereIsValue);
    }
    else {
      filteredFeatures = this.featureLayerObject.features;
    }

    if (dateComparisonField.toUpperCase() === 'NOW') {
      let today: Date = new Date; //Date.now;
      let now = getTime(today);
      count = filteredFeatures.filter(x => isBefore(x.attributes[dateField], now)).length;
    }
    else {
      count = filteredFeatures.filter(x => isBefore(x.attributes[dateField], x.attributes[dateComparisonField])).length;
    }

    // let today: Date = new Date; //Date.now;
    // let now = getTime(today);
    // let count = this.features.filter(x => x.attributes[whereField] === whereIsValue).filter(x => isBefore(x.attributes[dateField], now)).length;
    //console.log('count', count);

    let data = {
      name: label,
      value: count
    }

    summaryData.push(data);

    return summaryData;
  }

  // groupByRange - should be a range of numbers, (eg {From: 2010, To: 2020} )
  protected createDataGroup_Date(dataArray: any[], groupField: string, groupByRange: GroupByRange[]): GroupArray[] {
    let groupArray: GroupArray[] = [];

    groupByRange.forEach(range => {
      const filtered = dataArray.filter(x => isWithinInterval(x[groupField], { start: range.From, end: range.To }));
      //console.log('createDataGroup_Date filtered', filtered);

      const group: GroupArray = {
        Label: range.Label,
        Group: filtered
      }

      groupArray.push(group);
    });

    //console.log('createDataGroup_Date groupArray', groupArray);
    return groupArray;
  }

  protected createDataGroup_BeforeDate(dataArray: any[], groupField: string, groupByRange: GroupByRange[], includeToEndOfDateRange: boolean): GroupArray[] {
    let groupArray: GroupArray[] = [];
    let filtered;


    groupByRange.forEach(range => {

      if (includeToEndOfDateRange === true) {
        // Include to the end of the date range
        filtered = dataArray.filter(x => isBefore(x[groupField], range.To));
      }
      else {
        // Only include records prior to the date range
        filtered = dataArray.filter(x => isBefore(x[groupField], range.From));
      }

      //console.log('createDataGroup_Date filtered', filtered);

      const group: GroupArray = {
        Label: range.Label,
        Group: filtered
      }

      groupArray.push(group);
    });

    //console.log('createDataGroup_Date groupArray', groupArray);
    return groupArray;
  }

  protected createChartDataSet_Average(dataArrayGroups: any[], field: string, dataSeries?: boolean, sort?: string) {
    let summaryData = [];
    let series: any[] = [];

    dataArrayGroups.forEach((group) => {
      //console.log('createChartDataSet_Average group.Group', group.Group);
      let sum: number = 0;
      let avg: any = 0;
      let count: number = 0;
      //const count = group.Group.length; // This would include null values, so count non null records instead
      //console.log('createChartDataSet_Average length', );

      for (let i = 0; i < group.Group.length; i++) {
        //console.log('createChartDataSet_Average group.Group[i][field]', group.Group[i][field]);
        if (group.Group[i][field]) {
          sum += group.Group[i][field];
          count++;
        }
      }
      //console.log('createChartDataSet_Average length, count, sum', group.Group.length, count, sum);

      // Calculate the average (rounded)
      //console.log('createChartDataSet_Average avg', sum / count);
      avg = (sum / count).toFixed(1);
      //console.log('createChartDataSet_Average avg', avg);

      let data = {
        name: group.Label,
        value: avg
      }

      // Line / Area Chart wants a Data Series
      if (dataSeries === true) {
        // Build the series data
        series.push(data);
      }
      else {
        summaryData.push(data);
      }
    });

    if (dataSeries === true) {
      // Add the series data and create the data set
      let data_s = {
        name: 'Average',
        series
      }
      summaryData.push(data_s);
    }

    let sortField = 'name';

    if (sort === 'DESC') {
      Global.sortArrayDesc(summaryData, sortField);
    }
    else {
      Global.sortArrayAsc(summaryData, sortField);
    }

    //console.log('createChartDataSet_Average summaryData', summaryData);
    return summaryData;
  }

  //(this._members, 'AgeFinancialYearEnd', 'Gender', 'ASC');
  protected createChartDataSet_Count_Split(dataArray: any[], field: string, splitByField: string, sort?: string) { // , sortField?: string

    let valuesToSummarise = Array.from(new Set(dataArray.map((item: any) => item[field])));
    let valuesToSplitBy = Array.from(new Set(dataArray.map((item: any) => item[splitByField])));
    //console.log('this.createChartDataSet_GetUniqueValueCount() valuesToSummarise', valuesToSummarise);

    // sort = ASC or DESC
    // sortField = name or value
    let summaryData = [];
    let idx = 0;

    //   {
    //     "name": "Germany",
    //     "series": [
    //       {
    //         "name": "2010",
    //         "value": 7300000
    //       },
    //       {
    //         "name": "2011",
    //         "value": 8940000
    //       }
    //     ]
    //   },

    let series: any[] = [];

    // Loop through the top level filter
    valuesToSummarise.forEach(name => {

      let split = dataArray.filter(x => x[field] === name);
      series = [];

      // Loop through the group / count by field
      valuesToSplitBy.forEach(splitBy => {

        // Count the number of features for the key value and add to the summary data
        let count = split.filter(x => x[splitByField] === splitBy).length;
        //console.log('group, field, groupBy, count', group, field, groupBy, count);

        let data = {
          name: splitBy,
          value: count
        }

        // Build the series data
        series.push(data);
      });

      // Add the series data and create the data set
      let data = {
        name,
        series
      }

      summaryData.push(data);
      idx++
    });

    // ************ TO DO ************
    // Not sure how to sort by value with a series values - 
    //if (!sortField) {
    let sortField = 'name';
    //}
    //************ TO DO ************

    if (sort === 'DESC') {
      Global.sortArrayDesc(summaryData, sortField);
    }
    else {
      Global.sortArrayAsc(summaryData, sortField);
    }

    return summaryData;
  }

  protected createChartDataSet_Count_Split_Grouped(dataArray: any[], field: string, fieldValueGroupByRange: GroupByRange[], splitByField: string, sort?: string, excludeNull?: boolean) { // , sortField?: string
    //let valuesToSummarise = Array.from(new Set(dataArray.map( (item: any) => item[field])));
    let valuesToSplitBy = Array.from(new Set(dataArray.map((item: any) => item[splitByField])));
    //console.log('this.createChartDataSet_GetUniqueValueCount_Split_Grouped() valuesToSplitBy', valuesToSplitBy);

    // sort = ASC or DESC
    // sortField = name or value
    let summaryData = [];
    let idx = 0;
    let series: any[] = [];

    for (let range of fieldValueGroupByRange) {
      let valuesToGroupBy = dataArray.filter((item: any) => item[field] >= range.From && item[field] <= range.To);
      //console.log('valuesToGroupBy', valuesToGroupBy);
      series = [];

      // Loop through the group / count by field
      valuesToSplitBy.forEach(splitBy => {
        if (excludeNull === true && splitBy === 'Unknown') {
          // Ignore Value
        }
        else {
          let split = valuesToGroupBy.filter(x => x[splitByField] === splitBy);
          //console.log('split', split);

          // Count the number of features for the key value and add to the summary data
          let count = split.filter(x => x[splitByField] === splitBy).length;
          //console.log('range, field, splitBy, count', range, field, splitBy, count);

          let data = {
            name: splitBy,
            value: count
          }
          // Build the series data
          series.push(data);
        }
      });

      // Add the series data and create the data set
      let data = {
        name: range.From + '-' + range.To,
        series
      }

      summaryData.push(data);
      idx++
    }

    // ************ TO DO ************
    // Not sure how to sort by value with a series values - 
    //if (!sortField) {
    let sortField = 'name';
    //}
    //************ TO DO ************

    if (sort === 'DESC') {
      Global.sortArrayDesc(summaryData, sortField);
    }
    else {
      Global.sortArrayAsc(summaryData, sortField);
    }

    return summaryData;
  }

  protected createChartDataSet_Count_GroupedRange(dataArray: any[], field: string, fieldValueGroupByRange: GroupByRange[], dataSeries: boolean, sort?: string) {

    let summaryData = [];
    let series: any[] = [];

    for (let range of fieldValueGroupByRange) {

      let valuesToGroupBy = dataArray.filter((item: any) => item[field] >= range.From && item[field] <= range.To);
      //console.log('createChartDataSet_Count_Grouped() valuesToGroupBy', valuesToGroupBy);

      // Count the number of features for the key value and add to the summary data
      let count = valuesToGroupBy.length;
      //console.log('createChartDataSet_Count_Grouped() range, field, count', range.Label, field, count);

      // Add the series data and create the data set
      let data = {
        name: range.Label,
        value: count
      }

      // Line / Area Chart wants a Data Series
      if (dataSeries === true) {
        // Build the series data
        series.push(data);
      }
      else {
        summaryData.push(data);
      }
    }

    if (dataSeries === true) {
      // Add the series data and create the data set
      let data_s = {
        name: 'Count',
        series
      }
      summaryData.push(data_s);
    }

    let sortField = 'name';

    if (sort === 'DESC') {
      Global.sortArrayDesc(summaryData, sortField);
    }
    else if (sort === 'NONE') {
      // No sort
    }
    else {
      Global.sortArrayAsc(summaryData, sortField);
    }

    return summaryData;
  }

  protected createChartDataSet_Count_Grouped(dataArray: any[], groupByField: string, sort?: string) {

    let summaryData = [];
    let series: any[] = [];


    let valuesToGroupBy = Array.from(new Set(dataArray.map((item: any) => item[groupByField])));
    //console.log('valuesToGroupBy', valuesToGroupBy);

    for (let groupByValue of valuesToGroupBy) {
      //console.log('groupByValue', groupByValue);

      let group = dataArray.filter((item: any) => item[groupByField] === groupByValue);
      let count = group.length;


      let data = {
        name: groupByValue,
        value: count
      }

      summaryData.push(data);

    }

    let sortField = 'name';

    if (sort === 'DESC') {
      Global.sortArrayDesc(summaryData, sortField);
    }
    else if (sort === 'NONE') {
      // No sort
    }
    else {
      Global.sortArrayAsc(summaryData, sortField);
    }

    return summaryData;

  }

  protected createChartDataSet_Sum_Split(dataArray: any, sumField: string, splitByField: string, sort?: string, sortField?: string) {
    //console.log('this.createChartDataSet_GetUniqueValueCount() dataArray', dataArray);
    let valuesToSplitBy = Array.from(new Set(dataArray.map((item: any) => item[splitByField])));
    //let valuesToSummarise = Array.from(new Set(dataArray.map((item: any) => item[groupByField])));
    //console.log('this.createChartDataSet_Sum() valuesToSummarise', valuesToSplitBy);

    // sort = ASC or DESC
    // sortField = name or value
    let summaryData = [];
    let idx = 0;

    valuesToSplitBy.forEach(name => {
      // Count the number of features for the key value and add to the summary data

      let group: any[] = dataArray.filter(x => x[splitByField] === name);
      //console.log('this.createChartDataSet_Sum() group', group);

      let sum: number = 0;

      //for (let i = 0; i < group.length; i++) {
      // for (let i of group) {
      //   console.log('sumField', [i]);
      //   sum += [i].sumField;
      // };

      group.forEach(item => {
        sum += item[sumField]
      });
      //console.log('count', count);

      let data = {
        name,
        value: sum
      }

      summaryData.push(data);
      idx++
    });

    if (!sortField) {
      sortField = 'name';
    }

    if (sort === 'DESC') {
      Global.sortArrayDesc(summaryData, sortField);
    }
    else {
      Global.sortArrayAsc(summaryData, sortField);
    }

    return summaryData;
  }

  protected createChartDataSet_Sum_Split_Grouped(dataArray: any[], sumField: string, groupByField: string, splitByField: string, sort?: string) {
    let summaryData = [];
    let series: any[] = [];

    //valuesToGroupBy
    let valuesToGroupBy = Array.from(new Set(dataArray.map((item: any) => item[groupByField])));
    let valuesToSplitBy = Array.from(new Set(dataArray.map((item: any) => item[splitByField])));

    for (let groupByValue of valuesToGroupBy) {

      let group = dataArray.filter((item: any) => item[groupByField] === groupByValue); //.From);
      series = [];

      valuesToSplitBy.forEach(splitByValue => {
        let split = group.filter(x => x[splitByField] === splitByValue);
        let sum: number = 0;

        for (let i = 0; i < split.length; i++) {
          //console.log('sumField', split[i][sumField]);
          sum += split[i][sumField];
        };

        let data = {
          name: splitByValue,
          value: sum
        }

        // Build the series data
        series.push(data);
      });

      let data = {
        name: groupByValue, //.From,
        series
      }

      summaryData.push(data);
    }

    let sortField = 'name';

    if (sort === 'DESC') {
      Global.sortArrayDesc(summaryData, sortField);
    }
    else {
      Global.sortArrayAsc(summaryData, sortField);
    }

    return summaryData;
  }

  protected createChartDataSet_SumMany_Grouped(dataArray: any[], sumFields: string[], sumFieldLabels: string[], groupByField: string, sort?: string) {

    let summaryData = [];
    let series: any[] = [];

    let valuesToGroupBy = Array.from(new Set(dataArray.map((item: any) => item[groupByField])));
    //console.log('valuesToGroupBy', valuesToGroupBy);

    for (let groupByValue of valuesToGroupBy) {
      //console.log('groupByValue', groupByValue);

      let group = dataArray.filter((item: any) => item[groupByField] === groupByValue); //.From);
      series = [];

      let idx: number = 0;

      sumFields.forEach(keyField => {
        //console.log('sumFields keyField', keyField);
        let sum: number = 0;

        for (let i = 0; i < group.length; i++) {
          //console.log('group', group[i]);
          sum += group[i][keyField];
        };

        //console.log('sumFieldLabels, idx, sumFieldLabels[idx]', sumFieldLabels, idx, sumFieldLabels[idx]);
        let data = {
          name: sumFieldLabels[idx],
          value: sum
        }

        // Build the series data
        series.push(data);

        idx++;
      })

      let data = {
        name: groupByValue,
        series
      }

      summaryData.push(data);
    }

    let sortField = 'name';

    if (sort === 'DESC') {
      Global.sortArrayDesc(summaryData, sortField);
    }
    else {
      Global.sortArrayAsc(summaryData, sortField);
    }

    return summaryData;
  }

  // Card Data Functions

  protected createCardDataSet_Count(dataArray: any[]) {
    return dataArray.length;
  }

}


/*

<!-- Dashboard Row 1 -->
<div fxLayout='row' fxLayoutGap="20px" fxLayoutAlign="space-between center" style='height: 450px; margin-top: 20px; padding-left: 40px; padding-right: 60px;'>

    <!-- Chart 1 -->
    <div fxFlex="33" class='chart-box' [ngClass]="{'dark-chart-box': darkMode}">
        <div fxLayout='column' fxLayoutAlign="space-between center">
            <div fxLayout='row' fxShow fxHide.lt-md fxLayoutAlign="space-between center" [ngClass]="{'dark': darkMode}">

            </div>

            <div fxLayout='row' fxHide fxShow.lt-md fxLayoutAlign="space-between center" [ngClass]="{'dark': darkMode}">
            </div>

            <div>
                <span class='chart-title' [ngClass]="{'dark-chart-title': darkMode}">Status</span>
            </div>
        </div>
    </div>


    <!-- Chart 2 -->
    <div fxFlex="33" class='chart-box' [ngClass]="{'dark-chart-box': darkMode}">
        <div fxLayout='column' fxLayoutAlign="space-between center">
            <div fxLayout='row' fxShow fxHide.lt-md fxLayoutAlign="space-between center" [ngClass]="{'dark': darkMode}">

            </div>

            <div fxLayout='row' fxHide fxShow.lt-md fxLayoutAlign="space-between center" [ngClass]="{'dark': darkMode}">
            </div>

            <div>
                <span class='chart-title' [ngClass]="{'dark-chart-title': darkMode}">Status</span>
            </div>
        </div>
    </div>


</div>



<ngx-charts-advanced-pie-chart
    [view]="[(chartWidth-60)/2, 220]"
    [results]="getChartData('STATUS')"
    [label]="'Number of work requests'"
    [gradient]="true"
    [scheme]='customColorScheme'
    (select)="selectChart($event, 'STATUS', 'DomStatus', 'Status', false)">
</ngx-charts-advanced-pie-chart>


<ngx-charts-pie-chart
    [view]="[chartWidth, 220]"
    [results]="getChartData('STATUS')"
    [labels]="true"
    [gradient]="true"
    [scheme]='customColorScheme'
    (select)="selectChart($event, 'STATUS', 'DomWorkRequestStatus', 'Status', false)">
</ngx-charts-pie-chart>



<ngx-charts-bar-vertical
    [view]="[(chartWidth-60)/2, 220]"
    [results]="getChartData('FAIRWAY')"
    [xAxisLabel]="'Hole'"
    [yAxisLabel]="'Count'"
    [showXAxisLabel]="false"
    [showYAxisLabel]="false"
    [xAxis]="true"
    [yAxis]="true"
    [trimXAxisTicks]="true"
    [maxXAxisTickLength]='5'
    [rotateXAxisTicks]='true'
    [tooltipDisabled]='true'
    [gradient]="true"
    [roundEdges]='true'
    [noBarWhenZero]='true'
    [scheme]='customColorScheme'
    (select)="selectChart($event, 'FAIRWAY', 'DomFairway', 'FairwayNumber', false, true)">
</ngx-charts-bar-vertical>


*/