import { Component, OnInit, OnDestroy, AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, ElementRef } from '@angular/core';
import { BaseFeaturePage } from 'src/app/_esri/models/base-feature-page';
import { MatDialog } from '@angular/material/dialog';
import { getTime, toDate, format, isBefore, parse, addYears } from 'date-fns';
import { FeaturesService } from 'src/app/_esri/services/features.service';
import { Esri, EsriGraphic, ApplyEditsResultMessage, GeometryPoint, DashboardChart } from 'src/app/_esri/models/esri';
import { CurrentModeService } from 'src/app/_globals/services/current-mode.service';
import { GeocodeDataService } from 'src/app/_globals/services/geocode-data.service';
import { FormChangeService } from 'src/app/_globals/services/form-change.service';
import { FormFieldChangeService } from 'src/app/_globals/services/form-field-change.service';
import { FormFieldUpdateService } from 'src/app/_globals/services/form-field-update.service';
import { MemberRoundOrigin } from 'src/app/member/models/member';
import { CsvReadService } from 'src/app/_globals/services/csv-read.service';
import { Global, GroupBy, GroupArray, GroupByRange } from 'src/app/_globals/models/global';

@Component({
  selector: 'app-member-origins',
  templateUrl: './member-origins.component.html',
  styleUrls: [
    './member-origins.component.scss',
    '../../../_styles/base-feature-page.scss'
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MemberOriginsComponent extends BaseFeaturePage implements OnInit, OnDestroy, AfterViewInit {

  title = 'Rounds Played - Origin';
  //subTitle = '';
  showTimeSliderWidget = false;
  //dateFilterAttributeName = '';

  showDataCards = false;
  showDataTable = false;
  showBaseLayers = false;
  showImageGallery = false;
  darkMode = false;

  dashboardCharts: DashboardChart[] = [ 
    { chartName: 'ORIGIN_TOTAL_YEAR' },
    { chartName: 'ORIGIN' },
    { chartName: 'ROUND_TOTAL_DAY' }, 
    { chartName: 'ORIGIN_TOTAL_PERIOD' },
    { chartName: 'ROUND_TOTAL_PERIOD_DAY' }, 
  ];

  private _csvDataArray: any = [];   // used by CSV load 
  private _dataArray_filter: any = [];
  private _fieldToSummarise: string = 'Total';

  constructor(
    protected ref: ChangeDetectorRef,
    protected dialog: MatDialog,
    protected currentModeService: CurrentModeService,
    protected geocodeDataService: GeocodeDataService,
    protected formChangeService: FormChangeService,
    protected formFieldChangeService: FormFieldChangeService,
    protected formFieldUpdateService: FormFieldUpdateService,
    protected elementRef: ElementRef,
    //
    private csvReadService: CsvReadService,
  ) {
    super(
      ref,
      dialog,
      currentModeService,
      geocodeDataService,
      formChangeService,
      formFieldChangeService,
      formFieldUpdateService,
      elementRef
    );
  }

  ngOnInit(): void {
    this.thinking = true;
    // Get layers, features, lovs, domains, etc
    this.featureLayerObject = new MemberRoundOrigin();
    // Load the Feature Layer - There is no view, so need to call this here
    this.featureLayerObject.getFeatureLayerConfig();
    super.ngOnInit();
  }

  //
  // Get attributes from feature set and assign to class "featureAttributes", (view table, and view cards )- calc virtual fields
  //
  protected refreshDisplay() {
    this.featureLayerObject.updateVirtualFields();
    this._dataArray_filter = this.featureLayerObject.attributes;
    super.refreshDisplay();

    //console.log('Attempting to load csv data');
    //this.createFeatures(this.featureLayerObject.featureLayer);
  }

  //
  // Build data for charts
  //

  protected createCharts() {
    super.createCharts();
    let days: string[] = [];
    let dayLabels: string[] = [];

    if (this._fieldToSummarise === 'Total') {
      console.log('createCharts() Total');
      days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
      dayLabels = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    }
    else {
      console.log('createCharts() Not Total', this._fieldToSummarise);
      days = [this._fieldToSummarise];
      dayLabels = [this._fieldToSummarise.substr(0, 3)];
    }

    //console.log('createCharts() days, dayLabels', days, dayLabels);

    this.dashboardCharts.find(x => x.chartName == 'ORIGIN_TOTAL_YEAR').summaryData = this.createChartDataSet_Sum_Split_Grouped(this._dataArray_filter, this._fieldToSummarise, 'Year', 'PlayerOrigin', 'ASC');
    this.dashboardCharts.find(x => x.chartName == 'ORIGIN').summaryData = this.createChartDataSet_Sum_Split(this._dataArray_filter, this._fieldToSummarise, 'PlayerOrigin'); //, 'value');
    //this.dashboardCharts.find(x => x.chartName == 'ROUND_TOTAL_DAY').summaryData = this.createChartDataSet_SumMany(this._dataArray_GenderRound_filter, ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'], ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']);
    this.dashboardCharts.find(x => x.chartName == 'ROUND_TOTAL_DAY').summaryData = this.createChartDataSet_SumMany(this._dataArray_filter, days, dayLabels);

    this.dashboardCharts.find(x => x.chartName == 'ORIGIN_TOTAL_PERIOD').summaryData = this.createChartDataSet_Sum_Split_Grouped(this._dataArray_filter, this._fieldToSummarise, 'PeriodLabel', 'PlayerOrigin', 'ASC');
    
    //this.dashboardCharts.find(x => x.chartName == 'ROUND_TOTAL_PERIOD_DAY').summaryData = this.createChartDataSet_SumMany_Grouped(this._dataArray_GenderRound_filter, ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'], ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'], 'PeriodLabel', 'ASC');
    this.dashboardCharts.find(x => x.chartName == 'ROUND_TOTAL_PERIOD_DAY').summaryData = this.createChartDataSet_SumMany_Grouped(this._dataArray_filter, days, dayLabels, 'PeriodLabel', 'ASC');
    console.log('this.dashboardCharts', this.dashboardCharts);
  }

  public selectChart_Rounds(event, chartName: string) {
    console.log(event);
    let chart = this.dashboardCharts.find(x => x.chartName == chartName);

    switch (chartName) { 
      case 'ORIGIN_TOTAL_YEAR':
        chart.clause = 'Year=' + event.series;
        chart.label = event.series;
        chart.visibleChip = true;
        break;

      case 'ORIGIN':
        chart.clause = "PlayerOrigin='" + event.name + "'";
        chart.label = event.name;
        chart.visibleChip = true;
        break;

      case 'ROUND_TOTAL_DAY':
        this._fieldToSummarise = event.extra.code;
        chart.label = event.name;
        chart.visibleChip = true;
        break;

      default:
        break;
    }

    this.reQueryChartData();
  }

  protected removeChip(chartName: string) {
    // Over-ride base fn
    let chart = this.dashboardCharts.find(x => x.chartName.toUpperCase() == chartName.toUpperCase());
    //console.log('removeChip() chartName, chart', chartName, chart);

    // Remove the chip from the displayed filter list
    if (chart) {
      chart.visibleChip = false;
    }

    if (chart.chartName === 'ROUND_TOTAL_DAY') {
      this._fieldToSummarise = 'Total';
    }

    // Re-query
    this.reQueryChartData();
  }

  public reQueryChartData() {
    //console.log('reQueryChartData() 1', this._dataArray_GenderRound_filter.length);
    this._dataArray_filter = [...this.featureLayerObject.attributes];

    this.dashboardCharts.filter(x => x.visibleChip === true).forEach(element => {
      if (element.clause) {
        const clauseParts = element.clause.split('=', 2);

        if (clauseParts[1].indexOf('\'') >= 0) { //&& clauseParts[0].indexOf('\"') >= 0) {
          // String
          const clausePartString: string = clauseParts[1].replace(/'/g, '');
          this._dataArray_filter = [...this._dataArray_filter.filter(x => x[clauseParts[0]] === clausePartString)];
        }
        else {
          // Numeric
          //console.log('reQueryChartData() Numeric');
          const clausePartNumber: number = +clauseParts[1];
          this._dataArray_filter = [...this._dataArray_filter.filter(x => x[clauseParts[0]] === clausePartNumber)];
        }
      }
    });

    // Re-build the charts
    this.createCharts();
  }

  //
  // CSV Data Load
  //

  protected async getData(): Promise<any> {
    let members: any[] = [];
    let fileName: string = 'assets/data/PlayerOrigin.csv';

    await this.csvReadService.readCsvFile(fileName, true).then( (parsedData) => {
      members = parsedData;
    });

    return members;
  }

  // This fn creates features from a CSV
  private async XXcreateFeatures(featureLayer: any) {
    await this.getData().then((data) => {
      this._csvDataArray = data;
    });
    //console.log('this._memberDataArray', this._memberDataArray);

    let result: ApplyEditsResultMessage = new ApplyEditsResultMessage();
    let featuresService: FeaturesService = new FeaturesService();
    let params;
    let resultMessage: string = '';
    //let geometry: GeometryPoint;
    let newFeatures: any[] = [];

    for (let element of this._csvDataArray.data) {
      const attributes = {
        'Year': element['Year'],
        'MonthNumber': element['Month'],
        'RoundDate': getTime(parse(element['Date'], 'dd/MM/yyyy', new Date())),
        'PlayerOrigin': element['PlayerOrigin'],
        'MonthLabel': Global.getMonthShortName(element['Month']),
        'Sunday': element['Sunday'],
        'Monday': element['Monday'],
        'Tuesday': element['Tuesday'],
        'Wednesday': element['Wednesday'],
        'Thursday': element['Thursday'],
        'Friday': element['Friday'],
        'Saturday': element['Saturday'],
      }
      console.log('this.createFeatures attributes', attributes);

      // Create a new feature
      const newFeature: EsriGraphic = {
        attributes
      };
      //console.log('this.createFeatures newFeature', newFeature);

      newFeatures.push(newFeature);
    }

    // Setup the applyEdits parameter with adds
    const adds = {
      addFeatures: newFeatures
    };
    params = adds;

    console.log('createFeatures() params', params);

    // Send the adds to the GIS server (ESRI feature service)
    await featuresService.applyEditsFeatureLayer(featureLayer, params).then((editResult) => {
      result = editResult;
      console.log('createFeatures() applyEditsFeatureLayer result', result);
      //result.recordId = recordId;
    });
  }


}
