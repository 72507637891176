import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, Injectable, ViewChild, ElementRef } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { Esri } from '../../models/esri';
import { setDefaultOptions, loadModules } from 'esri-loader';
import { CurrentModeService, CurrentModeData } from 'src/app/_globals/services/current-mode.service';
import { EsriViewCardsComponent } from 'src/app/_esri/components/esri-view-cards/esri-view-cards.component';

// const legend = new Legend({
//   view: view
// });
// const legendExpand = new Expand({
//   expandIconClass: "esri-icon-legend",
//   expandTooltip: "Legend",
//   view: view,
//   content: legend,
//   expanded: false
// });
// view.ui.add(legendExpand, "top-left");

@Component({
  selector: 'app-esri-map',
  templateUrl: './esri-map.component.html',
  styleUrls: ['./esri-map.component.scss'],
  animations: [
    // the fade-in/fade-out animation.
    trigger('fadeInOut', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate(600)
      ]),
      // transition(':leave',
      //   animate(600, style({opacity: 0}))
      // )
    ]),
    trigger('fadeSlideInOut', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(-100px)' }),
        animate(600, style({ opacity: 1, transform: 'translateY(0)' })),
      ]),
      transition(':leave', [
        animate(600, style({ opacity: 0, transform: 'translateY(-100px)' })),
      ])
    ]),
    trigger('fadeInSlideOut', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate(600)
      ]),
      transition(':leave', [
        animate(600, style({ opacity: 0, transform: 'translateY(-100px)' })),
      ])
    ])
  ],
})

@Injectable({
  providedIn: 'root'
})

export class EsriMapComponent implements OnInit, OnDestroy {

  @Output() mapLoadedEvent = new EventEmitter<any>();
  @Output() viewCreatedEvent = new EventEmitter<any>();
  @Output() fullscreenToggleEvent = new EventEmitter<any>();
  @Output() reportBtnSelectedEvent = new EventEmitter<any>();
  @Output() createWorkRequestBtnSelectedEvent = new EventEmitter<any>();
  @Output() createProjectBtnSelectedEvent = new EventEmitter<any>();

  private _zoom: number = Esri.initialZoom;
  private _center: Array<number> = Esri.initialCenter;
  private _hideBasemap: boolean = false;
  private _hideBasemapBackgroundColour: string = "rgb(253, 253, 253)";   //background-color: rgba($theme-dark-grey, 0.01);
  private _basemapOpacity: number = 0.25;
  private _initialBasemapName: string = Esri.initialBasemapName; //Esri.initialBasemapName 'streets';
  private _toggleBasemapName: string = Esri.toggleBasemapName;
  private _map = null;
  private _view = null;
  private _sidePanelWidth: number;    // Same value as the width in CSS
  private _showHomeWidget: boolean = true;
  private _showCompassWidget: boolean = false;
  private _showFullscreenWidget: boolean = false;
  private _showBasemapGalleryWidget: boolean = false;
  private _showLayerListWidget: boolean = false;
  private _showLegendWidget: boolean = false;
  private _showSwipeWidget: boolean = false;
  private _showLogo: boolean = false;
  //private _showTimeSliderWidget: boolean = false;
  private _widgetPosition: string = 'top-right';
  private _layerListPosition: string = 'bottom-right';
  private _fullscreenWidget: any;
  private _legendLayerInfos: any[] = [];
  //public timeSlider: any;

  public swipeWidget: any;
  public legendWidget: any;
  public layerListWidget: any;

  private _leadingLayerList: any[] = [];
  private _trailingLayerList: any[] = [];
  private _swipeStartPosition: number = 50;

  public infoPanelTitleHidden: boolean = true;
  public title: string;
  public subTitle: string;
  public loaded: boolean = false;
  public zoomToFeature: boolean = false;
  public panToFeature: boolean = true;
  private _panToFeature_previous: boolean = true;

  // Feature Edit Workflow (Tabs / Buttons)
  public hasLocation: boolean = true;
  public allowNoLocation: boolean = false;
  public locationHidden: boolean = true;
  public locationMap: boolean = false;
  public locationAddress: boolean = false;
  public locationNoneChoice: boolean = false;

  public addBtnHidden: boolean = false;
  public reportBtnHidden: boolean = false;
  public createWorkRequestBtnHidden: boolean = true;
  public createProjectBtnHidden: boolean = true;

  public addressSelectBtnDisabled: boolean = true;
  public locationSelectBtnDisabled: boolean = true;
  public createWorkRequestBtnDisabled: boolean = true;
  public createProjectBtnDisabled: boolean = true;


  //public showDataCards: boolean = true;

  @ViewChild("infoPanelTitle", { static: true }) public infoPanelTitleEl: ElementRef;
  @ViewChild("sideBar", { static: true }) public sideBarEl: ElementRef;
  @ViewChild("map", { static: true }) private mapEl: ElementRef;
  @ViewChild("timeSlider", { static: true }) private timeSliderEl: ElementRef;
  @ViewChild("logo", { static: true }) private logoEl: ElementRef;
  @ViewChild("logoCustomer", { static: true }) private logoCustomerEl: ElementRef;
  @ViewChild("zoomToFeatureChoice", { static: true }) private zoomToFeatureChoiceEl: ElementRef;
  @ViewChild("panToFeatureChoice", { static: true }) private panToFeatureChoiceEl: ElementRef;
  @ViewChild("addBtn", { static: true }) private addBtnEl: ElementRef;
  @ViewChild("reportBtn", { static: true }) private reportBtnEl: ElementRef;
  @ViewChild("createIncidentBtn", { static: true }) private createWorkRequestBtnEl: ElementRef;
  @ViewChild("createProjectBtn", { static: true }) private createProjectBtnEl: ElementRef;
  @ViewChild("addFeatureWorkflow", { static: true }) public addFeatureWorkflowEl: ElementRef;
  //@ViewChild("popupData", { static: true }) public popupDataEl: ElementRef;

  @ViewChild(EsriViewCardsComponent) esriViewCardsComponent: EsriViewCardsComponent;

  // private _popupMenuEl: ElementRef;

  // @Input()
  // set popupMenuEl(popupMenuEl: ElementRef) {
  //   this._popupMenuEl = popupMenuEl;
  // }

  // get popupMenuEl(): ElementRef {
  //   return this._popupMenuEl;
  // }

  get mapLoaded(): boolean {
    return this.loaded;
  }

  @Input()
  set zoom(zoom: number) {
    this._zoom = zoom;
  }

  get zoom(): number {
    return this._zoom;
  }

  @Input()
  set center(center: Array<number>) {
    this._center = center;
  }

  get center(): Array<number> {
    return this._center;
  }

  @Input()
  set hideBasemap(hideBasemap: boolean) {
    this._hideBasemap = hideBasemap;
  }

  get hideBasemap(): boolean {
    return this._hideBasemap;
  }

  @Input()
  set basemapName(basemapName: string) {
    this._initialBasemapName = basemapName;
  }

  get basemapName(): string {
    return this._initialBasemapName;
  }

  @Input()
  set toggleBasemapName(toggleBasemapName: string) {
    this._toggleBasemapName = toggleBasemapName;
  }

  get toggleBasemapName(): string {
    return this._toggleBasemapName;
  }

  @Input()
  set basemapOpacity(basemapOpacity: number) {
    this._basemapOpacity = basemapOpacity;
  }

  get basemapOpacity(): number {
    return this._basemapOpacity;
  }

  @Input()
  set sidePanelWidth(sidePanelWidth: number) {
    this._sidePanelWidth = sidePanelWidth;
  }

  get sidePanelWidth(): number {
    return this._sidePanelWidth;
  }

  @Input()
  set showHomeWidget(showHomeWidget: boolean) {
    this._showHomeWidget = showHomeWidget;
  }

  get showHomeWidget(): boolean {
    return this._showHomeWidget;
  }

  @Input()
  set showFullscreenWidget(showFullscreenWidget: boolean) {
    this._showFullscreenWidget = showFullscreenWidget;
  }

  get showFullscreenWidget(): boolean {
    return this._showFullscreenWidget;
  }

  @Input()
  set showBasemapGalleryWidget(showBasemapGalleryWidget: boolean) {
    this._showBasemapGalleryWidget = showBasemapGalleryWidget;
  }

  get showBasemapGalleryWidget(): boolean {
    return this._showBasemapGalleryWidget;
  }

  @Input()
  set showLayerListWidget(showLayerListWidget: boolean) {
    this._showLayerListWidget = showLayerListWidget;
  }

  get showLayerListWidget(): boolean {
    return this._showLayerListWidget;
  }

  @Input()
  set showLegendWidget(showLegendWidget: boolean) {
    this._showLegendWidget = showLegendWidget;
  }

  get showLegendWidget(): boolean {
    return this._showLegendWidget;
  }

  @Input()
  set showSwipeWidget(showSwipeWidget: boolean) {
    this._showSwipeWidget = showSwipeWidget;
  }

  get showSwipeWidget(): boolean {
    return this._showSwipeWidget;
  }

  @Input()
  set legendLayerInfos(legendLayerInfos: any[]) {
    this._legendLayerInfos = legendLayerInfos;
  }

  get legendLayerInfos(): any[] {
    return this._legendLayerInfos;
  }

  @Input()
  set leadingLayerList(leadingLayerList: any[]) {
    this._leadingLayerList = leadingLayerList;
  }

  get leadingLayerList(): any[] {
    return this._leadingLayerList;
  }

  @Input()
  set trailingLayerList(trailingLayerList: any[]) {
    this._trailingLayerList = trailingLayerList;
  }

  get trailingLayerList(): any[] {
    return this._trailingLayerList;
  }

  @Input()
  set swipeStartPosition(swipeStartPosition: number) {
    this._swipeStartPosition = swipeStartPosition;
  }

  get swipeStartPosition(): number {
    return this._swipeStartPosition;
  }

  // @Input()
  // set showTimeSliderWidget(showTimeSliderWidget: boolean) {
  //   this._showTimeSliderWidget = showTimeSliderWidget;
  // }

  // get showTimeSliderWidget(): boolean {
  //   return this._showTimeSliderWidget;
  // }

  @Input()
  set showLogo(showLogo: boolean) {
    this._showLogo = showLogo;
  }

  get showLogo(): boolean {
    return this._showLogo;
  }

  @Input()
  set widgetPosition(widgetPosition: string) {
    this._widgetPosition = widgetPosition;
  }

  get widgetPosition(): string {
    return this._widgetPosition;
  }

  @Input()
  set view(view: any) {
    this._view = view;
  }

  get view(): any {
    return this._view;
  }

  constructor(
    public currentModeService: CurrentModeService,
  ) { }

  ngOnInit(): void {
    // Initialize MapView and return an instance of MapView

    // if (this.sidePanelWidth > 0) {
    //   this.showDataCards = true;
    //   //this.esriMapComponent.sidePanelWidth = 500;
    // }
    // else {
    //   this.showDataCards = false;
    //   //this.esriMapComponent.sidePanelWidth = 0;
    // }

    //console.log('this._sidePanelWidth', this._sidePanelWidth);

    this.initialiseMap().then((view) => {
      // The map has been initialized
      //console.log('View ready: ', mapView.ready);
      this.loaded = view.ready;
      this.mapLoadedEvent.emit(this._map);

      // Send the view back as well
      this.viewCreatedEvent.emit(view);
    });
  }

  ngOnDestroy(): void {
    // destroy the map view
    if (this._view) {
      this._view.map.destroy();
      this._view.container = null;
      console.log('Map View destroyed');
    }
  }

  async initialiseMap() {
    try {
      const options = {
        version: Esri.apiVersion
      };

      setDefaultOptions(options);

      const [
        Map,
        MapView,
        BasemapToggle,
        BasemapGallery,
        Home,
        Fullscreen,
        LayerList,
        //TimeSlider,
        Expand,
        Compass,
        Legend,
        Swipe,
        Bookmarks,
      ] = await loadModules([
        'esri/Map',
        'esri/views/MapView',
        'esri/widgets/BasemapToggle',
        'esri/widgets/BasemapGallery',
        'esri/widgets/Home',
        'esri/widgets/Fullscreen',
        'esri/widgets/LayerList',
        //"esri/widgets/TimeSlider",
        'esri/widgets/Expand',
        'esri/widgets/Compass',
        "esri/widgets/Legend",
        "esri/widgets/Swipe",
        'esri/widgets/Bookmarks',
      ]);

      // Basemaps
      let basemap = await Esri.getBasemap(this._initialBasemapName);

      const mapProperties = {
        basemap: basemap
      };

      this._map = new Map(mapProperties);

      // Initialize the MapView
      let mapViewProperties = {
        container: this.mapEl.nativeElement,
        center: this._center,
        zoom: this._zoom,
        padding: {},
        map: this._map,
        background: {
          color: this._hideBasemapBackgroundColour
        },
        navigation: {
          mouseWheelZoomEnabled: false,
          browserTouchPanEnabled: false
        },
        popup: {
          actions: [],
          dockEnabled: false,
          dockOptions: {
            buttonEnabled: false,   // Disables the dock button from the popup
            breakpoint: false,      // Ignore the default sizes that trigger responsive docking
            //position: "top-left"
          },
          visibleElements: {
            closeButton: false
          },
          autoCloseEnabled: true
          //content: 'Wal was here'
        }
      };

      if (this._sidePanelWidth > 0) {
        mapViewProperties.padding = {
          left: this._sidePanelWidth
        }
      }

      // Hide the base map if set
      if (this._hideBasemap === true) {
        // Set background colour
        // mapViewProperties.background = {
        //   color: this._hideBasemapBackgroundColour
        // }

        // Set opacity to 0, ie hide them
        // this._map.basemap.baseLayers.forEach(layer => {
        //   layer.opacity = 0;
        // })
        this._basemapOpacity = 0;
      }
      // else {
      //   // this._map.basemap.baseLayers.forEach(layer => {
      //   //   layer.opacity = 0.2;
      //   // })
      //   this._basemapOpacity = 0.2;
      // }

      this._map.basemap.baseLayers.forEach(layer => {
        layer.opacity = this._basemapOpacity;
      })

      this._view = new MapView(mapViewProperties);

      // Logos
      if (this._showLogo) {
        this._view.ui.add([
          {
            component: this.logoEl.nativeElement,
            //position: 'top-right',
            position: 'bottom-right',
            index: 0
          }
        ]);
      }

      // if (this._sidePanelWidth > 0) {

      //   this._view.ui.add([
      //     {
      //       component: this.sideBarEl.nativeElement,
      //       position: 'top-left',
      //       index: 0
      //     }
      //   ]);

      // }




      // this._view.ui.add([
      //   {
      //     component: this.logoCustomerEl.nativeElement,
      //     position: 'top-left',
      //     //index: 0
      //   }
      // ]);

      //
      // Widgets
      //

      // Home Widget
      if (this._showHomeWidget) {
        const homeWidget = new Home({
          view: this._view
        });

        this._view.ui.add([
          {
            component: homeWidget,
            position: this._widgetPosition,
            index: 0
          }
        ]);
      }

      // Compass Widget
      if (this._showCompassWidget) {
        const compassWidget = new Compass({
          view: this._view
        });

        this._view.ui.add([
          {
            component: compassWidget,
            position: this._widgetPosition,
            index: 1
          }
        ]);
      }

      // Fullscreen Widget
      if (this._showFullscreenWidget) {
        // Create the fullscreen widget

        this._fullscreenWidget = new Fullscreen({
          view: this._view
        });

        // Add it to the view
        this._view.ui.add([
          {
            component: this._fullscreenWidget,
            position: this._widgetPosition,
            index: 2
          }
        ]);

        this._fullscreenWidget.on("click", (event) => {
          console.log('_fullscreenWidget click', event);
          this.fullscreenToggleEvent.emit(event);
        });
      }

      // Basemap Toggle Widget

      let toggleBasemap = await Esri.getBasemap(this._toggleBasemapName);

      const basemapToggleWidget = new BasemapToggle({
        view: this._view,
        nextBasemap: toggleBasemap
      });



      // let toggleBasemap;

      // let basemapToggleWidget = new BasemapToggle({
      //   view: this._view,
      //   //nextBasemap: toggleBasemap 
      // });

      // if (this._hideBasemap === true) {
      //   toggleBasemap = await Esri.getBasemap('hidden');
      // }
      // else {
      //   toggleBasemap = await Esri.getBasemap(this._toggleBasemapName);
      // }

      // basemapToggleWidget.nextBasemap = toggleBasemap;




      // Add it to the view
      this._view.ui.add([
        {
          component: basemapToggleWidget,
          position: this._widgetPosition,
          index: 3
        }
      ]);

      // Basemap Gallery Widget   
      if (this._showBasemapGalleryWidget) {

        const basemapGalleryWidget = new BasemapGallery({
          view: this._view
        });

        const galleryExpand = new Expand({
          view: this._view,
          label: 'Basemaps',
          expandTooltip: 'Basemaps',
          expandIconClass: "esri-icon-basemap",
          autoCollapse: true,
          content: basemapGalleryWidget
        });

        // Add it to the view
        this._view.ui.add([
          {
            component: galleryExpand,
            position: this._widgetPosition,
            index: 4
          }
        ]);

        // Think about saving the last selected basemap to local storage - can then always have the user's preferrred basemap
        // basemapGalleryWidget.on("click", (event) => {
        //   console.log(event);
        // });
      }


      // Legend Widget
      if (this._showLegendWidget) {

        this.legendWidget = new Legend({
          view: this._view,
          layerInfos: this._legendLayerInfos
        });

        const legendExpand = new Expand({
          view: this._view,
          label: 'Legend',
          expandTooltip: 'Legend',
          expandIconClass: "esri-icon-legend",
          content: this.legendWidget
        });

        // Add it to the view
        this._view.ui.add([
          {
            component: legendExpand,
            position: this._widgetPosition,
            index: 5
          }
        ]);
      }

      // LayerList Widget
      if (this._showLayerListWidget) {
        // Create the LayerList widget
        this.layerListWidget = new LayerList({
          view: this._view,
          selectionEnabled: false,
          multipleSelectionEnabled: false
        });

        const layerExpand = new Expand({
          view: this._view,
          label: 'Layers',
          expandTooltip: 'Layers',
          expandIconClass: "esri-icon-layers",
          content: this.layerListWidget
        });

        // Add it to the view
        this._view.ui.add([
          {
            component: layerExpand,
            position: this._widgetPosition,
            index: 6
          }
        ]);
      }

      // // Swipe Widget
      // if (this._showSwipeWidget) {
      //   this.swipeWidget = new Swipe({
      //     leadingLayers: this._leadingLayerList,
      //     trailingLayers: this._trailingLayerList,
      //     position: this._swipeStartPosition,
      //     view: this._view
      //   });

      //   //this._view.ui.add(swipeWidget);
      // }

      // // TimeSlider Widget
      // if (this._showTimeSliderWidget) {

      //   this.timeSlider = new TimeSlider({
      //     view: this._view,
      //     container: this.timeSliderEl.nativeElement,
      //     layout: "auto",   // "auto"|"compact"|"wide"
      //     //timeVisible: true, // show the time stamps on the timeslider
      //     //loop: true
      //   });

      //   // Add it to the view
      //   this._view.ui.add([
      //     {
      //       component: this.timeSlider,
      //       position: 'bottom-left',
      //       index: 0
      //     }
      //   ]);
      // }

      // Add the Info Panel
      this.view.ui.add([
        {
          component: this.infoPanelTitleEl.nativeElement,
          position: 'top-left',
          index: 0
        }
      ]);

      // Toggle Slide to turn on/off zoom on select 
      this.view.ui.add([
        {
          component: this.zoomToFeatureChoiceEl.nativeElement,
          position: 'bottom-left',
          index: 0
        }
      ]);

      // Toggle Slide to turn on/off pan on select
      this.view.ui.add([
        {
          component: this.panToFeatureChoiceEl.nativeElement,
          position: 'bottom-left',
          index: 1
        }
      ]);

      // Add a new feature button
      this.view.ui.add([
        {
          component: this.addBtnEl.nativeElement,
          position: 'top-left',
          index: 1
        }
      ]);

      // Run a report button
      this.view.ui.add([
        {
          component: this.reportBtnEl.nativeElement,
          position: 'top-left',
          index: 2
        }
      ]);

      // Create a WorkRequest button
      this.view.ui.add([
        {
          component: this.createWorkRequestBtnEl.nativeElement,
          position: 'top-left',
          index: 3
        }
      ]);

      // Create a project button
      this.view.ui.add([
        {
          component: this.createProjectBtnEl.nativeElement,
          position: 'top-left',
          index: 4
        }
      ]);

      // New feature workflow
      this.view.ui.add([
        {
          component: this.addFeatureWorkflowEl.nativeElement,
          position: 'top-left',
          index: 3
        }
      ]);

      // Move the zoom widget to be on the same side as the other widgets
      if (this._widgetPosition != 'top-left') {
        this._view.ui.move('zoom', this._widgetPosition);
      }

      //
      // wait for the map to load
      //
      await this._view.when(() => {
      });
      this.loaded = this._view.ready;

      //return this._view;
      return this.view;
    }
    catch (error) {
      console.error('Esri-Loader: ', error);
    }
  }

  public exitFullscreen() {
    if (this._fullscreenWidget.viewModel.state === 'active') {
      this._fullscreenWidget.viewModel.exit();
    }
    //includeGalleryInsideMap = false
  }

  public async createSwipeWidget(leadingLayers: any[], trailingLayers: any[], swipeStartPosition?: number) {
    // Create a Swipe Widget
    const options = {
      version: Esri.apiVersion
    };

    setDefaultOptions(options);

    const [
      Swipe
    ] = await loadModules([
      "esri/widgets/Swipe"
    ]);

    let position: number;

    if (swipeStartPosition) {
      position = swipeStartPosition;
    }
    else {
      position = this._swipeStartPosition
    }

    this.swipeWidget = new Swipe({
      leadingLayers,
      trailingLayers,
      position,
      view: this._view
    });

    this._view.ui.add(this.swipeWidget);

    return;
  }

  public async createLegendWidget(layerInfos: any[], position: string, index: number) {
    const options = {
      version: Esri.apiVersion
    };

    setDefaultOptions(options);

    const [
      Legend
    ] = await loadModules([
      "esri/widgets/Legend"
    ]);

    this.legendWidget = new Legend({
      view: this._view,
      layerInfos
    });

    // type "classic"|"card"
    // layout "auto"|"side-by-side"|"stack"

    this.legendWidget.style = {
      type: "card",
      layout: "auto"
    };

    // Add it to the view
    this._view.ui.add([
      {
        component: this.legendWidget,
        position,
        index
      }
    ]);
  }

  //
  // Slide Toggles
  //

  // Zoom to feature
  public onToggleZoomToFeatureChange(event) {
    if (event.checked == true) {
      this.zoomToFeature = true;
      this._panToFeature_previous = this.panToFeature;
      this.panToFeature = true;
    }
    else {
      this.zoomToFeature = false;
      this.panToFeature = this._panToFeature_previous;
    }
  }

  // Pan to Feature
  public onTogglePanToFeatureChange(event) {
    if (event.checked == true) {
      this.panToFeature = true;
    }
    else {
      this.panToFeature = false;
    }
  }

  // Add button
  public addBtnEvent() {
    //this.currentModeService.currentModeData.next('add');
    let currentModeData: CurrentModeData = {
      formName: 'FORM',
      mode: 'add'
    }
    this.currentModeService.currentModeData.next(currentModeData);
  }

  public reportBtnEvent(event) {
    this.reportBtnSelectedEvent.emit(event);
    //this.currentModeService.currentModeData.next('add');
  }

  public createWorkRequestBtnEvent(event) {
    this.createWorkRequestBtnSelectedEvent.emit(event);
  }

  public createProjectBtnEvent(event) {
    this.createProjectBtnSelectedEvent.emit(event);
  }

  public locationEvent(mode: string) {
    let currentModeData: CurrentModeData = {
      formName: 'FORM',
      mode
    }
    this.currentModeService.currentModeData.next(currentModeData);
  }


  // public addressSelectedEvent(event) {
  //   this.addressSelectBtnDisabled = false;
  // }

}
