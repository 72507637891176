import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { startWith, map, debounceTime, switchMap, catchError } from 'rxjs/operators';
import { SuggestResponse } from 'src/app/_esri/models/esri';
import { GeocodeService } from 'src/app/_esri/services/geocode.service';
import { GeocodeDataService } from 'src/app/_globals/services/geocode-data.service';
//import { CurrentModeService, CurrentModeData } from 'src/app/_globals/services/current-mode.service';

@Component({
  selector: 'app-esri-address-search',
  templateUrl: './esri-address-search.component.html',
  styleUrls: ['./esri-address-search.component.scss']
})
export class EsriAddressSearchComponent implements OnInit {

  public geocodeSuggestAutoComplete$: Observable<any> = null;
  public autoCompleteControl = new FormControl();
  private _executeGeocode: boolean = true;
  private _featureForm: any = null;
  private _field: any;
  private _idx: any;
  private _label: string = 'Search address...'
  private _readOnly: boolean = false; 

  @Input()
  set executeGeocode(executeGeocode: boolean) {
    this._executeGeocode = executeGeocode;
  }

  get executeGeocode(): boolean {
    return this._executeGeocode;
  }

  @Input()
  set featureForm(featureForm: any) {
    this._featureForm = featureForm;
  }

  get featureForm(): any {
    return this._featureForm;
  }

  @Input()
  set field(field: any) {
    this._field = field;
  }

  get field(): any {
    return this._field;
  }

  @Input()
  set idx(idx: any) {
    this._idx = idx;
  }

  get idx(): any {
    return this._idx;
  }
  
  @Input()
  set label(label: string) {
    this._label = label;
  }

  get label(): string {
    return this._label;
  }

  @Input()
  set readOnly(readOnly: boolean) {
    this._readOnly = readOnly;
  }

  get readOnly(): boolean {
    return this._readOnly;
  }

  constructor(
    private geocodeService: GeocodeService,
    private geocodeDataService: GeocodeDataService,
    //public currentModeService: CurrentModeService,
  ) { }

  ngOnInit(): void {
    this.setFieldGeocode();
    //console.log('xxxxxx field xxxxxxxx', this.field, this._featureForm);
  }

  // Set up geocode field
  public setFieldGeocode() {
    //console.log('Setting up geocode field');
    if (this.field) {
      for (let i = 0; i < this._featureForm.controls.formArray.controls.length; i++) {
        if (this._featureForm.controls.formArray.controls[i].controls.hasOwnProperty(this.field.name)) {
          this.autoCompleteControl = this._featureForm.controls.formArray.controls[i].get(this.field.name);
        }
      }
    }

    this.geocodeSuggestAutoComplete$ = this.autoCompleteControl.valueChanges.pipe(
      startWith(''),
      // delay emits
      debounceTime(300),
      // use switch map so as to cancel previous subscribed events, before creating new once
      switchMap( value => {
        if (value !== '') {
          // lookup from world geocoder
          return this.lookupAddress(value as string);
        } 
        else {
          // if no value is pressent, return null
          return of(null);
        }
      })
    );
  }

  // Run the autocomplete lookup
  public lookupAddress(value: string): Observable<SuggestResponse> {
    // Wait for at least 3 chars before attempting an auto complete
    if (value.length > 3) {
      this.geocodeService.searchAddress = value;

      return this.geocodeService.suggest().pipe(
        map( (results) => 
          results.suggestions 
        ),
        // catch errors
        catchError( _ => {
          return of(null);
        })
      );
    }
    else {
      return of(null);
    }
  }

  // Address Selected Event
  public onAddressSelected(event): void  {
    //console.log('magicKey', event.option._element.nativeElement.magicKey);

    // Get the magicKey for the address and emit the result
    this.geocodeService.magicKey = event.option._element.nativeElement.magicKey;
    //this.magicKeyEvent.emit(this.geocodeService.magicKey);

    // Geocode the address (if enabled) and emit the results
    if (this._executeGeocode === true) {
      this.geocodeService.geocode(true).subscribe(
        data => { 
          console.log('geocode data received is: ', data);
          this.geocodeDataService.geocodeData.next(data);
        },
        error => {
          console.error('There was an error! [EsriAddressSearchComponent onAddressSelected()]', error);
          this.geocodeDataService.geocodeData.next(null);
        }
      );
    }
  }

  public clearField() {
    this.autoCompleteControl.setValue('');
    this.geocodeDataService.geocodeData.next(null);
  }

  // public acceptResult() {
  //   //this.addressAcceptedEvent.emit();
  //   let currentModeData: CurrentModeData = {
  //     formName: 'FORM',
  //     mode: 'location-selected'
  //   }
  //   this.currentModeService.currentModeData.next(currentModeData);
  // }

}
