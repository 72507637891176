import { Injectable } from '@angular/core';
import { LocalStorage } from 'ngx-webstorage';
import { Esri } from 'src/app/_esri/models/esri';
import { LayerIds } from 'src/app/_esri/models/layer-ids';
import { setDefaultOptions, loadModules } from 'esri-loader';

@Injectable({
  providedIn: 'root'
})

export class TokenRegisterService {

  @LocalStorage()
  public access_token;

  constructor() { }

  async registerToken() {

    const options = {
      version: Esri.apiVersion
  };

  setDefaultOptions(options);
  
    const [
      IdentityManager
    ] = await loadModules([
      'esri/identity/IdentityManager'
    ]);

    //console.log('Registering: ', GlobalValues._accessToken);
    console.log('Registering token:', this.access_token);

    // Feature Services
    IdentityManager.registerToken({
      'server': LayerIds.gisServer_AGOL,
      //'token': Esri.accessToken
      'token': this.access_token
    });

    // Elevation Services / Tiles
    IdentityManager.registerToken({
      'server': LayerIds.customElevationUrl,
      //'token': Esri.accessToken
      'token': this.access_token
    });

    // Base Scene 
    IdentityManager.registerToken({
      'server': 'https://www.arcgis.com/sharing/rest/content/items/' + LayerIds.portal_BaseScene,
      //'token': Esri.accessToken
      'token': this.access_token
    });

    return;
  }
}

























// import { Injectable } from '@angular/core';
// import { Esri } from 'src/app/_esri/models/esri';
// import { LayerIds } from 'src/app/_esri/models/layer-ids';
// import { setDefaultOptions, loadModules } from 'esri-loader';

// @Injectable({
//   providedIn: 'root'
// })

// export class TokenRegisterService {

//   public accessToken;

//   public options = {
//     version: Esri.apiVersion
//   };

//   constructor() { }

//   async registerToken() {

//     setDefaultOptions(this.options);

//     const [
//       OAuthInfo,
//       IdentityManager,
//       ServerInfo
//     ] = await loadModules([
//       "esri/identity/OAuthInfo",
//       'esri/identity/IdentityManager',
//       "esri/identity/ServerInfo"
//     ]);


//     // const oAuthClientId = LayerIds.oAuthClientId;

//     // let oAuthInfo = new OAuthInfo({
//     //   appId: oAuthClientId,
//     //   popup: false
//     // });

//     // IdentityManager.registerOAuthInfos([oAuthInfo]);

    


//       // var serverInfo1 = new ServerInfo();
//       // serverInfo1.server = 'https://www.arcgis.com/sharing/rest/content/items/5f22eeebd8494f9e999a755835294827';
//       // //serverInfo.tokenServiceUrl = "http://sampleserver6.arcgisonline.com/arcgis/tokens/generateToken";
//       // serverInfo1.hasPortal = true;

//       // var serverInfo2 = new ServerInfo();
//       // serverInfo2.server = 'https://tiles.arcgis.com/tiles/GEY7BOibXkcbWcpS/arcgis/rest';
//       // //serverInfo2.tokenServiceUrl = "http://sampleserver6.arcgisonline.com/arcgis/tokens/generateToken";
//       // serverInfo2.hasPortal = true;

//       // // var serverInfo1 = new ServerInfo();
//       // // serverInfo1.server = LayerIds.gisServer_AGOL;
//       // // //serverInfo.tokenServiceUrl = "http://sampleserver6.arcgisonline.com/arcgis/tokens/generateToken";
//       // // serverInfo1.hasPortal = true;

//       // IdentityManager.registerServers([serverInfo1, serverInfo2]);
  





//     //console.log('Registering: ', GlobalValues._accessToken);
//     console.log('Registering token', this.accessToken);
//     //console.log('Registering token', accessToken);

//     // Feature Services
//     IdentityManager.registerToken({
//       'server': LayerIds.gisServer_AGOL,
//       'token': this.accessToken
//      //'token': accessToken
//     });

//     // Elevation Services / Tiles
//     IdentityManager.registerToken({
//       'server': LayerIds.customElevationUrl,
//       'token': this.accessToken
//       //'token': accessToken
//     });

//     //     // Base Scene 
//     // IdentityManager.registerToken({
//     //   'server': 'https://www.arcgis.com/sharing/rest/content/items/5f22eeebd8494f9e999a755835294827',
//     //   //GET https:/ /      www.arcgis.com/sharing/rest/content/items/5f22eeebd8494f9e999a755835294827?f=json 
//     //   //'token': Esri.accessToken
//     //   'token': this.accessToken
//     // });

//     // IdentityManager.registerToken({
//     //   'server': 'https://tiles.arcgis.com/tiles/GEY7BOibXkcbWcpS/arcgis/rest/services/Tree/SceneServer',
//     //   //'token': Esri.accessToken
//     //   'token': this.accessToken
//     // });



    

    

//     // // Base Scene 
//     // IdentityManager.registerToken({
//     //   'server': 'https://www.arcgis.com/sharing/rest/content/items/' + LayerIds.portal_BaseScene,
//     //   //'token': Esri.accessToken
//     //   'token': this.accessToken
//     // });

//     return true;
//   }
// }
