import { Component, OnInit, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { addDays, addBusinessDays } from 'date-fns';
import { MatDialog } from '@angular/material/dialog';
import { BaseFeaturePage } from 'src/app/_esri/models/base-feature-page';
import { FeaturesService } from 'src/app/_esri/services/features.service';
import { CourseService } from 'src/app/_course/services/course.service';
import { SurroundsService } from 'src/app/_course/services/surrounds.service';
import { GeocodeService } from 'src/app/_esri/services/geocode.service';
import { DashboardChart, Address, EsriGeometryType } from 'src/app/_esri/models/esri';
//import { WorkRequestService } from 'src/app/maintenance/services/work-request.service';
//import { Options, LabelType, ChangeContext } from '@angular-slider/ngx-slider';
import { Global } from '../_globals/models/global';
import { CurrentModeService, CurrentModeData } from 'src/app/_globals/services/current-mode.service';
import { GeocodeDataService } from 'src/app/_globals/services/geocode-data.service';
import { FormChangeService } from 'src/app/_globals/services/form-change.service';
import { FormFieldChangeService } from 'src/app/_globals/services/form-field-change.service';
import { FormFieldUpdateService } from 'src/app/_globals/services/form-field-update.service';
import { WorkRequest, WorkRequestSubCategoryLOV } from 'src/app/maintenance/models/work-request';
import { DomainLovService } from 'src/app/_esri/services/domain-lov.service';
import { LovDataService } from 'src/app/_globals/services/lov-data.service';
import { ButtonClickService } from 'src/app/_globals/services/button-click.service';

//import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: [
    './maintenance.component.scss',
    '../_styles/base-feature-page.scss'
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class MaintenanceComponent extends BaseFeaturePage implements OnInit, OnDestroy, AfterViewInit {

  protected featureLayerObject_SubCat: any;   // Object for Sub Category Defaults

  // A feature record can have multiple locations
  geometryType = EsriGeometryType.MultiPoint;

  title = 'Maintenance';
  subTitle = 'Maintenence and Work Requests';
  idType = 'MAINT';
  idTypeFieldName = 'WorkRequestId';
  showTimeSliderWidget = true;
  dateFilterAttributeName = 'WorkRequestDate';
  forceFieldVisibleInTable = true;

  // Assign Object Type
  //attributes: WorkRequestAttributes[] = [];

  showDataTable = false;
  showDataCards = true;
  //showBaseLayers = true;
  darkMode = false;

  courseLayerOpacity = 0.3;
  surroundsLayerOpacity = 0.1;
  boundaryLayerOpacity = 0.3;
  hillshadeLayerOpacity = 0.05;

  parentLovWatchList = [{ parentLovKey: 'WorkRequestCategoryId', childLovName: 'LovWorkRequestSubCategory', childLovKey: 'WorkRequestSubCategoryId' }];
  fieldChangeWatchList = ['Status', 'CompletedDate', 'WorkRequestSubCategoryId'];

  popupMenuNumberItems: string = '1';
  popupMenuNumberItems_selectedFeature: string = '4';
  popupMenuWidth: string = '300';

  dashboardCharts: DashboardChart[] = [
    { chartName: 'STATUS' },
    { chartName: 'FAIRWAY' },
    { chartName: 'PERFORMANCE' },

    { chartName: 'REPORTED-BY' },
    { chartName: 'PRIORITY' },
    { chartName: 'SOURCE' },

    { chartName: 'CATEGORY' },
    { chartName: 'SUB-CATEGORY' },
    { chartName: 'RESPONSIBILITY' },
    { chartName: 'ASSIGNED' },

    //{ chartName: 'OVERDUE' },
    //{ chartName: 'OVERDUE-OPEN' },
  ];

  dashboardCards: DashboardChart[] = [
    // infoBoxKpi='Total' infoBoxTitle='Total' infoBoxValue='234' infoBoxDescription='Total number of work requests'></app-esri-info-box>
    { chartName: 'CARD-TOTAL', chartType: 'info-box', label: 'Total', kpi: 'Total', description: 'Total number of work requests', summaryData: [''] },
    { chartName: 'CARD-OPEN', chartType: 'info-box', label: 'Open', kpi: 'Open', description: 'Total number of open work requests', summaryData: [''] },
    { chartName: 'CARD-CLOSED', chartType: 'info-box', label: 'Closed', kpi: 'Closed', description: 'Total number of closed work requests', summaryData: [''] },
    { chartName: 'CARD-OVERDUE', chartType: 'info-box', label: 'Open & Overdue', kpi: 'Overdue', description: 'Open work requests that exceed the SLA', summaryData: [''] },
    { chartName: 'CARD-INSLA', chartType: 'info-box', label: 'Closed in SLA', kpi: 'InSLA', description: 'Work requests completed within SLA', summaryData: [''] },
    { chartName: 'CARD-OUTSLA', chartType: 'info-box', label: 'Closed out of SLA', kpi: 'OutSLA', description: 'Work requests not completed within SLA', summaryData: [''] },
    { chartName: 'CARD-PERFORMANCE', chartType: 'info-box', label: 'Performance', kpi: 'Performance', description: 'SLA performance of completed requests', summaryData: [''] }
  ]

  public cardWidth = 100 / this.dashboardCards.length;

  constructor(
    protected ref: ChangeDetectorRef,
    // protected featuresService: FeaturesService,
    // protected courseService: CourseService,
    // protected surroundsService: SurroundsService,
    protected dialog: MatDialog,
    protected currentModeService: CurrentModeService,
    protected geocodeDataService: GeocodeDataService,
    protected formChangeService: FormChangeService,
    protected formFieldChangeService: FormFieldChangeService,
    protected formFieldUpdateService: FormFieldUpdateService,
    // protected lovDataService: LovDataService,
    // protected domainLovService: DomainLovService,
    // protected buttonClickService: ButtonClickService,
    protected elementRef: ElementRef,
    // ----
    //private workRequestService: WorkRequestService,
    private geocodeService: GeocodeService,
  ) {
    super(
      ref,
      // featuresService,
      // courseService,
      // surroundsService,
      dialog,
      currentModeService,
      geocodeDataService,
      formChangeService,
      formFieldChangeService,
      formFieldUpdateService,
      // lovDataService,
      // domainLovService,
      //buttonClickService,
      elementRef
    );
  }

  ngOnInit(): void {
    this.thinking = true;
    // Get WorkRequest layers, features, lovs, domains, etc
    this.featureLayerObject = new WorkRequest();

    // Get Sub Category Details - this has the default SLA, assignment, responsibility, and work force integration info
    this.featureLayerObject_SubCat = new WorkRequestSubCategoryLOV();

    this.featureLayerObject_SubCat.featureLayerLoadedEvent.pipe(takeUntil(this.ngUnsubscribe)).subscribe((response: any) => {
      this.featureLayerObject_SubCat.updateVirtualFields();
      //console.log('featureLayerObject_SubCat data ready', this.featureLayerObject_SubCat.attributes);
    });

    // Load the Sub Category Feature Layer (table, so doesn't need to wait for the view to be ready)
    this.featureLayerObject_SubCat.getLovs();
    //this.featureLayerObject_SubCat.getLayer();  
    this.featureLayerObject_SubCat.getFeatureLayerConfig();

    // Continue as per normal
    super.ngOnInit();
  }

  //
  // Get attributes from feature set and assign to class "featureAttributes", (view table, and view cards )- calc virtual fields
  //

  protected refreshDisplay() {
    this.featureLayerObject.updateVirtualFields();
    super.refreshDisplay();
  }

  // Build data for charts
  protected createCharts() {
    super.createCharts();
    //console.log('createCharts', this.featureLayerObject.attributes, this.featureLayerObject.features, this.featureLayerObject.domains, this.featureLayerObject.domains_lov);

    const total = this.createCardDataSet_Count(this.featureLayerObject.attributes);
    //console.log('createCharts total', total);

    const inSla: any = this.createCardDataSet_Count(this.featureLayerObject.attributes.filter(x => x.CompletedWithinSLA === 'YES'));
    //console.log('createCharts inSla', inSla);

    const performance: any = (total > 0) ? ((inSla / total) * 100).toFixed(0) : 0;
    //console.log('createCharts performance', performance);

    // Info-box Cards
    this.dashboardCards.find(x => x.chartName == 'CARD-TOTAL').summaryData = [total];
    this.dashboardCards.find(x => x.chartName == 'CARD-OPEN').summaryData = [this.createCardDataSet_Count(this.featureLayerObject.attributes.filter(x => x.Status === 2))];           // Open
    this.dashboardCards.find(x => x.chartName == 'CARD-CLOSED').summaryData = [this.createCardDataSet_Count(this.featureLayerObject.attributes.filter(x => x.Status === 3))];         // Closed
    this.dashboardCards.find(x => x.chartName == 'CARD-OVERDUE').summaryData = [this.createCardDataSet_Count(this.featureLayerObject.attributes.filter(x => x.Overdue === 'YES'))];   // Open and Overdue
    this.dashboardCards.find(x => x.chartName == 'CARD-INSLA').summaryData = [inSla];
    this.dashboardCards.find(x => x.chartName == 'CARD-OUTSLA').summaryData = [this.createCardDataSet_Count(this.featureLayerObject.attributes.filter(x => x.CompletedWithinSLA === 'NO'))];
    this.dashboardCards.find(x => x.chartName == 'CARD-PERFORMANCE').summaryData = [performance];

    //  // Charts and Graphs
    this.dashboardCharts.find(x => x.chartName == 'STATUS').summaryData = this.createChartDataSet('DomStatus', 'Status', true);
    this.dashboardCharts.find(x => x.chartName == 'FAIRWAY').summaryData = this.createChartDataSet('DomFairway', 'FairwayNumber', true);
    this.dashboardCharts.find(x => x.chartName == 'PERFORMANCE').summaryData = [{ name: 'Performance', value: performance }];

    this.dashboardCharts.find(x => x.chartName == 'REPORTED-BY').summaryData = this.createChartDataSet('LovWorkRequestReportedBy', 'ReportedBy', true);
    this.dashboardCharts.find(x => x.chartName == 'PRIORITY').summaryData = this.createChartDataSet('DomPriority', 'Priority', true);
    this.dashboardCharts.find(x => x.chartName == 'SOURCE').summaryData = this.createChartDataSet('DomSource', 'Source', true);

    this.dashboardCharts.find(x => x.chartName == 'CATEGORY').summaryData = this.createChartDataSet('LovWorkRequestCategory', 'WorkRequestCategoryId', true);
    this.dashboardCharts.find(x => x.chartName == 'SUB-CATEGORY').summaryData = this.createChartDataSet_SubCategory(this.featureLayerObject.attributes, 'WorkRequestCategoryId', 'Category', 'WorkRequestSubCategoryId', 'SubCategory');

    this.dashboardCharts.find(x => x.chartName == 'RESPONSIBILITY').summaryData = this.createChartDataSet('LovStaff', 'Responsible', true);
    this.dashboardCharts.find(x => x.chartName == 'ASSIGNED').summaryData = this.createChartDataSet('LovStaff', 'AssignedTo', true);




    //selectChart($event, 'CATEGORY', 'LovWorkRequestCategory', 'WorkRequestCategoryId', true)




    //this.dashboardCharts.find(x => x.chartName == 'OVERDUE-OPEN').summaryData = this.createChartDataSet_Overdue('SLADueDate', 'NOW', 'Open and Overdue', 'Status', 2);    // Open and Overdue
    //this.dashboardCharts.find(x => x.chartName == 'OVERDUE').summaryData = this.createChartDataSet_Overdue('SLADueDate', 'CompletedDate', 'Overdue');    // Overdue

    console.log('createCharts() this.dashboardCharts', this.dashboardCharts);

    // chartName: string;
    // chartType?: string;
    // label?: string;
    // description?: string;
    // kpi?: string;
    // visibleChip?: boolean;
    // clause?: string;
    // summaryData?: any[];
  }

  //
  // Workflow - Control button and editor behaviour based on current feature edit mode
  //

  protected setCurrentMode(currentModeData: CurrentModeData) {
    super.setCurrentMode(currentModeData);

    // Only process parent forms here.  Child forms (with names other than FORM) should be processed in extended class
    if (currentModeData.formName.toUpperCase() === 'FORM') {

      let mode = currentModeData.mode.toLowerCase();

      switch (mode) {
        case 'add':
          this.esriMapComponent.locationHidden = true;
          this.esriMapComponent.locationMap = true;
          this.esriMapComponent.locationAddress = false;
          break;

        case 'add-popup':
          break;

        case 'location-map':
          //this.setFormFields_OnPremises('YES');
          break;

        case 'location-address':
          //this.setFormFields_OnPremises('NO');
          break;

        case 'location-selected':
        case 'location-changed':
          this.featuresService.checkWithinLayer(this.boundaryLayer, this.geometry).then(onPremises => {
            // Lookup the Fairway Number
            this.featuresService.getAttributeWithinLayer(this.fairwayLayer, this.geometry, 'FairwayNumber').then((fairwayNumber) => {
              //console.log('**** fairwayNumber **** ', fairwayNumber);
              let value;

              // Set value if only 1 record is found (ie not 'ERROR%'), otherwise set to 0
              if (isNaN(+fairwayNumber)) {
                // 'ERROR-0'
                // 'ERROR-2'
                // 'ERROR-D'
                value = 0;    // Fairway = None
              }
              else {
                value = fairwayNumber;
              }

              this.defaultFieldValues.push({
                name: 'FairwayNumber',
                value
              });

              // Show form
              if (mode === 'location-changed') {
                // Mark the form dirty
                this.openEditFeatureDialog(mode, true);
              }
              else {
                this.openEditFeatureDialog(mode);
              }
            });

            // Hide the workflow box
            this.esriMapComponent.locationMap = false;
            this.esriMapComponent.locationAddress = false;
          });

          break;

        case 'edit-attribute':
          this.openEditFeatureDialog(mode);
          break;

        case 'form-submitted':
          // if (this.lastrecordId) {
          //   let fieldValue: any[] = [];
          //   let fieldUpdateData = {};

          //   fieldValue.push({
          //     name: this.idTypeFieldName,
          //     value: this.lastrecordId
          //   });

          //   fieldUpdateData = {
          //     updateType: 'value',
          //     data: fieldValue
          //   }

          //   if (fieldUpdateData) {
          //     this.formFieldUpdateService.formFieldUpdateData.next(fieldUpdateData);
          //   }
          // }
          break;
      }
    }

  }

  //
  // Form Changes Events
  //

  protected featureFormFieldChangeEvent(event) {
    //console.log('featureFormFieldChangeEvent', event);
    super.featureFormFieldChangeEvent(event);

    let fieldVisibility: any[] = [];
    let fieldValue: any[] = [];
    let fieldUpdateData = {};

    switch (event.fieldName.toUpperCase()) {

      case 'STATUS':
        //Status 
        if (event.newValue === 3) { // Closed
          // Set the closed date to the current date
          fieldValue.push({
            name: 'CompletedDate',
            value: Date.now()
          });

          fieldUpdateData = {
            updateType: 'value',
            data: fieldValue
          }
        }
        else if (event.newValue === 2) {    // Open
          // Clear the value in the date closed field
          fieldValue.push({
            name: 'CompletedDate',
            value: null
          });

          fieldUpdateData = {
            updateType: 'value',
            data: fieldValue
          }
        }
        else {
          fieldUpdateData = null;
        }
        break;

      case 'COMPLETEDDATE':
        //CompletedDate
        if ((this.selectedFeature && this.selectedFeature.attributes.Status === 2) || (!this.selectedFeature)) {
          fieldValue.push({
            name: 'Status',
            value: 3
          });

          fieldUpdateData = {
            updateType: 'value',
            data: fieldValue
          }
        }
        else {
          fieldUpdateData = null;
        }
        break;

      case 'WORKREQUESTSUBCATEGORYID':
        //WorkRequestSubCategoryId

        //event.fieldName
        //event.newValue
        //event.parentFieldName
        //event.parentValue

        let subCatInfo = this.featureLayerObject_SubCat.attributes.find(x => x.CategoryId === event.parentValue && x.SubCategoryId === event.newValue);
        //console.log('featureFormFieldChangeEvent() subCatInfo', subCatInfo );

        if (event.parentValue) {
          if ((this.selectedFeature && !this.selectedFeature.attributes.Responsible) || (!this.selectedFeature)) {
            fieldValue.push({
              name: 'Responsible',
              value: subCatInfo.DefaultResponsible
            });
          }

          if ((this.selectedFeature && !this.selectedFeature.attributes.AssignedTo) || (!this.selectedFeature)) {
            fieldValue.push({
              name: 'AssignedTo',
              value: subCatInfo.DefaultAssignedTo
            });
          }

          if ((this.selectedFeature && !this.selectedFeature.attributes.SLADays) || (!this.selectedFeature)) {
            fieldValue.push({
              name: 'SLADays',
              value: subCatInfo.SLADays
            });
          }

          if (this.selectedFeature && !this.selectedFeature.attributes.SLADueDate) {
            //addBusinessDays(date, amount)
            //addDays(date, amount)
            let value;

            if (subCatInfo.SLABusinessDays === 'YES') {
              value = addBusinessDays(this.selectedFeature.attributes.WorkRequestDate, subCatInfo.SLADays);
            }
            else {
              value = addDays(this.selectedFeature.attributes.WorkRequestDate, subCatInfo.SLADays);
            }

            fieldValue.push({
              name: 'SLADueDate',
              value
            });
          }



          // if (subCat_WorkRequest.SLABusinessDays === 'YES') {
          //   this._workRequestAttributes.SLADueDate = addBusinessDays(this._workRequestAttributes.WorkRequestDate, subCat_WorkRequest.SLADays);
          // }
          // else {
          //   this._workRequestAttributes.SLADueDate = addDays(this._workRequestAttributes.WorkRequestDate, subCat_WorkRequest.SLADays);
          // }



          if (!this.selectedFeature) {
            //addBusinessDays(date, amount)
            //addDays(date, amount)
            fieldValue.push({
              name: 'SLADueDate',
              value: addDays(Date.now(), subCatInfo.SLADays)
            });
          }

          if ((this.selectedFeature && !this.selectedFeature.attributes.WorkForce_AssignmentGlobalId) || (!this.selectedFeature)) {
            fieldValue.push({
              name: 'WorkForce_AssignmentGlobalId',
              value: subCatInfo.WorkForce_AssignmentGlobalId
            });
          }

          if (fieldValue) {
            console.log('updates', fieldValue);
            fieldUpdateData = {
              updateType: 'value',
              data: fieldValue
            }
          }
          else {
            fieldUpdateData = null;
            console.log('NO updates', fieldValue);
          }
        }
        break;

      default:
        console.log('Form Field Change not configured for this field', event);
        break;
    }

    // Update the field value / visibility in the current form
    console.log('Form Field Change', fieldUpdateData);
    if (fieldUpdateData) {
      this.formFieldUpdateService.formFieldUpdateData.next(fieldUpdateData);
    }
  }

}
