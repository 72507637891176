import { Component, OnInit, Inject } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { Global } from 'src/app/_globals/models/global';

export interface BottomSheetData {
  title: string,
  gameGender: string,
  gameTeeMarkerType: string,
  gameDriveLength: string,
  gameFairwayNumber: number
}

@Component({
  selector: 'app-hole-gallery',
  templateUrl: './hole-gallery.component.html',
  styleUrls: ['./hole-gallery.component.scss']
})
export class HoleGalleryComponent implements OnInit {

  private _assetHostServer = Global.assetHostServer;

  public pathCourse_thumbnail: string = '';
  public pathCourse_jpg: string = '';
  public pathCourse_pdf: string = '';

  public pathPuttingGreen_thumbnail: string = this._assetHostServer + 'assets/images/putting-green/';
  public pathPuttingGreen_jpg: string = this._assetHostServer + 'assets/downloads/map-gallery/putting-green/';
  public pathPuttingGreen_pdf: string = this._assetHostServer + 'assets/downloads/map-gallery/putting-green/';

  public pathGamePlay_thumbnail: string = this._assetHostServer + 'assets/images/hole-thumbnails/';
  public pathGamePlay_jpg: string = this._assetHostServer + 'assets/downloads/map-gallery/game/';
  public pathGamePlay_pdf: string = this._assetHostServer + 'assets/downloads/map-gallery/game/';

  public pathFairway_thumbnail: string = '';
  public pathFairway_jpg: string = '';
  public pathFairway_pdf: string = '';

  public filenameCourse_thumbnail: string = '';
  public filenameCourse_jpg: string = '';
  public filenameCourse_pdf: string = '';

  public filenamePuttingGreen_thumbnail: string = '';
  public filenamePuttingGreen_jpg: string = '';
  public filenamePuttingGreen_pdf: string = '';

  public filenameGamePlay_thumbnail: string = '';
  public filenameGamePlay_jpg: string = '';
  public filenameGamePlay_pdf: string = '';

  public filenameFairway_thumbnail: string = '';
  public filenameFairway_jpg: string = '';
  public filenameFairway_pdf: string = '';

  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: BottomSheetData
  ) { }

  ngOnInit(): void {

    // Set the filenames
    //let _fileName = 'GREEN_' + this.data.gameFairwayNumber;
    //let _fileName = 'GREEN_THUMBNAIL_' + this.data.gameFairwayNumber;
    let _fileName = 'GREEN_' + this.data.gameFairwayNumber;
    let _fileNameThumbnail = 'GREEN_THUMBNAIL_' + this.data.gameFairwayNumber;

    //this.filenamePuttingGreen_thumbnail = this.pathPuttingGreen_thumbnail + _fileName + '_thumbnail.png';
    this.filenamePuttingGreen_thumbnail = this.pathPuttingGreen_thumbnail + _fileNameThumbnail + '.png';   ////GREEN_THUMBNAIL_1 



    this.filenamePuttingGreen_jpg = _fileName + '.jpg';
    this.filenamePuttingGreen_pdf = _fileName + '.pdf';

    _fileName = this.data.gameTeeMarkerType + '_' + this.data.gameDriveLength + '_' + this.data.gameGender + '_' + this.data.gameFairwayNumber;

    console.log('_fileName', _fileName);

    //this.filenameGamePlay_thumbnail = this.pathGamePlay_thumbnail + _fileName + '_thumbnail.jpg';
    //this.filenameGamePlay_thumbnail = this.pathGamePlay_thumbnail + 'Hole' + this.data.gameFairwayNumber + 'Thumbnail.jpg';
    this.filenameGamePlay_thumbnail = this.pathGamePlay_thumbnail + 'FAIRWAY_THUMBNAIL_' + this.data.gameFairwayNumber + '.png';   //FAIRWAY_THUMBNAIL_1
    this.filenameGamePlay_jpg = _fileName + '.jpg';
    this.filenameGamePlay_pdf = _fileName + '.pdf';

  }

}
