<ng-scrollbar #scrollable>
    <span *ngFor='let card of cards; let idx = index;'>       
        <mat-list>
            <div class='sidebar-card' [ngClass]='{"sidebar-card-highlight": selectedRow === card.attribute["OBJECTID"]}' (click)='cardSelected(card.attribute, "TABLE");'>
                <mat-card class='sidebar-card-content'>  
                        <mat-card-header>
                            <div mat-card-avatar class="">
                                <button mat-icon-button *ngIf="showEditActionBtn" aria-label="Button with a edit icon" (click)='$event.stopPropagation(); editActionBtnSelected(card.attribute)'>
                                    <mat-icon class='material-icons-sharp row-action-icon'>edit</mat-icon>
                                </button>
                            </div>             
                            <mat-card-title>
                                <span [ngClass]="{'sidebar-card-title-overdue' : card.attribute['Overdue'] === 'YES', 'sidebar-card-title' : !card.attribute['Overdue']}">
                                    {{card.title}} 
                                </span>
                            </mat-card-title>
                            <mat-card-subtitle>
                                <div fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="start center" class='sidebar-card-sub-title' [innerHTML]='card.subTitle'></div>
                            </mat-card-subtitle>              
                        </mat-card-header>
                        <mat-card-content>
                            <!-- Row 1 -->
                            <div fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="start center" class='sidebar-card-content-row' [innerHTML]='card.contentRow1'></div>
                            <!-- Row 2 -->
                            <div fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="start center" class='sidebar-card-content-row' [innerHTML]='card.contentRow2'></div>
                            <!-- Row 3 -->
                            <div fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="start center" class='sidebar-card-content-row' [innerHTML]='card.contentRow3'></div>
                        </mat-card-content>           
                </mat-card>
            </div>
        </mat-list>
    </span>
</ng-scrollbar>


