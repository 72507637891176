import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LocalStorage } from 'ngx-webstorage';
import { FeaturesService } from 'src/app/_esri/services/features.service';
import { NextId } from 'src/app/_esri/models/esri';

@Injectable({
  providedIn: 'root'
})

export class NextIdService {

  @LocalStorage()
  private _token: string;

  constructor(
    private featuresService: FeaturesService,
    private http: HttpClient
  ) { }

  private async loadNextIdTable(): Promise<any> {
    let featureLayer;
    let featureLayerProperties = NextId.createLayerPropertiesList();

    await this.featuresService.loadLayer(false, featureLayerProperties).then(layer => {
      featureLayer = layer;
    });

    return featureLayer;
  }

  public async getNextId(idType: string): Promise<any> {
    let whereClause: string = "IdType = '" + idType + "'";
    let nextNumber: number = 0;
    let formattedNumber: string;
    let feature: any;
    let layer: any;

    await this.loadNextIdTable().then(featureLayer => {
      layer = featureLayer;
    });

    // Define the query
    let queryTblNextId = layer.createQuery();
    queryTblNextId.where = whereClause;
    queryTblNextId.outFields = '*';

    // Execute the query and get all the data
    await layer.queryFeatures(queryTblNextId).then((response) => {
      feature = response.features[0];
      nextNumber = response.features[0].attributes.NextId;
      formattedNumber = response.features[0].attributes.Prefix + '-' + nextNumber.toString().padStart(6, '0');

      // Increment The Id by 1
      feature.attributes.NextId++;

      const edits = {
        updateFeatures: [feature]
      };

      // Update the Feature Service table to the next increment
      this.featuresService.applyEditsFeatureLayer(layer, edits);
    });

    //return nextNumber;
    return formattedNumber;
  }

  public async getNextId_API(idType: string): Promise<any> {
    let featureLayerProperties = NextId.createLayerPropertiesList();
    let url = featureLayerProperties.url;
    //let apiUrl = "https://map-test.crispora.com.au/api/next-id/get.php?url=" +  url + "&idType=" + idType + "&token=" + this._token;
    let apiUrl = "https://api.on-par-technology.com/next-id/get.php?url=" +  url + "&idType=" + idType + "&token=" + this._token;

    console.log('getNextId_API', apiUrl);

    //  Call the API
    const headers = new HttpHeaders({    
      'Content-Type': 'application/x-www-form-urlencoded'
    });

    try {
      return await this.http.get<any>(apiUrl, { headers }).toPromise();
    }
    catch (error) {
      console.error('error: ', error);
    }
  }
  
}