import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { MemberComponent } from './member.component';
import { MemberRoundsComponent } from 'src/app/member/components/member-rounds/member-rounds.component';
import { MemberOriginsComponent } from 'src/app/member/components/member-origins/member-origins.component';
import { MemberWaitingListComponent } from 'src/app/member/components/member-waiting-list/member-waiting-list.component';
import { MemberReciprocalComponent } from 'src/app/member/components/member-reciprocal/member-reciprocal.component';
import { MemberReciprocalRoundComponent } from 'src/app/member/components/member-reciprocal-round/member-reciprocal-round.component';

const routes: Routes = [
  { path: '', component: MemberComponent },
  { path: 'member-rounds', component: MemberRoundsComponent },
  { path: 'member-origins', component: MemberOriginsComponent },
  { path: 'member-waiting', component: MemberWaitingListComponent },
  { path: 'member-reciprocal', component: MemberReciprocalComponent },
  { path: 'member-reciprocal-rounds', component: MemberReciprocalRoundComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MemberRoutingModule { }
