import { Injectable, Output, EventEmitter } from '@angular/core';
import { combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Esri, FeatureLayerProperties, ElevationInfo, EsriRenderer_Point, FormFieldConfig, FieldConfig, FieldDateFormat, FieldNumberFormat, GeometryPoint } from 'src/app/_esri/models/esri';
import { LayerIds } from 'src/app/_esri/models/layer-ids';
import { Global } from 'src/app/_globals/models/global';
import { LovDataService } from 'src/app/_globals/services/lov-data.service';
import { DomainLovService } from 'src/app/_esri/services/domain-lov.service';
import { StaffLOV } from 'src/app/_esri/models/common-feature-class';
import { FeatureLayerObjectBaseAttributes, FeatureLayerObjectBase } from 'src/app/_esri/models/base-feature-class';

export interface RiskTitle {
  Register: string;
  Category: string;
  SubCategory: string;
  RiskTitle: string;
  RiskId: string;
  GlobalId: string;
}

export interface RiskTitleResponse {
  riskTitles: RiskTitle[];
}

export class RiskAttributes extends FeatureLayerObjectBaseAttributes {

  public RiskId: string;
  public RiskRegisterId: string;
  public RiskCategoryId: string;
  public RiskSubCategoryId: string;
  public RiskTitle: string;
  public DateRegistered: Date;
  public RiskDetails: string;
  public PotentialCauses: string;
  public PotentialConsequences: string;
  public InherentRisk_Likelihood: string;
  public InherentRisk_Consequence: string;
  public InherentRisk_Rating: string;
  public CurrentControls: string;
  public AdditionalControls: string;
  public DateDueAdditionalControls: Date;
  public RiskOwnerId: string;
  public RiskResponsibilityId: string;
  public ControlEffectiveness: string;
  public ControlStatus: string;
  public ResidualRisk_Likelihood: string;
  public ResidualRisk_Consequence: string;
  public ResidualRisk_Rating: string;
  public Comments: string;

  constructor(
    protected lovDataService: LovDataService,
    protected domainLovService: DomainLovService
  ) {
    super(
      lovDataService,
      domainLovService
    );
  }

  // Virtual Fields

  get RiskRegister(): string {
    if (this.RiskRegisterId) {
      return this.domainLovService.getDomainLovValue('LovRiskRegister', this.RiskRegisterId);
    }
    else {
      return null;
    }
  }

  get Category(): string {
    if (this.RiskCategoryId) {
      return this.domainLovService.getDomainLovValue('LovRiskCategory', this.RiskCategoryId);
    }
    else {
      return null;
    }
  }

  get SubCategory(): string {
    if (this.RiskCategoryId && this.RiskSubCategoryId) {
      return this.domainLovService.getSubDomainLovValue('LovRiskSubCategory', this.RiskCategoryId, this.RiskSubCategoryId);
    }
    else {
      return null;
    }
  }

  get InherentLikelihood(): string {
    if (this.InherentRisk_Likelihood) {
      return this.domainLovService.getDomainLovValue('LovRiskLikelihood', this.InherentRisk_Likelihood);
    }
    else {
      return null;
    }
  }

  get InherentConsequence(): string {
    if (this.InherentRisk_Consequence) {
      return this.domainLovService.getDomainLovValue('LovRiskConsequence', this.InherentRisk_Consequence);
    }
    else {
      return null;
    }
  }

  get InherentRiskRating(): string {
    if (this.InherentRisk_Rating) {
      return this.domainLovService.getDomainLovValue('LovRiskRating', this.InherentRisk_Rating);
    }
    else {
      return null;
    }
  }

  get OwnerStaffName(): string {
    if (this.RiskOwnerId) {
      return this.domainLovService.getDomainLovValue('LovStaff', this.RiskOwnerId);
    }
    else {
      return null;
    }
  };

  get ResponsibleStaffName(): string {
    if (this.RiskResponsibilityId) {
      return this.domainLovService.getDomainLovValue('LovStaff', this.RiskResponsibilityId);
    }
    else {
      return null;
    }
  };

  get ControlEffectiveness_DomainDesc(): string {
    if (this.ControlEffectiveness) {
      return this.domainLovService.getDomainValue('DomRiskControlEffectiveness', this.ControlEffectiveness);
    }
    else {
      return null;
    }
  };

  get ControlStatus_DomainDesc(): string {
    if (this.ControlStatus) {
      return this.domainLovService.getDomainValue('DomRiskControlStatus', this.ControlStatus);
    }
    else {
      return null;
    }
  };

  get ResidualLikelihood(): string {
    if (this.ResidualRisk_Likelihood) {
      return this.domainLovService.getDomainLovValue('LovRiskLikelihood', this.ResidualRisk_Likelihood);
    }
    else {
      return null;
    }
  }

  get ResidualConsequence(): string {
    if (this.ResidualRisk_Consequence) {
      return this.domainLovService.getDomainLovValue('LovRiskConsequence', this.ResidualRisk_Consequence);
    }
    else {
      return null;
    }
  }

  get ResidualRiskRating(): string {
    if (this.ResidualRisk_Rating) {
      return this.domainLovService.getDomainLovValue('LovRiskRating', this.ResidualRisk_Rating);
    }
    else {
      return null;
    }
  }
}

@Injectable()
export class Risk extends FeatureLayerObjectBase {
  public riskRegisterName: string;

  attributes: RiskAttributes[];
  server = LayerIds.gisServer_Risk;
  folder = LayerIds.folder_Risk;
  layerId = LayerIds.layerId_RiskRegister;
  id = 'featureLayerRiskRegister';
  title = 'Risk Register';

  minScale = 10000;
  whereClause = "1=1";

  labelInfo = {
    symbol: Esri.text12pxWithHalo,
    labelExpressionInfo: {
      expression: "$feature.RiskTitle"
    },
    maxScale: 0,
    minScale: 2000,
    where: "$feature.HasLocation = 'YES'"
  };

  labelsVisible = true;
  labelingInfo = this.labelInfo;

  @Output() categoryLovReadyEvent = new EventEmitter<any>();
  @Output() subCategoryLovReadyEvent = new EventEmitter<any>();
  @Output() staffLovReadyEvent = new EventEmitter<any>();
  @Output() registerLovReadyEvent = new EventEmitter<any>();
  @Output() likelihoodLovReadyEvent = new EventEmitter<any>();
  @Output() consequenceLovReadyEvent = new EventEmitter<any>();
  @Output() ratingLovReadyEvent = new EventEmitter<any>();

  protected async createFormFieldConfigs(): Promise<FormFieldConfig[]> {           // (featureLayerId: string)

    let formFieldConfig: FormFieldConfig;
    let fieldConfigs: FieldConfig[] = [];
    let fieldConfig: FieldConfig;
    let format: any;

    if (this.riskRegisterName == null) {
      this.riskRegisterName = Global.riskRegisterName;
    }

    // Description

    fieldConfigs = [];

    fieldConfig = new FieldConfig('RiskId', 'Risk Id:', 'text');
    fieldConfig.required = false;
    fieldConfig.readOnly = true;
    fieldConfig.maxLength = 20;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('RiskRegisterId', 'Risk Register:', 'text');
    fieldConfig.required = true;
    fieldConfig.maxLength = 20;
    fieldConfig.domain_lov = 'LovRiskRegister';
    fieldConfig.defaultValue = 'DEFAULT';
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('RiskCategoryId', 'Risk Category:', 'text');
    fieldConfig.required = true;
    fieldConfig.maxLength = 20;
    fieldConfig.includeInTable = true;
    fieldConfig.includeInTableIndex = 1;
    fieldConfig.domain_lov = 'LovRiskCategory';
    fieldConfig.domain_VirtualFieldName = 'Category';
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('RiskSubCategoryId', 'Risk Sub-category:', 'text');
    fieldConfig.required = false;
    fieldConfig.maxLength = 20;
    fieldConfig.includeInTable = true;
    fieldConfig.includeInTableIndex = 2;
    fieldConfig.domain_lovParent = 'LovRiskCategory';
    fieldConfig.domain_lovParent_FieldName = 'RiskCategoryId';
    fieldConfig.domain_lov = 'LovRiskSubCategory';
    fieldConfig.domain_VirtualFieldName = 'SubCategory';
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('RiskTitle', 'Title', 'text');
    fieldConfig.required = true;
    fieldConfig.maxLength = 200;
    fieldConfig.includeInTable = true;
    fieldConfig.includeInTableIndex = 0;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('DateRegistered', 'Date Registered:', 'date');
    fieldConfig.required = true;
    format = new FieldDateFormat();
    fieldConfig.format = format
    fieldConfig.defaultValue = 'date-now';
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('RiskDetails', 'Risk Details:', 'text-area');
    fieldConfig.required = true;
    fieldConfig.maxLength = 2000;
    fieldConfigs.push(fieldConfig);

    formFieldConfig = new FormFieldConfig('Description:', fieldConfigs);
    this.formFieldConfigs.push(formFieldConfig);

    //
    // Causes and Consequences
    //

    fieldConfigs = [];

    fieldConfig = new FieldConfig('PotentialCauses', 'Potential Causes:', 'text-area');
    fieldConfig.maxLength = 2000;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('PotentialConsequences', 'Potential Consequences:', 'text-area');
    fieldConfig.maxLength = 2000;
    fieldConfigs.push(fieldConfig);

    formFieldConfig = new FormFieldConfig('Causes', fieldConfigs);
    formFieldConfig.visible = true;
    this.formFieldConfigs.push(formFieldConfig);

    //
    // Inherent Risk
    //

    fieldConfigs = [];

    fieldConfig = new FieldConfig('InherentRisk_Likelihood', 'Inherent Risk Likelihood:', 'radio-v');
    fieldConfig.required = true;
    fieldConfig.maxLength = 20;
    fieldConfig.domain_lov = 'LovRiskLikelihood';
    fieldConfig.domain_VirtualFieldName = 'InherentLikelihood';
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('InherentRisk_Consequence', 'Inherent Risk Consequence:', 'radio-v');
    fieldConfig.required = true;
    fieldConfig.maxLength = 20;
    fieldConfig.domain_lov = 'LovRiskConsequence';
    fieldConfig.domain_VirtualFieldName = 'InherentConsequence';
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('InherentRisk_Rating', 'Inherent Risk Rating:', 'radio-v');
    fieldConfig.required = true;
    fieldConfig.maxLength = 20;
    fieldConfig.readOnly = true;
    fieldConfig.includeInTable = true;
    fieldConfig.includeInTableIndex = 3;
    fieldConfig.domain_lov = 'LovRiskRating';
    fieldConfig.domain_VirtualFieldName = 'InherentRiskRating';
    //fieldConfig.preFieldContent = '<div>Wal <strong>was</strong> here</div>';
    //fieldConfig.postFieldContent = '<div>Fred <strong>was</strong> here</div>';
    fieldConfigs.push(fieldConfig);

    formFieldConfig = new FormFieldConfig('Inherent Risk', fieldConfigs);
    this.formFieldConfigs.push(formFieldConfig);

    //
    // Controls
    //

    fieldConfigs = [];

    fieldConfig = new FieldConfig('CurrentControls', 'Current Controls:', 'text-area');
    fieldConfig.maxLength = 2000;
    //fieldConfig.includeInTable = true;
    //fieldConfig.includeInTableIndex = 4;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('AdditionalControls', 'Additional Controls Required:', 'text-area');
    fieldConfig.maxLength = 2000;
    //fieldConfig.includeInTable = true;
    //fieldConfig.includeInTableIndex = 5;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('DateDueAdditionalControls', 'Due Date:', 'date');
    format = new FieldDateFormat();
    fieldConfig.format = format;
    //fieldConfig.includeInTable = true;
    //fieldConfig.includeInTableIndex = 8;
    fieldConfigs.push(fieldConfig);

    formFieldConfig = new FormFieldConfig('Controls', fieldConfigs);
    this.formFieldConfigs.push(formFieldConfig);

    //
    // Additional Controls
    //

    // fieldConfigs = [];

    // formFieldConfig = new FormFieldConfig('Additional', fieldConfigs);
    // formFieldConfigs.push(formFieldConfig);

    //
    // Status
    //

    fieldConfigs = [];

    fieldConfig = new FieldConfig('RiskOwnerId', 'Risk Owner:', 'text');
    fieldConfig.required = true;
    fieldConfig.maxLength = 20;
    fieldConfig.domain_lov = 'LovStaff';
    fieldConfig.domain_VirtualFieldName = 'OwnerStaffName';
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('RiskResponsibilityId', 'Risk Responsibility:', 'text');
    fieldConfig.required = true;
    fieldConfig.maxLength = 20;
    fieldConfig.domain_lov = 'LovStaff';
    fieldConfig.domain_VirtualFieldName = 'ResponsibleStaffName';
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('ControlEffectiveness', 'Control Effectiveness:', 'radio-v');
    fieldConfig.required = true;
    fieldConfig.maxLength = 20;
    fieldConfig.domain = 'DomRiskControlEffectiveness';
    fieldConfig.domain_VirtualFieldName = 'ControlEffectiveness_DomainDesc';
    fieldConfig.includeInTable = true;
    fieldConfig.includeInTableIndex = 6;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('ControlStatus', 'Control Status:', 'radio-v');
    fieldConfig.required = true;
    fieldConfig.maxLength = 20;
    fieldConfig.domain = 'DomRiskControlStatus';
    fieldConfig.domain_VirtualFieldName = 'ControlStatus_DomainDesc';
    fieldConfig.includeInTable = true;
    fieldConfig.includeInTableIndex = 7;
    fieldConfigs.push(fieldConfig);

    formFieldConfig = new FormFieldConfig('Status', fieldConfigs);
    this.formFieldConfigs.push(formFieldConfig);

    //
    // Residual Risk
    //

    fieldConfigs = [];

    fieldConfig = new FieldConfig('ResidualRisk_Likelihood', 'Residual Risk Likelihood:', 'radio-v');
    fieldConfig.required = true;
    fieldConfig.maxLength = 20;
    fieldConfig.domain_lov = 'LovRiskLikelihood';
    fieldConfig.domain_VirtualFieldName = 'ResidualLikelihood';
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('ResidualRisk_Consequence', 'Residual Risk Consequence:', 'radio-v');
    fieldConfig.required = true;
    fieldConfig.maxLength = 20;
    fieldConfig.domain_lov = 'LovRiskConsequence';
    fieldConfig.domain_VirtualFieldName = 'ResidualConsequence';
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('ResidualRisk_Rating', 'Residual Risk Rating:', 'radio-v');
    fieldConfig.required = true;
    fieldConfig.maxLength = 20;
    fieldConfig.readOnly = true;
    fieldConfig.includeInTable = true;
    fieldConfig.includeInTableIndex = 9;
    fieldConfig.domain_lov = 'LovRiskRating';
    fieldConfig.domain_VirtualFieldName = 'ResidualRating';
    fieldConfigs.push(fieldConfig);

    formFieldConfig = new FormFieldConfig('Residual Risk', fieldConfigs);
    this.formFieldConfigs.push(formFieldConfig);

    //
    // Comments
    //

    fieldConfigs = [];

    fieldConfig = new FieldConfig('Comments', 'Comments:', 'text-area');
    fieldConfig.maxLength = 1000;
    fieldConfigs.push(fieldConfig);

    formFieldConfig = new FormFieldConfig('Comments', fieldConfigs);
    this.formFieldConfigs.push(formFieldConfig);

    // Virtual (and hidden)

    fieldConfigs = [];

    fieldConfig = new FieldConfig('RiskRegister', 'RiskRegister:', 'text');
    fieldConfig.readOnly = true;
    fieldConfig.visible = false;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('Category', 'Category:', 'text');
    fieldConfig.readOnly = true;
    fieldConfig.visible = false;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('SubCategory', 'Sub-Category:', 'text');
    fieldConfig.readOnly = true;
    fieldConfig.visible = false;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('InherentLikelihood', 'InherentLikelihood:', 'text');
    fieldConfig.readOnly = true;
    fieldConfig.visible = false;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('InherentConsequence', 'InherentConsequence:', 'text');
    fieldConfig.readOnly = true;
    fieldConfig.visible = false;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('InherentRiskRating', 'InherentRiskRating:', 'text');
    fieldConfig.readOnly = true;
    fieldConfig.visible = false;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('OwnerStaffName', 'OwnerStaffName:', 'text');
    fieldConfig.readOnly = true;
    fieldConfig.visible = false;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('ResponsibleStaffName', 'ResponsibleStaffName:', 'text');
    fieldConfig.readOnly = true;
    fieldConfig.visible = false;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('ControlEffectiveness_DomainDesc', 'ControlEffectiveness_DomainDesc:', 'text');
    fieldConfig.readOnly = true;
    fieldConfig.visible = false;
    fieldConfigs.push(fieldConfig);
            
    fieldConfig = new FieldConfig('ControlStatus_DomainDesc', 'ControlStatus_DomainDesc:', 'text');
    fieldConfig.readOnly = true;
    fieldConfig.visible = false;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('ResidualLikelihood', 'ResidualLikelihood:', 'text');
    fieldConfig.readOnly = true;
    fieldConfig.visible = false;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('ResidualConsequence', 'ResidualConsequence:', 'text');
    fieldConfig.readOnly = true;
    fieldConfig.visible = false;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('ResidualRiskRating', 'ResidualRiskRating:', 'text');
    fieldConfig.readOnly = true;
    fieldConfig.visible = false;
    fieldConfigs.push(fieldConfig);

    formFieldConfig = new FormFieldConfig('Virtual', fieldConfigs);
    this.formFieldConfigs.push(formFieldConfig);

    super.createFormFieldConfigs();

    return this.formFieldConfigs;  
  }

  protected updateVirtualFields() {
    // Reset
    this.attributes = [];

    this.features.forEach((f) => {
      let attr: RiskAttributes = new RiskAttributes(this.lovDataService, this.domainLovService);

      attr.RiskId = f.attributes.RiskId;
      attr.RiskRegisterId = f.attributes.RiskRegisterId;
      attr.RiskCategoryId = f.attributes.RiskCategoryId;
      attr.RiskSubCategoryId = f.attributes.RiskSubCategoryId;
      attr.RiskTitle = f.attributes.RiskTitle;
      attr.DateRegistered = f.attributes.DateRegistered;
      attr.RiskDetails = f.attributes.RiskDetails;
      attr.PotentialCauses = f.attributes.PotentialCauses;
      attr.PotentialConsequences = f.attributes.PotentialConsequences;
      attr.InherentRisk_Likelihood = f.attributes.InherentRisk_Likelihood;
      attr.InherentRisk_Consequence = f.attributes.InherentRisk_Consequence;
      attr.InherentRisk_Rating = f.attributes.InherentRisk_Rating;
      attr.CurrentControls = f.attributes.CurrentControls;
      attr.AdditionalControls = f.attributes.AdditionalControls;
      attr.DateDueAdditionalControls = f.attributes.DateDueAdditionalControls;
      attr.RiskOwnerId = f.attributes.RiskOwnerId;
      attr.RiskResponsibilityId = f.attributes.RiskResponsibilityId;
      attr.ControlEffectiveness = f.attributes.ControlEffectiveness;
      attr.ControlStatus = f.attributes.ControlStatus;
      attr.ResidualRisk_Likelihood = f.attributes.ResidualRisk_Likelihood;
      attr.ResidualRisk_Consequence = f.attributes.ResidualRisk_Consequence;
      attr.ResidualRisk_Rating = f.attributes.ResidualRisk_Rating;
      attr.Comments = f.attributes.Comments;

      // Common
      attr.OBJECTID = f.attributes.OBJECTID;
      attr.ClubId = f.attributes.ClubId;
      attr.Active = f.attributes.Active;
      attr.GlobalID = f.attributes.GlobalID;
      attr.HasLocation = f.attributes.HasLocation;
      attr.CreationDate = f.attributes.CreationDate;
      attr.Creator = f.attributes.Creator;
      attr.EditDate = f.attributes.EditDate;
      attr.Editor = f.attributes.Editor;

      // Populate virtual / calculated fields
      f.attributes.RiskRegister = attr.RiskRegister;
      f.attributes.Category = attr.Category;
      f.attributes.SubCategory = attr.SubCategory;
      f.attributes.InherentLikelihood = attr.InherentLikelihood;
      f.attributes.InherentConsequence = attr.InherentConsequence;
      f.attributes.InherentRiskRating = attr.InherentRiskRating;
      f.attributes.OwnerStaffName = attr.OwnerStaffName;
      f.attributes.ResponsibleStaffName = attr.ResponsibleStaffName;
      f.attributes.ControlEffectiveness_DomainDesc = attr.ControlEffectiveness_DomainDesc;
      f.attributes.ControlStatus_DomainDesc = attr.ControlStatus_DomainDesc;
      f.attributes.ResidualLikelihood = attr.ResidualLikelihood;
      f.attributes.ResidualConsequence = attr.ResidualConsequence;
      f.attributes.ResidualRiskRating = attr.ResidualRiskRating;

      this.attributes.push(attr);
    });

  }

  protected getLovs() {
    // All LOV Domains
    combineLatest([
      this.categoryLovReadyEvent,
      this.subCategoryLovReadyEvent,
      this.staffLovReadyEvent,
      this.registerLovReadyEvent,
      this.likelihoodLovReadyEvent,
      this.consequenceLovReadyEvent,
      this.ratingLovReadyEvent
    ]).pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      console.log('Risk/FeatureBase getLovs() lovsReady()');
      this.lovReadyEvent.emit(true);
    });

    this.domains_lov = [];
    this.domains_lovSubDomain = [];

    let riskRegisterLOV: RiskRegisterLOV = new RiskRegisterLOV();
    riskRegisterLOV.getFeatureLayerConfigAndData().then(() => {
      riskRegisterLOV.getDomain('LovRiskRegister', 'coded-value').then((domain) => {
        this.domains_lov.push(domain);
        this.registerLovReadyEvent.emit();
      });
    });

    let riskCategoryLOV: RiskCategoryLOV = new RiskCategoryLOV();
    riskCategoryLOV.getFeatureLayerConfigAndData().then(() => {
      riskCategoryLOV.getDomain('LovRiskCategory', 'coded-value').then((domain) => {
        this.domains_lov.push(domain);
        //console.log('getLovs() categoryLovReadyEvent.emit');
        this.categoryLovReadyEvent.emit();
      });
    });

    let riskSubCategoryLOV: RiskSubCategoryLOV = new RiskSubCategoryLOV();
    riskSubCategoryLOV.getFeatureLayerConfigAndData().then(() => {
      riskSubCategoryLOV.getSubDomain('LovRiskSubCategory', 'coded-value').then((domain) => {
        this.domains_lovSubDomain.push(domain);
        //console.log('getLovs() subCategoryLovReadyEvent.emit');
        this.subCategoryLovReadyEvent.emit();
      });
    });

    let riskLikelihoodLOV: RiskLikelihoodLOV = new RiskLikelihoodLOV();
    riskLikelihoodLOV.getFeatureLayerConfigAndData().then(() => {
      riskLikelihoodLOV.getDomain('LovRiskLikelihood', 'coded-value').then((domain) => {
        this.domains_lov.push(domain);
        this.likelihoodLovReadyEvent.emit();
      });
    });

    let riskConsequenceLOV: RiskConsequenceLOV = new RiskConsequenceLOV();
    riskConsequenceLOV.getFeatureLayerConfigAndData().then(() => {
      riskConsequenceLOV.getDomain('LovRiskConsequence', 'coded-value').then((domain) => {
        this.domains_lov.push(domain);
        this.consequenceLovReadyEvent.emit();
      });
    });

    let riskRatingLOV: RiskRatingLOV = new RiskRatingLOV();
    riskRatingLOV.getFeatureLayerConfigAndData().then(() => {
      riskRatingLOV.getDomain('LovRiskRating', 'coded-value').then((domain) => {
        this.domains_lov.push(domain);
        this.ratingLovReadyEvent.emit();
      });
    });

    let staffLOV: StaffLOV = new StaffLOV();
    staffLOV.getFeatureLayerConfigAndData().then(() => {
      staffLOV.getDomain('LovStaff', 'coded-value').then((domain) => {
        this.domains_lov.push(domain);
        this.staffLovReadyEvent.emit();
      });
    });
  }
}

// Tables

export class RiskRegisterLOV extends FeatureLayerObjectBase {
  protected riskRegisterName: string;

  server = LayerIds.gisServer_Risk;
  folder = LayerIds.folder_Risk;
  layerId = LayerIds.layerId_TblRiskRegister_LOV;
  id = 'tableRiskRegister_LOV';
  title = 'Risk Register LOV';

  whereClause = "ClubId='" + Global.clubId + "' AND Active <> 'NO'";
  outFields = ['RiskRegisterId', 'Description'];

  protected async createFormFieldConfigs(): Promise<FormFieldConfig[]> {
    let formFieldConfig: FormFieldConfig;
    let fieldConfigs: FieldConfig[] = [];
    let fieldConfig: FieldConfig;
    let format: any;

    if (this.riskRegisterName == null) {
      this.riskRegisterName = Global.riskRegisterName;
    }

    fieldConfig = new FieldConfig('RiskRegisterId', 'Risk Register Id', 'text');
    fieldConfig.required = true;
    fieldConfig.editable = true;
    fieldConfig.visible = true;
    fieldConfig.maxLength = 20;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('Description', 'Description', 'text');
    fieldConfig.required = true;
    fieldConfig.editable = true;
    fieldConfig.visible = true;
    fieldConfig.maxLength = 200;
    fieldConfigs.push(fieldConfig);

    formFieldConfig = new FormFieldConfig('Description', fieldConfigs);
    this.formFieldConfigs.push(formFieldConfig);

    super.createFormFieldConfigs();

    return this.formFieldConfigs;  
  }
}

export class RiskCategoryLOV extends FeatureLayerObjectBase {
  protected riskRegisterName: string;

  server = LayerIds.gisServer_Risk;
  folder = LayerIds.folder_Risk;
  layerId = LayerIds.layerId_TblRiskCategory_LOV;
  id = 'tableRiskCategory_LOV';
  title = 'Risk Category LOV';

  //whereClause = "ClubId='" + Global.clubId + "' AND RiskRegisterId='" + this.riskRegisterName + "' AND Active <> 'NO'";
  //whereClause = "ClubId='" + Global.clubId + "' AND Active <> 'NO'";
  outFields = ['RiskCategoryId', 'Description'];

  constructor(
    // protected lovDataService: LovDataService,
    // protected domainLovService: DomainLovService
  ) {
    super();

    if (!this.riskRegisterName) {
      this.riskRegisterName = Global.riskRegisterName; 
    }
    this.whereClause = "ClubId='" + Global.clubId + "' AND RiskRegisterId='" + this.riskRegisterName + "' AND Active <> 'NO'";
  }

  protected async createFormFieldConfigs(): Promise<FormFieldConfig[]> {
    let formFieldConfig: FormFieldConfig;
    let fieldConfigs: FieldConfig[] = [];
    let fieldConfig: FieldConfig;
    let format: any;

    fieldConfig = new FieldConfig('RiskRegisterId', 'Risk Register Id', 'text');
    fieldConfig.required = true;
    fieldConfig.maxLength = 20;
    fieldConfig.domain_lov = 'LovRiskRegister';
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('RiskCategoryId', 'Risk Category Id', 'text');
    fieldConfig.required = true;
    fieldConfig.maxLength = 20;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('Description', 'Description', 'text');
    fieldConfig.required = true;
    fieldConfig.maxLength = 200;
    fieldConfigs.push(fieldConfig);

    formFieldConfig = new FormFieldConfig('Description', fieldConfigs);
    this.formFieldConfigs.push(formFieldConfig);

    super.createFormFieldConfigs();

    return this.formFieldConfigs;   
  }
}

export class RiskSubCategoryLOV extends FeatureLayerObjectBase {
  protected riskRegisterName: string;

  server = LayerIds.gisServer_Risk;
  folder = LayerIds.folder_Risk;
  layerId = LayerIds.layerId_TblRiskSubCategory_LOV;
  id = 'tableRiskSubCategory_LOV';
  title = 'Risk Sub-Category LOV';

  //whereClause = "ClubId='" + Global.clubId + "' AND Active <> 'NO'";
  outFields = ['RiskCategoryId', 'RiskSubCategoryId', 'Description'];

  constructor(
    // protected lovDataService: LovDataService,
    // protected domainLovService: DomainLovService
  ) {
    super();

    if (!this.riskRegisterName) {
      this.riskRegisterName = Global.riskRegisterName; 
    }
    this.whereClause = "ClubId='" + Global.clubId + "' AND RiskRegisterId='" + this.riskRegisterName + "' AND Active <> 'NO'";
  }

  public async createFormFieldConfigs(): Promise<FormFieldConfig[]> {
    let formFieldConfig: FormFieldConfig;
    let fieldConfigs: FieldConfig[] = [];
    let fieldConfig: FieldConfig;
    let format: any;

    if (this.riskRegisterName == null) {
      this.riskRegisterName = Global.riskRegisterName;
    }

    fieldConfig = new FieldConfig('RiskRegisterId', 'Risk Register Id', 'text');
    fieldConfig.required = true;
    fieldConfig.maxLength = 20;
    fieldConfig.domain_lov = 'LovRiskRegister';
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('RiskCategoryId', 'Risk Category Id', 'text');
    fieldConfig.required = true;
    fieldConfig.maxLength = 20;
    fieldConfig.domain_lov = 'LovRiskCategory';
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('RiskSubCategoryId', 'Risk Sub-Category Id', 'text');
    fieldConfig.required = true;
    fieldConfig.maxLength = 20;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('Description', 'Description', 'text');
    fieldConfig.required = true;
    fieldConfig.maxLength = 200;
    fieldConfigs.push(fieldConfig);

    formFieldConfig = new FormFieldConfig('Description', fieldConfigs);
    this.formFieldConfigs.push(formFieldConfig);

    super.createFormFieldConfigs();
    return this.formFieldConfigs;
  }
}

export class RiskLikelihoodLOV extends FeatureLayerObjectBase {

  server = LayerIds.gisServer_Risk;
  folder = LayerIds.folder_Risk;
  layerId = LayerIds.layerId_TblRiskLikelihood_LOV;
  id = 'tableRiskLikelihood_LOV';
  title = 'Risk Likelihood LOV';

  whereClause = "ClubId='" + Global.clubId + "' AND Active <> 'NO'";
  outFields = ['RiskLikelihoodId', 'Description'];

  protected async createFormFieldConfigs(): Promise<FormFieldConfig[]> {
    let formFieldConfig: FormFieldConfig;
    let fieldConfigs: FieldConfig[] = [];
    let fieldConfig: FieldConfig;
    let format: any;

    fieldConfig = new FieldConfig('RiskLikelihoodId', 'Risk Likelihood Id', 'text');
    fieldConfig.required = true;
    fieldConfig.maxLength = 20;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('Description', 'Description', 'text');
    fieldConfig.required = true;
    fieldConfig.maxLength = 200;
    fieldConfigs.push(fieldConfig);

    formFieldConfig = new FormFieldConfig('Description', fieldConfigs);
    this.formFieldConfigs.push(formFieldConfig);

    super.createFormFieldConfigs();
    return this.formFieldConfigs;
  }
}

export class RiskConsequenceLOV extends FeatureLayerObjectBase {

  server = LayerIds.gisServer_Risk;
  folder = LayerIds.folder_Risk;
  layerId = LayerIds.layerId_TblRiskConsequence_LOV;
  id = 'tableRiskConsequence_LOV';
  title = 'Risk Consequence LOV';

  whereClause = "ClubId='" + Global.clubId + "' AND Active <> 'NO'";
  outFields = ['RiskConsequenceId', 'Description'];

  protected async createFormFieldConfigs(): Promise<FormFieldConfig[]> {
    let formFieldConfig: FormFieldConfig;
    let fieldConfigs: FieldConfig[] = [];
    let fieldConfig: FieldConfig;
    let format: any;

    fieldConfig = new FieldConfig('RiskConsequenceId', 'Risk Consequence Id', 'text');
    fieldConfig.required = true;
    fieldConfig.maxLength = 20;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('Description', 'Description', 'text');
    fieldConfig.required = true;
    fieldConfig.maxLength = 200;
    fieldConfigs.push(fieldConfig);

    formFieldConfig = new FormFieldConfig('Description', fieldConfigs);
    this.formFieldConfigs.push(formFieldConfig);

    super.createFormFieldConfigs();
    return this.formFieldConfigs;
  }
}

export class RiskRatingLOV extends FeatureLayerObjectBase {

  server = LayerIds.gisServer_Risk;
  folder = LayerIds.folder_Risk;
  layerId = LayerIds.layerId_TblRiskRating_LOV;
  id = 'tableRiskRating_LOV';
  title = 'Risk Rating LOV';

  whereClause = "ClubId='" + Global.clubId + "' AND Active <> 'NO'";
  outFields = ['RiskRatingId', 'Description'];

  protected async createFormFieldConfigs(): Promise<FormFieldConfig[]> {
    let formFieldConfig: FormFieldConfig;
    let fieldConfigs: FieldConfig[] = [];
    let fieldConfig: FieldConfig;
    let format: any;

    fieldConfig = new FieldConfig('RiskRatingId', 'Risk Rating Id', 'text');
    fieldConfig.required = true;
    fieldConfig.maxLength = 20;
    fieldConfigs.push(fieldConfig);

    fieldConfig = new FieldConfig('Description', 'Description', 'text');
    fieldConfig.required = true;
    fieldConfig.maxLength = 200;
    fieldConfigs.push(fieldConfig);

    formFieldConfig = new FormFieldConfig('Description', fieldConfigs);
    this.formFieldConfigs.push(formFieldConfig);

    super.createFormFieldConfigs();
    return this.formFieldConfigs;
  }
}

//
// Risk Matrix
//

export class RiskMatrix {
  public static MatrixHeader_5x5: string[] = [
    "Likelihood",
    "Negligible",
    "Minor",
    "Moderate",
    "Major",
    "Catastrophic"
  ];

  public static MatrixHeader_5x5_D: string[] = [
    "Likelihood",
    "1",
    "2",
    "3",
    "4",
    "5"
  ];

  public static MatrixData_5x5_A = [{
    "Likelihood": "Almost_Certain",
    "Negligible": "Moderate",
    "Minor": "High",
    "Moderate": "Extreme",
    "Major": "Extreme",
    "Catastrophic": "Extreme"
  },
  {
    "Likelihood": "Likely",
    "Negligible": "Moderate",
    "Minor": "High",
    "Moderate": "High",
    "Major": "Extreme",
    "Catastrophic": "Extreme"
  },
  {
    "Likelihood": "Possible",
    "Negligible": "Low",
    "Minor": "Moderate",
    "Moderate": "High",
    "Major": "High",
    "Catastrophic": "Extreme"
  },
  {
    "Likelihood": "Unlikely",
    "Negligible": "Low",
    "Minor": "Moderate",
    "Moderate": "Moderate",
    "Major": "High",
    "Catastrophic": "High"
  },
  {
    "Likelihood": "Rare",
    "Negligible": "Low",
    "Minor": "Low",
    "Moderate": "Low",
    "Major": "Moderate",
    "Catastrophic": "Moderate"
  }];

  public static MatrixData_5x5_B = [{
    "Likelihood": "Almost_Certain",
    "Negligible": "Moderate",
    "Minor": "High",
    "Moderate": "Extreme",
    "Major": "Extreme",
    "Catastrophic": "Extreme"
  },
  {
    "Likelihood": "Likely",
    "Negligible": "Moderate",
    "Minor": "High",
    "Moderate": "High",
    "Major": "Extreme",
    "Catastrophic": "Extreme"
  },
  {
    "Likelihood": "Possible",
    "Negligible": "Low",
    "Minor": "Moderate",
    "Moderate": "High",
    "Major": "High",
    "Catastrophic": "Extreme"
  },
  {
    "Likelihood": "Unlikely",
    "Negligible": "Low",
    "Minor": "Low",
    "Moderate": "Moderate",
    "Major": "Moderate",
    "Catastrophic": "High"
  },
  {
    "Likelihood": "Rare",
    "Negligible": "Low",
    "Minor": "Low",
    "Moderate": "Low",
    "Major": "Low",
    "Catastrophic": "Moderate"
  }];

  public static MatrixData_5x5_C = [{
    "Likelihood": "Almost_Certain",
    "Negligible": "Moderate",
    "Minor": "High",
    "Moderate": "High",
    "Major": "Extreme",
    "Catastrophic": "Extreme"
  },
  {
    "Likelihood": "Likely",
    "Negligible": "Low",
    "Minor": "Moderate",
    "Moderate": "High",
    "Major": "Extreme",
    "Catastrophic": "Extreme"
  },
  {
    "Likelihood": "Possible",
    "Negligible": "Low",
    "Minor": "Moderate",
    "Moderate": "High",
    "Major": "High",
    "Catastrophic": "Extreme"
  },
  {
    "Likelihood": "Unlikely",
    "Negligible": "Low",
    "Minor": "Low",
    "Moderate": "Moderate",
    "Major": "High",
    "Catastrophic": "High"
  },
  {
    "Likelihood": "Rare",
    "Negligible": "Low",
    "Minor": "Low",
    "Moderate": "Low",
    "Major": "Moderate",
    "Catastrophic": "Moderate"
  }];

  public static MatrixData_5x5_D = [{
    "Likelihood": "5",
    "1": "2",
    "2": "3",
    "3": "3",
    "4": "4",
    "5": "4"
  },
  {
    "Likelihood": "4",
    "1": "1",
    "2": "2",
    "3": "3",
    "4": "4",
    "5": "4"
  },
  {
    "Likelihood": "3",
    "1": "1",
    "2": "2",
    "3": "3",
    "4": "3",
    "5": "4"
  },
  {
    "Likelihood": "2",
    "1": "1",
    "2": "1",
    "3": "2",
    "4": "3",
    "5": "3"
  },
  {
    "Likelihood": "1",
    "1": "1",
    "2": "1",
    "3": "1",
    "4": "2",
    "5": "2"
  }];

  public static getRiskRating(matrixSize: string, matrixVersion: string, likelihood: string, consequence: string): string {

    let riskRating = '';
    let matrixData;

    console.log('likelihood ', likelihood);
    console.log('consequence ', consequence);


    switch (matrixSize) {
      case '4x4':
        switch (matrixVersion) {
          case 'A':
            //matrixData = RiskMatrix.MatrixData_4x4_A;
            break;

          case 'B':
            //matrixData = RiskMatrix.MatrixData_4x4_B;
            break;

          default:
            //matrixData = RiskMatrix.MatrixData_4x4_A;
            break;
        }
        break;

      case '5x5':
        switch (matrixVersion) {
          case 'A':
            matrixData = RiskMatrix.MatrixData_5x5_A;
            break;

          case 'B':
            matrixData = RiskMatrix.MatrixData_5x5_B;
            break;

          case 'C':
            matrixData = RiskMatrix.MatrixData_5x5_C;
            break;

          case 'D':
            matrixData = RiskMatrix.MatrixData_5x5_D;
            break;

          default:
            matrixData = RiskMatrix.MatrixData_5x5_D;
            break;
        }
        break;

      default:
        matrixData = RiskMatrix.MatrixData_5x5_A;
        break;
    }

    console.log('matrixData', matrixData);

    for (let i = 0; i < matrixData.length; i++) {
      if (matrixData[i].Likelihood === likelihood) {
        console.log('likelihood found', matrixData[i]);
        console.log('riskRating found', matrixData[i][consequence]);
        riskRating = matrixData[i][consequence];
      }
    }

    console.log('riskRating ', riskRating);

    return riskRating;
  }
}
